import React from 'react';

import { Dialog } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import useResponsive from 'src/utils/responsive';

import { ProfilePopupLayoutContainer } from './profile-popup.styled';

interface IProfilePopupLayoutProps {
  children?: React.ReactNode;
  open: boolean;
  notExpanded?: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(() => ({
  paper: {
    width: 960,
    borderRadius: 12,
    height: '100%',
    maxWidth: '100%',
  },
}));

const ProfilePopupLayout = ({ children, open, notExpanded, onClose }: IProfilePopupLayoutProps) => {
  const { paper } = useStyles();
  const responsive = useResponsive();
  return (
    <Dialog classes={{ paper }} open={open} onClose={onClose} disableBackdropClick>
      <ProfilePopupLayoutContainer responsive={responsive} notExpanded={notExpanded}>
        {children}
      </ProfilePopupLayoutContainer>
    </Dialog>
  );
};

export default ProfilePopupLayout;
