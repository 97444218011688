import React, { useEffect, useState } from 'react';

import {
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';

import { CustomDatePicker, CustomInputSelect, InputField, NASSRadio } from 'src/components';
import CustomFormDialog from 'src/components/formDialog';
import { CALL_SUCCESS } from 'src/constants/common';
import { ErrorMessage } from 'src/pages/signup/components/BasicInformation';
import { InputCheckBox } from 'src/pages/signup/components/InputCheckbox';
import { CustomLabel, genders, ReactFlagsSelectStyled } from 'src/pages/signup/components/types';
import { ISignUpReq } from 'src/services/auth';
import { createUser } from 'src/services/users';
import { customLabels, getAreaCode } from 'src/utils/areaCode';
import {
  capitalizeFirstLetter,
  containsNumericCharacters,
  containsSpecialCharacters,
  containsUpperAndLowerCaseRegexp,
  intRegex,
  isSpace,
  emailIsValid,
  _checkErrors,
} from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { accountTypes } from '../types';

const MAXIMUM_USERNAME_LENGTH = 50;
const MAXIMUM_PHONE_NUMBER_LENGTH = 15;
const MINIMUM_PASSWORD_LENGTH = 8;
const MAXIMUM_PASSWORD_LENGTH = 32;
const MAXIMUM_MAILING_LENGTH = 128;
const POSTAL_CODE_LENGTH = 6;
const POSTAL_CODE_MESSAGE = 'Postal Code must be 6 number';
const TEXT_REQUIRE = 'This field is required';
const EMAIL_TEXT_CORRECT = 'Please fill in a correct email';
const PHONE_TEXT_REQUIRE = 'This field must be a number';
const TOOLTIP_PASSWORD_MESSAGE = `- Your password MUST be between 8 and 32 characters in length. 
  - Your password MUST have at least one UPPERCASE character. 
  - Your password MUST have at least one LOWERCASE character. 
  - Your password MUST have at least one number. 
  - Your password MUST have at least one Special (Non-Alphanumeric) character (eg. ! @ # $ % ^ & * ). 
  - Your password MUST NOT contain spaces.`;
const ERROR_PASSWORD_MESSAGE = 'Please input a valid password';
const ERROR_VERIFY_PASSWORD_MESSAGE = 'Passwords do not match';
const CORRECT_YEAR = 'Please fill a correct year';
const GRADUATE_YEAR_GREATER_MESSAGE = 'Year of graduation must be greater than birth year';
const MINIMUM_MEMBER_OLD = 25;
const YOUTH_MEMBER_REQUIRE_TEXT = "Youth member's age must not be over 25";

const useStyles = makeStyles((theme) => ({
  noticeRequire: {
    color: 'red',
    verticalAlign: 'middle',
    marginLeft: 4,
  },
  modalStyle: {},
}));

interface ICreateUser {
  _onCloseModal: () => void;
  getData({ currentPage, limit }: { currentPage: number; limit: number }): Promise<void>;
  open: boolean;
  setActionSuccess: (value: React.SetStateAction<boolean>) => void;
  setActionFailed: (value: React.SetStateAction<boolean>) => void;
  setMessageResponse: (value: React.SetStateAction<string>) => void;
  setShowDetailUser?: React.Dispatch<React.SetStateAction<boolean>>;
  setUserDetailId?: React.Dispatch<React.SetStateAction<string | undefined>>;
}
const CreateUser = ({
  _onCloseModal,
  open,
  getData,
  setActionSuccess,
  setActionFailed,
  setMessageResponse,
  setShowDetailUser,
  setUserDetailId,
}: ICreateUser) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const responsive = useResponsive();

  const [data, setData] = useState<ISignUpReq>({
    surname: '',
    givenName: '',
    dateOfBirth: null,
    gender: '',
    areaCode: 'SG',
    mobilePhone: '',
    emailAddress: '',
    periodStudyInNYK: null,
    periodStudyInNPP: null,
    periodStudyInNYP: null,
    periodStudyInNYGH: null,
    password: '',
    verifyPassword: '',
    accountType: 'none',
    verificationStatus: 'unverified',
    mailingAddress: '',
    postalCode: '',
  });

  const [errors, setErrors] = useState({
    surname: false,
    givenName: false,
    dateOfBirth: false,
    gender: false,
    emailAddress: false,
    mobilePhone: false,
    password: false,
    verifyPassword: false,
    graduateSchool: false,
    accountType: false,
    mailingAddress: false,
    postalCode: false,
  });

  const date = data.dateOfBirth ?? '';
  let oldMember = Number(new Date().getFullYear()) - Number(new Date(date?.toString())?.getFullYear());

  const disabled =
    data.surname !== '' &&
    data.givenName !== '' &&
    data?.dateOfBirth?.toString() !== null &&
    data.gender !== '' &&
    data.emailAddress !== '' &&
    data.areaCode !== '' &&
    data.mobilePhone !== '' &&
    data.password !== '' &&
    data.verifyPassword !== '' &&
    data.mailingAddress !== '' &&
    data.postalCode !== '' &&
    data.accountType !== 'none' &&
    (data.periodStudyInNYK !== null ||
      data.periodStudyInNPP !== null ||
      data.periodStudyInNYGH !== null ||
      data.periodStudyInNYP !== null) &&
    data.password === data.verifyPassword &&
    !_checkErrors(errors);

  const periodStudies = [
    {
      label: 'Nanyang Kindergarten',
      name: 'periodStudyInNYK',
      checked: Boolean(data.periodStudyInNYK),
      graduateYear: data.periodStudyInNYK,
    },
    {
      label: 'Nanyang Pre-Primary',
      name: 'periodStudyInNPP',
      checked: Boolean(data.periodStudyInNPP),
      graduateYear: data.periodStudyInNPP,
    },
    {
      label: 'Nanyang Primary',
      name: 'periodStudyInNYP',
      checked: Boolean(data.periodStudyInNYP),
      graduateYear: data.periodStudyInNYP,
    },
    {
      label: "Nanyang Girls' High",
      name: 'periodStudyInNYGH',
      checked: Boolean(data.periodStudyInNYGH),
      graduateYear: data.periodStudyInNYGH,
    },
  ];

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleClickShowVerifyPassword() {
    setShowVerifyPassword(!showVerifyPassword);
  }

  function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
  }

  const _handleChangeArea = (areaCode: string) => {
    setData({ ...data, areaCode: areaCode });
  };

  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [e.target.name]: e.target.value });

    setValidate(e.target.name, e.target.value);

    if (e.target.name === 'emailAddress') {
      setValidate('emailAddress', emailIsValid(e.target.value) ? e.target.value : '');
    }

    if (e.target.name === 'mobilePhone') {
      setValidate('mobilePhone', intRegex.test(e.target.value) ? e.target.value : '');
    }

    if (e.target.name === 'password') {
      const passwordLongEnough = _.size(e.target.value) >= MINIMUM_PASSWORD_LENGTH;

      const isPasswordValid =
        passwordLongEnough &&
        containsUpperAndLowerCaseRegexp.test(e.target.value) &&
        containsSpecialCharacters.test(e.target.value) &&
        !isSpace(e.target.value) &&
        containsNumericCharacters.test(e.target.value);

      setValidate('password', isPasswordValid ? e.target.value : '');
    }

    if (e.target.name === 'verifyPassword') {
      if (e.target.value === data.password) {
        setValidate('verifyPassword', e.target.value);
      } else {
        setValidate('verifyPassword', '');
      }
    }

    if (e.target.name === 'postalCode') {
      const postalCodeLongEnough = _.size(e.target.value) >= POSTAL_CODE_LENGTH;
      const isPostalCodeValid = postalCodeLongEnough && intRegex.test(e.target.value);

      setValidate('postalCode', isPostalCodeValid ? e.target.value : '');
    }

    if (e.target.name === 'accountType') {
      if (e.target.value === 'lifetime-membership') {
        setData({ ...data, [e.target.name]: e.target.value, verificationStatus: 'verified' });
      }
    }

    return;
  }
  const handleDateChange = (date: Date | null) => {
    if (date) {
      setData({ ...data, dateOfBirth: date });
      setValidate('dateOfBirth', date.toString());
    }
  };

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setData({ ...data, [e.target.name]: valueTrimmed });
  };

  const validateGraduate =
    data.periodStudyInNYK !== null ||
    data.periodStudyInNPP !== null ||
    data.periodStudyInNYGH !== null ||
    data.periodStudyInNYP !== null ||
    (data.periodStudyInNYK !== null && !intRegex.test(data.periodStudyInNYK)) ||
    (data.periodStudyInNPP !== null && !intRegex.test(data.periodStudyInNPP)) ||
    (data.periodStudyInNYGH !== null && !intRegex.test(data.periodStudyInNYGH)) ||
    (data.periodStudyInNYP !== null && !intRegex.test(data.periodStudyInNYP)) ||
    (data.periodStudyInNYK !== null && Number(data.periodStudyInNYK) <= new Date(date.toString()).getFullYear()) ||
    (data.periodStudyInNPP !== null && Number(data.periodStudyInNPP) <= new Date(date.toString()).getFullYear()) ||
    (data.periodStudyInNYGH !== null && Number(data.periodStudyInNYGH) <= new Date(date.toString()).getFullYear()) ||
    (data.periodStudyInNYP !== null && Number(data.periodStudyInNYP) <= new Date(date.toString()).getFullYear());

  const _onSubmit = async () => {
    let validErrors = errors;

    if (!validateGraduate) {
      validErrors.graduateSchool = true;
      setErrors({ ...validErrors, graduateSchool: true });
    }
    if (_.isEmpty(data.surname)) {
      validErrors.surname = true;
      setErrors({ ...validErrors, surname: true });
    }
    if (_.isEmpty(data.givenName)) {
      validErrors.givenName = true;
      setErrors({ ...validErrors, givenName: true });
    }
    if (_.isEmpty(data?.dateOfBirth?.toString())) {
      validErrors.dateOfBirth = true;
      setErrors({ ...validErrors, dateOfBirth: true });
    }
    if (_.isEmpty(data.gender)) {
      validErrors.gender = true;
      setErrors({ ...validErrors, gender: true });
    }
    if (_.isEmpty(data.mobilePhone)) {
      validErrors.mobilePhone = true;
      setErrors({ ...errors, mobilePhone: true });
    }
    if (_.isEmpty(data.emailAddress)) {
      validErrors.emailAddress = true;
      setErrors({ ...errors, emailAddress: true });
    }
    if (_.isEmpty(data.password)) {
      validErrors.password = true;
      setErrors({ ...errors, password: true });
    }
    if (_.isEmpty(data.verifyPassword)) {
      validErrors.verifyPassword = true;
      setErrors({ ...errors, verifyPassword: true });
    }
    if (data.accountType === 'none') {
      validErrors.accountType = true;
      setErrors({ ...errors, accountType: true });
    }
    if (_.isEmpty(data.postalCode)) {
      validErrors.postalCode = true;
      setErrors({ ...errors, postalCode: true });
    }
    if (_.isEmpty(data.mailingAddress)) {
      validErrors.mailingAddress = true;
      setErrors({ ...errors, mailingAddress: true });
    }

    if (disabled) {
      const body = {
        surname: data.surname,
        givenName: data.givenName,
        dateOfBirth: data.dateOfBirth,
        gender: data.gender,
        emailAddress: data.emailAddress,
        areaCode: getAreaCode(data.areaCode),
        mobilePhone: data.mobilePhone,
        password: data.password,
        periodStudyInNYK: data.periodStudyInNYK !== null ? data.periodStudyInNYK.toString() : null,
        periodStudyInNPP: data.periodStudyInNPP !== null ? data.periodStudyInNPP.toString() : null,
        periodStudyInNYP: data.periodStudyInNYP !== null ? data.periodStudyInNYP.toString() : null,
        periodStudyInNYGH: data.periodStudyInNYGH !== null ? data.periodStudyInNYGH.toString() : null,
        accountType: data.accountType,
        verificationStatus: data.accountType === 'lifetime-membership' ? 'verified' : data.verificationStatus,
        mailingAddress: data.mailingAddress,
        postalCode: data.postalCode,
      };
      setLoading(true);

      const response = await createUser(body);

      if (response.statusCode === CALL_SUCCESS) {
        getData({ currentPage: 1, limit: 10 });
        setActionSuccess(true);
        setMessageResponse(response.message);
        _onCloseModal();

        setUserDetailId?.(response?.data?.id);
        setShowDetailUser?.(true);
      } else {
        setActionFailed(true);
        setMessageResponse(response.message);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    if (validateGraduate) {
      setErrors({ ...errors, graduateSchool: false });
    }
  }, [validateGraduate]);

  useEffect(() => {
    if (open === false) {
      setData({
        surname: '',
        givenName: '',
        dateOfBirth: null,
        gender: '',
        areaCode: 'SG',
        mobilePhone: '',
        emailAddress: '',
        periodStudyInNYK: null,
        periodStudyInNPP: null,
        periodStudyInNYP: null,
        periodStudyInNYGH: null,
        password: '',
        verifyPassword: '',
        accountType: 'none',
        verificationStatus: 'unverified',
        mailingAddress: '',
        postalCode: '',
      });
    }
  }, [open]);

  useEffect(() => {
    if (data.password === data.verifyPassword) {
      setErrors({ ...errors, verifyPassword: false });
    }
  }, [data.password, data.verifyPassword]);

  function handleSubmit() {
    _onSubmit();
  }

  return (
    <CustomFormDialog
      open={open}
      _onCloseModal={_onCloseModal}
      _onSubmit={handleSubmit}
      submitText="Create"
      title="Create Account"
      disabled={loading}
      noClickOutSide={true}
      modalStyles={{
        marginLeft: 0,
        marginRight: 0,
      }}
      minWidth="960px"
      minWidthPaper={responsive.isDesktopOrLaptop ? '1000px' : undefined}
      isConfirmPopup={false}
    >
      <Box mt={-2} display="flex" justifyContent="space-between">
        <Box flex={1} pr={1}>
          <InputField
            value={data.surname}
            onChange={_handleChange}
            onBlur={onBlurField}
            require
            title="Surname (as in NRIC)"
            name="surname"
            margin="dense"
            fullWidth
            InputProps={{
              inputProps: {
                maxLength: MAXIMUM_USERNAME_LENGTH,
              },
            }}
            error={errors.surname}
            errormessage={errors.surname && TEXT_REQUIRE}
          />
        </Box>
        <Box flex={1} pl={1}>
          <InputField
            value={data.givenName}
            onBlur={onBlurField}
            onChange={_handleChange}
            require
            title="Given Name (as in NRIC)"
            name="givenName"
            margin="dense"
            fullWidth
            InputProps={{
              inputProps: {
                maxLength: MAXIMUM_USERNAME_LENGTH,
              },
            }}
            error={errors.givenName}
            errormessage={errors.givenName && TEXT_REQUIRE}
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box flex={1} pr={1} display="flex" mt={2}>
          <FormControl fullWidth>
            <CustomLabel>
              Date of Birth
              <Typography component="span" className={classes.noticeRequire}>
                *
              </Typography>
            </CustomLabel>
            <CustomDatePicker
              isError={errors.dateOfBirth}
              onChange={handleDateChange}
              value={data.dateOfBirth}
              name="dateOfBirth"
              maxDate={moment().subtract(0, 'years')}
            />
            {errors.dateOfBirth && <ErrorMessage isError={errors.dateOfBirth}>{TEXT_REQUIRE}</ErrorMessage>}
          </FormControl>
        </Box>
        <Box flex={1} pl={1}>
          <CustomInputSelect
            title="Gender"
            name="gender"
            value={data.gender}
            onChange={_handleChange}
            error={errors.gender}
            errormessage={TEXT_REQUIRE}
          >
            {genders.map((gender) => (
              <MenuItem key={gender} value={gender}>
                {capitalizeFirstLetter(gender)}
              </MenuItem>
            ))}
          </CustomInputSelect>
        </Box>
      </Box>
      <Box>
        <InputField
          value={data.emailAddress}
          onChange={_handleChange}
          onBlur={onBlurField}
          name="emailAddress"
          title="Email Address"
          type="email"
          margin="dense"
          require
          fullWidth
          InputProps={{
            inputProps: {
              maxLength: MAXIMUM_USERNAME_LENGTH,
            },
          }}
          error={errors.emailAddress}
          errormessage={
            errors.emailAddress && data.emailAddress === '' ? TEXT_REQUIRE : errors.emailAddress && EMAIL_TEXT_CORRECT
          }
        />
      </Box>
      <Box display="flex" mt={3}>
        <Box width={145} mt={1}>
          <CustomLabel>
            Phone Number
            <Typography component="span" className={classes.noticeRequire}>
              *
            </Typography>
          </CustomLabel>
          <ReactFlagsSelectStyled
            fullWidth
            selected={data.areaCode}
            onSelect={_handleChangeArea}
            searchable
            customLabels={customLabels}
            showSecondaryOptionLabel
            showSelectedLabel={false}
          />
        </Box>
        <Box flex={1} pl={2} mt={2}>
          <InputField
            placeholder="Phone number (Including area code, if there is)"
            value={data.mobilePhone}
            onChange={_handleChange}
            onBlur={onBlurField}
            margin="dense"
            name="mobilePhone"
            fullWidth
            InputProps={{
              inputProps: {
                maxLength: MAXIMUM_PHONE_NUMBER_LENGTH,
              },
            }}
            error={errors.mobilePhone}
            errormessage={
              errors.mobilePhone && data.mobilePhone === '' ? TEXT_REQUIRE : errors.mobilePhone && PHONE_TEXT_REQUIRE
            }
          />
        </Box>
      </Box>
      <Box display="flex" mt={3}>
        <Box flex={responsive.isTabletOrMobile ? 3 : 1} pr={1}>
          <CustomLabel>
            School Attended
            <Typography component="span" className={classes.noticeRequire}>
              *
            </Typography>
          </CustomLabel>
          {errors.graduateSchool && (
            <ErrorMessage isError={errors.graduateSchool}>
              {!(
                data.periodStudyInNYK !== null ||
                data.periodStudyInNPP !== null ||
                data.periodStudyInNYGH !== null ||
                data.periodStudyInNYP !== null
              ) && TEXT_REQUIRE}
            </ErrorMessage>
          )}
        </Box>
        <Box flex={responsive.isTabletOrMobile ? 2 : 1} pl={1}>
          <CustomLabel>
            Year of Graduation / Leave School
            <Typography component="span" className={classes.noticeRequire}>
              *
            </Typography>
          </CustomLabel>
          {errors.graduateSchool && (
            <ErrorMessage isError={errors.graduateSchool}>
              {!(
                data.periodStudyInNYK !== null ||
                data.periodStudyInNPP !== null ||
                data.periodStudyInNYGH !== null ||
                data.periodStudyInNYP !== null
              )
                ? TEXT_REQUIRE
                : (data.periodStudyInNYK !== null && !intRegex.test(data.periodStudyInNYK)) ||
                  (data.periodStudyInNPP !== null && !intRegex.test(data.periodStudyInNPP)) ||
                  (data.periodStudyInNYGH !== null && !intRegex.test(data.periodStudyInNYGH)) ||
                  (data.periodStudyInNYP !== null && !intRegex.test(data.periodStudyInNYP))
                ? CORRECT_YEAR
                : (data.periodStudyInNYK !== null &&
                    Number(data.periodStudyInNYK) <= new Date(date.toString()).getFullYear()) ||
                  (data.periodStudyInNPP !== null &&
                    Number(data.periodStudyInNPP) <= new Date(date.toString()).getFullYear()) ||
                  (data.periodStudyInNYGH !== null &&
                    Number(data.periodStudyInNYGH) <= new Date(date.toString()).getFullYear()) ||
                  (data.periodStudyInNYP !== null &&
                    Number(data.periodStudyInNYP) <= new Date(date.toString()).getFullYear())
                ? GRADUATE_YEAR_GREATER_MESSAGE
                : ''}
            </ErrorMessage>
          )}
        </Box>
      </Box>
      {periodStudies.map((school) => (
        <InputCheckBox
          errors={errors}
          setErrors={setErrors}
          key={school.name}
          data={data}
          setData={setData}
          school={school}
          type="createUser"
        />
      ))}
      <Box>
        <InputField
          value={data.mailingAddress}
          onChange={_handleChange}
          onBlur={onBlurField}
          margin="dense"
          name="mailingAddress"
          title="Mailing Address"
          require
          fullWidth
          InputProps={{
            inputProps: {
              maxLength: MAXIMUM_MAILING_LENGTH,
            },
          }}
          error={errors.mailingAddress}
          errormessage={errors.mailingAddress && TEXT_REQUIRE}
        />
      </Box>
      <Box>
        <InputField
          placeholder="E.g: 569933"
          value={data.postalCode}
          onChange={_handleChange}
          onBlur={onBlurField}
          margin="dense"
          name="postalCode"
          title="Postal Code"
          require
          fullWidth
          InputProps={{
            inputProps: {
              maxLength: POSTAL_CODE_LENGTH,
            },
          }}
          error={errors.postalCode}
          errormessage={
            errors.postalCode && data.postalCode === '' ? TEXT_REQUIRE : errors.postalCode && POSTAL_CODE_MESSAGE
          }
        />
      </Box>
      <Box>
        <CustomInputSelect
          disabled={data.dateOfBirth === null}
          title="Account type"
          name="accountType"
          value={data.accountType === '' ? 'none' : data.accountType}
          margin="dense"
          fullWidth
          onChange={_handleChange}
          error={errors.accountType || (data.accountType === 'youth-member' && oldMember > MINIMUM_MEMBER_OLD)}
          errormessage={data.accountType === 'none' ? TEXT_REQUIRE : YOUTH_MEMBER_REQUIRE_TEXT}
        >
          <MenuItem disabled style={{ display: 'none' }} key="none" value="none">
            Choose a type
          </MenuItem>
          {accountTypes.map((accountType) => (
            <MenuItem key={accountType.key} value={accountType.key}>
              {capitalizeFirstLetter(accountType.value)}
            </MenuItem>
          ))}
        </CustomInputSelect>
      </Box>
      <Box flex={1} pr={4} mt={2}>
        <CustomLabel>
          Verification Status
          <Typography component="span" className={classes.noticeRequire}>
            *
          </Typography>
        </CustomLabel>
      </Box>
      <Box>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="confirm-old"
            value={data.accountType === 'lifetime-membership' ? 'verified' : data.verificationStatus}
            onChange={_handleChange}
            name="verificationStatus"
            row
          >
            <FormControlLabel value="verified" control={<NASSRadio />} label="Verified" />
            <Box width={'180px'} />
            <FormControlLabel
              disabled={data.accountType === 'lifetime-membership'}
              value="unverified"
              control={<NASSRadio />}
              label="Unverified"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <InputField
          value={data.password}
          onChange={_handleChange}
          margin="dense"
          name="password"
          title="Password"
          require
          fullWidth
          type={showPassword ? 'text' : 'password'}
          error={errors.password}
          errormessage={errors.password && data.password === '' ? TEXT_REQUIRE : ERROR_PASSWORD_MESSAGE}
          notice
          noticeYellow
          noticeTitle={
            <Typography style={{ whiteSpace: 'pre-line', fontSize: 14 }} color="inherit">
              {TOOLTIP_PASSWORD_MESSAGE}
            </Typography>
          }
          InputProps={{
            inputProps: {
              maxLength: MAXIMUM_PASSWORD_LENGTH,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box>
        <InputField
          value={data.verifyPassword}
          onChange={_handleChange}
          margin="dense"
          name="verifyPassword"
          title="Verify Password"
          require
          fullWidth
          type={showVerifyPassword ? 'text' : 'password'}
          error={data.password !== data.verifyPassword}
          errormessage={
            data.verifyPassword === ''
              ? TEXT_REQUIRE
              : data.password !== data.verifyPassword && ERROR_VERIFY_PASSWORD_MESSAGE
          }
          InputProps={{
            inputProps: {
              maxLength: MAXIMUM_PASSWORD_LENGTH,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowVerifyPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {showVerifyPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </CustomFormDialog>
  );
};

export default CreateUser;
