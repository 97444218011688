import { Box, Button, TextField, Typography, MenuItem } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import styled, { css } from 'styled-components';

export const GeneralInformationContainer = styled(Box)<{ variants?: 'user-detail' }>`
  ${({ theme, variants }) => css`
    padding: 32px 109px 0 109px;
    ${variants === 'user-detail' &&
    css`
      padding: 0;
    `}
  `}
`;

export const ActionButton = styled(Button)<{
  variants: 'ghost' | 'yellow' | 'outlined';
  isBig?: boolean;
  disabled?: boolean;
}>`
  ${({ theme, variants, isBig, disabled }) => css`
    font-size: ${theme.fonts.textSBold.fontSize};
    font-weight: ${theme.fonts.textSBold.fontWeight};
    width: ${isBig ? 240 : 187}px;
    height: 48px;
    border-radius: 6px;
    background-color: ${variants === 'yellow' ? theme.palette.yellow?.[900] : theme.palette.common.white};
    color: ${variants === 'yellow' ? theme.palette.common.white : theme.palette.gray?.[60]};
    ${variants === 'ghost' &&
    css`
      border: 1px solid ${theme.palette.lightGray?.[25]};
      color: ${theme.palette.gray?.[1000]};
    `}
    text-transform: capitalize;
    ${variants === 'yellow' &&
    css`
      &:hover {
        background-color: ${theme.palette.yellow?.[900]};
      }
    `}
    ${variants === 'outlined' &&
    css`
      border: 1px solid ${theme.palette.yellow?.[900]};
      color: ${theme.palette.yellow?.[900]};
    `}
      ${disabled &&
    css`
      && {
        background-color: #dcdcdd;
        border-color: transparent;
        span {
          pointer-events: none;
        }
        span p {
          pointer-events: none;
        }
      }
    `}
  `}
`;
export const Label = styled(Box)<{ required?: boolean }>`
  ${({ theme, required }) => css`
    font-size: ${theme.fonts.header6.fontSize};
    font-weight: ${theme.fonts.header6.fontWeight};
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.6);
    ${required &&
    css`
      &::after {
        content: '*';
        display: inline-block;
        color: red;
        margin-left: 3px;
      }
    `};
  `}
`;
export const FormTextField = styled(TextField)<{ ellipsis?: boolean }>`
  ${({ theme, disabled, ellipsis, error }) => css`
    .MuiOutlinedInput-root {
      border-radius: 6px !important;
      background-color: ${disabled ? 'rgba(0, 0, 0, 0.1)' : 'white'};
    }
    & .MuiInputBase-root {
      height: 40px;
    }

    & .MuiSelect-root {
      /* color: ${theme.palette.gray?.[1000]}; */
      width: ${ellipsis ? '128px' : '100%'};
    }
    .MuiOutlinedInput-root {
      border-radius: 6px !important;
      background-color: ${disabled ? 'rgba(0, 0, 0, 0.1)' : 'white'};
    }
    & .MuiInputBase-root {
      height: 40px;
    }

    & fieldset {
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      ${error &&
      css`
        border: 1px solid red !important;
      `}
    }
    input {
      /* color: rgba(113, 128, 150, 1); */
    }
    & .MuiFormHelperText-root {
      margin-left: 0;
    }
  `};
`;

export const FormMenuItem = styled(MenuItem)`
  font-weight: 300 !important;
`;

export const GeneralInformationFormContainer = styled(Box)<{ variants?: 'user-detail' }>`
  ${({ variants }) => css`
    padding: 0 24px 0;
    padding-bottom: 32px;
    ${variants === 'user-detail' &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}
  `}
`;

export const GeneralInformationPopupTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header3.fontSize};
    font-weight: ${theme.fonts.header3.fontWeight};
    line-height: 32px;
  `}
`;

export const GeneralInformationDatePicker = styled(KeyboardDatePicker)<{ inputFontSize?: number }>`
  ${({ theme, disabled, error, inputFontSize }) => css`
    .MuiOutlinedInput-root {
      border-radius: 6px !important;
      background-color: ${disabled ? 'rgba(0, 0, 0, 0.1)' : 'white'};
    }
    & .MuiInputBase-root {
      height: 40px;
    }
    & .MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }
    & input {
      font-weight: ${theme.fonts.textM.fontWeight};
      font-size: ${inputFontSize ? `${inputFontSize}px` : theme.fonts.textM.fontSize};
    }
    & fieldset {
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      ${error &&
      css`
        border: 1px solid red !important;
      `}
    }
    & .MuiFormHelperText-root {
      margin-left: 0;
    }
  `}
`;
export const GeneralInformationTimePicker = styled(KeyboardTimePicker)`
  ${({ theme, disabled, error }) => css`
    .MuiOutlinedInput-root {
      border-radius: 6px !important;
      background-color: ${disabled ? 'rgba(0, 0, 0, 0.1)' : 'white'};
    }
    & .MuiInputBase-root {
      height: 40px;
    }
    & .MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }
    & input {
      font-size: 10px;
      font-weight: ${theme.fonts.textM.fontWeight};
      font-size: ${theme.fonts.textM.fontSize};
    }
    & fieldset {
      border: 1px solid rgba(0, 0, 0, 0.1) !important;
      ${error &&
      css`
        border: 1px solid red !important;
      `}
    }
    & .MuiFormHelperText-root {
      margin-left: 0;
    }
  `}
`;

export const ErrorMessage = styled(Box)`
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
`;
