import React from 'react';

import { Box, CircularProgress, FormControlLabel, IconButton, RadioGroup } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useFetchProfileQuery } from 'src/api/endpoints/profiles.endpoint';
import { NASSRadio as Radio } from 'src/components';
import ConfirmPopup from 'src/components/confirm';
import { ACCOUNT_TYPE, USER_ROLE, BLOCKING_REASON } from 'src/constants/users.constants';
import CloseIcon from 'src/icons/close';
import { Title } from 'src/pages/profile/components/additional-information.styled';
import {
  ActionButton,
  FormTextField,
  FormMenuItem,
  Label,
} from 'src/pages/profile/components/general-information.styled';
import ProfilePopupLayout from 'src/pages/profile/layout/profile-popup.layout';
import { HeaderSticky, FooterSticky } from 'src/pages/profile/shared/additional-information.popup';
import { Close } from 'src/pages/user-management/shared/user-detail.component';
import { IUser } from 'src/services/users';

interface IAccountSettingPopupProps {
  profile: IUser;
  onSave: (updateValues: any) => void;
  onClose: () => void;
  isSaveLoading?: boolean;
  open: boolean;
  isUpdatingRole?: boolean;
}

const AccountSettingPopup = ({
  open,
  isSaveLoading,
  profile,
  isUpdatingRole,
  onSave,
  onClose,
}: IAccountSettingPopupProps) => {
  const [isShowConfirmPopup, setShowConfirmPopup] = React.useState(false);
  const { data: me } = useFetchProfileQuery();

  const DISABLE_FIELDS = {
    ACCOUNT_TYPE: me?.data?.roleName === 'it-admin' || profile?.accountType === 'lifetime-membership',
    VERIFICATION_STATUS: me?.data?.roleName === 'it-admin',
    ACCOUNT_STATUS: me?.data?.roleName === 'it-admin',
    BLOCKING_REASON: me?.data?.roleName === 'it-admin',
    IS_COMMITTEE_MEMBER: me?.data?.roleName === 'membership-admin' || me?.data?.roleName === 'it-admin',
    ADMIN_ROLE:
      me?.data?.roleName === 'membership-admin' ||
      me?.data?.roleName === 'secretariat-admin' ||
      profile?.id === me?.data?.id,
  };
  const formik = useFormik({
    initialValues: {
      accountType: profile?.accountType,
      accountStatus: profile?.accountStatus,
      verificationStatus: profile?.verificationStatus,
      committeeMember: profile?.committeeMember,
      roleName: profile?.roleName,
      reason: profile?.reason,
    },
    validationSchema: Yup.object({
      verificationStatus: Yup.string().oneOf(['verified', 'unverified']).optional(),
      accountStatus: Yup.string().optional(),
      reason: Yup.mixed().when('accountStatus', {
        is: (val: any) => {
          return val === 'blocked';
        },
        then: Yup.string().required('This field is required').notOneOf(['default'], 'This field is required'),
        otherwise: Yup.string().notRequired().nullable(),
      }),
    }),
    onSubmit: (values) => {
      onSave({
        ...profile,
        ...values,
        roleName: values.roleName === 'default' || '' ? null : values.roleName,
        reason: values.accountStatus === 'active' ? null : values.reason,
      });
    },
  });

  React.useEffect(() => {
    if (formik.values.accountType === 'lifetime-membership') {
      formik.setFieldValue('verificationStatus', 'verified');
    }
  }, [formik.values.accountType]);

  if (!profile) return <div>Loading...</div>;

  function handleTogglePopup() {
    setShowConfirmPopup((status) => !status);
  }
  function handleFormSubmit() {
    handleTogglePopup();
    formik.submitForm();
  }
  const handleAccountStatusChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    formik.setFieldValue('accountStatus', value);
  };
  function handleOkConfirmPopup() {
    if (formik.isValid) {
      handleTogglePopup();
    }
  }

  return (
    <ProfilePopupLayout open={open} onClose={onClose}>
      <ConfirmPopup
        open={isShowConfirmPopup}
        title="Notification"
        content="Are you sure you want to save this change?"
        onCancel={handleTogglePopup}
        onOk={handleFormSubmit}
        btnCancelVariant="outlined"
        btnOkVariant="filled"
      />
      <form onSubmit={formik.handleSubmit}>
        <Box px={3}>
          <HeaderSticky>
            <Title>Account setting</Title>
            <Close onClick={onClose}>
              <CloseIcon fill="white" />
            </Close>
          </HeaderSticky>
          <Box>
            <Box mb={3}>
              <Label required>Account Type</Label>
              <FormTextField
                disabled={DISABLE_FIELDS.ACCOUNT_TYPE}
                select
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  },
                }}
                variant="outlined"
                fullWidth
                size="small"
                value={formik.values.accountType}
                onChange={formik.handleChange}
                name="accountType"
              >
                {profile.accountType === 'applicant'
                  ? ACCOUNT_TYPE.filter((item) => item.value !== 'youth-member').map(({ value, label }, idx) => (
                      <FormMenuItem key={idx} value={value}>
                        {label}
                      </FormMenuItem>
                    ))
                  : profile.accountType === 'youth-member'
                  ? ACCOUNT_TYPE.filter((item) => item.value !== 'applicant').map(({ value, label }, idx) => (
                      <FormMenuItem key={idx} value={value}>
                        {label}
                      </FormMenuItem>
                    ))
                  : ACCOUNT_TYPE.map(({ value, label }, idx) => (
                      <FormMenuItem key={idx} value={value}>
                        {label}
                      </FormMenuItem>
                    ))}
              </FormTextField>
            </Box>
            <Box mb={3}>
              <Label required>Verification Status</Label>
              <RadioGroup
                value={formik.values.verificationStatus}
                onChange={formik.handleChange}
                row
                name="verificationStatus"
              >
                <Box width={210}>
                  <FormControlLabel
                    disabled={formik.values.accountType === 'lifetime-membership' || DISABLE_FIELDS.VERIFICATION_STATUS}
                    value="verified"
                    control={
                      <Radio
                        disabled={
                          formik.values.accountType === 'lifetime-membership' || DISABLE_FIELDS.VERIFICATION_STATUS
                        }
                      />
                    }
                    label="Verified"
                  />
                </Box>
                <Box width={210}>
                  <FormControlLabel
                    disabled={formik.values.accountType === 'lifetime-membership' || DISABLE_FIELDS.VERIFICATION_STATUS}
                    value="unverified"
                    control={
                      <Radio
                        disabled={
                          formik.values.accountType === 'lifetime-membership' || DISABLE_FIELDS.VERIFICATION_STATUS
                        }
                      />
                    }
                    label="Unverified"
                  />
                </Box>
              </RadioGroup>
            </Box>
            <Box mb={3}>
              <Label required>Account Status</Label>
              <RadioGroup
                value={formik.values.accountStatus}
                onChange={handleAccountStatusChange}
                name="accountStatus"
                row
              >
                <Box width={210}>
                  <FormControlLabel
                    disabled={DISABLE_FIELDS.ACCOUNT_STATUS}
                    value="active"
                    control={<Radio />}
                    label="Active"
                  />
                </Box>
                <Box width={210}>
                  <FormControlLabel
                    disabled={DISABLE_FIELDS.ACCOUNT_STATUS}
                    value="blocked"
                    control={<Radio />}
                    label="Block"
                  />
                </Box>
              </RadioGroup>
            </Box>
            <Box mb={3}>
              <Label>Blocking reason</Label>
              <FormTextField
                disabled={formik.values.accountStatus === 'active' || DISABLE_FIELDS.ACCOUNT_STATUS}
                variant="outlined"
                name="reason"
                fullWidth
                size="small"
                onChange={(e) => {
                  formik.setFieldValue('reason', e.target.value);
                }}
                value={formik.values.accountStatus === 'active' ? 'default' : formik.values.reason}
                select
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  },
                }}
                onBlur={formik.handleBlur}
                error={formik.values.accountStatus === 'blocked' && (formik.touched.reason || !!formik.errors.reason)}
                helperText={
                  formik.values.accountStatus === 'blocked' && !!formik.errors.reason && 'This field is required'
                }
              >
                <FormMenuItem value="default">--- Choose a blocking reason ---</FormMenuItem>
                {BLOCKING_REASON.map(({ value, label }, idx) => (
                  <FormMenuItem key={idx} value={value}>
                    {label}
                  </FormMenuItem>
                ))}
              </FormTextField>
            </Box>
            <Box mb={3}>
              <Label required>Is committee member</Label>
              <RadioGroup
                value={formik.values.committeeMember}
                name="committeeMember"
                onChange={formik.handleChange}
                row
              >
                <Box width={210}>
                  <FormControlLabel
                    disabled={DISABLE_FIELDS.IS_COMMITTEE_MEMBER}
                    value="yes"
                    control={<Radio />}
                    label="Yes"
                  />
                </Box>
                <Box width={210}>
                  <FormControlLabel
                    disabled={DISABLE_FIELDS.IS_COMMITTEE_MEMBER}
                    value="no"
                    control={<Radio />}
                    label="No"
                  />
                </Box>
              </RadioGroup>
            </Box>
            <Box mb={3}>
              <Label>Admin’s role</Label>
              <FormTextField
                disabled={DISABLE_FIELDS.ADMIN_ROLE}
                variant="outlined"
                name="roleName"
                fullWidth
                size="small"
                onChange={formik.handleChange}
                select
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  },
                }}
                defaultValue="default"
                value={formik.values.roleName}
              >
                <FormMenuItem value="default">--- Choose a role ---</FormMenuItem>
                {USER_ROLE.map(({ value, label }, idx) => (
                  <FormMenuItem key={idx} value={value}>
                    {label}
                  </FormMenuItem>
                ))}
              </FormTextField>
            </Box>
          </Box>
          <FooterSticky>
            <ActionButton onClick={onClose} variants="outlined">
              Cancel
            </ActionButton>
            <ActionButton disabled={isSaveLoading || isUpdatingRole} onClick={handleOkConfirmPopup} variants="yellow">
              {(isSaveLoading || isUpdatingRole) && (
                <IconButton>
                  <CircularProgress size={20} />
                </IconButton>
              )}
              Save
            </ActionButton>
          </FooterSticky>
        </Box>
      </form>
    </ProfilePopupLayout>
  );
};

export default AccountSettingPopup;
