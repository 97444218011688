import { CALL_SUCCESS } from 'src/constants/common';
import { IResponseList } from 'src/types';

export const REGISTRATION_STATUS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Bounced',
    value: 'bounce',
  },
  {
    label: 'Delivered',
    value: 'delivered',
  },
];
export const GUEST_ACTIONS = [
  {
    label: 'Resend Email',
    value: 'send-email',
  },
];

export const DEFAULT_RESPONSE: IResponseList<any> = {
  data: {
    items: [],
    meta: {
      currentPage: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  message: '',
  statusCode: CALL_SUCCESS,
};
