import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { CustomButton } from 'src/components';
import UnSuccess from 'src/medias/icons/Fail.svg';
import Success from 'src/medias/icons/icon-success.png';
import { registerVolunteer } from 'src/services/events';

function RegisterVolunteer() {
  const url = new URLSearchParams(window.location.search);
  const token = url.get('token');
  const history = useHistory();
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const registerVolunteerPost = async (token: string) => {
    setLoading(true);
    const response = await registerVolunteer(token);
    if (response && response.statusCode === 200) {
      history.replace('/users-event/change-status?status=success');
      setIsSuccess(true);
      setIsError(false);
    } else {
      history.replace('/users-event/change-status?status=fail');
      setIsError(true);
      setIsSuccess(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (token) {
      registerVolunteerPost(token);
    }
  }, [token]);
  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={40}>
      {loading && <Typography>Loading...</Typography>}

      <Container display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        {isSuccess && !loading && (
          <>
            <IconCustom src={Success} />
            <Text>Confirm successfully!</Text>
            <LinkCustom to="/">
              <CustomButton>Go Back To Home Page</CustomButton>
            </LinkCustom>
          </>
        )}
        {isError && !loading && (
          <>
            <IconCustom src={UnSuccess} />
            <Text>You’re no longer on the confirmed list!</Text>
            <LinkCustom to="/">
              <CustomButton>Go Back To Home Page</CustomButton>
            </LinkCustom>
          </>
        )}
      </Container>
    </Box>
  );
}

export default RegisterVolunteer;

const IconCustom = styled.img`
  width: 100px;
  margin: auto;
`;
const Text = styled(Typography)`
  ${({ theme }) => ({
    ...theme.fonts.header3,
  })}
`;
const Container = styled(Box)`
  gap: 60px;
`;
const LinkCustom = styled(Link)`
  color: white;
  text-decoration: none;
`;
