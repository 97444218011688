import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { CustomButton } from 'src/components';
import { HeaderMobile } from 'src/components/headerMobile';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import CheckIcon from 'src/medias/icons/check-icon.svg';
import { checkInCheckOut } from 'src/services/events';
import { getMe } from 'src/services/users';
import { selectLoginState, selectUserProfile } from 'src/store/authentication/selector';
import { saveProfile } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';
import useResponsive from 'src/utils/responsive';

const params = new URLSearchParams(window.location.search);
let type: string | null;
let eventId: string | null;

if (params) {
  eventId = params.get('eventId');
  type = params.get('type');
}

export const CheckInCheckOutScreen = () => {
  const { isMobile, isTabletOrMobile } = useResponsive();
  const isLogin = useSelector(selectLoginState);
  const dispatch = useAppDispatch();
  const userProfile = useSelector(selectUserProfile);

  const [isActionSuccess, setActionSuccess] = useState(false);
  const [isActionFailed, setActionFailed] = useState(false);
  const [messageResponse, setMessageResponse] = useState('');
  const [loading, setLoading] = useState(true);

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const _gotoHome = () => {
    window.location.href = '/';
  };

  const getData = async () => {
    const response = await getMe();
    if (response && response?.statusCode === CALL_SUCCESS) {
      dispatch(saveProfile(response?.data));
    }
  };

  const handleCheckInOut = async () => {
    if (eventId && type) {
      const responseCheckIn = await checkInCheckOut(eventId, { type });
      if (responseCheckIn?.statusCode === CALL_SUCCESS) {
        setLoading(false);
        setActionSuccess(true);
        setMessageResponse(responseCheckIn?.message);
      } else {
        window.location.href = '/';
        setActionFailed(true);
        setMessageResponse(responseCheckIn?.message);
      }
    }
  };

  useEffect(() => {
    if (isLogin) {
      Promise.all([getData(), handleCheckInOut()]);
    }
  }, [isLogin]);
  return (
    <Box>
      {loading ? null : (
        <Box>
          {isTabletOrMobile && type ? (
            <Box minHeight={isMobile ? '896px' : '1194px'} display="flex" flexDirection="column">
              <HeaderMobile
                title={type === 'check-in' ? `Volunteer Check-in` : `Volunteer Check-out`}
                userProfile={userProfile}
              />
              <Box display="flex" flex={1} justifyContent="center" alignItems="center" flexDirection="column">
                <ImageStyled src={CheckIcon} />
                <TypographyStyled>
                  {type === 'check-in' ? `Check-in successfully` : `Check-out successfully`}
                </TypographyStyled>
              </Box>
              <Box display="flex" mb={4} justifyContent="center">
                <CustomButton onClick={_gotoHome} style={{ width: '94%' }}>
                  Go To Homepage
                </CustomButton>
              </Box>
            </Box>
          ) : null}
        </Box>
      )}

      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </Box>
  );
};

const ImageStyled = styled.img(() => ({}));

const TypographyStyled = styled(Typography)(() => ({
  color: 'rgba(0, 0, 0, 0.6)',
  fontSize: 24,
  fontWeight: 700,
  marginTop: 24,
}));
