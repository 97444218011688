import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { HeaderMobile } from 'src/components/headerMobile';
import { Header } from 'src/layouts/admin/components/header';
import { IDataDetail, ISearchPayment } from 'src/services/payment';
import { selectUserProfile } from 'src/store/authentication/selector';
import useResponsive from 'src/utils/responsive';

import ListPayment from './list-payment/ListPayment';
import SearchPayment from './search-payment/SearchPayment';
import { CustomBox, HeaderGroup, BigTitle, defaultSearch, PaymentManagementContainer, BoxContainer } from './type';

function PaymentManagement() {
  const userProfile = useSelector(selectUserProfile);
  const history = useHistory();
  const [data, setData] = React.useState<IDataDetail[]>([]);
  const [loading, setLoading] = useState(false);
  const [isShowPaymentDetail, setShowPaymentDetail] = useState(false);
  const [paymentDetailId, setPaymentDetailId] = useState<string>();
  const [search, setSearch] = useState<ISearchPayment>(defaultSearch);
  const [isOnSearch, setIsOnSearch] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<ISearchPayment>(defaultSearch);
  const responsive = useResponsive();

  const _onSearch = (searchData: ISearchPayment) => () => {
    setIsOnSearch(true);
    setSearch(searchData);
  };
  const _onReset = () => {
    // setIsOnSearch(true);
    setSearch(defaultSearch);
    setSearchValue(defaultSearch);
  };
  useEffect(() => {
    if (userProfile && userProfile.roleName !== 'accountant') {
      history.push('/not-access');
    }
  }, [userProfile]);
  return (
    <Box width="100%" overflow="auto">
      <BoxContainer minHeight={500} p={4} bgcolor="white" boxShadow={'1px 1px 4px #9E9E9E'} borderRadius={4}>
        {responsive.isTabletOrMobile && <HeaderMobile title="Payment Management" userProfile={userProfile} />}
        {responsive.isDesktopOrLaptop && <Header />}
        <Box pt={responsive.isTabletOrMobile ? 15 : 3.75} />
        <PaymentManagementContainer responsive={responsive}>
          <CustomBox>
            <HeaderGroup display="flex" justifyContent="space-between" alignItems="center">
              <BigTitle>Payment Management</BigTitle>
            </HeaderGroup>
          </CustomBox>
          <SearchPayment
            search={search}
            setSearch={setSearch}
            _onSearch={_onSearch}
            loading={loading}
            _onReset={_onReset}
            _setSearchValue={setSearchValue}
          />
          <ListPayment
            data={data}
            setShowPaymentDetail={setShowPaymentDetail}
            isShowPaymentDetail={isShowPaymentDetail}
            setPaymentDetailId={setPaymentDetailId}
            paymentDetailId={paymentDetailId}
            loading={loading}
            setLoading={setLoading}
            searchData={search}
            setData={setData}
            setSearch={setSearch}
            isOnSearch={isOnSearch}
            _setIsOnSearch={setIsOnSearch}
            searchValue={searchValue}
          />
        </PaymentManagementContainer>
      </BoxContainer>
    </Box>
  );
}

export default PaymentManagement;
