import * as React from 'react';

import { CardMedia, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FORMAT_DATE_DMY } from 'src/constants/common';
import { IPost } from 'src/services/news';

export default function NewsGroup({ postData }: { postData: IPost[] }) {
  let leftPost;
  let rightPost;
  if (postData) {
    leftPost = postData[0];
    rightPost = postData[1];
  }
  return (
    <NewsGroupContainer
      container
      style={{ maxWidth: 1440, marginLeft: 'auto', marginRight: 'auto', overflow: 'hidden' }}
    >
      {postData && (
        <Grid item container direction="row" spacing={3} alignItems="center">
          <LeftCardCustom item container md={6} lg={6}>
            <NavLinkStyled to={`/cn/news/${leftPost?.id}`}>
              <CardMediaCustom photo={leftPost?.coverPhoto} />
              <TitleCustom>
                <BigTime>{moment(+leftPost?.publishTime!).format(FORMAT_DATE_DMY)}</BigTime>
                <BigTitle>{leftPost?.title}</BigTitle>
              </TitleCustom>
            </NavLinkStyled>
          </LeftCardCustom>
          <RightCardCustom item container md={6} lg={6}>
            <NavLinkLeftStyled to={`/cn/news/${rightPost?.id}`}>
              <CardMedia
                component="img"
                max-width="100%"
                width="auto"
                height="auto"
                alt="green iguana"
                src={rightPost?.coverPhoto}
                style={{ objectFit: 'contain' }}
              />
              <RightDescription item container direction="column">
                <Time>{moment(+rightPost?.publishTime!).format(FORMAT_DATE_DMY)}</Time>
                <Title>{rightPost?.title}</Title>
                <NewsDescription>{rightPost?.shortDescription}</NewsDescription>
              </RightDescription>
            </NavLinkLeftStyled>
          </RightCardCustom>
        </Grid>
      )}
    </NewsGroupContainer>
  );
}
const TitleCustom = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header5,
  position: 'absolute',
  color: '#ffffff',
  bottom: '36px',
  left: '48px',
}));
const CardMediaCustom = styled(CardMedia)<{ photo?: string }>(({ theme, photo }) => ({
  maxWidth: '100%',
  width: '100%',
  height: '100%',
  backgroundImage: `url(${photo})`,
  display: 'block',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundBlendMode: 'color',
  backgroundColor: '#00000024',
}));
const RightCardCustom = styled(Grid)`
  ${({ theme }) => css`
    && {
      height: 100%;
    }
  `}
`;
const LeftCardCustom = styled(Grid)`
  && {
    height: 100%;
    position: relative;
  }
`;
const RightDescription = styled(Grid)`
  && {
    padding: 24px;
    background-color: #f9f9f4;
  }
`;
const NewsGroupContainer = styled(Grid)`
  && {
    margin-bottom: 24px;
  }
`;
const Time = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textSs,
  color: theme.palette.yellow?.[900],
}));
const BigTime = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header4,
  color: theme.palette.common.white,
}));
const BigTitle = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header4S,
  color: theme.palette.common.white,
}));
const Title = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header5,
  marginTop: '16px',
  color: theme.palette.common.black,
}));
const NewsDescription = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textS,
  marginTop: '16px',
  color: theme.palette.gray?.[60],
  backgroundColor: 'inherit',
}));
const NavLinkStyled = styled(NavLink)`
  && {
    text-decoration: none;
    width: 100%;
  }
`;
const NavLinkLeftStyled = styled(NavLinkStyled)`
  && {
    background-color: white;
  }
`;
