import React from 'react';

import { Box } from '@material-ui/core';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import ProfileTabs from 'src/pages/profile/shared/profile.tabs';
import { IEvent } from 'src/services/events';

import GuestList from './GuestList';
import GuestStatistic from './GuestStatistic';
import { GuestContainer } from './index.styled';

interface IGuestProps {
  event: IEvent;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}
const Guest = ({ event }: IGuestProps) => {
  useScrollToTop();
  const tabs = [
    { label: 'Guest List', disabled: false },
    { label: 'Statistic', disabled: false },
  ];
  const panels = [<GuestList event={event} />, <GuestStatistic event={event} />];

  return (
    <Box>
      <GuestContainer>
        <ProfileTabs panels={panels} tabs={tabs} />
      </GuestContainer>
    </Box>
  );
};

export default Guest;
