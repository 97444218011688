import React from 'react';

import { youthMembershipFAQ } from '../../data';
import Membership from '../../shared/membership.component';

const MembershipYouth = () => {
  return <Membership data={youthMembershipFAQ} />;
};

export default MembershipYouth;
