import React from 'react';

import { Box, Collapse, IconButton, makeStyles, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { CustomDrawer } from 'src/components';

import { menuAboutUs, menuMembership } from '../type';

import BasicMenu from './BasicMenu';

interface IRightDrawer {
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  open: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isLogin?: boolean;
  userProfile?: any;
  redirectURL?: string;
}
const useStyles = makeStyles((theme) => ({
  container: {
    transition: '0.4s',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));
const BoxStyled = styled(Box)<{ active?: boolean }>`
  &.MuiBox-root {
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: calc(100% - 32px);
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      bottom: 0;
      left: 18px;
      right: 32px;
    }
  }
  ${({ theme, active }) => css`
    position: relative;
    width: 95%;
    padding-right: 16px;
    height: 60px;
    cursor: pointer;
    ${active &&
    css`
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
    `}

    .MuiButtonBase-root.MuiIconButton-root {
      padding: 0;
    }
  `}
`;
const CustomDrawerCustom = styled(CustomDrawer)`
  &.MuiDrawer-paperAnchorLeft {
    overflow: hidden;
  }
`;
const NavLinkResponsiveStyled = styled(NavLink)`
  && {
    font-size: 16px;
    text-decoration: none;
    height: 100%;
    margin-right: 15px;
    color: rgba(0, 0, 0, 0.6);
  }
  ${({ theme }) => css`
    position: relative;
    &.header__nav-active {
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
    }
  `}
`;
const LiNavLink = styled(NavLink)`
  && {
    font-size: 16px;
    text-decoration: none;
    height: 100%;
    margin-right: 15px;
    color: rgba(0, 0, 0, 0.6);
    width: calc(100% - 32px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  ${({ theme }) => css`
    position: relative;
    &.header__nav-active {
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
    }
  `}
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const ExpandMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: disc;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 0;
  margin: 0;
  margin-left: 54px;
`;
const ExpandItem = styled.li`
  ${({ theme }) => ({
    ...theme.fonts.textS,
    height: '60px',
  })}
`;
const LanguageBox = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 24px;
  gap: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;
const Text = styled(Typography)<{ isMobile?: boolean }>`
  ${({ theme, isMobile }) => ({
    ...theme.fonts.textSSBold,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: isMobile ? '18px' : '24px',
    color: '#00000080',
    userSelect: 'none',
    cursor: 'default',
  })}
  span {
    ${({ theme, isMobile }) => ({
      ...theme.fonts.textSS,
      fontSize: isMobile ? '18px' : '24px',
      userSelect: 'all',
      cursor: 'pointer',
    })}
  }
`;
const NavLinkStyled = styled(NavLink)`
  text-decoration: none;
`;
function SideMenuChinese({ toggleDrawer, open, isMobile }: IRightDrawer) {
  const classes = useStyles();
  const [expandedAboutUs, setExpandedAboutUs] = React.useState(false);
  const [expandedMembership, setExpandedMembership] = React.useState(false);

  const ExpandMembershipClick = () => {
    setExpandedMembership(!expandedMembership);
    setExpandedAboutUs(false);
  };
  const ExpandAboutUsClick = () => {
    setExpandedAboutUs(true);
    setExpandedMembership(false);
  };
  return (
    <CustomDrawerCustom anchor="left" open={open} onClose={toggleDrawer(false)}>
      <Box className={classes.container} width={isMobile ? 300 : 500} />
      <Box mt={10}>
        <BoxStyled>
          <NavLinkResponsiveStyled exact activeClassName="header__nav-active" to="/cn">
            <BasicMenu text={'主页'} link="/cn" grayColor isMobile />
          </NavLinkResponsiveStyled>
        </BoxStyled>
        <BoxStyled
          onClick={ExpandAboutUsClick}
          active={window.location.pathname.includes('about-us')}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <BasicMenu text={'关于校友会'} grayColor />
          <IconButton
            className={expandedAboutUs ? classes.expand : classes.expandOpen}
            aria-expanded={expandedAboutUs}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </BoxStyled>
        <Collapse in={expandedAboutUs} timeout="auto" unmountOnExit>
          <ExpandMenu>
            {menuAboutUs.map((item) => (
              <ExpandItem>
                <LiNavLink to={item.link}>{item.title}</LiNavLink>
              </ExpandItem>
            ))}
          </ExpandMenu>
        </Collapse>
        <BoxStyled>
          <NavLinkResponsiveStyled activeClassName="header__nav-active" to="/cn/events">
            <BasicMenu text={'活动与项目'} link="/cn/events" grayColor />
          </NavLinkResponsiveStyled>
        </BoxStyled>
        <BoxStyled>
          <NavLinkResponsiveStyled activeClassName="header__nav-active" to="/cn/news">
            <BasicMenu text={'消息'} link="/cn/news" grayColor isMobile />
          </NavLinkResponsiveStyled>
        </BoxStyled>
        <BoxStyled
          onClick={ExpandMembershipClick}
          active={window.location.pathname.includes('membership')}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <BasicMenu text={'会籍'} grayColor />
          <IconButton
            className={expandedMembership ? classes.expand : classes.expandOpen}
            aria-expanded={expandedMembership}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </BoxStyled>
        <Collapse in={expandedMembership} timeout="auto" unmountOnExit>
          <ExpandMenu>
            {menuMembership.map((item) => (
              <ExpandItem>
                <LiNavLink to={item.link}>{item.title}</LiNavLink>
              </ExpandItem>
            ))}
          </ExpandMenu>
        </Collapse>
        <BoxStyled>
          <NavLinkResponsiveStyled exact activeClassName="header__nav-active" to="/cn/contact-us">
            <BasicMenu text={'联络我们'} link="/cn/contact-us" grayColor isMobile />
          </NavLinkResponsiveStyled>
        </BoxStyled>
      </Box>
      <LanguageBox>
        <NavLinkStyled to={'/'}>
          <Text isMobile={isMobile}>
            <span>English</span>
          </Text>
        </NavLinkStyled>
        <Text isMobile={isMobile}>|</Text>
        <NavLinkStyled to="/cn">
          <Text isMobile={isMobile}>中文</Text>
        </NavLinkStyled>
      </LanguageBox>
    </CustomDrawerCustom>
  );
}

export default SideMenuChinese;
