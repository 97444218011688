import React, { useState } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import moment from 'moment';
import styled, { css } from 'styled-components';

import { CustomButton, CustomDialog } from 'src/components';
import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import { CALL_SUCCESS } from 'src/constants/common';
import UpcomingBackImage from 'src/medias/images/upcoming_back.svg';
import UpcomingFrontImage from 'src/medias/images/upcoming_front.svg';
import { cancelVolunteer } from 'src/services/events';
import { IUsersToEvent } from 'src/services/users';

import MyVolunteerDetail from '../MyVolunteerDetail';
import { DEFAULT_IMAGE, FORMAT_TIME } from '../types';

interface IMenuActive {
  text: string;
  value: string;
}

const StyledLocation = styled(Typography)`
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;
const UpcomingImage = styled.img<{ variants: 'front' | 'back' }>`
  ${({ theme, variants }) => css`
    position: absolute;
    left: 0;
    top: 0;

    ${variants === 'front' &&
    css`
      left: -40px;
      top: -30px;
      z-index: 1;
    `}
    ${variants === 'back' &&
    css`
      left: -42px;
      top: -30px;
      z-index: -1;
    `}
  `}
`;
const ImgCustom = styled(Box)<{ image?: string }>`
  background-image: ${({ image }) => `url(${image})`};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
`;
const EventBoxCustom = styled(Box)`
  position: relative;
  box-shadow: 0px 3px 20px rgb(0 0 0 / 15%);
  background: #f9f9f4;
  &:first-child {
    margin-top: 50px;
  }
`;
const GapBox = styled(Box)`
  gap: 10px;
`;
const Title = styled(Typography)`
  ${({ theme }) => ({
    ...theme.fonts.textXX,
    fontWeight: 'bold',
    color: theme.palette.yellow?.[900],
  })}
`;
const DateDuration = styled(Typography)`
  ${({ theme }) => ({
    ...theme.fonts.textM,
  })}
`;
const ButtonGroup = styled(Box)`
  gap: 24px;
`;
const DetailButton = styled(CustomButton)`
  ${({ theme }) => ({})}
  && {
    background-color: transparent;
    color: #979168;
    border: 1px solid #979168;
  }
`;
export const ButtonStatus = styled(Button)<{
  variants?: 'Completed' | 'Confirm' | 'Pending' | 'Incomplete' | 'Draft';
}>`
  ${({ theme, variants }) => css`
    pointer-events: none;
    width: 112px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: ${variants === 'Completed'
      ? '#0FA958'
      : variants === 'Confirm'
      ? '#979168'
      : variants === 'Pending'
      ? '#EA7D18'
      : variants === 'Incomplete'
      ? '#DC1A1A'
      : variants === 'Draft'
      ? '#718096'
      : null};
    font-size: 13px;
    color: white;
    text-transform: capitalize;
    &:hover {
      background-color: ${variants === 'Completed'
        ? '#0FA958'
        : variants === 'Confirm'
        ? '#979168'
        : variants === 'Pending'
        ? '#EA7D18'
        : variants === 'Incomplete'
        ? '#DC1A1A'
        : variants === 'Draft'
        ? '#718096'
        : null};
    }
  `}
`;
const ActionButton = styled(Button)<{ variants: 'able' | 'disable' }>`
  ${({ theme, variants }) => css`
    font-size: ${theme.fonts.textM.fontSize};
    line-height: ${theme.fonts.textM.lineHeight};
    font-weight: 500;
    color: ${theme.palette.common.white};
    width: 160px;
    height: 44px;
    border-radius: 6px;
    text-transform: capitalize;
    ${variants === 'able' &&
    css`
      background-color: ${theme.palette.red?.[150]};
      &:hover {
        background-color: ${theme.palette.red?.[150]};
      }
    `}
    ${variants === 'disable' &&
    css`
      pointer-events: none;
      background-color: #0000001a;
      &:hover {
        background-color: #0000001a;
      }
    `};
  `}
`;

function EventBox({
  event,
  setMenuActive,
  menuActive,
}: {
  event: IUsersToEvent;
  setMenuActive: React.Dispatch<React.SetStateAction<IMenuActive>>;
  menuActive: IMenuActive;
}) {
  const [isPendingEvent, setIsPendingEvent] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setIsLoading] = useState<boolean>();
  const [openConfirmBox, setOpenConfirmBox] = useState<boolean>(false);

  const checkVariants = () => {
    let variants: 'Completed' | 'Confirm' | 'Pending' | 'Incomplete' | 'Draft' =
      event?.attendanceStatus === 'completed'
        ? 'Completed'
        : event?.attendanceStatus === 'pending'
        ? 'Pending'
        : event?.attendanceStatus === 'incomplete'
        ? 'Incomplete'
        : event?.attendanceStatus === 'draft'
        ? 'Draft'
        : 'Confirm';
    return variants;
  };
  React.useEffect(() => {
    if (event?.event.status === 'pending') {
      setIsPendingEvent(true);
    } else {
      setIsPendingEvent(false);
    }
  }, [event]);

  function handleClose() {
    setOpen(false);
  }
  function handleClickOpen() {
    setOpen(true);
  }
  const cancelRegistration = async () => {
    setIsLoading(true);
    const newMenuValue = { ...menuActive };

    const response = await cancelVolunteer(event?.event.id);
    if (response.statusCode === CALL_SUCCESS) {
      setMenuActive(newMenuValue);
    }
    setIsLoading(false);
    setOpenConfirmBox(false);
  };
  const onOpenConfirmBox = () => {
    setOpenConfirmBox(true);
  };
  const onCloseModal = () => {
    setOpenConfirmBox(false);
  };
  return (
    <EventBoxCustom mb={6.25}>
      <LoadingCustom loading={loading} loadingIcon />
      <Box height="100%" display="grid" gridTemplateColumns="250px 1fr" gridColumnGap="">
        <Box position="relative" height="250px">
          {event?.event?.status === 'pending' && (
            <>
              <UpcomingImage src={UpcomingFrontImage} variants="front" />
              <UpcomingImage src={UpcomingBackImage} variants="back" />
            </>
          )}
          <ImgCustom image={event?.event.cover_photo || DEFAULT_IMAGE} />
        </Box>
        <Box
          display="flex"
          alignItems="flex-end"
          padding={3}
          width="100%"
          justifyContent="space-between"
          gridColumnGap="30px"
        >
          <GapBox display="flex" justifyContent="space-evenly" alignItems="flex-start" flexDirection="column">
            <Title>{event?.event?.title}</Title>
            <DateDuration>
              {moment(+event?.event.start_time)
                .local()
                .format(FORMAT_TIME)}{' '}
              -
              {moment(+event?.event.end_time)
                .local()
                .format(FORMAT_TIME)}
            </DateDuration>
            <StyledLocation>{event?.event?.location ? event?.event?.location : '-'}</StyledLocation>
            <ButtonGroup display="flex">
              <DetailButton onClick={handleClickOpen}>Detail</DetailButton>
              <ActionButton variants={isPendingEvent ? 'able' : 'disable'} onClick={onOpenConfirmBox}>
                Cancel Registration
              </ActionButton>
            </ButtonGroup>
          </GapBox>
          <GapBox display="flex" alignItems="flex-end" flexDirection="column">
            <Typography noWrap>
              Number of volunteer session: {event?.event?.duration && event?.event?.duration >= 1 ? 1 : 0.5}
            </Typography>
            <Typography noWrap>
              {event?.isInWaitlist ? 'You’re on the Waiting List' : 'You’re on the Confirmed List'}
            </Typography>
            <ButtonStatus variants={checkVariants()}>{event?.attendanceStatus}</ButtonStatus>
          </GapBox>
        </Box>
      </Box>
      <MyVolunteerDetail
        _onCloseModal={handleClose}
        open={open}
        event={event}
        status={<ButtonStatus variants={checkVariants()}>{event?.attendanceStatus}</ButtonStatus>}
      />
      <CustomDialog
        open={openConfirmBox}
        handleClose={onCloseModal}
        onSubmit={cancelRegistration}
        minHeight="auto"
        isConfirmPopup={true}
      >
        <Box p={4} display={'flex'} justifyContent={'center'}>
          Are you sure you want to cancel your registration?
        </Box>
      </CustomDialog>
    </EventBoxCustom>
  );
}
export default EventBox;
