import React from 'react';

import { Box, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';

import { CustomPagination, InputField } from 'src/components';
import NSAATable, { INSAAColumn, INSAADataSource } from 'src/components/table';
import { FORMAT_TIME_AM_PM } from 'src/constants/common';
import IconSuccess from 'src/medias/icons/icon-success.png';
import ViewIcon from 'src/medias/icons/view-icon.png';
import { Actions, IconButtonStyled, IconVerifyStyled, ImageIconStyled } from 'src/pages/user-management/types';
import { IQuizResponseList } from 'src/services/quiz/quiz.interface';
import { IMeta } from 'src/types';
import { intRegex } from 'src/utils/common';

import { ResponseDetail } from './details';

interface ITable {
  quiz: IQuizResponseList[];
  data: IMeta | undefined;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  loading?: boolean;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  totalGuest: number;
  currentPage: number;
}

const Listing = ({ quiz, data, currentPage, setCurrentPage, loading, limit, setLimit, totalGuest }: ITable) => {
  const [disableSearchPage, setDisableSearchPage] = React.useState(false);
  const [searchPage, setSearchPage] = React.useState('');
  const [isOpenDetail, setIsOpenDetail] = React.useState(false);
  const [itemSelected, setItemSelected] = React.useState<IQuizResponseList>();

  const handleChangePagination = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || e.target.value === '0') {
      setSearchPage('');
      return;
    }
    if (e.target.name === 'searchPage') {
      if (data?.totalPages) {
        if (!intRegex.test(e.target.value)) {
          setDisableSearchPage(true);
        } else {
          if (Number(e.target.value) > data.totalPages) {
            setDisableSearchPage(true);
          } else {
            setDisableSearchPage(false);
            setSearchPage(e.target.value);
          }
        }
      } else {
        setDisableSearchPage(true);
      }
    }
    if (e.target.name === 'records/page') {
      setLimit(Number(e.target.value));
      setSearchPage('');
      setCurrentPage(1);
    }
  };

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setSearchPage(valueTrimmed);
  };

  const onClickChangePage = () => {
    setCurrentPage(Number(searchPage));
  };

  const handleModal = () => {
    setIsOpenDetail(!isOpenDetail);
  };

  const columns = React.useMemo(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'No',
        dataIndex: 'No',
        title: 'No',
        width: 50,
      },
      {
        key: 'NSAAID',
        dataIndex: 'NSAAID',
        title: 'NSAA ID',
        width: 50,
      },
      {
        key: 'Name',
        dataIndex: 'Name',
        title: 'Name',
        width: 150,
        render: (value, record) => {
          return (
            <Typography>
              {(record.id?.user?.surname + ', ' + record.id?.user?.givenName)?.length > 40
                ? (record.id?.user?.surname + ', ' + record.id?.user?.givenName)?.slice(0, 40) + '...'
                : record.id?.user?.surname + ', ' + record.id?.user?.givenName}
              {record.id?.user?.verificationStatus === 'verified' && <IconVerifyStyled src={IconSuccess} />}
            </Typography>
          );
        },
      },
      {
        key: 'ResponseTime',
        dataIndex: 'ResponseTime',
        title: 'Response Time',
        width: 150,
      },
      {
        key: 'Score',
        dataIndex: 'Score',
        title: 'Score',
        width: 50,
      },
      {
        key: 'Actions',
        title: '',
        dataIndex: ' Actions',
        width: 50,
        render: (value, record) => {
          return (
            <Actions>
              <Tooltip title={'Response Detail'}>
                <IconButtonStyled
                  onClick={() => {
                    setItemSelected(record.id);
                    handleModal();
                  }}
                >
                  <ImageIconStyled src={ViewIcon} />
                </IconButtonStyled>
              </Tooltip>
            </Actions>
          );
        },
      },
    ];
    return columns;
  }, []);

  const dataSource = React.useMemo(() => {
    const dataSource = quiz.map((item, idx) => ({
      key: idx,
      id: item,
      No: idx + 1,
      NSAAID: item?.user?.username,
      Name: item?.user?.surname,
      ResponseTime: item?.updatedAt ? moment(new Date(item.updatedAt)).format(FORMAT_TIME_AM_PM) : '',
      Score: item?.totalPoint,
    }));
    return dataSource as unknown as INSAADataSource[];
  }, [quiz]);

  let responseRate = 0;
  if (data?.totalItems) {
    responseRate = (data?.totalItems * 100) / totalGuest;
  }

  return (
    <Box>
      <Box display="flex" flex={1}>
        <Box display="flex" flex={1}>
          <InputField
            value={responseRate !== Infinity ? responseRate : 0}
            disabled
            title="Response Rate (%)"
            margin="dense"
            fullWidth
          />
        </Box>
        <Box ml={2} display="flex" flex={1}>
          <InputField value={data?.totalItems} disabled title="Number of response" margin="dense" fullWidth />
        </Box>
      </Box>

      <Box mt={2} mb={2}>
        <NSAATable loading={loading} columns={columns} dataSource={dataSource} />
      </Box>
      <CustomPagination
        itemName={'response'}
        data={data}
        limit={limit}
        handleChangePagination={handleChangePagination}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onClickChangePage={onClickChangePage}
        disableSearchPage={disableSearchPage || searchPage === ''}
        searchPage={searchPage}
        onBlurField={onBlurField}
      />
      {isOpenDetail && <ResponseDetail open={isOpenDetail} _onCloseModal={handleModal} itemSelected={itemSelected} />}
    </Box>
  );
};

export default Listing;
