import { Box, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const IconCustom = styled.img`
  width: 24px;
`;
export const TextCustom = styled(Typography)<{ isQualified: boolean }>`
  ${({ theme, isQualified }) => css`
    color: ${isQualified ? '#34C759' : '#FF3B30'};
  `}
`;
export const ItemBox = styled(Box)`
  ${({ theme }) => css`
    gap: 8px;
  `}
`;
