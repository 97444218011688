import React, { useCallback, useEffect, useState } from 'react';

import { Box, Tooltip } from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { HeaderMobile } from 'src/components/headerMobile';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import NSAATable, { INSAAColumn, INSAADataSource, INSAATableSearch } from 'src/components/table';
import { CALL_SUCCESS, FORBIDDEN, FORMAT_TIME_AM_PM } from 'src/constants/common';
import { Header } from 'src/layouts/admin/components/header';
import SendMailIcon from 'src/medias/icons/send-mail-icon.svg';
import { ActionButton, ActionButtonPopover } from 'src/pages/event-management/components/guest/shared/button.component';
import TooltipStyled from 'src/pages/event-management/components/guest/shared/tooltip.component';
import { Heading } from 'src/pages/event-management/components/search/index.styled';
import { FormMenuItem, FormTextField, Label } from 'src/pages/profile/components/general-information.styled';
import { Actions, IconButtonStyled } from 'src/pages/user-management/types';
import { emailTrackingResendEmail, getAllEmailTracking, IEmailTracking } from 'src/services/email-tracking';
import { selectUserProfile } from 'src/store/authentication/selector';
import useResponsive from 'src/utils/responsive';

import { ActionBar, ButtonStatus, EmailTrackingContainer, ImageIconStyled, ListingLabel } from './index.styled';
import { DEFAULT_RESPONSE, GUEST_ACTIONS, REGISTRATION_STATUS } from './types';

const EmailTracking = () => {
  const history = useHistory();
  const MAX_LENGTH_128 = 128;
  const SEND_MAIL_ACTION = 'send-email';
  const { id } = useParams<{ id: string }>();
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [formSearch, setFormSearch] = useState<{ emailAddress: string; status: string }>({
    emailAddress: '',
    status: 'all',
  });
  const [isSearching, setSearching] = useState(false);
  const [idsSelected, setIdsSelected] = React.useState<IEmailTracking[]>([]);
  const [, setData] = useState<any>([]);
  const responsive = useResponsive();
  const [isActionDisabled, setActionDisabled] = useState(true);

  const userProfile = useSelector(selectUserProfile);
  useEffect(() => {
    const includeDelivered = idsSelected.some((item, index) => item.status === 'delivered');
    if (includeDelivered || idsSelected.length === 0) setActionDisabled(true);
    else setActionDisabled(false);
  }, [idsSelected]);

  const PLEASE_FILL_CORRECT_EMAIL = 'Please fill in a correct email';

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };

  const handleResendEmail = async (value: any) => {
    const emailId = value?.id?.id;
    const response = await emailTrackingResendEmail([emailId]);
    if (response?.statusCode === CALL_SUCCESS) {
      setMessage(response.message);
      setShowSnackBarSuccess(true);
    } else {
      setMessage(response.message);
      setShowSnackBarSuccess(true);
    }
  };

  const handleChangeAction = async (action: string) => {
    if (action === SEND_MAIL_ACTION) {
      const emailIds = idsSelected.map((item) => item.id);
      const response = await emailTrackingResendEmail(emailIds);
      if (response?.statusCode === CALL_SUCCESS) {
        setMessage(response.message);
        setShowSnackBarSuccess(true);
      } else {
        setMessage(response.message);
        setShowSnackBarSuccess(true);
      }
    }
  };

  const builderColumns = useCallback(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'Subject',
        dataIndex: 'Subject',
        title: 'Subject',
        width: 200,
        ellipsis: true,
        render: (value, record) => {
          return <Box>{value}</Box>;
        },
      },
      {
        key: 'EmailAddress',
        dataIndex: 'EmailAddress',
        title: 'Email Address',
        width: 130,
        ellipsis: true,
      },
      {
        key: 'RegistrationTime',
        dataIndex: 'RegistrationTime',
        title: 'Time',
        width: 80,
        ellipsis: true,
        render: (value, record) => {
          return <Box>{value}</Box>;
        },
      },
      {
        key: 'Reason',
        dataIndex: 'Reason',
        title: 'Reason',
        width: 350,
        ellipsis: true,
        render: (value, record) => {
          return (
            <Tooltip title={value} placement="top">
              <Box>{value}</Box>
            </Tooltip>
          );
        },
      },
      {
        key: 'Status',
        dataIndex: 'Status',
        title: 'Status',
        width: 150,
        ellipsis: true,
        render: (value, record) => {
          let variants: 'delivered' | 'bounce' | undefined =
            value === 'delivered' ? 'delivered' : value === 'bounce' ? 'bounce' : undefined;
          return <ButtonStatus variants={variants}>{value === 'bounce' ? 'bounced' : 'delivered'}</ButtonStatus>;
        },
      },
      {
        key: 'Actions',
        title: '',
        dataIndex: ' Actions',
        align: 'right',
        width: 20,
        render: (value, record) => {
          const disabled = record?.Status === 'delivered';
          return (
            <Actions>
              <Tooltip title="Re-send Email">
                <IconButtonStyled onClick={handleResendEmail.bind(null, record)} disabled={disabled}>
                  <ImageIconStyled src={SendMailIcon} disabled={disabled} />
                </IconButtonStyled>
              </Tooltip>
            </Actions>
          );
        },
      },
    ] as INSAAColumn[];

    return columns;
  }, []);

  const builderDataSource = (data: IEmailTracking[]) => {
    setData(data);
    const dataSource = data.map((item, index) => {
      return {
        key: item.id,
        id: item,
        Subject: item.subject,
        EmailAddress: item.mailTo,
        RegistrationTime: moment(+item?.createdTime).format(FORMAT_TIME_AM_PM),
        Reason: item.reason,
        Status: item.status === 'delivered' ? 'delivered' : 'bounce',
      };
    });
    return dataSource as unknown as INSAADataSource[];
  };

  const fetchData = useCallback(
    async (search: INSAATableSearch) => {
      const searchFields = {
        ...search,
        ...formSearch,
      };

      setLoading(true);
      const response = await getAllEmailTracking(searchFields);

      if (response?.statusCode === CALL_SUCCESS) {
        setLoading(false);
        setSearching(false);
        return response;
      } else if (response?.statusCode === FORBIDDEN) {
        history.push('/not-access');
      }
      setLoading(false);
      setSearching(false);
      return DEFAULT_RESPONSE;
    },
    [formSearch, id],
  );

  const onSelectedItems = (ids: any) => {
    setIdsSelected(ids);
  };

  return (
    <Box overflow="auto" width="100%">
      <HeaderMobile title="Email Tracking" userProfile={userProfile} />
      {responsive.isDesktopOrLaptop && <Header />}
      <Box pt={responsive.isTabletOrMobile ? 15 : 3.75} />
      <EmailTrackingContainer py={3} responsive={responsive}>
        <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
        <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
        <Heading>EMAIL TRACKING</Heading>
        <Box>
          <Formik
            initialValues={{
              emailAddress: '',
              status: 'all',
            }}
            onSubmit={(values) => {
              setSearching(true);
              setFormSearch({ emailAddress: values.emailAddress, status: values.status });
            }}
            validationSchema={Yup.object({
              emailAddress: Yup.string().email(PLEASE_FILL_CORRECT_EMAIL).notRequired(),
            })}
          >
            {(formik) => {
              const handleReset = () => {
                formik.handleReset();
                setSearching(true);
                setFormSearch({ emailAddress: '', status: 'all' });
              };
              return (
                <Form>
                  <Box display="flex" justifyContent="space-between">
                    <Box display="flex" style={{ gap: 24 }} width="100%">
                      <Box flex="0 1 358px">
                        <Label>
                          Receiver Email Address
                          <TooltipStyled message="You can search by Email Address" placement="top" />
                        </Label>
                        <FormTextField
                          onChange={formik.handleChange}
                          value={formik.values.emailAddress}
                          name="emailAddress"
                          variant="outlined"
                          fullWidth
                          size="small"
                          placeholder="Enter at least 3 character"
                          error={formik.touched.emailAddress && !!formik.errors.emailAddress}
                          helperText={formik.touched.emailAddress && formik.errors.emailAddress}
                          onBlur={formik.handleBlur}
                          inputProps={{ maxLength: MAX_LENGTH_128 }}
                        />
                      </Box>
                      <Box flex="0 1 358px">
                        <Label>Status</Label>
                        <FormTextField
                          onChange={formik.handleChange}
                          value={formik.values.status}
                          variant="outlined"
                          name="status"
                          fullWidth
                          size="small"
                          select={true}
                          SelectProps={{
                            MenuProps: {
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              getContentAnchorEl: null,
                            },
                          }}
                          defaultValue="all"
                        >
                          {REGISTRATION_STATUS.map(({ label, value }, index) => (
                            <FormMenuItem key={index} value={value}>
                              {label}
                            </FormMenuItem>
                          ))}
                        </FormTextField>
                      </Box>
                    </Box>
                    <Box
                      width={264}
                      ml={6}
                      display="flex"
                      justifySelf="flex-end"
                      style={{ gap: 24 }}
                      alignSelf="flex-end"
                      justifyContent="flex-end"
                    >
                      {formik.dirty && (
                        <ActionButton variants="outlined" onClick={handleReset}>
                          Reset
                        </ActionButton>
                      )}
                      <ActionButton type="submit" variants="yellow">
                        Search
                      </ActionButton>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
        <Box mt={6}>
          <ActionBar>
            <ListingLabel>Listing</ListingLabel>
            <Box display="flex" style={{ gap: 24 }}>
              <ActionButtonPopover
                label="Action"
                onChange={handleChangeAction}
                items={GUEST_ACTIONS}
                disabled={isActionDisabled}
              />
            </Box>
          </ActionBar>
          <Box mt={3}>
            <NSAATable
              isShowCheckBoxes
              isShowPagination
              fetchData={fetchData}
              builderColumns={builderColumns}
              builderDataSource={builderDataSource}
              loading={loading}
              paginationTitle="email"
              isSearchReset={isSearching}
              onSelectedItems={onSelectedItems}
            />
          </Box>
        </Box>
      </EmailTrackingContainer>
    </Box>
  );
};

export default EmailTracking;
