import React, { useState, useEffect, useMemo } from 'react';

import { Box, Typography } from '@material-ui/core';
import {
  createAlignPlugin,
  createBoldPlugin,
  createItalicPlugin,
  createHighlightPlugin,
  createUnderlinePlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
  createKbdPlugin,
  createNodeIdPlugin,
  createDndPlugin,
  createExitBreakPlugin,
  createSelectOnBackspacePlugin,
} from '@udecode/plate';
import { createHistoryPlugin, createReactPlugin, Plate, PlatePlugin, SPEditor } from '@udecode/plate-core';
import { createHeadingPlugin } from '@udecode/plate-heading';
import { createImagePlugin, ELEMENT_IMAGE } from '@udecode/plate-image';
import { createLinkPlugin } from '@udecode/plate-link';
import { createListPlugin, createTodoListPlugin } from '@udecode/plate-list';
import { createMediaEmbedPlugin } from '@udecode/plate-media-embed';
import { createParagraphPlugin } from '@udecode/plate-paragraph';
import { createTablePlugin } from '@udecode/plate-table';
import { useRouteMatch } from 'react-router';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import Banner from 'src/components/banner';
import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import { HeaderMobile } from 'src/components/headerMobile';
import { optionsExitBreakPlugin, editablePreviewProps } from 'src/components/slateEditor/config/pluginOptions';
import { CALL_SUCCESS } from 'src/constants/common';
import useFetchMe from 'src/hooks/use-fetch-me';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { components } from 'src/pages/admin/components/Preview';
import { getCMSData, ICms } from 'src/services/cms';
import useResponsive from 'src/utils/responsive';

import { Notification } from './components/modal';
import { BoxDonation, DonateBtn, DonateHeader, DonationContainer, donations, ImgCustom, NSAAContainer } from './types';

type TEditor = SPEditor & ReactEditor & HistoryEditor;

const Donation = () => {
  useScrollToTop();
  const { isTabletOrMobile } = useResponsive();
  const responsive = useResponsive();
  const { me } = useFetchMe();

  const { path } = useRouteMatch();
  const [isOpenModal, setOpenModal] = useState(false);

  const PreviewId = 'preview';

  const [data, setData] = useState<ICms>({
    type: '',
    banner_image: '',
    banner_title: '',
    banner_description: '',
    content_title: '',
    content_body: '',
    background_image: '',
    item_image: '',
    item_date: '',
    item_image_description: '',
  });

  const _onClickDonate = (donationType: string) => {
    if (donationType === 'donationNYPS') {
      handleModal();
      return;
    }
    if (donationType === 'donationNSAA') {
      window.location.href = '/donation/nsaa';
      return;
    }
    if (donationType === 'donationNYGH') {
      window.location.href = '/donation/nygh';
      return;
    }
  };

  const handleModal = () => {
    setOpenModal(!isOpenModal);
  };

  const getData = async () => {
    const response = await getCMSData('donation');
    if (response.statusCode === CALL_SUCCESS) {
      setData(response.data as ICms);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const pluginsMemo: PlatePlugin<TEditor>[] = useMemo(() => {
    const plugins = [
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createMediaEmbedPlugin(),
      createAlignPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createDndPlugin(),
      createExitBreakPlugin(optionsExitBreakPlugin),
      createSelectOnBackspacePlugin({
        allow: [ELEMENT_IMAGE],
      }),
    ];

    return plugins;
  }, []);

  return (
    <Box pt={isTabletOrMobile ? '90px' : 3.75}>
      {isTabletOrMobile && <HeaderMobile title={`Donation`} userProfile={me} />}
      {!isTabletOrMobile && <NSAABreadcrumbs path={path} />}
      <Banner
        image={data.banner_image}
        title={data.banner_title}
        description={data.banner_description}
        responsive={responsive}
      />
      <NSAAContainer
        maxWidth="lg"
        pl={isTabletOrMobile ? 2 : 0}
        pr={isTabletOrMobile ? 2 : 0}
        pt={5}
        style={{ textAlign: 'center' }}
      >
        <Plate
          id={PreviewId}
          plugins={pluginsMemo}
          components={components}
          editableProps={editablePreviewProps}
          value={data?.item_content && JSON.parse(data?.item_content)}
        />
      </NSAAContainer>
      <DonateHeader isTabletOrMobile={isTabletOrMobile}>Donate Online</DonateHeader>
      <DonationContainer
        pl={isTabletOrMobile ? 2 : 0}
        pr={isTabletOrMobile ? 2 : 0}
        overflow={isTabletOrMobile ? 'auto' : 'visible'}
        justifyContent={isTabletOrMobile ? 'flex-start' : 'center'}
      >
        {donations.map((item) => {
          return (
            <BoxDonation
              pb={isTabletOrMobile ? 4 : 0}
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
            >
              <Box position="relative">
                <ImgCustom src={item.image} alt="" />
                <Typography
                  style={{
                    fontSize: 24,
                    fontWeight: 700,
                    color: '#fff',
                    position: 'absolute',
                    left: '50%',
                    bottom: 0,
                    transform: 'translate(-50%, -50%)',
                  }}
                >
                  {item.title}
                </Typography>
              </Box>
              <DonateBtn onClick={_onClickDonate.bind(null, item.key)}>Donate Now</DonateBtn>
            </BoxDonation>
          );
        })}
      </DonationContainer>
      <Notification open={isOpenModal} handleClose={handleModal} />
    </Box>
  );
};

export default Donation;
