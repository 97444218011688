import React from 'react';

import { Box } from '@material-ui/core';

import { CustomDialog } from 'src/components';

import { DialogContentTextStyled } from '../type';

interface IConfirmModal {
  action: string;
  open: boolean;
  handleClose: () => void;
  onSubmit: () => void;
}

function ConfirmModal({ action, open, handleClose, onSubmit }: IConfirmModal) {
  let text;

  if (action === 'cancel' || action === 'cancel edit') {
    text = 'If you cancel, news will not be created. Are you sure you want to cancel?';
  }
  if (action === 'save create' || action === 'update and preview' || action === 'update') {
    text = 'Are you sure you want to save this change?';
  }
  if (action === 'delete') {
    text = 'Are you sure you want to Delete this news?';
  }
  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      onSubmit={onSubmit}
      cancelText="No"
      submitText="Yes"
      isConfirmPopup={true}
    >
      <Box alignSelf="center" display="flex" flex={1} justifyContent="center" alignItems="center" textAlign="center">
        <DialogContentTextStyled>{text}</DialogContentTextStyled>
      </Box>
    </CustomDialog>
  );
}

export default ConfirmModal;
