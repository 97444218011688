import React from 'react';

import { Box, IconButton, makeStyles, MenuItem, Typography } from '@material-ui/core';
import _ from 'lodash';

import { CustomButton, CustomInputSelect, InputField } from 'src/components';
import CloseIcon from 'src/medias/icons/close-icon.svg';
import { CloseIconStyled } from 'src/pages/signup/components/Child';
import { ICriteriaSetting } from 'src/services/users';
import { intRegex, positiveRegex } from 'src/utils/common';

import { eventCategory } from '../types';

import { Label, Title } from './EligibilityCriteriaApplicant';

const TEXT_REQUIRE = 'This field is required';
const NUMBER_SESSION_LENGTH = 50;
const IS_NUMBER = 'This field must be a number';
const IS_POSITIVE_NUMBER = 'This field must be a positive number';

const useStyles = makeStyles((theme) => ({
  clearIcon: {
    position: 'absolute',
    right: -64,
    top: 8,
  },
}));
interface ICritiriaYouthMemnber {
  settings: ICriteriaSetting;
  setSettings: React.Dispatch<React.SetStateAction<ICriteriaSetting>>;
  edit: boolean;
  errors: {
    applicantSession: boolean;
    youngMemberSession: boolean;
    applicantConfig: {
      key: boolean;
      value: boolean;
    }[];
    youngMemberConfig: {
      key: boolean;
      value: boolean;
    }[];
  };
  setErrors: React.Dispatch<
    React.SetStateAction<{
      applicantSession: boolean;
      youngMemberSession: boolean;
      applicantConfig: {
        key: boolean;
        value: boolean;
      }[];
      youngMemberConfig: {
        key: boolean;
        value: boolean;
      }[];
    }>
  >;
}

const EligibilityCriteriaYouthMember = ({ settings, setSettings, edit, errors, setErrors }: ICritiriaYouthMemnber) => {
  const classes = useStyles();

  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSettings({ ...settings, [e.target.name]: e.target.value });

    if (e.target.name === 'youngMemberSession') {
      setValidate('youngMemberSession', intRegex.test(e.target.value) ? e.target.value : '');
    }
  }

  const _handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    if (e.target.name === 'event-category') {
      let newCategory = settings.youngMemberConfig;
      newCategory[index].key = e.target.value;

      setSettings({ ...settings, youngMemberConfig: newCategory });

      if (e.target.value === 'none') {
        let newErrors = errors.youngMemberConfig;
        newErrors[index].key = true;
        setErrors({ ...errors, youngMemberConfig: newErrors });
      } else {
        let newErrors = errors.youngMemberConfig;
        newErrors[index].key = false;
        setErrors({ ...errors, youngMemberConfig: newErrors });
      }
    }

    if (e.target.name === 'number-session') {
      let newNumberSession = settings.youngMemberConfig;
      newNumberSession[index].value = e.target.value;

      setSettings({ ...settings, youngMemberConfig: newNumberSession });

      if (_.isEmpty(e.target.value) || !positiveRegex.test(e.target.value)) {
        let newErrors = errors.youngMemberConfig;
        newErrors[index].value = true;
        setErrors({ ...errors, youngMemberConfig: newErrors });
      } else {
        let newErrors = errors.youngMemberConfig;
        newErrors[index].value = false;
        setErrors({ ...errors, youngMemberConfig: newErrors });
      }
    }
  };

  const handleDelete = (index: number) => {
    const newConfigs = settings.youngMemberConfig;
    const newErrors = errors.youngMemberConfig;

    newConfigs.splice(index, 1);
    newErrors.splice(index, 1);

    setSettings({ ...settings, youngMemberConfig: newConfigs });
    setErrors({ ...errors, youngMemberConfig: newErrors });
  };

  const _onAddMore = () => {
    let newCategory = settings.youngMemberConfig;
    let newErrors = errors.youngMemberConfig;

    newCategory.push({ key: 'none', value: '1' });
    newErrors.push({ key: true, value: false });

    setSettings({ ...settings, youngMemberConfig: newCategory });
    setErrors({ ...errors, youngMemberConfig: newErrors });
  };

  return (
    <Box mt={5}>
      <Title>Eligibility Criteria for Youth Member</Title>
      <Box mt={'20px'}>
        <Label display="inline">
          Number of volunteer session required
          <Typography display="inline" color="error">
            {` *`}
          </Typography>
        </Label>
        <InputField
          disabled={!edit}
          value={settings?.youngMemberSession}
          name="youngMemberSession"
          onChange={_handleChange}
          error={errors.youngMemberSession}
          errormessage={
            errors?.youngMemberSession && settings.youngMemberSession === ''
              ? TEXT_REQUIRE
              : errors?.youngMemberSession && IS_NUMBER
          }
          InputProps={{
            inputProps: {
              maxLength: NUMBER_SESSION_LENGTH,
            },
          }}
        />
      </Box>
      <Box mt={'20px'} flexDirection="row" display="flex">
        <Box display="flex" flex={1} pr={1}>
          <Label display="inline">
            Event Category
            <Typography display="inline" color="error">
              {` *`}
            </Typography>
          </Label>
        </Box>
        <Box display="flex" flex={1} pl={1}>
          <Label display="inline">
            Number of session required
            <Typography display="inline" color="error">
              {` *`}
            </Typography>
          </Label>
        </Box>
      </Box>
      {settings?.youngMemberConfig?.map((setting, index) => (
        <Box display="flex" position="relative">
          <Box flex={1} mt={1} display="flex" pr={1}>
            <CustomInputSelect
              name="event-category"
              value={setting.key}
              disabled={!edit}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => _handleChangeEvent(e, index)}
              error={errors?.youngMemberConfig[index]?.key}
              errormessage={TEXT_REQUIRE}
            >
              <MenuItem key="none" value="none">
                --- Choose one category ---
              </MenuItem>
              {eventCategory.map((event) => {
                const hadCategory = settings.youngMemberConfig.find((x) => x.key === event.key);
                return (
                  <MenuItem disabled={hadCategory ? true : false} key={event.key} value={event.key}>
                    {event.value}
                  </MenuItem>
                );
              })}
            </CustomInputSelect>
          </Box>
          <Box flex={1} display="flex" pl={1}>
            <InputField
              disabled={!edit}
              value={setting.value}
              margin="dense"
              name="number-session"
              fullWidth
              require
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => _handleChangeEvent(e, index)}
              error={errors?.youngMemberConfig[index]?.value}
              errormessage={
                errors?.youngMemberConfig[index]?.value && settings.youngMemberConfig[index]?.value === ''
                  ? TEXT_REQUIRE
                  : errors?.youngMemberConfig[index]?.value && IS_POSITIVE_NUMBER
              }
              InputProps={{
                inputProps: {
                  maxLength: NUMBER_SESSION_LENGTH,
                },
              }}
            />
          </Box>
          {edit && (
            <IconButton size="medium" className={classes.clearIcon} onClick={handleDelete.bind(null, index)}>
              <CloseIconStyled src={CloseIcon} />
            </IconButton>
          )}
        </Box>
      ))}
      {edit && (
        <Box justifyContent="center" display="flex" mt={'20px'}>
          <CustomButton onClick={_onAddMore} outlineButton>
            Add more criteria
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default EligibilityCriteriaYouthMember;
