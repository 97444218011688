import React from 'react';

import { Box } from '@material-ui/core';
import moment from 'moment';

import NSAATable, { INSAAColumn } from 'src/components/table';
import { CALL_SUCCESS, FORMAT_TIME_AM_PM } from 'src/constants/common';
import CloseIcon from 'src/icons/close';
import { GeneralInformationPopupTitle } from 'src/pages/profile/components/general-information.styled';
import { HeaderSticky } from 'src/pages/profile/shared/additional-information.popup';
import { Close } from 'src/pages/user-management/shared/user-detail.component';
import { getGuestsByRegistrationId, IGuestDetailResponse } from 'src/services/guests';

import { RegistrationInformationLabel, RegistrationStatus, TextContent, TextLabel } from './index.styled';

interface ITicketInformationProps {
  onClose: () => void;
  ticketInformationId: any;
}

const TicketInformation = ({ onClose, ticketInformationId }: ITicketInformationProps) => {
  const [data, setData] = React.useState<Partial<IGuestDetailResponse>>();
  React.useEffect(() => {
    (async () => {
      const response = await getGuestsByRegistrationId(ticketInformationId);
      if (response?.statusCode === CALL_SUCCESS && response.data) {
        setData(response.data);
      }
    })();
  }, [ticketInformationId]);
  const columns = React.useMemo(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'No',
        dataIndex: 'No',
        title: 'No.',
        ellipsis: true,
        width: 100,
      },
      {
        key: 'TicketID',
        dataIndex: 'TicketID',
        title: 'Ticket Code',
        width: 210,
        ellipsis: true,
      },
      {
        key: 'Status',
        dataIndex: 'Status',
        title: 'Status',
        width: 280,
        ellipsis: true,
        render: (value, record) => {
          return <RegistrationStatus variants={value}>{value}</RegistrationStatus>;
        },
      },
      {
        key: 'Checkin',
        dataIndex: 'Checkin',
        title: 'Check-in',
        width: 340,
      },
    ];
    return columns;
  }, [data]);

  const dataSource = React.useMemo(() => {
    if (data?.ticket) {
      const dataSource = data?.ticket.map((item, index) => {
        return {
          key: item.id,
          id: item.id,
          No: index + 1,
          TicketID: item?.code,
          Status: item.status,
          Checkin: item?.checkinTime ? moment(+item?.checkinTime).format(FORMAT_TIME_AM_PM) : '',
        };
      });
      return dataSource as any;
    }
    return [];
  }, [data]);

  if (!data) return <div />;

  return (
    <Box px={4} py={1}>
      <HeaderSticky>
        <GeneralInformationPopupTitle>Registration Info</GeneralInformationPopupTitle>
        <Close onClick={onClose}>
          <CloseIcon fill="white" />
        </Close>
      </HeaderSticky>
      <RegistrationInformationLabel>REGISTRATION INFO</RegistrationInformationLabel>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Box>
          <Box mb={2} display="flex">
            <TextLabel>Name:</TextLabel>
            <TextContent>{`${data?.user?.surname}, ${data?.user?.givenName}`}</TextContent>
          </Box>
          <Box mb={2} display="flex">
            <TextLabel>NSAA ID:</TextLabel>
            <TextContent>{data?.user?.username || ''}</TextContent>
          </Box>
          <Box mb={2} display="flex">
            <TextLabel>Email Address:</TextLabel>
            <TextContent>{data?.guest?.email || data?.user?.emailAddress}</TextContent>
          </Box>
          <Box mb={2} display="flex">
            <TextLabel>Phone Number:</TextLabel>
            <TextContent>
              {data?.guest?.areaCode || data?.user?.areaCode}
              {data?.guest?.mobilePhone || data?.user?.mobilePhone}
            </TextContent>
          </Box>
        </Box>
        <Box minWidth={'293px'}>
          <Box mb={2} display="flex">
            <TextLabel>School Attended:</TextLabel>
            <Box width={169}>
              <TextContent>
                {data?.guest?.periodStudyInNYK
                  ? `NYK (${data?.guest?.periodStudyInNYK});`
                  : data?.user?.periodStudyInNYK
                  ? `NYK (${data?.user?.periodStudyInNYK});`
                  : ''}
                {data?.guest?.periodStudyInNPP
                  ? `NPP (${data?.guest?.periodStudyInNPP});`
                  : data?.user?.periodStudyInNPP
                  ? `NPP (${data?.user?.periodStudyInNPP});`
                  : ''}
                {data?.guest?.periodStudyInNYP
                  ? `NYPS (${data?.guest?.periodStudyInNYP});`
                  : data?.user?.periodStudyInNYP
                  ? `NYPS (${data?.user?.periodStudyInNYP});`
                  : ''}
                {data?.guest?.periodStudyInNYGH
                  ? `NYGH (${data?.guest?.periodStudyInNYGH});`
                  : data?.guest?.periodStudyInNYGH
                  ? `NYGH (${data?.user?.periodStudyInNYGH});`
                  : ''}
              </TextContent>
            </Box>
          </Box>
          <Box mb={2} display="flex">
            <TextLabel>Registration Time:</TextLabel>
            <TextContent>
              {data?.registrationTime ? moment(+data?.registrationTime).format(FORMAT_TIME_AM_PM) : null}
            </TextContent>
          </Box>
          <Box mb={2} display="flex" alignItems="center">
            <TextLabel>Registration Status:</TextLabel>
            <TextContent>
              <RegistrationStatus variants={data?.registrationStatus! as any}>
                {data?.registrationStatus}
              </RegistrationStatus>
            </TextContent>
          </Box>
        </Box>
      </Box>
      <RegistrationInformationLabel mt={'24px'}>TICKET INFO</RegistrationInformationLabel>
      <Box display="flex" justifyContent="space-between" mt={2}>
        <Box>
          <Box mb={2} display="flex">
            <TextLabel>Event:</TextLabel>
            <TextContent>
              {data?.event!.title.length < 50 ? data?.event?.title : data?.event?.title.slice(0, 50) + '...'}
            </TextContent>
          </Box>
          <Box mb={2} display="flex">
            <TextLabel>Total Number of Ticket Purchased:</TextLabel>
            <TextContent>{data?.quantity}</TextContent>
          </Box>
          <Box mb={2} display="flex">
            <TextLabel>Number of Standard Ticket:</TextLabel>
            <TextContent>{data?.ticketForStandardMeal || 0}</TextContent>
          </Box>
          <Box mb={2} display="flex">
            <TextLabel>number of Vegetarian ticket:</TextLabel>
            <TextContent>{data?.ticketForVegetarian || 0}</TextContent>
          </Box>
          <Box mb={2} display="flex">
            <TextLabel>Number of Halal Ticket:</TextLabel>
            <TextContent>{data?.ticketForHalal || 0}</TextContent>
          </Box>
        </Box>
        <Box minWidth={'293px'}>
          <Box mb={2} display="flex">
            <TextLabel>Number of discounted ticket:</TextLabel>
            <TextContent>{data?.numberAttendedAge ? data?.numberAttendedAge : 0}</TextContent>
          </Box>
          <Box mb={2} display="flex">
            <TextLabel>Sub-total (SGD):</TextLabel>
            <TextContent>{data?.subTotal ? data?.subTotal : 0} </TextContent>
          </Box>
          <Box mb={2} display="flex">
            <TextLabel>Discount (SGD):</TextLabel>
            <TextContent>{data?.discount ? data?.discount : 0}</TextContent>
          </Box>
          <Box mb={2} display="flex">
            <TextLabel>Total payment (SGD):</TextLabel>
            <TextContent>{data?.totalPayment ? data?.totalPayment : 0}</TextContent>
          </Box>
        </Box>
      </Box>
      <Box mt={1} pb={3}>
        <NSAATable columns={columns} dataSource={dataSource} />
      </Box>
    </Box>
  );
};

export default TicketInformation;
