import queryString from 'query-string';

import httpClient from 'src/configs/http-client';
import { IResponse } from 'src/types';

import { IApplicant } from '.';

export const getUserApplicantById = async (userId: string) => {
  const response = await httpClient.get<IResponse<IApplicant>>(
    `${process.env.REACT_APP_API_URL}/users/${userId}/applicant`,
  );
  return response?.data;
};

export const updateApplicantGtkyById = async (applicantId: string, payload: any) => {
  const response = await httpClient.patch<IResponse<IApplicant>>(
    `${process.env.REACT_APP_API_URL}/users/${applicantId}/applicant`,
    payload,
  );
  return response?.data;
};

export const verifyUserById = async (userId: string) => {
  const response = await httpClient.patch<IResponse<IApplicant>>(
    `${process.env.REACT_APP_API_URL}/users/verify/${userId}`,
  );
  return response?.data;
};

export const updateApplicantGtkyChildById = async (applicantId: string, payload: any) => {
  const response = await httpClient.patch<IResponse<IApplicant>>(
    `${process.env.REACT_APP_API_URL}/users/${applicantId}/update-child`,
    payload,
  );
  return response?.data;
};

export const exportGuestListCSV = async (
  eventId: string,
  { page, limit, type, name, status }: { page: number; limit: number; type: string; name: string; status: string },
) => {
  const queries = queryString.stringify({
    page,
    limit,
    type,
    name,
    status,
  });
  const response = await httpClient.get<IResponse<{ data: string }>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/export/confirmed-list?${queries}`,
  );
  const date = new Date();

  var downloadLink = document.createElement('a');
  var blob = new Blob(['\ufeff', response.data as any], { type: 'text/csv;charset=utf-8;' });
  var url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `List of guest_${date.toLocaleDateString('en-GB').split('/').join('')}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  return true;
};
