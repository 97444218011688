import React, { useState } from 'react';

import { Box, InputAdornment, Typography } from '@material-ui/core';
import _ from 'lodash';

import { CustomButton, InputField } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import DollarIconURL from 'src/medias/icons/dollar-icon.svg';
import { CustomLabel, TextNote } from 'src/pages/signup/components/types';
import { donateNSAA } from 'src/services/payment';
import { intRegex, _checkErrors } from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { BoxStyled, CustomButtonStyled, HeaderText, ImageStyled } from '../types';

const TEXT_REQUIRE = 'This field is required';
const MAXIMUM_AMOUNT_NUMBER_LENGTH = 50;

interface IFillForm {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  data: {
    amount: string;
  };
  errors: {
    amount: boolean;
  };
  setData: React.Dispatch<
    React.SetStateAction<{
      amount: string;
    }>
  >;
  setErrors: React.Dispatch<
    React.SetStateAction<{
      amount: boolean;
    }>
  >;
  setPaymentId: React.Dispatch<React.SetStateAction<string>>;
}

const FillForm = ({ data, setData, errors, setErrors, activeStep, setActiveStep, setPaymentId }: IFillForm) => {
  const { isTabletOrMobile, isTablet } = useResponsive();
  const [loading, setLoading] = useState<boolean>(false);
  const [isActionSuccess, setActionSuccess] = useState(false);
  const [isActionFailed, setActionFailed] = useState(false);
  const [messageResponse, setMessageResponse] = useState('');

  const _onSubmit = async () => {
    setLoading(true);
    let validErrors = errors;
    if (_.isEmpty(data.amount)) {
      validErrors.amount = true;
      setErrors({ ...validErrors, amount: true });
    }

    if (!_checkErrors(errors)) {
      const response = await donateNSAA({ amount: Number(data.amount) });
      if (response && response.statusCode === CALL_SUCCESS) {
        setPaymentId(response?.data);
        setActionSuccess(true);
        setMessageResponse(response.message);
        setActiveStep(activeStep + 1);
      } else {
        setActionFailed(true);
        setMessageResponse(response.message);
      }
    }
    setLoading(false);
  };

  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.name === 'amount') {
      if (intRegex.test(e.target.value) || e.target.value === '') {
        setData({ ...data, [e.target.name]: e.target.value });
        setValidate(e.target.name, intRegex.test(e.target.value) ? e.target.value : '');
      }
    }
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setData({ ...data, [e.target.name]: valueTrimmed });
  };

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const _onClickAmount = (amount: number) => {
    setData({ ...data, amount: amount.toString() });
    setErrors({ ...errors, amount: false });
  };

  return (
    <Box display="flex" flex={1} flexDirection="column">
      {isTabletOrMobile && (
        <Box justifyContent="center" display="flex">
          <TextNote isTabletOrMobile={isTabletOrMobile}>Fill form</TextNote>
        </Box>
      )}
      <Box mb={4} mt={isTabletOrMobile ? 2 : 7} justifyContent="center" display="flex">
        <HeaderText isTabletOrMobile={isTabletOrMobile}>
          Please note that NSAA will not issue TER for your donation
        </HeaderText>
      </Box>
      <BoxStyled>
        <Box>
          <CustomLabel display="inline">
            Amount{' '}
            <Typography component="span" display="inline" color="error">
              *
            </Typography>
          </CustomLabel>
          <Box display="flex" width={'100%'} style={{ gap: 5 }}>
            {[50, 100, 500, 1000].map((item) => (
              <Box display="flex" flex={1}>
                <CustomButtonStyled onClick={_onClickAmount.bind(null, item)} outlineButton>
                  ${item}
                </CustomButtonStyled>
              </Box>
            ))}
          </Box>
          <Box mt={1}>
            <InputField
              name="amount"
              value={data.amount}
              onChange={_handleChange}
              onBlur={onBlurField}
              placeholder="Other"
              error={errors.amount}
              errormessage={TEXT_REQUIRE}
              InputProps={{
                inputProps: {
                  maxLength: MAXIMUM_AMOUNT_NUMBER_LENGTH,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <ImageStyled src={DollarIconURL} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </BoxStyled>
      <Box
        mt={5}
        display="flex"
        justifyContent="center"
        flex={1}
        alignItems={isTabletOrMobile ? 'flex-end' : 'flex-start'}
        mb={4}
      >
        <CustomButton style={{ width: isTablet && '100%' }} disabled={loading} onClick={_onSubmit}>
          Submit
        </CustomButton>
      </Box>
      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </Box>
  );
};

export default FillForm;
