import React from 'react';

import { Box, IconButton } from '@material-ui/core';

import { CustomDrawer } from 'src/components';
import IconSuccess from 'src/medias/icons/icon-success.png';
import {
  BoxUserInfoStyled,
  ClearIconStyled,
  IconVerifyStyled,
  LabelStyled,
  TypographyUserInfo,
  UserInfoHeaderTitle,
} from 'src/pages/user-management/types';
import { IVolunteer } from 'src/services/volunteer';
import { capitalizeFirstLetter } from 'src/utils/common';

interface IRightDrawer {
  toggleDrawer: (name: 'userInformation', open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  open: boolean;
  userClicked: IVolunteer | undefined;
}

const RightDrawer = ({ toggleDrawer, open, userClicked }: IRightDrawer) => {
  let skills: string[] = [];

  if (userClicked?.user?.skills !== null && userClicked?.user?.skills[0]['artistic-design']) {
    skills.push('Artistic Design');
  }
  if (userClicked?.user?.skills !== null && userClicked?.user?.skills[0]['building-architecture']) {
    skills.push('Building/architecture/estate Management');
  }
  if (userClicked?.user?.skills !== null && userClicked?.user?.skills[0]['editorial']) {
    skills.push('Editorial');
  }
  if (userClicked?.user?.skills !== null && userClicked?.user?.skills[0]['legal-advice']) {
    skills.push('Legal Advice');
  }
  if (userClicked?.user?.skills !== null && userClicked?.user?.skills[0]['translation']) {
    skills.push('Translation');
  }

  return (
    <CustomDrawer anchor="right" open={open} onClose={toggleDrawer('userInformation', false)}>
      <Box width={436}>
        <BoxUserInfoStyled>
          <UserInfoHeaderTitle>USER INFORMATION</UserInfoHeaderTitle>
          <IconButton onClick={toggleDrawer('userInformation', false)}>
            <ClearIconStyled />
          </IconButton>
        </BoxUserInfoStyled>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>NSAA ID</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>{userClicked?.user.username}</TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Name</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>
              {userClicked?.user?.surname + ', ' + userClicked?.user?.givenName}{' '}
              {userClicked?.user?.verificationStatus === 'verified' && <IconVerifyStyled src={IconSuccess} />}
            </TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>中文名字</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>{userClicked?.user?.chineseName}</TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>DOB</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>
              {userClicked?.user?.dateOfBirth
                ? new Date(userClicked?.user?.dateOfBirth).toLocaleDateString('en-GB')
                : ''}
            </TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Gender</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>
              {userClicked?.user?.gender ? capitalizeFirstLetter(userClicked?.user.gender) : ''}
            </TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Email Address</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>{userClicked?.user?.emailAddress}</TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Phone Number</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>
              {userClicked?.user?.areaCode} {userClicked?.user?.mobilePhone}
            </TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>School Attended</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            {userClicked?.user?.periodStudyInNYK && (
              <TypographyUserInfo>Nanyang Kindergarten - {userClicked?.user?.periodStudyInNYK}</TypographyUserInfo>
            )}
            {userClicked?.user?.periodStudyInNPP && (
              <TypographyUserInfo>Nanyang Pre-Primary - {userClicked?.user?.periodStudyInNPP}</TypographyUserInfo>
            )}
            {userClicked?.user?.periodStudyInNYP && (
              <TypographyUserInfo>Nanyang Primary - {userClicked?.user?.periodStudyInNYP}</TypographyUserInfo>
            )}
            {userClicked?.user?.periodStudyInNYGH && (
              <TypographyUserInfo>Nanyang Girls' High - {userClicked?.user?.periodStudyInNYGH}</TypographyUserInfo>
            )}
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Job Title</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>{userClicked?.user?.jobTitle}</TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Skills</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>{skills ? skills.join(', ') : ''}</TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Other skill</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>
              {userClicked?.user?.skills !== null && userClicked?.user?.skills[0]['other-skills']}
            </TypographyUserInfo>
          </Box>
        </Box>
      </Box>
    </CustomDrawer>
  );
};

export default RightDrawer;
