import { useCallback, useEffect, useState } from 'react';

export const useGetAppData = () => {
  const [loading, setLoading] = useState(true);

  const getAppData = useCallback(async () => {
    setLoading(false);
  }, []);

  useEffect(() => {
    getAppData();
  }, [getAppData]);

  return { loading };
};
