import React, { useEffect, useState } from 'react';

import { Box, Checkbox, CircularProgress, FormControlLabel, FormGroup, IconButton } from '@material-ui/core';
import { useFormik } from 'formik';
import moment from 'moment';

import { ISkill, ISkillKey } from 'src/api/interfaces/users.interface';
import ConfirmPopup from 'src/components/confirm';
import { DATE_PICKER_DISPLAY_FORMAT } from 'src/constants/common';
import { GENDER, INPUT_MAX_LENGTH_50, NATIONALITY, SKILLS } from 'src/constants/users.constants';
import CloseIcon from 'src/icons/close';
import {
  ActionButton,
  ErrorMessage,
  FormMenuItem,
  FormTextField,
  GeneralInformationDatePicker,
  GeneralInformationPopupTitle,
  Label,
} from 'src/pages/profile/components/general-information.styled';
import { generalInformationSchema } from 'src/pages/profile/forms/general-information.validation';
import { FooterSticky, HeaderSticky } from 'src/pages/profile/shared/additional-information.popup';
import { ReactFlagsSelectStyled } from 'src/pages/signup/components/types';
import { Close } from 'src/pages/user-management/shared/user-detail.component';
import { IApplicant } from 'src/services/gtky-applicant';
import { IUser } from 'src/services/users';
import { customLabels } from 'src/utils/areaCode';

type IGeneralInformationKeys = ReturnType<() => keyof IUser>;

interface IGeneralInformationPopupProps {
  onClose: () => void;
  onSave: (profile: any) => void;
  onSaveAndVerify: (profile: any) => void;
  applicant?: IApplicant;
  isSaveLoading: boolean;
  isSaveAndVerifyLoading: boolean;
  variants?: 'admin-edit';
}

const GeneralInformationPopup = ({
  applicant,
  isSaveLoading,
  isSaveAndVerifyLoading,
  variants,
  onClose,
  onSave,
  onSaveAndVerify,
}: IGeneralInformationPopupProps) => {
  const SAVE_MESSAGE = 'Are you sure you want to save this change?';
  const SAVE_AND_VERIFY_MESSAGE = 'Are you sure you want to save and verify this change?';
  const [isShowConfirmPopup, setShowConfirmPopup] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = useState(SAVE_MESSAGE);
  const profileSkills: ISkill = applicant?.skills?.[0] || ({} as ISkill);
  const [dateOfBirth, setDateOfBirth] = useState<any>();

  useEffect(() => {
    setDateOfBirth(applicant?.dateOfBirth);
  }, [applicant]);

  const handleConfirmPopup = () => {
    if (confirmMessage === SAVE_MESSAGE) {
      onSave(formik.values);
      setShowConfirmPopup(false);
    }
    if (confirmMessage === SAVE_AND_VERIFY_MESSAGE) {
      onSaveAndVerify(formik.values);
      setShowConfirmPopup(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      surname: applicant?.surname || '',
      givenName: applicant?.givenName || '',
      chineseName: applicant?.chineseName || '',
      emailAddress: applicant?.emailAddress || '',
      mailingAddress: applicant?.mailingAddress || '',
      postalCode: applicant?.postalCode || '',
      nationality: applicant?.nationality || '',
      skills: profileSkills || ({} as ISkill),
      jobTitle: applicant?.jobTitle || '',
      companyName: applicant?.companyName || '',
      periodStudyInNPP: applicant?.periodStudyInNPP,
      isCheckPeriodStudyInNPP: !!applicant?.periodStudyInNPP || false,
      periodStudyInNYGH: applicant?.periodStudyInNYGH,
      isCheckPeriodStudyInNYGH: !!applicant?.periodStudyInNYGH || false,
      periodStudyInNYK: applicant?.periodStudyInNYK,
      isCheckPeriodStudyInNYK: !!applicant?.periodStudyInNYK || false,
      periodStudyInNYP: applicant?.periodStudyInNYP,
      isCheckPeriodStudyInNYP: !!applicant?.periodStudyInNYP || false,
      gender: applicant?.gender,
      areaCode: applicant?.areaCode,
      mobilePhone: applicant?.mobilePhone,
      isChildAttend: '',
      dateOfBirth: applicant?.dateOfBirth,
      remark: applicant?.remark || '',
    },
    validationSchema: generalInformationSchema({ dateOfBirth: dateOfBirth, variants }),
    onSubmit: (values) => {
      //TODO:
    },
  });

  function handleInputChange(fieldName: IGeneralInformationKeys) {
    return function (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
      formik.setFieldValue(fieldName, e.target.value);
    };
  }
  function handleGraduateChange(fieldName: string, resetField: string) {
    return function (_: any, value: boolean) {
      formik.setFieldValue(fieldName, value);
      formik.setFieldValue(resetField, '');
    };
  }

  const handleOkConfirmPopup = (confirmPopupName: 'SAVE' | 'SAVE_AND_VERIFY') => async () => {
    await formik.submitForm();

    if (!Object.keys(formik.errors).length) {
      if (confirmPopupName === 'SAVE') {
        setConfirmMessage(SAVE_MESSAGE);
        setShowConfirmPopup(true);
      }
      if (confirmPopupName === 'SAVE_AND_VERIFY') {
        setConfirmMessage(SAVE_AND_VERIFY_MESSAGE);
        setShowConfirmPopup(true);
      }
    }
  };

  function handleTogglePopup() {
    setShowConfirmPopup((status) => !status);
  }

  return (
    <Box px={3}>
      <ConfirmPopup
        open={isShowConfirmPopup}
        title="Notification"
        content={confirmMessage}
        onCancel={handleTogglePopup}
        onOk={handleConfirmPopup}
        btnCancelVariant="outlined"
        btnOkVariant="filled"
      />
      <div>
        <HeaderSticky>
          <GeneralInformationPopupTitle>General Information</GeneralInformationPopupTitle>
          <Close onClick={onClose}>
            <CloseIcon fill="white" />
          </Close>
        </HeaderSticky>
        <Box mb={3} display="flex" style={{ gap: 24 }}>
          <Box flex="1">
            <Label required>Surname (as in NRIC)</Label>
            <FormTextField
              onChange={handleInputChange('surname')}
              variant="outlined"
              fullWidth
              size="small"
              onBlur={formik.handleBlur}
              value={formik.values.surname}
              error={formik.touched.surname && !!formik.errors.surname}
              helperText={formik.touched.surname && formik.errors.surname}
              name="surname"
              inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
            />
          </Box>
          <Box flex="1">
            <Label required>Given Name (as in NRIC)</Label>
            <FormTextField
              onChange={handleInputChange('givenName')}
              variant="outlined"
              fullWidth
              size="small"
              onBlur={formik.handleBlur}
              value={formik.values.givenName}
              error={formik.touched.givenName && !!formik.errors.givenName}
              helperText={formik.touched.givenName && formik.errors.givenName}
              name="givenName"
              inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
            />
          </Box>
        </Box>
        <Box mb={3}>
          <Label>中文名字</Label>
          <FormTextField
            onChange={handleInputChange('chineseName')}
            variant="outlined"
            fullWidth
            size="small"
            onBlur={formik.handleBlur}
            value={formik.values.chineseName}
            error={formik.touched.chineseName && !!formik.errors.chineseName}
            helperText={formik.touched.chineseName && formik.errors.chineseName}
            name="chineseName"
            inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
          />
        </Box>
        <Box mb={3} display="flex" style={{ gap: 24 }}>
          <Box flex="1">
            <Label required>Date Of Birth</Label>
            <GeneralInformationDatePicker
              fullWidth
              autoOk
              variant="inline"
              inputVariant="outlined"
              format={DATE_PICKER_DISPLAY_FORMAT}
              name="dateOfBirth"
              value={moment(formik.values?.dateOfBirth, 'YYYY-MM-DD')}
              InputAdornmentProps={{ position: 'end' }}
              onChange={(date) => {
                formik.setFieldValue('dateOfBirth', date);
                setDateOfBirth(date);
              }}
              invalidDateMessage=""
            />
          </Box>
          <Box flex="1">
            <Label required>Gender</Label>
            <FormTextField
              variant="outlined"
              name="gender"
              fullWidth
              size="small"
              value={GENDER.find((i) => i.value === formik.values?.gender)?.value}
              onChange={handleInputChange('gender')}
              select={true}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                },
              }}
            >
              {GENDER.map(({ value, label }, idx) => (
                <FormMenuItem key={idx} value={value}>
                  {label}
                </FormMenuItem>
              ))}
            </FormTextField>
          </Box>
        </Box>
        <Box mb={3}>
          <Label required>Email Address</Label>
          <FormTextField
            variant="outlined"
            fullWidth
            size="small"
            onChange={handleInputChange('emailAddress')}
            onBlur={formik.handleBlur}
            value={formik.values.emailAddress}
            error={formik.touched.emailAddress && !!formik.errors.emailAddress}
            helperText={formik.touched.emailAddress && formik.errors.emailAddress}
            name="emailAddress"
            inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
          />
        </Box>
        <Box mb={3}>
          <Label required>Phone number</Label>
          <Box display="flex" alignItems="flex-start" style={{ gap: 24 }}>
            <Box flex="0.5">
              <ReactFlagsSelectStyled
                fullWidth
                selected={formik.values.areaCode ?? ''}
                onSelect={(v) => {
                  formik.setFieldValue('areaCode', v);
                }}
                searchable
                customLabels={customLabels}
                showSecondaryOptionLabel
                showSelectedLabel={false}
              />
            </Box>
            <Box flex="0.5">
              <FormTextField
                variant="outlined"
                fullWidth
                size="small"
                value={formik.values.mobilePhone}
                onChange={handleInputChange('mobilePhone')}
                name="mobilePhone"
                onBlur={formik.handleBlur}
                error={formik.touched.mobilePhone && !!formik.errors.mobilePhone}
                helperText={formik.touched.mobilePhone && formik.errors.mobilePhone}
                inputProps={{ maxLength: 15 }}
              />
            </Box>
          </Box>
        </Box>
        <Box mb={3}>
          <Box display="flex">
            <Box flex="0.4">
              <Label required>Which school have you attend?</Label>
              <ErrorMessage>{formik.errors.isChildAttend}</ErrorMessage>
            </Box>
            <Box flex="0.6">
              <Label required>Year of Graduation / Leave School</Label>
            </Box>
          </Box>
          <Box display="flex" mb={3}>
            <Box flex="0.4">
              <FormControlLabel
                label="Nanyang Kindergarten"
                control={
                  <Checkbox
                    color="primary"
                    checked={formik.values.isCheckPeriodStudyInNYK}
                    onChange={handleGraduateChange('isCheckPeriodStudyInNYK', 'periodStudyInNYK')}
                  />
                }
              />
            </Box>
            <Box flex="0.6">
              <FormTextField
                variant="outlined"
                color="primary"
                fullWidth
                size="small"
                disabled={!formik.values.isCheckPeriodStudyInNYK}
                value={formik.values.periodStudyInNYK}
                onChange={handleInputChange('periodStudyInNYK')}
                name="periodStudyInNYK"
                onBlur={formik.handleBlur}
                error={
                  formik.values.isCheckPeriodStudyInNYK &&
                  formik.touched.periodStudyInNYK &&
                  !!formik.errors.periodStudyInNYK
                }
                helperText={
                  formik.values.isCheckPeriodStudyInNYK &&
                  formik.touched.periodStudyInNYK &&
                  formik.errors.periodStudyInNYK
                }
                inputProps={{ maxLength: 4 }}
              />
            </Box>
          </Box>
          <Box display="flex" mb={3}>
            <Box flex="0.4">
              <FormControlLabel
                label="Nanyang Pre-Primary"
                control={
                  <Checkbox
                    color="primary"
                    checked={formik.values.isCheckPeriodStudyInNPP}
                    onChange={handleGraduateChange('isCheckPeriodStudyInNPP', 'periodStudyInNPP')}
                  />
                }
              />
            </Box>
            <Box flex="0.6">
              <FormTextField
                variant="outlined"
                fullWidth
                size="small"
                disabled={!formik.values.isCheckPeriodStudyInNPP}
                value={formik.values.periodStudyInNPP}
                onChange={handleInputChange('periodStudyInNPP')}
                name="periodStudyInNPP"
                onBlur={formik.handleBlur}
                error={
                  formik.values.isCheckPeriodStudyInNPP &&
                  formik.touched.periodStudyInNPP &&
                  !!formik.errors.periodStudyInNPP
                }
                helperText={
                  formik.values.isCheckPeriodStudyInNPP &&
                  formik.touched.periodStudyInNPP &&
                  formik.errors.periodStudyInNPP
                }
                inputProps={{ maxLength: 4 }}
              />
            </Box>
          </Box>
          <Box display="flex" mb={3}>
            <Box flex="0.4">
              <FormControlLabel
                label="Nanyang Primary"
                control={
                  <Checkbox
                    color="primary"
                    checked={formik.values.isCheckPeriodStudyInNYP}
                    onChange={handleGraduateChange('isCheckPeriodStudyInNYP', 'periodStudyInNYP')}
                  />
                }
              />
            </Box>
            <Box flex="0.6">
              <FormTextField
                variant="outlined"
                fullWidth
                size="small"
                disabled={!formik.values.isCheckPeriodStudyInNYP}
                value={formik.values.periodStudyInNYP}
                onChange={handleInputChange('periodStudyInNYP')}
                name="periodStudyInNYP"
                onBlur={formik.handleBlur}
                error={
                  formik.values.isCheckPeriodStudyInNYP &&
                  formik.touched.periodStudyInNYP &&
                  !!formik.errors.periodStudyInNYP
                }
                helperText={
                  formik.values.isCheckPeriodStudyInNYP &&
                  formik.touched.periodStudyInNYP &&
                  formik.errors.periodStudyInNYP
                }
                inputProps={{ maxLength: 4 }}
              />
            </Box>
          </Box>
          <Box display="flex" mb={3}>
            <Box flex="0.4">
              <FormControlLabel
                label="Nanyang Girls' High"
                control={
                  <Checkbox
                    color="primary"
                    checked={formik.values.isCheckPeriodStudyInNYGH}
                    onChange={handleGraduateChange('isCheckPeriodStudyInNYGH', 'periodStudyInNYGH')}
                  />
                }
              />
            </Box>
            <Box flex="0.6">
              <FormTextField
                variant="outlined"
                fullWidth
                size="small"
                disabled={!formik.values.isCheckPeriodStudyInNYGH}
                value={formik.values.periodStudyInNYGH}
                onChange={handleInputChange('periodStudyInNYGH')}
                name="periodStudyInNYGH"
                onBlur={formik.handleBlur}
                error={
                  formik.values.isCheckPeriodStudyInNYGH &&
                  formik.touched.periodStudyInNYGH &&
                  !!formik.errors.periodStudyInNYGH
                }
                helperText={
                  formik.values.isCheckPeriodStudyInNYGH &&
                  formik.touched.periodStudyInNYGH &&
                  formik.errors.periodStudyInNYGH
                }
                inputProps={{ maxLength: 4 }}
              />
            </Box>
          </Box>
        </Box>
        <Box mb={3}>
          <Label required>Mailing Address</Label>
          <FormTextField
            variant="outlined"
            fullWidth
            size="small"
            onChange={handleInputChange('mailingAddress')}
            onBlur={formik.handleBlur}
            value={formik.values.mailingAddress}
            error={formik.touched.mailingAddress && !!formik.errors.mailingAddress}
            helperText={formik.touched.mailingAddress && formik.errors.mailingAddress}
            name="mailingAddress"
            inputProps={{ maxLength: 128 }}
          />
        </Box>
        <Box mb={3}>
          <Label required>Postal Code</Label>
          <FormTextField
            variant="outlined"
            fullWidth
            size="small"
            onChange={handleInputChange('postalCode')}
            onBlur={formik.handleBlur}
            value={formik.values.postalCode}
            error={formik.touched.postalCode && !!formik.errors.postalCode}
            helperText={formik.touched.postalCode && formik.errors.postalCode}
            name="postalCode"
            inputProps={{ maxLength: 6 }}
          />
        </Box>
        <Box mb={3}>
          <Label>Nationality</Label>
          <FormTextField
            variant="outlined"
            fullWidth
            size="small"
            onChange={handleInputChange('nationality')}
            onBlur={formik.handleBlur}
            value={NATIONALITY.find((i) => i.value === formik.values.nationality)?.value}
            error={formik.touched.nationality && !!formik.errors.nationality}
            helperText={formik.touched.nationality && formik.errors.nationality}
            name="nationality"
            select={true}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              },
            }}
          >
            {NATIONALITY.map(({ value, label }, idx) => (
              <FormMenuItem key={idx} value={value}>
                {label}
              </FormMenuItem>
            ))}
          </FormTextField>
        </Box>
        <Box mb={3}>
          <Label>Job Title</Label>
          <FormTextField
            variant="outlined"
            fullWidth
            size="small"
            onBlur={formik.handleBlur}
            value={formik.values.jobTitle}
            error={formik.touched.jobTitle && !!formik.errors.jobTitle}
            helperText={formik.touched.jobTitle && formik.errors.jobTitle}
            name="jobTitle"
            onChange={handleInputChange('jobTitle')}
            inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
          />
        </Box>
        <Box mb={3}>
          <Label>Company Name</Label>
          <FormTextField
            variant="outlined"
            fullWidth
            size="small"
            onBlur={formik.handleBlur}
            value={formik.values.companyName}
            error={formik.touched.companyName && !!formik.errors.companyName}
            helperText={formik.touched.companyName && formik.errors.companyName}
            name="companyName"
            onChange={handleInputChange('companyName')}
            inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
          />
        </Box>
        <Box mb={3}>
          <Label>Skills</Label>

          <FormGroup>
            {SKILLS.map(({ label, value }) => (
              <FormControlLabel
                label={label}
                control={
                  <Checkbox
                    color="primary"
                    name={value}
                    value={value}
                    checked={!!formik.values.skills?.[value as ISkillKey]}
                    onChange={(e, checked) =>
                      formik.setFieldValue('skills', { ...formik.values.skills, [value]: checked })
                    }
                  />
                }
              />
            ))}
          </FormGroup>
        </Box>
        <Box mb={3}>
          <Label>Other Skills</Label>
          <FormTextField
            variant="outlined"
            fullWidth
            size="small"
            value={formik.values.skills['other-skills']}
            onChange={(e) =>
              formik.setFieldValue('skills', { ...formik.values.skills, 'other-skills': e.target.value })
            }
            inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
          />
        </Box>
        <Box mb={3}>
          <Label>Remark</Label>
          <FormTextField
            variant="outlined"
            fullWidth
            size="small"
            value={formik.values.remark}
            onChange={handleInputChange('remark')}
            inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
          />
        </Box>
        <FooterSticky>
          <ActionButton onClick={onClose} variants="outlined">
            Cancel
          </ActionButton>
          <ActionButton
            type="button"
            variants="outlined"
            disabled={isSaveLoading}
            onClick={handleOkConfirmPopup('SAVE')}
          >
            {isSaveLoading && (
              <IconButton>
                <CircularProgress size={20} />
              </IconButton>
            )}
            Save
          </ActionButton>
          <ActionButton
            type="button"
            variants="yellow"
            disabled={isSaveAndVerifyLoading}
            onClick={handleOkConfirmPopup('SAVE_AND_VERIFY')}
          >
            {isSaveAndVerifyLoading && (
              <IconButton>
                <CircularProgress size={20} />
              </IconButton>
            )}
            Save & Verify
          </ActionButton>
        </FooterSticky>
      </div>
    </Box>
  );
};

export default GeneralInformationPopup;
