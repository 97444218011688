import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { localStorageHelper } from 'src/helpers/localstorage';

export const rootApi = createApi({
  tagTypes: ['EVENTS', 'USERS'],
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers, { getState }) => {
      headers.append('Authorization', `Bearer ${localStorageHelper.getItem(process.env.REACT_APP_ACCESS_TOKEN_KEY!)}`);
      return headers;
    },
    baseUrl: process.env.REACT_APP_API_URL!,
  }),
  endpoints: () => ({}),
});
