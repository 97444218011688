import httpClient from 'src/configs/http-client';
import { IResponse } from 'src/types';

import { IAuth, IEmail } from './auth.interface';

import { IMailingList, INewPassword } from '.';

export const login = async (username: string, password: string, recaptchaToken: string) => {
  const response = await httpClient.post<IResponse<IAuth>>(`${process.env.REACT_APP_API_URL}/auth/signin`, {
    username: btoa(username),
    password: btoa(password),
    recaptchaToken: process.env.REACT_APP_ENV === 'development' ? 'development' : recaptchaToken,
  });
  return response?.data;
};

export const forgotPassword = async (email: string) => {
  const response = await httpClient.post<IResponse<IEmail>>(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
    email,
  });
  return response?.data;
};

export const signUp = async (body: any) => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/auth/signup`, body);
  return response?.data;
};

export const resetPassword = async ({
  newPassword,
  username,
  token,
}: {
  newPassword: string;
  username: string;
  token: string;
}) => {
  const response = await httpClient.post<IResponse<INewPassword>>(
    `${process.env.REACT_APP_API_URL}/auth/reset-password`,
    {
      newPassword,
      username,
      token,
    },
  );
  return response?.data;
};

export const checkTokenResetPass = async (token: any) => {
  const response = await httpClient.get<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/auth/check-token?token=${token}`,
  );
  return response?.data;
};

export const mailingSend = async (body: IMailingList) => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/auth/mailing-list`, body);
  return response?.data;
};

export const sendBlockToIP = async () => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/block`);
  return response?.data;
};
