import React, { useEffect, useState } from 'react';

import { Box, Grid, IconButton, InputAdornment, Link, makeStyles, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import _ from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { CustomButton, InputField } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import { RECAPTCHA_SITE_KEY } from 'src/constants/recaptcha';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import LogoJSA from 'src/medias/images/main-nsaa-logo.png';
import SignInBackground from 'src/medias/images/signin-background.jpg';
import { selectAuth } from 'src/store/authentication/slices';
import {
  containsNumericCharacters,
  containsSpecialCharacters,
  containsUpperAndLowerCaseRegexp,
  intRegex,
  isSpace,
  validEmailRegex,
} from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { logInWithPasswordAction } from '../../store/authentication/action';
import { selectLoginState, selectLoginStatus, selectPreviousPath } from '../../store/authentication/selector';
import { useAppDispatch } from '../../store/hooks';
import { CustomTextS, LinkButton } from '../signup/components/types';

import { LoginMobile } from './LoginMobile';

const TEXT_REQUIRE = 'This field is required';
const ERROR_PASSWORD_MESSAGE = 'Please input a valid password';
const ERROR_USERNAME_MESSAGE = 'Username must be an email address or a user-id has 10 number';
const MINIMUM_PASSWORD_LENGTH = 8;
const MAXIMUM_PASSWORD_LENGTH = 32;
const USER_ID_LENGTH = 10;

const useStyles = makeStyles(() => ({
  left: {
    minHeight: '100vh',
    position: 'relative',
  },
}));

export const NYSImage = styled.img<{ objectFitContain?: boolean }>(({ theme, objectFitContain }) => ({
  width: '100%',
  minHeight: '100vh',
  ...(objectFitContain
    ? {
        objectFit: 'contain',
      }
    : {}),
}));

export const Logo = styled.img(({ theme }) => ({
  position: 'absolute',
  top: 54,
  left: 80,
  width: 70,
  height: 90,
}));

export const BoxPosition = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '20%',
  top: '40%',
}));

export const TitleBoxPosition = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
  color: 'white',
  fontSize: 22,
}));

export const DesBoxPosition = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textS,
  color: 'white',
  textAlign: 'center',
  marginTop: 16,
  lineHeight: 2,
}));

export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: 32,
  fontSize: 36,
  fontWeight: 700,
  color: 'rgba(51, 51, 51, 1)',
}));
export const TitleResponse = styled(Typography)(({ theme }) => ({
  marginBottom: 32,
  fontSize: 24,
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.8)',
}));
export const TitleLogin = styled(Typography)(({ theme }) => ({
  marginBottom: 32,
  fontSize: 36,
  fontWeight: 700,
  color: theme.palette.yellow?.[900],
}));

export const TitleSmall = styled(Typography)(({ theme }) => ({
  marginBottom: 32,
  ...theme.fonts.header3,
  color: theme.palette.yellow?.[900],
}));

const CustomLink = styled.div(({ theme }) => ({
  textAlign: 'right',
  marginTop: 8,
  marginBottom: 16,
}));

export const Form = styled.form(({ theme }) => ({
  width: '60%',
  paddingTop: 150,
}));

export const Note = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textSs,
  marginBottom: 32,
  color: 'black',
}));

export const Login = () => {
  useScrollToTop();
  const { isMobile, isTabletOrMobile } = useResponsive();
  const classes = useStyles(isMobile);
  const isLogin = useSelector(selectLoginState);
  const { loading } = useSelector(selectAuth);

  const loginStatus = useSelector(selectLoginStatus);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<{ username: string; password: string }>({ username: '', password: '' });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    username: false,
    password: false,
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const previousPath = useSelector(selectPreviousPath);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const environment = process.env.REACT_APP_ENV;
  const params = new URLSearchParams(window.location.search);
  let type: string | null;
  let eventId: string | null;
  if (params) {
    eventId = params.get('eventId');
    type = params.get('type');
  }

  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [e.target.name]: e.target.value });

    if (e.target.name === 'username') {
      if (validEmailRegex.test(e.target.value)) {
        setValidate('username', validEmailRegex.test(e.target.value) ? e.target.value : '');
        return;
      }

      if (e.target.value.length !== USER_ID_LENGTH || !intRegex.test(e.target.value)) {
        setValidate('username', '');
        return;
      } else {
        setValidate('username', e.target.value);
        return;
      }
    }

    if (e.target.name === 'password') {
      const passwordLongEnough = _.size(e.target.value) >= MINIMUM_PASSWORD_LENGTH;

      const isPasswordValid =
        passwordLongEnough &&
        containsUpperAndLowerCaseRegexp.test(e.target.value) &&
        containsSpecialCharacters.test(e.target.value) &&
        !isSpace(e.target.value) &&
        containsNumericCharacters.test(e.target.value);

      setValidate('password', isPasswordValid ? e.target.value : '');
    }
  }

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  }

  function _onClickSignUp() {
    window.location.href = '/signup';
  }

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    dispatch(logInWithPasswordAction({ ...data, recaptchaToken }));
    setOpenSnackBar(true);
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleClear() {
    setData({ ...data, username: '' });
  }

  function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
  }

  const onChangeCaptcha = (token?: string | null) => {
    if (token) {
      setRecaptchaToken(token);
    }
  };

  let redirectTo = '/';
  if (!/\/login/.test(previousPath) && previousPath) {
    redirectTo = previousPath;
  }

  useEffect(() => {
    if (isLogin) {
      if (type && (type === 'check-in' || type === 'check-out') && eventId) {
        return;
      } else {
        let timeOut = setTimeout(() => {
          window.location.href = redirectTo;
        }, 2000);

        return () => {
          clearTimeout(timeOut);
        };
      }
    }
  }, [isLogin]);

  useEffect(() => {
    if (environment === 'development') {
      setRecaptchaToken('development');
    }
  }, []);

  useEffect(() => {
    if (loading) {
      recaptchaRef?.current?.reset();
      if (environment !== 'development') {
        setRecaptchaToken('');
      }
    }
  }, [loading]);

  return (
    <Box>
      {isTabletOrMobile ? (
        <LoginMobile />
      ) : (
        <Grid container>
          <Grid item xs={6} className={classes.left}>
            <Box display="flex" width={'100%'} height={'100%'} pt={'150px'}>
              <NYSImage src={SignInBackground} objectFitContain />
              <Link href="/">
                <Logo src={LogoJSA} />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              width={'100%'}
              height={'100%'}
              justifyContent="center"
              display="flex"
              alignItems="center"
              bgcolor="#f9f9f9"
            >
              <Form onSubmit={handleSubmit}>
                <TitleLogin>LOG IN</TitleLogin>
                <Box mb={4}>
                  <CustomTextS>
                    We have a new look, but there’s no need to create a new account if you already have an NSAA ID. You
                    can continue to use your NSAA ID or your registered email address to set a new password.
                  </CustomTextS>
                </Box>
                <Box>
                  <InputField
                    value={data.username}
                    title="Username"
                    fullWidth
                    type="text"
                    name="username"
                    onChange={handleChange}
                    margin="dense"
                    notice
                    noticeYellow
                    noticeTitle={<Typography color="inherit">{ERROR_USERNAME_MESSAGE}</Typography>}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClear} edge="end">
                            {data.username !== '' && <ClearIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={errors.username}
                    errormessage={errors.username && ERROR_USERNAME_MESSAGE}
                  />
                </Box>
                <Box mt={2} />
                <InputField
                  fullWidth
                  value={data.password}
                  type={showPassword ? 'text' : 'password'}
                  onChange={handleChange}
                  title="Password"
                  name="password"
                  margin="dense"
                  InputProps={{
                    inputProps: {
                      maxLength: MAXIMUM_PASSWORD_LENGTH,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={errors.password}
                  errormessage={errors.password && data.password === '' ? TEXT_REQUIRE : ERROR_PASSWORD_MESSAGE}
                />
                <CustomLink>
                  <Link style={{ color: '#C5BE8B' }} href="/forgot-password" underline="always">
                    {'Forgot your password?'}
                  </Link>
                </CustomLink>
                <Box mt={'24px'} mb={'24px'} display="flex" justifyContent="center" alignItems="center">
                  <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} onChange={onChangeCaptcha} />
                </Box>
                <Box mb={4} display="flex" justifyContent="center">
                  <CustomButton disabled={environment === 'production' && recaptchaToken === ''} type="submit">
                    LOG IN
                  </CustomButton>
                </Box>
                <Box mt={2} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                  <Note>
                    Don’t have an account yet?
                    <LinkButton onClick={_onClickSignUp} variant="text">
                      Sign up
                    </LinkButton>
                  </Note>
                </Box>
              </Form>
            </Box>
            {!loading && loginStatus.statusCode === CALL_SUCCESS && openSnackBar && (
              <SuccessSnackBar message={loginStatus.message} open={openSnackBar} handleClose={handleCloseSnackBar} />
            )}
            {!loading &&
              loginStatus.statusCode !== undefined &&
              loginStatus.statusCode !== CALL_SUCCESS &&
              openSnackBar && (
                <ErrorSnackBar message={loginStatus.message} open={openSnackBar} handleClose={handleCloseSnackBar} />
              )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
