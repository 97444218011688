export const TOS = [
  {
    id: 1647317526652,
    type: 'h1',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: 'NSAA Terms of Use',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        underline: true,
      },
    ],
  },
  {
    id: 1647395252946,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317526669,
    type: 'p',
    children: [
      {
        text: 'Access to this website is granted by Nanyang Schools Alumni Association (',
      },
      {
        text: '“NSAA”',
        bold: true,
      },
      {
        text: '), subject to these Terms of Use and our Privacy Policy.',
      },
      {
        text: ' By accessing this website, you agree to be bound by these Terms of Use and our Privacy Policy',
        bold: true,
      },
      {
        text: '. If you are not comfortable with any of these Terms of Use and/or Privacy Policy, please discontinue your access to this website immediately. ',
      },
    ],
  },
  {
    id: 1647395675270,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317526668,
    type: 'p',
    children: [
      {
        text: 'Changes to these Terms of Use and/or the Privacy Policy effective immediately may be posted on this website from time to time. Your continued access to this website will constitute your unconditional acceptance of such revised Terms of Use or Privacy Policy.',
      },
    ],
  },
  {
    id: 1647398582465,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317526667,
    type: 'p',
    children: [
      {
        text: '1. This website and its contents, including services, products, information, data, text, graphics, audio, video, links (third party or otherwise), advertisements or other items (',
      },
      {
        text: '“Content”',
        bold: true,
      },
      {
        text: ') is provided "as is" and on an "as available" basis.',
      },
    ],
  },
  {
    id: 1647398261862,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317526666,
    type: 'p',
    children: [
      {
        text: '2. NSAA provides no representation, warranty or undertaking (as the case may be), express or implied, to anyone of any kind, including:',
      },
    ],
  },
  {
    id: 1647317526665,
    type: 'p',
    children: [
      {
        text: '   a. To the maximum extent permitted, all conditions, warranties and other terms that may otherwise be implied by law into these Terms of Use.',
      },
    ],
  },
  {
    id: 1647317526665,
    type: 'p',
    children: [
      {
        text: '   b. The availability of this website or that there will be no interruption or error in any of this website’s feature or function.',
      },
    ],
  },
  {
    id: 1647317526664,
    type: 'p',
    children: [
      {
        text: '   c. In relation to any title in connection with this website and its Content, or any non-infringement of third party rights.',
      },
    ],
  },
  {
    id: 1647317526663,
    type: 'p',
    children: [
      {
        text: '   d. that this website or the servers or third party links therein that make it available will be free of viruses, malware, trojan horses, worms, similar items or processes, other harmful components or other malicious, destructive or corrupting code, agent program or macros.',
      },
    ],
  },
  {
    id: 1647317526662,
    type: 'p',
    children: [
      {
        text: '   e. that this website will be compatible with all software and hardware or that it will be secure.',
      },
    ],
  },
  {
    id: 1647317526661,
    type: 'p',
    children: [
      {
        text: '   f. that this website is of satisfactory fitness or quality for any general or particular purpose',
      },
    ],
  },
  {
    id: 1647317526660,
    type: 'p',
    children: [
      {
        text: '   g. that the information on this website is complete, true, accurate, non-misleading, reliable or valid; ',
      },
    ],
  },
  {
    id: 1647317526659,
    type: 'p',
    children: [
      {
        text: '   h. that any graph, chart, formula, illustration on part performance, prediction, projection, forecast or other devices set out or referred to in this website may be indicative, used to determine or assist any person in deciding future trends of any economy, liquidity market;',
      },
    ],
  },
  {
    id: 1647317526659,
    type: 'p',
    children: [
      {
        text: '   i. that any defects and/or errors on this website will be corrected immediately or promptly;',
      },
    ],
  },
  {
    id: 1647317526658,
    type: 'p',
    children: [
      {
        text: '   j. in relation to the character, credit worthiness, business or financial situation of any person, event or product, or any assumption and/or analysis of information in the Content; and',
      },
    ],
  },
  {
    id: 1647317526656,
    type: 'p',
    children: [
      {
        text: '   k. in respect of any content, service (including any payment function or event booking service), product, material or information contained in any third party link or website. We are not responsible for and may not endorse the availability or contents of such third party websites or resources and does not constitute or imply any sponsorship, recommendation or any other relationship between NSAA and such third party.',
      },
    ],
  },
  {
    id: 1647398274567,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317526656,
    type: 'p',
    children: [
      {
        text: '3. Under no circumstances will NSAA (or our members, agents, committee or advisors) be liable for, assume or accept any liability for any Content on this website, including (without limitation) any errors or omissions in any Content, or for any (direct or indirect, consequential or otherwise) loss, expenses or damage of any kind incurred (monetary or otherwise, howsoever caused) as a result of or arising from your use of this website (for the Activities or otherwise) or any Content, including any loss of data, opportunity, goodwill or profits',
      },
    ],
  },
  {
    id: 1647398285712,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317526655,
    type: 'p',
    children: [
      {
        text: '4. The names, images and logos (',
      },
      {
        text: '"Marks"',
        bold: true,
      },
      {
        text: ') identifying NSAA or third parties and their products and services are subject to copyright, design rights and trademarks respectively, of NSAA and/or third parties, and all rights to the Marks are expressly reserved accordingly. You shall have no right or licence to download, reproduce or use any such Marks or the name of NSAA whether for use as a link to any website or otherwise, except with our prior approval.',
      },
    ],
  },
  {
    id: 1647398288649,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317526654,
    type: 'p',
    children: [
      {
        text: '5. Our Privacy Policy applies to any personal data submitted to NSAA whether through this website or otherwise.',
      },
    ],
  },
  {
    id: 1647398308819,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317526653,
    type: 'p',
    children: [
      {
        text: '6. By accessing this website, you agree that you will not:',
      },
    ],
  },
  {
    id: 1647317679807,
    type: 'p',
    children: [
      {
        text: '   a. be accessing this website from territories where its contents are deemed illegal or unlawful is prohibited, and will be fully able and competent to enter into these Terms of Use and be responsible for your own compliance with local laws in the territory of access;',
      },
    ],
  },
  {
    id: 1647317689696,
    type: 'p',
    children: [
      {
        text: '   b. show, re-transmit, re-distribute, disclose or copy, or rely upon, in whole or in part, by any person for whatever purpose without NSAA’s prior consent;',
      },
    ],
  },
  {
    id: 1647317698690,
    type: 'p',
    children: [
      {
        text: '   c. use this website in any way which is unlawful, illegal, fraudulent, threatening, harassing, vulgar, obscene, abusive or harmful, or otherwise objectionable, or in connection with any such purpose or activity;',
      },
    ],
  },
  {
    id: 1647317707354,
    type: 'p',
    children: [
      {
        text: "   d. use this website to defame, abuse, harass, stalk, threaten or otherwise violate the rights of others or NSAA, including without limitation others' privacy rights or rights of publicity; ",
      },
    ],
  },
  {
    id: 1647317714650,
    type: 'p',
    children: [
      {
        text: '   e. impersonate any person or entity, falsely state or otherwise misrepresent your particulars, affiliation with NSAA or any person or entity in connection with this website or express or imply that NSAA endorses any statement you make (on this website or otherwise);',
      },
    ],
  },
  {
    id: 1647317720186,
    type: 'p',
    children: [
      {
        text: '   f. interfere with or disrupt the operation of this website or the servers or networks used to make this website available or violate any requirements, procedures, policies or regulations of such networks; ',
      },
    ],
  },
  {
    id: 1647317730066,
    type: 'p',
    children: [
      {
        text: '   g. transmit or otherwise make available in connection with this website any virus, worm or other computer code that is harmful or invasive or may or is intended to damage the operation of, or to monitor the use of, any hardware, software, or equipment; ',
      },
    ],
  },
  {
    id: 1647317739578,
    type: 'p',
    children: [
      {
        text: '   h. remove any copyright, trade mark or other proprietary rights notice from this website; ',
      },
    ],
  },
  {
    id: 1647317747786,
    type: 'p',
    children: [
      {
        text: '   i. make any financial decision or commitment before carefully considering the risks and seeking professional advice (where applicable);',
      },
    ],
  },
  {
    id: 1647317748380,
    type: 'p',
    children: [
      {
        text: '   j. without NSAA’s prior written approval, use any information found on this website to canvass or solicit any person or entice any person to perform any act (whether or not related to the NSAA); or',
      },
    ],
  },
  {
    id: 1647317801103,
    type: 'p',
    children: [
      {
        text: '   k. insert a hyperlink to or of this website or any part thereof or “mirror” any Content on any other server, website or web page.',
      },
    ],
  },
  {
    id: 1647398327271,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317807450,
    type: 'p',
    children: [
      {
        text: '7. NSAA membership account (the',
      },
      {
        text: ' "Account"',
        bold: true,
      },
      {
        text: ')',
      },
    ],
  },
  {
    id: 1647317837150,
    type: 'p',
    children: [
      {
        text: "   a. You are entirely responsible for security related to access to your Account and all information provided by you. NSAA bears no responsibility for any breach of security or unauthorised access to your Account. You are advised to: (i) avoid selecting passwords easily related to you; (2) keep your password and any related secret information secure and confidential and do not share them with anyone else; and (3) restrict access to your devices which you use to access your Account. You are solely responsible for any harm resulting from your disclosure, or authorization of the disclosure, of your password or from any person's use of your password to gain access to your Account.",
      },
    ],
  },
  {
    id: 1647317846671,
    type: 'p',
    children: [
      {
        text: '   b. You are solely responsible for all activities conducted through your Account whether or not you authorise the activity. In the event that fraud, illegality or other conduct that violates this Agreement is discovered or reported (whether by you or someone else) that is connected with your Account, NSAA may (at sole discretion) suspend, block or terminate your Account.',
      },
    ],
  },
  {
    id: 1647317860618,
    type: 'p',
    children: [
      {
        text: '   c. NSAA will not be liable for any loss or damage (of any kind or of whatsoever nature) to you or any third party arising from your inability or failure for any reason to comply with any of the foregoing obligations, or for any reason whatsoever.',
      },
    ],
  },
  {
    id: 1647317868154,
    type: 'p',
    children: [
      {
        text: '   d. Please note that NSAA membership terms and conditions apply, and NSAA may at sole discretion disable, suspend or terminate an Account for breach of these Terms of Use or the NSAA membership terms and conditions .',
      },
    ],
  },
  {
    id: 1647317873738,
    type: 'p',
    children: [
      {
        text: '   e. Please be reminded to update your personal details in your Account so that NSAA communications can reach you in a timely manner and to avoid misdirected NSAA communications.',
      },
    ],
  },
  {
    id: 1647398338763,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317883346,
    type: 'p',
    children: [
      {
        text: '8. Youth members ',
      },
    ],
  },
  {
    id: 1647317907649,
    type: 'p',
    children: [
      {
        text: '   a. NSAA youth members and applicants for NSAA youth memberships below the age of 21 (',
      },
      {
        text: '“Youth”',
        bold: true,
      },
      {
        text: ') cannot create an Account on your own. You will need to ask your parent/guardian to submit their name and email address to our NSAA contact address, after which we will send instructions on how to create an Account for Youth and a parental consent form required to process the Youth’s Account.',
      },
    ],
  },
  {
    id: 1647317918726,
    type: 'p',
    children: [
      {
        text: '   b. Youth should access this website with parental guidance and be careful in disclosing any information (particularly personal information) to anyone in connection with access to the website and the Youth Account. ',
      },
    ],
  },
  {
    id: 1647317928098,
    type: 'p',
    children: [
      {
        text: '   c. In accessing this website, both Youth and your parent/guardian understand and agree to these Terms of Use jointly and severally (with them on your behalf). Where you are a parent/guardian agreeing to these terms for the benefit of a Youth, you agree and accept full responsibility for the Youth’s access to this website including all financial transactions, charges and legal liability related to the Youth’s Activities or for which the Youth may incur.',
      },
    ],
  },
  {
    id: 1647317935930,
    type: 'p',
    children: [
      {
        text: '   d. Unless otherwise granted by NSAA, an NSAA youth member Account expires on the last day of the calendar year that they turn 25 years old.',
      },
    ],
  },
  {
    id: 1647398343236,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317946290,
    type: 'p',
    children: [
      {
        text: '9. Financial transactions ',
      },
    ],
  },
  {
    id: 1647317947134,
    type: 'p',
    children: [
      {
        text: '   a. This website may from time to time provide you access to membership application (including applicable membership fee submission), event bookings, event ticket purchase, volunteer registration, donation opportunities (',
      },
      {
        text: '“Activities”',
        bold: true,
      },
      {
        text: ') which involves you making payment of monies through this website or a third party financial payment service provider.',
      },
    ],
  },
  {
    id: 1647317968122,
    type: 'p',
    children: [
      {
        text: '   b.You accept and acknowledge that there are inherent risks associated with the Activities (including the risk of hardware, software and internet connections failure or problems, the risk of malicious software introduction, and the risk that third parties may obtain unauthorised access to information stored within your credit cards or financial payment modes).',
      },
    ],
  },
  {
    id: 1647317968571,
    type: 'p',
    children: [
      {
        text: '   c. NSAA has no insight into or control over these payments or transactions relating to the Activities, nor can we reverse any transactions. With that in mind, NSAA will have no liability to you or to any third party for any claims or damages that may arise as a result of any transactions that you engage in via this website or in connection with the Activities.',
      },
    ],
  },
  {
    id: 1647317974476,
    type: 'p',
    children: [
      {
        text: '   d. You represent and warrant that all funds or assets used by you in such Activities have been lawfully obtained by you in compliance with all applicable laws and that you are solely responsible for any bank charges, taxes or duties which may apply to your Activities. ',
      },
    ],
  },
  {
    id: 1647317986673,
    type: 'p',
    children: [
      {
        text: '   e. Thank you for your support in the Activities',
      },
    ],
  },
  {
    id: 1647398566066,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317994161,
    type: 'p',
    children: [
      {
        text: '10. You agree and acknowledge that we may use cookies, pixels and other technologies to recognise your browser or device, learn more about your interest and provide you with features and services to enhance your experience of this website or your NSAA membership. ',
      },
    ],
  },
  {
    id: 1647398371906,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1647317996842,
    type: 'p',
    children: [
      {
        text: '11. You may choose to submit comments, bug reports, ideas or other feedback about this website or generally NSAA (',
      },
      {
        text: '"Feedback"',
        bold: true,
      },
      {
        text: '). By submitting any Feedback, you agree to assign all rights in relation to such Feedback to NSAA and that NSAA is free to use such Feedback without additional compensation to you, and to disclose such Feedback to third parties (whether on a non-confidential basis or otherwise).',
      },
    ],
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1647398373489,
  },
  {
    id: 1647318041088,
    type: 'p',
    children: [
      {
        text: '12. If any provision of these Terms of Use or part thereof is rendered void, illegal or unenforceable by any legislation to which it is subject, it shall be rendered void, illegal or unenforceable to that extent and no further.',
      },
    ],
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1647398375081,
  },
  {
    id: 1647318074137,
    type: 'p',
    children: [
      {
        text: '13. NSAA is not registered with any regulatory authority (save for the Registry of Societies of Singapore) and does not conduct any regulated activity. This website and its Content are strictly for general information only and does not constitute, and shall not be deemed to constitute, an offer, solicitation, advice or recommendation to purchase or subscribe to any membership, event or matter generally. This website should not be construed as accounting, legal, regulatory, tax, financial or other advice.',
      },
    ],
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1647398376889,
  },
  {
    id: 1647318092312,
    type: 'p',
    children: [
      {
        text: '14. These Terms of Use are governed by the laws of Singapore and you irrevocably submit to the exclusive jurisdiction of the courts of Singapore in order to settle or resolve any dispute which may arise out of or in connection with these Terms of Use. In connection therewith, you irrevocably waive, to the fullest extent permitted by law, any objection that any of them may now or hereafter have to the laying by NSAA of the venue of any such dispute in any such court or that any such dispute brought in any such court has been brought in an inconvenient forum.',
      },
    ],
  },
  {
    id: 1647315010043,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
];
