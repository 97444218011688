import React from 'react';

import { Box, Checkbox, RadioGroup } from '@material-ui/core';
import { FormikProps } from 'formik';

import { NASSRadio } from 'src/components';
import { ErrorMessage, FormTextField, Label } from 'src/pages/profile/components/general-information.styled';
import { ReactFlagsSelectStyled } from 'src/pages/signup/components/types';
import { FormControlLabelStyled } from 'src/pages/user-management/shared/user-detail.account-settings';
import { IEvent } from 'src/services/events';
import { customLabels } from 'src/utils/areaCode';
import { isInputNumber } from 'src/utils/common';

interface Props {
  formik: FormikProps<any>;
  event: IEvent;
  isLoggedIn?: boolean;
  isAdminForm?: boolean;
}

const FillForm = ({ formik, event, isLoggedIn, isAdminForm }: Props) => {
  const INPUT_MAX_LENGTH_128 = 128;
  const INPUT_MAX_LENGTH_50 = 50;
  const INPUT_MAX_LENGTH_15 = 15;
  const INPUT_YEAR_LENGTH = 4;
  const NO = 'no';
  const THIS_FIELD_IS_REQUIRED = 'This field is required';
  const EMPTY = '';

  const IS_NOT_INCLUDE_MEAL = !formik.values.include_meal;

  const TOTAL_TICKETS = IS_NOT_INCLUDE_MEAL
    ? formik.values.totalTicket
    : +formik.values.ticketForStandardMeal + +formik.values.ticketForVegetarian + +formik.values.ticketForHalal;
  const TICKET_TYPE_2 = 2;

  const handleGraduateChange = (fieldName: string, resetField: string) => {
    return function (_: any, value: boolean) {
      formik.setFieldValue(fieldName, value);
      formik.setFieldValue(resetField, EMPTY);
    };
  };

  const handleGraduateInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, name: string) => {
    if (!isInputNumber(e.target.value) || e.target.value.includes(' ')) return;
    formik.setFieldValue(name, e.target.value);
  };

  const handleInputNumberChange =
    (name: string) => async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!isInputNumber(e.target.value)) return;
      formik.setFieldValue('totalTicket', TOTAL_TICKETS);
      formik.setFieldValue(name, e.target.value);
    };

  const setEmptyChildAttended = () => {
    formik.setFieldValue('attendedNsaa', false);
    formik.setFieldValue('isCheckPeriodStudyInNYK', false);
    formik.setFieldValue('isCheckPeriodStudyInNPP', false);
    formik.setFieldValue('isCheckPeriodStudyInNYP', false);
    formik.setFieldValue('isCheckPeriodStudyInNYGH', false);
    formik.setFieldValue('periodStudyInNPP', EMPTY);
    formik.setFieldValue('periodStudyInNYGH', EMPTY);
    formik.setFieldValue('periodStudyInNYK', EMPTY);
    formik.setFieldValue('periodStudyInNYP', EMPTY);
    formik.setFieldValue('isChildAttend', EMPTY);
  };

  return (
    <Box width={isAdminForm ? '100%' : 776} mx={isAdminForm ? '' : 'auto'} mb={3.75}>
      <Box mb={3.75}>
        <Label required>Event</Label>
        <FormTextField
          onChange={(e) => {}}
          variant="outlined"
          fullWidth
          size="small"
          onBlur={formik.handleBlur}
          value={formik.values.eventTitle}
          error={formik.touched.eventId && !!formik.errors.eventId}
          helperText={formik.touched.eventId && formik.errors.eventId}
          name="eventId"
          inputProps={{ maxLength: INPUT_MAX_LENGTH_128 }}
          disabled
        />
      </Box>
      <Box display="flex" style={{ gap: 24 }}>
        <Box flex="0.5">
          <Label required>Surname</Label>
        </Box>
        <Box flex="0.5">
          <Label required>Given Name</Label>
        </Box>
      </Box>
      <Box display="flex" style={{ gap: 24 }} mb={3.75}>
        <FormTextField
          onChange={formik.handleChange}
          variant="outlined"
          fullWidth
          size="small"
          onBlur={formik.handleBlur}
          value={formik.values.surName}
          error={formik.touched.surName && !!formik.errors.surName}
          helperText={formik.touched.surName && formik.errors.surName}
          name="surName"
          inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
        />
        <FormTextField
          onChange={formik.handleChange}
          variant="outlined"
          fullWidth
          size="small"
          onBlur={formik.handleBlur}
          value={formik.values.givenName}
          error={formik.touched.givenName && !!formik.errors.givenName}
          helperText={formik.touched.givenName && formik.errors.givenName}
          name="givenName"
          inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
        />
      </Box>
      <Box mb={3.75}>
        <Label required>Email Address</Label>
        <FormTextField
          onChange={formik.handleChange}
          variant="outlined"
          fullWidth
          size="small"
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          name="email"
          inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
        />
      </Box>
      <Box mb={3}>
        <Label required>Phone Number</Label>
        <Box display="flex" alignItems="flex-start" style={{ gap: 24 }}>
          <Box flex="0.2">
            <ReactFlagsSelectStyled
              fullWidth
              selected={formik.values.areaCode}
              onSelect={(v) => {
                formik.setFieldValue('areaCode', v);
              }}
              searchable
              customLabels={customLabels}
              showSecondaryOptionLabel
              showSelectedLabel={false}
            />
          </Box>
          <Box flex="0.8">
            <FormTextField
              variant="outlined"
              fullWidth
              size="small"
              value={formik.values.mobilePhone}
              onChange={formik.handleChange}
              name="mobilePhone"
              onBlur={formik.handleBlur}
              error={formik.touched.mobilePhone && !!formik.errors.mobilePhone}
              helperText={formik.touched.mobilePhone && formik.errors.mobilePhone}
              inputProps={{ maxLength: INPUT_MAX_LENGTH_15 }}
              placeholder="Phone number including Area code, if there is"
            />
          </Box>
        </Box>
      </Box>
      <Box mb={3}>
        <Label>Have you attended nanyang school?</Label>
        <RadioGroup
          name="attendedNsaa"
          row
          value={formik.values.attendedNsaa}
          onChange={(e, v) => {
            if (v === 'no') {
              setEmptyChildAttended();
              formik.setFieldValue('attendedNsaa', v);
            } else {
              formik.setFieldValue('attendedNsaa', v);
            }
          }}
        >
          <FormControlLabelStyled value="yes" control={<NASSRadio />} label="Yes" />
          <Box width={100} />
          <FormControlLabelStyled value="no" control={<NASSRadio />} label="No" />
        </RadioGroup>
      </Box>
      <Box mb={3}>
        <Box display="flex">
          <Box flex="0.5">
            <Label required>School Attended</Label>
            {formik.values.attendedNsaa === 'yes' && formik.errors.isChildAttend && (
              <ErrorMessage>{THIS_FIELD_IS_REQUIRED}</ErrorMessage>
            )}
          </Box>
          <Box flex="0.5">
            <Label required>Year of Graduation / Leave School</Label>
          </Box>
        </Box>
        <Box display="flex" mb={3}>
          <Box flex="0.5">
            <FormControlLabelStyled
              label="Nanyang Kindergarten"
              control={
                <Checkbox
                  disabled={formik.values.attendedNsaa === NO}
                  color="primary"
                  checked={formik.values.isCheckPeriodStudyInNYK}
                  onChange={handleGraduateChange('isCheckPeriodStudyInNYK', 'periodStudyInNYK')}
                />
              }
            />
          </Box>
          <Box flex="0.5">
            <FormTextField
              variant="outlined"
              color="primary"
              fullWidth
              size="small"
              disabled={!formik.values.isCheckPeriodStudyInNYK}
              value={formik.values.periodStudyInNYK}
              onChange={(e) => handleGraduateInputChange(e, 'periodStudyInNYK')}
              name="periodStudyInNYK"
              onBlur={formik.handleBlur}
              error={
                formik.values.isCheckPeriodStudyInNYK &&
                formik.touched.periodStudyInNYK &&
                !!formik.errors.periodStudyInNYK
              }
              helperText={
                formik.values.isCheckPeriodStudyInNYK &&
                formik.touched.periodStudyInNYK &&
                formik.errors.periodStudyInNYK
              }
              inputProps={{ maxLength: INPUT_YEAR_LENGTH }}
            />
          </Box>
        </Box>
        <Box display="flex" mb={3}>
          <Box flex="0.5">
            <FormControlLabelStyled
              label="Nanyang Pre-Primary"
              control={
                <Checkbox
                  disabled={formik.values.attendedNsaa === NO}
                  color="primary"
                  checked={formik.values.isCheckPeriodStudyInNPP}
                  onChange={handleGraduateChange('isCheckPeriodStudyInNPP', 'periodStudyInNPP')}
                />
              }
            />
          </Box>
          <Box flex="0.5">
            <FormTextField
              variant="outlined"
              fullWidth
              size="small"
              disabled={!formik.values.isCheckPeriodStudyInNPP}
              value={formik.values.periodStudyInNPP}
              onChange={(e) => handleGraduateInputChange(e, 'periodStudyInNPP')}
              name="periodStudyInNPP"
              onBlur={formik.handleBlur}
              error={
                formik.values.isCheckPeriodStudyInNPP &&
                formik.touched.periodStudyInNPP &&
                !!formik.errors.periodStudyInNPP
              }
              helperText={
                formik.values.isCheckPeriodStudyInNPP &&
                formik.touched.periodStudyInNPP &&
                formik.errors.periodStudyInNPP
              }
              inputProps={{ maxLength: INPUT_YEAR_LENGTH }}
            />
          </Box>
        </Box>
        <Box display="flex" mb={3}>
          <Box flex="0.5">
            <FormControlLabelStyled
              label="Nanyang Primary"
              control={
                <Checkbox
                  disabled={formik.values.attendedNsaa === NO}
                  color="primary"
                  checked={formik.values.isCheckPeriodStudyInNYP}
                  onChange={handleGraduateChange('isCheckPeriodStudyInNYP', 'periodStudyInNYP')}
                />
              }
            />
          </Box>
          <Box flex="0.5">
            <FormTextField
              variant="outlined"
              fullWidth
              size="small"
              disabled={!formik.values.isCheckPeriodStudyInNYP}
              value={formik.values.periodStudyInNYP}
              onChange={(e) => handleGraduateInputChange(e, 'periodStudyInNYP')}
              name="periodStudyInNYP"
              onBlur={formik.handleBlur}
              error={
                formik.values.isCheckPeriodStudyInNYP &&
                formik.touched.periodStudyInNYP &&
                !!formik.errors.periodStudyInNYP
              }
              helperText={
                formik.values.isCheckPeriodStudyInNYP &&
                formik.touched.periodStudyInNYP &&
                formik.errors.periodStudyInNYP
              }
              inputProps={{ maxLength: INPUT_YEAR_LENGTH }}
            />
          </Box>
        </Box>
        <Box display="flex" mb={3}>
          <Box flex="0.5">
            <FormControlLabelStyled
              label="Nanyang Girls' High"
              control={
                <Checkbox
                  disabled={formik.values.attendedNsaa === NO}
                  color="primary"
                  checked={formik.values.isCheckPeriodStudyInNYGH}
                  onChange={handleGraduateChange('isCheckPeriodStudyInNYGH', 'periodStudyInNYGH')}
                />
              }
            />
          </Box>
          <Box flex="0.5">
            <FormTextField
              variant="outlined"
              fullWidth
              size="small"
              disabled={!formik.values.isCheckPeriodStudyInNYGH}
              value={formik.values.periodStudyInNYGH}
              onChange={(e) => handleGraduateInputChange(e, 'periodStudyInNYGH')}
              name="periodStudyInNYGH"
              onBlur={formik.handleBlur}
              error={
                formik.values.isCheckPeriodStudyInNYGH &&
                formik.touched.periodStudyInNYGH &&
                !!formik.errors.periodStudyInNYGH
              }
              helperText={
                formik.values.isCheckPeriodStudyInNYGH &&
                formik.touched.periodStudyInNYGH &&
                formik.errors.periodStudyInNYGH
              }
              inputProps={{ maxLength: INPUT_YEAR_LENGTH }}
            />
          </Box>
        </Box>
      </Box>

      {IS_NOT_INCLUDE_MEAL ? (
        <Box mb={3.75}>
          <Label required>Number of tickets</Label>
          <FormTextField
            onChange={handleInputNumberChange('totalTicket')}
            variant="outlined"
            fullWidth
            size="small"
            onBlur={formik.handleBlur}
            value={formik.values.totalTicket}
            error={formik.touched.totalTicket && !!formik.errors.totalTicket}
            helperText={formik.touched.totalTicket && formik.errors.totalTicket}
            name="totalTicket"
            inputProps={{ maxLength: INPUT_MAX_LENGTH_128, readOnly: false }}
          />
        </Box>
      ) : (
        <Box>
          <Box>
            <Box mb={3.75}>
              <Label required>Number of tickets for standard meal</Label>
              <FormTextField
                onChange={handleInputNumberChange('ticketForStandardMeal')}
                variant="outlined"
                fullWidth
                size="small"
                onBlur={formik.handleBlur}
                value={formik.values.ticketForStandardMeal}
                error={formik.touched.ticketForStandardMeal && !!formik.errors.ticketForStandardMeal}
                helperText={formik.touched.ticketForStandardMeal && formik.errors.ticketForStandardMeal}
                name="ticketForStandardMeal"
                inputProps={{ maxLength: INPUT_MAX_LENGTH_128 }}
                InputProps={{ inputProps: { min: 0, step: 1 } }}
              />
            </Box>
            <Box mb={3.75}>
              <Label required>Number of tickets for vegetarian</Label>
              <FormTextField
                onChange={handleInputNumberChange('ticketForVegetarian')}
                variant="outlined"
                fullWidth
                size="small"
                onBlur={formik.handleBlur}
                value={formik.values.ticketForVegetarian}
                error={formik.touched.ticketForVegetarian && !!formik.errors.ticketForVegetarian}
                helperText={formik.touched.ticketForVegetarian && formik.errors.ticketForVegetarian}
                name="ticketForVegetarian"
                inputProps={{ maxLength: INPUT_MAX_LENGTH_128 }}
                InputProps={{ inputProps: { min: 0, step: 1 } }}
              />
            </Box>
            <Box mb={3.75}>
              <Label required>Number of tickets for halal</Label>
              <FormTextField
                onChange={handleInputNumberChange('ticketForHalal')}
                variant="outlined"
                fullWidth
                size="small"
                onBlur={formik.handleBlur}
                value={formik.values.ticketForHalal}
                error={formik.touched.ticketForHalal && !!formik.errors.ticketForHalal}
                helperText={formik.touched.ticketForHalal && formik.errors.ticketForHalal}
                name="ticketForHalal"
                inputProps={{ maxLength: INPUT_MAX_LENGTH_128 }}
                InputProps={{ inputProps: { min: 0, step: 1 } }}
              />
            </Box>
            <Box mb={3.75}>
              <Label required>Number of tickets</Label>
              <FormTextField
                onChange={formik.handleChange}
                variant="outlined"
                fullWidth
                size="small"
                onBlur={formik.handleBlur}
                value={TOTAL_TICKETS}
                error={formik.touched.totalTicket && !!formik.errors.totalTicket}
                helperText={formik.touched.totalTicket && formik.errors.totalTicket}
                name="totalTicket"
                inputProps={{ maxLength: INPUT_MAX_LENGTH_128, readOnly: true }}
                disabled
                InputProps={{ inputProps: { min: 0, step: 1 } }}
              />
            </Box>
          </Box>
        </Box>
      )}
      {event.ticket_type === TICKET_TYPE_2 && event.is_public && (
        <Box mb={3.75}>
          <Label required>Number of attendees age above [{formik.values.discount_for_age || 0}]</Label>
          <FormTextField
            onChange={handleInputNumberChange('numberAttendedAge')}
            variant="outlined"
            fullWidth
            size="small"
            onBlur={formik.handleBlur}
            value={formik.values.numberAttendedAge}
            error={formik.touched.numberAttendedAge && !!formik.errors.numberAttendedAge}
            helperText={formik.touched.numberAttendedAge && formik.errors.numberAttendedAge}
            name="numberAttendedAge"
            inputProps={{ maxLength: INPUT_MAX_LENGTH_128 }}
            InputProps={{ inputProps: { min: 0, step: 1 } }}
          />
        </Box>
      )}
    </Box>
  );
};

export default FillForm;
