import React from 'react';

import { Box, Grid, Link, makeStyles, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { CustomButton } from 'src/components';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import LogoJSA from 'src/medias/images/main-nsaa-logo.png';
import SignInBackground from 'src/medias/images/signin-background.jpg';
import useResponsive from 'src/utils/responsive';

import ForgotEmailMobile from './ForgotEmailMobile';

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: 32,
  ...theme.fonts.header3S,
  color: theme.palette.common.black,
  textAlign: 'center',
}));
const NavLinkStyled = styled(NavLink)`
  && {
    font-size: 20px;
    text-decoration: none;
    display: flex;
    justify-content: center;
  }
`;
const Note = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textS,
}));
const NoteGroup = styled(Typography)(({ theme }) => ({
  width: '100%',
  marginBottom: 32,
  color: theme.palette.common.black,
  textAlign: 'center',
}));
const ContactBtn = styled(CustomButton)(({ theme }) => ({
  ...theme.fonts.textSs,
  marginRight: '10px',
  borderRadius: '5px',
  width: 'fit-content !important',
  padding: '0px 50px',
}));
export const NYSImage = styled.img<{ objectFitContain?: boolean }>(({ theme, objectFitContain }) => ({
  width: '100%',
  minHeight: '100vh',
  ...(objectFitContain
    ? {
        objectFit: 'contain',
      }
    : {}),
}));
const Logo = styled.img(({ theme }) => ({
  position: 'absolute',
  top: 54,
  left: 80,
  width: 70,
  height: 90,
}));
export const BoxPosition = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '20%',
  top: '40%',
}));
export const TitleBoxPosition = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
  color: 'white',
  fontSize: 22,
}));
export const DesBoxPosition = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textS,
  color: 'white',
  textAlign: 'center',
  marginTop: 16,
  lineHeight: 2,
}));
const useStyles = makeStyles((theme) => ({
  left: {
    minHeight: 966,
    position: 'relative',
  },
}));
function ForgotEmail() {
  useScrollToTop();
  const classes = useStyles();
  const { isTabletOrMobile } = useResponsive();

  return (
    <Box>
      {isTabletOrMobile ? (
        <ForgotEmailMobile />
      ) : (
        <Grid container>
          <Grid item xs={6} className={classes.left}>
            <Box display="flex" width={'100%'} height={'100%'}>
              <NYSImage src={SignInBackground} objectFitContain />
              <Link href="/">
                <Logo src={LogoJSA} />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              width={'100%'}
              height={'100%'}
              justifyContent="center"
              display="flex"
              alignItems="center"
              bgcolor="#f9f9f9"
            >
              <Box width={'60%'}>
                <Title>
                  Don’t remember an email address you’ve used in connection with Nanyang School Alumni Association?
                </Title>
                <NoteGroup>
                  <Note>No problem!</Note>
                  <Note> Contact our alumni association department, and we’ll look you up.</Note>
                </NoteGroup>
                <NavLinkStyled to="/contact-us">
                  <ContactBtn variant="contained">CONTACT US</ContactBtn>
                </NavLinkStyled>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default ForgotEmail;
