import React, { useState } from 'react';

import { Box, FormControl, FormControlLabel, Grid, InputAdornment, RadioGroup, Typography } from '@material-ui/core';
import _ from 'lodash';

import { CustomButton, InputField, NASSRadio } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import DollarIconURL from 'src/medias/icons/dollar-icon.svg';
import { CustomLabel, TextNote } from 'src/pages/signup/components/types';
import { donateNYGH, IDonateNYGH } from 'src/services/payment';
import { intRegex, _checkErrors } from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { BoxStyled, CustomButtonStyled, ImageStyled, NoteStyled, RedText } from '../types';

const TEXT_REQUIRE = 'This field is required';
const MAXIMUM_AMOUNT_NUMBER_LENGTH = 50;
const AMOUNT_OVER = 5000;

interface IFillForm {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  data: {
    amount: string;
    requestForTer: string;
    surname: string;
    givenName: string;
    nirc: string;
  };
  errors: {
    amount: boolean;
    surname: boolean;
    givenName: boolean;
    nirc: boolean;
  };
  setData: React.Dispatch<
    React.SetStateAction<{
      amount: string;
      requestForTer: string;
      surname: string;
      givenName: string;
      nirc: string;
    }>
  >;
  setErrors: React.Dispatch<
    React.SetStateAction<{
      amount: boolean;
      surname: boolean;
      givenName: boolean;
      nirc: boolean;
    }>
  >;
  setPaymentId: React.Dispatch<React.SetStateAction<string>>;
}

const FillForm = ({ data, setData, errors, setErrors, activeStep, setActiveStep, setPaymentId }: IFillForm) => {
  const { isTabletOrMobile, isTablet } = useResponsive();
  const [loading, setLoading] = useState<boolean>(false);
  const [isActionSuccess, setActionSuccess] = useState(false);
  const [isActionFailed, setActionFailed] = useState(false);
  const [messageResponse, setMessageResponse] = useState('');

  const _onSubmit = async () => {
    setLoading(true);
    let validErrors = errors;
    if (_.isEmpty(data.amount)) {
      validErrors.amount = true;
      setErrors({ ...validErrors, amount: true });
    }

    if (!_checkErrors(errors)) {
      let body: IDonateNYGH = {
        amount: Number(data.amount),
        requestForTer: data.requestForTer,
      };
      if (data.requestForTer === 'yes') {
        body = {
          amount: Number(data.amount),
          requestForTer: data.requestForTer,
          surname: data.surname,
          givenName: data.givenName,
          nirc: data.nirc,
        };
      }
      const response = await donateNYGH(body);
      if (response && response.statusCode === CALL_SUCCESS) {
        setPaymentId(response.data);
        setActionSuccess(true);
        setMessageResponse(response.message);
        setActiveStep(activeStep + 1);
      } else {
        setActionFailed(true);
        setMessageResponse(response.message);
      }
    }
    setLoading(false);
  };

  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.name === 'amount') {
      if (intRegex.test(e.target.value) || e.target.value === '') {
        setData({ ...data, [e.target.name]: e.target.value });
        setValidate(e.target.name, intRegex.test(e.target.value) ? e.target.value : '');

        if (Number(e.target.value) > AMOUNT_OVER) {
          let validErrors = errors;
          if (data.surname === '') {
            validErrors.surname = true;
            setErrors({ ...validErrors, surname: true });
          }
          if (data.givenName === '') {
            validErrors.givenName = true;
            setErrors({ ...validErrors, givenName: true });
          }
          if (data.nirc === '') {
            validErrors.nirc = true;
            setErrors({ ...validErrors, nirc: true });
          }
        } else {
          setErrors({ ...errors, surname: false, givenName: false, nirc: false });
        }
      }
    }

    if (e.target.name === 'surname') {
      setData({ ...data, [e.target.name]: e.target.value });

      if (data.requestForTer === 'yes' || Number(data.amount) > AMOUNT_OVER) {
        setValidate(e.target.name, e.target.value);
      }
    }
    if (e.target.name === 'givenName') {
      setData({ ...data, [e.target.name]: e.target.value });

      if (data.requestForTer === 'yes') {
        setValidate(e.target.name, e.target.value);
      }
    }

    if (e.target.name === 'nirc') {
      setData({ ...data, [e.target.name]: e.target.value });

      if (data.requestForTer === 'yes') {
        setValidate(e.target.name, e.target.value);
      }
    }

    if (e.target.name === 'requestForTer') {
      setData({ ...data, [e.target.name]: e.target.value });

      if (e.target.value === 'yes') {
        let validErrors = errors;

        if (data.surname === '') {
          validErrors.surname = true;
          setErrors({ ...validErrors, surname: true });
        }
        if (data.givenName === '') {
          validErrors.givenName = true;
          setErrors({ ...validErrors, givenName: true });
        }
        if (data.nirc === '') {
          validErrors.nirc = true;
          setErrors({ ...validErrors, nirc: true });
        }
      } else {
        setErrors({ ...errors, surname: false, givenName: false, nirc: false });
      }
    }
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setData({ ...data, [e.target.name]: valueTrimmed });
  };

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const _onClickAmount = (amount: number) => {
    setData({ ...data, amount: amount.toString() });
    setErrors({ ...errors, amount: false });
  };

  return (
    <Box display="flex" flex={1} flexDirection="column">
      {isTabletOrMobile && (
        <Box justifyContent="center" display="flex">
          <TextNote isTabletOrMobile={isTabletOrMobile}>Fill form</TextNote>
        </Box>
      )}
      <BoxStyled mt={isTabletOrMobile ? 2 : 5}>
        <Box>
          <CustomLabel display="inline">
            Amount{' '}
            <Typography component="span" display="inline" color="error">
              *
            </Typography>
          </CustomLabel>
          <Box display="flex" width={'100%'} style={{ gap: 5 }}>
            {[50, 100, 500, 1000].map((item) => (
              <Box display="flex" flex={1}>
                <CustomButtonStyled onClick={_onClickAmount.bind(null, item)} outlineButton>
                  ${item}
                </CustomButtonStyled>
              </Box>
            ))}
          </Box>
          <Box mt={1}>
            <InputField
              name="amount"
              value={data.amount}
              onChange={_handleChange}
              onBlur={onBlurField}
              placeholder="Other"
              error={errors.amount}
              errormessage={TEXT_REQUIRE}
              InputProps={{
                inputProps: {
                  maxLength: MAXIMUM_AMOUNT_NUMBER_LENGTH,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <ImageStyled src={DollarIconURL} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
        <Box mt={3}>
          <CustomLabel font15>
            Would You Like A Tax Deduction Receipt?{' '}
            <Typography component="span" display="inline" color="error">
              *
            </Typography>
          </CustomLabel>
          <Box mt={2}>
            <NoteStyled>
              Note: NSAA is only provide tax deduction receipt if donation amount is bigger than $100
            </NoteStyled>
          </Box>
          <Box>
            <FormControl component="fieldset">
              <RadioGroup onChange={_handleChange} value={data.requestForTer} name="requestForTer" row>
                <FormControlLabel value="yes" control={<NASSRadio />} label="Yes" />
                <Box width={'250px'} />
                <FormControlLabel value="no" control={<NASSRadio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
          <Box mt={2}>
            <RedText>
              If you request for a TER or donation amount is over $5000, please provide your full name & NRIC/FIN/UEN
            </RedText>
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={isTabletOrMobile ? 12 : 6}>
            <Box flex={1} pr={isTabletOrMobile ? 0 : 1}>
              <InputField
                value={data.surname}
                onChange={_handleChange}
                onBlur={onBlurField}
                title="Surname"
                name="surname"
                margin="dense"
                fullWidth
                InputProps={{
                  inputProps: {
                    maxLength: MAXIMUM_AMOUNT_NUMBER_LENGTH,
                  },
                }}
                error={errors.surname}
                errormessage={TEXT_REQUIRE}
              />
            </Box>
          </Grid>
          <Grid item xs={isTabletOrMobile ? 12 : 6}>
            <Box flex={1} pl={isTabletOrMobile ? 0 : 1}>
              <InputField
                value={data.givenName}
                onChange={_handleChange}
                onBlur={onBlurField}
                title="Given Name"
                name="givenName"
                margin="dense"
                fullWidth
                InputProps={{
                  inputProps: {
                    maxLength: MAXIMUM_AMOUNT_NUMBER_LENGTH,
                  },
                }}
                error={errors.givenName}
                errormessage={TEXT_REQUIRE}
              />
            </Box>
          </Grid>
        </Grid>
        <Box>
          <InputField
            value={data.nirc}
            name="nirc"
            title="NRIC/FIN/UEN"
            onChange={_handleChange}
            onBlur={onBlurField}
            InputProps={{
              inputProps: {
                maxLength: MAXIMUM_AMOUNT_NUMBER_LENGTH,
              },
            }}
            error={errors.nirc}
            errormessage={TEXT_REQUIRE}
          />
        </Box>
      </BoxStyled>
      <Box
        mt={5}
        display="flex"
        justifyContent="center"
        flex={1}
        alignItems={isTabletOrMobile ? 'flex-end' : 'flex-start'}
        mb={4}
      >
        <CustomButton style={{ width: isTablet && '100%' }} disabled={loading} onClick={_onSubmit}>
          Submit
        </CustomButton>
      </Box>
      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </Box>
  );
};

export default FillForm;
