import React from 'react';

import { Box } from '@material-ui/core';

import useResponsive from 'src/utils/responsive';

import { Footer } from './components/footer';
import { Header } from './components/header';

export const LoginLayout: React.FC = ({ children }) => {
  const { isTabletOrMobile } = useResponsive();

  return (
    <Box>
      {!isTabletOrMobile && <Header />}
      <Box mt={isTabletOrMobile ? 0 : '30px'} minHeight={'100vh'}>
        {children}
      </Box>
      {!isTabletOrMobile && <Footer />}
    </Box>
  );
};
