import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  withStyles,
  Theme,
  createStyles,
  TableSortLabel,
} from '@material-ui/core';
import styled, { css } from 'styled-components';

export const NSAATableContainer = styled(TableContainer)`
  ${({ theme }) => css`
    min-width: 1200;
    overflow-x: auto;
  `}
`;

export const TableContainerMain = styled(Table)``;
export const NSAATableHead = styled(TableHead)`
  ${({ theme }) => css`
    background-color: ${theme.palette.yellow?.[900]};
    outline: 1px solid white;
    font-weight: bold;
    height: 56px;
  `}
`;
export const NSAATableHeader = styled(TableRow)``;
export const NSAATableHeaderCell = styled(TableCell)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header6.fontSize};
    font-weight: ${theme.fonts.header6.fontWeight};
    line-height: ${theme.fonts.header6.lineHeight};
    color: ${theme.palette.common.white};
    padding: 8px;
  `}
`;
export const NSAATableRow = styled(TableRow)<{ striped: boolean }>`
  ${({ theme, striped }) => css`
    background-color: ${striped ? theme.palette.lightGray?.[10] : theme.palette.common.white};
    outline: 1px solid white;
  `}
`;
export const NSAATableHeaderCheckbox = styled(TableCell)`
  padding: 8px;
`;
export const NSAATableBodyCheckbox = styled(TableCell)`
  padding: 8px;
`;
export const NSAATableBodyCell = styled(TableCell)<{ ellipsis?: boolean; w?: number | string }>`
  ${({ theme, ellipsis }) => css`
    padding: 8px;
    max-width: 100px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.9);
    span {
      margin: 0;
      font-size: 14px !important;
    }
    ${ellipsis &&
    css`
      & span {
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        word-break: break-all;
      }
    `}
  `}
`;

export const NoDataImageStyled = styled.img(() => ({}));

export const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'white',
      '&:hover': {
        color: 'white',
      },
      '&$active': {
        color: 'white',
      },
    },
    active: {},
    icon: {
      color: 'inherit !important',
      fontSize: 30,
    },
  }),
)(TableSortLabel);
