import React from 'react';

import { Breadcrumbs, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { BREADCRUMBS } from 'src/constants/home.constant';

const Separator = styled(Box)`
  ${({ theme }) => css`
    width: 6px;
    height: 6px;
    background-color: white;
    border-radius: 50%;
    margin: 0 16px;
  `}
`;

const BreadcrumbsStyled = styled(Breadcrumbs)`
  display: flex;
  align-items: center;

  ${({ theme }) => css`
    background-color: ${theme.palette.common.black};
    padding-left: 80px;
    height: ${theme.spacing(6)}px;
    color: ${theme.palette.common.white};
    box-sizing: border-box;

    .MuiBreadcrumbs-separator {
      color: ${theme.palette.common.white};
    }
    .MuiLink-root {
      color: ${theme.palette.gray?.[650]};
      text-decoration: none;
    }
  `}
`;

const LinkStyled = styled(Link)`
  ${({ theme }) => css`
    text-transform: capitalize;
    color: white !important;
    text-decoration: none;
    font-size: ${theme.fonts.textS.fontSize};
    line-height: ${theme.fonts.textS.lineHeight};
    font-weight: ${theme.fonts.textS.fontWeight};
  `}
`;

const LinkStyledLastItem = styled(Link)`
  ${({ theme }) => css`
    text-transform: capitalize;
    color: ${theme.palette.yellow?.[900]};
    font-size: ${theme.fonts.textS.fontSize};
    line-height: ${theme.fonts.textS.lineHeight};
    font-weight: ${theme.fonts.textSBold.fontWeight};
    pointer-events: none;
    text-decoration: none;
  `}
`;

export const NSAABreadcrumbs = ({ path, appendItems }: { path: string; appendItems?: any[] }) => {
  const HOME_EVENT_PATH = '/events';
  const paths = path
    .split('/')
    .filter(Boolean)
    .filter((item) => !item.startsWith(':'));

  if (appendItems && appendItems?.length > 0)
    return (
      <BreadcrumbsStyled separator={<Separator />}>
        <LinkStyled to="/">Home</LinkStyled>
        {paths
          .filter((item) => !item.startsWith(':'))
          .map((item, idx) => {
            const itemCapitalize = item.replace('-', ' ');

            return idx === paths.length ? (
              <LinkStyledLastItem to="#">{itemCapitalize}</LinkStyledLastItem>
            ) : (
              <LinkStyled to={`/${paths.slice(0, idx + 1).join('/')}`}>{itemCapitalize}</LinkStyled>
            );
          })}
        {appendItems && appendItems.map((appendItem) => <LinkStyledLastItem to="#">{appendItem}</LinkStyledLastItem>)}
      </BreadcrumbsStyled>
    );

  if (path === HOME_EVENT_PATH) {
    return (
      <BreadcrumbsStyled separator={<Separator />}>
        <LinkStyled to="/">Home</LinkStyled>
        <LinkStyledLastItem to={HOME_EVENT_PATH}> Events & Activities</LinkStyledLastItem>
      </BreadcrumbsStyled>
    );
  }
  return (
    <BreadcrumbsStyled separator={<Separator />}>
      <LinkStyled to="/">Home</LinkStyled>
      {paths
        .filter((item) => !item.startsWith(':'))
        .map((item, idx) => {
          let itemCapitalize = item.replace(/-/g, ' ');
          BREADCRUMBS.forEach((item) => {
            if (item.key === itemCapitalize) {
              itemCapitalize = item.value;
            }
          });

          return idx === paths.length - 1 ? (
            <LinkStyledLastItem to="#">{itemCapitalize}</LinkStyledLastItem>
          ) : (
            <LinkStyled to={`/${paths.slice(0, idx + 1).join('/')}`}>{itemCapitalize}</LinkStyled>
          );
        })}
    </BreadcrumbsStyled>
  );
};
