import React from 'react';

import { Drawer } from '@material-ui/core';

interface ICustomDrawer {
  anchor: 'left' | 'right';
  open: boolean;
  onClose: (event: React.KeyboardEvent | React.MouseEvent) => void;
  children: any;
}

export const CustomDrawer = ({ anchor, open, onClose, children }: ICustomDrawer) => {
  return (
    <Drawer anchor={anchor} open={open} onClose={onClose}>
      {children}
    </Drawer>
  );
};
