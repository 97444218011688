import { MenuItem, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { CustomButton } from 'src/components';

export const NavLinkCustom = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
  color: '#000000',
}));
export const MOCK_UP_FAIL_RESPONSE = {
  statusCode: 1,
  message: 'MOCK_UP_RESPONSE',
  data: {
    items: [],
    meta: {
      currentPage: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
};
export interface ISearchCMSHomepage {
  searchTitle: string;
  type: string;
  isFeature: string;
  fromDate: string;
  toDate: string;
}
export const CMSHomepageTypes = [
  { key: 'news', value: 'News' },
  { key: 'event', value: 'Events & Activities' },
];
export const CMSFeatureOptions = [
  { key: 'yes', value: 'Yes' },
  { key: 'no', value: 'No' },
];

export const CustomButtonStyled = styled(CustomButton)(({ theme }) => ({
  width: '120px !important',
  height: '44px !important',
  marginLeft: 16,
}));
export const Title = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header4,
}));
export const KeyboardArrowDownIconStyled = styled(KeyboardArrowDownIcon)(() => ({
  marginLeft: 12,
}));
export const MenuItemStyled = styled(MenuItem)(() => ({
  height: 40,
  minWidth: 217,
}));
export const defaultSearchCMSHomepage = {
  title: '',
  type: '',
  isFeatureItem: '',
  startDate: null,
  endDate: null,
};
export const mockupCMSHomepage = [
  {
    id: 1,
    title: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly use...',
    type: 'Events & Activities',
    startDate: '22/10/2021',
    setAsFeature: true,
  },
  {
    id: 2,
    title: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly use...',
    type: 'News',
    startDate: '22/10/2021',
    setAsFeature: true,
  },
  {
    id: 3,
    title: 'In publishing and graphic design, Lorem ipsum is a placeholder text commonly use...',
    type: 'Events & Activities',
    startDate: '22/10/2021',
    setAsFeature: false,
  },
];
