import React, { useState } from 'react';

import { useFetchProfileQuery, useUpdateProfileMutation } from 'src/api/endpoints/profiles.endpoint';
import SuccessSnackBar from 'src/components/snackBar/success';
import { TOAST } from 'src/constants/toasts.constant';

import GeneralInformationForm from '../forms/general-information.form';
import ProfilePopupLayout from '../layout/profile-popup.layout';
import GeneralInformationPopup from '../shared/general-information.popup';

import { GeneralInformationContainer } from './general-information.styled';

interface IGeneralInformationProps {
  variants?: 'user-detail';
  userId?: string;
}

const GeneralInformation = ({ variants, userId }: IGeneralInformationProps) => {
  const [isOpenEditGeneralInformation, setOpenGeneralInformation] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);

  function handleCloseGeneralInformation() {
    setOpenGeneralInformation(false);
  }
  function handleOpenGeneralInformation() {
    setOpenGeneralInformation(true);
  }
  const {
    data: profile,
    isLoading: isFetchingProfile,
    isError: isFetchingProfileError,
    error: fetchingErrors,
    isSuccess: isFetchingSuccess,
    refetch,
  } = useFetchProfileQuery();

  const [
    updateProfile,
    { isLoading: isUpdatingProfile, isSuccess: isUpdatingProfileSuccess, isError: isUpdatingProfileError },
  ] = useUpdateProfileMutation();

  React.useEffect(() => {
    if (isUpdatingProfileSuccess) {
      setShowSnackBarSuccess(true);
      setOpenGeneralInformation(false);
    }
  }, [isUpdatingProfileSuccess, isUpdatingProfileError]);

  React.useEffect(() => {
    refetch();
  }, []);

  function handleUpdateProfile(profileUpdate: any) {
    updateProfile(profileUpdate);
  }

  function handleCloseSnackBarSuccess() {
    setShowSnackBarSuccess(false);
  }

  return (
    <GeneralInformationContainer variants={variants}>
      <SuccessSnackBar
        open={isShowSnackBarSuccess}
        handleClose={handleCloseSnackBarSuccess}
        message={TOAST.UPDATE_PROFILE_SUCCESS}
      />
      {isFetchingProfileError && <div>{JSON.stringify(fetchingErrors, null, 4)}</div>}
      {isFetchingProfile && <div>Loading...</div>}
      {isFetchingSuccess && (
        <GeneralInformationForm
          onOpenEditGeneralInformation={handleOpenGeneralInformation}
          variants={variants}
          profile={profile?.data || null}
          showActionButton={true}
        />
      )}
      <ProfilePopupLayout open={isOpenEditGeneralInformation} onClose={handleCloseGeneralInformation}>
        <GeneralInformationPopup
          onClose={handleCloseGeneralInformation}
          profile={profile?.data || null}
          onSave={handleUpdateProfile}
          isSaveLoading={isUpdatingProfile}
        />
      </ProfilePopupLayout>
    </GeneralInformationContainer>
  );
};

export default GeneralInformation;
