export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const isSpace = (str: string) => {
  const regex = /(\s)/;
  return regex.test(str);
};

export const _checkErrors = (errors: any) => {
  let result = false;

  // eslint-disable-next-line array-callback-return
  Object.keys(errors).map((key) => {
    if (errors[key] === true) {
      result = true;
    }
  });

  return result;
};

export function convertSlugToUpperCase(slug: string) {
  var words = slug.split('-');

  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }

  return words.join(' ');
}

// const FORBIDDEN_TERMINAL_CHARACTERS = [
//   `!`,
//   `#`,
//   `$`,
//   `%`,
//   `&`,
//   `'`,
//   `*`,
//   `+`,
//   `-`,
//   `/`,
//   `=`,
//   `?`,
//   `^`,
//   `_`,
//   '`',
//   `{`,
//   `|`,
//   `}`,
//   `~`,
// ];

const FORBIDDEN_TERMINAL_CHARACTERS: any = [];

export function emailIsValid(email: string) {
  // let syntaxGood = /^[\w.]+@(?:[a-zA-Z]+\.)?[a-zA-Z]{2,10}\.[a-zA-Z]{2,10}$/.test(email);
  // if (!syntaxGood) return false; // skip loop if we've already failed
  for (let badChar of FORBIDDEN_TERMINAL_CHARACTERS) {
    if (email.includes(badChar)) {
      return false;
    }
  }

  return true;
}

export const validEmailRegex = RegExp(/^[\w.]+@(?:[a-zA-Z]+\.)?[a-zA-Z]{2,10}\.[a-zA-Z]{2,10}$/);

export const intRegex = /^[0-9 ]+$/;
export const positiveRegex = /^[1-9 ]+$/;

// eslint-disable-next-line no-useless-escape
export const containsSpecialCharacters = /^(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).([^ก-๙])+$/; // !, @, #, $, % (no space)

export const containsNumericCharacters = new RegExp('^(?=.*\\d).+$');
export const containsUpperAndLowerCaseRegexp = new RegExp('^(?=.*[a-z])(?=.*[A-Z]).+$');

export const dateString2String = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}/${month}/${day}`;
};

export function shallowEqual(object1: any, object2: any) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}

export function sortUserName(a: any, b: any) {
  if (a?.surname?.toUpperCase() < b?.surname?.toUpperCase()) {
    return -1;
  }
  if (a.surname > b.surname) {
    return 1;
  }
  return 0;
}
export function sortUserNameInUser(a: any, b: any) {
  if (a?.user?.surname?.toUpperCase() < b?.user?.surname?.toUpperCase()) {
    return -1;
  }
  if (a.surname > b.surname) {
    return 1;
  }
  return 0;
}

export const delayFn = (fn: Function, ms?: number) => {
  const timeOut = ms || 2000;
  setTimeout(() => fn(), timeOut);
};

export const delayFnAsync = (fn: Function, ms?: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(true);
      fn();
    }, ms || 1000);
  });
};

export const convertURL = (text: string) => {
  return text.replace('+', '%2B');
};

export const isInputNumber = (input: string) => {
  if (input.includes('+') || input.includes('-') || isNaN(input as any) || input.includes(' ')) return false;
  return true;
};

export const utcToDateTime = (utcTime: string) => {
  const date = new Date(utcTime);
  return `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
};

export const openBase64InNewTab = (base64ImageData: string) => {
  const contentType = 'image/png';

  const byteCharacters = atob(base64ImageData.substr(`data:${contentType};base64,`.length));
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }
  const blob = new Blob(byteArrays, { type: contentType });
  const blobUrl = URL.createObjectURL(blob);

  window.open(blobUrl, '_blank');
};
