import React from 'react';

import { Dialog } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';

const DialogCustom = styled(Dialog)`
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiPaper-elevation24.MuiPaper-rounded {
    overflow-y: clip !important;
    background: inherit;
  }
  .MuiBackdrop-root {
    background-color: #0000000d;
  }
`;
export const LoadingCustom = ({ loading, loadingIcon }: { loading?: boolean; loadingIcon?: boolean }) => {
  const handleClose = () => {
    return !loading;
  };
  return (
    <>
      <div />
      {loading ? (
        <DialogCustom onClose={handleClose} aria-labelledby="simple-dialog-title" open={loading || false}>
          {loadingIcon ? <CircularProgress size={24} /> : null}
        </DialogCustom>
      ) : null}
    </>
  );
};
