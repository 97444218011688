import { IEvent } from 'src/services/events';

export const TEXT_REQUIRE = 'This field is required';
export const IS_POSITIVE_NUMBER = 'This field must be a positive number';
export const MIN_NUMBER = 'You can not decrease max number of volunteers if event is already published';
export const MUST_BE_GREATER_THAN = 'Max number of volunteers must be greater than 0';
export const VACANCIES_MUST_BE_GREATER_THAN = '% of vacancies for volunteers to be opened must be greater than 0';
export const MAX_OF_VACANCIES = 'max % of vacancies for volunteers to be opened is 100%';

export const MIN_VACANCIES_NUMBER = 0;
export const MAX_VACANCIES_NUMBER = 100;

export const eventStatus = {
  draft: 'draft',
  onGoing: 'on-going',
  pending: 'pending',
};

export interface IOverView {
  event: IEvent | null;
  setEvent: React.Dispatch<React.SetStateAction<IEvent | undefined>>;
}
export const initState = {
  require_volunteer: false,
  max_volunteer: 0,
  percent_vacancies: 100,
  link_for_volunteer_registration: false,
  volunteer_registration_expire: 1,
};
