import { Box, Button, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const QuestionTabContainer = styled(Box)`
  ${({ theme }) => css`
    height: 100%;
  `}
`;

export const ActionButtons = styled(Box)`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
`;
export const NoHaveQuestion = styled(Box)`
  width: 100%;
  height: 100%;
  min-height: 456px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CreateQuizButton = styled(Button)<{ variants: 'ghost' | 'yellow' | 'outlined'; isBig?: boolean }>`
  ${({ theme, variants, disabled }) => css`
    font-size: ${theme.fonts.textSBold.fontSize};
    font-weight: ${theme.fonts.textSBold.fontWeight};
    width: 120px;
    height: 44px;
    border-radius: 6px;
    background-color: ${variants === 'yellow' ? theme.palette.yellow?.[900] : theme.palette.common.white};
    color: ${variants === 'yellow' ? theme.palette.common.white : theme.palette.gray?.[60]};
    ${variants === 'ghost' &&
    css`
      border: 1px solid ${theme.palette.lightGray?.[25]};
      color: ${theme.palette.gray?.[1000]};
    `}
    text-transform: capitalize;
    ${variants === 'yellow' &&
    css`
      &:hover {
        background-color: ${theme.palette.yellow?.[900]};
      }
    `}
    ${variants === 'outlined' &&
    css`
      border: 1px solid ${theme.palette.yellow?.[900]};
      color: ${theme.palette.yellow?.[900]};
    `}
    ${disabled &&
    css`
      background-color: #dcdcdd;
    `}
  `}
`;
export const QuizTitle = styled(Box)`
  height: 100px;
  border-radius: 8px;
  padding: 16px;
  background-color: #f7f7f7;
  margin: 24px 0;
  box-sizing: border-box;
`;
export const QuizLabel = styled(Typography)<{ required?: boolean }>`
  ${({ theme, required }) => css`
    color: #00000099;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 4px;
    ${required &&
    css`
      &::after {
        content: '*';
        display: inline-block;
        color: red;
        margin-left: 3px;
      }
    `}
  `}
`;
export const QuestionContainer = styled(Box)`
  background-color: #00000008;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 16px;
  flex: 1;
`;
export const ShortQuestionContainer = styled(Box)``;

export const QuestionTitle = styled(Typography)<{ variants: 'title' | 'type'; required?: boolean }>`
  ${({ variants, required }) => css`
    font-size: 16px;
    font-weight: 700;
    color: #00000099;
    margin-bottom: 16px;
    flex: ${variants === 'title' ? 0.7 : 0.3};
    width: ${variants === 'title' ? '70%' : '30%'};
    justify-content: flex-end;

    ${
      required &&
      css`
        &::after {
          content: '*';
          display: inline-block;
          color: red;
          margin-left: 3px;
        }
      `
    }}
  `}
`;

export const QuestionRow = styled(Box)<{ variants: 'head' | 'body' }>`
  ${({ variants, theme }) => css`
    display: flex;
    gap: 24px;
    justify-content: space-between;
    ${variants === 'body' &&
    css`
      margin-bottom: 10px;
    `}
  `}
`;

export const QuestionPoint = styled(Box)`
  display: flex;
`;

export const AddQuestion = styled(Button)`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    border: 1px solid ${theme.palette.yellow?.[900]};
    border-radius: 6px;
    color: ${theme.palette.yellow?.[1000]};
    text-transform: capitalize;

    font-size: 16px;
    font-weight: 500;
    text-align: center;

    margin-top: 16px;
    margin-bottom: 32px;

    gap: 2px;
  `}
`;

export const AddQuizIcon = styled.img`
  margin-right: 5px;
`;

export const SingleChoiceContainer = styled(Box)``;

export const RemoveIcon = styled(Box)<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    width: 32px;
    height: 32px;
    border-radius: 6px;
    color: ${theme.palette.yellow?.[1000]};
    border: 1px solid ${theme.palette.yellow?.[1000]};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;
    flex-shrink: 0;

    ${disabled &&
    css`
      pointer-events: none;
      user-select: none;
      filter: grayscale(100%);
      background: #dcdcdd;
    `}
  `}
`;
export const QuestionToolbar = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const QuestionToolbarText = styled(Typography)`
  color: #718096;
  font-size: 16px;
  font-weight: 400;
`;

export const QuestionBox = styled(Box)`
  display: flex;
  align-items: center;
`;
