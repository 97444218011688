import { Box, DialogContentText, DialogTitle } from '@material-ui/core';
import styled from 'styled-components';

import { CustomDialog } from 'src/components';

const DialogTitleStyled = styled(DialogTitle)(() => ({
  '& > h2': {
    fontSize: 20,
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.8)',
  },
  marginTop: 16,
  alignSelf: 'center',
}));

const DialogContentTextStyled = styled(DialogContentText)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
}));

interface IPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  title?: string;
  content?: string;
}

const Popup = ({ open, title = 'Notification', content = '', onClose, onSubmit }: IPopupProps) => {
  return (
    <CustomDialog open={open} handleClose={onClose} onSubmit={onSubmit} isConfirmPopup hiddenCancelButton>
      <DialogTitleStyled>{title}</DialogTitleStyled>
      <Box
        width={412}
        alignSelf="center"
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        maxWidth="100%"
      >
        <DialogContentTextStyled>{content}</DialogContentTextStyled>
      </Box>
    </CustomDialog>
  );
};

export default Popup;
