import React, { useState } from 'react';

import { Box, FormControl, FormControlLabel, makeStyles, RadioGroup, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { InputField, NASSRadio } from 'src/components';
import CustomFormDialog from 'src/components/formDialog';
import { IVolunteer } from 'src/services/volunteer';
import { sortUserNameInUser } from 'src/utils/common';

import { Notification } from './notification';
import WaitList from './wait-list';

const useStyles = makeStyles(() => ({
  input: {
    '&  .MuiOutlinedInput-root': {
      height: '205px !important',
    },
  },
}));

const MAXIMUM_LENGTH = 255;

interface IRemoveVolunteerModal {
  eventId: string;
  _onCloseModal: () => void;
  open: boolean;
  _onSubmit?: () => void;
  userSelected: IVolunteer[] | undefined;
  loading: boolean | undefined;
  reasonRemove: string;
  setReasonRemove: React.Dispatch<React.SetStateAction<string>>;
  method: string;
  setMethod: React.Dispatch<React.SetStateAction<string>>;
  setVolunteerWaitList: React.Dispatch<React.SetStateAction<IVolunteer[]>>;
  volunteerWaitList: IVolunteer[];
  waitedList: IVolunteer[];
  openNoWaitedListModal: boolean;
  setOpenNoWaitedListModal: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDontEnoughWaitedListModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const RemoveVolunteerModal = ({
  eventId,
  loading,
  _onCloseModal,
  open,
  _onSubmit,
  userSelected,
  reasonRemove,
  setReasonRemove,
  method,
  setMethod,
  volunteerWaitList,
  setVolunteerWaitList,
  waitedList,
  openNoWaitedListModal,
  setOpenNoWaitedListModal,
  setOpenDontEnoughWaitedListModal,
}: IRemoveVolunteerModal) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({
    hasReason: false,
  });
  const [openWaitList, setOpenWaitList] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [contentModal, setContentModal] = useState('');
  const [hiddenCancelButton, setHiddenCancelButton] = useState(false);

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.name === 'reasonRemove') {
      setReasonRemove(e.target.value);
      if (e.target.value !== '') {
        setErrors({ ...errors, hasReason: false });
      } else {
        setErrors({ ...errors, hasReason: true });
      }
    }

    if (e.target.name === 'method') {
      setMethod(e.target.value);
    }
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setReasonRemove(valueTrimmed);
  };

  const handleSubmit = () => {
    if (reasonRemove === '') {
      setErrors({ ...errors, hasReason: true });
      return;
    }

    if (method === 'manual') {
      if (!waitedList.length && !openNoWaitedListModal) {
        setOpenNoWaitedListModal(true);
        return;
      }
      if (waitedList.length <= (userSelected ? userSelected.length : 0)) {
        setOpenDontEnoughWaitedListModal(true);
        return;
      }
      setOpenWaitList(true);
      return;
    }

    _onSubmit?.();
  };

  const handleClose = () => {
    _onCloseModal?.();
  };

  const _goBack = () => {
    setOpenWaitList(false);
  };

  const handleModal = () => {
    setOpenModal(!isOpenModal);
    setHiddenCancelButton(false);
  };

  const _onSelectVolunteerWaitList = () => {
    if (userSelected && userSelected.length < volunteerWaitList.length) {
      setContentModal(`You can not select more than ${userSelected.length} persons from the waitlist`);
      handleModal();
      setHiddenCancelButton(true);
    }

    if (userSelected && userSelected.length >= volunteerWaitList.length) {
      if (userSelected.length - volunteerWaitList.length > 0) {
        setContentModal(
          `${
            userSelected.length - volunteerWaitList.length
          } persons will be chosen from the waitlist as first comes first serves.`,
        );
        handleModal();
      } else {
        _onRemove();
      }
    }
  };

  const _onRemove = () => {
    _onSubmit?.();
  };

  React.useEffect(() => {
    setErrors({ ...errors, hasReason: false });
    setReasonRemove('');
    setOpenWaitList(false);
    setMethod('auto');
    setOpenModal(false);
  }, [open]);

  return (
    <CustomFormDialog
      title={openWaitList ? 'Choose volunteer from the waitlist' : 'Remove Volunteer From Confirmed List'}
      open={open}
      _onCloseModal={openWaitList ? _goBack : handleClose}
      submitText={openWaitList ? 'Submit' : 'Save'}
      _onSubmit={openWaitList ? _onSelectVolunteerWaitList : handleSubmit}
      disabled={loading}
      noClickOutSide
      hiddenCloseButton={openWaitList ? true : false}
      isConfirmPopup={true}
    >
      {openWaitList ? (
        <WaitList
          eventId={eventId}
          setVolunteerWaitList={setVolunteerWaitList}
          volunteerWaitList={volunteerWaitList}
          userSelected={userSelected}
        />
      ) : (
        <Box display="flex" flex={1} mt={1} mb={2}>
          <Box width={'50%'}>
            <LabelStyled>Removed Volunteer: [x] person(s)</LabelStyled>
            <SelectBox mt={1} style={{ overflowY: 'auto' }}>
              {userSelected?.sort(sortUserNameInUser)?.map((user, index) => {
                return (
                  <SelectBoxItem p={1} index={index}>
                    <TextStyled>
                      {`${user?.user?.surname}, ${user?.user?.givenName} ( ${user?.user?.emailAddress} )`?.length > 45
                        ? `${user?.user?.surname}, ${user?.user?.givenName} ( ${user?.user?.emailAddress} )`?.slice(
                            0,
                            45,
                          ) + '...'
                        : `${user?.user?.surname}, ${user?.user?.givenName} ( ${user?.user?.emailAddress} )`}
                    </TextStyled>
                  </SelectBoxItem>
                );
              })}
            </SelectBox>
            <Box mt={4}>
              <LabelStyled>Please select replacement method:</LabelStyled>
              <Box>
                <FormControl component="fieldset">
                  <RadioGroup value={method} onChange={_handleChange} aria-label="eventRequire" name="method">
                    <FormControlLabel
                      value="auto"
                      control={<NASSRadio />}
                      label="Auto add volunteer from the waitlist"
                    />
                    <FormControlLabel
                      value="manual"
                      control={<NASSRadio />}
                      label="Choose volunteer from the waitlist"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box width={'50%'}>
            <Box ml={3}>
              <LabelStyled display="inline">
                Reason
                <Typography display="inline" color="error">
                  {' '}
                  *
                </Typography>
              </LabelStyled>
              <Box>
                <InputField
                  className={classes.input}
                  value={reasonRemove}
                  onChange={_handleChange}
                  onBlur={onBlurField}
                  name="reasonRemove"
                  multiline
                  rows={9}
                  rowsMax={9}
                  fullWidth
                  error={errors.hasReason}
                  errorMessage="This field is required"
                  InputProps={{
                    inputProps: {
                      maxLength: MAXIMUM_LENGTH,
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Notification
        open={isOpenModal}
        handleClose={handleModal}
        content={contentModal}
        hiddenCancelButton={hiddenCancelButton}
        onSubmit={userSelected && userSelected.length >= volunteerWaitList.length ? _onRemove : handleModal}
      />
    </CustomFormDialog>
  );
};

export default RemoveVolunteerModal;

const LabelStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.6)',
}));

const SelectBox = styled(Box)(() => ({
  border: '1px solid #ccc',
  height: 205,
  borderRadius: 6,
}));

const SelectBoxItem = styled(Box)(({ index }: { index: number }) => ({
  backgroundColor: index % 2 ? '#F8F9FA' : 'white',
  borderBottom: '1px solid #ccc',
}));

const TextStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
}));
