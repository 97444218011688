import React from 'react';

import { Box, FormControl, FormHelperText, TextField, TextFieldProps, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import noticeBlackIcon from 'src/medias/icons/notice-black.svg';
import noticeYellowIcon from 'src/medias/icons/notice-yellow.svg';
import noticeIcon from 'src/medias/icons/notice.png';

const useStyles = makeStyles((theme) => ({
  outlined: {
    '& .MuiFormLabel-root': {},
    '& .MuiInputBase-root': {
      backgroundColor: 'white',
      borderRadius: 6,
    },
    '& .MuiFilledInput-root:focus': {},
    '& .Mui-disabled': {
      backgroundColor: 'rgba(113, 128, 150, 0.1)',
      '& > svg': {
        display: 'none',
      },
    },
    '& .MuiSelect-root': {
      padding: '10.5px',
    },
    '& .Mui-error input': {
      color: `red !important;`,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(113, 128, 150, 0.5)',
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(113, 128, 150, 0.5)',
    },
    '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: 'rgba(0, 0, 0, 0.1)',
    },
  },
  noticeRequire: {
    color: 'red',
    verticalAlign: 'middle',
    marginLeft: 4,
  },
}));

const Title = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 700;
    vertical-align: middle;
    max-height: 18px;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const Icon = styled.img(() => ({
  marginLeft: 8,
  marginTop: 2,
}));

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#E1DFC5',
    color: '#6B695D',
    fontSize: 16,
    borderRadius: 4,
    fontWeight: 400,
  },
  arrow: {
    color: '#E1DFC5',
  },
}))(Tooltip);

const FormHelperTextCustom = styled(FormHelperText)(({ theme }) => ({
  ...theme.fonts.textS,
  marginTop: '10px',
  color: 'red !important',
}));
interface IInputField {
  title?: string;
  customBoxTitle?: React.CSSProperties;
  colorTitle?: string;
  lableStyle?: React.CSSProperties;
  require?: boolean;
  notice?: boolean;
  noticeTitle?: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
  noticeBlack?: boolean;
  noticeYellow?: boolean;
  error?: boolean;
  errormessage?: string | boolean;
  errorMessage?: string;
  marginTop?: string;
}

export function InputField({
  title,
  customBoxTitle,
  colorTitle,
  lableStyle,
  require,
  notice,
  noticeTitle,
  noticeBlack,
  noticeYellow,
  error,
  errormessage,
  errorMessage,
  marginTop,
  _onKeyDown,
  ...props
}: IInputField & TextFieldProps & any) {
  const classes = useStyles();

  return (
    <FormControl fullWidth {...props}>
      {title && (
        <Box mt={2} display="flex" flexDirection="row" style={{ ...customBoxTitle }}>
          <Title style={{ color: colorTitle && colorTitle, ...lableStyle }}>
            {title}
            {require && (
              <Typography component="span" className={classes.noticeRequire}>
                *
              </Typography>
            )}
          </Title>
          {notice && (
            <LightTooltip title={noticeTitle ?? ''} placement="top" arrow>
              <Icon src={noticeBlack ? noticeBlackIcon : noticeYellow ? noticeYellowIcon : noticeIcon} />
            </LightTooltip>
          )}
        </Box>
      )}

      <TextField
        {...props}
        className={classes.outlined}
        size="small"
        variant="outlined"
        margin="dense"
        style={{ marginTop }}
        InputProps={{
          inputProps: {
            disableUnderline: true,
          },
          ...props.InputProps,
        }}
        error={error}
        onKeyDown={_onKeyDown}
      />
      {error && <FormHelperTextCustom>{errormessage || errorMessage}</FormHelperTextCustom>}
    </FormControl>
  );
}
