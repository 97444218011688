import { Box, Typography, Button } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { IResponsive } from 'src/pages/home/homepageStyled';

export const CreateEventDialogContainer = styled(Box)`
  ${() => css`
    background-color: white;
    min-height: 600px;
    padding: 24px;
    border-radius: 12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `};
`;
export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 36px;
`;
export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header3.fontSize};
    font-weight: ${theme.fonts.header3.fontWeight};
    line-height: ${theme.fonts.header3.lineHeight};
    color: #000000cc;
  `}
`;
export const Icon = styled(Box)`
  ${({ theme }) => css`
    background: ${theme.palette.yellow?.[900]};
    border-radius: 6px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;
export const FormLabel = styled(Typography)<{ required?: boolean }>`
  ${({ theme, required }) => css`
    font-size: ${theme.fonts.header6.fontSize};
    font-weight: ${theme.fonts.header6.fontWeight};
    line-height: ${theme.fonts.header6.lineHeight};
    margin-bottom: 4px;

    ${required &&
    css`
      &::after {
        content: '*';
        color: ${theme.palette.red?.[250]};
        display: inline-block;
        margin-left: 5px;
      }
    `}
  `}
`;

export const Action = styled(Box)`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;
export const Cancel = styled(Button)`
  ${({ theme, disabled }) => css`
    font-size: 16px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.palette.yellow?.[900]};
    border-radius: 6px;
    background: white;
    width: 193px;
    height: 44px;
    color: ${theme.palette.yellow?.[900]};
    ${disabled &&
    css`
      background-color: #dcdcdd;
      pointer-events: none;
      border-color: #dcdcdd;
    `}
  `}
`;
export const Create = styled(Button)`
  ${({ theme, disabled }) => css`
    font-size: 16px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    color: white;
    background: ${theme.palette.yellow?.[900]};
    width: 193px;
    height: 44px;

    ${disabled &&
    css`
      background-color: #dcdcdd;
      pointer-events: none;
      border-color: #dcdcdd;
    `}

    &:hover {
      color: white;
      background: ${theme.palette.yellow?.[900]};
    }
  `}
`;

export const FormStyled = styled.form<{ responsive?: IResponsive }>`
  ${({ responsive }) => css`
    height: 100%;
    ${responsive?.isMobile &&
    css`
      min-width: 1200px;
    `}
  `}
`;
