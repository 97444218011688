import React, { useMemo } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { Room } from '@material-ui/icons';
import {
  createAlignPlugin,
  createBoldPlugin,
  createDndPlugin,
  createExitBreakPlugin,
  createFontBackgroundColorPlugin,
  createFontColorPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createHistoryPlugin,
  createImagePlugin,
  createItalicPlugin,
  createKbdPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createReactPlugin,
  createSelectOnBackspacePlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  ELEMENT_IMAGE,
  Plate,
  PlatePlugin,
  SPEditor,
} from '@udecode/plate';
import moment from 'moment';
import { useHistory } from 'react-router';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';

import ShareToButton from 'src/components/button/ShareToButton';
import ConfirmPopup from 'src/components/confirm';
import { editablePreviewProps, optionsExitBreakPlugin } from 'src/components/slateEditor/config/pluginOptions';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS, FORMAT_TIME_AM_PM } from 'src/constants/common';
import { localStorageHelper } from 'src/helpers';
import useFetchMe from 'src/hooks/use-fetch-me';
import YellowCalendar from 'src/medias/icons/yellow-calendar.png';
import FBLogo from 'src/medias/images/facebook-logo.png';
import TwitterLogo from 'src/medias/images/twitter-logo.png';
import { components } from 'src/pages/admin/components/Preview';
import { IEvent, registerEventGTKY, registrationEvent, registrationEventByVolunteer } from 'src/services/events';
import { checkUserRegisterVolunteer } from 'src/services/volunteer';
import useResponsive from 'src/utils/responsive';

interface IEventDetail {
  event: IEvent;
}

const Event = styled(Box)`
  flex: 1;
`;

const EventShare = styled(Box)`
  ${({ theme }) => css`
    color: #cfcfcf;
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing(3)}px;
    margin-bottom: ${theme.spacing(3)}px;
  `}
`;
export const ShareIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 9px;
  border-radius: 5px;
`;
const EventContent = styled(Box)`
  ${({ theme }) => css`
    color: ${theme.palette.gray?.[650]};
    line-height: 27px;
    text-align: justify;
  `}
`;
const EventAction = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    gap: 24px;
    margin-top: ${theme.spacing(6)}px;
  `}
`;
const EventActionButton = styled(Button)<{ variants: 'register' | 'volunteer' }>`
  ${({ theme }) => ({
    ...theme.fonts.textSBold,
  })}
  ${({ theme, variants, disabled }) => css`
    border-radius: 3px;
    flex: 50%;
    background: ${variants === 'register' ? theme.palette.common.white : theme.palette.yellow?.[900]};
    outline: 1px solid ${variants === 'register' ? '#cfcfcf' : 'transparent'};
    color: ${variants === 'register' ? theme.palette.gray?.[1000] : theme.palette.common.white};
    padding: ${theme.spacing(2)}px;
    text-transform: capitalize;
    width: 100%;
    &:hover {
      background-color: ${variants === 'volunteer' ? theme.palette.yellow?.[900] : ''};
    }
    ${disabled &&
    css`
      background-color: #dcdcdd;
      pointer-events: none;
      user-select: none;
    `}
  `}
`;
const EventDetailContentContainer = styled(Box)`
  display: flex;
  gap: 130px;
`;
const EventInterestedHeader = styled(Typography)`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-size: ${theme.fonts.header3.fontSize};
    font-weight: ${theme.fonts.header3.fontWeight};
    margin-bottom: ${theme.spacing(3)}px;
  `}
`;

type TEditor = SPEditor & ReactEditor & HistoryEditor;

function EventDetailContent({ event }: IEventDetail) {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [loadingRegister, setLoadingRegister] = React.useState(false);
  const [isActionSuccess, setActionSuccess] = React.useState(false);
  const [isActionFailed, setActionFailed] = React.useState(false);
  const [messageResponse, setMessageResponse] = React.useState('');
  const isLoggedIn = localStorageHelper.isLogin();
  const [isShowConfirmPopup, setShowConfirmPopup] = React.useState(false);
  const startTime = moment(+event.start_time!);
  const nowTime = moment();
  const EVENT_STATUS_PENDING = 'pending';
  const START_TIME_GREAT_THAN_NOW_TIME = startTime > nowTime;
  const EVENT_REGISTER_PATH = `/registration-forms/${event.id}`;
  const GTKY_EVENT = 'gtky-party';
  const YES = 'yes';
  const NO = 'no';
  const CANCEL = 'cancel';
  const responsive = useResponsive();
  const ARE_YOU_SURE_REGISTER_EVENT = 'Are you sure you want to register for this event?';
  const NOTIFICATION_TOTAL_TICKET_SOLD_OUT = 'All tickets have been sold out. Please choose another events';
  const IN_VOLUNTEER_LIST_MESSAGE =
    'You’re already volunteering for this event. To register for this event as a guest, you should cancel your volunteer registration first';

  const handleToggleConfirmPopup = () => setShowConfirmPopup(!isShowConfirmPopup);
  const { me } = useFetchMe();
  const [message, setMessage] = React.useState(ARE_YOU_SURE_REGISTER_EVENT);
  const [isInVolunteerList, setIsInVolunteerList] = React.useState(false);

  React.useEffect(() => {
    if (isLoggedIn) {
      (async () => {
        const response = await checkUserRegisterVolunteer(event.id);
        if (response.data) {
          setIsInVolunteerList(true);
        }
      })();
    }
  }, [isLoggedIn]);

  const pluginsMemo: PlatePlugin<TEditor>[] = useMemo(() => {
    const plugins = [
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createMediaEmbedPlugin(),
      createAlignPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createDndPlugin(),
      createExitBreakPlugin(optionsExitBreakPlugin),
      createSelectOnBackspacePlugin({
        allow: [ELEMENT_IMAGE],
      }),
    ];

    return plugins;
  }, []);

  if (!event) return <div />;
  const isShowRegister =
    event.status === EVENT_STATUS_PENDING && START_TIME_GREAT_THAN_NOW_TIME && event.link_for_registration;
  const isShowVolunteer = event.link_for_volunteer_registration;

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const _onClickVolunteerNow = async () => {
    setLoading(true);

    const response = await registrationEventByVolunteer(event.id, 'register');

    if (response?.statusCode === CALL_SUCCESS) {
      setActionSuccess(true);
      setMessageResponse(response.message);
      setLoading(false);
      (async () => {
        const response = await checkUserRegisterVolunteer(event.id);
        if (response.data) {
          setIsInVolunteerList(true);
        }
      })();
    } else {
      setActionFailed(true);
      setMessageResponse(response.message);
      setLoading(false);
    }
  };

  const handleRegisterEvent = () => {
    if (!event.is_public && !isLoggedIn) {
      window.location.href = '/login';
      return;
    }
    if (isInVolunteerList) {
      setActionFailed(true);
      setMessageResponse(IN_VOLUNTEER_LIST_MESSAGE);
      return;
    }
    if (event?.max_guest === event?.current_guest) {
      setMessage(NOTIFICATION_TOTAL_TICKET_SOLD_OUT);
      setShowConfirmPopup(true);
      return;
    } else {
      setMessage(ARE_YOU_SURE_REGISTER_EVENT);
      setShowConfirmPopup(true);
    }
  };

  const handleOkConfirmPopup = () => {
    setShowConfirmPopup(false);
    if (!event.is_public) {
      if (!isLoggedIn) {
        window.location.href = '/login';
        return;
      }
      if (event.category === GTKY_EVENT) {
        (async () => {
          setLoadingRegister(true);
          const response = await registerEventGTKY(event.id);
          if (response?.statusCode === CALL_SUCCESS) {
            setActionSuccess(true);
            setMessageResponse(response.message);
            setLoadingRegister(false);
          } else {
            setActionFailed(true);
            setMessageResponse(response.message);
            setLoadingRegister(false);
          }
        })();
        return;
      }
      (async () => {
        setLoading(true);
        setLoadingRegister(true);
        const response = await registrationEvent({
          eventId: event.id,
          surName: me?.surname,
          givenName: me?.givenName,
          email: me?.emailAddress,
          areaCode: me?.areaCode,
          mobilePhone: me?.mobilePhone,
          attendedNsaa:
            me?.periodStudyInNYK || me?.periodStudyInNPP || me?.periodStudyInNYP || me?.periodStudyInNYGH ? YES : NO,
          periodStudyInNYK: me?.periodStudyInNYK ? me?.periodStudyInNYK : null,
          periodStudyInNPP: me?.periodStudyInNPP ? me?.periodStudyInNPP : null,
          periodStudyInNYP: me?.periodStudyInNYP ? me?.periodStudyInNYP : null,
          periodStudyInNYGH: me?.periodStudyInNYGH ? me?.periodStudyInNYGH : null,
          totalTicket: 1,
          userId: me?.id,
        });
        if (response?.statusCode === CALL_SUCCESS) {
          setActionSuccess(true);
          setMessageResponse(response.message);
          setLoading(false);
          setLoadingRegister(false);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
          setLoading(false);
          setLoadingRegister(false);
        }
        return;
      })();
    } else {
      window.location.pathname = EVENT_REGISTER_PATH;
    }
  };

  return (
    <Box>
      <ConfirmPopup
        title="Notification"
        content={message}
        cancelText={message === NOTIFICATION_TOTAL_TICKET_SOLD_OUT ? CANCEL : NO}
        okText={'Yes'}
        btnCancelVariant={'outlined'}
        btnOkVariant={'filled'}
        open={isShowConfirmPopup}
        onCancel={handleToggleConfirmPopup}
        onOk={handleOkConfirmPopup}
        hiddenBtnOk={message === NOTIFICATION_TOTAL_TICKET_SOLD_OUT}
        hiddenBtnCancel={false}
      />
      {responsive.isTabletOrMobile ? (
        <Box maxWidth={846} mx="auto" my={2}>
          <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
          <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
          <Box>
            <Box display="flex" justifyContent="space-between" mt={2} pl={2} pr={2} alignItems="flex-start">
              <Box display="flex" alignItems="center" style={{ gap: 10 }} color="#C5BE8B">
                <Box>
                  <Box display={'flex'} alignItems="center" gridGap={10}>
                    <img src={YellowCalendar} alt="yellow calendar" />
                    <Box>
                      <span> {moment(+event.start_time!).format(FORMAT_TIME_AM_PM)}</span> {' SGT'} <br />
                      <span> {moment(+event.end_time!).format(FORMAT_TIME_AM_PM)}</span> {' SGT'}
                    </Box>
                  </Box>
                  <Box display={'flex'} alignItems="center" gridGap={10} mt={1.5} ml={'-2px'}>
                    <Room style={{ color: '#C5BE8B' }} fontSize="small" />{' '}
                    <Box pr={'7px'} style={{ wordBreak: 'break-all' }}>
                      {event.location || ''}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <NewsShare>
                <Box mr={1}>Share on</Box>
                <ShareToButton children={<Logo src={FBLogo} />} url={history.location.pathname} facebook />
                <ShareToButton children={<Logo src={TwitterLogo} />} url={history.location.pathname} twitter />
              </NewsShare>
            </Box>
            <Title responsive={responsive}>{event?.title || ''}</Title>
          </Box>
          <Box pl={2} pr={2}>
            <Plate
              id={'NEWS_DETAIL'}
              plugins={pluginsMemo}
              components={components}
              editableProps={editablePreviewProps}
              value={event.description}
            />
          </Box>
          <Box display="flex" mt={4} style={{ gap: 16 }} mx={2}>
            {isShowRegister && (
              <EventActionButton variants="register" onClick={handleRegisterEvent} disabled={loadingRegister}>
                Register Now
              </EventActionButton>
            )}
            {isShowVolunteer && (
              <EventActionButton disabled={loading} onClick={_onClickVolunteerNow} variants="volunteer">
                Volunteer Now
              </EventActionButton>
            )}
          </Box>
        </Box>
      ) : (
        <Box maxWidth={846} mx="auto">
          <EventDetailContentContainer py={10} pt={14}>
            <Event>
              <EventInterestedHeader>DESCRIPTION</EventInterestedHeader>
              <EventContent>
                <Box pl={2} pr={2}>
                  <Plate
                    id={'EVENT_DETAIL'}
                    plugins={pluginsMemo}
                    components={components}
                    editableProps={editablePreviewProps}
                    value={event.description}
                  />
                </Box>
              </EventContent>
              <EventShare>
                <Box mr={1}>Share on</Box>
                <ShareToButton children={<ShareIcon src={FBLogo} />} url={history.location.pathname} facebook />
                <ShareToButton children={<ShareIcon src={TwitterLogo} />} url={history.location.pathname} twitter />
              </EventShare>
              <EventAction>
                {isShowRegister && (
                  <EventActionButton variants="register" onClick={handleRegisterEvent} disabled={loadingRegister}>
                    Register Now
                  </EventActionButton>
                )}
                {isShowVolunteer && (
                  <EventActionButton disabled={loading} onClick={_onClickVolunteerNow} variants="volunteer">
                    Volunteer Now
                  </EventActionButton>
                )}
              </EventAction>
            </Event>
          </EventDetailContentContainer>
          <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
          <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
        </Box>
      )}
    </Box>
  );
}

export default EventDetailContent;

const Title = styled(Typography)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    margin: ${theme.spacing(3)}px 0;
    font-size: ${responsive?.isTabletOrMobile ? '20px' : '45px'};
    font-weight: 700;
    padding: ${responsive?.isTabletOrMobile ? '0px 16px' : '0'};
  `}
`;
const Logo = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 3px;
`;

const NewsShare = styled(Box)`
  ${({ theme }) => css`
    color: #cfcfcf;
    display: flex;
    align-items: center;
  `}
`;
