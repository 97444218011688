import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import styled from 'styled-components';

import { HeaderMobileChinese } from 'src/components/headerMobileChinese';
import useFetchMe from 'src/hooks/use-fetch-me';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import useResponsive from 'src/utils/responsive';

import NsaaEvent from './components/Events';
import NsaaNews from './components/News';
import Sliders from './components/Sliders/Sliders';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const HomeChinese = () => {
  const [current, setCurrent] = useState(0);

  const responsive = useResponsive();
  const { me } = useFetchMe();

  useScrollToTop();
  return (
    <Container container>
      {responsive.isTabletOrMobile && <HeaderMobileChinese title={`主页`} userProfile={me} />}
      <Sliders responsive={responsive} setCurrent={setCurrent} current={current} />
      <NsaaEvent responsive={responsive} />
      <NsaaNews responsive={responsive} />
    </Container>
  );
};

const Container = styled(Grid)``;
