import httpClient from 'src/configs/http-client';
import { IResponse, IResponseList } from 'src/types';

import { IQuizResponseList, ISurvey, ISurveyParams } from './quiz.interface';

export const getResponseListQuiz = async (
  eventId: string,
  { currentPage, limit }: { currentPage: number; limit: number },
) => {
  const response = await httpClient.get<IResponseList<IQuizResponseList>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/get-response?page=${currentPage}&limit=${limit}`,
  );
  return response?.data;
};

export const getSurvey = async ({ eventId }: { eventId: string }) => {
  const response = await httpClient.get<IResponse<ISurvey>>(`${process.env.REACT_APP_API_URL}/quiz/${eventId}/survey`);
  return response?.data;
};

export const getSurveyPermission = async ({ eventId }: { eventId: string }) => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/guest-check`,
  );
  return response?.data;
};

export const getQuizSetting = async ({ eventId }: { eventId: string }) => {
  const response = await httpClient.get<IResponse<any>>(`${process.env.REACT_APP_API_URL}/quiz/${eventId}/setting`);
  return response?.data;
};

export const updateQuizSetting = async (eventId: string, body: { isOpen: boolean }) => {
  const response = await httpClient.patch<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/quiz/${eventId}/status`,
    body,
  );
  return response?.data;
};

export const submitSurvey = async (body: ISurveyParams) => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/quiz/response`, body);
  return response?.data;
};

export const getResponseQuizDetail = async (responseId: string) => {
  const response = await httpClient.get<IResponse<any>>(`${process.env.REACT_APP_API_URL}/quiz/response/${responseId}`);
  return response?.data;
};

export const createQuiz = async (payload: any) => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/quiz`, payload);
  return response?.data;
};

export const getQuizByEventId = async (eventId: string) => {
  const response = await httpClient.get<IResponse<any>>(`${process.env.REACT_APP_API_URL}/quiz/${eventId}`);
  return response?.data;
};
export const updateQuizByEventId = async (eventId: string, payload: any) => {
  const response = await httpClient.patch<IResponse<any>>(`${process.env.REACT_APP_API_URL}/quiz`, payload);
  return response?.data;
};
