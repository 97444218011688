import React, { useEffect, useState } from 'react';

import { CALL_SUCCESS } from 'src/constants/common';
import { getUsers } from 'src/services/users';
import { IPagination, ISearchUser, IUser } from 'src/services/users/users.interface';

import UserDetail from '../shared/user-detail.component';
import { Container, defaultSearchUser, Header } from '../types';

import Listing from './Listing';
import SearchUser from './SearchUser';

const UserList = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [data, setData] = useState<IPagination>();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<ISearchUser>(defaultSearchUser);
  const [userDetailId, setUserDetailId] = useState<string>();
  const [isShowUserDetail, setShowDetailUser] = useState(false);

  async function getData({ search, currentPage, limit }: { search?: ISearchUser; currentPage: number; limit: number }) {
    setLoading(true);
    const response = await getUsers({ search, currentPage, limit });

    if (response?.statusCode === CALL_SUCCESS) {
      setUsers(response.data.items);
      setData(response.data.meta);
    }
    setLoading(false);
  }

  const _onSearch = (searchData: ISearchUser) => () => {
    setSearch(searchData);
    getData({
      search: searchData,
      currentPage: 1,
      limit,
    });
    setCurrentPage(1);
  };

  const _onReset = async () => {
    setLoading(true);
    setSearch(defaultSearchUser);
    setCurrentPage(1);

    const response = await getUsers({
      search: defaultSearchUser,
      currentPage: 1,
      limit,
    });

    if (response?.statusCode === CALL_SUCCESS) {
      setUsers(response.data.items);
      setData(response.data.meta);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!isShowUserDetail) {
      getData({
        search,
        currentPage,
        limit,
      });
    }
  }, [isShowUserDetail, currentPage, limit]);

  function handleCloseUserDetail() {
    setShowDetailUser(false);
  }

  return (
    <Container minHeight={500} p={4} bgcolor="white">
      {isShowUserDetail ? (
        <UserDetail userId={userDetailId!} onClose={handleCloseUserDetail} />
      ) : (
        <>
          <Header>USER LIST</Header>
          <SearchUser searchData={search} loading={loading} _onSearch={_onSearch} _onReset={_onReset} />
          <Listing
            users={users}
            data={data}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            loading={loading}
            limit={limit}
            setLimit={setLimit}
            getData={getData}
            setShowDetailUser={setShowDetailUser}
            setUserDetailId={setUserDetailId}
            search={search}
          />
        </>
      )}
    </Container>
  );
};

export default UserList;
