import React from 'react';

import { Box, DialogContentText, DialogTitle } from '@material-ui/core';
import styled from 'styled-components';

import { CustomDialog } from 'src/components';

const DialogTitleStyled = styled(DialogTitle)(() => ({
  '& > h2': {
    fontSize: 20,
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.8)',
  },
  marginTop: 16,
  alignSelf: 'center',
}));

const DialogContentTextStyled = styled(DialogContentText)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
  padding: 16,
}));

interface IConfirmModal {
  open: boolean;
  handleClose: () => void;
  _onContinue: () => void;
  disabled?: boolean;
}

export const ConfirmModal = ({ disabled, open, handleClose, _onContinue }: IConfirmModal) => {
  return (
    <CustomDialog
      disabled={disabled}
      open={open}
      handleClose={handleClose}
      onSubmit={_onContinue}
      cancelText="No, I’ve already paid it"
      submitText="Continue"
      isConfirmPopup={true}
    >
      <DialogTitleStyled id="form-dialog-title">Notification</DialogTitleStyled>
      <Box alignSelf="center" display="flex" flex={1} justifyContent="center" alignItems="center" textAlign="center">
        <DialogContentTextStyled>
          We found a pending payment of you. Don’t worry, if you already paid the membership fee, your payment may not
          be reconciled in our system. Please be patient.
          <br />
          If you haven’t paid the membership before, please continue” with 2 buttons ‘No, I’ve already paid it’ and
          ‘Continue’
        </DialogContentTextStyled>
      </Box>
    </CustomDialog>
  );
};
