import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import moment from 'moment';
import QRCode from 'react-qr-code';

import { CustomButton } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import BankSupportedURL from 'src/medias/images/banks-supported.png';
import { TextNote } from 'src/pages/signup/components/types';
import { ISendMailForPayment, sendMailPayment } from 'src/services/payment';
import PaynowQR from 'src/utils/paynow-code-generator';
import useResponsive from 'src/utils/responsive';

import { CaptionStyled, ImageStyled, SubCaptionStyled } from '../types';

interface IPaymentMethod {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  type: 'nsaa' | 'nygh';
  amount: string;
  nirc?: string;
  requestForTer?: string;
  fullName: string;
  paymentId: string;
}

const FORMAT = 'YYYYMMDD';
const COMPANY_NAME = 'VMO';
const UEN_NSAA = 'S83SS0071H';
const URN_NYGH = '199205016K';
const NSAA = 'nsaa';
const NYGH = 'nygh';
const QR_SIZE = 136;

const PaymentMethod = ({
  activeStep,
  setActiveStep,
  amount,
  type,
  nirc,
  fullName,
  paymentId,
  requestForTer,
}: IPaymentMethod) => {
  const { isTabletOrMobile, isTablet } = useResponsive();
  const [loading, setLoading] = useState<boolean>(false);
  const [isActionSuccess, setActionSuccess] = useState(false);
  const [isActionFailed, setActionFailed] = useState(false);
  const [messageResponse, setMessageResponse] = useState('');

  let qrCode;

  if (type === NYGH) {
    qrCode = new PaynowQR({
      uen: URN_NYGH,
      amount: Number(amount),
      editable: false,
      expiry: moment().add(1, 'day').format(FORMAT),
      company: COMPANY_NAME,
      refNumber: `${paymentId} - ${fullName} - NYGH Bursary Fund`,
    });
  }

  if (type === NSAA) {
    qrCode = new PaynowQR({
      uen: UEN_NSAA,
      amount: Number(amount),
      editable: false,
      expiry: moment().add(1, 'day').format(FORMAT),
      company: COMPANY_NAME,
      refNumber: `${paymentId} - ${fullName} - NSAA Bursary Fund`,
    });
  }

  let QRstring = qrCode?.output();

  const _onSubmit = async () => {
    setLoading(true);

    let body: ISendMailForPayment = {
      template: NSAA,
      paymentId,
    };

    if (type === NYGH) {
      body = {
        template: NYGH,
        paymentId,
        requestForTer,
        nirc,
      };
    }

    const response = await sendMailPayment(body);
    if (response && response.statusCode === CALL_SUCCESS) {
      setActionSuccess(true);
      setMessageResponse(response.message);
      setActiveStep(activeStep + 1);
    } else {
      setActionFailed(true);
      setMessageResponse(response.message);
    }
    setLoading(false);
  };

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      flex={1}
      flexDirection="column"
      mt={isTabletOrMobile ? 0 : 6}
      p={isTabletOrMobile ? 0 : 3}
    >
      {isTabletOrMobile ? (
        <TextNote isTabletOrMobile={isTabletOrMobile}>Scan below QR Code using your internet banking app</TextNote>
      ) : (
        <CaptionStyled>Scan below QR Code using your internet banking app</CaptionStyled>
      )}
      <SubCaptionStyled>QR Code will expire in 1 day</SubCaptionStyled>
      <Box m={3}>
        <QRCode value={QRstring} size={QR_SIZE} />
      </Box>
      {!isTabletOrMobile && <ImageStyled src={BankSupportedURL} />}
      {!isTabletOrMobile && (
        <Box>
          <SubCaptionStyled>
            If you finish the payment, please click Finish to complete your registration
          </SubCaptionStyled>
        </Box>
      )}
      <Box
        mt={4}
        display="flex"
        justifyContent="center"
        width={'100%'}
        flex={1}
        alignItems={isTabletOrMobile ? 'flex-end' : 'flex-start'}
        mb={4}
      >
        <CustomButton style={{ width: isTablet && '100%' }} disabled={loading} onClick={_onSubmit}>
          Finish
        </CustomButton>
      </Box>
      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </Box>
  );
};

export default PaymentMethod;
