import 'tippy.js/dist/tippy.css';
import React, { useEffect, useMemo, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import {
  ELEMENT_IMAGE,
  ELEMENT_PARAGRAPH,
  createPlateComponents,
  PlatePlugin,
  Plate,
  createAlignPlugin,
  createBoldPlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createHistoryPlugin,
  createKbdPlugin,
  createImagePlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createReactPlugin,
  createSelectOnBackspacePlugin,
  createDndPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  SPEditor,
  MARK_COLOR,
  withStyledProps,
  StyledLeaf,
  MARK_BG_COLOR,
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
  TNode,
  AnyObject,
  withProps,
  StyledElement,
} from '@udecode/plate';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';

import { IPost } from 'src/services/news';

import Header from './components/header';
import { initialValueEmpty } from './config/initialValues';
import { editableProps, optionsExitBreakPlugin } from './config/pluginOptions';
import { withStyledDraggables } from './config/withStyledDraggables';
import { withStyledPlaceHolders } from './config/withStyledPlaceHolders';

const CustomBox = styled(Box)<{ headerType?: 'event' }>`
  min-height: ${({ headerType }) => (headerType === 'event' ? 100 : 1000)}px;
`;

type TEditor = SPEditor & ReactEditor & HistoryEditor;
const id = 'slateEditor';

let components = createPlateComponents({
  [MARK_COLOR]: withStyledProps(StyledLeaf, {
    leafProps: {
      [MARK_COLOR]: ['color'],
    },
  }),
  [MARK_BG_COLOR]: withStyledProps(StyledLeaf, {
    leafProps: {
      [MARK_BG_COLOR]: ['backgroundColor'],
    },
  }),
  [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
    styles: {
      root: {
        fontSize: '1rem',
      },
    },
  }),
});

components = withStyledPlaceHolders(components);
components = withStyledDraggables(components);

interface IRichEditor {
  type: string;
  value?: TNode<AnyObject>[];
  setValue?: React.Dispatch<React.SetStateAction<TNode<AnyObject>[] | undefined>>;
  post?: IPost | null;
  headerType?: 'event';
  disable?: boolean;
  bgColor?: string;
  require?: boolean;
  radius?: string;
  border?: string;
  onChange?: (val: any) => void;
  empty?: (status: boolean) => void;
}
const GridContainer = styled(Grid)<{ disable?: boolean; bgColor?: string; radius?: string; border?: string }>`
  ${({ theme, disable, bgColor, radius, border }) => css`
    pointer-events: ${disable ? 'none' : 'all'};
    background-color: ${bgColor ? bgColor : 'white'};
    border-radius: ${radius ? radius : '0'};
    border: ${border ? border : '0'};
    min-height: 200px;
  `}
`;
const RichEditor = ({
  type,
  value = initialValueEmpty,
  setValue,
  post,
  headerType,
  disable,
  bgColor,
  require,
  radius,
  border,
  onChange,
  empty,
}: IRichEditor) => {
  const [title, setNewTitle] = useState<string>('');
  const [thumbnail, setThumbnail] = useState<string>('');
  const [shortDescription, setShortDescription] = useState<string>('');
  const [isEmpty, setEmpty] = useState(false);

  async function _onCreateDraftNew() {}

  async function _onUpdatePost() {}
  const pluginsMemo: PlatePlugin<TEditor>[] = useMemo(() => {
    const plugins = [
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createMediaEmbedPlugin(),
      createAlignPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createDndPlugin(),
      createExitBreakPlugin(optionsExitBreakPlugin),
      createSelectOnBackspacePlugin({
        allow: [ELEMENT_IMAGE],
      }),
    ];

    return plugins;
  }, []);

  const _onChange = () => (newValue: TNode<AnyObject>[]) => {
    setValue?.(newValue);
    onChange?.(newValue);
  };

  useEffect(() => {
    empty?.(isEmpty);
  }, [isEmpty]);

  useEffect(() => {
    if (!value) {
      setEmpty(true);
    } else if (value?.length === 1) {
      let array = value;
      array.forEach((item) => {
        let text;
        // eslint-disable-next-line array-callback-return
        item.children.map((textValue: any) => {
          text = textValue.text;
          if (!text?.replace(/\s/g, '')?.length) {
            setEmpty(true);
          } else {
            setEmpty(false);
          }
        });
      });
    } else {
      setEmpty(false);
    }
  }, [value]);

  return (
    <Grid
      container
      justifyContent="space-between"
      style={{
        marginTop: headerType === 'event' ? 0 : 120,
        border: require ? '1px solid red' : 0,
        borderRadius: border ? 6 : 0,
      }}
    >
      {!disable && (
        <Header
          type={type}
          title={title}
          content={value}
          setNewTitle={setNewTitle}
          thumbnail={thumbnail}
          setThumbnail={setThumbnail}
          shortDescription={shortDescription}
          setShortDescription={setShortDescription}
          _onCreateDraftNew={_onCreateDraftNew}
          _onUpdatePost={_onUpdatePost}
          post={post}
          headerType={headerType}
          disable={disable}
        />
      )}
      <GridContainer item xs={12} disable={disable} bgColor={bgColor} radius={radius} border={border}>
        <CustomBox p={2} headerType={headerType}>
          <DndProvider backend={HTML5Backend}>
            <Plate
              id={id}
              plugins={pluginsMemo}
              components={components}
              editableProps={editableProps}
              onChange={_onChange()}
              value={value}
            />
          </DndProvider>
        </CustomBox>
      </GridContainer>
    </Grid>
  );
};

export default RichEditor;
