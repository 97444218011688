import React from 'react';

import { Box, IconButton, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { CustomDialog } from 'src/components';
import CloseIcon from 'src/medias/icons/close-icon.svg';
import { IResponsive } from 'src/pages/home/homepageStyled';
import { CloseIconStyled } from 'src/pages/signup/components/Child';

export const BoxStyled = styled(Box)<{ responsive?: IResponsive }>(({ theme, width, responsive }) => ({
  WebkitTextSizeAdjust: 'none',
  textSizeAdjust: 'none',
  zoom: responsive?.isMobile ? 0.3 : responsive?.isTablet ? 0.6 : 1,
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '24px',
  alignItems: 'center',
  width: '100%',
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  zIndex: 1,
}));

export const UserInfoTitle = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
}));

interface ICustomFormDialog {
  _onCloseModal?: () => void;
  open: boolean;
  _onSubmit?: () => void;
  title?: string;
  children: any;
  submitText?: string;
  cancelText?: string;
  disabled?: boolean;
  noClickOutSide?: boolean;
  deleteButton?: boolean;
  hiddenCloseButton?: boolean;
  hiddenSubmitButton?: boolean;
  minHeight?: string;
  responsive?: IResponsive;
  modalStyles?: React.CSSProperties;
  minWidth?: string;
  minWidthPaper?: string;
  isConfirmPopup?: boolean;
}

const CustomFormDialog = ({
  _onCloseModal,
  open,
  title,
  children,
  _onSubmit,
  submitText = 'OK',
  cancelText = 'Cancel',
  disabled,
  noClickOutSide,
  deleteButton,
  hiddenCloseButton,
  hiddenSubmitButton,
  minHeight = '250px',
  minWidth,
  responsive,
  modalStyles,
  minWidthPaper,
  isConfirmPopup,
}: ICustomFormDialog) => {
  return (
    <CustomDialog
      open={open}
      handleClose={_onCloseModal}
      modalStyles={{
        marginLeft: 189,
        marginRight: 189,
        borderRadius: 12,
        ...modalStyles,
      }}
      maxWidth="md"
      minWidth={minWidthPaper}
      fullWidth
      onSubmit={_onSubmit}
      submitText={submitText}
      cancelText={cancelText}
      disabled={disabled}
      noClickOutSide={noClickOutSide}
      deleteButton={deleteButton}
      hiddenCancelButton={hiddenCloseButton}
      hiddenCloseButton={hiddenCloseButton}
      hiddenSubmitButton={hiddenSubmitButton}
      isConfirmPopup={isConfirmPopup}
    >
      <BoxStyled responsive={responsive}>
        <UserInfoTitle>{title ?? ''}</UserInfoTitle>
        {!hiddenCloseButton && (
          <IconButton style={{ padding: 0 }} onClick={_onCloseModal}>
            <CloseIconStyled src={CloseIcon} />
          </IconButton>
        )}
      </BoxStyled>
      <Box width={'100%'} pb={2} pl={3} pr={3} style={{ overflowY: 'auto', minHeight, minWidth }}>
        {children}
      </Box>
    </CustomDialog>
  );
};

export default CustomFormDialog;
