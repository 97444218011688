import React from 'react';

interface IDuplicateIcon {
  width?: number;
  height?: number;
}

const DuplicateIcon = ({ width = 24, height = 24, ...props }: IDuplicateIcon & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M11 10H9v3H6v2h3v3h2v-3h3v-2h-3v-3z" />
      <path d="M4 22h12c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2zM4 8h12l.002 12H4V8z" />
      <path d="M20 2H8v2h12v12h2V4c0-1.103-.897-2-2-2z" />
    </svg>
  );
};

export default DuplicateIcon;
