import React from 'react';

import { Dialog, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styled, { css } from 'styled-components';

export type TButtonVariant = 'outlined' | 'filled' | 'danger';

interface IConfirmPopupProps {
  open: boolean;
  cancelText?: string;
  okText?: string;
  title?: string;
  content: string;
  btnCancelVariant: TButtonVariant;
  btnOkVariant: TButtonVariant;
  onCancel: () => void;
  onOk: () => void;
  hiddenBtnOk?: boolean;
  hiddenBtnCancel?: boolean;
  disableActionButton?: boolean;
  disableBackdropClick?: boolean;
}

const useStyles = makeStyles(() => ({
  paper: {
    width: 410,
    borderRadius: 8,
    minHeight: 216,
    padding: 32,
  },
}));

const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header4.fontSize};
    font-weight: ${theme.fonts.header4.fontWeight};
    line-height: 24px;
    text-align: center;
    margin-bottom: 32px;
  `}
`;
const Content = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header6.fontSize};
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin-bottom: 32px;
    color: #00000099;
  `}
`;
const ActionButton = styled(Button)<{ variants: TButtonVariant }>`
  ${({ theme, variants, disabled }) => css`
    width: 165px;
    height: 44px;
    border-radius: 6px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
    flex: 1;
    ${variants === 'danger' &&
    css`
      color: white;
      background-color: #ff1515;
      &:hover {
        background-color: #ff1515;
      }
    `}
    ${variants === 'filled' &&
    css`
      color: white;
      background-color: ${theme.palette.yellow?.[900]};

      &:hover {
        background-color: ${theme.palette.yellow?.[900]};
      }
    `}
    ${variants === 'outlined' &&
    css`
      color: ${theme.palette.yellow?.[900]};
      border: 1px solid ${theme.palette.yellow?.[900]};
    `}
    ${disabled &&
    css`
      && {
        background-color: #dcdcdd;
        span {
          pointer-events: none;
        }
        span p {
          pointer-events: none;
        }
      }
    `}
  `}
`;

const ConfirmPopup = ({
  title = 'Notification',
  content,
  cancelText = 'No',
  okText = 'Yes',
  btnCancelVariant,
  btnOkVariant,
  open,
  onCancel,
  onOk,
  hiddenBtnOk,
  hiddenBtnCancel,
  disableActionButton,
  disableBackdropClick,
}: IConfirmPopupProps) => {
  const { paper } = useStyles();
  return (
    <Dialog classes={{ paper }} open={open} onClose={onCancel} disableBackdropClick={disableBackdropClick}>
      <Box>
        <Title>{title}</Title>
        <Content dangerouslySetInnerHTML={{ __html: content }} />
        <Box display="flex" justifyContent="space-between" style={{ gap: 16 }}>
          {!hiddenBtnCancel && (
            <ActionButton variants={btnCancelVariant} onClick={onCancel}>
              {cancelText}
            </ActionButton>
          )}
          {!hiddenBtnOk && (
            <ActionButton variants={btnOkVariant} onClick={onOk} disabled={disableActionButton}>
              {okText}
            </ActionButton>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ConfirmPopup;
