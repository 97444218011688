import React, { useEffect } from 'react';

import { Accordion, AccordionSummary, Box, Link, Popover, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSelector } from 'react-redux';

import { CustomPagination, Loading } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import NSAATable, { INSAAColumn, INSAADataSource } from 'src/components/table';
import { CALL_SUCCESS } from 'src/constants/common';
import addIcon from 'src/medias/icons/add-icon.png';
import DeleteIcon from 'src/medias/icons/delete-icon.png';
import IconSuccess from 'src/medias/icons/icon-success.png';
import LockIcon from 'src/medias/icons/lock-icon.svg';
import UnLockIcon from 'src/medias/icons/unlock-icon.png';
import VerifyIcon from 'src/medias/icons/verify-icon.png';
import ViewIcon from 'src/medias/icons/view-icon.png';
import {
  approveMembership,
  changeAccountStatus,
  deleteUsers,
  exportEligibleUsersCSV,
  exportUsersCSV,
  IPagination,
  ISearchUser,
  IUser,
  removeCommitteeMember,
  sendEmail,
  setCommitteeMember,
  updateRole,
} from 'src/services/users';
import { selectUserProfile } from 'src/store/authentication/selector';
import { convertSlugToUpperCase, intRegex, shallowEqual } from 'src/utils/common';

import {
  CustomButtonStyled,
  IconStyled,
  MenuItemStyled,
  KeyboardArrowDownIconStyled,
  Title,
  ButtonStatus,
  Actions,
  IconButtonStyled,
  ImageIconStyled,
  LabelStyled,
  itAdminActions,
  IconVerifyStyled,
  memberShipAdminActions,
  secretariatAdminActions,
  defaultSearchUser,
  defaultSearchEligibleUser,
  eligibleUserActions,
  assignRoleLists,
} from '../types';

import BlockAccountModal from './BlockAccountModal';
import { ConfirmModal } from './ConfirmModal';
import CreateUser from './CreateUser';
import EligibleUserInfo from './EligibleUserInfo';
import SendEmailModal from './SendEmailModal';
import UserInformation from './UserInformation';

interface ITable {
  users: IUser[];
  eligibility?: boolean;
  data: IPagination | undefined;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  loading?: boolean;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  getData({ search, currentPage, limit }: { search: ISearchUser; currentPage: number; limit: number }): Promise<void>;
  currentPage: number;
  search: ISearchUser;
  setUserDetailId?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setShowDetailUser?: React.Dispatch<React.SetStateAction<boolean>>;
  isShowUserDetail?: boolean;
  userDetailId?: string | undefined;
}

const Listing = ({
  users,
  eligibility,
  data,
  currentPage,
  setCurrentPage,
  loading,
  limit,
  setLimit,
  getData,
  search,
  setShowDetailUser,
  setUserDetailId,
  isShowUserDetail,
  userDetailId,
}: ITable) => {
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openDrawer, setOpenDrawer] = React.useState({
    userInformation: false,
  });
  const [openCreateUser, setOpenCreateUser] = React.useState(false);
  const [menuPosition, setMenuPosition] = React.useState<any>(null);
  const [userClicked, setUserClicked] = React.useState<IUser>();
  const [disableSearchPage, setDisableSearchPage] = React.useState(false);
  const [searchPage, setSearchPage] = React.useState('');
  const [idsSelected, setIdsSelected] = React.useState<IUser[]>();
  const userProfile = useSelector(selectUserProfile);
  const [openConfirmActionModal, setOpenConfirmActionModal] = React.useState(false);
  const [actionSelected, setActionSelected] = React.useState('');
  const [isActionSuccess, setActionSuccess] = React.useState(false);
  const [isActionFailed, setActionFailed] = React.useState(false);
  const [messageResponse, setMessageResponse] = React.useState('');
  const [openBlockAccountModal, setOpenBlockAccountModal] = React.useState(false);
  const [openSendEmailModal, setOpenSendEmailModal] = React.useState(false);
  const [blockReason, setBlockReason] = React.useState('none');
  const [userSelected, setUserSelected] = React.useState<IUser[]>([]);
  const [disabledSetCommittee, setDisabledSetCommittee] = React.useState(true);
  const [disabledAssignRole, setDisabledAssignRole] = React.useState(true);
  const [disabledRemoveRole, setDisabledRemoveRole] = React.useState(true);
  const [disabledBlockAccount, setDisabledBlockAccount] = React.useState(true);
  const [disabledUnBlockAccount, setDisabledUnBlockAccount] = React.useState(true);

  const LIFE_MEMBER_TEXT = 'Life Member';
  let actions: any[] = [];

  if (userProfile && userProfile.roleName === 'it-admin') {
    actions = itAdminActions;
  }
  if (userProfile && userProfile.roleName === 'membership-admin') {
    actions = memberShipAdminActions;
  }
  if (userProfile && userProfile.roleName === 'secretariat-admin') {
    actions = secretariatAdminActions;
  }

  if (eligibility) {
    actions = eligibleUserActions;
  }

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuPosition(null);
  };

  const handleModal = () => {
    setOpenCreateUser(!openCreateUser);
  };

  const handleBlockAccountModal = () => {
    setOpenBlockAccountModal(!openBlockAccountModal);
    setBlockReason('none');
    setUserSelected([]);
  };

  const handleSendEmailModal = () => {
    setOpenSendEmailModal(!openSendEmailModal);
    setBlockReason('none');
  };

  function handleViewDetailUser(id: string) {
    return function () {
      setShowDetailUser?.(true);
      setUserDetailId?.(id);
    };
  }

  function handleCloseUserDetail() {
    setShowDetailUser?.(false);
    setUserDetailId?.(undefined);
  }

  const toggleDrawer = (name: string, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    setOpenDrawer({ ...openDrawer, [name]: open });
  };

  const _onClickUserName = (user: IUser) => {
    setOpenDrawer({ ...openDrawer, userInformation: true });
    setUserClicked(user);
  };

  const onSelectedItems = (ids: IUser[]) => {
    setIdsSelected(ids);
  };

  const handleChangePagination = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || e.target.value === '0') {
      setSearchPage('');
      return;
    }
    if (e.target.name === 'searchPage') {
      if (data?.totalPages) {
        if (!intRegex.test(e.target.value)) {
          setDisableSearchPage(true);
        } else {
          if (Number(e.target.value) > data.totalPages) {
            setDisableSearchPage(true);
          } else {
            setDisableSearchPage(false);
            setSearchPage(e.target.value);
          }
        }
      } else {
        setDisableSearchPage(true);
      }
    }
    if (e.target.name === 'records/page') {
      setLimit(Number(e.target.value));
      setSearchPage('');
      setCurrentPage(1);
    }
  };

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setSearchPage(valueTrimmed);
  };

  const _onSubmitBlockAccount = () => {
    setOpenConfirmActionModal(true);
  };

  const _onSelectAction = (action: string) => () => {
    if (action === 'Block Account') {
      setActionSelected(action);
      handleBlockAccountModal();
      handleClose();
      return;
    }
    if (action === 'Send Email') {
      setActionSelected(action);
      handleSendEmailModal();
      handleClose();
      return;
    }
    setOpenConfirmActionModal(true);
    setActionSelected(action);
  };

  const _onCloseModalConfirmAction = () => {
    setOpenConfirmActionModal(false);
  };

  const _onSelectedAction = async () => {
    setLoadingAction(true);
    if (
      actionSelected === 'membership-admin' ||
      actionSelected === 'event-ic' ||
      actionSelected === 'secretariat-admin' ||
      actionSelected === 'it-admin' ||
      actionSelected === 'accountant'
    ) {
      if (idsSelected && idsSelected.length > 0) {
        let ids: string[] = [];

        idsSelected.map((item) => {
          return ids.push(item.id);
        });

        const response = await updateRole({ role: actionSelected, ids: ids, action: 'update' });
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
    if (actionSelected === 'Remove Role') {
      if (idsSelected && idsSelected.length > 0) {
        let ids: string[] = [];

        idsSelected.map((item) => {
          return ids.push(item.id);
        });

        const response = await updateRole({ ids: ids, action: 'remove' });
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
    if (actionSelected === 'Block Account') {
      if (userSelected && idsSelected) {
        const data = userSelected.length > 0 ? userSelected : idsSelected;

        let ids: string[] = [];

        data.map((item) => {
          return ids.push(item.id);
        });
        const response = await changeAccountStatus({
          ids: ids,
          action: 'block',
          reason: blockReason,
        });
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleBlockAccountModal();
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
    if (actionSelected === 'Unblock Account') {
      if (userSelected && idsSelected) {
        const data = userSelected.length > 0 ? userSelected : idsSelected;

        let ids: string[] = [];

        data.map((item) => {
          return ids.push(item.id);
        });

        const response = await changeAccountStatus({
          ids: ids,
          action: 'unblock',
        });
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
    if (actionSelected === 'Send Email') {
      if (userSelected && idsSelected) {
        let emails: string[] = [];

        [...new Set([...idsSelected, ...userSelected])].map((item) => {
          return emails.push(item.emailAddress);
        });
        const response = await sendEmail({
          emails,
          templateId: blockReason,
        });
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleSendEmailModal();
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
    if (actionSelected === 'Set as Committee Member') {
      if (userSelected && idsSelected) {
        let ids: string[] = [];

        [...new Set([...idsSelected, ...userSelected])].map((item) => {
          return ids.push(item.id);
        });
        const response = await setCommitteeMember({
          ids: ids,
        });
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
    if (actionSelected === 'Remove Committee Member') {
      if (userSelected && idsSelected) {
        let ids: string[] = [];

        [...new Set([...idsSelected, ...userSelected])].map((item) => {
          return ids.push(item.id);
        });
        const response = await removeCommitteeMember({
          ids: ids,
        });
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
    if (actionSelected === 'Delete User') {
      if (userSelected && idsSelected) {
        const data = userSelected.length > 0 ? userSelected : idsSelected;

        let ids: string[] = [];

        data.map((item) => {
          return ids.push(item.id);
        });
        const response = await deleteUsers({
          ids: ids,
        });
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
    if (actionSelected === 'Approve Membership') {
      if (userSelected && idsSelected) {
        const data = userSelected.length > 0 ? userSelected : idsSelected;

        let ids: string[] = [];

        data.map((item) => {
          return ids.push(item.id);
        });

        const response = await approveMembership({
          ids: ids,
        });

        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
  };

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const onClickChangePage = () => {
    setCurrentPage(Number(searchPage));
  };

  const exportCSV = async () => {
    setDownloading(true);
    setLoadingAction(true);
    let response;
    if (eligibility) {
      response = await exportEligibleUsersCSV({ search, currentPage, limit });
    } else {
      response = await exportUsersCSV({ search, currentPage, limit });
    }

    if (response) {
      setDownloading(false);
      setLoadingAction(false);
    }
  };

  const columns = React.useMemo(() => {
    const hasRole =
      (userProfile && userProfile?.roleName === 'membership-admin') ||
      (userProfile && userProfile?.roleName === 'secretariat-admin');

    const columns: INSAAColumn[] = [
      {
        key: 'NSAAID',
        dataIndex: 'NSAAID',
        title: 'NSAA ID',
        width: 62,
      },
      {
        key: 'Name',
        dataIndex: 'Name',
        title: 'Name',
        width: 120,
        render: (value, record) => {
          return (
            <Link onClick={_onClickUserName.bind('', record.id)} underline="none">
              <LabelStyled>
                {((record.id.surname || '') + ', ' + (record.id.givenName || '')).length > 10
                  ? ((record.id.surname || '') + ', ' + (record.id.givenName || '')).slice(0, 10) + '...'
                  : (record.id.surname || '') + ', ' + (record.id.givenName || '')}
                {record.id.verificationStatus === 'verified' && <IconVerifyStyled src={IconSuccess} />}
              </LabelStyled>
            </Link>
          );
        },
      },
      {
        key: 'EmailAddress',
        dataIndex: 'EmailAddress',
        title: 'Email Address',
        width: 130,
        ellipsis: true,
      },
      {
        key: 'PhoneNumber',
        dataIndex: 'PhoneNumber',
        title: 'Phone Number',
        width: 115,
      },
      {
        key: 'AccountType',
        dataIndex: 'AccountType',
        title: 'Account Type',
        width: 100,
      },
      {
        key: 'IsCommitteeMember',
        dataIndex: 'IsCommitteeMember',
        title: 'Is committee member?',
        width: 110,
      },
      {
        key: 'Role',
        dataIndex: 'Role',
        title: 'Role',
        width: 71,
      },
      {
        key: 'AccountStatus',
        dataIndex: 'AccountStatus',
        title: 'Account Status',
        width: 85,
        render: (value, record) => {
          let variants: 'active' | 'blocked' | undefined =
            value === 'active' ? 'active' : value === 'blocked' ? 'blocked' : undefined;
          return <ButtonStatus variants={variants}>{value}</ButtonStatus>;
        },
      },
      {
        key: 'Actions',
        title: '',
        dataIndex: ' Actions',
        width: 130,
        render: (value, record) => {
          const userId = record?.id?.id;
          return (
            <Actions>
              {record.AccountStatus === 'active' ? (
                <Tooltip title={'Block'}>
                  <IconButtonStyled
                    onClick={() => {
                      setOpenBlockAccountModal(true);
                      setUserSelected([record.id]);
                      setActionSelected('Block Account');
                    }}
                    disabled={!hasRole}
                  >
                    <ImageIconStyled src={LockIcon} />
                  </IconButtonStyled>
                </Tooltip>
              ) : (
                <Tooltip title={'Unblock'}>
                  <IconButtonStyled
                    onClick={() => {
                      setUserSelected([record.id]);
                      setActionSelected('Unblock Account');
                      setOpenConfirmActionModal(true);
                    }}
                    disabled={!hasRole}
                  >
                    <ImageIconStyled src={UnLockIcon} />
                  </IconButtonStyled>
                </Tooltip>
              )}
              <Tooltip title="View details">
                <IconButtonStyled onClick={handleViewDetailUser(userId)}>
                  <ImageIconStyled src={ViewIcon} />
                </IconButtonStyled>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButtonStyled
                  onClick={() => {
                    setUserSelected([record.id]);
                    setActionSelected('Delete User');
                    setOpenConfirmActionModal(true);
                  }}
                  disabled={!hasRole}
                >
                  <ImageIconStyled src={DeleteIcon} />
                </IconButtonStyled>
              </Tooltip>
            </Actions>
          );
        },
      },
    ];
    return columns;
  }, [userProfile]);

  const dataSource = React.useMemo(() => {
    const dataSource = users?.map((item, idx) => ({
      key: idx,
      id: item,
      NSAAID: item?.username,
      Name: item?.surname,
      EmailAddress: item.emailAddress?.length > 13 ? item.emailAddress.slice(0, 13) + '...' : item.emailAddress,
      PhoneNumber:
        item?.areaCode +
        ' ' +
        (item?.mobilePhone?.length > 4 ? item.mobilePhone.slice(0, 4) + '...' : item.mobilePhone || ''),
      AccountType:
        item?.accountType === 'lifetime-membership'
          ? LIFE_MEMBER_TEXT
          : convertSlugToUpperCase(item?.accountType ?? ''),
      IsCommitteeMember: convertSlugToUpperCase(item?.committeeMember ?? ''),
      Role:
        item?.roleName === 'event-ic'
          ? 'Event IC'
          : item?.roleName === 'it-admin'
          ? 'IT Admin'
          : convertSlugToUpperCase(item?.roleName ?? ''),
      AccountStatus: item.accountStatus,
    }));
    return dataSource as unknown as INSAADataSource[];
  }, [users]);

  const columnsEligibility = React.useMemo(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'NSAAID',
        dataIndex: 'NSAAID',
        title: 'NSAA ID',
        width: 62,
      },
      {
        key: 'Name',
        dataIndex: 'Name',
        title: 'Name',
        width: 130,
        render: (value, record) => {
          return (
            <Link onClick={_onClickUserName.bind('', record.id)} underline="none">
              <LabelStyled>
                {((record.id.surname || '') + ', ' + (record.id.givenName || '')).length > 20
                  ? ((record.id.surname || '') + ', ' + (record.id.givenName || '')).slice(0, 20) + '...'
                  : (record.id.surname || '') + ', ' + (record.id.givenName || '')}
                {record.id.verificationStatus === 'verified' && <IconVerifyStyled src={IconSuccess} />}
              </LabelStyled>
            </Link>
          );
        },
      },
      {
        key: 'EmailAddress',
        dataIndex: 'EmailAddress',
        title: 'Email Address',
        width: 150,
        ellipsis: true,
      },
      {
        key: 'PhoneNumber',
        dataIndex: 'PhoneNumber',
        title: 'Phone Number',
        width: 115,
      },
      {
        key: 'AccountType',
        dataIndex: 'AccountType',
        title: 'Account Type',
        width: 110,
      },
      {
        key: 'EligibleStatus',
        dataIndex: 'EligibleStatus',
        title: 'Eligible Status',
        width: 110,
        render: (value, record) => {
          let variants: 'not-eligible' | 'eligible' | 'pending-member' | undefined =
            value === 'not-eligible'
              ? 'not-eligible'
              : value === 'eligible'
              ? 'eligible'
              : value === 'pending-member'
              ? 'pending-member'
              : undefined;
          return <ButtonStatus variants={variants}> {value ? convertSlugToUpperCase(value) : ''}</ButtonStatus>;
        },
      },
      {
        key: 'Actions',
        title: '',
        dataIndex: ' Actions',
        width: 150,
        render: (value, record) => {
          const userId = record?.id?.id;
          return (
            <Actions>
              <Tooltip title="Approve Membership">
                <IconButtonStyled
                  onClick={() => {
                    setUserSelected([record.id]);
                    setActionSelected('Approve Membership');
                    setOpenConfirmActionModal(true);
                  }}
                >
                  <ImageIconStyled src={VerifyIcon} />
                </IconButtonStyled>
              </Tooltip>
              <Tooltip title="View details">
                <IconButtonStyled onClick={handleViewDetailUser(userId)}>
                  <ImageIconStyled src={ViewIcon} />
                </IconButtonStyled>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButtonStyled
                  onClick={() => {
                    setUserSelected([record.id]);
                    setActionSelected('Delete User');
                    setOpenConfirmActionModal(true);
                  }}
                >
                  <ImageIconStyled src={DeleteIcon} />
                </IconButtonStyled>
              </Tooltip>
            </Actions>
          );
        },
      },
    ];
    return columns;
  }, []);

  const dataSourceEligibility = React.useMemo(() => {
    const dataSource = users.map((item, idx) => ({
      key: idx,
      id: item,
      NSAAID: item?.username,
      Name: item?.surname,
      EmailAddress: item?.emailAddress?.length > 20 ? item?.emailAddress.slice(0, 20) + '...' : item.emailAddress,
      PhoneNumber:
        item?.areaCode +
        (item?.mobilePhone?.length > 6 ? item.mobilePhone.slice(0, 6) + '...' : item.mobilePhone || ''),
      AccountType: convertSlugToUpperCase(item?.accountType ?? ''),
      EligibleStatus: item.eligibleStatus,
    }));
    return dataSource as unknown as INSAADataSource[];
  }, [users]);

  useEffect(() => {
    const userIsNotLifeTimeMember = idsSelected?.filter((item) => item.accountType !== 'lifetime-membership');

    if (userIsNotLifeTimeMember && userIsNotLifeTimeMember.length > 0) {
      setDisabledSetCommittee(true);
    } else {
      setDisabledSetCommittee(false);
    }
  }, [idsSelected]);

  useEffect(() => {
    const userIsNotCommitterMember = idsSelected?.filter((item) => item.committeeMember !== 'yes');

    if (userIsNotCommitterMember && userIsNotCommitterMember.length > 0) {
      setDisabledAssignRole(true);
    } else {
      setDisabledAssignRole(false);
    }
  }, [idsSelected]);

  useEffect(() => {
    const userIsNotAdmin = idsSelected?.filter((item) => item.roleName === null);

    if (userIsNotAdmin && userIsNotAdmin.length > 0) {
      setDisabledRemoveRole(true);
    } else {
      setDisabledRemoveRole(false);
    }
  }, [idsSelected]);

  useEffect(() => {
    const userIsAdmin = idsSelected?.filter((item) => item.roleName !== null);
    const userIsBlocked = idsSelected?.filter((item) => item.accountStatus === 'blocked');

    if ((userIsAdmin && userIsAdmin.length > 0) || (userIsBlocked && userIsBlocked.length > 0)) {
      setDisabledBlockAccount(true);
    } else {
      setDisabledBlockAccount(false);
    }
  }, [idsSelected]);

  useEffect(() => {
    const userIsActive = idsSelected?.filter((item) => item.accountStatus === 'active');

    if (userIsActive && userIsActive.length > 0) {
      setDisabledUnBlockAccount(true);
    } else {
      setDisabledUnBlockAccount(false);
    }
  }, [idsSelected]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="left">
        <Title>
          {shallowEqual(search, eligibility ? defaultSearchEligibleUser : defaultSearchUser) ? `Listing` : `Results`}
        </Title>
        <Box>
          <CustomButtonStyled outlineButton onClick={exportCSV} disabled={downloading || loadingAction || loading}>
            {downloading ? <Loading /> : `Export CSV`}
          </CustomButtonStyled>
          {!eligibility && (
            <CustomButtonStyled
              disabled={
                userProfile &&
                userProfile.roleName !== 'membership-admin' &&
                userProfile.roleName !== 'secretariat-admin'
              }
              onClick={handleModal}
            >
              <IconStyled src={addIcon} />
              Create
            </CustomButtonStyled>
          )}
          <CustomButtonStyled disabled={idsSelected && idsSelected.length === 0} outlineButton onClick={handleClick}>
            Action
            <KeyboardArrowDownIconStyled />
          </CustomButtonStyled>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {actions?.map((action) => {
              return (
                <Box>
                  {action === 'Assign Role' ? (
                    <Accordion disabled={disabledAssignRole}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        {action}
                      </AccordionSummary>
                      <Box>
                        {assignRoleLists.map((role) => {
                          return (
                            <MenuItemStyled
                              style={{ paddingLeft: 32 }}
                              onClick={_onSelectAction(role.key)}
                              key={role.key}
                              value={role.key}
                            >
                              {role.value}
                            </MenuItemStyled>
                          );
                        })}
                      </Box>
                    </Accordion>
                  ) : (
                    <MenuItemStyled
                      disabled={
                        (action === 'Set as Committee Member' && disabledSetCommittee) ||
                        (action === 'Remove Role' && disabledRemoveRole) ||
                        (action === 'Block Account' && disabledBlockAccount) ||
                        (action === 'Unblock Account' && disabledUnBlockAccount)
                      }
                      onClick={_onSelectAction(action)}
                      key={action}
                      value={action}
                    >
                      {action}
                    </MenuItemStyled>
                  )}
                </Box>
              );
            })}
          </Popover>
        </Box>
      </Box>
      <Box mt={2} mb={2}>
        {eligibility ? (
          <NSAATable
            loading={loading}
            columns={columnsEligibility}
            dataSource={dataSourceEligibility}
            isShowCheckBoxes
            onSelectedItems={onSelectedItems}
          />
        ) : (
          <NSAATable
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            isShowCheckBoxes
            onSelectedItems={onSelectedItems}
          />
        )}
      </Box>
      <CustomPagination
        itemName={'users'}
        data={data}
        limit={limit}
        handleChangePagination={handleChangePagination}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onClickChangePage={onClickChangePage}
        disableSearchPage={disableSearchPage || searchPage === ''}
        searchPage={searchPage}
        onBlurField={onBlurField}
      />
      {openDrawer.userInformation && (
        <UserInformation userClicked={userClicked} open={openDrawer.userInformation} toggleDrawer={toggleDrawer} />
      )}
      {openCreateUser && (
        <CreateUser
          open={openCreateUser}
          _onCloseModal={handleModal}
          getData={getData}
          setActionSuccess={setActionSuccess}
          setActionFailed={setActionFailed}
          setMessageResponse={setMessageResponse}
          setShowDetailUser={setShowDetailUser}
          setUserDetailId={setUserDetailId}
        />
      )}
      {openConfirmActionModal && (
        <ConfirmModal
          open={openConfirmActionModal}
          handleClose={_onCloseModalConfirmAction}
          onSubmit={_onSelectedAction}
          actionSelected={actionSelected}
          disabled={loadingAction}
        />
      )}
      {openBlockAccountModal && (
        <BlockAccountModal
          loading={loading}
          open={openBlockAccountModal}
          _onCloseModal={handleBlockAccountModal}
          _onSubmit={_onSubmitBlockAccount}
          userSelected={userSelected.length > 0 ? userSelected : idsSelected}
          blockReason={blockReason}
          setBlockReason={setBlockReason}
        />
      )}
      {openSendEmailModal && (
        <SendEmailModal
          loading={loading}
          open={openSendEmailModal}
          _onCloseModal={handleSendEmailModal}
          _onSubmit={_onSubmitBlockAccount}
          userSelected={idsSelected}
          template={blockReason}
          setTemplate={setBlockReason}
        />
      )}

      {isShowUserDetail && (
        <EligibleUserInfo
          userId={userDetailId!}
          open={true}
          _onCloseModal={handleCloseUserDetail}
          setUserSelected={setUserSelected}
          setActionSelected={setActionSelected}
          setOpenConfirmActionModal={setOpenConfirmActionModal}
        />
      )}

      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </Box>
  );
};

export default Listing;
