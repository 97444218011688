import React, { useEffect, useMemo, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import {
  ELEMENT_IMAGE,
  ELEMENT_PARAGRAPH,
  createPlateComponents,
  PlatePlugin,
  Plate,
  createAlignPlugin,
  createBoldPlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createHistoryPlugin,
  createKbdPlugin,
  createImagePlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createReactPlugin,
  createSelectOnBackspacePlugin,
  createDndPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  SPEditor,
  MARK_COLOR,
  withStyledProps,
  StyledLeaf,
  MARK_BG_COLOR,
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
  StyledElement,
  withProps,
} from '@udecode/plate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import { editablePreviewProps, optionsExitBreakPlugin } from 'src/components/slateEditor/config/pluginOptions';
import { CALL_SUCCESS } from 'src/constants/common';
import { getPost, IPost } from 'src/services/news';

const PreviewId = 'preview';

type TEditor = SPEditor & ReactEditor & HistoryEditor;

export let components = createPlateComponents({
  [MARK_COLOR]: withStyledProps(StyledLeaf, {
    leafProps: {
      [MARK_COLOR]: ['color'],
    },
  }),
  [MARK_BG_COLOR]: withStyledProps(StyledLeaf, {
    leafProps: {
      [MARK_BG_COLOR]: ['backgroundColor'],
    },
  }),
  [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
    styles: {
      root: {
        fontSize: '1rem',
      },
    },
  }),
});

export const Preview = () => {
  const [data, setData] = useState<IPost | null>(null);

  async function getData(id: string | number) {
    const response = await getPost(id);

    if (response?.statusCode === CALL_SUCCESS) {
      setData(response.data);
    }
  }

  useEffect(() => {
    const lastSegmentOfUrl = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);

    if (lastSegmentOfUrl) {
      getData(lastSegmentOfUrl);
    }
  }, [window.location]);

  const pluginsMemo: PlatePlugin<TEditor>[] = useMemo(() => {
    const plugins = [
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createMediaEmbedPlugin(),
      createAlignPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createDndPlugin(),
      createExitBreakPlugin(optionsExitBreakPlugin),
      createSelectOnBackspacePlugin({
        allow: [ELEMENT_IMAGE],
      }),
    ];

    return plugins;
  }, []);

  return (
    <Box display="flex" p={8}>
      {data && data?.description && (
        <Box>
          <Grid container justifyContent="center">
            <Grid item xs={8}>
              <Plate
                id={PreviewId}
                plugins={pluginsMemo}
                components={components}
                editableProps={editablePreviewProps}
                value={data?.content}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
