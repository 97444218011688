import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';

import { HeaderMobile } from 'src/components/headerMobile';
import { CALL_SUCCESS, FORBIDDEN } from 'src/constants/common';
import useRoleAccess from 'src/hooks/use-role-access';
import { Header as HeaderAdmin } from 'src/layouts/admin/components/header';
import { getEventById, IEvent } from 'src/services/events';
import { selectUserProfile } from 'src/store/authentication/selector';
import useResponsive from 'src/utils/responsive';

import General from './components/general';
import Guest from './components/guest';
import Quiz from './components/quiz';
import Ticket from './components/ticket';
import Volunteer from './components/volunteer';
import Header from './shared/layout/Header';
import {
  LayoutChildren,
  LayoutContainer,
  LayoutContent,
  LayoutHeader,
  LayoutNavigation,
} from './shared/layout/index.styled';
import Navigation from './shared/layout/Navigation';

const EventManagement = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const EVENT_IC = 'event-ic';
  const MEMBER_ADMIN = 'membership-admin';
  useRoleAccess([EVENT_IC, MEMBER_ADMIN]);
  const { id: eventId } = useParams<{ id: string }>();
  const [event, setEvent] = useState<IEvent>();
  const [isDirtyForm, setDirtyForm] = useState(false);
  const responsive = useResponsive();
  const userProfile = useSelector(selectUserProfile);

  const fetchData = async () => {
    const response = await getEventById(eventId!);
    if (response?.statusCode === CALL_SUCCESS) {
      setEvent(response?.data!);
    }
    if (response?.statusCode === FORBIDDEN) {
      history.push('/not-access');
    }
  };

  useEffect(() => {
    fetchData();
  }, [location]);

  if (!event) return <div />;

  return (
    <Box width="100%" overflow="auto">
      {responsive.isTabletOrMobile && (
        <HeaderMobile
          title={event.title.length > 20 ? event.title.slice(0, 20) + '...' : event.title}
          userProfile={userProfile}
        />
      )}
      {responsive.isDesktopOrLaptop && <HeaderAdmin />}
      <Box pt={responsive.isTabletOrMobile ? 15 : 3.75} />
      <LayoutContainer responsive={responsive}>
        <LayoutHeader responsive={responsive}>
          <Header event={event!} setEvent={setEvent} isDirtyForm={isDirtyForm} setDirty={setDirtyForm} />
        </LayoutHeader>
        <LayoutContent responsive={responsive}>
          <LayoutNavigation>
            <Navigation event={event} />
          </LayoutNavigation>
          <LayoutChildren>
            <Switch>
              <Route
                exact
                path={`${path}/general`}
                render={() => <General setEvent={setEvent} event={event!} setDirty={setDirtyForm} />}
              />
              <Route exact path={`${path}/quiz`} render={() => <Quiz event={event!} setDirty={setDirtyForm} />} />
              <Route
                exact
                path={`${path}/ticket`}
                render={() => <Ticket event={event!} setEvent={setEvent} setDirty={setDirtyForm} />}
              />
              <Route exact path={`${path}/volunteer`} render={() => <Volunteer event={event!} setEvent={setEvent} />} />
              <Route exact path={`${path}/guest`} render={() => <Guest event={event!} setDirty={setDirtyForm} />} />
            </Switch>
          </LayoutChildren>
        </LayoutContent>
      </LayoutContainer>
    </Box>
  );
};

export default EventManagement;
