import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Banner from 'src/components/banner';
import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import { HeaderMobile } from 'src/components/headerMobile';
import { HeaderMobileChinese } from 'src/components/headerMobileChinese';
import { CALL_SUCCESS } from 'src/constants/common';
import useFetchMe from 'src/hooks/use-fetch-me';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { getCMSData, ICms } from 'src/services/cms';
import useResponsive from 'src/utils/responsive';

import CommitteeImage from '../../../../medias/images/committee.png';
import { TypographyStyled } from '../nsaa';

const Committee = () => {
  const history = useHistory();
  const isChinesePage = history.location.pathname.slice(0, 3) === '/cn';
  useScrollToTop();
  const responsive = useResponsive();
  const { me } = useFetchMe();
  const { path } = useRouteMatch();
  const [data, setData] = useState<ICms>({
    type: '',
    banner_image: '',
    banner_title: '',
    banner_description: '',
    content_title: '',
    content_body: '',
    background_image: '',
    item_image: '',
    item_date: '',
    item_image_description: '',
  });
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const response = await getCMSData('committee');
    if (response.statusCode === CALL_SUCCESS) {
      setData(response.data as ICms);
    }
  };
  return (
    <Box pt={responsive.isTabletOrMobile ? 15 : 3.75}>
      {responsive.isTabletOrMobile && !isChinesePage ? (
        <HeaderMobile title={`Executive Committee`} userProfile={me} />
      ) : (
        <HeaderMobileChinese title={`校友会执委`} />
      )}
      {!responsive.isTabletOrMobile && <NSAABreadcrumbs path={path} />}
      <Banner
        image={data.banner_image}
        title={data.banner_title}
        description={data.banner_description}
        responsive={responsive}
      />
      {responsive.isTabletOrMobile && (
        <TypographyStyled responsive={responsive}>
          (Nanyang Girls’ High School, Nanyang Primary School, Nanyang Kindergarten)
        </TypographyStyled>
      )}
      <ContainerStyled maxWidth="lg" responsive={responsive}>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Title responsive={responsive}>NSAA Executive Committee 2021/2023 </Title>
          <Image responsive={responsive} src={CommitteeImage} />
        </Box>
      </ContainerStyled>
    </Box>
  );
};

export default Committee;

const ContainerStyled = styled(Box)<{ responsive: any }>`
  padding: 50px;
  ${({ theme, responsive }) => css`
    ${responsive?.isMobile &&
    css`
      padding: 20px;
    `}
  `}
`;

const Title = styled(Typography)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    font-size: 34px;
    font-weight: ${theme.fonts.header1.fontWeight};
    line-height: ${theme.fonts.header1.lineHeight};
    color: ${theme.palette.gray?.[60]};
    margin-bottom: ${theme.spacing(10)}px;
    ${responsive.isMobile &&
    css`
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      text-align: center;
      margin-bottom: 30px;
    `}
  `}
`;
const Image = styled.img<{ responsive: any }>`
  ${({ theme, responsive }) => css`
    ${responsive.isTabletOrMobile &&
    css`
      width: 100%;
    `}
  `}
`;
