import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import Banner from 'src/components/banner';
import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import { HeaderMobile } from 'src/components/headerMobile';
import { HeaderMobileChinese } from 'src/components/headerMobileChinese';
import { CALL_SUCCESS } from 'src/constants/common';
import { FORMAT_DATE_YMD } from 'src/constants/common';
import useFetchMe from 'src/hooks/use-fetch-me';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { getCMSData, ICms } from 'src/services/cms';
import { getPublicEventNonLoggedIn, IEvent } from 'src/services/events';
import { IMeta } from 'src/types';
import useResponsive from 'src/utils/responsive';

import { BannerMainTitle } from '../about-us/components/nsaa';

import EventList from './EventList';
import Search from './Search';

function Events() {
  useScrollToTop();
  const history = useHistory();
  const isChinesePage = history.location.pathname.slice(0, 3) === '/cn';
  const responsive = useResponsive();
  const { me } = useFetchMe();
  const [loading, setLoading] = useState(false);

  const FIRST_PAGE = 1;
  const { path } = useRouteMatch();
  const [data, setData] = useState<ICms>({
    type: '',
    banner_image: '',
    banner_title: '',
    banner_description: '',
    content_title: '',
    content_body: '',
    background_image: '',
    item_image: '',
    item_date: '',
    item_image_description: '',
  });
  useEffect(() => {
    getData();
  }, []);
  const [search, setSearch] = useState<any>();
  const getData = async () => {
    const response = await getCMSData('event-activities');
    if (response.statusCode === CALL_SUCCESS) {
      setData(response.data as ICms);
    }
  };
  const [events, setEvents] = useState<IEvent[]>([]);
  const [page, setPage] = useState(FIRST_PAGE);
  const limit = 6;
  const [meta, setMeta] = useState<IMeta>();
  const NO = 'no';
  const YES = 'yes';
  const EMPTY = '';
  const ALL = 'all';
  const ENTER_AT_LEAST_3_CHARACTERS = 'Enter at least 3 character';

  useEffect(() => {
    (async () => {
      const response = await getPublicEventNonLoggedIn({ page, limit, ...search });
      if (response.statusCode === CALL_SUCCESS) {
        setEvents((events) => [...events, ...response.data.items]);
        setMeta((meta) => response.data.meta);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (page === 1) return;
      const response = await getPublicEventNonLoggedIn({ page, limit, ...search });
      if (response.statusCode === CALL_SUCCESS) {
        setEvents((events) => [...events, ...response.data.items]);
        setMeta((meta) => response.data.meta);
      }
    })();
  }, [page]);

  const handleViewMoreEvent = () => {
    if (meta?.currentPage && meta?.totalPages && meta?.currentPage < meta?.totalPages) {
      setPage((page) => page + 1);
    }
  };

  return (
    <Box pt={responsive.isTabletOrMobile ? 15 : 3.75} mx={responsive.isTabletOrMobile ? 2 : 0}>
      {responsive.isTabletOrMobile && !isChinesePage ? (
        <HeaderMobile title={`Events & Activities`} userProfile={me} />
      ) : (
        <HeaderMobileChinese title={`关于校友会`} />
      )}
      {!responsive.isTabletOrMobile && <NSAABreadcrumbs path={path} />}
      <Banner
        image={data.banner_image}
        title={data.banner_title}
        description={data.banner_description}
        responsive={responsive}
      />
      <BannerMainTitle style={{ paddingTop: !responsive.isMobile ? 80 : 30 }}>Events & Activities</BannerMainTitle>
      <Box
        display="flex"
        mx="auto"
        my={responsive.isDesktopOrLaptop ? 10 : 0}
        maxWidth={1280}
        flexDirection={responsive.isTabletOrMobile ? 'column' : 'row'}
      >
        <Formik
          initialValues={{
            search: EMPTY,
            fromDate: EMPTY,
            toDate: EMPTY,
            category: ALL,
            requireVolunteer: EMPTY,
          }}
          validationSchema={Yup.object({
            search: Yup.string().min(3, ENTER_AT_LEAST_3_CHARACTERS).notRequired(),
          })}
          onSubmit={(values, { resetForm }) => {
            setPage(1);
            setSearch({
              ...values,
              requireVolunteer:
                values?.requireVolunteer === NO
                  ? false
                  : values?.requireVolunteer === YES
                  ? true
                  : values?.requireVolunteer === ALL
                  ? null
                  : null,
              fromDate: values.fromDate ? moment(values.fromDate).format(FORMAT_DATE_YMD) : null,
              toDate: values.toDate ? moment(values.toDate).format(FORMAT_DATE_YMD) : null,
            });
            (async () => {
              setLoading(true);
              const response = await getPublicEventNonLoggedIn({
                page: FIRST_PAGE,
                limit,
                ...values,
                search: !!values.search ? values.search.trim() : null,
                requireVolunteer:
                  values?.requireVolunteer === NO
                    ? false
                    : values?.requireVolunteer === YES
                    ? true
                    : values?.requireVolunteer === ALL
                    ? null
                    : null,
                fromDate: values.fromDate ? moment(values.fromDate).format(FORMAT_DATE_YMD) : null,
                toDate: values.toDate ? moment(values.toDate).format(FORMAT_DATE_YMD) : null,
              } as any);
              if (response.statusCode === CALL_SUCCESS) {
                setLoading(false);
                window.scrollTo(0, 0);
                setEvents(response.data.items);
                setMeta((meta) => response.data.meta);
              } else {
                setLoading(false);
              }
            })();
          }}
        >
          {() => {
            return (
              <Form>
                <Search responsive={responsive} isLoading={loading} />
              </Form>
            );
          }}
        </Formik>
        <EventList onViewMoreEvent={handleViewMoreEvent} events={events} meta={meta!} />
      </Box>
    </Box>
  );
}

export default Events;
