import React from 'react';

import Membership from '../membership/shared/membership.component';

import { privacyPolicyData } from './data';

const PrivacyPolicy = () => {
  return <Membership titleMobile="Privacy Policy" data={privacyPolicyData} />;
};

export default PrivacyPolicy;
