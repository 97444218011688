import React from 'react';

import { StepperProps, Stepper, withStyles } from '@material-ui/core';

export const StepperCustom = withStyles((theme) => ({
  root: {
    backgroundColor: 'transparent',
  },
  checked: {},
}))((props: StepperProps) => <Stepper {...props} />);
