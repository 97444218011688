import React, { useEffect, useState } from 'react';

import { Box, Step, StepLabel, Grid, makeStyles, Link } from '@material-ui/core';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { CustomButton, StepperCustom } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import BackgroundMobile from 'src/medias/images/background-school-mobile.svg';
import backgroundSignup from 'src/medias/images/background-signup.png';
import LogoJSA from 'src/medias/logo/logo-JSA.svg';
import { Title, TitleResponse } from 'src/pages/login';
import { ISignUpReq, signUp } from 'src/services/auth';
import { selectLoginState } from 'src/store/authentication/selector';
import { selectAuth } from 'src/store/authentication/slices';
import { intRegex, _checkErrors } from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { getAreaCode } from '../../../utils/areaCode';

import BasicInformation from './BasicInformation';
import GetToKnowYou from './GetToKnowYou';
import PrivacyPolicy from './PrivacyPolicy';
import { LogoMobile, nationalities } from './types';
import VerifyEmail from './VerifyEmail';

const steps = ['Basic Information', 'Additional Information', 'Finish'];

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${backgroundSignup})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  containerResponse: {
    backgroundColor: '#F9F9F4',
    padding: 16,
  },
  bgMobile: {
    backgroundImage: `url(${BackgroundMobile})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 175,
  },
}));

const SignUpForm = () => {
  const { isTabletOrMobile } = useResponsive();
  const classes = useStyles();
  const { isChildError } = useSelector(selectAuth);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [signUpStatus, setSignUpStatus] = useState<{ statusCode: undefined | number; message: string }>({
    statusCode: undefined,
    message: '',
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const currentYear = new Date().getFullYear();
  const [checkChild, setCheckChild] = useState(false);
  const [isHaveChildren, setIsHaveChildren] = useState<string>('yes');
  const isLogin = useSelector(selectLoginState);

  const [data, setData] = useState<ISignUpReq>({
    surname: '',
    givenName: '',
    nationality: nationalities[0].key,
    dateOfBirth: null,
    gender: '',
    jobTitle: '',
    companyName: '',
    mailingAddress: '',
    postalCode: '',
    areaCode: 'SG',
    mobilePhone: '',
    emailAddress: '',
    periodStudyInNYK: null,
    periodStudyInNPP: null,
    periodStudyInNYP: null,
    periodStudyInNYGH: null,
    skills: '',
    otherSkills: '',
    password: '',
    verifyPassword: '',
    chineseName: '',
    child: [
      {
        surname: '',
        givenName: '',
        dateOfBirth: null,
        nationality: nationalities[0].key,
      },
    ],
    graduatingClass: '',
    parentName: '',
    parentSurname: '',
    parentGivenName: '',
    relationship: 'parent',
    parentEmail: '',
    parentAreaCode: 'SG',
    parentMobilePhone: '',
    role: '',
    isConfirmPolicy: false,
  });
  const [errors, setErrors] = useState({
    surname: false,
    givenName: false,
    dateOfBirth: false,
    gender: false,
    emailAddress: false,
    mobilePhone: false,
    mailingAddress: false,
    postalCode: false,
    password: false,
    verifyPassword: false,
    graduateSchool: false,
  });

  const disabledStepBasicInformation =
    data.surname !== '' &&
    data.givenName !== '' &&
    data?.dateOfBirth?.toString() !== null &&
    data.gender !== '' &&
    data.emailAddress !== '' &&
    data.areaCode !== '' &&
    data.mobilePhone !== '' &&
    data.mailingAddress !== '' &&
    data.postalCode !== '' &&
    data.password !== '' &&
    data.verifyPassword !== '' &&
    (data.periodStudyInNYK !== null ||
      data.periodStudyInNPP !== null ||
      data.periodStudyInNYGH !== null ||
      data.periodStudyInNYP !== null) &&
    data.password === data.verifyPassword &&
    !_checkErrors(errors);

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  }

  function _onClickBack() {
    if (activeStep === 0) {
      window.location.href = '/signup';
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  }

  const _onHandleClickHaveChild = (event: { target: { value: string; name: string } }) => {
    if (event.target.value === 'no') {
      setData({ ...data, child: [] });
    }

    setIsHaveChildren(event.target.value);
  };
  const date = data.dateOfBirth ?? '';
  const validateGraduate =
    data.periodStudyInNYK !== null ||
    data.periodStudyInNPP !== null ||
    data.periodStudyInNYGH !== null ||
    data.periodStudyInNYP !== null ||
    (data.periodStudyInNYK !== null && !intRegex.test(data.periodStudyInNYK)) ||
    (data.periodStudyInNPP !== null && !intRegex.test(data.periodStudyInNPP)) ||
    (data.periodStudyInNYGH !== null && !intRegex.test(data.periodStudyInNYGH)) ||
    (data.periodStudyInNYP !== null && !intRegex.test(data.periodStudyInNYP)) ||
    (data.periodStudyInNYK !== null && Number(data.periodStudyInNYK) <= new Date(date.toString()).getFullYear()) ||
    (data.periodStudyInNPP !== null && Number(data.periodStudyInNPP) <= new Date(date.toString()).getFullYear()) ||
    (data.periodStudyInNYGH !== null && Number(data.periodStudyInNYGH) <= new Date(date.toString()).getFullYear()) ||
    (data.periodStudyInNYP !== null && Number(data.periodStudyInNYP) <= new Date(date.toString()).getFullYear()) ||
    (data.periodStudyInNYK !== null && Number(data.periodStudyInNYK) >= currentYear) ||
    (data.periodStudyInNPP !== null && Number(data.periodStudyInNPP) >= currentYear) ||
    (data.periodStudyInNYGH !== null && Number(data.periodStudyInNYGH) >= currentYear) ||
    (data.periodStudyInNYP !== null && Number(data.periodStudyInNYP) >= currentYear);

  const _onClickNext = () => {
    if (activeStep === 0) {
      if (!disabledStepBasicInformation) {
        let validErrors = errors;

        if (!validateGraduate) {
          validErrors.graduateSchool = true;
          setErrors({ ...validErrors, graduateSchool: true });
        }
        if (_.isEmpty(data.verifyPassword)) {
          validErrors.verifyPassword = true;
          setErrors({ ...validErrors, verifyPassword: true });
        }
        if (_.isEmpty(data.password)) {
          validErrors.password = true;
          setErrors({ ...validErrors, password: true });
        }
        if (_.isEmpty(data.postalCode)) {
          validErrors.postalCode = true;
          setErrors({ ...errors, postalCode: true });
        }
        if (_.isEmpty(data.mailingAddress)) {
          validErrors.mailingAddress = true;
          setErrors({ ...errors, mailingAddress: true });
        }
        if (_.isEmpty(data.mobilePhone)) {
          validErrors.mobilePhone = true;
          setErrors({ ...errors, mobilePhone: true });
        }
        if (_.isEmpty(data.emailAddress)) {
          validErrors.emailAddress = true;
          setErrors({ ...errors, emailAddress: true });
        }
        if (_.isEmpty(data.gender)) {
          validErrors.gender = true;
          setErrors({ ...validErrors, gender: true });
        }
        if (_.isEmpty(data?.dateOfBirth?.toString())) {
          validErrors.dateOfBirth = true;
          setErrors({ ...validErrors, dateOfBirth: true });
        }
        if (_.isEmpty(data.givenName)) {
          validErrors.givenName = true;
          setErrors({ ...validErrors, givenName: true });
        }
        if (_.isEmpty(data.surname)) {
          validErrors.surname = true;
          setErrors({ ...validErrors, surname: true });
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
    }
    if (activeStep === 1) {
      if (data?.child?.length !== 0) {
        setCheckChild(true);
        if (!isChildError) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      } else {
        if (isHaveChildren === 'yes') {
          setCheckChild(true);
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
      }
    }
  };

  useEffect(() => {
    if (!isChildError) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [isChildError]);

  useEffect(() => {
    if (validateGraduate) {
      setErrors({ ...errors, graduateSchool: false });
    }
  }, [validateGraduate]);

  async function _onClickSubmit() {
    if (disabledStepBasicInformation && data.isConfirmPolicy) {
      setLoading(true);
      let skills = {
        editorial: false,
        translation: false,
        'legal-advice': false,
        'building-architecture': false,
        'artistic-design': false,
        'other-skills': data.otherSkills,
      };

      const listSkill = data.skills?.split(',');

      // eslint-disable-next-line array-callback-return
      listSkill?.map((skill) => {
        if (skill === 'Editorial') {
          skills['editorial'] = true;
        }
        if (skill === 'Translation') {
          skills['translation'] = true;
        }
        if (skill === 'Legal Advice') {
          skills['legal-advice'] = true;
        }
        if (skill === 'Building/architecture/estate Management') {
          skills['building-architecture'] = true;
        }
        if (skill === 'Artistic Design') {
          skills['artistic-design'] = true;
        }
      });

      const body = {
        surname: data.surname,
        givenName: data.givenName,
        chineseName: data.chineseName,
        dateOfBirth: data.dateOfBirth,
        gender: data.gender,
        emailAddress: data.emailAddress,
        areaCode: getAreaCode(data.areaCode),
        mobilePhone: data.mobilePhone,
        mailingAddress: data.mailingAddress,
        postalCode: data.postalCode,
        password: data.password,
        periodStudyInNYK: data.periodStudyInNYK !== null ? data.periodStudyInNYK.toString() : null,
        periodStudyInNPP: data.periodStudyInNPP !== null ? data.periodStudyInNPP.toString() : null,
        periodStudyInNYP: data.periodStudyInNYP !== null ? data.periodStudyInNYP.toString() : null,
        periodStudyInNYGH: data.periodStudyInNYGH !== null ? data.periodStudyInNYGH.toString() : null,
        nationality: data.nationality,
        jobTitle: data.jobTitle,
        companyName: data.companyName,
        skills: [skills],
        child: data.child,
      };
      const response = await signUp(body);
      setOpenSnackBar(true);

      if (response.statusCode === CALL_SUCCESS) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSignUpStatus(response);
        setLoading(false);
      }
      if (response.error) {
        setSignUpStatus({ message: response.message, statusCode: response.statusCode });
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (isLogin) {
      window.location.href = '/';
    }
  }, [isLogin]);

  return (
    <>
      {isTabletOrMobile && (
        <Box display="flex" width={'100%'} className={classes.bgMobile} flex={1}>
          <Link href="/">
            <LogoMobile src={LogoJSA} />
          </Link>
        </Box>
      )}
      <Grid
        className={isTabletOrMobile ? classes.containerResponse : classes.container}
        container
        justifyContent="center"
      >
        <Grid item xs={isTabletOrMobile ? 12 : 6}>
          {activeStep === steps.length ? (
            <Box> {activeStep === 3 && <VerifyEmail />}</Box>
          ) : (
            <Box>
              <Box mt={isTabletOrMobile ? 2 : 8} textAlign="center">
                {isTabletOrMobile ? <TitleResponse>Sign up form</TitleResponse> : <Title>SIGN UP FORM</Title>}
                <Box mt={2} width={'100%'}>
                  <StepperCustom activeStep={activeStep}>
                    {steps.map((label, index) => {
                      return (
                        <Step key={label}>
                          <StepLabel>{isTabletOrMobile ? '' : label}</StepLabel>
                        </Step>
                      );
                    })}
                  </StepperCustom>
                </Box>
              </Box>
              {activeStep === 0 && (
                <BasicInformation
                  currentYear={currentYear}
                  errors={errors}
                  setErrors={setErrors}
                  data={data}
                  setData={setData}
                />
              )}
              {activeStep === 1 && (
                <GetToKnowYou
                  data={data}
                  setData={setData}
                  checkChild={checkChild}
                  setCheckChild={setCheckChild}
                  isHaveChildren={isHaveChildren}
                  _onHandleClickHaveChild={_onHandleClickHaveChild}
                />
              )}
              {activeStep === 2 && <PrivacyPolicy data={data} setData={setData} />}
              <Box mb={8} mt={4} display="flex" justifyContent="center" style={{ gap: 20 }}>
                <CustomButton outlineButton onClick={_onClickBack}>
                  Back
                </CustomButton>
                {activeStep === steps.length - 1 ? (
                  <CustomButton disabled={!data.isConfirmPolicy || loading} onClick={_onClickSubmit}>
                    Submit
                  </CustomButton>
                ) : (
                  <CustomButton onClick={_onClickNext}>Next</CustomButton>
                )}
              </Box>
            </Box>
          )}
          {signUpStatus.statusCode === CALL_SUCCESS && openSnackBar && (
            <SuccessSnackBar message={signUpStatus.message} open={openSnackBar} handleClose={handleCloseSnackBar} />
          )}
          {signUpStatus.statusCode !== undefined && signUpStatus.statusCode !== CALL_SUCCESS && openSnackBar && (
            <ErrorSnackBar message={signUpStatus.message} open={openSnackBar} handleClose={handleCloseSnackBar} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SignUpForm;
