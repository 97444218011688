import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import moment from 'moment';

import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import NSAATable, { INSAAColumn, INSAADataSource } from 'src/components/table';
import { CALL_SUCCESS, FORMAT_DATE_DMY } from 'src/constants/common';
import { getUserEventAttendance, IEventAttendanceUsersToEvent } from 'src/services/users';

interface IEventAttendance {
  userId: string;
}
function EventAttendance({ userId }: IEventAttendance) {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IEventAttendanceUsersToEvent[]>([]);

  const getData = async () => {
    setLoading(true);
    const response = await getUserEventAttendance(userId);
    if (response.statusCode === CALL_SUCCESS && response.data) {
      setData(response.data.usersToEvent);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, [userId]);

  const columns = React.useMemo(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'No.',
        dataIndex: 'No',
        title: 'No.',
        width: 40,
        render: (value) => {
          return <div>{value + 1}</div>;
        },
      },
      {
        key: 'Event',
        dataIndex: 'Event',
        title: 'Event',
        width: 115,
        ellipsis: true,
      },
      {
        key: 'Role',
        dataIndex: 'Role',
        title: 'Role',
        width: 40,
      },
      {
        key: 'EventDate',
        dataIndex: 'EventDate',
        title: 'Event date',
        width: 115,

        render: (value, record) => {
          const startTime = Number(record?.EventData?.event?.start_time);
          const startTimeNewDate = new Date(startTime);
          const endTime = Number(record?.EventData?.event?.end_time);
          const endTimeNewDate = new Date(endTime);
          return (
            <div>
              {moment(startTime).format(FORMAT_DATE_DMY)}{' '}
              {startTimeNewDate.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true })} -{' '}
              {moment(endTime).format(FORMAT_DATE_DMY)}{' '}
              {endTimeNewDate.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true })}
            </div>
          );
        },
      },
      {
        key: 'Session',
        dataIndex: 'Session',
        title: 'Session',
        width: 40,
        render: (value) => {
          return <div>{!value || value >= 1 ? '1.0' : '0.5'}</div>;
        },
      },
    ];
    return columns;
  }, []);
  const dataSource = React.useMemo(() => {
    const dataSource = data.map((item: IEventAttendanceUsersToEvent, idx: number) => ({
      key: idx,
      No: idx,
      Event: item?.event?.title,
      Role: item.type,
      EventData: item,
      Session: item?.event?.duration,
    }));
    return dataSource as unknown as INSAADataSource[];
  }, [data]);
  return (
    <Box mt={2} mb={2}>
      <LoadingCustom loading={loading} />
      <NSAATable columns={columns} dataSource={dataSource} loading={loading} />
    </Box>
  );
}

export default EventAttendance;
