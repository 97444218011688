import React, { useState } from 'react';

import { Box, Grid, makeStyles, Step, StepLabel } from '@material-ui/core';

import { StepperCustom } from 'src/components';
import { HeaderMobile } from 'src/components/headerMobile';
import useFetchMe from 'src/hooks/use-fetch-me';
import backgroundSignup from 'src/medias/images/background-signup.png';
import { Title } from 'src/pages/login';
import useResponsive from 'src/utils/responsive';

import FillForm from '../components/FillFormNSAA';
import Finish from '../components/Finish';
import PaymentMethod from '../components/PaymentMethod';
import { TitleMobile } from '../types';

const steps = ['Fill Form', 'Choose Payment Method', 'Finish'];

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${backgroundSignup})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: 890,
  },
  containerMobile: {
    backgroundColor: '#F9F9F4',
    minHeight: '100vh',
    paddingTop: 98,
  },
}));

export const DonationNSAA = () => {
  const classes = useStyles();
  const { isTabletOrMobile } = useResponsive();
  const { me } = useFetchMe();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [paymentId, setPaymentId] = useState('');
  const [data, setData] = useState({
    amount: '',
  });
  const [errors, setErrors] = useState({
    amount: false,
  });

  return (
    <Box display={'flex'} flex={1} flexDirection={'column'}>
      {isTabletOrMobile && <HeaderMobile title={`Donation`} userProfile={me} />}
      <Grid
        container
        justifyContent="center"
        className={isTabletOrMobile ? classes.containerMobile : classes.container}
      >
        <Grid item xs={isTabletOrMobile ? 12 : 7}>
          <Box display={'flex'} flex={1} flexDirection={'column'} height={'100%'} p={isTabletOrMobile ? 2 : 0}>
            <Box mt={isTabletOrMobile ? 0 : 8} textAlign="center">
              {isTabletOrMobile ? (
                <TitleMobile>DONATE TO NSAA BURSARY FUND</TitleMobile>
              ) : (
                <Title>DONATE TO NSAA BURSARY FUND</Title>
              )}
              <Box mt={2} width={'100%'}>
                <StepperCustom activeStep={activeStep}>
                  {steps.map((label, index) => {
                    return (
                      <Step key={label}>
                        <StepLabel>{isTabletOrMobile ? '' : label}</StepLabel>
                      </Step>
                    );
                  })}
                </StepperCustom>
              </Box>
            </Box>
            {activeStep === 0 && (
              <FillForm
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                data={data}
                setData={setData}
                errors={errors}
                setErrors={setErrors}
                setPaymentId={setPaymentId}
              />
            )}
            {activeStep === 1 && paymentId && (
              <PaymentMethod
                type="nsaa"
                amount={data.amount}
                fullName={`${me?.surname}, ${me?.givenName}`}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                paymentId={paymentId}
              />
            )}
            {activeStep === 2 && <Finish nsaa />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
