import { useEffect, useState } from 'react';

import { getEventById, IEvent } from 'src/services/events';

const useFetchEvent = (id: string, deps?: any[]) => {
  const [event, setEvent] = useState<IEvent | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(
    () => {
      (async () => {
        setLoading(true);
        const response = await getEventById(id);
        if (response.statusCode === 200 && response.data) {
          setEvent(response.data);
        }
        if (response.statusCode !== 200) {
          setEvent(null);
        }
        setLoading(false);
      })();
    },
    deps ? deps : [],
  );

  return { event, loading } as const;
};

export default useFetchEvent;
