import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import moment from 'moment';

import CustomFormDialog from 'src/components/formDialog';
import NSAATable, { INSAAColumn, INSAADataSource } from 'src/components/table';
import { CALL_SUCCESS, FORMAT_TIME_AM_PM } from 'src/constants/common';
import { getHistoryChangeEligibility, IHistoryEligibility } from 'src/services/users';

interface IHistoryChangeModal {
  _onCloseModal: () => void;
  open: boolean;
}

const HistoryChangeModal = ({ _onCloseModal, open }: IHistoryChangeModal) => {
  const [loading, setLoading] = useState(false);
  const [history, setHistory] = useState<IHistoryEligibility[]>([]);

  const handleClose = () => {
    _onCloseModal?.();
  };

  const getData = async () => {
    setLoading(true);
    const response = await getHistoryChangeEligibility();
    if (response && response.statusCode === CALL_SUCCESS) {
      setHistory(response?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [open]);

  const columns = React.useMemo(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'No',
        dataIndex: 'No',
        title: 'No.',
        width: 62,
      },
      {
        key: 'Update',
        dataIndex: 'Update',
        title: 'Update',
      },
      {
        key: 'Update Time',
        dataIndex: 'UpdateTime',
        title: 'Update Time',
      },
    ];
    return columns;
  }, []);

  const dataSource = React.useMemo(() => {
    const dataSource = history.map((item, idx) => ({
      key: idx,
      id: item,
      No: idx,
      Update:
        `${item?.user?.surname}, ${item?.user?.givenName}`.length > 40
          ? `${item?.user?.surname}, ${item?.user?.givenName}`.slice(0, 40) + '...'
          : `${item?.user?.surname}, ${item?.user?.givenName}`,
      UpdateTime: item?.updatedAt ? moment(new Date(item.updatedAt)).format(FORMAT_TIME_AM_PM) : '',
    }));
    return dataSource as unknown as INSAADataSource[];
  }, [history]);

  return (
    <CustomFormDialog
      title="History Change"
      open={open}
      _onCloseModal={handleClose}
      disabled={loading}
      hiddenSubmitButton
      cancelText="Close"
    >
      <Box display="flex" flex={1}>
        <NSAATable loading={loading} columns={columns} dataSource={dataSource} disabledSort />
      </Box>
    </CustomFormDialog>
  );
};

export default HistoryChangeModal;
