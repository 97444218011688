export const data = [
  {
    title: 'Name',
    hash: 'name',
    content: [
      {
        children: [
          {
            text: 'The association shall be known as the Nanyang Schools Alumni Association (Nanyang Girls’ High School, Nanyang Primary School, Nanyang Kindergarten).',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'Place of Business',
    hash: 'place-of-business',
    content: [
      {
        children: [
          {
            text: 'Its place of business shall be at 2 Linden Drive, Singapore 288683 or such other address as may be decided by the Management Committee (hereinafter referred to as “the committee”) and approved by the Registrar of Societies.',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'Objects',
    hash: 'objects',
    content: [
      {
        type: 'h4',
        id: 1640055139517,
        children: [
          {
            text: 'i. To provide opportunities for the gathering of the former students of the Nanyang Girls’ High School, the Nanyang Primary School and the Nanyang Kindergarten (hereinafter collectively referred to as “Nanyang Schools”).',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055193871,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055193877,
        children: [
          {
            text: 'ii. To promote friendship and unity among the members.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055193888,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055193896,
        children: [
          {
            text: 'iii. To assist in development of the Nanyang Schools.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055193906,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055193914,
        children: [
          {
            text: 'iv. To participate in the social and welfare work of our association.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055193921,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055193924,
        children: [
          {
            text: 'v. To accept gifts and donations from any person, body or organisation and to utilitise such gifts or donations in connection with and to further the interests of the association and the Nanyang Schools.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055193927,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055193929,
        children: [
          {
            text: 'vi. To execute any purpose which the committee deems to be ancillary, incidental to or consequential on any of the objects herein set forth.',
          },
        ],
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'Membership',
    hash: 'membership',
    content: [
      {
        type: 'h4',
        children: [
          {
            text: 'There shall be 4 categories of membership:',
          },
        ],
        id: 1640055554667,
      },
      {
        type: 'p',
        children: [
          {
            text: '',
          },
        ],
        id: 1640055566196,
      },
      {
        children: [
          {
            text: '• Life membership',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '• Associate membership',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '• Honorary membership',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '• Youth membership',
          },
        ],
        type: 'h4',
      },
      {
        type: 'p',
        children: [
          {
            text: '',
          },
        ],
        id: 1640055459584,
      },
      {
        children: [
          {
            text: 'i. Persons who are below 21 years of age shall not be accepted as members without the written consent of their parent or guardian.',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: 'ii. All former students of the Nanyang Schools above the age of 21 years may apply for life membership',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: 'iii. All the retired staff of the Nanyang Schools and persons with special contribution to the Nanyang Schools or the association may be appointed by the committee as Honorary members.',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: 'iv. The present staff of the Nanyang Schools may be eligible for associate membership. Their membership will terminate once they cease employment in the Nanyang Schools.',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: 'v. Former students of the Nanyang Schools below the age of 25 may be eligible for youth membership. Their membership will terminate on 31 December in the year they attain the age of 25 or when they become a life member.',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: 'vi. All applications for membership shall be submitted on a prescribed form to the committee which may, at its discretion, accept or reject any application.',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: 'vii. Subject to Clause 4(viii), members shall have the right to participate in the association’s activities.',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: 'viii. Only life members shall have the right to speak and vote at all general meetings of the association, and to be elected to office in the committee.',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: 'ix. Youth members may at any time convert their membership after the age of 21 to that of life by paying up the entrance and life subscription fees prescribed in Clause 5 & 6.',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: 'x. There is no restriction regarding sex, race, or religion.',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: 'xi. Membership is unlimited.',
          },
        ],
        type: 'h4',
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'Entrance Fees',
    hash: 'entrance-fees',
    content: [
      {
        type: 'h4',
        id: 1640055638854,
        children: [
          {
            text: 'i. Entrance Fees for members at 55 years old and above:-',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055691013,
        children: [
          {
            text: 'An entrance fee is payable upon approval of membership, in default of which membership may be cancelled by order of the committee. The entrance fee for the respective types of membership is as follow:',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055691019,
        children: [
          {
            text: ' • Life - $ 5.00',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055691022,
        children: [
          {
            text: ' • Honorary – nil',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055691026,
        children: [
          {
            text: ' • Associate – nil',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055691031,
        children: [
          {
            text: 'ii. Entrance Fees for members below 55 years old:-',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055691038,
        children: [
          {
            text: 'An entrance fee is payable upon approval of membership, in default of which membership may be cancelled by order of the committee. The entrance fee for the respective types of membership is as follows:',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055691051,
        children: [
          {
            text: ' • Life - $500.00',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055691058,
        children: [
          {
            text: ' • Honorary – nil',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055691062,
        children: [
          {
            text: ' • Associate – nil',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055691072,
        children: [
          {
            text: ' • Youth – nil',
          },
        ],
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'Subscription',
    hash: 'subscription',
    content: [
      {
        type: 'h4',
        id: 1640055691072,
        children: [
          {
            text: 'i.Subscriptions for members at 55 years old and above',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944262,
        children: [
          {
            text: '• Honorary – nil',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944266,
        children: [
          {
            text: '• Associate – nil',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944268,
        children: [
          {
            text: 'Life members shall pay a one time subscription of $200/-',
          },
        ],
      },
      {
        type: 'p',
        children: [
          {
            text: '',
          },
        ],
        id: 1640056030875,
      },
      {
        type: 'h4',
        id: 1640055944281,
        children: [
          {
            text: 'ii. Subscription for members below 55 years old:-',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944291,
        children: [
          {
            text: '• Honorary – nil',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944298,
        children: [
          {
            text: '• Associate – nil',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944303,
        children: [
          {
            text: '• Youth – nil',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944309,
        children: [
          {
            text: 'Life members shall pay a one time subscription of $500.00.',
          },
        ],
      },
      {
        type: 'p',
        children: [
          {
            text: '',
          },
        ],
        id: 1640056033488,
      },
      {
        type: 'h4',
        id: 1640055944322,
        children: [
          {
            text: 'iii. Installment payment is accepted if the family income is $3000.00 and below.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944333,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944338,
        children: [
          {
            text: 'iv. Membership of members who are in arrears of subscription of instalments for more than 3 years will be terminated automatically.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944345,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944350,
        children: [
          {
            text: 'v. If a member falls into arrears with his subscriptions for more than 2 years he/she will automatically cease to be a member. He or she can apply for readmission, subject to the approval of the committee and his/her payment of the prescribed entrance fees.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944357,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640055944368,
        children: [
          {
            text: 'vi. The subscription rate may only be varied by the general meeting of the members. Any special subscription for particular purposes may only be raised from the members with the consent of the general meeting of the members.',
          },
        ],
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'General Meetings',
    hash: 'general-meetings',
    content: [
      {
        type: 'h4',
        id: 1640055944368,
        children: [
          {
            text: 'i. The supreme authority of the association is vested in a General Meeting of the members. At least 1/10 of the total voting membership of the association or 30 voting members, whichever is lesser, present at a general meeting shall form a quorum.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056154219,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056154224,
        children: [
          {
            text: 'ii. An annual general meeting (“AGM”) will be held in March. At other times an extraordinary general meeting (“EGM”) can be called by the president on the request in writing of 20 or more life members and it may be called at any time by order of the committee.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056154228,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056154232,
        children: [
          {
            text: 'iii. At least 2 weeks’ notice shall be given of a general meeting. A notice of meeting stating the date, time and place of meeting shall be sent by the Hon. Secretary to all life members. The notice and agenda of all meetings may be sent to life members by way of electronic mail or by post. The following points will be considered at the AGM:',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056154241,
        children: [
          {
            text: '    a. The previous year’s accounts and report of the committee',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056154244,
        children: [
          {
            text: '    b. The election of office-bearers and auditors for the following year.',
          },
        ],
      },
      {
        type: 'p',
        children: [
          {
            text: '',
          },
        ],
        id: 1640056226063,
      },
      {
        type: 'h4',
        id: 1640056154249,
        children: [
          {
            text: 'iv. Any life member who wishes to place an item on the agenda of the general meeting may do so provided he or she gives notice to the Hon. Secretary 1 week before the meeting is due to be held.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056154255,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056154258,
        children: [
          {
            text: 'v. In the event of there being no quorum, the meeting shall be adjourned for ½ hour and should the number then present be insufficient to form a quorum, those present shall be considered a quorum, but they shall have no power to amend any part of the existing rules.',
          },
        ],
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'Management Committee',
    hash: 'management-committee',
    content: [
      {
        type: 'h4',
        id: 1640056154258,
        children: [
          {
            text: 'i. A committee consisting of the following shall be elected at the alternate AGM:-',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262258,
        children: [
          {
            text: '  • A President',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262260,
        children: [
          {
            text: '  • 4 Vice-Presidents',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262263,
        children: [
          {
            text: '  • An Hon. Secretary',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262266,
        children: [
          {
            text: '  • 4 Assistant Hon. Secretaries',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262269,
        children: [
          {
            text: '  • An Hon. Treasurer',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262272,
        children: [
          {
            text: '  • 1 Assistant Hon. Treasurer',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262275,
        children: [
          {
            text: '  • 18 Ordinary Committee Members',
          },
        ],
      },
      {
        type: 'p',
        children: [
          {
            text: '',
          },
        ],
        id: 1640056339446,
      },
      {
        type: 'h4',
        id: 1640056262278,
        children: [
          {
            text: 'ii. Names for all committee members shall be proposed and seconded at an AGM and an election shall follow on a simple majority vote of the life members present. The committee members so elected shall elect amongst themselves the holders of the different posts. All office-bearers, except the Hon. Treasurer may be re-elected to the same or related post for a consecutive term of office. The term of office of each committee shall be 2 years.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262280,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262282,
        children: [
          {
            text: 'iii. Election of committee members will be either by way of a show of hand or, subject to the agreement of the majority of the life members present, by a secret ballot. Each committee member shall have 1 vote. In the event of a tie, the Chairman of the meeting shall have a casting vote.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262285,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262287,
        children: [
          {
            text: 'iv. A committee meeting shall be held at least once every 2 months after 7 days of notice to committee members. The President may call a committee meeting at any time by giving 7 days’ notice. At least 12 of the committee members must be present for its proceedings to be valid.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262290,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262292,
        children: [
          {
            text: 'v. Each committee member shall have 1 vote. If votes for and against a motion are equal, the Chairman of the meeting shall have an additional or casting vote.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262295,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262298,
        children: [
          {
            text: 'vi. The duty of the committee is to organise and supervise the daily activities of the association and to make decisions on matters affecting its running when the general meeting is not sitting. It may not act contrary to the expressed wishes of the general meeting without prior reference to it and always remains subordinate to the general meetings.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262300,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262302,
        children: [
          {
            text: 'vii. The committee has the power to authorise from the association’s funds for the purposes and/or benefit of the association or the Nanyang Schools in such manner the committee deems fit and proper. In this connection, the committee shall have the power to authorise the expenditure of a sum not exceeding $10,000.00 per month from the association funds and/or a net expenditure not exceeding $100,000.00 per project.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262305,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262307,
        children: [
          {
            text: 'viii. The committee shall have the power to delegate or refer such of its duties to any subcommittee which it may appoint for any specific purpose as it shall deem necessary, provided that the decisions, findings, recommendations, reports or actions of any such sub-committee shall have no force whatsoever unless and until approved by the committee. A sub-committee may be comprised of members and non-members of the association but shall be chaired by a member of the committee',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262310,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262312,
        children: [
          {
            text: 'ix. The committee shall have the power to fill any vacancy in the committee by co-option from the general body of life members.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262315,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056262319,
        children: [
          {
            text: 'x. To appoint any person with a particular skill or knowledge from the general body of life members to be an advisor to the committee, during the term of office of the committee, to assist the committee in its work. An advisor so appointed shall have the right to be present and speak at meetings of the committee but shall have no right to hold office or vote at meetings of the committee.',
          },
        ],
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'Duties of Office-bearers',
    hash: 'duties-of-office-bearers',
    content: [
      {
        type: 'h4',
        id: 1640056262319,
        children: [
          {
            text: 'i. The President shall act as Chairman at all general and committee meetings. He/she shall also represent the association in its dealings with outside persons.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056473537,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056473540,
        children: [
          {
            text: 'ii. The Vice-Presidents shall assist the President and one of them shall be appointed to deputise for the President in the latter’s absence.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056473543,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056473545,
        children: [
          {
            text: 'iii. The Hon. Secretary shall keep all records, except financial, of the association and shall be responsible for their correctness. He/she will keep minutes of all general and committee meetings.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056473547,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056473550,
        children: [
          {
            text: 'iv. The Assistant Hon. Secretaries shall assist the Hon. Secretary and shall deputise for the Hon. Secretary in the latter’s absence.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056473552,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056473554,
        children: [
          {
            text: 'v. The Hon. Treasurer shall keep all funds and collect and disburse all money on behalf of the association and shall keep an account of all monetary transactions and shall be responsible for their correctness. He/she is authorised to spend up to $500.00 per month for petty expenses on behalf of the association. He/she will not keep more than $500.00 in the form of cash and money in excess of this will be deposited in the bank or banks to be named by the committee. Cheques etc. for withdrawal from a bank will be signed by The President, Vice-President or Hon. Secretary in addition to the Hon. Treasurer or Assistant Hon. Treasurer.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056473556,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056473558,
        children: [
          {
            text: 'vi. The Assistant Hon. Treasurer shall assist the Hon. Treasurer and shall deputise for the Hon. Treasurer in the latter’s absence.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056473562,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056473566,
        children: [
          {
            text: 'vii. Any member of the committee absenting himself/herself from 3 meetings consecutively without satisfactory explanations may be deemed to have withdrawn from the committee and a successor may be co-opted by the committee to serve until the next AGM.',
          },
        ],
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'Auditors',
    hash: 'auditors',
    content: [
      {
        type: 'h4',
        id: 1640056473566,
        children: [
          {
            text: '2 persons not members of the committee will be elected as Hon. Auditors at each AGM and will hold office for 2 years only and may not be re-elected. They will be required to audit each year’s account and present a report upon them to the AGM. They may be required by the President to audit the association’s account for any period within their tenure of office at any date and make a report to the committee. The financial year shall be from 1st March to end February.',
          },
        ],
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'Amendments to Rules',
    hash: 'amendments-to-rules',
    content: [
      {
        type: 'h4',
        id: 1640056473566,
        children: [
          {
            text: 'The association shall not amend its Constitution without the prior approval in writing of the Registrar of Societies. No alterations or addition/deletion to this Constitution shall be passed except at a general meeting and with the consent of two-thirds (2/3)of the voting members present at the General Meeting.',
          },
        ],
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'Prohibitions',
    hash: 'prohibitions',
    content: [
      {
        type: 'h4',
        id: 1640056473566,
        children: [
          {
            text: "i. Gambling of any kind, excluding the promotion or conduct of a private lottery which has been permitted under the Private Lotteries Act Cap 250, is forbidden on the association's premises. The introduction of materials for gambling or drug taking and of bad characters into the premises is prohibited.",
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056603164,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056603167,
        children: [
          {
            text: 'ii. The funds of the association shall not be used to pay the fines of members who have been convicted in court of law.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056603170,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056603172,
        children: [
          {
            text: 'iii. The association shall not engage in any trade union activity as defined in any written law relating to trade unions for the time being in force in Singapore.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056603175,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056603178,
        children: [
          {
            text: 'iv. The association shall not hold any lottery, whether confined to its members or not, in the name of the association or its office-bearers, Committee or members unless with the prior approval of the relevant authorities.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056603181,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056603184,
        children: [
          {
            text: 'v. The association shall not indulge in any political activity or allow its funds and/or premises to be used for political purposes.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056603186,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056603189,
        children: [
          {
            text: 'vi. The association shall not raise funds from the public for whatever purposes without the prior approval in writing of the Assistant Director Operations, Licensing Division, Singapore Police Force and other relevant authorities, where necessary.',
          },
        ],
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'Interpretation',
    hash: 'interpretation',
    content: [
      {
        type: 'h4',
        id: 1640056603189,
        children: [
          {
            text: 'i. In the event of any question or matter pertaining to the day-to-day administration which is not expressly provided for in this constitution, the committee shall have power to use their own discretion. The decision of the committee shall be final unless it is reversed at a general meeting of members.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056665037,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056665039,
        children: [
          {
            text: 'ii. In the event that this constitution is translated into any other language(s), such a translation(s) shall be meant for reference purposes only and shall in no way govern or affect the interpretation of this constitution drafted in the English and this constitution drafted in English shall prevail over all other translations.',
          },
        ],
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
  {
    title: 'Dissolution',
    hash: 'dissolution',
    content: [
      {
        type: 'h4',
        id: 1640056665039,
        children: [
          {
            text: 'i. The association shall not be dissolved, except with the consent of not less than 3/5 of the voting members of the association for the time being resident in Singapore expressed, either in person or by proxy at a general meeting convened for the purpose.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056693109,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056693112,
        children: [
          {
            text: 'ii. In the event of the association being dissolved as provided above, all debt and liabilities legally incurred on behalf of the association shall be fully discharged and the remaining funds be donated to the Nanyang Schools in proportions agreed by voting members.',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056693115,
        children: [
          {
            text: '',
          },
        ],
      },
      {
        type: 'h4',
        id: 1640056693120,
        children: [
          {
            text: 'iii. Notice of dissolution shall be given within 7 days of the dissolution to the Registrar of Societies.',
          },
        ],
      },
      {
        children: [
          {
            text: '',
          },
        ],
        type: 'p',
      },
    ],
  },
];

export const menus = [
  'Name',
  'Place of Business',
  'Objects',
  'Membership',
  'Entrance Fees',
  'Subscription',
  'General Meetings',
  'Management Committee',
  'Duties of Office-bearers',
  'Auditors',
  'Amendments to Rules',
  'Prohibitions',
  'Interpretation',
  'Dissolution',
];
export const menuSpy = [
  'name',
  'place-of-business',
  'objects',
  'membership',
  'entrance-fees',
  'subscription',
  'general-meetings',
  'management-committee',
  'duties-of-office-bearers',
  'auditors',
  'amendments-to-rules',
  'prohibitions',
  'interpretation',
  'dissolution',
];
