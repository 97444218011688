import React from 'react';

import { lifeMembershipFAQ } from '../../data';
import Membership from '../../shared/membership.component';

const MembershipLifetime = () => {
  return <Membership data={lifeMembershipFAQ} />;
};

export default MembershipLifetime;
