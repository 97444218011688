import React from 'react';

import { RouteProps } from 'react-router-dom';

import { MainChineseLayout } from 'src/layouts/chineseLayout/main';
import { LoginLayout } from 'src/layouts/login';
import { SignUpLayout } from 'src/layouts/signup';
import { AboutUs } from 'src/pages/about-us';
import { DashBoard } from 'src/pages/admin';
import { AttendanceManagement } from 'src/pages/attendance-management';
import { CheckInCheckOutScreen } from 'src/pages/checkin-checkout';
import ContactUsCn from 'src/pages/chinese-pages/contact-us';
import { HomeChinese } from 'src/pages/chinese-pages/home';
import { AboutUs as ChineseAboutUs } from 'src/pages/chinese-pages/home/components/about-us';
import ChineseEventActivities from 'src/pages/chinese-pages/home/components/EventActivities';
import EventDetailContentChinese from 'src/pages/chinese-pages/home/components/EventActivities/EventDetail';
import CNMembershipApplicant from 'src/pages/chinese-pages/membership/membership-applicant';
import CNMembershipInformation from 'src/pages/chinese-pages/membership/membership-information';
import CNMembershipLifetime from 'src/pages/chinese-pages/membership/membership-lifetime';
import CNMembershipYouth from 'src/pages/chinese-pages/membership/membership-youth';
import ContactUs from 'src/pages/contact-us';
import SubscriptionCenter from 'src/pages/contact-us/SubscriptionCenter';
import Donation from 'src/pages/donation';
import { DonationNSAA } from 'src/pages/donation/donationNSAA';
import { DonationNYGH } from 'src/pages/donation/donationNYGH';
import EmailTracking from 'src/pages/email-tracking';
import { ETicketChecking } from 'src/pages/eTicket-checking';
import EventManagement from 'src/pages/event-management';
import EventManagementSearchList from 'src/pages/event-management/components/search';
import { EventQuiz } from 'src/pages/event-quiz';
import Events from 'src/pages/events';
import EventDetailContent from 'src/pages/events/EventDetail/index';
import { GTKYApplicant } from 'src/pages/gtky-applicant';
import ForgotEmail from 'src/pages/login/ForgotEmail';
import { ForgotPassword } from 'src/pages/login/ForgotPassword';
import ResetPassword from 'src/pages/login/ResetPassword';
import { MemberShipPayment } from 'src/pages/membership-payment';
import MembershipApplicant from 'src/pages/membership/components/membership-applicant';
import MembershipInformation from 'src/pages/membership/components/membership-information';
import MembershipLifetime from 'src/pages/membership/components/membership-lifetime';
import MembershipYouth from 'src/pages/membership/components/membership-youth';
import MyVolunteerEvent from 'src/pages/my-volunteer-event';
import RegisterVolunteer from 'src/pages/my-volunteer-event/components/registerVolunteer';
import { PostDetail } from 'src/pages/news/PostDetail';
import { Forbidden } from 'src/pages/not-access';
import PaymentManagement from 'src/pages/payment-managerment';
import VerifyPayment from 'src/pages/payment-managerment/verify-payment/VerifyPayment';
import PrivacyPolicy from 'src/pages/privacy-policy';
import Profile from 'src/pages/profile';
import RegistrationForms from 'src/pages/registration-forms';
import SignUpForChild from 'src/pages/sign-up-child';
import { SignUp } from 'src/pages/signup';
import SignUpForm from 'src/pages/signup/components/SignUpForm';
import TermOfService from 'src/pages/terms-of-use';
import { UserManagement } from 'src/pages/user-management';

import { MainLayout } from '../layouts/main';
import { Home } from '../pages/home';
import { Login } from '../pages/login';
import { News } from '../pages/news';
import { PublicPost } from '../pages/public-post';

export interface IRoute extends RouteProps {
  restricted?: boolean;
  layout?: React.ComponentType;
}

const privateRoutes: IRoute[] = [
  {
    children: <DashBoard />,
    path: '/cms',
    exact: true,
  },
  {
    children: <GTKYApplicant />,
    path: '/gtky-applicant',
    exact: true,
  },
  {
    children: <PaymentManagement />,
    path: '/payment-management',
    exact: true,
  },
  {
    children: <AttendanceManagement />,
    path: '/attendance-management',
    exact: true,
  },
  {
    children: <PublicPost />,
    path: '/public/post',
    layout: LoginLayout,
    exact: true,
  },
  {
    children: <UserManagement />,
    path: '/user-management',
    exact: true,
  },
  {
    children: <EventManagementSearchList />,
    path: '/event-management',
    exact: true,
  },
  {
    children: <EventManagement />,
    path: '/event-management/:id',
  },
  {
    children: <Profile />,
    path: '/my-profile',
    exact: true,
  },
  {
    children: <MyVolunteerEvent />,
    path: '/my-volunteer-event',
    exact: true,
  },
  {
    children: <DonationNYGH />,
    path: '/donation/nygh',
    exact: true,
  },
  {
    children: <DonationNSAA />,
    path: '/donation/nsaa',
    exact: true,
  },
  {
    children: <ETicketChecking />,
    path: '/e-ticket-checking',
    exact: true,
    layout: LoginLayout,
  },
  {
    children: <EventQuiz />,
    path: '/events/:id/quiz',
    exact: true,
  },

  {
    children: <MemberShipPayment />,
    path: '/membership-payment',
    exact: true,
  },
  {
    children: <EmailTracking />,
    path: '/email-tracking',
    exact: true,
  },
];
const publicRoutes: IRoute[] = [
  {
    children: <Home />,
    exact: true,
    path: '/',
  },
  {
    children: <RegisterVolunteer />,
    path: '/users-event/change-status',
    exact: true,
  },
  {
    children: <VerifyPayment />,
    path: '/payment/verify-token',
    exact: true,
  },
  {
    children: <Login />,
    path: '/login',
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <SignUp />,
    path: '/signup',
    layout: LoginLayout,
    exact: true,
  },
  {
    children: <SignUpForChild />,
    path: '/signup/child',
    layout: SignUpLayout,
    exact: true,
  },
  {
    children: <SignUpForm />,
    path: '/signup/member',
    layout: SignUpLayout,
    exact: true,
  },
  {
    children: <News />,
    exact: true,
    path: '/news',
  },
  {
    children: <PostDetail />,
    exact: true,
    path: '/news/:id',
  },
  {
    children: <ForgotPassword />,
    path: '/forgot-password',
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <ResetPassword />,
    path: '/reset-password/:userId',
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <ForgotEmail />,
    path: '/forgot-email',
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <PublicPost />,
    path: '/public/post',
    layout: LoginLayout,
    restricted: true,
  },
  {
    children: <AboutUs />,
    path: '/about-us',
    restricted: false,
  },
  {
    children: <Events />,
    path: '/events',
    exact: true,
  },

  {
    children: <Donation />,
    path: '/donation',
    exact: true,
  },
  {
    children: <ContactUs />,
    path: '/contact-us',
    exact: true,
  },
  {
    children: <SubscriptionCenter />,
    path: '/subcription-center',
    exact: true,
  },
  {
    children: <EventDetailContent />,
    path: '/events/:id',
    exact: true,
  },
  {
    children: <Forbidden />,
    path: '/not-access',
    exact: true,
  },
  {
    children: <RegistrationForms />,
    path: '/registration-forms/:id',
    exact: true,
  },
  {
    children: <CheckInCheckOutScreen />,
    path: '/check-in-check-out',
    exact: true,
    layout: LoginLayout,
  },
  {
    children: <MembershipInformation />,
    path: '/membership/information',
  },
  {
    children: <MembershipLifetime />,
    path: '/membership/life',
  },
  {
    children: <MembershipYouth />,
    path: '/membership/youth',
  },
  {
    children: <MembershipApplicant />,
    path: '/membership/applicant',
  },
  {
    children: <PrivacyPolicy />,
    path: '/privacy-policy',
  },
  {
    children: <TermOfService />,
    path: '/terms-of-use',
  },
];
const noLayoutRoutes: IRoute[] = [];
const chineseRoute: IRoute[] = [
  {
    children: <HomeChinese />,
    exact: true,
    path: '/cn',
  },
  {
    children: <News />,
    exact: true,
    path: '/cn/news',
  },
  {
    children: <PostDetail />,
    exact: true,
    path: '/cn/news/:id',
  },
  {
    children: <ChineseAboutUs />,
    path: '/cn/about-us',
    restricted: false,
  },
  {
    children: <ChineseEventActivities />,
    path: '/cn/events',
    exact: true,
  },
  {
    children: <ContactUsCn />,
    path: '/cn/contact-us',
    exact: true,
  },
  {
    children: <SubscriptionCenter />,
    path: '/cn/subcription-center',
    exact: true,
  },
  {
    children: <EventDetailContentChinese />,
    path: '/cn/events/:id',
    exact: true,
  },
  {
    children: <CNMembershipInformation />,
    path: '/cn/membership/information',
  },
  {
    children: <CNMembershipLifetime />,
    path: '/cn/membership/life',
  },
  {
    children: <CNMembershipYouth />,
    path: '/cn/membership/youth',
  },
  {
    children: <CNMembershipApplicant />,
    path: '/cn/membership/applicant',
  },
];

interface IConfigRoutes {
  appRoutes: IRoute[];
  isPrivate?: boolean;
  layout?: React.ComponentType;
}

export const configRoutes: IConfigRoutes[] = [
  {
    appRoutes: privateRoutes,
    isPrivate: true,
    layout: MainLayout,
  },
  {
    appRoutes: publicRoutes,
    layout: MainLayout,
  },
  {
    appRoutes: noLayoutRoutes,
  },
  {
    appRoutes: chineseRoute,
    layout: MainChineseLayout,
  },
];
