import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const questionTypes = {
  singleChoice: 'single-choice',
  shortParagraph: 'short-paragraph',
};

export const MAXIMUM_LENGTH = 255;
export const TEXT_REQUIRED = 'This field is required';

export const TitleStyled = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 700,
  color: '#C5BE8B',
}));

export const TextScore = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  fontStyle: 'italic',
  color: 'rgba(0, 0, 0, 0.6)',
}));
export const ScorePoint = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  fontStyle: 'italic',
  color: '#F9443E',
}));

export const ErrorMessage = styled.span(() => ({
  color: '#f44336',
  fontSize: 14,
  fontWeight: 400,
  marginTop: 4,
}));
