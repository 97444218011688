import React, { useEffect, useState } from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import _ from 'lodash';

import { InputField, NASSRadio } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import { EventSwitch } from 'src/pages/event-management/shared/switch';
import { CustomLabel } from 'src/pages/signup/components/types';
import { eventCategories } from 'src/pages/user-management/types';
import { IEvent } from 'src/services/events';
import { IUpdateOverViewParams, updateVolunteerManagement } from 'src/services/volunteer';
import { intRegex, _checkErrors } from 'src/utils/common';

import { CustomButtonStyled, TextStyled, TypoItalicStyled } from '../../types';

import {
  eventStatus,
  initState,
  IOverView,
  TEXT_REQUIRE,
  MIN_NUMBER,
  MUST_BE_GREATER_THAN,
  VACANCIES_MUST_BE_GREATER_THAN,
  MAX_OF_VACANCIES,
  MIN_VACANCIES_NUMBER,
  MAX_VACANCIES_NUMBER,
} from './types';

const OverView = ({ event, setEvent }: IOverView) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [data, setData] = useState<IUpdateOverViewParams>(initState);
  const [defaultData, setDefault] = useState<IUpdateOverViewParams>();
  const [errors, setErrors] = useState({
    max_volunteer: false,
    percent_vacancies: false,
    link_for_volunteer_registration: false,
    volunteer_registration_expire: false,
  });

  const [isActionSuccess, setActionSuccess] = useState(false);
  const [isActionFailed, setActionFailed] = useState(false);
  const [messageResponse, setMessageResponse] = useState('');

  let turnOffLink = false;
  if (event) {
    if (new Date(event.start_time).getTime() < new Date().getTime()) {
      turnOffLink = true;
    }
  }

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.name === 'max_volunteer' ||
      e.target.name === 'percent_vacancies' ||
      e.target.name === 'volunteer_registration_expire'
    ) {
      if (intRegex.test(e.target.value) || e.target.value === '') {
        setData({ ...data, [e.target.name]: e.target.value });
        setValidate([e.target.name], intRegex.test(e.target.value) ? e.target.value : '');
      }
    }

    if (e.target.name === 'require_volunteer') {
      if (e.target.value === 'true') {
        if (turnOffLink === false) {
          setData({ ...data, link_for_volunteer_registration: true, require_volunteer: true });
        } else {
          setData({ ...data, require_volunteer: true });
        }

        let newErrors = errors;

        if (data.max_volunteer === '') {
          newErrors.max_volunteer = true;
        } else {
          newErrors.max_volunteer = false;
        }
        if (data.percent_vacancies === '') {
          newErrors.percent_vacancies = true;
        } else {
          newErrors.percent_vacancies = false;
        }
        if (data.volunteer_registration_expire === '') {
          newErrors.volunteer_registration_expire = true;
        } else {
          newErrors.volunteer_registration_expire = false;
        }
        setErrors(newErrors);
      } else {
        setData({
          ...data,
          max_volunteer: MIN_VACANCIES_NUMBER,
          percent_vacancies: MAX_VACANCIES_NUMBER,
          link_for_volunteer_registration: false,
          volunteer_registration_expire: 1,
          require_volunteer: false,
        });
        setErrors({
          max_volunteer: false,
          percent_vacancies: false,
          link_for_volunteer_registration: false,
          volunteer_registration_expire: false,
        });
      }
    }
    if (e.target.name === 'link_for_volunteer_registration') {
      setData({ ...data, [e.target.name]: !data.link_for_volunteer_registration });
    }
  };

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setData({ ...data, [e.target.name]: valueTrimmed });
  };

  const _onChangeEditStatus = () => {
    setEdit(!edit);
  };

  const _onCancel = () => {
    setEdit(!edit);
    if (defaultData) {
      setData(defaultData);
    }
  };

  const _onSave = async () => {
    setLoading(true);
    let body = data;
    body.max_volunteer = Number(body.max_volunteer);
    body.percent_vacancies = Number(body.percent_vacancies);
    body.volunteer_registration_expire = Number(body.volunteer_registration_expire);

    if (body?.max_volunteer < event?.max_volunteer && event?.status === eventStatus.pending) {
      setErrors({ ...errors, max_volunteer: true });
      setLoading(false);

      return;
    }

    if (body.require_volunteer && body?.max_volunteer === MIN_VACANCIES_NUMBER) {
      setErrors({ ...errors, max_volunteer: true });
      setLoading(false);

      return;
    }

    if (body.require_volunteer && body?.percent_vacancies === MIN_VACANCIES_NUMBER) {
      setErrors({ ...errors, percent_vacancies: true });
      setLoading(false);

      return;
    }

    if (body.require_volunteer && body?.percent_vacancies > MAX_VACANCIES_NUMBER) {
      setErrors({ ...errors, percent_vacancies: true });
      setLoading(false);

      return;
    }

    if (!_checkErrors(errors) && event) {
      const response = await updateVolunteerManagement(event.id, body);
      if (response.statusCode === CALL_SUCCESS) {
        setEdit(!edit);
        setActionSuccess(true);
        setMessageResponse(response.message);
        setEvent({ ...event, ...body } as IEvent);
      } else {
        setActionFailed(true);
        setMessageResponse(response.message);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (event) {
      setData({
        require_volunteer: event.require_volunteer,
        max_volunteer: event.max_volunteer ?? MIN_VACANCIES_NUMBER,
        percent_vacancies: event.percent_vacancies ?? MAX_VACANCIES_NUMBER,
        link_for_volunteer_registration: event.link_for_volunteer_registration,
        volunteer_registration_expire: event.volunteer_registration_expire ?? 1,
      });
      setDefault({
        require_volunteer: event.require_volunteer,
        max_volunteer: event.max_volunteer ?? MIN_VACANCIES_NUMBER,
        percent_vacancies: event.percent_vacancies ?? MAX_VACANCIES_NUMBER,
        link_for_volunteer_registration: event.link_for_volunteer_registration,
        volunteer_registration_expire: event.volunteer_registration_expire ?? 1,
      });
    }
  }, [event]);

  return (
    <Box>
      <Box flex={1} mt={4} mb={1}>
        <CustomLabel>Does this Event require volunteers?</CustomLabel>
      </Box>
      <Box>
        <FormControl component="fieldset">
          <RadioGroup
            value={event?.category === eventCategories.GTKY_PARTY ? false : data.require_volunteer}
            onChange={_handleChange}
            name="require_volunteer"
            row
          >
            <FormControlLabel
              value={true}
              disabled={event?.category === eventCategories.GTKY_PARTY ? true : !edit}
              control={<NASSRadio />}
              label="Yes"
            />
            <Box width={'250px'} />
            <FormControlLabel
              value={false}
              disabled={event?.category === eventCategories.GTKY_PARTY ? true : !edit}
              control={<NASSRadio />}
              label="No"
            />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <InputField
          disabled={event?.category === eventCategories.GTKY_PARTY ? true : !data.require_volunteer ? true : !edit}
          value={data.max_volunteer}
          onChange={_handleChange}
          onBlur={onBlurField}
          name="max_volunteer"
          title="Max Number of volunteers"
          margin="dense"
          fullWidth
          error={errors.max_volunteer}
          errormessage={
            data?.max_volunteer === ''
              ? TEXT_REQUIRE
              : data.require_volunteer && data?.max_volunteer === MIN_VACANCIES_NUMBER
              ? MUST_BE_GREATER_THAN
              : data?.max_volunteer < event?.max_volunteer && event?.status === eventStatus.pending && MIN_NUMBER
          }
        />
      </Box>
      <Box>
        <InputField
          disabled={event?.category === eventCategories.GTKY_PARTY ? true : !data.require_volunteer ? true : !edit}
          value={`${data.percent_vacancies}`}
          onChange={_handleChange}
          onBlur={onBlurField}
          name="percent_vacancies"
          title="% of vacancies for volunteers to be opened"
          margin="dense"
          fullWidth
          error={errors.percent_vacancies}
          errormessage={
            data?.percent_vacancies === ''
              ? TEXT_REQUIRE
              : data?.percent_vacancies === MIN_VACANCIES_NUMBER
              ? VACANCIES_MUST_BE_GREATER_THAN
              : data?.percent_vacancies > MAX_VACANCIES_NUMBER && MAX_OF_VACANCIES
          }
        />
      </Box>
      <Box mt={3} display="flex" alignItems="center">
        <CustomLabel>Turn on/off Link for volunteer registration</CustomLabel>
        <Box width={32} />
        <EventSwitch
          disabled={
            event?.category === eventCategories.GTKY_PARTY
              ? true
              : !data.require_volunteer
              ? true
              : turnOffLink
              ? true
              : !edit
          }
          checked={data.link_for_volunteer_registration}
          name="link_for_volunteer_registration"
          onChange={_handleChange}
        />
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Box flex={1} pr={3} display="flex">
          <InputField
            disabled={event?.category === eventCategories.GTKY_PARTY ? true : !data.require_volunteer ? true : !edit}
            value={data.volunteer_registration_expire}
            onChange={_handleChange}
            onBlur={onBlurField}
            name="volunteer_registration_expire"
            title="Volunteer Registration Link auto expired"
            margin="dense"
            fullWidth
            error={errors.volunteer_registration_expire}
            errormessage={TEXT_REQUIRE}
          />
        </Box>
        <Box p={0} pt={7} display="flex">
          <TypoItalicStyled>Day(s) before event start</TypoItalicStyled>
        </Box>
      </Box>
      <Box>
        {edit ? (
          <Box mt={3} justifyContent="flex-end" display="flex">
            <CustomButtonStyled outlineButton onClick={_onCancel}>
              Cancel
            </CustomButtonStyled>
            <Box ml={'16px'} />
            <CustomButtonStyled disabled={loading} onClick={_onSave}>
              Save
            </CustomButtonStyled>
          </Box>
        ) : (
          <Box mt={3} justifyContent="flex-end" display="flex">
            <CustomButtonStyled
              disabled={
                !(
                  event?.status === eventStatus.draft ||
                  event?.status === eventStatus.onGoing ||
                  event?.status === eventStatus.pending
                ) || loading
              }
              onClick={_onChangeEditStatus}
            >
              Edit
            </CustomButtonStyled>
          </Box>
        )}
      </Box>
      <Box p={0} mt={3} display="flex" justifyContent="center">
        <TextStyled>
          Created on {event?.createdAt ? new Date(event?.createdAt).toLocaleDateString('en-GB') : ''} by{' '}
          {event?.createdBy ? event.createdBy : ''} | Last Updated on{' '}
          {event?.updatedAt ? new Date(event?.updatedAt).toLocaleDateString('en-GB') : ''} by:{' '}
          {event?.updatedBy ? event.updatedBy : ''}
        </TextStyled>
      </Box>
      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </Box>
  );
};

export default OverView;
