import { Box, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { CustomButton } from 'src/components';

import donationNSAA from '../../medias/images/donation-nsaa.png';
import donationNYGH from '../../medias/images/donation-nygh.png';
import donationNYPS from '../../medias/images/donation-nyps.png';

export const donations = [
  { image: donationNSAA, key: 'donationNSAA', title: 'NSAA' },
  { image: donationNYPS, key: 'donationNYPS', title: 'NYPS' },
  { image: donationNYGH, key: 'donationNYGH', title: 'NYGH' },
];

export const NSAAContainer = styled(Box)`
  max-width: 846px;
  margin: auto;
`;

export const DonateHeader = styled(Typography)<{ isTabletOrMobile: boolean }>`
  && {
    ${({ theme }) => ({
      ...theme.fonts.header4S,
    })}
    ${({ theme, isTabletOrMobile }) => css`
      text-align: ${isTabletOrMobile ? 'left' : 'center'};
      padding-left: ${isTabletOrMobile ? '16px' : 0};
    `}
    margin-top: 64px;
  }
`;
export const DonationContainer = styled(Box)`
  && {
    max-width: 1200px;
    display: flex;
    align-items: center;
    margin: 32px auto 64px;
    gap: 24px;
  }
`;
export const BoxDonation = styled(Box)`
  && {
    gap: 24px;
  }
`;
export const ImgCustom = styled.img`
  && {
    width: 100%;
    height: auto;
  }
`;
export const DonateBtn = styled(CustomButton)`
  && {
    ${({ theme }) => ({
      ...theme.fonts.textM,
      fontWeight: 500,
    })}
    margin: auto;
  }
`;

export const BoxStyled = styled(Box)(() => ({
  border: '1px solid rgba(0, 0, 0, 0.1)',
  padding: 24,
  borderRadius: 12,
  backgroundColor: 'white',
}));

export const CustomButtonStyled = styled(CustomButton)(() => ({
  width: `100% !important`,
}));

export const ImageStyled = styled.img(() => ({}));
export const NoteStyled = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 400,
  color: '#2B457D',
  fontStyle: 'italic',
}));
export const RedText = styled(Typography)(() => ({
  fontSize: 13,
  fontWeight: 400,
  color: '#FF1515',
  fontStyle: 'italic',
}));
export const CaptionStyled = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 700,
  color: '#333333',
}));
export const SubCaptionStyled = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 700,
  color: '#333333',
  marginTop: 32,
}));
export const ContentStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.8)',
  lineHeight: 1.5,
}));
export const HeaderText = styled(Typography)(({ isTabletOrMobile }: { isTabletOrMobile: boolean }) => ({
  fontSize: isTabletOrMobile ? 14 : 18,
  fontWeight: 700,
  color: '#979168',
}));

export const TitleMobile = styled(Typography)(({ theme }) => ({
  marginBottom: 16,
  fontSize: 20,
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.8)',
}));
