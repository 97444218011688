import React from 'react';

import { Checkbox, CheckboxProps, withStyles } from '@material-ui/core';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

export const CheckboxCustom = withStyles((theme) => ({
  root: {
    padding: 0,
    color: '#979168',
    ...theme.fonts.textSSBold,
    '&$checked': {
      color: '#979168',
    },
    marginRight: 12,
    marginLeft: 4,
    transform: 'scale(1)',
  },

  checked: {},
}))((props: CheckboxProps) => <Checkbox checkedIcon={<CheckBoxOutlinedIcon />} {...props} />);
