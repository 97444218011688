import React, { useEffect, useState } from 'react';

import { Box, Grid, makeStyles, Step, StepLabel } from '@material-ui/core';

import { StepperCustom } from 'src/components';
import { HeaderMobile } from 'src/components/headerMobile';
import useFetchMe from 'src/hooks/use-fetch-me';
import backgroundSignup from 'src/medias/images/background-signup.png';
import { Title } from 'src/pages/login';
import useResponsive from 'src/utils/responsive';

import { TitleMobile } from '../donation/types';

import Confirmation from './components/Confirmation';
import Finish from './components/Finish';
import PaymentMethod from './components/PaymentMethod';

export const steps = ['Confirmation information', 'Payment', 'Finish'];
const MEMBER_OVER_YEAR = 55;
const MEMBER_OVER_YEAR_AMOUNT = 205;
const MEMBER_AMOUNT = 1000;

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${backgroundSignup})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: 890,
  },
  containerMobile: {
    backgroundColor: '#F9F9F4',
    minHeight: '100vh',
    paddingTop: 98,
  },
}));

export const MemberShipPayment = () => {
  const classes = useStyles();
  const { isTabletOrMobile } = useResponsive();
  const [activeStep, setActiveStep] = useState<number>(0);
  const { me } = useFetchMe();
  const [data, setData] = useState({
    amount: 0,
    surname: '',
    givenName: '',
    emailAddress: '',
  });
  const [paymentId, setPaymentId] = useState('');

  useEffect(() => {
    if (me) {
      let oldMember = Number(new Date().getFullYear()) - Number(new Date(me.dateOfBirth?.toString())?.getFullYear());
      setData({
        ...data,
        surname: me.surname,
        givenName: me.givenName,
        emailAddress: me.emailAddress,
        amount: oldMember > MEMBER_OVER_YEAR ? MEMBER_OVER_YEAR_AMOUNT : MEMBER_AMOUNT,
      });
    }
  }, [me]);

  return (
    <Box display={'flex'} flex={1} flexDirection={'column'}>
      {isTabletOrMobile && <HeaderMobile title={`Payment Membership`} userProfile={me} />}
      <Grid
        container
        justifyContent="center"
        className={isTabletOrMobile ? classes.containerMobile : classes.container}
      >
        <Grid item xs={isTabletOrMobile ? 12 : 7}>
          <Box display={'flex'} flex={1} flexDirection={'column'} height={'100%'} p={isTabletOrMobile ? 2 : 0}>
            <Box mt={isTabletOrMobile ? 0 : 8} textAlign="center">
              {isTabletOrMobile ? (
                <TitleMobile>MEMBERSHIP FEE PAYMENT</TitleMobile>
              ) : (
                <Title>MEMBERSHIP FEE PAYMENT</Title>
              )}
              <Box mt={2} width={'100%'}>
                <StepperCustom activeStep={activeStep}>
                  {steps.map((label, index) => {
                    return (
                      <Step key={label}>
                        <StepLabel>{isTabletOrMobile ? '' : label}</StepLabel>
                      </Step>
                    );
                  })}
                </StepperCustom>
              </Box>
            </Box>
            {activeStep === 0 && (
              <Confirmation
                me={me}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                data={data}
                setPaymentId={setPaymentId}
              />
            )}

            {activeStep === 1 && (
              <PaymentMethod
                amount={data.amount}
                fullName={`${me?.surname}, ${me?.givenName}`}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                paymentId={paymentId}
              />
            )}

            {activeStep === 2 && <Finish />}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
