import axios from 'axios';

import { localStorageHelper } from 'src/helpers';

import { httpStatus } from './http-status';
// Full config:  https://github.com/axios/axios#request-config
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Authorization'] =
  localStorageHelper.getItem(process.env.REACT_APP_ACCESS_TOKEN_KEY!) &&
  `Bearer ${localStorageHelper.getItem(process.env.REACT_APP_ACCESS_TOKEN_KEY!)}`;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

const config = {
  baseURL: process.env.REACT_APP_API_URL,
  validateStatus: (status: number) => {
    return (
      (status >= httpStatus.StatusOK && status < httpStatus.StatusMultipleChoices) ||
      status === httpStatus.StatusBadRequest
    );
  },
  timeout: 60 * 1000, // Timeout
  // withCredentials: true // Check cross-site Access-Control
};
const httpClient = axios.create(config);
httpClient.interceptors.request.use(
  (config) => {
    if (localStorageHelper.isLogin()) {
      config.headers['access-token'] = localStorageHelper.getItem(process.env.REACT_APP_ACCESS_TOKEN_KEY!);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error.response;
  },
);

export default httpClient;
