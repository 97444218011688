import React from 'react';

import { Box } from '@material-ui/core';
import moment from 'moment';
import styled, { css } from 'styled-components';

import {
  BoxContent,
  BoxItem,
  Description,
  NavLinkCustom,
  TextTime,
  ThumbnailPost,
  Title,
} from 'src/components/sliders/type';
import { FORMAT_DATE_DMY } from 'src/constants/common';
import { IEvent } from 'src/services/events';
import useResponsive from 'src/utils/responsive';

import { Line, UpComingTag } from './CardEvent';

const Post = styled(Box)<{ width?: string }>`
  ${({ theme, width }) => css`
    position: relative;
    box-sizing: border-box;
    border-radius: 3px;
    width: 300px;
  `}
`;

const EventItemMobile = ({ event, width }: { event: IEvent; width?: string }) => {
  const responsive = useResponsive();
  const eventStart = moment(+event.start_time);
  const now = moment();

  return (
    <NavLinkCustom to={`/events/${event.id}`}>
      {eventStart > now && <Line />}
      <Post key={event?.id} width={width}>
        {eventStart > now && <UpComingTag> UPCOMING</UpComingTag>}
        <BoxItem display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
          <ThumbnailPost src={event?.cover_photo} />
          <BoxContent p={3} responsive={responsive}>
            <TextTime>{moment(+event?.start_time!).format(FORMAT_DATE_DMY)}</TextTime>
            <NavLinkCustom to={`/events/${event.id}`}>
              <Title>{event?.title}</Title>
            </NavLinkCustom>
            <Description>{event?.short_description || 'No description'}</Description>
          </BoxContent>
        </BoxItem>
      </Post>
    </NavLinkCustom>
  );
};

export default EventItemMobile;
