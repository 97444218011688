import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import CustomFormDialog from 'src/components/formDialog';
import { CALL_SUCCESS } from 'src/constants/common';
import { getUser, IUser } from 'src/services/users';
import { capitalizeFirstLetter, convertSlugToUpperCase, utcToDateTime } from 'src/utils/common';

import {
  ButtonStatus,
  CaptionStyled,
  CustomBox,
  eventCategories,
  LabelStyled,
  TypographyTitleStyled,
  TypographyUserInfo,
  userTypes,
} from '../types';

import EventsCompleted from './EventsCompleted';
import { InfoText } from './Label';

interface IEligibleUserInfo {
  _onCloseModal: () => void;
  open: boolean;
  userId: string | undefined;
  setUserSelected: (value: React.SetStateAction<IUser[]>) => void;
  setActionSelected: (value: React.SetStateAction<string>) => void;
  setOpenConfirmActionModal: (value: React.SetStateAction<boolean>) => void;
}

const EligibleUserInfo = ({
  _onCloseModal,
  open,
  userId,
  setUserSelected,
  setActionSelected,
  setOpenConfirmActionModal,
}: IEligibleUserInfo) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<IUser | null>();

  const _onSubmit = () => {
    if (user) {
      if (user.eligibleStatus === 'pending-member') {
        setActionSelected('Approve Membership');
      } else {
        setActionSelected('Delete User');
      }
      setUserSelected([user]);
      setOpenConfirmActionModal(true);
      _onCloseModal();
    }
  };

  const getData = async () => {
    setLoading(true);

    if (userId) {
      const response = await getUser(userId);
      if (response?.statusCode === CALL_SUCCESS) {
        setUser(response.data);
      }
    }

    setLoading(false);
  };

  useEffect(() => {
    if (userId) {
      getData();
    }
  }, [userId]);

  let variants: 'not-eligible' | 'eligible' | 'pending-member' | undefined =
    user?.eligibleStatus === 'not-eligible'
      ? 'not-eligible'
      : user?.eligibleStatus === 'eligible'
      ? 'eligible'
      : user?.eligibleStatus === 'pending-member'
      ? 'pending-member'
      : undefined;

  let volunteerEventUser = 0;
  let volunteerNeed = 0;

  let configs: { key: string; value: string }[] = [];

  if (user && user.accountType === userTypes.applicant) {
    configs = user.config.applicantConfig;
  }
  if (user && user.accountType === userTypes.youthMember) {
    configs = user.config.youngMemberConfig;
  }

  if (user) {
    const volunteerEventUserItem = user.eligibility.find((item) => item.key === eventCategories.volunteer);

    if (user.accountType === userTypes.applicant) {
      volunteerNeed = Number(user.config.applicantSession);
    }
    if (user.accountType === userTypes.youthMember) {
      volunteerNeed = Number(user.config.youngMemberSession);
    }

    if (volunteerEventUserItem) {
      volunteerEventUser = Number(volunteerEventUserItem.value);
    }
  }

  return (
    <CustomFormDialog
      open={open}
      _onCloseModal={_onCloseModal}
      _onSubmit={_onSubmit}
      submitText={user?.eligibleStatus === 'pending-member' ? 'Approve' : 'Delete'}
      title="User’s Eligibility Info"
      disabled={loading}
      deleteButton={user?.eligibleStatus === 'pending-member' ? false : true}
      modalStyles={{
        marginLeft: 0,
        marginRight: 0,
      }}
      minWidth="960px"
    >
      <CustomBox>
        <Box display="flex" justifyContent="space-between">
          <Box mr={1} flex={1}>
            <CaptionStyled>USER INFO</CaptionStyled>
            <InfoText label="Name" value={user ? `${user?.surname}, ${user?.givenName}` : ''} />
            <InfoText label="NSAA ID" value={user?.username ?? ''} />
            <InfoText label="Email Address" value={user?.emailAddress ?? ''} />
            <InfoText label="Phone Number" value={user ? `${user?.areaCode}, ${user?.mobilePhone}` : ''} />
          </Box>
          <Box ml={1} mt={3} flex={1}>
            <InfoText
              valueWidth={'55%'}
              labelWidth={'40%'}
              label="Account Type"
              value={user?.accountType ? convertSlugToUpperCase(user.accountType) : ''}
            />
            <InfoText
              valueWidth={'55%'}
              labelWidth={'40%'}
              label="Account Create Date"
              value={user?.createdAt ? new Date(user.createdAt).toLocaleDateString('en-GB') : ''}
            />
            <InfoText
              valueWidth={'55%'}
              labelWidth={'40%'}
              label="Verification Date"
              value={user?.verifiedAt ? new Date(user.verifiedAt).toLocaleDateString('en-GB') : ''}
            />
            <InfoText
              valueWidth={'55%'}
              labelWidth={'40%'}
              label="Expire Date"
              value={user?.expirationDate ? new Date(user.expirationDate).toLocaleDateString('en-GB') : ''}
            />
            <InfoText
              valueWidth={'55%'}
              labelWidth={'40%'}
              label="Payment Date"
              value={user?.payment?.createdAt ? utcToDateTime(new Date(user.payment?.createdAt).toUTCString()) : ''}
            />
          </Box>
        </Box>
        <Box mt={2} display="flex">
          <Box mr={1} flex={1}>
            <CaptionStyled>ELIGIBILITY DETAIL</CaptionStyled>
            <Box display="flex" mt={2}>
              <Box width={'20%'}>
                <LabelStyled>Eligible Status</LabelStyled>
              </Box>

              <Box width={'75%'}>
                <ButtonStatus style={{ width: 120 }} variants={variants}>
                  {variants ? convertSlugToUpperCase(variants) : ''}
                </ButtonStatus>
              </Box>
            </Box>
            <Box display="flex" mt={2}>
              <Box width={'20%'}>
                <LabelStyled>Eligibility Criteria</LabelStyled>
              </Box>

              <Box width={'75%'}>
                <TypographyUserInfo display="inline">
                  &#8226; Verification Status:{' '}
                  {user?.verificationStatus ? capitalizeFirstLetter(user.verificationStatus) : ''}
                </TypographyUserInfo>
                <TypographyUserInfo>
                  &#8226; Volunteer Event: {volunteerEventUser}/{volunteerNeed}
                </TypographyUserInfo>

                {configs?.map((config) => {
                  const valueOfUser = user?.eligibility.find((i) => i.key === config.key);
                  return (
                    <TypographyUserInfo>
                      &#8226; {convertSlugToUpperCase(config.key)}: {valueOfUser?.value}/{config.value}
                    </TypographyUserInfo>
                  );
                })}
              </Box>
            </Box>
          </Box>
        </Box>
      </CustomBox>
      <Box m={'40px'}>
        <TypographyTitleStyled>Completed Event Detail</TypographyTitleStyled>
        <EventsCompleted events={user?.event} />
      </Box>
    </CustomFormDialog>
  );
};

export default EligibleUserInfo;
