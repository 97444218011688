import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import { CALL_SUCCESS } from 'src/constants/common';
import { IPagination } from 'src/services/users';
import { getVolunteerByEventId, ISearchVolunteer, IVolunteer } from 'src/services/volunteer';

import { defaultSearchVolunteer } from '../../types';

import Listing from './listing';
import SearchVolunteer from './search-volunteer';

interface IConfirmList {
  eventId: string;
}

const WaitList = ({ eventId }: IConfirmList) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<ISearchVolunteer>(defaultSearchVolunteer);
  const [volunteers, setVolunteers] = useState<IVolunteer[]>([]);
  const [data, setData] = useState<IPagination>();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  async function getData({
    search,
    currentPage,
    limit,
  }: {
    search?: ISearchVolunteer;
    currentPage: number;
    limit: number;
  }) {
    setLoading(true);
    const response = await getVolunteerByEventId({
      eventId: eventId,
      type: 'waitlist',
      search,
      currentPage,
      limit,
    });
    if (response?.statusCode === CALL_SUCCESS) {
      setVolunteers(response?.data?.items);
      setData(response?.data?.meta);
    }

    setLoading(false);
  }

  const _onSearch = (searchData: ISearchVolunteer) => () => {
    setSearch(searchData);
    getData({
      search: searchData,
      currentPage: 1,
      limit,
    });
    setCurrentPage(1);
  };

  const _onReset = async () => {
    setLoading(true);
    setSearch(defaultSearchVolunteer);
    setCurrentPage(1);
    const response = await getVolunteerByEventId({
      eventId: eventId,
      type: 'waitlist',
      search: defaultSearchVolunteer,
      currentPage: 1,
      limit,
    });
    if (response?.statusCode === CALL_SUCCESS) {
      setVolunteers(response?.data?.items);
      setData(response?.data?.meta);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData({
      search,
      currentPage,
      limit,
    });
  }, [currentPage, limit]);

  return (
    <Box>
      <SearchVolunteer searchData={search} loading={loading} _onSearch={_onSearch} _onReset={_onReset} />
      <Listing
        eventId={eventId}
        volunteers={volunteers}
        data={data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        limit={limit}
        setLimit={setLimit}
        getData={getData}
        search={search}
      />
    </Box>
  );
};

export default WaitList;
