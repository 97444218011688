import React, { useCallback, useEffect, useState } from 'react';

import { Box, Grid, Tooltip } from '@material-ui/core';
import moment from 'moment';

import { CALL_SUCCESS, FORMAT_DATE_DMY } from 'src/constants/common';
import DeleteIcon from 'src/medias/icons/delete-icon.png';
import { EventSwitch } from 'src/pages/event-management/shared/switch';
import { postCMSHomepage } from 'src/services/cms';
import { getPost, publicPost } from 'src/services/news';

import {
  BoxDetail,
  HeaderButton,
  HeaderRight,
  IconButtonStyled,
  ImageIconStyled,
  LabelDetail,
  TextDetail,
  TitleRight,
} from '../type';

import { IConfirmModal } from './NewsPostCMS';

interface IDataSource {
  key: number;
  id: string;
  Title: string;
  Type: string;
  StartDate: string;
  SetAsFeature: string;
}
interface IParams {
  action: string;
  events: string[];
  news: string[];
}
interface IRightSection {
  rightType: string | null | undefined;
  setRightType: any;
  postData: any;
  isEditMode: boolean;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  _onCreateDraftNew(): Promise<void>;
  _OnUpdateClick(): Promise<void>;
  _setPost?: any;
  _setMenuActive: React.Dispatch<React.SetStateAction<string>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  _setOpenConfirmModal: React.Dispatch<React.SetStateAction<IConfirmModal>>;
  getData: (id: string | number) => Promise<void>;
}
function RightSection({
  rightType,
  setRightType,
  postData,
  setIsEditMode,
  isEditMode,
  _onCreateDraftNew,
  _OnUpdateClick,
  _setMenuActive,
  _setPost,
  setLoading,
  setOpen,
  _setOpenConfirmModal,
  getData,
}: IRightSection) {
  const [isPublicPost, setIsPublicPost] = useState(false);
  useEffect(() => {
    if (rightType === 'detailNews' && postData?.status === 'public') {
      setIsPublicPost(true);
    }
  }, [postData]);
  const onDeletePost = () => {
    _setOpenConfirmModal({
      action: 'delete',
      open: true,
    });
  };
  const backToNews = () => {
    _setOpenConfirmModal({
      action: 'cancel',
      open: true,
    });
  };
  const cancelEdit = () => {
    _setOpenConfirmModal({
      action: 'cancel edit',
      open: true,
    });
  };
  const backToNewsWithNoAction = () => {
    setRightType('news');
    _setOpenConfirmModal({
      action: '',
      open: false,
    });
  };
  const changeEditMode = () => {
    setIsEditMode(!isEditMode);
  };
  const onUpdateNews = () => {
    _OnUpdateClick();
  };
  const previewPost = () => {
    setOpen(true);
    if (isEditMode) setIsEditMode(!isEditMode);
  };
  const saveAndPreviewPost = () => {
    _setOpenConfirmModal({
      action: 'update and preview',
      open: true,
    });
  };
  const UnpublishPost = async () => {
    setLoading(true);
    let params = {
      ids: [postData.id],
      action: 'unpublic',
    };
    const response = await publicPost(params);
    if (response.statusCode === 200) {
      const responseGetPost = await getPost(postData.id);
      if (responseGetPost && responseGetPost.statusCode === 200) {
        _setPost(responseGetPost.data);
        _setMenuActive('Create News');
        setRightType('detailNews');
        setIsPublicPost(false);
        setIsEditMode(false);
        setLoading(false);
      }
    }
    setLoading(false);
  };
  const setFeature = useCallback(
    (value: IDataSource, currentFeatureValue: boolean) => async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      setLoading(true);
      let params: IParams = {
        action: currentFeatureValue ? 'remove' : 'set',
        events: [],
        news: [value.id],
      };
      const response = await postCMSHomepage(params);
      if (response.statusCode === CALL_SUCCESS) {
        getData(value.id);
      }
    },
    [],
  );
  return (
    <Grid item xs={4}>
      <Box minHeight={200} p={3} bgcolor="white" boxShadow={'1px 1px 4px #9E9E9E'} borderRadius={4}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <TitleRight>News Setting</TitleRight>
          {rightType !== 'create' && (
            <Tooltip title="Delete">
              <IconButtonStyled>
                <ImageIconStyled src={DeleteIcon} onClick={onDeletePost} />
              </IconButtonStyled>
            </Tooltip>
          )}
        </Box>

        <BoxDetail display="flex" flexDirection="column" mb={3} mt={7}>
          {rightType !== 'create' && (
            <>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <LabelDetail>Status</LabelDetail>
                <TextDetail>{postData?.status === 'un_public' ? 'Unpublished' : 'Published'}</TextDetail>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <LabelDetail>Last Modified By</LabelDetail>
                <TextDetail>
                  {postData?.user ? `${postData?.user?.surname}, ${postData?.user?.givenName}` : '-'}
                </TextDetail>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <LabelDetail>Last Modified At</LabelDetail>
                <TextDetail>{moment(+postData?.updatedAt).format(FORMAT_DATE_DMY)}</TextDetail>
              </Box>
            </>
          )}
          {isPublicPost && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <LabelDetail>Pulish Time</LabelDetail>
              <TextDetail>{moment(+postData?.publishTime!).format(FORMAT_DATE_DMY)}</TextDetail>
            </Box>
          )}
          {isPublicPost && !isEditMode && (
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <LabelDetail>Feature News</LabelDetail>
              <TextDetail>
                {' '}
                <EventSwitch
                  checked={postData?.isFeatureItem === 'yes' ? true : false}
                  onClick={setFeature(postData, postData?.isFeatureItem === 'yes' ? true : false)}
                />
              </TextDetail>
            </Box>
          )}
        </BoxDetail>
        <HeaderRight>
          {isEditMode ? (
            rightType !== 'create' ? (
              <HeaderButton variants="delete" onClick={cancelEdit}>
                Cancel
              </HeaderButton>
            ) : rightType === 'create' ? (
              <HeaderButton variants="delete" onClick={backToNews}>
                Cancel
              </HeaderButton>
            ) : null
          ) : (
            <HeaderButton variants="delete" onClick={backToNewsWithNoAction}>
              Back
            </HeaderButton>
          )}

          {rightType === 'create' ? (
            <HeaderButton variants="save" onClick={_onCreateDraftNew}>
              Save
            </HeaderButton>
          ) : rightType === 'detailNews' ? (
            isEditMode ? (
              <HeaderButton variants="save" onClick={onUpdateNews}>
                Save
              </HeaderButton>
            ) : (
              <HeaderButton variants="save" onClick={changeEditMode}>
                Edit
              </HeaderButton>
            )
          ) : null}
          {isPublicPost ? (
            <HeaderButton variants="publish" onClick={UnpublishPost}>
              Unpublish
            </HeaderButton>
          ) : rightType === 'create' ? null : isEditMode ? (
            <HeaderButton variants="save & publish" onClick={saveAndPreviewPost}>
              Save & Preview
            </HeaderButton>
          ) : (
            <HeaderButton variants="save & publish" onClick={previewPost}>
              Preview
            </HeaderButton>
          )}
        </HeaderRight>
      </Box>
    </Grid>
  );
}

export default RightSection;
