import React from 'react';

import { Box, FormControl, FormControlLabel, Grid, makeStyles, RadioGroup, Typography } from '@material-ui/core';
import _ from 'lodash';

import { InputField, NASSRadio } from 'src/components';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { ISignUpReq } from 'src/services/auth';
import { customLabels } from 'src/utils/areaCode';
import { intRegex, emailIsValid } from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { CustomLabel, ReactFlagsSelectStyled, TextNote } from './types';

const MAXIMUM_PARENT_NAME_LENGTH = 50;
const MAXIMUM_PHONE_NUMBER_LENGTH = 15;
const TEXT_REQUIRE = 'This field is required';
const EMAIL_TEXT_CORRECT = 'Please fill in a correct email';
const PHONE_TEXT_REQUIRE = 'This field must be a number';

const useStyles = makeStyles((theme) => ({
  noticeRequire: {
    color: 'red',
    verticalAlign: 'middle',
    marginLeft: 4,
  },
}));

interface IParentInformation {
  data: ISignUpReq;
  setData: React.Dispatch<React.SetStateAction<ISignUpReq>>;
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
}

const ParentInformation = ({ data, setData, errors, setErrors }: IParentInformation) => {
  useScrollToTop();
  const { isTabletOrMobile } = useResponsive();
  const classes = useStyles();

  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [e.target.name]: e.target.value });
    setValidate(e.target.name, e.target.value);

    if (e.target.name === 'parentEmail') {
      setValidate('parentEmail', emailIsValid(e.target.value) ? e.target.value : '');
    }
    if (e.target.name === 'parentMobilePhone') {
      setValidate('parentMobilePhone', intRegex.test(e.target.value) ? e.target.value : '');
    }
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setData({ ...data, [e.target.name]: valueTrimmed });
  };

  const _handleChangeArea = (areaCode: string) => {
    setData({ ...data, parentAreaCode: areaCode });
  };

  return (
    <Box minHeight={'544px'}>
      <Box justifyContent="center" display="flex">
        <TextNote isTabletOrMobile={isTabletOrMobile}>Parent/Guardian Information</TextNote>
      </Box>
      <Grid container>
        <Grid item xs={isTabletOrMobile ? 12 : 6}>
          <Box flex={1} pr={isTabletOrMobile ? 0 : 1}>
            <InputField
              value={data.parentSurname}
              onChange={_handleChange}
              onBlur={onBlurField}
              require
              title="Surname"
              margin="dense"
              name="parentSurname"
              fullWidth
              InputProps={{
                inputProps: {
                  maxLength: MAXIMUM_PARENT_NAME_LENGTH,
                },
              }}
              error={errors.parentSurname}
              errormessage={errors.parentSurname && TEXT_REQUIRE}
            />
          </Box>
        </Grid>
        <Grid item xs={isTabletOrMobile ? 12 : 6}>
          <Box flex={1} pl={isTabletOrMobile ? 0 : 1}>
            <InputField
              value={data.parentGivenName}
              onChange={_handleChange}
              onBlur={onBlurField}
              require
              margin="dense"
              name="parentGivenName"
              title="Given Name"
              fullWidth
              InputProps={{
                inputProps: {
                  maxLength: MAXIMUM_PARENT_NAME_LENGTH,
                },
              }}
              error={errors.parentGivenName}
              errormessage={errors.parentGivenName && TEXT_REQUIRE}
            />
          </Box>
        </Grid>
      </Grid>
      <Box flex={1} mt={2} mb={1}>
        <CustomLabel>
          Relationship
          <Typography component="span" className={classes.noticeRequire}>
            *
          </Typography>
        </CustomLabel>
      </Box>
      <Box>
        <FormControl component="fieldset">
          <RadioGroup
            onChange={_handleChange}
            value={data.relationship}
            name="relationship"
            row={isTabletOrMobile ? false : true}
          >
            <FormControlLabel value="parent" control={<NASSRadio />} label="Parents" />
            <FormControlLabel value="guardian" control={<NASSRadio />} label="Guardian" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box>
        <InputField
          value={data.parentEmail}
          onChange={_handleChange}
          margin="dense"
          name="parentEmail"
          title="Email Address"
          require
          fullWidth
          InputProps={{
            inputProps: {
              maxLength: MAXIMUM_PARENT_NAME_LENGTH,
            },
          }}
          error={errors.parentEmail}
          errormessage={
            errors.parentEmail && data.parentEmail === '' ? TEXT_REQUIRE : errors.parentEmail && EMAIL_TEXT_CORRECT
          }
        />
      </Box>
      <Box display="flex">
        <Box width={isTabletOrMobile ? 130 : 145} mt={1}>
          <CustomLabel>
            Phone Number
            <Typography component="span" className={classes.noticeRequire}>
              *
            </Typography>
          </CustomLabel>

          <ReactFlagsSelectStyled
            fullWidth
            selected={data.parentAreaCode ? data.parentAreaCode : ''}
            onSelect={_handleChangeArea}
            searchable
            customLabels={customLabels}
            showSecondaryOptionLabel
            showSelectedLabel={false}
          />
        </Box>

        <Box flex={1} pl={2} mt={'18px'}>
          <InputField
            placeholder="Phone number (Including area code, if there is)"
            value={data.parentMobilePhone}
            onChange={_handleChange}
            onBlur={onBlurField}
            margin="dense"
            name="parentMobilePhone"
            fullWidth
            InputProps={{
              inputProps: {
                maxLength: MAXIMUM_PHONE_NUMBER_LENGTH,
              },
            }}
            error={errors.parentMobilePhone}
            errormessage={
              errors.parentMobilePhone && data.parentMobilePhone === ''
                ? TEXT_REQUIRE
                : errors.parentMobilePhone && PHONE_TEXT_REQUIRE
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ParentInformation;
