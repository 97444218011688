import React from 'react';

import { Box } from '@material-ui/core';

import { CustomButton } from 'src/components';
import SuccessIcon from 'src/medias/images/success-image-nsaa.svg';
import useResponsive from 'src/utils/responsive';

import { ContentStyled, ImageStyled } from '../types';

const Finish = ({ nsaa }: { nsaa?: boolean }) => {
  const { isTablet, isTabletOrMobile } = useResponsive();
  const _onSubmit = () => {
    window.location.href = '/';
  };
  return (
    <Box display="flex" alignItems="center" flex={1} flexDirection="column" mt={6} p={3}>
      <ImageStyled src={SuccessIcon} />
      <Box mt={4} mb={nsaa ? 0 : 4}>
        <ContentStyled style={{ textAlign: 'center' }}>
          We are truly grateful for the kindness and encouragement that you have shown to the school and our students.
          <br />
          On behalf of the beneficiaries, we would like to thank you for your generosity.
        </ContentStyled>
      </Box>
      {!nsaa && <ContentStyled>We will send you TER after we receive your donation</ContentStyled>}
      <Box
        mt={4}
        display="flex"
        justifyContent="center"
        width={'100%'}
        flex={1}
        alignItems={isTabletOrMobile ? 'flex-end' : 'flex-start'}
      >
        <CustomButton style={{ width: isTablet && '100%' }} onClick={_onSubmit}>
          Go back to Homepage
        </CustomButton>
      </Box>
    </Box>
  );
};

export default Finish;
