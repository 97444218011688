import { createAsyncThunk } from '@reduxjs/toolkit';

import { login } from '../../services/auth';

export const logInWithPasswordAction = createAsyncThunk(
  'authenticate/login',
  async (
    { username, password, recaptchaToken }: { username: string; password: string; recaptchaToken: string },
    { rejectWithValue, getState, dispatch },
  ) => {
    let tokenData;
    try {
      tokenData = await login(username, password, 'development');
    } catch (err) {
      console.log('err', err);
      return;
    }
    return tokenData;
  },
);
