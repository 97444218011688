import React from 'react';

import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

import AdditionalInformation from './components/additional-information.component';
import ChangePassword from './components/change-password.component';
import GeneralInformation from './components/general-information.component';
import MembershipStatus from './components/membership-status.component';
import PaymentHistory from './components/payment-history.component';
import ProfileLayout from './layout/profile.layout';
import ProfileTabs from './shared/profile.tabs';

const ProfileContainer = styled(Box)`
  ${({ theme }) => css`
    border-radius: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    background-color: white;
  `}
`;

const Profile = () => {
  const tabs = [
    { label: 'General Information', disabled: false },
    { label: 'Additional Information', disabled: false },
    { label: 'Payment History', disabled: false },
    { label: 'Membership Status', disabled: false },
    { label: 'Change Password', disabled: false },
  ];

  const panels = [GeneralInformation, AdditionalInformation, PaymentHistory, MembershipStatus, ChangePassword];
  return (
    <ProfileLayout>
      <ProfileContainer>
        <ProfileTabs panels={panels} tabs={tabs} />
      </ProfileContainer>
    </ProfileLayout>
  );
};

export default Profile;
