import { Box, MenuItem, Typography, Button } from '@material-ui/core';
import styled, { css } from 'styled-components';

import EventBackgroundSource from 'src/medias/images/background-signup.png';

export const SearchContainer = styled(Box)<{ responsive?: any }>`
  min-height: 100vh;
  ${({ theme, responsive }) => css`
    padding: 40px 80px;
    background-image: url(${EventBackgroundSource});
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    ${responsive?.isTabletOrMobile &&
    css`
      width: 1440px;
    `}
    ${responsive?.isDesktopOrLaptop &&
    css`
      width: 100%;
    `}
  `}
`;

export const Heading = styled(Typography)`
  ${({ theme }) => css`
    margin-bottom: 40px;
    font-size: ${theme.fonts.header3.fontSize};
    font-weight: ${theme.fonts.header3.fontWeight};
    line-height: ${theme.fonts.header3.lineHeight};
  `}
`;

export const SearchSection = styled(Box)`
  ${({ theme }) => css`
    padding: 25px 16px;
    margin-bottom: 34px;
    display: flex;
    gap: 26px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: white;
  `}
`;

export const SearchItem = styled(Box)`
  flex-grow: 1;
  flex-basis: 300px;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
`;

export const SearchButton = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 24px;
  justify-content: flex-end;
`;

export const ButtonReset = styled(Button)`
  ${({ theme }) => css`
    width: 120px;
    height: 45px;
    box-sizing: border-box;
    border: 1px solid ${theme.palette.yellow?.[900]};
    border-radius: 6px;
    color: ${theme.palette.yellow?.[900]};
    font-size: 16px;
    text-transform: capitalize;
  `}
`;
export const ButtonSearch = styled(Button)`
  ${({ theme }) => css`
    width: 120px;
    height: 44px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: ${theme.palette.yellow?.[900]};
    font-size: 16px;
    color: white;
    text-transform: capitalize;
    &:hover {
      background-color: ${theme.palette.yellow?.[900]};
    }
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    margin-bottom: 4px;
    font-size: ${theme.fonts.header6.fontSize};
    font-weight: ${theme.fonts.header6.fontWeight};
    line-height: ${theme.fonts.header6.lineHeight};
    color: rgba(0, 0, 0, 0.6);
  `}
`;
export const MenuItemStyled = styled(MenuItem)`
  ${({ theme }) => css`
    color: ${theme.palette.gray?.[1000]};
  `}
`;

export const ListingSection = styled(Box)`
  ${({ theme }) => css`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: white;
  `}
`;

export const ListingCreate = styled(Box)`
  display: flex;
  justify-content: space-between;
  height: 93px;
  box-sizing: border-box;
  align-items: center;
  padding: 16px;
`;

export const ListingTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header4.fontSize};
    font-weight: ${theme.fonts.header4.fontWeight};
  `}
`;

export const ButtonCreate = styled(Button)`
  ${({ theme }) => css`
    .MuiButton-label {
      display: flex;
      gap: 10px;
    }
    width: 120px;
    height: 44px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: ${theme.palette.yellow?.[900]};
    font-size: 16px;
    color: white;
    text-transform: capitalize;
    &:hover {
      background-color: ${theme.palette.yellow?.[900]};
    }
  `}
`;

export const ButtonStatus = styled(Button)<{ variants?: 'completed' | 'on-going' | 'pending' | 'cancelled' | 'draft' }>`
  ${({ theme, variants }) => css`
    cursor: auto;
    width: 112px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: ${variants === 'completed'
      ? '#0FA958'
      : variants === 'on-going'
      ? '#FFB800'
      : variants === 'pending'
      ? '#EA7D18'
      : variants === 'cancelled'
      ? '#DC1A1A'
      : variants === 'draft'
      ? '#718096'
      : null};
    font-size: 13px;
    color: white;
    text-transform: capitalize;
    &:hover {
      background-color: ${variants === 'completed'
        ? '#0FA958'
        : variants === 'on-going'
        ? '#FFB800'
        : variants === 'pending'
        ? '#EA7D18'
        : variants === 'cancelled'
        ? '#DC1A1A'
        : variants === 'draft'
        ? '#718096'
        : null};
    }
  `}
`;

export const SearchPagination = styled(Box)`
  ${({ theme }) => css`
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px 24px;
  `}
`;

export const Actions = styled(Box)`
  display: flex;
  gap: 5px;
  justify-content: center;
`;

export const ButtonDuplicate = styled(Box)`
  ${({ theme }) => css`
    width: 32px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${theme.palette.yellow?.[900]};
    flex-shrink: 0;
  `}
`;
export const ButtonEdit = styled(Box)`
  ${({ theme }) => css`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.yellow?.[900]};
    border-radius: 6px;
    cursor: pointer;
    flex-shrink: 0;
  `}
`;
export const ButtonDelete = styled(Box)`
  ${({ theme }) => css`
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.palette.red?.[250]};
    border-radius: 6px;
    cursor: pointer;
    flex-shrink: 0;
  `}
`;
