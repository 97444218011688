import React from 'react';

import { Box } from '@material-ui/core';

import { CheckboxCustom } from 'src/components';

import { CustomLabelCheckBox } from './types';

interface ISkills {
  item: {
    label: string;
    name: string;
    checked: boolean | undefined;
  };
  toggleCheckboxValue: (item: string) => void;
}

export const Skills = ({ item, toggleCheckboxValue }: ISkills) => {
  return (
    <Box mt={1} key={item.name} display="flex" alignItems="center">
      <Box display="flex" flexDirection="row" flex={1} pr={4}>
        <CheckboxCustom
          id={item.name}
          name={item.name}
          checked={item.checked}
          onChange={() => toggleCheckboxValue(item.name)}
        />
        <CustomLabelCheckBox>{item.label}</CustomLabelCheckBox>
      </Box>
    </Box>
  );
};
