import React, { useCallback } from 'react';

import { Route, Switch, useRouteMatch, Redirect } from 'react-router-dom';

import Committee from './components/committee';
import Constitution from './components/constitution';
import History from './components/history';
import Nsaa from './components/nsaa';

export const AboutUs: React.FC<{}> = () => {
  const { path } = useRouteMatch();
  const redirectToDefaultAboutUs = useCallback(() => {
    return <Redirect to={`${path}/nsaa`} />;
  }, [path]);

  const renderAboutRoutes = () => {
    return (
      <Switch>
        <Route exact path={`${path}`} render={redirectToDefaultAboutUs} />
        <Route exact path={`${path}/nsaa`} component={Nsaa} />
        <Route exact path={`${path}/history`} component={History} />
        <Route exact path={`${path}/committee`} component={Committee} />
        <Route exact path={`${path}/constitution`} component={Constitution} />
      </Switch>
    );
  };

  return renderAboutRoutes();
};
