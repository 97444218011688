export const countries = {
  AF: '+93',
  AL: '+355',
  DZ: '+213',
  AS: '+684',
  AD: '+376',
  AO: '+244',
  AI: '+1-264',
  AG: '+1-268',
  AR: '+54',
  AM: '+374',
  AW: '+297',
  AU: '+61',
  AT: '+43',
  AZ: '+994',
  BS: '+1-242',
  BH: '+973',
  BD: '+880',
  BB: '+1-246',
  BY: '+375',
  BE: '+32',
  BZ: '+501',
  BJ: '+229',
  BM: '+1-441',
  BT: '+975',
  BO: '+591',
  BA: '+387',
  BW: '+267',
  BR: '+55',
  IO: '+246',
  BG: '+359',
  BF: '+226',
  BI: '+257',
  KH: '+855',
  CM: '+237',
  CA: '+1',
  CV: '+238',
  KY: '+1-345',
  CF: '+236',
  TD: '+235',
  CL: '56',
  CN: '+86',
  CO: '+57',
  KM: '+269',
  CG: '+243',
  CD: '+243',
  CK: '+682',
  CR: '+506',
  CI: '+225',
  HR: '+385',
  CU: '+53',
  CW: '+599',
  CY: '+357',
  CZ: '+420',
  DK: '+45',
  DJ: '+253',
  DM: '+1-767',
  DO: '+1',
  EC: '+593',
  EG: '+20',
  SV: '+503',
  GQ: '+240',
  ER: '+291',
  EE: '+372',
  ET: '+251',
  FK: '+500',
  FO: '+298',
  FJ: '+679',
  FI: '+358',
  FR: '+33',
  PF: '+689',
  GA: '+241',
  GM: '+220',
  GE: '+995',
  DE: '+49',
  GH: '+233',
  GI: '+350',
  GR: '+30',
  GL: '+299',
  GD: '+1-473',
  GU: '+1-671',
  GT: '+502',
  GG: '+44',
  GN: '+224',
  GW: '+245',
  HT: '+509',
  HN: '+504',
  HK: '+852',
  HU: '+36',
  IS: '+354',
  IN: '+91',
  ID: '+62',
  IR: '+98',
  IQ: '+964',
  IE: '+353',
  IM: '+44',
  IL: '+972',
  IT: '+39',
  JM: '+1-876',
  JP: '+81',
  JE: 'Jersey',
  JO: '+962',
  KZ: '+7',
  KE: '+254',
  KI: '+686',
  KP: '+850',
  KR: '+82',
  KW: '+965',
  KG: '+996',
  LA: '+856',
  LV: '+372',
  LB: '+961',
  LS: '+266',
  LR: '+231',
  LY: '+218',
  LI: '+423',
  LT: '+370',
  LU: '+352',
  MO: '+853',
  MK: '+389',
  MG: '+261',
  MW: '+265',
  MY: '+60',
  MV: '+960',
  ML: '+223',
  MT: '+356',
  MH: '+692',
  MQ: '+596',
  MR: '+222',
  MU: '+230',
  MX: '+52',
  FM: '+691',
  MD: '+373',
  MC: '+377',
  MN: '+976',
  ME: '+382',
  MS: '+1-664',
  MA: '+212',
  MZ: '+258',
  MM: '+95',
  NA: '+264',
  NR: '+674',
  NP: '+977',
  NL: '+31',
  NZ: '+64',
  NI: '+505',
  NE: '+227',
  NG: '+234',
  NU: '+683',
  NF: '+672',
  MP: '+1-670',
  NO: '+47',
  OM: '+968',
  PK: '+92',
  PW: '+680',
  PS: '+970',
  PA: '+507',
  PG: '+675',
  PY: '+595',
  PE: '+51',
  PH: '+63',
  PN: '+64',
  PL: '+48',
  PT: '+351',
  PR: '+1',
  QA: '+974',
  RO: '+40',
  RU: '+7',
  RW: '+250',
  KN: '+1-869',
  LC: '+1-758',
  WS: '+685',
  SM: '+378',
  ST: '+239',
  SA: '+966',
  SN: '+221',
  RS: '+381',
  SC: '+248',
  SL: '+232',
  SG: '+65',
  SX: '+1-721',
  SK: '+421',
  SI: '+386',
  SB: '+677',
  SO: '+252',
  ZA: '+27',
  SS: '+211',
  ES: '+34',
  LK: '+94',
  SD: '+249',
  SR: '+597',
  SZ: '+268',
  SE: '+46',
  CH: '+41',
  SY: '+963',
  TW: '+886',
  TJ: '+992',
  TZ: '+255',
  TH: '+66',
  TG: '+228',
  TK: '+690',
  TO: '+676',
  TT: '+1-868',
  TN: '+216',
  TR: '+90',
  TM: '+993',
  TC: '+1-649',
  TV: '+688',
  UG: '+256',
  UA: '+380',
  AE: '+971',
  GB: '+44',
  US: '+1',
  UY: '+598',
  UZ: '+998',
  VU: '+678',
  VE: '+58',
  VN: '+84',
  VI: '+1-340',
  YE: '+967',
  ZM: '+260',
  ZW: '+263',
};

export const customLabels: {
  [key: string]: { primary: string; secondary: string };
} = {
  US: { primary: 'EN-US', secondary: '+1' },
  GB: { primary: 'EN-GB', secondary: '+44' },
  AF: { primary: 'AF', secondary: '+93' },
  AL: { primary: 'AL', secondary: '+355' },
  DZ: { primary: 'DZ', secondary: '+213' },
  AS: { primary: 'AS', secondary: '+684' },
  AD: { primary: 'AD', secondary: '+376' },
  AO: { primary: 'AO', secondary: '+244' },
  AI: { primary: 'AI', secondary: '+1-264' },
  AG: { primary: 'AG', secondary: '+1-268' },
  AR: { primary: 'AR', secondary: '+54' },
  AM: { primary: 'AM', secondary: '+374' },
  AW: { primary: 'AW', secondary: '+297' },
  AU: { primary: 'AU', secondary: '+61' },
  AT: { primary: 'AT', secondary: '+43' },
  AZ: { primary: 'AZ', secondary: '+994' },
  BS: { primary: 'BS', secondary: '+1-242' },
  BH: { primary: 'BH', secondary: '+973' },
  BD: { primary: 'BD', secondary: '+880' },
  BB: { primary: 'BB', secondary: '+1-246' },
  BY: { primary: 'BY', secondary: '+375' },
  BE: { primary: 'BE', secondary: '+32' },
  BZ: { primary: 'BZ', secondary: '+501' },
  BJ: { primary: 'BJ', secondary: '+229' },
  BM: { primary: 'BM', secondary: '+1-441' },
  BT: { primary: 'BT', secondary: '+975' },
  BO: { primary: 'BO', secondary: '+591' },
  BA: { primary: 'BA', secondary: '+387' },
  BW: { primary: 'BW', secondary: '+267' },
  BR: { primary: 'BR', secondary: '+55' },
  IO: { primary: 'IO', secondary: '+246' },
  BG: { primary: 'BG', secondary: '+359' },
  BF: { primary: 'BF', secondary: '+226' },
  BI: { primary: 'BI', secondary: '+257' },
  KH: { primary: 'KH', secondary: '+855' },
  CM: { primary: 'CM', secondary: '+237' },
  CA: { primary: 'CA', secondary: '+1' },
  CV: { primary: 'CV', secondary: '+238' },
  KY: { primary: 'KY', secondary: '+1-345' },
  CF: { primary: 'CF', secondary: '+236' },
  TD: { primary: 'TD', secondary: '+235' },
  CL: { primary: 'CL', secondary: '+56' },
  CN: { primary: 'CN', secondary: '+86' },
  CO: { primary: 'CO', secondary: '+57' },
  KM: { primary: 'KM', secondary: '+269' },
  CG: { primary: 'CG', secondary: '+243' },
  CD: { primary: 'CD', secondary: '+243' },
  CK: { primary: 'CK', secondary: '+682' },
  CR: { primary: 'CR', secondary: '+506' },
  CI: { primary: 'CI', secondary: '+225' },
  HR: { primary: 'HR', secondary: '+385' },
  CU: { primary: 'CU', secondary: '+53' },
  CW: { primary: 'CW', secondary: '+599' },
  CY: { primary: 'CY', secondary: '+357' },
  CZ: { primary: 'CZ', secondary: '+420' },
  DK: { primary: 'DK', secondary: '+45' },
  DJ: { primary: 'DJ', secondary: '+253' },
  DM: { primary: 'DM', secondary: '+1-767' },
  DO: { primary: 'DO', secondary: '+1' },
  EC: { primary: 'EC', secondary: '+593' },
  EG: { primary: 'EG', secondary: '+20' },
  SV: { primary: 'SV', secondary: '+503' },
  GQ: { primary: 'GQ', secondary: '+240' },
  ER: { primary: 'ER', secondary: '+291' },
  EE: { primary: 'EE', secondary: '+372' },
  ET: { primary: 'ET', secondary: '+251' },
  FK: { primary: 'FK', secondary: '+500' },
  FO: { primary: 'FO', secondary: '+298' },
  FJ: { primary: 'FJ', secondary: '+679' },
  FI: { primary: 'FI', secondary: '+358' },
  FR: { primary: 'FR', secondary: '+33' },
  PF: { primary: 'PF', secondary: '+689' },
  GA: { primary: 'GA', secondary: '+241' },
  GM: { primary: 'GM', secondary: '+220' },
  GE: { primary: 'GE', secondary: '+995' },
  DE: { primary: 'DE', secondary: '+49' },
  GH: { primary: 'GH', secondary: '+233' },
  GI: { primary: 'GI', secondary: '+350' },
  GR: { primary: 'GR', secondary: '+30' },
  GL: { primary: 'GL', secondary: '+299' },
  GD: { primary: 'GD', secondary: '+1-473' },
  GU: { primary: 'GU', secondary: '+1+671' },
  GT: { primary: 'GT', secondary: '+502' },
  GG: { primary: 'GG', secondary: '+44' },
  GN: { primary: 'GN', secondary: '+244' },
  GW: { primary: 'GW', secondary: '+245' },
  HT: { primary: 'HT', secondary: '+509' },
  HN: { primary: 'HN', secondary: '+504' },
  HK: { primary: 'HK', secondary: '+852' },
  HU: { primary: 'HU', secondary: '+36' },
  IS: { primary: 'IS', secondary: '+354' },
  IN: { primary: 'IN', secondary: '+91' },
  ID: { primary: 'ID', secondary: '+62' },
  IR: { primary: 'IR', secondary: '+98' },
  IQ: { primary: 'IQ', secondary: '+964' },
  IE: { primary: 'IE', secondary: '+353' },
  IM: { primary: 'IM', secondary: '+44' },
  IL: { primary: 'IL', secondary: '+972' },
  IT: { primary: 'IT', secondary: '+39' },
  JM: { primary: 'JM', secondary: '+1-876' },
  JP: { primary: 'JP', secondary: '+81' },
  JE: { primary: 'JE', secondary: '+44-1534' },
  JO: { primary: 'JO', secondary: '+962' },
  KZ: { primary: 'KZ', secondary: '+7' },
  KE: { primary: 'KE', secondary: '+254' },
  KI: { primary: 'KI', secondary: '+686' },
  KP: { primary: 'KP', secondary: '+850' },
  KR: { primary: 'KR', secondary: '+82' },
  KW: { primary: 'KW', secondary: '+965' },
  KG: { primary: 'KG', secondary: '+966' },
  LA: { primary: 'LA', secondary: '+856' },
  LV: { primary: 'LV', secondary: '+372' },
  LB: { primary: 'LB', secondary: '+961' },
  LS: { primary: 'LS', secondary: '+266' },
  LR: { primary: 'LR', secondary: '+231' },
  LY: { primary: 'LY', secondary: '+218' },
  LI: { primary: 'LI', secondary: '+423' },
  LT: { primary: 'LT', secondary: '+370' },
  LU: { primary: 'LU', secondary: '+352' },
  MO: { primary: 'MO', secondary: '+853' },
  MK: { primary: 'MK', secondary: '+389' },
  MG: { primary: 'MG', secondary: '+261' },
  MW: { primary: 'MW', secondary: '+265' },
  MY: { primary: 'MY', secondary: '+60' },
  MV: { primary: 'MV', secondary: '+960' },
  ML: { primary: 'ML', secondary: '+223' },
  MT: { primary: 'MT', secondary: '+356' },
  MH: { primary: 'MH', secondary: '+692' },
  MQ: { primary: 'MQ', secondary: '+596' },
  MR: { primary: 'MR', secondary: '+222' },
  MU: { primary: 'MU', secondary: '+230' },
  MX: { primary: 'MX', secondary: '+52' },
  FM: { primary: 'FM', secondary: '+691' },
  MD: { primary: 'MD', secondary: '+373' },
  MC: { primary: 'MC', secondary: '+377' },
  MN: { primary: 'MN', secondary: '+976' },
  ME: { primary: 'ME', secondary: '+382' },
  MS: { primary: 'MS', secondary: '+1-664' },
  MA: { primary: 'MA', secondary: '+212' },
  MZ: { primary: 'MZ', secondary: '+258' },
  MM: { primary: 'MM', secondary: '+95' },
  NA: { primary: 'NA', secondary: '+264' },
  NR: { primary: 'NR', secondary: '+674' },
  NP: { primary: 'NP', secondary: '+977' },
  NL: { primary: 'NL', secondary: '+31' },
  NZ: { primary: 'NZ', secondary: '+64' },
  NI: { primary: 'NI', secondary: '+505' },
  NE: { primary: 'NE', secondary: '+227' },
  NG: { primary: 'NG', secondary: '+234' },
  NU: { primary: 'NU', secondary: '+683' },
  NF: { primary: 'NF', secondary: '+672' },
  MP: { primary: 'MP', secondary: '+1-670' },
  NO: { primary: 'NO', secondary: '+47' },
  OM: { primary: 'OM', secondary: '+968' },
  PK: { primary: 'PK', secondary: '+92' },
  PW: { primary: 'PW', secondary: '+680' },
  PS: { primary: 'PS', secondary: '+970' },
  PA: { primary: 'PA', secondary: '+507' },
  PG: { primary: 'PG', secondary: '+675' },
  PY: { primary: 'PY', secondary: '+595' },
  PE: { primary: 'PE', secondary: '+51' },
  PH: { primary: 'PH', secondary: '+63' },
  PN: { primary: 'PN', secondary: '+64' },
  PL: { primary: 'PL', secondary: '+48' },
  PT: { primary: 'PT', secondary: '+351' },
  PR: { primary: 'PR', secondary: '+1' },
  QA: { primary: 'QA', secondary: '+974' },
  RO: { primary: 'RO', secondary: '+40' },
  RU: { primary: 'RU', secondary: '+7' },
  RW: { primary: 'RW', secondary: '+250' },
  KN: { primary: 'KN', secondary: '+1-869' },
  LC: { primary: 'LC', secondary: '+1-758' },
  WS: { primary: 'WS', secondary: '+685' },
  SM: { primary: 'SM', secondary: '+378' },
  ST: { primary: 'ST', secondary: '+239' },
  SA: { primary: 'SA', secondary: '+966' },
  SN: { primary: 'SN', secondary: '+221' },
  RS: { primary: 'RS', secondary: '+381' },
  SC: { primary: 'SC', secondary: '+248' },
  SL: { primary: 'SL', secondary: '+232' },
  SG: { primary: 'SG', secondary: '+65' },
  SX: { primary: 'SX', secondary: '+721' },
  SK: { primary: 'SK', secondary: '+421' },
  SI: { primary: 'SI', secondary: '+386' },
  SB: { primary: 'SB', secondary: '+677' },
  SO: { primary: 'SO', secondary: '+252' },
  ZA: { primary: 'ZA', secondary: '+27' },
  SS: { primary: 'SS', secondary: '+211' },
  ES: { primary: 'ES', secondary: '+34' },
  LK: { primary: 'LK', secondary: '+94' },
  SD: { primary: 'SD', secondary: '+249' },
  SR: { primary: 'SR', secondary: '+597' },
  SZ: { primary: 'SZ', secondary: '+268' },
  SE: { primary: 'SE', secondary: '+46' },
  CH: { primary: 'CH', secondary: '+41' },
  SY: { primary: 'SY', secondary: '+963' },
  TW: { primary: 'TW', secondary: '+886' },
  TJ: { primary: 'TJ', secondary: '+992' },
  TZ: { primary: 'TZ', secondary: '+255' },
  TH: { primary: 'TH', secondary: '+66' },
  TG: { primary: 'TG', secondary: '+228' },
  TK: { primary: 'TK', secondary: '+690' },
  TO: { primary: 'TO', secondary: '+676' },
  TT: { primary: 'TT', secondary: '+1-868' },
  TN: { primary: 'TN', secondary: '+216' },
  TR: { primary: 'TR', secondary: '+90' },
  TM: { primary: 'TM', secondary: '+993' },
  TC: { primary: 'TC', secondary: '+1-649' },
  TV: { primary: 'TV', secondary: '+688' },
  UG: { primary: 'UG', secondary: '+256' },
  UA: { primary: 'UA', secondary: '+380' },
  AE: { primary: 'AE', secondary: '+971' },
  UY: { primary: 'UY', secondary: '+598' },
  UZ: { primary: 'UZ', secondary: '+998' },
  VU: { primary: 'VU', secondary: '+678' },
  VE: { primary: 'VE', secondary: '+58' },
  VN: { primary: 'VN', secondary: '+84' },
  VI: { primary: 'VI', secondary: '+1-340' },
  YE: { primary: 'YE', secondary: '+967' },
  ZM: { primary: 'ZM', secondary: '+260' },
  ZW: { primary: 'ZW', secondary: '+263' },
};

export const getAreaCode = (country: any) => {
  let result: string = '+65';
  // eslint-disable-next-line array-callback-return
  Object.keys(customLabels).find((key) => {
    if (customLabels[key].primary === country) {
      result = customLabels[key].secondary;
    }
  });

  return result;
};

export const getAreaKey = (country: any) => {
  let result: string = 'SG';
  // eslint-disable-next-line array-callback-return
  Object.keys(customLabels).find((key) => {
    if (customLabels[key].secondary === country) {
      result = customLabels[key].primary;
    }
  });

  return result;
};
