import { Box, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { IResponsive } from 'src/pages/home/homepageStyled';

export const Container = styled(Box)`
  && {
    max-width: 1200px;
    display: flex;
    align-items: stretch;
    margin: 32px auto 64px;
    gap: 24px;
  }
`;
export const BoxItem = styled(Box)`
  && {
    gap: 24px;
  }
`;
export const ImgCustom = styled.img`
  && {
    width: 100%;
    object-fit: contain;
    flex-grow: 1;
  }
`;
export const Description = styled(Typography)(
  ({ theme }) => css`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    ${{
      ...theme.fonts.textS,
      marginTop: 12,
      wordWrap: 'break-word',
      color: theme.palette.gray?.[60],
    }}
  `,
);
export const BoxContent = styled(Box)<{ responsive?: IResponsive }>(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 250px;
    background-color: #f9f9f4;
    width: 100%;
  `,
);
export const TextTime = styled(Typography)(
  ({ theme }) => css`
    ${{
      ...theme.fonts.textSs,
      textAlign: 'left',
      position: 'static',
      color: theme.palette.yellow?.[1000],
      marginBottom: 12,
    }}
  `,
);
export const NavLinkCustom = styled(NavLink)(({ theme }) => ({
  ...theme.fonts.header3,
  textDecoration: 'none',
  color: '#000000',
  position: 'relative',
}));
export const Title = styled(Typography)(
  ({ theme }) =>
    css`
      ${{
        ...theme.fonts.header5,
        height: '60px',
      }}
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `,
);
export const ThumbnailPost = styled.img`
  && {
    width: 300px;
    min-height: 250px;
    height: 250px;
    object-fit: contain;
    object-position: left bottom;
  }
`;
