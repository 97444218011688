import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { IResponsive } from 'src/pages/home/homepageStyled';

export const ProfilePopupLayoutContainer = styled(Box)<{ responsive?: IResponsive; notExpanded?: boolean }>`
  ${({ theme, responsive, notExpanded = false }) => css`
    ${responsive?.isMobile &&
    css`
      min-width: 1440px;
    `}
    ${notExpanded &&
    css`
      min-width: auto;
    `}
  `}
`;
