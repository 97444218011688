import { useLayoutEffect } from 'react';

import useFetchMe from './use-fetch-me';

const useRoleAccess = (role: string[]) => {
  const { me, loading } = useFetchMe();
  useLayoutEffect(() => {
    if (me && !loading) {
      const isAllowAccess = role.includes(me.roleName!);
      if (!isAllowAccess) {
        window.location.pathname = '/not-access';
      }
    }
  }, [me, loading, role]);

  return { loading } as const;
};

export default useRoleAccess;
