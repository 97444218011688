import { Box, Typography } from '@material-ui/core';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import styled, { css } from 'styled-components';

export const GeneralContainer = styled(Box)`
  ${() => css`
    background-color: white;
    border-radius: 8px;
    padding: 24px;
  `}
`;

export const FormLabel = styled(Typography)<{ required?: boolean }>`
  ${({ theme, required }) => css`
    font-size: ${theme.fonts.header6.fontSize};
    font-weight: ${theme.fonts.header6.fontWeight};
    line-height: ${theme.fonts.header6.lineHeight};
    margin-bottom: 4px;

    ${required &&
    css`
      &::after {
        content: '*';
        color: ${theme.palette.red?.[250]};
        display: inline-block;
        margin-left: 5px;
      }
    `}
  `}
`;

export const KeyboardTimePickerStyled = styled(KeyboardTimePicker)`
  .MuiOutlinedInput-adornedEnd {
    padding: 0;
  }
`;
export const KeyboardDatePickerStyled = styled(KeyboardDatePicker)`
  .MuiOutlinedInput-adornedEnd {
    padding: 0;
  }
`;

export const ImagePreview = styled.img`
  width: 300px;
  height: 180px;
  object-fit: contain;
  object-position: center;
  border-radius: 8px;
`;

export const PlaceHolderPreviewImage = styled(Box)`
  ${({ theme }) => css`
    width: 300px;
    height: 180px;
    border-radius: 8px;
    border: 1px solid ${theme.palette.yellow?.[900]};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

export const PlaceHolderImageTitle = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.yellow?.[900]};
  `}
`;

export const ShortDescription = styled.textarea<{ disable?: boolean }>`
  width: 100%;
  height: 92px;
  font-family: 'Roboto';
  border-radius: 6px;
  border: 1px solid #0000001a;
  resize: none;
  padding: 10px 16px;
  color: rgba(113, 128, 150, 1);
  font-size: 16px;
  font-weight: 400;

  ${({ disable }) => css`
    background-color: ${disable ? '#0000001A' : ''};
    user-select: ${disable ? 'none' : ''};
    pointer-events: ${disable ? 'none' : ''};
  `}

  &:focus {
    outline: none !important;
    border: 1px solid #0000001a;
  }
`;

export const LabelUpload = styled.label`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

export const PreviewImage = styled(Box)<{ disabled?: boolean; src?: string; error?: boolean }>`
  ${({ theme, disabled, src, error }) => css`
    position: relative;
    width: 300px;
    height: 180px;
    border-radius: 8px;
    border: 1px solid ${disabled ? '#7180961A' : theme.palette.yellow?.[900]};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
    ${disabled &&
    css`
      pointer-events: none;
      * {
        color: red;
      }
    `}
    ${src &&
    css`
      background-image: url(${src});
      background-size: cover;
      background-position: center;
    `}
    ${error &&
    css`
      border: 1px solid red;
    `}
  `}
`;
export const UploadText = styled(Typography)<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    font-size: 16px;
    font-weight: 400;
    color: ${theme.palette.yellow?.[900]};
    ${disabled &&
    css`
      color: #0000001a;
    `}
  `}
`;

export const GeneralInformationContainer = styled(Box)`
  border-radius: 16px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 1px 1px 4px #9e9e9e;
  border-radius: 4px;
  max-width: 100%;
`;
export const ErrorMessage = styled(Box)`
  color: #f44336;
  font-size: 12px;
`;
