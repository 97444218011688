import queryString from 'query-string';

import httpClient from 'src/configs/http-client';
import { IResponse, IResponseList } from 'src/types';

import { ISearchGuest } from './guests.interface';

import { IGuest, IRegistrationResponse } from '.';

export const getGuestListByEventId = async (
  id: string,
  search: ISearchGuest = { limit: 10, page: 1, name: '', status: 'all' },
) => {
  const query = queryString.stringify(search);
  const response = await httpClient.get<IResponseList<IGuest>>(
    `${process.env.REACT_APP_API_URL}/event/${id}/guest-list?${query}`,
  );
  return response?.data;
};

export const getGuestsByRegistrationId = async (registrationId: string) => {
  const response = await httpClient.get<IResponse<IRegistrationResponse>>(
    `${process.env.REACT_APP_API_URL}/users-event/${registrationId}`,
  );
  return response?.data;
};
