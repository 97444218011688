import * as React from 'react';

import { Box, Button, Menu, MenuItem, MenuProps, Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { IMenu } from 'src/layouts/main/type';
import DefaultAvatar from 'src/medias/images/default_avatar.png';
import { IUser } from 'src/services/users';

interface IBasicMenuProps {
  text?: string;
  active?: boolean;
  link?: string;
  extend?: boolean;
  logout?: () => void;
  menuItem?: IMenu[] | null;
  userProfile?: IUser | undefined;
}

export default function BasicMenu(props: IBasicMenuProps) {
  const { text, active, userProfile, menuItem, logout } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  function handleClose() {
    setAnchorEl(null);
  }

  function handleLogout() {
    logout?.();
    handleClose();
  }
  function handleAnchorEl(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  return (
    <Box height={'100%'}>
      {text && (
        <MenuButtonLink id="basic-button" aria-controls="basic-menu" aria-haspopup="true" active={active}>
          {text}
        </MenuButtonLink>
      )}

      {menuItem && (
        <>
          <Box
            onClick={handleAnchorEl}
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            {userProfile && (
              <UserBox display="flex" alignItems="center">
                <UserName>
                  Hello {userProfile.surname}, {userProfile.givenName}
                </UserName>
                <Avatar imageUrl={userProfile?.avatar || DefaultAvatar} />
              </UserBox>
            )}
          </Box>
          <StyledMenu id="basic-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {menuItem.map((item: any, idx: number) =>
              item.logout ? (
                <MenuItemStyled key={idx} onClick={handleLogout}>
                  <LinkStyled to={'/'}>{item.title}</LinkStyled>
                </MenuItemStyled>
              ) : (
                <MenuItemStyled key={idx} onClick={handleClose}>
                  <LinkStyled to={item.link}>{item.title}</LinkStyled>
                </MenuItemStyled>
              ),
            )}
          </StyledMenu>
        </>
      )}
    </Box>
  );
}

export const Avatar = styled(Box)<{ imageUrl: string }>`
  ${({ theme, imageUrl }) => css`
    width: 38px;
    height: 38px;
    border-radius: 999999px;
    background-image: url(${imageUrl});
    background-size: cover;
    background-position: center;
  `}
`;

const UserName = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textSs,
  color: 'rgba(51, 51, 51, 1)',
  marginRight: 24,
}));

const UserBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
}));

const MenuButtonLink = styled(Button)<{ active?: boolean }>`
  ${({ theme, active }) => css`
    font-size: ${theme.fonts.textSBold.fontSize};
    font-weight: ${theme.fonts.textSBold.fontWeight};
    line-height: ${theme.fonts.textSBold.lineHeight};
    color: ${active ? theme.palette.yellow?.[900] : theme.palette.common.black};
    text-decoration: none;
    cursor: pointer;
    height: 100%;
    margin-left: 10px;
  `}
`;

const StyledMenu = withStyles({
  paper: {
    borderRadius: 0,
    boxShadow: '0px 3px 20px rgb(0 0 0 / 15%)',
    marginTop: '16px',
  },
  list: {
    padding: 0,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

const MenuItemStyled = styled(MenuItem)`
  ${({ theme }) => css`
    min-width: 160px;
    padding: 0;
    &&:hover {
      background-color: ${theme.palette.yellow?.[900]};
      a {
        color: white;
      }
    }
  `}
`;

const LinkStyled = styled(Link)`
  ${({ theme }) => ({
    ...theme.fonts.textS,
  })}
  text-decoration: none;
  height: 100%;
  color: black;
  width: 100%;
  padding: 6px 16px;
`;
