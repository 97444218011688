import React, { useCallback, useEffect, useState } from 'react';

import { Box, Tooltip } from '@material-ui/core';
import html2canvas from 'html2canvas';
import QRCode from 'react-qr-code';

import { InputField } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import CopyIcon from 'src/medias/icons/copy-icon.svg';
import downloadIcon from 'src/medias/icons/download-icon.svg';
import { EventSwitch } from 'src/pages/event-management/shared/switch';
import { CustomLabel } from 'src/pages/signup/components/types';
import { IconButtonStyled } from 'src/pages/user-management/types';
import { IEvent } from 'src/services/events';
import { getQuizSetting, updateQuizSetting } from 'src/services/quiz';

import { CustomButtonStyled, TextStyled } from '../../../volunteer/types';
import { ImageIconStyled } from '../types';

interface ISettingQuiz {
  event: IEvent | null;
}

const SettingQuiz = ({ event }: ISettingQuiz) => {
  const [defaultState, setDefaultState] = useState(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState({
    isRegistration: false,
  });

  const [isActionSuccess, setActionSuccess] = useState(false);
  const [isActionFailed, setActionFailed] = useState(false);
  const [messageResponse, setMessageResponse] = useState('');

  const quizLink = `${window.location.origin}/events/${event?.id}/quiz`;

  const _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });

    if (e.target.name === 'isRegistration') {
      setData({ ...data, [e.target.name]: !data.isRegistration });
    }
  };

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const _onChangeEditStatus = () => {
    setEdit(!edit);
  };

  const _onCancel = () => {
    setEdit(!edit);
    setData({ ...data, isRegistration: defaultState });
  };

  const _onSave = async () => {
    setLoading(true);
    if (event) {
      const response = await updateQuizSetting(event.id, { isOpen: data.isRegistration });
      if (response && response.statusCode === CALL_SUCCESS) {
        setEdit(!edit);
        setActionSuccess(true);
        setMessageResponse(response.message);
      } else {
        setActionFailed(true);
        setMessageResponse(response.message);
      }
      setLoading(false);
    }
  };

  const _onCopyQuizLink = () => {
    const copyText = document.createElement('textarea');
    copyText.innerText = quizLink;
    document.body.appendChild(copyText);
    copyText.select();
    document.execCommand('copy');
    copyText.remove();
  };

  const _onCaptureQRCode = () => {
    const htmlData = document.getElementById('qr-quiz-capture');
    if (htmlData) {
      html2canvas(htmlData).then((canvas) => {
        const img = canvas.toDataURL('image/png');
        let link = document.createElement('a');
        link.download = `QR Code_Quiz_${event?.title}.png`;
        link.href = 'data:' + img;
        document.body.appendChild(link);
        link.click();
      });
    }
  };

  const getData = useCallback(async () => {
    if (event) {
      const response = await getQuizSetting({ eventId: event.id });
      if (response && response.statusCode === CALL_SUCCESS) {
        setData({ ...data, isRegistration: response?.data?.isOpen });
        setDefaultState(response?.data?.isOpen);
      }
    }
  }, [event]);

  useEffect(() => {
    if (event) {
      getData();
    }
  }, [event, getData]);

  return (
    <Box>
      <Box mt={4} display="flex" alignItems="center">
        <CustomLabel>Turn on/off Link Accessibility</CustomLabel>
        <Box width={32} />
        <EventSwitch disabled={!edit} checked={data.isRegistration} name="isRegistration" onChange={_handleChange} />
      </Box>
      <Box display="flex" flex={1}>
        <InputField
          disabled
          value={quizLink}
          onChange={_handleChange}
          name="quizLink"
          title="Quiz Link"
          margin="dense"
          fullWidth
        />
        <Box ml={3} display="flex" alignItems="flex-end" mb={'6px'}>
          <Tooltip title={'Copy'}>
            <IconButtonStyled onClick={_onCopyQuizLink}>
              <ImageIconStyled src={CopyIcon} />
            </IconButtonStyled>
          </Tooltip>
        </Box>
      </Box>
      <Box mt={3} display="flex" flexDirection="column">
        <CustomLabel>QR Code For Quiz Link</CustomLabel>
        <Box display="flex">
          <Box
            id="qr-quiz-capture"
            mt={1}
            mb={3}
            border="1px solid #C5BE8B"
            borderRadius={8}
            alignSelf="flex-start"
            p={2}
          >
            <QRCode value={quizLink} size={158} />
          </Box>
          <Box ml={3} mt={1}>
            <Tooltip title={'Download'}>
              <IconButtonStyled onClick={_onCaptureQRCode}>
                <ImageIconStyled src={downloadIcon} />
              </IconButtonStyled>
            </Tooltip>
          </Box>
        </Box>
      </Box>
      {edit ? (
        <Box mt={3} justifyContent="flex-end" display="flex">
          <CustomButtonStyled outlineButton onClick={_onCancel}>
            Cancel
          </CustomButtonStyled>
          <Box ml={'16px'} />
          <CustomButtonStyled disabled={loading} onClick={_onSave}>
            Save
          </CustomButtonStyled>
        </Box>
      ) : (
        <Box mt={3} justifyContent="flex-end" display="flex">
          <CustomButtonStyled onClick={_onChangeEditStatus}>Edit</CustomButtonStyled>
        </Box>
      )}
      <Box p={0} mt={3} display="flex" justifyContent="center">
        <TextStyled>
          Created on {event?.createdAt ? new Date(event?.createdAt).toLocaleDateString('en-GB') : ''} by{' '}
          {event?.createdBy ? event.createdBy : ''} | Last Updated on{' '}
          {event?.updatedAt ? new Date(event?.updatedAt).toLocaleDateString('en-GB') : ''} by:{' '}
          {event?.updatedBy ? event.updatedBy : ''}
        </TextStyled>
      </Box>
      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </Box>
  );
};

export default SettingQuiz;
