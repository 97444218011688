import { Box, Button, Theme, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { CustomButton } from 'src/components';

export const MAX_LENGTH_EMAIL = 50;
export const WRONG_EMAIL_FORMAT = 'Please fill in a correct email';
export const REQUIRED_FIELD = 'This field is required';
export const USER_NOT_FOUND =
  'This email address does not exist in our system. Please try submitting a different email address';

export const NYSImage = styled.img<{ objectFitContain?: boolean }>(({ theme, objectFitContain }) => ({
  width: '100%',
  height: 300,
  ...(objectFitContain
    ? {
        objectFit: 'contain',
      }
    : {}),
}));

export const Logo = styled.img(({ theme }) => ({
  position: 'absolute',
  top: -50,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 10000,
  width: 70,
  height: 90,
}));
export const Form = styled.form(({ theme }) => ({
  width: '100%',
  color: 'white',
}));
export const TitleLogin = styled(Typography)(({ theme, isTablet }: { theme: Theme; isTablet: boolean }) => ({
  marginBottom: 8,
  marginTop: 52,
  fontSize: isTablet ? 36 : 20,
  fontWeight: 700,
  color: '#979168',
}));
export const CustomTextS = styled(Typography)(({ theme, isTablet }: { theme: Theme; isTablet: boolean }) => ({
  color: 'rgba(113, 128, 150, 1)',
  fontSize: isTablet ? 16 : 12,
  fontWeight: 400,
}));
export const Note = styled(Typography)(({ theme, isTablet }: { theme: Theme; isTablet: boolean }) => ({
  fontSize: isTablet ? 18 : 16,
  fontWeight: 700,
  marginBottom: 32,
  color: '#333333',
}));
export const LinkButton = styled(Button)`
  ${({ theme, isTablet }: { theme: Theme; isTablet: boolean }) => css`
    font-size: ${isTablet ? '18px' : '16px'};
    font-weight: 700;
    text-transform: none;
  `}
`;
export const CloseIconCustom = styled(CloseIcon)<{ isError?: boolean }>`
  ${({ theme, isError }) => css`
    color: ${isError ? theme.palette.red?.[600] : theme.palette.common.black};
  `}
`;
export const NavLinkCustom = styled(NavLink)(({ theme }) => ({
  ...theme.fonts.header3,
  textDecoration: 'none',
  color: '#000000',
  width: '35%',
  minWidth: 'fit-content',
}));
export const Title = styled(Typography)<{ isTablet?: boolean }>(({ theme, isTablet }) => ({
  marginBottom: 32,
  ...theme.fonts.header3S,
  color: theme.palette.yellow?.[900],
  textAlign: isTablet ? 'center' : 'left',
}));
export const NoteGroup = styled(Typography)<{ isTablet?: boolean }>(({ theme, isTablet }) => ({
  width: '100%',
  marginBottom: 32,
  color: theme.palette.common.black,
  textAlign: isTablet ? 'center' : 'left',
}));
export const SmallNote = styled(Typography)(({ theme, isTablet }: { theme: Theme; isTablet: boolean }) => ({
  fontSize: isTablet ? 18 : 16,
  fontWeight: 700,
  marginBottom: 32,
  color: '#000',
}));
export const NavLinkStyled = styled(NavLink)`
  && {
    font-size: 20px;
    text-decoration: none;
    display: flex;
    justify-content: center;
  }
`;
export const ContactBtn = styled(CustomButton)(({ theme }) => ({
  ...theme.fonts.textSs,
  marginRight: '10px',
  borderRadius: '5px',
  width: 'fit-content !important',
  padding: '0px 50px',
}));
export const TitleResetPassword = styled(Typography)<{ isMobile?: boolean }>(({ theme, isMobile }) => ({
  ...theme.fonts.header4S,
  fontSize: isMobile ? '20px' : theme.fonts.header4S.fontSize,
  color: theme.palette.yellow?.[900],
}));
export const TextBox = styled(Box)<{ isMobile?: boolean }>`
  ${({ theme, isMobile }) => css`
    margin: 10px 0 32px 0;
    p {
      margin: 0;
      font-size: ${isMobile ? '12px' : theme.typography.h6.fontSize};
      font-weight: ${theme.typography.h6.fontWeight};
      line-height: ${theme.typography.h6.lineHeight};
      color: ${isMobile ? theme.palette.gray?.[1000] : theme.palette.common.black};
      span {
        color: ${theme.palette.yellow?.[900]};
      }
    }
  `}
`;
export const ErrorMess = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textS,
  color: 'red',
  marginTop: '10px',
}));
export const SubmitButton = styled(CustomButton)<{ disable?: boolean }>`
  ${({ disable }) => css`
    && {
      pointer-events: ${disable ? 'none' : 'all'};
    }
  `}
`;
