import React, { useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import {
  createAlignPlugin,
  createBoldPlugin,
  createDndPlugin,
  createExitBreakPlugin,
  createFontBackgroundColorPlugin,
  createFontColorPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createHistoryPlugin,
  createImagePlugin,
  createItalicPlugin,
  createKbdPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createPlateComponents,
  createReactPlugin,
  createSelectOnBackspacePlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  ELEMENT_IMAGE,
  ELEMENT_PARAGRAPH,
  MARK_BG_COLOR,
  MARK_COLOR,
  Plate,
  PlatePlugin,
  SPEditor,
  StyledElement,
  StyledLeaf,
  withProps,
  withStyledProps,
} from '@udecode/plate';
import { useRouteMatch } from 'react-router-dom';
import Scrollspy from 'react-scrollspy';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';

import Banner from 'src/components/banner';
import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import { HeaderMobile } from 'src/components/headerMobile';
import { editablePreviewProps, optionsExitBreakPlugin } from 'src/components/slateEditor/config/pluginOptions';
import { CALL_SUCCESS } from 'src/constants/common';
import useFetchMe from 'src/hooks/use-fetch-me';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { getCMSData, ICms } from 'src/services/cms';
import useResponsive from 'src/utils/responsive';

import { TypographyStyled } from '../nsaa';

import { data, menus, menuSpy } from './data';

let components = createPlateComponents({
  [MARK_COLOR]: withStyledProps(StyledLeaf, {
    leafProps: {
      [MARK_COLOR]: ['color'],
    },
  }),
  [MARK_BG_COLOR]: withStyledProps(StyledLeaf, {
    leafProps: {
      [MARK_BG_COLOR]: ['backgroundColor'],
    },
  }),
  [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
    styles: {
      root: {
        fontSize: '1rem',
      },
    },
  }),
});

type TEditor = SPEditor & ReactEditor & HistoryEditor;
const Constitution = () => {
  useScrollToTop();
  const { path } = useRouteMatch();
  const responsive = useResponsive();
  const { me } = useFetchMe();
  const pluginsMemo: PlatePlugin<TEditor>[] = useMemo(() => {
    const plugins = [
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createMediaEmbedPlugin(),
      createAlignPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createDndPlugin(),
      createExitBreakPlugin(optionsExitBreakPlugin),
      createSelectOnBackspacePlugin({
        allow: [ELEMENT_IMAGE],
      }),
    ];

    return plugins;
  }, []);

  const [datas, setDatas] = useState<ICms>({
    type: '',
    banner_image: '',
    banner_title: '',
    banner_description: '',
    content_title: '',
    content_body: '',
    background_image: '',
    item_image: '',
    item_date: '',
    item_image_description: '',
  });

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const response = await getCMSData('constitution');
    if (response.statusCode === CALL_SUCCESS) {
      setDatas(response.data as ICms);
    }
  };

  return (
    <Box style={{ scrollBehavior: 'smooth', scrollPaddingTop: 500 }} pt={responsive.isTabletOrMobile ? 15 : 3.75}>
      {responsive.isTabletOrMobile && <HeaderMobile title={`Constitution`} userProfile={me} />}
      {!responsive.isTabletOrMobile && <NSAABreadcrumbs path={path} />}
      <Banner
        image={datas.banner_image}
        title={datas.banner_title}
        description={datas?.banner_description}
        responsive={responsive}
      />

      {responsive.isTabletOrMobile && (
        <TypographyStyled responsive={responsive}>
          (Nanyang Girls’ High School, Nanyang Primary School, Nanyang Kindergarten)
        </TypographyStyled>
      )}
      <ConstitutionContainer responsive={responsive} style={{ scrollPaddingTop: 100 }}>
        <Menu responsive={responsive}>
          <Scrollspy items={menuSpy} currentClassName={`isCurrent`} offset={-200}>
            {menus.map((item, idx) => {
              const hash = `#${item
                .split(' ')
                .map((c) => c[0].toLowerCase() + c.slice(1))
                .join('-')}`;
              return (
                <MenuItem active={hash === window.location.hash} href={`${path}/${hash}`} key={idx}>
                  {item}
                </MenuItem>
              );
            })}
          </Scrollspy>
        </Menu>

        <Content responsive={responsive}>
          {data.map((item) => (
            <Box id={`${item.hash}`} style={{ scrollMarginTop: 120 }}>
              <Box pt={3}>
                <Title>{item.title}</Title>
                <Plate
                  id={item.hash}
                  plugins={pluginsMemo}
                  components={components}
                  editableProps={editablePreviewProps}
                  value={item.content}
                />
              </Box>
            </Box>
          ))}
        </Content>
      </ConstitutionContainer>
    </Box>
  );
};

export default Constitution;

const ConstitutionContainer = styled(Box)<{ responsive?: any }>`
  ${({ responsive }) => css`
    max-width: 1170px;
    margin: 80px auto;
    display: flex;
    gap: 80px;
    ${responsive.isTabletOrMobile &&
    css`
      margin: 0 16px;
    `}
  `}
`;

const Menu = styled(Box)<{ responsive?: any }>`
  ${({ responsive }) => css`
    display: flex;
    flex-direction: column;
    width: 420px;
    position: sticky;
    top: 120px;
    height: calc(100vh - 300px);
    overflow: auto;
    padding-top: 24px;
    & > ul {
      margin: 0;
    }
    ${responsive.isTabletOrMobile &&
    css`
      display: none;
    `};
  `}
`;

const MenuItem = styled.a<{ active: boolean }>`
  ${({ theme, active }) => css`
    text-decoration: none;
    height: 56px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: #000;
    &.isCurrent {
      color: ${theme.palette.yellow?.[900]};
      text-decoration: none;
      height: 56px;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 20px;
      border-left: 5px solid ${theme.palette.yellow?.[900]};
      background: ${theme.palette.gray?.[10]};
    }
  `}
`;

const Content = styled(Box)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    flex: 1;
    color: ${theme.palette.gray?.[1000]};
    line-height: 36px;
    scroll-padding-top: 1000px;
    ${responsive.isTabletOrMobile &&
    css`
      margin-top: 20px;
    `}
  `}
`;

const Title = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.gray?.[60]};
    font-size: ${theme.fonts.header4S.fontSize};
    font-weight: ${theme.fonts.header4S.fontWeight};
    line-height: ${theme.fonts.header4S.lineHeight};
    margin: 24px 0;
    &:first-child {
      margin-top: 0px;
    }
  `}
`;
