import React from 'react';

import { useFormikContext } from 'formik';
import { Prompt } from 'react-router-dom';

interface IPromptIfDirtyProps {
  message?: string;
  when?: boolean;
}

const DEFAULT_MESSAGE =
  'Your updates are not saved. This action will cancel all of your updates, are you sure you want to proceed with this action?';

const FormikPromptIfDirty = ({ message }: IPromptIfDirtyProps) => {
  const formik = useFormikContext();
  return (
    <Prompt
      when={formik.dirty}
      message={(location, action) => {
        return message || DEFAULT_MESSAGE;
      }}
    />
  );
};

export default FormikPromptIfDirty;
