import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { CustomButton } from 'src/components';
import iconSuccess from 'src/medias/icons/icon-success.png';
import backgroundImage from 'src/medias/images/background-email-has-send.png';
import useResponsive from 'src/utils/responsive';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
  },
  containerResponse: {
    height: '100vh',
  },
}));

export const IconSuccess = styled.img(({ theme }) => ({}));

export const Text = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header4S,
  color: 'white',
  marginTop: 8,
  marginBottom: 24,
  width: 730,
  textAlign: 'center',
}));
export const TextResponse = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 16,
  color: 'rgba(0, 0, 0, 0.6)',
}));
const TitleResponse = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 20,
  color: 'rgba(0, 0, 0, 0.6)',
}));

interface IMailSendSuccess {
  message?: string;
  titleButtonSubmit?: string;
}

export const MailSendSuccess = ({
  message = 'Email has been sent. Please check your mail!',
  titleButtonSubmit = 'OK',
}: IMailSendSuccess) => {
  const classes = useStyles();
  const { isTabletOrMobile, isMobile, isTablet } = useResponsive();

  function _onClickOK() {
    window.location.href = '/';
  }

  return (
    <Box
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
      flex={1}
      className={isTabletOrMobile ? classes.containerResponse : classes.container}
    >
      {isTabletOrMobile && (
        <Box display="flex" flex={1} justifyContent="center" alignItems="center" flexDirection="column">
          <IconSuccess src={iconSuccess} />
          <Box textAlign="center" mt={3}>
            <TitleResponse>Email has been sent.</TitleResponse>
            <TextResponse>Please check your parents/ guardian’s mail!</TextResponse>
          </Box>
        </Box>
      )}

      {isMobile && (
        <Box display="flex" mb={4}>
          <CustomButton onClick={_onClickOK}>{titleButtonSubmit}</CustomButton>
        </Box>
      )}

      {isTablet && (
        <Box position={'absolute'} bottom={0} left={0} width={'100%'} display="flex" flex={1}>
          <CustomButton style={{ width: '100%', height: 83 }} onClick={_onClickOK}>
            {titleButtonSubmit}
          </CustomButton>
        </Box>
      )}

      {!isTabletOrMobile && <IconSuccess src={iconSuccess} />}
      {!isTabletOrMobile && <Text>{message}</Text>}
      {!isTabletOrMobile && <CustomButton onClick={_onClickOK}>{titleButtonSubmit}</CustomButton>}
    </Box>
  );
};
