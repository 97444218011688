import React from 'react';

import { Tooltip } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import noticeYellowIcon from 'src/medias/icons/notice-yellow.svg';

interface ITooltipStyledProps {
  message: string;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
    | undefined;
}

const TooltipStyled = ({ message, placement }: ITooltipStyledProps) => {
  return (
    <LightTooltip title={message} placement={placement} arrow>
      <Icon src={noticeYellowIcon} />
    </LightTooltip>
  );
};

export default TooltipStyled;

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#E1DFC5',
    color: '#6B695D',
    fontSize: 16,
    borderRadius: 4,
    fontWeight: 400,
  },
  arrow: {
    color: '#E1DFC5',
  },
}))(Tooltip);

const Icon = styled.img(() => ({
  marginLeft: 8,
  marginTop: 2,
}));
