import React, { useState } from 'react';

import { Box, Step, StepLabel } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';

import { StepperCustom } from 'src/components';
import ConfirmPopup from 'src/components/confirm';
import { HeaderMobile } from 'src/components/headerMobile';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import useFetchMe from 'src/hooks/use-fetch-me';
import { Title as BigTitle } from 'src/pages/login';
import { getPublicEventById, IEvent, registrationEvent } from 'src/services/events';
import { getAreaCode } from 'src/utils/areaCode';
import useResponsive from 'src/utils/responsive';

import { ActionButton } from '../profile/components/general-information.styled';

import Checkout from './Checkout';
import FillForm from './FillForm';
import { Description, DescriptionLink, RegistrationFormContainer, Title } from './index.styled';
import Payment from './Payment';
import Success from './Success';
import { REGISTRATION_FORMS_INIT_VALUE, REGISTRATION_FORMS_VALIDATION } from './validation';

interface IRegistrationFormsProps {}

const RegistrationForms = (props: IRegistrationFormsProps) => {
  const { id } = useParams<{ id: string }>();
  const [activeStep, setActiveStep] = useState(0);
  const [event, setEvent] = useState<IEvent>();
  const [qrCode, setQrCode] = useState<string>('');
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [isShowConfirm, setShowConfirmPopup] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState('');
  const [receiveAccount, setReceiveAccount] = useState('');
  const [loading, setLoading] = useState(false);
  const [, setShowPopupInformation] = useState(false);
  const { loading: isFetchingMe, me: userProfile } = useFetchMe();
  const [isEventRegistering, setEventRegistering] = useState(false);
  const responsive = useResponsive();
  const ZERO = '0';

  const NOTIFICATION_TOTAL_TICKET_IS_MULTIPLE_10 =
    'Tickets are selling for table of 10. If you register with odd number of tickets, please contact us for support';
  const NOTIFICATION_TOTAL_TICKET_EXCEEDS_ALLOW = 'Total number of ticket exceeds the allowed maximum number';
  const NOTIFICATION_TOTAL_TICKET_SOLD_OUT = 'All tickets have been sold out. Please choose another events';
  const STEPS = ['Fill form', 'Check-out', 'Payment', 'Finish'];
  const FILL_FORM_STEP = 0;
  const CHECKOUT_STEP = 1;
  const PAYMENT_STEP = 2;
  const SUCCESS_STEP = 3;
  const NUMBER_OF_TICKET_MUST_BE_A_MULTIPLE_10 = 'Number of tickets must be a multiple of 10';
  const NOTIFICATION_ACCOUNT_WAS_VERIFIED =
    'Your account has been verified already. You can not register this event again';

  React.useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await getPublicEventById(id!);
      if (response?.statusCode === CALL_SUCCESS) {
        setEvent(response.data!);
      }
      setLoading(false);
    })();
  }, []);

  React.useEffect(() => {
    if (event && !loading && !isFetchingMe) {
      const IS_PUBLIC_EVENT = event.is_public;
      if (!IS_PUBLIC_EVENT && !userProfile) {
        window.location.pathname = '/login';
      }
    }
  }, [event, userProfile, loading, isFetchingMe]);

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };
  const handleToggleAlert = () => setShowConfirmPopup((status) => !status);
  const handleTogglePopupInformation = () => setShowPopupInformation((status) => !status);

  if (loading || !event || isFetchingMe) return <div />;

  if (event?.category === 'gtky-party' && userProfile?.accountStatus === 'verified') {
    return (
      <ConfirmPopup
        open={true}
        onCancel={handleTogglePopupInformation}
        onOk={handleTogglePopupInformation}
        okText="Contact Us"
        cancelText="Cancel"
        title="Notification"
        btnCancelVariant="outlined"
        btnOkVariant="filled"
        content={NOTIFICATION_ACCOUNT_WAS_VERIFIED}
        hiddenBtnCancel={false}
        hiddenBtnOk={true}
      />
    );
  }

  return (
    <RegistrationFormContainer>
      {responsive.isTabletOrMobile && (
        <HeaderMobile
          title={event.title.length > 20 ? event.title.slice(0, 20) + '...' : event.title}
          userProfile={userProfile}
        />
      )}
      <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
      <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
      <ConfirmPopup
        open={isShowConfirm}
        onCancel={handleToggleAlert}
        onOk={handleToggleAlert}
        okText="Contact Us"
        cancelText="Cancel"
        title="Notification"
        btnCancelVariant="outlined"
        btnOkVariant="filled"
        content={confirmMessage}
        hiddenBtnCancel={false}
        hiddenBtnOk={
          confirmMessage === NOTIFICATION_TOTAL_TICKET_EXCEEDS_ALLOW ||
          confirmMessage === NOTIFICATION_TOTAL_TICKET_SOLD_OUT
        }
      />
      <Box mt={8} textAlign="center" maxWidth={1100} mx="auto" pt={9}>
        {responsive.isTabletOrMobile ? (
          <Title responsive={responsive}>EVENT REGISTRATION FORM</Title>
        ) : (
          <BigTitle>EVENT REGISTRATION FORM</BigTitle>
        )}
        {!userProfile && (
          <Description>
            If you already have an account, <DescriptionLink href="/login">Log in</DescriptionLink> to receive the
            membership offer
          </Description>
        )}
        <Box mt={2} pb={9} width={'100%'}>
          <StepperCustom activeStep={activeStep}>
            {STEPS.map((label, index) => {
              return (
                <Step key={label}>
                  <StepLabel>{responsive.isTabletOrMobile ? '' : label}</StepLabel>
                </Step>
              );
            })}
          </StepperCustom>
        </Box>
      </Box>
      <Formik
        initialValues={REGISTRATION_FORMS_INIT_VALUE(event, userProfile!)}
        validationSchema={REGISTRATION_FORMS_VALIDATION({
          includeMeals: !!event.include_meal,
          isRequiredTicket: !!event.require_ticket,
          isMemberOnly: !event.is_public,
        })}
        onSubmit={(values) => {
          const TOTAL_TICKET =
            +values.ticketForStandardMeal + +values.ticketForVegetarian + +values.ticketForHalal || +values.totalTicket;
          const MAX_NUMBER_PER_PURCHASE = event?.max_number_per_purchase;
          const CURRENT_GUEST = event?.current_guest;
          const MAX_GUEST = event?.max_guest;
          const IS_SOLD_OUT = CURRENT_GUEST === MAX_GUEST;
          if (event.include_meal && TOTAL_TICKET % 10 !== 0) {
            setShowConfirmPopup(true);
            setConfirmMessage(NOTIFICATION_TOTAL_TICKET_IS_MULTIPLE_10);
            return;
          }
          if (event.is_public && event.require_ticket && TOTAL_TICKET > MAX_NUMBER_PER_PURCHASE!) {
            setShowConfirmPopup(true);
            setConfirmMessage(NOTIFICATION_TOTAL_TICKET_EXCEEDS_ALLOW);
            return;
          }
          if (IS_SOLD_OUT) {
            setShowConfirmPopup(true);
            setConfirmMessage(NOTIFICATION_TOTAL_TICKET_SOLD_OUT);
            return;
          }
          if (!event.is_public && TOTAL_TICKET > MAX_GUEST) {
            return;
          }

          if (+values.numberAttendedAge > +TOTAL_TICKET) return;

          setActiveStep((step) => (step < SUCCESS_STEP ? step + 1 : step));
        }}
      >
        {(formik) => {
          const handleNextStep = () => {
            if (activeStep === FILL_FORM_STEP) {
              formik.submitForm();
              return;
            }
            if (activeStep === CHECKOUT_STEP) {
              const values = formik.values;
              let val: any = {};
              Object.entries(values).forEach((entry) => {
                const [key, value] = entry;
                val[key] = typeof value === 'string' ? value.trim() : value;

                val[key] =
                  key === 'mobilePhone' ||
                  key === 'areaCode' ||
                  key === 'givenName' ||
                  key === 'surName' ||
                  key === 'periodStudyInNPP' ||
                  key === 'periodStudyInNYP' ||
                  key === 'periodStudyInNYGH' ||
                  key === 'periodStudyInNYK'
                    ? value?.trim()
                    : isNaN(value)
                    ? value
                    : Number(value);
              });
              val['totalTicket'] =
                +values.ticketForStandardMeal + +values.ticketForVegetarian + +values.ticketForHalal ||
                +values.totalTicket;
              val = Object.entries(val).reduce((a: any, [k, v]: any) => (v !== '' ? ((a[k] = v), a) : a), {});
              val = { ...val, numberAttendedAge: +formik.values.numberAttendedAge };
              val['areaCode'] = getAreaCode(values.areaCode);

              if (userProfile) {
                val['userId'] = userProfile.id;
              }
              (async () => {
                setEventRegistering(true);
                const response = await registrationEvent(val);
                if (response.statusCode === CALL_SUCCESS) {
                  setEventRegistering(false);
                  setQrCode(response.data?.code!);
                  setReceiveAccount(response?.data?.receiveAccount!);
                  setActiveStep((activeStep) => activeStep + 1);
                  setShowSnackBarSuccess(true);
                  setMessage(response.message);

                  if (formik.values.totalPayment === ZERO) {
                    setActiveStep(SUCCESS_STEP);
                  } else {
                    setActiveStep(PAYMENT_STEP);
                  }
                } else {
                  setEventRegistering(false);
                  if (response.message === NUMBER_OF_TICKET_MUST_BE_A_MULTIPLE_10) {
                    setShowConfirmPopup(true);
                    setConfirmMessage(NOTIFICATION_TOTAL_TICKET_IS_MULTIPLE_10);
                  } else {
                    setShowSnackBarError(true);
                    setMessage(response.message);
                  }
                }
              })();
            }
            if (activeStep === PAYMENT_STEP) {
              setActiveStep((step) => (step < SUCCESS_STEP ? step + 1 : step));
            }
            if (activeStep === SUCCESS_STEP) {
              window.location.pathname = '/';
            }
          };
          const handleBackStep = () => {
            if (formik.values.totalPayment === ZERO && activeStep === SUCCESS_STEP) {
              setActiveStep(CHECKOUT_STEP);
              return;
            }
            setActiveStep((step) => (step > FILL_FORM_STEP ? step - 1 : step));
          };
          const BUTTON_LABEL = {
            NEXT: 'NEXT',
            CONFIRM: 'CONFIRM',
            FINISH: 'FINISH',
            GO_TO_HOME_PAGE: 'GO TO HOME PAGE',
          };
          return (
            <Box px={responsive.isTabletOrMobile ? 2 : 0}>
              <Form>
                {activeStep === FILL_FORM_STEP && (
                  <FillForm isLoggedIn={!!userProfile} formik={formik} event={event!} />
                )}
                {activeStep === CHECKOUT_STEP && <Checkout formik={formik} event={event!} />}
                {activeStep === PAYMENT_STEP && (
                  <Payment
                    receiveAccount={receiveAccount}
                    fullName={`${formik.values.surName}, ${formik.values.givenName}`}
                    qrCode={qrCode}
                    totalPayment={+formik.values.totalPayment}
                  />
                )}
                {activeStep === SUCCESS_STEP && <Success />}
                <Box display="flex" pb={8} justifyContent="center" style={{ gap: 16 }}>
                  {activeStep === CHECKOUT_STEP && (
                    <ActionButton variants="outlined" onClick={handleBackStep}>
                      BACK
                    </ActionButton>
                  )}
                  <ActionButton variants="yellow" onClick={handleNextStep} disabled={isEventRegistering}>
                    {activeStep === FILL_FORM_STEP && BUTTON_LABEL.NEXT}
                    {activeStep === CHECKOUT_STEP && BUTTON_LABEL.CONFIRM}
                    {activeStep === PAYMENT_STEP && BUTTON_LABEL.FINISH}
                    {activeStep === SUCCESS_STEP && BUTTON_LABEL.GO_TO_HOME_PAGE}
                  </ActionButton>
                </Box>
              </Form>
            </Box>
          );
        }}
      </Formik>
    </RegistrationFormContainer>
  );
};

export default RegistrationForms;
