import React from 'react';

import { Box } from '@material-ui/core';
import styled from 'styled-components';

import { LabelStyled, TypographyInfo } from './type';

export const InfoText = ({
  label,
  value,
  valueWidth,
  labelWidth,
  maxContent,
}: {
  label: string;
  value: string | React.ReactNode;
  valueWidth?: string;
  labelWidth?: string;
  maxContent?: boolean;
}) => {
  return (
    <BoxContainer display="flex" mt={2} justifyContent={'flex-start'} alignItems={maxContent ? 'flex-start' : 'center'}>
      <Box>
        <LabelStyled>{label}</LabelStyled>
      </Box>
      <Box>
        <LabelStyled>:</LabelStyled>
      </Box>
      <Box>
        <TypographyInfo>{value}</TypographyInfo>
      </Box>
    </BoxContainer>
  );
};
const BoxContainer = styled(Box)`
  gap: 10px;
`;
