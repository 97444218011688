import React, { useState } from 'react';

import { Box } from '@material-ui/core';

import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import ProfilePopupLayout from 'src/pages/profile/layout/profile-popup.layout';
import {
  getUserApplicantById,
  IApplicant,
  updateApplicantGtkyById,
  updateApplicantGtkyChildById,
  verifyUserById,
} from 'src/services/gtky-applicant';

import AdditionalInformationForm from '../forms/AdditionalInformationForm';
import AdditionalInformationYouthForm from '../forms/AdditionalInformationYouthForm';
import AdditionalInformationPopup from '../popup/AdditionalInformationPopup';

interface IAdditionalInformationProps {
  applicant?: IApplicant;
  setApplicationInfo: React.Dispatch<React.SetStateAction<IApplicant | undefined>>;
}

const AdditionalInformation = ({ applicant, setApplicationInfo }: IAdditionalInformationProps) => {
  const YOUTH_MEMBER = 'youth-member';
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [, setSaveLoading] = useState(false);
  const [, setSaveAndVerifyLoading] = useState(false);
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [message, setMessage] = useState('');

  const handleTogglePopup = () => setOpenPopup((status) => !status);

  const handleSave = async (values: any) => {
    setSaveLoading(true);
    const response = await updateApplicantGtkyChildById(applicant?.id!, {
      child: values?.child?.map?.((item: any) => ({
        ...item,
        verificationStatus: item?.verificationStatus === '' ? null : item?.verificationStatus,
      })),
    });

    if (response?.statusCode === CALL_SUCCESS) {
      setMessage(response.message);
      setShowSnackBarSuccess(true);
      handleTogglePopup();
      (async () => {
        const response = await getUserApplicantById(applicant?.id!);
        if (response.statusCode === CALL_SUCCESS) {
          setApplicationInfo(response.data);
        }
      })();
    } else {
      setMessage(response.message);
      setShowSnackBarError(true);
    }
  };
  const handleSaveAndVerify = async (values: any) => {
    setSaveAndVerifyLoading(true);
    Promise.all([
      updateApplicantGtkyById(applicant?.id!, {
        ...applicant,
      }),
      updateApplicantGtkyChildById(applicant?.id!, {
        child: values?.child?.map?.((item: any) => ({
          ...item,
          verificationStatus: item?.verificationStatus === '' ? null : item?.verificationStatus,
        })),
      }),
      verifyUserById(applicant?.id!),
    ]).then(
      (response) => {
        setSaveAndVerifyLoading(false);
        setMessage(response[0].message);
        setShowSnackBarSuccess(true);
        setOpenPopup(false);
        (async () => {
          const response = await getUserApplicantById(applicant?.id!);
          if (response.statusCode === CALL_SUCCESS) {
            setApplicationInfo(response.data);
          }
        })();
      },
      (err) => {
        setSaveAndVerifyLoading(false);
        setMessage(err[0]?.message);
        setShowSnackBarError(true);
      },
    );
  };

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };

  const handleUpdateAdditionalInformation = (values: any) => {};

  return (
    <Box>
      <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
      <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
      <Box mt={4}>
        {applicant?.accountType === YOUTH_MEMBER ? (
          <AdditionalInformationYouthForm applicant={applicant || null} onSave={handleUpdateAdditionalInformation} />
        ) : (
          <>
            <AdditionalInformationForm
              onOpenEditAdditionalInformation={handleTogglePopup}
              applicant={applicant || null}
            />
            <ProfilePopupLayout open={isOpenPopup} onClose={handleTogglePopup}>
              <AdditionalInformationPopup
                onSave={handleSave}
                onSaveAndVerify={handleSaveAndVerify}
                onClose={handleTogglePopup}
                applicant={applicant || null}
                isSaveLoading={false}
                isSaveAndVerifyLoading={false}
              />
            </ProfilePopupLayout>
          </>
        )}
      </Box>
    </Box>
  );
};

export default AdditionalInformation;
