import { stringify } from 'query-string';

import httpClient from 'src/configs/http-client';
import { IResponse, IResponseList } from 'src/types';

import { IEmailTracking, ISearchEmailTracking } from '.';

export const getAllEmailTracking = async (search: ISearchEmailTracking) => {
  const queryString = stringify(search);
  const response = await httpClient.get<IResponseList<IEmailTracking>>(
    `${process.env.REACT_APP_API_URL}/mail/tracking?${queryString}`,
  );
  return response?.data;
};

export const emailTrackingResendEmail = async (emailIds: string[]) => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/mail/resend`, {
    emailIds,
  });
  return response?.data;
};
