import React from 'react';

import { Box } from '@material-ui/core';
import moment from 'moment';
import QRCode from 'react-qr-code';

import PaymentMethodImageSrc from 'src/medias/images/banks-supported.png';
import PaynowQR from 'src/utils/paynow-code-generator';
import useResponsive from 'src/utils/responsive';

import { PaymentMethodBorder, PaymentMethodImage, QrContainer, Subtitle, Title } from './index.styled';

interface Props {
  qrCode: string;
  totalPayment: number;
  fullName: string;
  receiveAccount: string;
}

const Payment = ({ qrCode, totalPayment, fullName, receiveAccount }: Props) => {
  const FORMAT = 'YYYYMMDD';
  const COMPANY_NAME = 'VMO';
  const responsive = useResponsive();

  let qrcode = new PaynowQR({
    uen: receiveAccount,
    amount: totalPayment,
    editable: false,
    expiry: moment().add(2, 'day').format(FORMAT),
    company: COMPANY_NAME,
    refNumber: `${qrCode} - ${fullName} - Buy event ticket`,
  });

  let QRstring = qrcode.output();

  return (
    <Box textAlign="center">
      <Title mb responsive={responsive}>
        Scan below QR Code using your internet banking app
      </Title>
      <Subtitle mb={'17px'} variants="success" responsive={responsive}>
        QR Code will expire in 1 day
      </Subtitle>
      <QrContainer>
        <QRCode value={QRstring} size={189} />
      </QrContainer>
      <PaymentMethodBorder responsive={responsive}>
        <PaymentMethodImage src={PaymentMethodImageSrc} />
      </PaymentMethodBorder>
      <Subtitle mb={'20px'}>If you finish the payment, please click Finish to complete your registration</Subtitle>
    </Box>
  );
};

export default Payment;
