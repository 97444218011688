import React from 'react';

import { Box, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { useFetchProfileQuery } from 'src/api/endpoints/profiles.endpoint';
import {
  useFetchUserByIdQuery,
  useUpdateUserByIdMutation,
  useUpdateUserRoleMutation,
} from 'src/api/endpoints/users.endpoint';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { TOAST } from 'src/constants/toasts.constant';
import { ACCOUNT_TYPE, USER_ROLE, BLOCKING_REASON } from 'src/constants/users.constants';
import { ActionButton, FormTextField, FormMenuItem } from 'src/pages/profile/components/general-information.styled';

import AccountSettingPopup from './user-detail.account-settings.popup';

interface IAccountSettingProps {
  userId: string;
}

const AccountSetting = ({ userId }: IAccountSettingProps) => {
  const { data: profile, refetch } = useFetchUserByIdQuery(userId);
  const { data: me } = useFetchProfileQuery();
  const [updateRole, { isLoading: isUpdatingRole, isError: isUpdatingRoleError, isSuccess: isUpdatingRoleSuccess }] =
    useUpdateUserRoleMutation();

  const [isShowEditPopup, setShowPopupEdit] = React.useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = React.useState(false);
  const [isShowSnackBarError, setShowSnackBarError] = React.useState(false);

  React.useEffect(() => {
    refetch();
  }, []);

  const isShow = !(profile?.data?.id === me?.data?.id && profile?.data?.roleName === 'it-admin');
  const [
    update,
    { isLoading: isUpdating, isError: isUpdatingError, isSuccess: isUpdatingSuccess, error: updatedError },
  ] = useUpdateUserByIdMutation();

  React.useEffect(() => {
    if (isUpdatingSuccess) {
      setShowPopupEdit(false);
      setShowSnackBarSuccess(true);
    }
    if (isUpdatingError) {
      setShowSnackBarError(true);
    }
    if (isUpdatingRoleSuccess) {
      setShowPopupEdit(false);
      setShowSnackBarSuccess(true);
    }
    if (isUpdatingRoleError) {
      setShowSnackBarError(true);
    }
  }, [isUpdatingError, isUpdatingSuccess, isUpdatingRoleSuccess, isUpdatingRoleError]);

  function handleClosePopup() {
    setShowPopupEdit(false);
  }
  function handleOpenPopup() {
    setShowPopupEdit(true);
  }
  function handleOnSavePopup(values: any) {
    if (me?.data?.roleName === 'it-admin') {
      updateRole({ action: 'update', ids: [values?.id], role: values?.roleName });
    } else {
      update(values);
    }
  }
  function handleCloseSnackBarSuccess() {
    setShowSnackBarSuccess(false);
  }
  function handleCloseSnackBarError() {
    setShowSnackBarError(false);
  }

  return (
    <Box mt={3}>
      <SuccessSnackBar
        open={isShowSnackBarSuccess}
        handleClose={handleCloseSnackBarSuccess}
        message={TOAST.UPDATE_PROFILE_SUCCESS}
      />
      <ErrorSnackBar
        open={isShowSnackBarError}
        handleClose={handleCloseSnackBarError}
        message={(updatedError as any)?.data?.message}
      />
      <Box mb={3}>
        <Label required>Account Type</Label>
        <FormTextField
          disabled={true}
          variant="outlined"
          fullWidth
          size="small"
          value={profile?.data?.accountType}
          select
        >
          {ACCOUNT_TYPE.map(({ value, label }, idx) => (
            <FormMenuItem key={idx} value={value}>
              {label}
            </FormMenuItem>
          ))}
        </FormTextField>
      </Box>
      <Box mb={3}>
        <Label required>Verification Status</Label>
        <RadioGroup value={profile?.data?.verificationStatus!} onChange={(e, v) => {}} row>
          <Box width={210}>
            <FormControlLabel disabled value="verified" control={<Radio disabled />} label="Verified" />
          </Box>
          <Box width={210}>
            <FormControlLabel disabled value="unverified" control={<Radio disabled />} label="Unverified" />
          </Box>
        </RadioGroup>
      </Box>
      <Box mb={3}>
        <Label required>Account Status</Label>
        <RadioGroup value={profile?.data?.accountStatus!} onChange={(e, v) => {}} row>
          <Box width={210}>
            <FormControlLabel disabled value="active" control={<Radio disabled />} label="Active" />
          </Box>
          <Box width={210}>
            <FormControlLabel disabled value="blocked" control={<Radio disabled />} label="Block" />
          </Box>
        </RadioGroup>
      </Box>
      <Box mb={3}>
        <Label>Blocking reason</Label>
        <FormTextField
          disabled={true}
          variant="outlined"
          name="reason"
          fullWidth
          size="small"
          value={profile?.data?.reason}
          select
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          }}
          defaultValue="default"
        >
          <FormMenuItem value="default">--- Choose a blocking reason ---</FormMenuItem>
          {BLOCKING_REASON.map(({ value, label }, idx) => (
            <FormMenuItem key={idx} value={value}>
              {label}
            </FormMenuItem>
          ))}
        </FormTextField>
      </Box>
      <Box mb={3}>
        <Label required>Is committee member</Label>
        <RadioGroup value={profile?.data?.committeeMember!} onChange={(e, v) => {}} row>
          <Box width={210}>
            <FormControlLabel disabled value="yes" control={<Radio disabled />} label="Yes" />
          </Box>
          <Box width={210}>
            <FormControlLabel disabled value="no" control={<Radio disabled />} label="No" />
          </Box>
        </RadioGroup>
      </Box>
      <Box mb={3}>
        <Label>Admin’s role</Label>
        <FormTextField
          variant="outlined"
          name="gender"
          fullWidth
          disabled
          size="small"
          onChange={() => {}}
          select={true}
          SelectProps={{
            MenuProps: {
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            },
          }}
          defaultValue="default"
          value={profile?.data?.roleName}
        >
          {USER_ROLE.map(({ value, label }, idx) => (
            <FormMenuItem key={idx} value={value}>
              {label}
            </FormMenuItem>
          ))}
        </FormTextField>
      </Box>
      {isShow && (
        <Box display="flex" justifyContent="center">
          <ActionButton variants="yellow" onClick={handleOpenPopup}>
            Edit
          </ActionButton>
        </Box>
      )}
      <AccountSettingPopup
        open={isShowEditPopup}
        onClose={handleClosePopup}
        onSave={handleOnSavePopup}
        profile={profile?.data}
        isSaveLoading={isUpdating}
        isUpdatingRole={isUpdatingRole}
      />
    </Box>
  );
};

export const Label = styled(Box)<{ required?: boolean }>`
  ${({ theme, required }) => css`
    font-size: ${theme.fonts.header6.fontSize};
    font-weight: ${theme.fonts.header6.fontWeight};
    margin-bottom: 2px;
    color: rgba(0, 0, 0, 0.6);
    ${required &&
    css`
      &::after {
        content: '*';
        display: inline-block;
        color: red;
        margin-left: 3px;
      }
    `};
  `}
`;

export const RadioStyled = styled(Radio)`
  & .MuiIconButton-root {
    padding: 0 !important;
  }
  & .MuiButtonBase-root {
    padding: 0 !important;
  }
  & .MuiRadio-root {
    padding: 0 !important;
  }
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  ${({ theme }) => css`
    && .MuiFormControlLabel-label {
      color: ${theme.palette.gray?.[1000]};
    }
  `}
`;

export default AccountSetting;
