import React from 'react';

import { Box, CircularProgress, IconButton } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';

import useFetchEvent from 'src/hooks/use-fetch-event';
import CloseIcon from 'src/icons/close';
import { ActionButton, GeneralInformationPopupTitle } from 'src/pages/profile/components/general-information.styled';
import { FooterSticky, HeaderSticky } from 'src/pages/profile/shared/additional-information.popup';
import { Close } from 'src/pages/user-management/shared/user-detail.component';
import { getAreaCode } from 'src/utils/areaCode';

import FillForm from './Form';
import { REGISTRATION_FORMS_INIT_VALUE, REGISTRATION_FORMS_VALIDATION } from './validate';

interface ICreateTicketProps {
  onClose: () => void;
  onSave: (val: any) => void;
  isLoading?: boolean;
}

const CreateTicket = ({ onClose, onSave, isLoading }: ICreateTicketProps) => {
  const { id } = useParams<{ id: string }>();
  const { event, loading: isFetchingEvent } = useFetchEvent(id);
  if (isFetchingEvent || !event) return <div />;
  return (
    <Box px={3}>
      <Formik
        initialValues={REGISTRATION_FORMS_INIT_VALUE(event!)}
        validationSchema={REGISTRATION_FORMS_VALIDATION}
        onSubmit={(values) => {
          let val: any = {};
          Object.entries(values).forEach((entry) => {
            const [key, value] = entry;
            val[key] = typeof value === 'string' ? value.trim() : value;

            val[key] =
              key === 'mobilePhone' ||
              key === 'areaCode' ||
              key === 'givenName' ||
              key === 'surName' ||
              key === 'periodStudyInNPP' ||
              key === 'periodStudyInNYP' ||
              key === 'periodStudyInNYGH' ||
              key === 'periodStudyInNYK'
                ? value?.trim()
                : isNaN(value)
                ? value
                : Number(value);
          });
          val['totalTicket'] =
            +values.ticketForStandardMeal + +values.ticketForVegetarian + +values.ticketForHalal || +values.totalTicket;
          val = Object.entries(val).reduce((a: any, [k, v]: any) => (v !== '' ? ((a[k] = v), a) : a), {});
          val = { ...val, numberAttendedAge: +values.numberAttendedAge };
          val['areaCode'] = getAreaCode(values.areaCode);
          onSave(val);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <HeaderSticky>
                <GeneralInformationPopupTitle>Create New Ticket</GeneralInformationPopupTitle>
                <Close onClick={onClose}>
                  <CloseIcon fill="white" />
                </Close>
              </HeaderSticky>
              <FillForm isAdminForm formik={formik} event={event!} />
              <FooterSticky>
                <Box width="100%" display="flex" justifyContent="flex-end">
                  <ActionButton isBig type="submit" variants="yellow" disabled={isLoading}>
                    {isLoading && (
                      <IconButton>
                        <CircularProgress size={20} />
                      </IconButton>
                    )}
                    Submit
                  </ActionButton>
                </Box>
              </FooterSticky>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CreateTicket;
