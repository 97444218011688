import React from 'react';

import { Box, CircularProgress, IconButton, InputAdornment } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { useUpdateProfilePasswordMutation } from 'src/api/endpoints/profiles.endpoint';
import { InputField } from 'src/components';
import SuccessSnackbar from 'src/components/snackBar/success';
import { USER_MESSAGE } from 'src/constants/users.constants';
import { PROFILE_VALIDATION_MESSAGE } from 'src/constants/validation-message.constant';
import { logout } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';

import { ActionButton } from './general-information.styled';

const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = React.useState(false);
  const [, setShowSnackBarError] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState({
    currentPassword: false,
    newPassword: false,
    verifyPassword: false,
  });
  const [inlineErrors, setInlineErrors] = React.useState({
    currenPassword: '',
    newPassword: '',
    verifyPassword: '',
  });

  const [
    updateProfilePassword,
    {
      isLoading: isUpdatingProfilePassword,
      isError: isUpdatingProfilePasswordError,
      isSuccess: isUpdatingProfilePasswordSuccess,
      data,
      error,
    },
  ] = useUpdateProfilePasswordMutation();

  React.useEffect(() => {
    const responseError = error as any;
    if (responseError?.data?.message === USER_MESSAGE.WRONG_PASSWORD) {
      setInlineErrors((errors) => ({ ...errors, currenPassword: USER_MESSAGE.WRONG_PASSWORD }));
    }
    if (responseError?.data?.message === USER_MESSAGE.CURRENT_PASSWORD_NOT_CORRECT) {
      setInlineErrors((errors) => ({ ...errors, currenPassword: USER_MESSAGE.CURRENT_PASSWORD_NOT_CORRECT }));
    }
    if (responseError?.data?.message === USER_MESSAGE.SAME_CURRENT_PASSWORD) {
      setInlineErrors((errors) => ({ ...errors, newPassword: USER_MESSAGE.SAME_CURRENT_PASSWORD }));
    }
    if (responseError?.data?.message === USER_MESSAGE.DUPLICATE_PASSWORD) {
      setInlineErrors((errors) => ({ ...errors, newPassword: USER_MESSAGE.DUPLICATE_PASSWORD }));
    }
  }, [error]);

  React.useEffect(() => {
    if (isUpdatingProfilePasswordError) {
      setShowSnackBarError(true);
    }
    if (isUpdatingProfilePasswordSuccess) {
      setShowSnackBarSuccess(true);
    }
  }, [isUpdatingProfilePasswordError, isUpdatingProfilePasswordSuccess, isUpdatingProfilePassword]);

  React.useEffect(() => {
    if (isUpdatingProfilePasswordSuccess) {
      setTimeout(() => {
        dispatch(logout());
        window.location.pathname = '/';
      }, 2000);
    }
  }, [isUpdatingProfilePasswordSuccess, dispatch]);

  function handleCloseSnackbar(type: 'success' | 'error') {
    return function () {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  }

  const form = useFormik({
    initialValues: {
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required(PROFILE_VALIDATION_MESSAGE.PASSWORD),
      newPassword: Yup.string()
        .required(PROFILE_VALIDATION_MESSAGE.PASSWORD)
        .test(
          'PASSWORD_AT_LATEST_ONE_UPPERCASE',
          PROFILE_VALIDATION_MESSAGE.PASSWORD_AT_LATEST_ONE_UPPERCASE,
          (val) => {
            return /[A-Z]+/.test(val!);
          },
        )
        .test(
          'PASSWORD_AT_LATEST_ONE_LOWERCASE',
          PROFILE_VALIDATION_MESSAGE.PASSWORD_AT_LATEST_ONE_LOWERCASE,
          (val) => {
            return /[a-z]+/.test(val!);
          },
        )
        .test(
          'PASSWORD_AT_LATEST_ONE_SPECIAL_CHARACTER',
          PROFILE_VALIDATION_MESSAGE.PASSWORD_AT_LATEST_ONE_SPECIAL_CHARACTER,
          (val) => {
            return /[*@!#%&()^~{}]+/.test(val!);
          },
        )
        .test('PASSWORD_AT_LATEST_ONE_NUMBER', PROFILE_VALIDATION_MESSAGE.PASSWORD_AT_LATEST_ONE_NUMBER, (val) => {
          return /\d+/.test(val!);
        })
        .test('PASSWORD_MUST_HAVE_SPACE', PROFILE_VALIDATION_MESSAGE.PASSWORD_MUST_HAVE_SPACE, (val) => {
          return !/\s/.test(val!);
        })
        .test('PASSWORD_VALIDATION_STRONG', PROFILE_VALIDATION_MESSAGE.PASSWORD_LENGTH, (val) => {
          const passwordRegex = /.{8,32}/;
          return passwordRegex.test(val!);
        }),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], PROFILE_VALIDATION_MESSAGE.PASSWORD_NOT_MATCH)
        .required(PROFILE_VALIDATION_MESSAGE.PASSWORD),
    }),
    onSubmit: ({ password, newPassword }) => {
      setInlineErrors({ currenPassword: '', newPassword: '', verifyPassword: '' });
      updateProfilePassword({ password, newPassword });
    },
  });

  function handleClickShowPassword(type: 'currentPassword' | 'newPassword' | 'verifyPassword') {
    return function () {
      setShowPassword((values) => ({ ...values, [type]: !values[type] }));
    };
  }
  function handleMouseDownPassword() {}

  return (
    <Box maxWidth={410} mx="auto" py={4}>
      <SuccessSnackbar
        open={isShowSnackBarSuccess}
        handleClose={handleCloseSnackbar('success')}
        message={(data as any)?.message}
      />
      <form onSubmit={form.handleSubmit}>
        <InputField
          value={form.values.password}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          margin="dense"
          name="password"
          title="Current Password"
          require
          fullWidth
          type={showPassword.currentPassword ? 'text' : 'password'}
          error={(form.touched.password && !!form.errors.password) || inlineErrors.currenPassword}
          errormessage={(form.touched.password && form.errors.password) || inlineErrors.currenPassword}
          notice
          noticeYellow
          noticeTitle={
            <div color="inherit" style={{ whiteSpace: 'pre-line' }}>
              {PROFILE_VALIDATION_MESSAGE.PASSWORD_TOOLTIP}
            </div>
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword('currentPassword')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword.currentPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <InputField
          value={form.values.newPassword}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          margin="dense"
          name="newPassword"
          title="New Password"
          require
          fullWidth
          type={showPassword.newPassword ? 'text' : 'password'}
          error={(form.touched.newPassword && !!form.errors.newPassword) || inlineErrors.newPassword}
          errormessage={(form.touched.newPassword && form.errors.newPassword) || inlineErrors.newPassword}
          notice
          noticeYellow
          noticeTitle={
            <div color="inherit" style={{ whiteSpace: 'pre-line' }}>
              {PROFILE_VALIDATION_MESSAGE.PASSWORD_TOOLTIP}
            </div>
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword('newPassword')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword.newPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <InputField
          value={form.values.confirmNewPassword}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          margin="dense"
          name="confirmNewPassword"
          title="Verify Password"
          require
          fullWidth
          type={showPassword.verifyPassword ? 'text' : 'password'}
          error={form.touched.confirmNewPassword && !!form.errors.confirmNewPassword}
          errormessage={form.touched.confirmNewPassword && form.errors.confirmNewPassword}
          notice
          noticeYellow
          noticeTitle={
            <div color="inherit" style={{ whiteSpace: 'pre-line' }}>
              {PROFILE_VALIDATION_MESSAGE.PASSWORD_TOOLTIP}
            </div>
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword('verifyPassword')}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword.verifyPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box display="flex" justifyContent="center" mt={3}>
          <ActionButton type="submit" variants="yellow" disabled={isUpdatingProfilePassword}>
            {isUpdatingProfilePassword && (
              <IconButton>
                <CircularProgress size={20} />
              </IconButton>
            )}
            Submit
          </ActionButton>
        </Box>
      </form>
    </Box>
  );
};

export default ChangePassword;
