export const QUESTION_TYPES = [
  {
    label: 'Single Choice',
    value: 'single-choice',
  },
  {
    label: 'Short Paragraph',
    value: 'short-paragraph',
  },
];
