import { rootApi } from '../api';

const usersApi = rootApi.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    fetchUserById: query<any, string>({
      query: (id) => `/users/${id}`,
      providesTags: (result) => {
        return [{ type: 'USERS', id: 'USER_DETAIL' }];
      },
    }),
    deleteUserById: mutation<any, string>({
      query: (id) => ({
        url: `/users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (status, _, response) => {
        return [{ type: 'USERS', id: 'USER_DETAIL' }];
      },
    }),
    updateUserById: mutation<any, any>({
      query: ({ id, ...body }) => ({
        url: `users/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (status, _, response) => {
        return [{ type: 'USERS', id: 'USER_DETAIL' }];
      },
    }),
    updateUserRole: mutation<
      any,
      {
        action: 'update' | 'remove';
        ids: string[];
        role: string;
      }
    >({
      query: (body) => ({
        url: '/users/update-role',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (status, _, response) => {
        return [{ type: 'USERS', id: 'USER_DETAIL' }];
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchUserByIdQuery,
  useUpdateUserByIdMutation,
  useDeleteUserByIdMutation,
  useUpdateUserRoleMutation,
} = usersApi;
