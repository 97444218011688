import * as React from 'react';

import { Box, Button, Menu, MenuItem, MenuProps, Typography, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import DefaultAvatar from 'src/medias/images/default_avatar.png';
import { Avatar } from 'src/pages/profile/shared/profile.image.styled';
import { IUser } from 'src/services/users';

import { IMenu } from '../type';

interface IBasicMenuProps {
  text?: string | React.ReactNode;
  me?: IUser | null;
  active?: boolean;
  menuItem?: IMenu[] | null;
  extend?: boolean;
  logout?: () => void;
  isLogin?: boolean;
  link?: string;
  grayColor?: boolean;
  isMobile?: boolean;
}

const MenuItemStyled = styled(MenuItem)`
  ${({ theme }) => css`
    min-width: 160px;
    padding: 0;
    &&:hover {
      background-color: ${theme.palette.yellow?.[900]};
      a {
        color: white;
      }
    }
  `}
`;

export default function BasicMenu(props: IBasicMenuProps) {
  const { text, menuItem, logout, isLogin, active, me } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }
  function handleLogout() {
    logout?.();
    handleClose();
  }
  return (
    <Box height={'100%'} display={'flex'} alignItems={'center'}>
      <Box>
        {me && (
          <UserName>
            Hello {me.surname}, {me.givenName}
          </UserName>
        )}
      </Box>
      <MenuButtonLink
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        active={active}
        grayColor={props.grayColor}
        isMobile={props.isMobile}
      >
        {text}
        {me && (
          <Box display={'flex'}>
            <UserBox display="flex" alignItems="center">
              <Avatar width={'38px'} height={'38px'} imageUrl={me?.avatar || DefaultAvatar} />
            </UserBox>
          </Box>
        )}
      </MenuButtonLink>
      {menuItem && (
        <StyledMenu id="basic-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {menuItem.map((item: any, idx: number) =>
            item.logout ? (
              isLogin ? (
                <MenuItemStyled key={idx} onClick={handleLogout}>
                  <LinkStyled to={'#'}>{item.title}</LinkStyled>
                </MenuItemStyled>
              ) : null
            ) : (
              <MenuItemStyled key={idx} onClick={handleClose}>
                <LinkStyled to={item.link}>{item.title}</LinkStyled>
              </MenuItemStyled>
            ),
          )}
        </StyledMenu>
      )}
    </Box>
  );
}
const MenuButtonLink = styled(Button)<{ active?: boolean; grayColor?: boolean; isMobile?: boolean }>`
  ${({ theme, active, grayColor, isMobile }) => css`
    font-size: ${theme.fonts.textSBold.fontSize};
    font-weight: ${theme.fonts.textSBold.fontWeight};
    line-height: ${theme.fonts.textSBold.lineHeight};
    color: ${active ? theme.palette.yellow?.[900] : grayColor ? '#00000080' : theme.palette.common.black};
    text-decoration: none;
    cursor: pointer;
    height: 100%;
    margin-left: 10px;
    ${isMobile &&
    css`
      display: flex;
      justify-content: flex-start;
    `}
  `}
`;

const LinkStyled = styled(Link)`
  ${({ theme }) => ({
    ...theme.fonts.textS,
  })}
  text-decoration: none;
  height: 100%;
  color: black;
  width: 100%;
  padding: 6px 16px;
`;
const StyledMenu = withStyles({
  paper: {
    borderRadius: 0,
    boxShadow: '0px 3px 20px rgb(0 0 0 / 15%)',
    marginTop: '16px',
  },
  list: {
    padding: 0,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));
const UserName = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textSs,
  color: 'rgba(51, 51, 51, 1)',
  textAlign: 'right',
}));

const UserBox = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
}));
