import React from 'react';

import { useRouteMatch } from 'react-router-dom';

import { useFetchProfileQuery } from 'src/api/endpoints/profiles.endpoint';
import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import { HeaderMobile } from 'src/components/headerMobile';
import { ACCOUNT_TYPE } from 'src/constants/users.constants';
import DefaultAvatar from 'src/medias/images/default_avatar.png';
import useResponsive from 'src/utils/responsive';

import ProfileImageInformation from '../shared/profile.image';

import { LayoutContainer, LayoutWrapper, ProfileLayoutContainer } from './profile.styled';

interface IProfileLayoutProps {
  children?: React.ReactNode;
}

const ProfileLayout = ({ children }: IProfileLayoutProps) => {
  const { path } = useRouteMatch();
  const { data: profile } = useFetchProfileQuery();
  const responsive = useResponsive();

  return (
    <LayoutWrapper pt={responsive.isTabletOrMobile ? 15 : 3.75}>
      {responsive.isDesktopOrLaptop && <NSAABreadcrumbs path={path} />}
      {responsive.isTabletOrMobile && <HeaderMobile title={`Profile`} userProfile={profile?.data!} />}
      <LayoutContainer
        responsive={responsive}
        factoryScale={responsive.isMobile ? 0.3 : responsive.isTablet ? 0.6 : 1}
        w={window.innerWidth}
        h={window.innerHeight}
      >
        <ProfileLayoutContainer>
          <ProfileImageInformation
            nsaaId={profile?.data?.username ? profile?.data?.username : ''}
            imageUrl={profile?.data?.avatar || DefaultAvatar}
            accountType={ACCOUNT_TYPE.find((i) => i.value === profile?.data?.accountType)?.label || ''}
          />
          {children}
        </ProfileLayoutContainer>
      </LayoutContainer>
    </LayoutWrapper>
  );
};

export default ProfileLayout;
