import React, { useCallback } from 'react';

import { Box, Popover, Tooltip } from '@material-ui/core';
import { find } from 'lodash';
import moment from 'moment';

import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import NSAATable, { INSAAColumn, INSAADataSource, INSAATableSearch } from 'src/components/table';
import { CALL_SUCCESS, FORMAT_DATE_DMY } from 'src/constants/common';
import addIcon from 'src/medias/icons/add-icon.png';
import DeleteIcon from 'src/medias/icons/delete-icon.png';
import ViewIcon from 'src/medias/icons/view-icon.png';
import { deletePost, getAllPost, IPostCmsNews, publicPost } from 'src/services/news';

import { MOCK_UP_FAIL_RESPONSE } from '../../Homepage/type';
import {
  Actions,
  ButtonStatus,
  CustomButtonStyled,
  IconButtonStyled,
  IconStyled,
  IListCMSNews,
  ImageIconStyled,
  IMenuPosition,
  ISearchNewsCMS,
  KeyboardArrowDownIconStyled,
  MenuItemStyled,
  Title,
} from '../type';

let newsActions = [
  { action: 'Publish News', isDisable: false },
  { action: 'Unpublished News', isDisable: false },
  { action: 'Delete News', isDisable: false },
];
function ListCMSNews({
  data,
  setData,
  _onCreatePost,
  _onClickView,
  loading,
  searchData,
  setLoading,
  setSearch,
  _setErrorMessage,
  _setIsOnSearch,
  isOnSearch,
  searchValue,
}: IListCMSNews) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [menuPosition, setMenuPosition] = React.useState<IMenuPosition | null>(null);
  const [idsSelected, setIdsSelected] = React.useState<string[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;
  const handleClose = () => {
    setAnchorEl(null);
    setMenuPosition(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
    setAnchorEl(event.currentTarget);
  };
  const onSelectedItems = (ids: any) => {
    setIdsSelected(ids);
  };
  const fetchData = useCallback(
    async (search: INSAATableSearch) => {
      setLoading(true);
      const defaultPage = 1;
      const params = {
        page: isOnSearch ? defaultPage : search.page,
        limit: search.limit,
      };
      const response = await getAllPost({ ...params, search: { ...searchValue } });

      if (response?.statusCode === CALL_SUCCESS) {
        _setIsOnSearch(false);
        setData(response.data.items);
        setLoading(false);

        return response;
      } else {
        setLoading(false);
        return MOCK_UP_FAIL_RESPONSE;
      }
    },

    [searchData, isOnSearch],
  );
  const builderColumns = useCallback(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'Title',
        dataIndex: 'Title',
        title: 'Title',
        width: 130,
        ellipsis: true,
      },
      {
        key: 'IsFeatureItem',
        dataIndex: 'IsFeatureItem',
        title: 'Is Feature New?',
        width: 115,
        render: (value, record) => {
          return <div>{value === 'yes' ? 'Yes' : 'No'}</div>;
        },
      },
      {
        key: 'PublishTime',
        dataIndex: 'PublishTime',
        title: 'Publish Date',
        width: 115,
      },
      {
        key: 'Status',
        dataIndex: 'Status',
        title: 'Status',
        width: 115,
        render: (value, record) => {
          let variants: 'public' | 'un_public' = value === 'public' ? 'public' : 'un_public';
          return <ButtonStatus variants={variants}>{value === 'public' ? 'Published' : 'Unpublished'}</ButtonStatus>;
        },
      },
      {
        key: 'UpdatedAt',
        dataIndex: 'UpdatedAt',
        title: 'Update Date',
        width: 115,
      },
      {
        key: 'UpdatedBy',
        dataIndex: 'UpdatedBy',
        title: 'Update By',
        width: 115,
        render: (value, record) => {
          const { surname, givenName } = record;
          return <div>{`${surname}${givenName ? ', ' : '- '}${givenName}`}</div>;
        },
      },
      {
        key: 'Actions',
        dataIndex: 'Actions',
        title: '',
        width: 115,
        render: (value, record) => {
          return (
            <Actions>
              <Tooltip title="View details">
                <IconButtonStyled onClick={_onClickView(value)}>
                  <ImageIconStyled src={ViewIcon} />
                </IconButtonStyled>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButtonStyled onClick={_onSelectAction('Delete News', [value.id])}>
                  <ImageIconStyled src={DeleteIcon} />
                </IconButtonStyled>
              </Tooltip>
            </Actions>
          );
        },
      },
    ];
    return columns;
  }, []);

  const builderDataSource = (data: any[]) => {
    const dataSource = data.map((item: any, idx: number) => ({
      key: idx,
      id: item.id,
      Title: item.title,
      IsFeatureItem: item.isFeatureItem,
      PublishTime: item.publishTime ? moment(+item.publishTime).format(FORMAT_DATE_DMY) : '-',
      Status: item.status,
      UpdatedAt: item.updatedAt ? moment(+item.updatedAt).format(FORMAT_DATE_DMY) : '-',
      UpdatedBy: item.user ? `${item.user.surname}, ${item.user.givenName}` : '-',
      Actions: item,
      surname: item.user ? item.user.surname : '',
      givenName: item.user ? item.user.givenName : '',
    }));
    return dataSource as unknown as INSAADataSource[];
  };
  const _onSelectAction = (action: string, deleteId?: string[]) => async () => {
    setIsLoading(true);
    if ((idsSelected && idsSelected.length > 0) || deleteId) {
      if (action !== 'Delete News') {
        let params = {
          ids: [...idsSelected],
          action: action === 'Publish News' ? 'public' : 'unpublic',
        };
        const response = await publicPost(params);
        if (response?.statusCode === CALL_SUCCESS) {
          setSearch((item: ISearchNewsCMS) => ({ ...searchData }));
        } else {
          _setErrorMessage({
            isError: true,
            message: response?.message,
          });
        }
      }
      if (action === 'Delete News') {
        if (deleteId) {
          const response = await deletePost(deleteId);
          if (response?.statusCode === CALL_SUCCESS) {
            setSearch((item: ISearchNewsCMS) => ({ ...searchValue }));
          }
        } else {
          const response = await deletePost([...idsSelected]);
          if (response?.statusCode === CALL_SUCCESS) {
            setSearch((item: ISearchNewsCMS) => ({ ...searchValue }));
          }
        }
      }
      setAnchorEl(null);
      setMenuPosition(null);
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  return (
    <Box>
      {isLoading && <LoadingCustom loading={isLoading} loadingIcon />}

      <Box display="flex" justifyContent="space-between" alignItems="left">
        <Title>Listing</Title>
        <Box>
          <CustomButtonStyled onClick={_onCreatePost}>
            <IconStyled src={addIcon} />
            Create
          </CustomButtonStyled>
          <CustomButtonStyled disabled={idsSelected && idsSelected.length === 0} outlineButton onClick={handleClick}>
            Action
            <KeyboardArrowDownIconStyled />
          </CustomButtonStyled>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {newsActions.map((action) => {
              const SelectedItems: string[] = [];
              if (idsSelected && idsSelected.length > 0 && data) {
                idsSelected.forEach((id: string) => {
                  const findData = find(data, (obj: IPostCmsNews) => {
                    return obj?.id === id;
                  });
                  if (findData) {
                    SelectedItems.push(findData?.status);
                  }
                });
              }
              let isAllPublish = false;
              let isAllUnPublish = false;
              if (SelectedItems && SelectedItems.length > 0) {
                isAllPublish = SelectedItems.every((value: string) => value === 'public');
                isAllUnPublish = SelectedItems.every((value: string) => value === 'un_public');
              }
              if (isAllPublish && !isAllUnPublish) {
                newsActions[0].isDisable = true;
                newsActions[1].isDisable = false;
              }
              if (!isAllPublish && isAllUnPublish) {
                newsActions[0].isDisable = false;
                newsActions[1].isDisable = true;
              }
              if (!isAllPublish && !isAllUnPublish) {
                newsActions[0].isDisable = true;
                newsActions[1].isDisable = true;
              }
              return (
                <MenuItemStyled
                  onClick={_onSelectAction(action.action)}
                  key={action.action}
                  value={action.action}
                  disabled={action.isDisable}
                >
                  {action.action}
                </MenuItemStyled>
              );
            })}
          </Popover>
        </Box>
      </Box>
      <Box mt={2} mb={2}>
        <NSAATable
          builderColumns={builderColumns}
          builderDataSource={builderDataSource}
          isShowCheckBoxes
          fetchData={fetchData}
          loading={loading}
          isShowPagination={true}
          onSelectedItems={onSelectedItems}
          isSearchReset={isOnSearch}
        />
      </Box>
    </Box>
  );
}

export default ListCMSNews;
