import React from 'react';

import { AppBar, Box, Toolbar, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { menuUser } from 'src/layouts/main/type';
import { selectUserProfile } from 'src/store/authentication/selector';
import { logout } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';

import headerLogo from '../../../medias/images/main-nsaa-logo.png';

import BasicMenu from './BasicMenu';

export const Header = () => {
  const dispatch = useAppDispatch();
  const userProfile = useSelector(selectUserProfile);

  function _onLogout() {
    dispatch(logout());
  }

  return (
    <div>
      <AppBarStyled>
        <Container>
          <Box pl={10} color={'black'} fontWeight={'bold'}>
            Nanyang Schools Alumni Association | 南洋中小幼校友会
          </Box>
          <Text bold={false}>
            English | <span>中文</span>
          </Text>
        </Container>
        <ToolbarCustom>
          <div>
            <NavLink to="/">
              <LogoHeader src={headerLogo} alt="" />
            </NavLink>
          </div>
          <BoxCustom sx={{ flexGrow: 1 }} color="black" display="flex" height={'100%'}>
            <BoxStyled>
              <NavLinkStyled exact activeClassName="header__nav-active" to="/user-management">
                <BasicMenu text={'USER MANAGEMENT'} link="/user-management" />
              </NavLinkStyled>
            </BoxStyled>
            <BoxStyled>
              <NavLinkStyled strict activeClassName="header__nav-active" to="/event-management">
                <BasicMenu text={'EVENT MANAGEMENT'} link="/event-management" />
              </NavLinkStyled>
            </BoxStyled>
            <BoxStyled>
              <NavLinkStyled exact activeClassName="header__nav-active" to="/attendance-management">
                <BasicMenu text={'ATTENDANCE MANAGEMENT'} link="/attendance-management" />
              </NavLinkStyled>
            </BoxStyled>
            <BoxStyled>
              <NavLinkStyled strict activeClassName="header__nav-active" to="/payment-management">
                <BasicMenu text={'PAYMENT MANAGEMENT'} link="/payment-management" />
              </NavLinkStyled>
            </BoxStyled>
            <BoxStyled>
              <NavLinkStyled exact activeClassName="header__nav-active" to="/gtky-applicant">
                <BasicMenu text={'GTKY APPLICANT'} link="/gtky-applicant" />
              </NavLinkStyled>
            </BoxStyled>
            <BoxStyled>
              <NavLinkStyled exact activeClassName="header__nav-active" to="/email-tracking">
                <BasicMenu text={'EMAIL TRACKING'} link="/email-tracking" />
              </NavLinkStyled>
            </BoxStyled>
            <BoxStyled>
              <NavLinkStyled exact activeClassName="header__nav-active" to="/cms">
                <BasicMenu text={'CMS'} link="/cms" />
              </NavLinkStyled>
            </BoxStyled>
          </BoxCustom>
          <Box display="flex" width={'20%'} justifyContent="flex-end" alignItems="center">
            <BasicMenu extend={true} menuItem={menuUser} logout={_onLogout} userProfile={userProfile} />
          </Box>
        </ToolbarCustom>
      </AppBarStyled>
    </div>
  );
};

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.yellow?.[900],
  height: theme.spacing(3.75),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Text = styled(Typography)<{ bold?: boolean }>`
  ${({ theme, bold }) => ({
    paddingRight: 80,
    color: theme.palette.common.black,
    ...theme.fonts.textSSBold,
    fontWeight: bold ? 700 : 300,
    fontSize: 14,
  })}
  span {
    ${({ theme }) => ({
      color: theme.palette.common.black,
      ...theme.fonts.textSS,
    })}
  }
`;
const ToolbarCustom = styled(Toolbar)(
  ({ theme }) => css`
    && {
      padding: 32px 68px 32px 68px;
      height: 120px;
    }
  `,
);

const LogoHeader = styled.img`
  && {
    height: 90px;
    width: 70px;
  }
`;
const BoxCustom = styled(Box)`
  && {
    padding-left: 5%;
  }
`;
const AppBarStyled = styled(AppBar)`
  && {
    background: ${({ theme }) => theme.palette.common.white};
    position: fixed;
    z-index: 999;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 10%);
  }
`;

const NavLinkStyled = styled(NavLink)`
  && {
    font-size: 20px;
    text-decoration: none;
    height: 100%;
    margin-right: 15px;
  }
  ${({ theme }) => css`
    position: relative;
    &.header__nav-active {
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
      .MuiButtonBase-root {
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 100%;
          height: 5px;
          background-color: ${theme.palette.yellow?.[900]};
          bottom: 0px;
          left: 0;
        }
      }
    }
  `}
`;

const BoxStyled = styled(Box)<{ active?: boolean }>`
  ${({ theme, active }) => css`
    position: relative;
    &:not(:first-child) {
      margin-left: 22px;
    }
    ${active &&
    css`
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
      .MuiButtonBase-root {
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 100%;
          height: 5px;
          background-color: ${theme.palette.yellow?.[900]};
          bottom: 0;
          left: 0;
        }
      }
    `}
  `}
`;
