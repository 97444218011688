import { Box, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

import RegistrationBackground from 'src/medias/images/background-signup.png';

export const RegistrationFormContainer = styled(Box)`
  width: 100%;
  min-height: 100vh;
  background-image: ${`url(${RegistrationBackground})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Description = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.textSs.fontSize};
    font-weight: ${theme.fonts.textSs.fontWeight};
  `}
`;
export const DescriptionLink = styled.a`
  ${({ theme }) => css`
    color: ${theme.palette.yellow?.[900]};
    font-size: ${theme.fonts.textS.fontSize};
    font-weight: ${theme.fonts.textS.fontWeight};
  `}
`;

export const Title = styled(Typography)<{ mb?: boolean; responsive?: any }>`
  ${({ theme, mb, responsive }) => css`
    color: #333333;
    font-size: ${theme.fonts.header3.fontSize};
    font-weight: ${theme.fonts.header3.fontWeight};
    line-height: 28px;
    ${mb &&
    css`
      margin-bottom: 32px;
    `}

    ${responsive?.isTabletOrMobile &&
    css`
      margin-bottom: 20px;
    `}
  `}
`;
export const Subtitle = styled(Typography)<{ mb?: string; variants?: string; responsive?: any }>`
  ${({ theme, mb, variants, responsive }) => css`
    color: #333333;
    font-size: ${theme.fonts.header4.fontSize};
    font-weight: ${theme.fonts.header4.fontWeight};
    ${mb &&
    css`
      margin-bottom: ${mb};
    `}
    ${responsive?.isTabletOrMobile &&
    variants === 'success' &&
    css`
      font-size: 16px;
      color: #000000cc;
      font-style: italic;
      font-weight: 400;
      margin-top: 16px;
    `}
  `}
`;
export const PaymentMethodImage = styled.img`
  display: block;
  margin: auto;
  width: 100%;
  height: 100%;
`;

export const PaymentMethodBorder = styled(Box)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    width: 453px;
    max-width: 100%;5
    height: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin: 30px auto;
  `}
`;
export const BigTitle = styled(Typography)`
  ${({ theme }) => css`
    color: #333333;
    font-size: ${theme.fonts.header4S.fontSize};
    font-weight: ${theme.fonts.header4S.fontWeight};
  `}
`;

export const SuccessContainer = styled(Box)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    width: 1052px;
    margin: auto;
    text-align: center;

    ${responsive.isTabletOrMobile &&
    css`
      max-width: 100%;
    `}
  `}
`;

export const CorrectImage = styled.img`
  width: 100px;
  height: 100px;
  margin-top: 60px;
`;

export const CheckoutContainer = styled(Box)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    max-width: 527px;
    width: 100%;
    height: 300px;
    margin: auto;
    border-radius: 16px;
    border: 1px solid ${theme.palette.yellow?.[900]};
    padding: 0 76px;
    margin-bottom: 60px;
    ${responsive.isMobile &&
    css`
      width: 100%;
      padding: 0 50px;
    `}
  `}
`;

export const OrderTitle = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.yellow?.[900]};
    text-transform: uppercase;
    padding-top: 40px;
    font-size: ${theme.fonts.header3.fontSize};
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
  `}
`;
export const OrderLabel = styled(Typography)<{ bold?: boolean }>`
  ${({ theme, bold }) => css`
    color: #333333;
    font-weight: 500;
    font-size: 16px;

    ${bold &&
    css`
      font-weight: 700;
      margin-top: 10px;
      font-size: 18px;
    `}
  `}
`;

export const Line = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.palette.yellow?.[900]};
    height: 1px;
    width: 100%;
  `}
`;

export const QrContainer = styled(Box)`
  background: white;
  border: 1px solid lightgray;
  border-radius: 6px;
  display: inline-block;
  padding: 20px;
`;
