import { Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { CustomButton } from 'src/components';

export const accessToETicket = [
  { key: 'yes', value: 'Yes' },
  { key: 'no', value: 'No' },
];

export const volunteerStatus = [
  { key: 'pending', value: 'Pending' },
  { key: 'confirmed', value: 'Confirmed' },
  { key: 'incomplete', value: 'Incomplete' },
  { key: 'complete', value: 'Completed' },
];

export const attendanceStatus = {
  pending: 'pending',
  incomplete: 'incomplete',
  complete: 'complete',
  confirmed: 'confirmed',
};

export const eventStatus = {
  draft: 'draft',
  onGoing: 'on-going',
  pending: 'pending',
  complete: 'complete',
};

export const defaultSearchVolunteer = {
  search: '',
  status: '',
  scanTicket: '',
};

export const actionsFlag = [
  { key: 'Send Email', value: 'Send Email' },
  { key: 'allow-access', value: 'Allow access to E-ticket checking' },
  { key: 'remove-access', value: 'Remove access to E-ticket checking' },
  { key: 'remove-volunteer', value: 'Remove Volunteer' },
];

export const waitListActionsFlag = [
  { key: 'Send Email', value: 'Send Email' },
  { key: 'remove-volunteer', value: 'Remove volunteer' },
  { key: 'add-to-confirmed-list', value: 'Add to confirmed list' },
];

export const TypoItalicStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(113, 128, 150, 1)',
  fontStyle: 'italic',
}));

export const CustomButtonStyled = styled(CustomButton)(() => ({
  width: `180px !important`,
}));

export const TextStyled = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: 'rgba(113, 128, 150, 1)',
}));
export const NoteText = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(255, 21, 21, 1)',
  fontStyle: 'italic',
}));

export const ButtonStatus = styled(Typography)<{
  variants?: 'pending' | 'incomplete' | 'complete' | 'confirmed' | undefined;
}>`
  ${({ theme, variants }) => css`
    padding: 6px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    background-color: ${variants === 'pending'
      ? theme.palette.yellow?.[150]
      : variants === 'incomplete'
      ? theme.palette.red?.[150]
      : variants === 'complete'
      ? theme.palette.green?.[900]
      : variants === 'confirmed'
      ? theme.palette.yellow?.[1000]
      : null};
    font-size: 13px;
    color: white;
    text-transform: capitalize;
  `}
`;

export const NameStyled = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: '#979168',
}));

export const NameTextDefaultStyled = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
}));
