import React, { useEffect, useState } from 'react';

import { Box, MenuItem, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { CustomInputSelect } from 'src/components';
import CustomFormDialog from 'src/components/formDialog';
import { CALL_SUCCESS } from 'src/constants/common';
import { getTemplateSendGrid } from 'src/services/users';
import { IVolunteer } from 'src/services/volunteer';

interface ISendEmailModal {
  _onCloseModal: () => void;
  open: boolean;
  _onSubmit?: () => void;
  userSelected: IVolunteer[] | undefined;
  template: string;
  setTemplate: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean | undefined;
}
const SendEmailModal = ({
  loading,
  _onCloseModal,
  open,
  _onSubmit,
  userSelected,
  template,
  setTemplate,
}: ISendEmailModal) => {
  const [templates, setTemplates] = useState<{ id: string; name: string }[]>([]);
  const [errors, setErrors] = useState({
    hasTemplate: false,
  });

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTemplate(e.target.value);
    if (e.target.value !== 'none') {
      setErrors({ ...errors, hasTemplate: false });
    } else {
      setErrors({ ...errors, hasTemplate: true });
    }
  }

  const handleSubmit = () => {
    if (template === 'none') {
      setErrors({ ...errors, hasTemplate: true });
      return;
    }

    _onSubmit?.();
  };

  const handleClose = () => {
    _onCloseModal?.();
    setErrors({ ...errors, hasTemplate: false });
  };

  async function getData() {
    const response = await getTemplateSendGrid();

    if (response?.statusCode === CALL_SUCCESS) {
      setTemplates(response?.data?.templates);
    }
  }

  useEffect(() => {
    if (open) {
      getData();
    }
  }, [open]);

  return (
    <CustomFormDialog
      title="Select Email Template"
      open={open}
      _onCloseModal={handleClose}
      submitText="Send"
      _onSubmit={handleSubmit}
      disabled={loading}
    >
      <Box display="flex" flex={1} mt={1} mb={2}>
        <Box width={'60%'}>
          <LabelStyled>Receiver</LabelStyled>
          <SelectBox mt={1} style={{ overflowY: 'auto' }}>
            {[...new Set(userSelected)]?.map((user, index) => {
              return (
                <SelectBoxItem p={1} index={index}>
                  <TextStyled>
                    {`${user.user.surname}, ${user.user.givenName} ( ${user.user.emailAddress} )`.length > 55
                      ? `${user.user.surname}, ${user.user.givenName} ( ${user.user.emailAddress} )`.slice(0, 55) +
                        '...'
                      : `${user.user.surname}, ${user.user.givenName} ( ${user.user.emailAddress} )`}
                  </TextStyled>
                </SelectBoxItem>
              );
            })}
          </SelectBox>
        </Box>
        <Box width={'40%'}>
          <Box ml={3}>
            <LabelStyled>Template</LabelStyled>
            <Box>
              <CustomInputSelect
                name="blockReason"
                value={template}
                fullWidth
                notRequired
                onChange={_handleChange}
                error={errors.hasTemplate}
                errorMessage="This field is required"
              >
                <MenuItem key="none" value="none">
                  <TextStyled>--- Choose one template ---</TextStyled>
                </MenuItem>
                {templates?.map((item) => {
                  return (
                    <MenuItem key={item?.id} value={item?.id}>
                      <TextStyled>{item?.name?.length > 40 ? item?.name?.slice(0, 40) + '...' : item?.name}</TextStyled>
                    </MenuItem>
                  );
                })}
              </CustomInputSelect>
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomFormDialog>
  );
};

export default SendEmailModal;

const LabelStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.6)',
}));

const SelectBox = styled(Box)(() => ({
  border: '1px solid #ccc',
  height: 205,
  borderRadius: 6,
}));

const SelectBoxItem = styled(Box)(({ index }: { index: number }) => ({
  backgroundColor: index % 2 ? '#F8F9FA' : 'white',
  borderBottom: '1px solid #ccc',
}));

const TextStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
}));
