import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import { HeaderMobile } from 'src/components/headerMobile';
import { CALL_SUCCESS } from 'src/constants/common';
import useFetchMe from 'src/hooks/use-fetch-me';
import { Header as HeaderAdmin } from 'src/layouts/admin/components/header';
import { Header } from 'src/pages/user-management/types';
import {
  getGTKYApplication,
  IEventGTKY,
  IGTKYApplication,
  IInterview,
  ISearchGTKYApplication,
} from 'src/services/volunteer';
import { IMeta } from 'src/types';
import useResponsive from 'src/utils/responsive';

import ApplicantInfo from './components/ApplicantInfo';
import Listing from './components/Listing';
import SearchGTKY from './components/Search';
import { defaultSearch, GTKYContainer } from './types';

export const GTKYApplicant = () => {
  const LISTING_TAB = 1;
  const VIEW_DETAIL_TAB = 2;
  const { me } = useFetchMe();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<IGTKYApplication[]>([]);
  const [pagination, setPagination] = useState<IMeta>();
  const [events, setEvents] = useState<IEventGTKY[]>([]);
  const [interviewers, setInterviewers] = useState<IInterview[]>([]);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<ISearchGTKYApplication>(defaultSearch);
  const responsive = useResponsive();

  const [activeTab, setActiveTab] = useState(LISTING_TAB);
  const [user, setUser] = useState<IGTKYApplication>();

  async function getData({
    search,
    currentPage,
    limit,
  }: {
    search?: ISearchGTKYApplication;
    currentPage: number;
    limit: number;
  }) {
    setLoading(true);
    const response = await getGTKYApplication({ search, currentPage, limit });

    if (response?.statusCode === CALL_SUCCESS) {
      setItems(response.data.gtkyApplicantList.items);
      setPagination(response.data.gtkyApplicantList.meta);
      setEvents(response.data.event);
      setInterviewers(response.data.interview);
    }
    setLoading(false);
  }

  const _onSearch = (searchData: ISearchGTKYApplication) => () => {
    setSearch(searchData);
    getData({
      search: searchData,
      currentPage: 1,
      limit,
    });
    setCurrentPage(1);
  };

  const _onReset = async () => {
    setLoading(true);
    setSearch(defaultSearch);
    setCurrentPage(1);

    const response = await getGTKYApplication({
      search: defaultSearch,
      currentPage: 1,
      limit,
    });

    if (response?.statusCode === CALL_SUCCESS) {
      setItems(response.data.gtkyApplicantList.items);
      setPagination(response.data.gtkyApplicantList.meta);
      setEvents(response.data.event);
      setInterviewers(response.data.interview);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData({
      search,
      currentPage,
      limit,
    });
  }, [currentPage, limit]);

  useEffect(() => {
    if (me && me.committeeMember !== 'yes' && me.roleName === null) {
      window.location.href = '/not-access';
    }
  }, [me]);

  return (
    <Box overflow="auto" width="100%">
      <HeaderMobile title="GTKY Applicant" userProfile={me} />
      {responsive.isDesktopOrLaptop && <HeaderAdmin />}
      <Box pt={responsive.isTabletOrMobile ? 15 : 3.75} />
      <GTKYContainer responsive={responsive}>
        {activeTab === LISTING_TAB && (
          <Box display="flex" flex={1} minHeight={890}>
            <Box display="flex" flex={1} flexDirection="column" mt={5} mb={5} ml={10} mr={10}>
              <Header>GTKY APPLICANT LIST</Header>
              <SearchGTKY
                events={events}
                interviewers={interviewers}
                searchData={search}
                loading={loading}
                _onSearch={_onSearch}
                _onReset={_onReset}
              />
              <Listing
                items={items}
                data={pagination}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                loading={loading}
                limit={limit}
                setLimit={setLimit}
                getData={getData}
                search={search}
                setActiveTab={setActiveTab}
                setUser={setUser}
              />
            </Box>
          </Box>
        )}
        {activeTab === VIEW_DETAIL_TAB && (
          <ApplicantInfo userId={user?.userId} registrationId={user?.id} setActiveTab={setActiveTab} />
        )}
      </GTKYContainer>
    </Box>
  );
};
