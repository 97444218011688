import React, { useEffect, useState } from 'react';

import { Table, TableBody, Box, TableCell } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import { CustomPagination, LinearIndeterminate } from 'src/components';
import NoDataImage from 'src/medias/images/no-data-available.svg';
import { IMeta, IResponseList } from 'src/types';
import { intRegex } from 'src/utils/common';

import { CheckboxCustom } from '../checkbox';

import {
  NSAATableBodyCell,
  NSAATableBodyCheckbox,
  NSAATableContainer,
  NSAATableHead,
  NSAATableHeader,
  NSAATableHeaderCell,
  NSAATableHeaderCheckbox,
  NSAATableRow,
  NoDataImageStyled,
  StyledTableSortLabel,
} from './index.styled';

export interface INSAAColumn {
  title: string;
  dataIndex: string;
  key: string;
  align?: 'left' | 'center' | 'right' | 'justify' | 'inherit' | undefined;
  width?: number;
  ellipsis?: boolean;
  render?: (val: any, record: any) => React.ReactNode;
}

export type INSAADataSource = {
  key: string;
  id: string;
  [key: string]: any;
};

export interface INSAATableSearch {
  page?: number;
  limit?: number;
}
interface INSAATable {
  columns?: INSAAColumn[];
  dataSource?: INSAADataSource[];
  isShowCheckBoxes?: boolean;
  onSelectedItems?: (ids: any) => void;
  loading?: boolean;
  paginationTitle?: string;
  isShowPagination?: boolean;
  isSearchReset?: boolean;
  builderColumns?: () => INSAAColumn[];
  builderDataSource?: (data: any) => INSAADataSource[];
  fetchData?: (search: INSAATableSearch) => Promise<IResponseList<any> | null>;
  disabledSort?: boolean;
  disabledCheckBoxes?: boolean;
}

type Order = 'asc' | 'desc';

const NSAATable = ({
  columns,
  dataSource,
  isShowCheckBoxes,
  loading,
  isShowPagination,
  paginationTitle,
  isSearchReset,
  onSelectedItems,
  builderColumns,
  builderDataSource,
  fetchData,
  disabledSort,
  disabledCheckBoxes,
}: INSAATable) => {
  const [isCheckAllBoxes, setCheckAllBoxes] = useState(false);
  const [idItemSelected, setIdItemSelected] = useState<string[]>([]);
  const [tableColumns, setTableColumns] = useState<INSAAColumn[]>([]);
  const [tableDataSource, setTableDataSource] = useState<INSAADataSource[]>();
  const [limit, setLimit] = useState(10);
  const [searchPage, setSearchPage] = useState('');
  const [page, setPage] = useState(1);
  const [disableSearchPage, setDisableSearchPage] = React.useState(false);
  const [meta, setMeta] = useState<IMeta>();
  const isResetSearchRef = React.useRef<boolean>(false);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string | number | symbol>('');

  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (a: { [key in Key]: string }, b: { [key in Key]: string }) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    return stabilizedThis.map((el) => {
      return el[0];
    });
  }

  useEffect(() => {
    const searchFields = { limit, page };
    (async () => {
      const response = await fetchData?.(searchFields!);
      if (response) {
        setMeta(response?.data.meta);
        setTableDataSource(builderDataSource?.(response?.data?.items || []));
        isResetSearchRef.current = false;
      } else {
        setMeta((meta) => meta);
        setTableDataSource((tableDataSource) => tableDataSource);
        isResetSearchRef.current = false;
      }
    })();
  }, [limit, page, fetchData]);

  useEffect(() => {
    setPage(1);
  }, [isSearchReset]);

  useEffect(() => {
    if (columns && dataSource) {
      setTableColumns(columns);
      setTableDataSource(dataSource);
    }
    if (builderColumns) {
      setTableColumns(builderColumns());
    }
  }, [columns, dataSource, builderColumns, builderDataSource]);

  useEffect(() => {
    !!onSelectedItems && onSelectedItems(idItemSelected);
  }, [idItemSelected, onSelectedItems]);

  useEffect(() => {
    if (loading) {
      setIdItemSelected([]);
      setCheckAllBoxes(false);
    }
  }, [loading]);

  function handleCheckboxAllChange(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
    if (!checked) {
      setIdItemSelected((items) => []);
      setCheckAllBoxes((status) => checked);
    } else {
      if (!!dataSource) {
        !!dataSource && setIdItemSelected((item) => dataSource.map((item) => item['id']));
      } else {
        !!tableDataSource && setIdItemSelected((item) => tableDataSource.map((item) => item['id']));
      }

      setCheckAllBoxes((status) => checked);
    }
  }
  function handleItemCheckboxChange(id: string) {
    return function (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
      if (isCheckAllBoxes) {
        setCheckAllBoxes(false);
        setIdItemSelected((items) => items.filter((item) => item !== id));
      } else {
        if (checked) {
          setIdItemSelected((items) => [...items, id]);
        } else {
          setIdItemSelected((items) => items.filter((item) => item !== id));
        }
      }
    };
  }

  let colSpan = tableColumns?.length + 1;

  const handleChangePagination = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || e.target.value === '0') {
      setSearchPage('');
      return;
    }
    if (e.target.name === 'searchPage') {
      if (meta?.totalPages) {
        if (!intRegex.test(e.target.value)) {
          setDisableSearchPage(true);
        } else {
          if (Number(e.target.value) > meta?.totalPages) {
            setDisableSearchPage(true);
          } else {
            setDisableSearchPage(false);
            setSearchPage(e.target.value);
          }
        }
      } else {
        setDisableSearchPage(true);
      }
    }
    if (e.target.name === 'records/page') {
      setLimit(Number(e.target.value));
      setSearchPage('');
      setPage(1);
    }
  };
  const onClickChangePage = () => {
    setPage(Number(searchPage));
  };
  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setSearchPage(valueTrimmed);
  };

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const createSortHandler = (property: string) => (event: React.MouseEvent<unknown>) => {
    handleRequestSort(event, property);
  };
  return (
    <>
      <NSAATableContainer style={{ maxHeight: 490 }}>
        <Table stickyHeader style={{ overflowX: 'auto' }}>
          <NSAATableHead>
            <NSAATableHeader>
              {isShowCheckBoxes && (
                <NSAATableHeaderCheckbox>
                  <CheckboxCustom
                    color="primary"
                    checked={isCheckAllBoxes}
                    onChange={handleCheckboxAllChange}
                    disabled={disabledCheckBoxes}
                  />
                </NSAATableHeaderCheckbox>
              )}
              {!!tableColumns &&
                tableColumns.map(({ key, title, width, ...props }) => {
                  return (
                    <NSAATableHeaderCell
                      key={key}
                      style={{ minWidth: width || 'auto' }}
                      sortDirection={orderBy === key ? order : false}
                      {...props}
                    >
                      <StyledTableSortLabel
                        disabled={disabledSort || key === 'Actions'}
                        hideSortIcon={disabledSort || key === 'Actions'}
                        active={orderBy === key}
                        direction={orderBy === key ? order : 'asc'}
                        onClick={createSortHandler(key)}
                        IconComponent={ArrowDropDownIcon}
                      >
                        {title}
                      </StyledTableSortLabel>
                    </NSAATableHeaderCell>
                  );
                })}
            </NSAATableHeader>
          </NSAATableHead>
          {!loading && tableDataSource && tableDataSource.length === 0 && (
            <NSAATableRow striped={false}>
              <TableCell colSpan={colSpan}>
                <Box display="flex" flex={1} justifyContent="center" pt={4} pb={4}>
                  <NoDataImageStyled src={NoDataImage} />
                </Box>
              </TableCell>
            </NSAATableRow>
          )}
          {!loading && (
            <TableBody>
              {!!tableDataSource &&
                stableSort(tableDataSource, getComparator(order, orderBy)).map((row, index) => {
                  return (
                    <NSAATableRow striped={index % 2 === 0}>
                      {isShowCheckBoxes && (
                        <NSAATableBodyCheckbox>
                          <CheckboxCustom
                            defaultChecked={false}
                            checked={isCheckAllBoxes || idItemSelected.includes(row['id'])}
                            onChange={handleItemCheckboxChange(row['id'])}
                            disabled={disabledCheckBoxes && !idItemSelected.includes(row['id'])}
                          />
                        </NSAATableBodyCheckbox>
                      )}
                      {!!tableColumns &&
                        tableColumns.map(({ key, dataIndex, title, align, render, width, ellipsis = false }, idx) => (
                          <NSAATableBodyCell
                            w={width || 'auto'}
                            key={`${idx}-${JSON.stringify(row[dataIndex])}`}
                            ellipsis={ellipsis}
                          >
                            <span> {!!render ? render(row[dataIndex], row) : row[dataIndex]}</span>
                          </NSAATableBodyCell>
                        ))}
                    </NSAATableRow>
                  );
                })}
            </TableBody>
          )}
        </Table>
        {loading && <LinearIndeterminate />}
      </NSAATableContainer>
      {isShowPagination && (
        <CustomPagination
          itemName={paginationTitle || ''}
          data={meta}
          limit={limit}
          handleChangePagination={handleChangePagination}
          currentPage={page}
          setCurrentPage={setPage}
          onClickChangePage={onClickChangePage}
          disableSearchPage={disableSearchPage || searchPage === ''}
          searchPage={searchPage}
          onBlurField={onBlurField}
        />
      )}
    </>
  );
};

export default React.memo(NSAATable);
