export const CALL_SUCCESS = 200;
export const FORMAT_TIME_AM_PM = 'DD/MM/YYYY hh:mm A';
export const FORMAT_DATE_DMY = 'DD/MM/YYYY';
export const FORMAT_DATE_YMD = 'YYYY/MM/DD';
export const DATE_PICKER_DISPLAY_FORMAT = 'dd/MM/yyyy';
export const FORMAT_DATE_MONTH_NAME = 'DD MMM YYYY hh:mm A';
export const TEXT_REQUIRE = 'This field is required';
export const MAX_LENGTH_15 = 15;
export const MAX_LENGTH_50 = 50;
export const MAX_LENGTH_128 = 128;
export const MAX_LENGTH_225 = 225;
export const IMAGE_1MB_SIZE = 1024;
export const ERROR_MESSAGE_IMAGE = 'Format file: PNG, JPG and file must be less than 1MB';
export const PLACEHOLDER_DATE_PICKER = 'dd/mm/yyyy';
export const ACCEPT_TYPE = {
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
};
export const FORBIDDEN = 403;
