import React from 'react';

import { AppBar, Box, Toolbar, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { CustomButton } from 'src/components/button';

import headerLogo from '../../../medias/images/menu-bar-logo.png';

export interface IMenu {
  title: string;
  link: string;
  logout?: boolean;
}

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.yellow?.[900],
  height: theme.spacing(3.75),
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
}));

const Text = styled(Typography)`
  ${({ theme }) => ({
    paddingRight: '5.33%',
    color: theme.palette.common.black,
    ...theme.fonts.textSSBold,
  })}
  span {
    ${({ theme }) => ({
      color: theme.palette.common.black,
      ...theme.fonts.textSS,
    })}
  }
`;
const ToolbarCustom = styled(Toolbar)(
  ({ theme }) => css`
    && {
      padding-right: 5.33%;
      padding-left: 68px;
      height: 120px;
    }
  `,
);
const LoginBtn = styled(CustomButton)`
  &&& {
    ${({ theme }) => css`
      background-color: ${theme.palette.yellow?.[900]} !important;
      border-radius: 3px;
      width: 120px;
      height: 36px;
      box-shadow: none;
      font-size: 16px;
      font-weight: 500;
    `}
  }
`;
export const Header = () => {
  return (
    <Box>
      <AppBarStyled>
        <Container>
          <Text>
            English | <span>中文</span>
          </Text>
        </Container>
        <ToolbarCustom>
          <Box>
            <NavLink to="/">
              <LogoHeader src={headerLogo} alt="" />
            </NavLink>
          </Box>
          <Box display="flex" flex={1} justifyContent="flex-end" alignItems="center">
            <ButtonNavLink to="/login">
              <LoginBtn>Log In</LoginBtn>
            </ButtonNavLink>
          </Box>
          )
        </ToolbarCustom>
      </AppBarStyled>
    </Box>
  );
};

const LogoHeader = styled.img`
  && {
    height: 90px;
    width: 70px;
  }
`;

const AppBarStyled = styled(AppBar)`
  && {
    background: ${({ theme }) => theme.palette.common.white};
    position: fixed;
    z-index: 99999;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }
`;
const NavLinkStyled = styled(NavLink)`
  && {
    font-size: 20px;
    text-decoration: none;
    height: 100%;
    margin-right: 15px;
  }
  ${({ theme }) => css`
    position: relative;
    &.header__nav-active {
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
      .MuiButtonBase-root {
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 100%;
          height: 5px;
          background-color: ${theme.palette.yellow?.[900]};
          bottom: 20px;
          left: 0;
        }
      }
    }
  `}
`;
const ButtonNavLink = styled(NavLinkStyled)`
  && {
    margin-right: 0;
  }
`;
