import React from 'react';

import { Box, Button, IconButton, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowNextIcon from '@material-ui/icons/ArrowForward';
import { NavLink } from 'react-router-dom';
import Slider, { Settings } from 'react-slick';
import styled, { css } from 'styled-components';

import ArrowRightAltIcon from 'src/icons/arrow-right-alt';
import { IFeatureHomepage } from 'src/services/homepage';
import useResponsive from 'src/utils/responsive';

import { DiaLogContent, IResponsive, MoreBtn } from '../../homepageStyled';

interface ISliders {
  slides: IFeatureHomepage[];
  responsive: IResponsive;
  current: number;
  setCurrent: React.Dispatch<React.SetStateAction<number>>;
}
export const SliderCustom = styled(Slider)<{ checkResponsive?: IResponsive }>`
  width: 1440px;
  padding-left: 16px;
  overflow: hidden;
  & .slick-slide {
    padding-right: 16px;
  }
  && {
    width: 100%;
  }

  && .slick-dots li {
    margin: 0;
  }
  ${({ theme, checkResponsive }) => css`
    height: ${checkResponsive?.isMobile ? '50vh' : '80vh'};
    && .slick-dots {
      display: flex !important;
      justify-content: center;
      gap: ${checkResponsive?.isMobile ? 0 : '30px'};
    }
  `}
`;
export const SliderContainer = styled(Box)`
  width: 100%;
  overflow: hidden;
`;
export const NextArrowCustom = (props: any) => {
  const { className, style, onClick } = props;
  const responsive = useResponsive();
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        zIndex: 10,
        width: responsive.isMobile ? 50 : 100,
        height: responsive.isMobile ? 50 : 100,
        paddingRight: responsive.isMobile ? 102 : 0,
        paddingTop: responsive.isMobile ? 30 : 0,
      }}
      onClick={onClick}
    >
      <IconButton>
        <ArrowNextIcon
          fontSize="large"
          style={{ color: 'white', fontSize: 50, padding: 10, background: '#C5BE8B', borderRadius: 9999, opacity: 0.5 }}
        />
      </IconButton>
    </div>
  );
};

export const PrevArrowCustom = (props: any) => {
  const responsive = useResponsive();
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        zIndex: 10,
        width: responsive.isMobile ? 50 : 100,
        height: responsive.isMobile ? 50 : 100,
        paddingLeft: 30,
        paddingTop: responsive.isMobile ? 30 : 0,
      }}
      onClick={onClick}
    >
      <IconButton>
        <ArrowBackIcon
          fontSize="large"
          style={{ color: 'white', fontSize: 50, padding: 10, background: '#C5BE8B', borderRadius: 9999, opacity: 0.5 }}
        />
      </IconButton>
    </div>
  );
};

export default function Sliders({ slides, responsive, current, setCurrent }: ISliders) {
  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }
  const settings: Settings = {
    afterChange: (current: number) => setCurrent(current),
    customPaging: (i: number) => {
      return <Dot responsive={responsive} active={current === i} />;
    },
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    dots: true,
    dotsClass: 'slick-dots',
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: 'linear',
    pauseOnHover: true,
    arrows: true,
    nextArrow: <NextArrowCustom />,
    prevArrow: <PrevArrowCustom />,
  };
  return (
    <SliderContainer>
      <SliderCustom checkResponsive={responsive} {...settings}>
        {slides &&
          slides.map((slide, index) => {
            return (
              <Box position={'relative'}>
                <BoxImage
                  src={slide?.cover_photo ? slide?.cover_photo : 'https://fakeimg.pl/300/'}
                  max-width="100%"
                  width="auto"
                  height={'100%'}
                  responsive={responsive}
                />
                {!responsive.isMobile && (
                  <DialogBox>
                    <DialogTitle>{slide?.title}</DialogTitle>
                    <DiaLogContent>{slide?.short_description}</DiaLogContent>
                    <NavLinkStyled to={slide.is_event ? `/events/${slide.id}` : `/news/${slide.id}`}>
                      <CustomBtn>Learn More</CustomBtn>
                    </NavLinkStyled>
                  </DialogBox>
                )}
              </Box>
            );
          })}
      </SliderCustom>
      <Box width="100%">
        {slides &&
          slides.map((slide, index) => {
            return (
              <>
                {responsive.isMobile && index === current && (
                  <DialogBoxMobile>
                    <TitleBox display="flex" justifyContent="space-between" alignItems="flex-start" width={'100%'}>
                      <DialogTitleMobile responsive={responsive}>{slide?.title}</DialogTitleMobile>
                      <Box display="flex" pt={3} pr={3}>
                        <NavLinkStyled to={slide.is_event ? `/events/${slide.id}` : `/news/${slide.id}`}>
                          <MoreBtn responsive={responsive}>LEARN MORE</MoreBtn>
                        </NavLinkStyled>
                        <ArrowRightAltIcon style={{ marginTop: 0 }} />
                      </Box>
                    </TitleBox>
                    <DiaLogContentMobile>{slide?.short_description}</DiaLogContentMobile>
                  </DialogBoxMobile>
                )}
                {false && <div />}
              </>
            );
          })}
      </Box>
    </SliderContainer>
  );
}
const NavLinkStyled = styled(NavLink)`
  && {
    text-decoration: none;
  }
`;
const BoxImage = styled.img<{ responsive?: IResponsive }>`
  width: 100%;
  height: 80vh;
  object-fit: contain;

  ${({ responsive }) => css`
    ${responsive?.isMobile &&
    css`
      height: 40vh;
      margin-top: 90px;
    `}
  `}
`;
const DialogBox = styled(Box)`
  ${({ theme }) => css`
    && {
      position: absolute;
      width: 40%;
      max-width: 480px;
      height: auto;
      left: 5%;
      top: 15%;
      opacity: 0.8;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 24px 48px;
      z-index: 1;
      background-color: ${theme.palette.red?.[25]};
    }
  `}
`;
const DialogTitle = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
}));
// const DiaLogContent = styled(Typography)(({ theme }) => ({
//   ...theme.fonts.textL,
//   margin: '10px 0px',
// }));
const CustomBtn = styled(Button)`
  && {
    ${({ theme }) => css`
      font-size: ${theme.fonts.textSs.fontSize};
      font-weight: ${theme.fonts.textSs.fontWeight};
      line-height: ${theme.fonts.textSs.lineHeight};
      border: 1px solid ${theme.palette.yellow?.[900]};
      color: ${theme.palette.yellow?.[900]};
    `}
  }
`;
const Dot = styled.span<{ responsive: IResponsive; active?: boolean }>`
  ${({ theme, responsive, active }) => css`
    && {
      position: absolute;
      background: ${theme.palette.yellow?.[900]};
      opacity: ${active ? 1 : 0.5};
      cursor: pointer;
      border-radius: 10px;
      width: ${responsive.isMobile ? '6px' : '35px'};
      height: 6px;
    }
  `}
`;
const DialogBoxMobile = styled(Box)`
  ${({ theme }) => css`
    && {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      z-index: 1;
    }
  `}
`;
const TitleBox = styled(Box)`
  ${({ theme }) => css`
    && {
      gap: 10px;
      margin-bottom: 4px;
    }
  `}
`;
const DialogTitleMobile = styled(Typography)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => ({
    ...theme.fonts.header3,
    fontSize: responsive?.isMobile ? theme.fonts.header5.fontSize : theme.fonts.header3.fontSize,
    padding: '24px 0px 0px 24px',
  })}
`;
const DiaLogContentMobile = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textM,
  fontStyle: 'italic',
  padding: '0px 16px 0px 16px',
  marginBottom: '48px',
}));
