import React from 'react';

import useResponsive from 'src/utils/responsive';

import { Container } from './type';

interface ICommonSlide {
  data: any[];
  render: (val: any) => React.ReactNode;
}
function CommonSlide({ data, render }: ICommonSlide) {
  const responsive = useResponsive();
  return (
    <Container
      pl={responsive.isTabletOrMobile ? 2 : 0}
      pr={responsive.isTabletOrMobile ? 2 : 0}
      overflow={responsive.isTabletOrMobile ? 'auto' : 'visible'}
      justifyContent={responsive.isTabletOrMobile ? 'flex-start' : 'center'}
    >
      {data.map((item) => render(item))}
    </Container>
  );
}

export default CommonSlide;
