import React, { useState } from 'react';

import { Box } from '@material-ui/core';

import LockIcon from 'src/icons/lock';
import useResponsive from 'src/utils/responsive';

import { ProfileTab, ProfileTabMobile, ProfileTabsContainer, TextProfileTabStyled } from './profile.tabs.styled';

interface IProfileTabsProps {
  tabs: { label: string; disabled: boolean }[];
  panels: React.ReactNode[];
}

const ProfileTabpanel = ({ children, value, index }: { value: number; index: number; children: React.ReactNode }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const ProfileTabs = ({ tabs, panels }: IProfileTabsProps) => {
  const { isTabletOrMobile } = useResponsive();
  const [currentTab, setCurrentTab] = useState(0);
  function handleTabChange(_: any, value: number) {
    setCurrentTab(value);
  }
  return (
    <Box>
      <ProfileTabsContainer
        value={currentTab}
        indicatorColor="primary"
        onChange={handleTabChange}
        isTabletOrMobile={isTabletOrMobile}
      >
        {tabs.map((item, idx) => {
          return isTabletOrMobile ? (
            <ProfileTabMobile
              key={idx}
              disabled={item.disabled}
              label={
                <TextProfileTabStyled display="inline">
                  {item.disabled && (
                    <LockIcon style={{ position: 'relative', top: 2 }} width={20} height={20} fill="#00000099" />
                  )}
                  <TextProfileTabStyled display="inline">{item.label}</TextProfileTabStyled>
                </TextProfileTabStyled>
              }
            />
          ) : (
            <ProfileTab
              key={idx}
              disabled={item.disabled}
              label={
                <TextProfileTabStyled display="inline">
                  {item.disabled && (
                    <LockIcon style={{ position: 'relative', top: 2 }} width={20} height={20} fill="#00000099" />
                  )}
                  <TextProfileTabStyled display="inline">{item.label}</TextProfileTabStyled>
                </TextProfileTabStyled>
              }
            />
          );
        })}
      </ProfileTabsContainer>
      {panels.map((Panel, idx) => {
        return (
          <ProfileTabpanel value={currentTab} index={idx}>
            {Panel}
          </ProfileTabpanel>
        );
      })}
    </Box>
  );
};

export default ProfileTabs;
