import React, { useEffect, useState } from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

import Banner from 'src/components/banner';
import { HeaderMobileChinese } from 'src/components/headerMobileChinese';
import { CALL_SUCCESS } from 'src/constants/common';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import email from 'src/medias/icons/email-icon.png';
import location from 'src/medias/icons/location.png';
import facebook from 'src/medias/images/facebook-blue-theme.png';
import instagram from 'src/medias/images/instagram.png';
import logoItem2 from 'src/medias/images/logo-item-2.png';
import logoItem3 from 'src/medias/images/logo-item-3.png';
import logoItem4 from 'src/medias/images/logo-item-4.png';
import logoItem6 from 'src/medias/images/logo-item-6.png';
import logoItem7 from 'src/medias/images/logo-item-7.png';
import { ImageItem, LinkItem } from 'src/pages/contact-us';
import { IResponsive } from 'src/pages/home/homepageStyled';
import { getCMSData, ICms } from 'src/services/cms';
import useResponsive from 'src/utils/responsive';

import ContactForm from './contact-form';

const FACEBOOK_LINK = 'https://www.facebook.com/Nanyang-Schools-Alumni-Association-299559223422037';
const INSTAGRAM_LINK = 'https://instagram.com/nanyang_family?utm_medium=copy_link';
const Container = styled(Box)`
  ${({ theme }) => css`
    padding: 5% 5% 5% 5%;
  `}
`;
const Header = styled(Typography)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    font-size: ${responsive?.isMobile ? theme.fonts.header3.fontSize : theme.fonts.header4S.fontSize};
    font-weight: ${theme.fonts.header4S.fontWeight};
    line-height: ${theme.fonts.header4S.lineHeight};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: ${theme.palette.gray?.[60]};
  `}
`;

const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header4.fontSize};
    font-weight: ${theme.fonts.header4.fontWeight};
    line-height: ${theme.fonts.header4.lineHeight};
    color: ${theme.palette.yellow?.[1000]};

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: ${theme.spacing(2)}px;
  `}
`;
const LogoContact = styled(Box)``;
const SocialMediaLogo = styled.img`
  ${({ theme }) => css`
    height: 42px;
    width: 42px;
    margin: 0px 8px;
  `}
`;
const BoxContainer = styled(Box)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    display: flex;
    align-items: ${responsive?.isMobile ? 'center' : 'stretch'};
    justify-content: center;
    padding: 20px;
    flex-direction: ${responsive?.isMobile ? 'column' : 'row'};
    gap: 16px;
    flex-wrap: ${responsive?.isTablet ? 'wrap' : 'nowrap'};
  `}
`;

const BoxItem = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: ${theme.spacing(3)}px;
    min-width: 302px;
    max-width: 302px;
    margin: 0px ${theme.spacing(1.5)}px;
    border-radius: ${theme.spacing(1)}px;
    flex-basis: 45%;
  `}
`;
const BoxItemSmaller = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${theme.spacing(2)}px;
  `}
`;
const ContentDetail = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
  `}
`;
const Logo = styled.img`
  ${({ theme }) => css`
    padding-right: 11px;
  `}
`;
const InfoBox = styled(Typography)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  `}
`;
const BoldText = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.textSBold.fontSize};
    font-weight: ${theme.fonts.textSBold.fontWeight};
    line-height: ${theme.fonts.textSBold.lineHeight};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: ${theme.spacing(0.5)}px;
  `}
`;
const Text = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.textS.fontSize};
    font-weight: ${theme.fonts.textS.fontWeight};
    line-height: ${theme.fonts.textS.lineHeight};
    color: ${theme.palette.gray?.[1000]};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  `}
`;
const LinkText = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.textS.fontSize};
    font-weight: ${theme.fonts.textS.fontWeight};
    line-height: ${theme.fonts.textS.lineHeight};
    color: ${theme.palette.blue?.[1000]};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  `}
`;
const ContactUsCn = () => {
  const responsive = useResponsive();
  useScrollToTop();
  const [data, setData] = useState<ICms>({
    type: '',
    banner_image: '',
    banner_title: '',
    banner_description: '',
    content_title: '',
    content_body: '',
    background_image: '',
    item_image: '',
    item_date: '',
    item_image_description: '',
  });
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const response = await getCMSData('contact-us');
    if (response.statusCode === CALL_SUCCESS) {
      setData(response.data as ICms);
    }
  };
  return (
    <Box mt={responsive.isTabletOrMobile && '90px'}>
      <HeaderMobileChinese title={`联络我们`} />
      <Banner
        image={data.banner_image}
        title={data.banner_title}
        description={data.banner_description}
        responsive={responsive}
      />
      <Container>
        <Header responsive={responsive}>联系方式</Header>
        {responsive.isDesktopOrLaptop && <Box pb={10} />}
        <BoxContainer responsive={responsive}>
          <BoxItem>
            <Title>地址</Title>
            <ContentDetail>
              <BoxItemSmaller>
                <Logo src={location} />
                <InfoBox>
                  <BoldText>Nanyang Girls' High School</BoldText>
                  <Text>2, Linden Drive Singapore 288683</Text>
                </InfoBox>
              </BoxItemSmaller>
            </ContentDetail>
          </BoxItem>
          <BoxItem>
            <Title>电邮</Title>
            <ContentDetail>
              <BoxItemSmaller>
                <Logo src={email} />
                <InfoBox>
                  <BoldText>有关我们会籍事宜的查询</BoldText>
                  <LinkText>membership@nanyang.org.sg</LinkText>
                </InfoBox>
              </BoxItemSmaller>
              <BoxItemSmaller>
                <Logo src={email} />
                <InfoBox>
                  <BoldText>其他查询</BoldText>
                  <LinkText>enquiry@nanyang.org.sg</LinkText>
                </InfoBox>
              </BoxItemSmaller>
            </ContentDetail>
          </BoxItem>
          {/* <BoxItem>
            <Title>传真号</Title>
            <InfoBox>
              <BoldText>NSAA 传真号</BoldText>
              <Text>65 6466 7564</Text>
            </InfoBox>
          </BoxItem> */}
          <BoxItem>
            <Title>社交媒体</Title>
            <LogoContact>
              <Link href={FACEBOOK_LINK}>
                <SocialMediaLogo src={facebook} />
              </Link>
              <Link href={INSTAGRAM_LINK}>
                <SocialMediaLogo src={instagram} />
              </Link>
            </LogoContact>
          </BoxItem>
        </BoxContainer>
        <Header responsive={responsive}>南洋大家庭</Header>
        <BoxContainer responsive={responsive}>
          <BoxItem>
            <LinkItem href="https://www.nygh.edu.sg/" target="_blank" rel="noreferrer">
              <ImageItem src={logoItem2} alt="Nanyang School" />
              <Box textAlign={'center'} pt={3}>
                南洋女子中学校
              </Box>
            </LinkItem>
          </BoxItem>
          <BoxItem>
            <LinkItem href="https://nyps.moe.edu.sg/" target="_blank" rel="noreferrer">
              <ImageItem src={logoItem4} alt="Nanyang School" />
              <Box textAlign={'center'} pt={3}>
                南洋小学
              </Box>
            </LinkItem>
          </BoxItem>
          <BoxItem>
            <LinkItem href="http://www.nanyangkindergarten.com/home.html" target="_blank" rel="noreferrer">
              <ImageItem src={logoItem3} alt="Nanyang School" />
              <Box textAlign={'center'} pt={3}>
                南洋幼稚园
              </Box>
            </LinkItem>
          </BoxItem>
        </BoxContainer>
        <BoxContainer responsive={responsive}>
          <BoxItem>
            <LinkItem href="https://www.facebook.com/NYGH.PSG/" target="_blank" rel="noreferrer">
              <ImageItem src={logoItem7} alt="Nanyang School" />
              <Box textAlign={'center'} pt={3}>
                南洋女中 家长协作组
              </Box>
            </LinkItem>
          </BoxItem>
          <BoxItem>
            <LinkItem href="https://www.nypspta.com" target="_blank" rel="noreferrer">
              <ImageItem src={logoItem6} alt="Nanyang School" />
              <Box textAlign={'center'} pt={3}>
                南洋小学 家长教师协会
              </Box>
            </LinkItem>
          </BoxItem>
        </BoxContainer>
        <ContactForm />
      </Container>
    </Box>
  );
};

export default ContactUsCn;
