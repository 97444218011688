import { Typography, Theme, FormControlLabel, Button } from '@material-ui/core';
import ReactFlagsSelect from 'react-flags-select';
import styled, { css } from 'styled-components';

import { CustomButton } from 'src/components';

export const genders = ['female', 'male'];

export const nationalities = [
  {
    key: 'singapore-citizen',
    value: 'Singapore Citizen',
  },
  {
    key: 'permanent-resident',
    value: 'Permanent Resident (PR)',
  },
  {
    key: 'foreigner',
    value: 'Foreigner',
  },
];

export const TextBold = styled(Typography)(({ theme, mt }: { theme: Theme; mt: boolean }) => ({
  fontSize: 16,
  fontWeight: 500,
  marginTop: mt ? 24 : 0,
  color: 'black',
}));

export const TextBoldMobile = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: 16,
  fontWeight: 400,
  color: '#888888',
}));
export const TextBoldSMobile = styled(Typography)(({ theme }: { theme: Theme }) => ({
  fontSize: 16,
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.6)',
}));

export const TextNote = styled(Typography)(
  ({ theme, isTabletOrMobile }: { theme: Theme; isTabletOrMobile: boolean }) => ({
    fontSize: isTabletOrMobile ? 16 : 24,
    fontWeight: isTabletOrMobile ? 700 : 400,
    marginTop: 16,
    marginBottom: 16,
    color: isTabletOrMobile ? 'rgba(0, 0, 0, 0.8)' : 'rgba(197, 190, 139, 1)',
  }),
);

export const CustomLabel = styled(Typography)<{ font15?: boolean }>(({ theme, font15 }) => ({
  ...theme.fonts.header6,
  marginBottom: 6,
  height: 18,
  color: 'rgba(0, 0, 0, 0.6)',
  whiteSpace: 'nowrap',
  ...(font15 ? { fontSize: 15 } : {}),
}));

export const CustomLabelWrap = styled(Typography)<{ font15?: boolean }>(({ theme, font15 }) => ({
  ...theme.fonts.header6,
  color: 'rgba(0, 0, 0, 0.6)',
  ...(font15 ? { fontSize: 15 } : {}),
}));

export const CustomLabelCheckBox = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textM,
  marginBottom: 8,
  height: 18,
  whiteSpace: 'nowrap',
}));

export const CustomLabelMedium = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textSs,
  color: 'rgba(51, 51, 51, 1)',
}));

export const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  color: 'black',
  ...theme.fonts.textSSBold,
}));

export const CustomTextS = styled(Typography)(({ theme }) => ({
  color: 'black',
  ...theme.fonts.textS,
}));

export const LinkButton = styled(Button)`
  ${({ theme }) => css`
    color: ${theme.palette.yellow?.[900]};
    font-size: ${theme.fonts.textSSBold.fontSize};
    font-weight: ${theme.fonts.textSSBold.fontWeight};
    text-transform: none;
    margin-left: '4px';
  `}
`;

export const CustomButtonStyled = styled(CustomButton)(() => ({
  width: '80px !important',
  height: '34px !important',
  marginLeft: 16,
}));

export const TitleTabletStyled = styled(Typography)(({ theme, isTablet }: { theme: Theme; isTablet: boolean }) => ({
  color: 'rgba(0, 0, 0, 0.8)',
  fontSize: isTablet ? 36 : 24,
  fontWeight: 700,
  marginTop: isTablet ? 68 : 0,
}));

export const LogoMobile = styled.img(({ theme }) => ({
  position: 'absolute',
  top: 100,
  left: '50%',
  transform: 'translate(-50%, -50%)',
}));

export const ReactFlagsSelectStyled = styled(ReactFlagsSelect)(() => ({
  '&': {
    paddingBottom: '0px',
  },
  '& > button': {
    borderRadius: 6,
    borderColor: 'rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
  },
  '& > ul': {
    width: 150,
  },
}));
