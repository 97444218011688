import React from 'react';

import { Box, Grid } from '@material-ui/core';
import { AnyObject, HeadingToolbar, TNode } from '@udecode/plate';
import styled, { css } from 'styled-components';

import { IPost } from 'src/services/news';

import { ToolbarButtons } from '../config/Toolbars';

const HeaderStyled = styled(Box)<{ headerType?: 'event' }>`
  ${({ theme, headerType }) => css`
    position: ${headerType === 'event' ? 'relative' : 'fixed'};
    top: 0;
    background-color: 'white';
    z-index: 10;
    width: 100%;
    flex: 1;
    flex-direction: 'row';
  `}
`;

const HeadingToolbarStyled = styled(HeadingToolbar)<{ headerType?: 'event' }>`
  ${({ theme, headerType }) => css`
    padding: '0px 40px 8px';
    margin-bottom: 0;
    justify-content: 'space-between';
    flex-direction: 'row';
  `}
`;

interface IHeader {
  type: string;
  title: string;
  setNewTitle: React.Dispatch<React.SetStateAction<string>>;
  thumbnail: string;
  setThumbnail: React.Dispatch<React.SetStateAction<string>>;
  shortDescription: string;
  setShortDescription: React.Dispatch<React.SetStateAction<string>>;
  _onCreateDraftNew: () => void;
  _onUpdatePost: () => void;
  content?: TNode<AnyObject>[];
  post?: IPost | null;
  headerType?: 'event';
  disable?: boolean;
}

const Header = ({ headerType }: IHeader) => {
  return (
    <Grid item xs={12}>
      <HeaderStyled headerType={headerType}>
        <HeadingToolbarStyled headerType={headerType}>
          <Box display="flex" flexWrap="wrap">
            <ToolbarButtons />
          </Box>
        </HeadingToolbarStyled>
      </HeaderStyled>
    </Grid>
  );
};

export default Header;
