import React from 'react';

import { Box } from '@material-ui/core';
import moment from 'moment';

import NSAATable, { INSAAColumn, INSAADataSource } from 'src/components/table';
import { FORMAT_TIME_AM_PM } from 'src/constants/common';
import IconSuccess from 'src/medias/icons/icon-success.png';
import { Title, IconVerifyStyled } from 'src/pages/user-management/types';
import { ISearchVolunteer, IVolunteer } from 'src/services/volunteer';
import { shallowEqual } from 'src/utils/common';

import { defaultSearchVolunteer, ButtonStatus, NoteText, NameTextDefaultStyled } from '../../../types';

interface ITable {
  volunteers: IVolunteer[];
  loading?: boolean;
  search: ISearchVolunteer;
  setVolunteerWaitList: React.Dispatch<React.SetStateAction<IVolunteer[]>>;
  volunteerWaitList: IVolunteer[];
  userSelected: IVolunteer[] | undefined;
}

const Listing = ({ volunteers, loading, search, setVolunteerWaitList, volunteerWaitList, userSelected }: ITable) => {
  const onSelectedItems = (ids: IVolunteer[]) => {
    setVolunteerWaitList(ids);
  };

  const columns = React.useMemo(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'NSAAID',
        dataIndex: 'NSAAID',
        title: 'NSAA ID',
        width: 50,
      },
      {
        key: 'Name',
        dataIndex: 'Name',
        title: 'Name',
        width: 130,
        render: (value, record) => {
          return (
            <NameTextDefaultStyled>
              {(value?.user?.surname + ', ' + value?.user?.givenName).length > 18
                ? (value?.user?.surname + ', ' + value?.user?.givenName).slice(0, 18) + '...'
                : value?.user?.surname + ', ' + value?.user?.givenName}
              {value?.user?.verificationStatus === 'verified' && <IconVerifyStyled src={IconSuccess} />}
            </NameTextDefaultStyled>
          );
        },
      },
      {
        key: 'EmailAddress',
        dataIndex: 'EmailAddress',
        title: 'Email Address',
        width: 130,
        ellipsis: true,
      },
      {
        key: 'PhoneNumber',
        dataIndex: 'PhoneNumber',
        title: 'Phone Number',
        width: 100,
      },
      {
        key: 'Status',
        dataIndex: 'Status',
        title: 'Status',
        width: 100,
        render: (value, record) => {
          let variants: 'pending' | 'incomplete' | 'complete' | 'confirmed' | undefined =
            value === 'pending'
              ? 'pending'
              : value === 'incomplete'
              ? 'incomplete'
              : value === 'complete'
              ? 'complete'
              : value === 'confirmed'
              ? 'confirmed'
              : undefined;
          return <ButtonStatus variants={variants}>{value}</ButtonStatus>;
        },
      },
      {
        key: 'RegistrationTime',
        dataIndex: 'RegistrationTime',
        title: 'Registration Time',
        width: 150,
      },
    ];
    return columns;
  }, []);

  const dataSource = React.useMemo(() => {
    const dataSource = volunteers.map((item, idx) => ({
      key: idx,
      id: item,
      NSAAID: item?.user?.username,
      Name: item,
      EmailAddress:
        item?.user?.emailAddress?.length > 15
          ? item?.user?.emailAddress?.slice(0, 15) + '...'
          : item?.user?.emailAddress,
      PhoneNumber:
        item?.user?.areaCode +
        (item?.user?.mobilePhone?.length > 8 ? item?.user?.mobilePhone?.slice(0, 8) + '...' : item?.user?.mobilePhone),
      Status: item?.attendanceStatus,
      RegistrationTime: item?.registrationTime ? moment(+item.registrationTime).format(FORMAT_TIME_AM_PM) : '',
    }));
    return dataSource as unknown as INSAADataSource[];
  }, [volunteers]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="left">
        <Title>{shallowEqual(search, defaultSearchVolunteer) ? `Listing` : `Results`}</Title>
      </Box>

      {userSelected && (
        <Box mt={1}>
          <NoteText>
            You should choose {userSelected.length} person(s) from the waitlist. Selected: {volunteerWaitList.length}{' '}
            person(s).
          </NoteText>
        </Box>
      )}
      <Box mt={2} mb={2}>
        <NSAATable
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          isShowCheckBoxes
          onSelectedItems={onSelectedItems}
          disabledSort
          disabledCheckBoxes={volunteerWaitList.length >= (userSelected ? userSelected.length : 0)}
        />
      </Box>
    </Box>
  );
};

export default Listing;
