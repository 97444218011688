import React, { useCallback, useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import ConfirmPopup from 'src/components/confirm';
import { HeaderMobile } from 'src/components/headerMobile';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import NSAATable, { INSAAColumn, INSAATableSearch } from 'src/components/table';
import {
  CALL_SUCCESS,
  DATE_PICKER_DISPLAY_FORMAT,
  FORBIDDEN,
  FORMAT_DATE_DMY,
  PLACEHOLDER_DATE_PICKER,
} from 'src/constants/common';
import { EventCategory, EventStatus, EventType } from 'src/constants/events.constant';
// import { INPUT_MAX_LENGTH_50 } from 'src/constants/users.constants';
import useRoleAccess from 'src/hooks/use-role-access';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import DuplicateIcon from 'src/icons/duplicate';
import EditIcon from 'src/icons/edit';
import PlusIcon from 'src/icons/plus';
import { Header } from 'src/layouts/admin/components/header';
import CalenderURL from 'src/medias/icons/calendar-icon.svg';
import DeleteIcon from 'src/medias/icons/delete-icon.png';
import {
  FormMenuItem,
  FormTextField,
  GeneralInformationDatePicker,
} from 'src/pages/profile/components/general-information.styled';
import { Label } from 'src/pages/user-management/shared/user-detail.account-settings';
import { ImageIconStyled } from 'src/pages/user-management/types';
import { cloneEventById, deleteEventById, getAllEvents, IEvent } from 'src/services/events';
import { selectUserProfile } from 'src/store/authentication/selector';
import { delayFn } from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import CreateEventDialog from '../../shared/popup';
import { DEFAULT_RESPONSE } from '../guest/types';

import {
  Actions,
  ButtonCreate,
  ButtonDelete,
  ButtonDuplicate,
  ButtonEdit,
  ButtonReset,
  ButtonSearch,
  ButtonStatus,
  Heading,
  ListingCreate,
  ListingSection,
  ListingTitle,
  SearchButton,
  SearchContainer,
  SearchItem,
  SearchSection,
} from './index.styled';

const EVENT_IC = 'event-ic';
const MEMBER_ADMIN = 'membership-admin';
const ONLY_DELETE_DRAFT_CANCEL = 'You can only delete <b>Draft/Cancelled</b> event';
const CONFIRM_DELETE_EVENT = 'Are you sure you want to delete this event?';

interface IFormSearch {
  start_time?: string | null;
  end_time?: string | null;
  type?: string | null;
  category?: string | null;
  status?: string | null;
}

const Search = () => {
  const history = useHistory();
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [isShowConfirmPopup, setShowConfirmPopup] = useState(false);
  const [currentEventId, setCurrentEventId] = useState('');
  const [actionLoading, setActionLoading] = useState(false);
  const [formSearch, setFormSearch] = useState<IFormSearch>({});
  const responsive = useResponsive();
  const userProfile = useSelector(selectUserProfile);

  const ENTER_AT_LEAST_3_CHARACTER = 'Enter at least 3 characters';

  useScrollToTop();
  useRoleAccess([EVENT_IC, MEMBER_ADMIN]);

  useEffect(() => {
    if (isShowSnackBarSuccess) {
      setFormSearch((formSearch) => ({ ...formSearch }));
    }
  }, [isShowSnackBarSuccess]);

  const handleDuplicateItem = (id: any) => async () => {
    const response = await cloneEventById(id);

    if (response?.statusCode === CALL_SUCCESS) {
      setShowSnackBarSuccess(true);
      setShowConfirmPopup(false);
      setMessage(response.message);
      const EVENT_MANAGEMENT_PATH = `/event-management/${response.data}/general`;
      delayFn(() => {
        window.location.pathname = EVENT_MANAGEMENT_PATH;
      }, 0);
    } else {
      if (response?.statusCode === FORBIDDEN) {
        history.push('/not-access');
      }
      setShowSnackBarError(() => true);
      setMessage(response.message);
    }
  };
  const handleDeleteItem = (event: any) => () => {
    const PENDING = 'Pending';
    const ONGOING = 'On-going';
    const COMPLETED = 'Completed';
    const status = event?.['EventStatus'];

    setCurrentEventId(event?.['key']);
    if (status !== ONGOING && status !== PENDING && status !== COMPLETED) {
      setMessage(CONFIRM_DELETE_EVENT);
      setShowConfirmPopup(true);
      return;
    } else {
      setMessage(ONLY_DELETE_DRAFT_CANCEL);
      setShowConfirmPopup(true);
      return;
    }
  };
  const handleEditItem = (id: any) => () => history.push(`/event-management/${id}/general`);

  const builderColumns = React.useCallback(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'EventId',
        dataIndex: 'EventId',
        title: 'Event ID',
        ellipsis: true,
      },
      {
        key: 'EventName',
        dataIndex: 'EventName',
        title: 'Event Name',
        width: 250,
        ellipsis: true,
      },
      {
        key: 'Category',
        dataIndex: 'Category',
        title: 'Category',
        width: 120,
        ellipsis: true,
      },
      {
        key: 'Type',
        dataIndex: 'Type',
        title: 'Type',
        width: 140,
      },
      {
        key: 'EventStatus',
        dataIndex: 'EventStatus',
        title: 'Event Status',
        width: 140,

        render: (value, record) => {
          let variants: 'completed' | 'on-going' | 'pending' | 'cancelled' | 'draft' =
            value === 'Completed'
              ? 'completed'
              : value === 'Pending'
              ? 'pending'
              : value === 'Cancelled'
              ? 'cancelled'
              : value === 'Draft'
              ? 'draft'
              : 'on-going';
          return (
            <ButtonStatus disableRipple variants={variants}>
              {value}
            </ButtonStatus>
          );
        },
      },
      {
        key: 'Date',
        dataIndex: 'Date',
        title: 'Date',
        width: 200,
      },
      {
        key: 'LastModifiedBy',
        dataIndex: 'LastModifiedBy',
        title: 'Last Modified By',
        width: 160,
      },
      {
        key: 'Actions',
        title: '',
        dataIndex: ' Actions',
        width: 150,
        render: (value, record) => {
          const event_id = record?.id?.id;
          return (
            <Actions>
              <ButtonDuplicate onClick={handleDuplicateItem(event_id)}>
                <DuplicateIcon fill="white" />
              </ButtonDuplicate>
              <ButtonEdit onClick={handleEditItem(event_id)}>
                <EditIcon fill="white" />
              </ButtonEdit>
              <ButtonDelete onClick={handleDeleteItem(record)}>
                <ImageIconStyled src={DeleteIcon} />
              </ButtonDelete>
            </Actions>
          );
        },
      },
    ];
    return columns;
  }, []);
  const builderDataSource = useCallback((data: IEvent[]) => {
    return data.map((item, index) => ({
      key: item.id,
      id: item,
      EventId: item.event_id,
      EventName: item.title,
      Category: item.category,
      Type: item.event_type,
      EventStatus: EventStatus.find((i) => i.value === item.status)?.label || '',
      Date: moment(+item?.start_time).format(FORMAT_DATE_DMY) + ' - ' + moment(+item?.end_time).format(FORMAT_DATE_DMY),
      LastModifiedBy: item.updatedBy,
    })) as any;
  }, []);
  const handleTogglePopup = () => setOpenPopup((show) => !show);
  const handleToggleConfirmPopup = () => setShowConfirmPopup((show) => !show);
  const handleConfirmMessage = async () => {
    if (message === CONFIRM_DELETE_EVENT) {
      setActionLoading(true);
      const response = await deleteEventById(currentEventId);
      if (response?.statusCode === CALL_SUCCESS) {
        setShowSnackBarSuccess(true);
        setMessage(response?.message);
        setActionLoading(false);
        handleToggleConfirmPopup();
      } else {
        setShowSnackBarError(true);
        setMessage(response?.message);
        setActionLoading(true);
        handleToggleConfirmPopup();
      }
    }
  };

  const fetchData = useCallback(
    async (search: INSAATableSearch) => {
      const searchFields = {
        ...search,
        ...formSearch,
      };
      setLoading(true);
      const response = await getAllEvents(searchFields);
      if (response?.statusCode === CALL_SUCCESS) {
        setLoading(false);
        setSearching(false);
        return response;
      }
      setLoading(false);
      setSearching(false);
      return DEFAULT_RESPONSE;
    },
    [formSearch],
  );

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };

  return (
    <Box width="100%" overflow="auto">
      <HeaderMobile title="Event Management" userProfile={userProfile} />
      {responsive.isDesktopOrLaptop && <Header />}
      <Box pt={responsive.isTabletOrMobile ? 15 : 3.75} />
      <SearchContainer responsive={responsive}>
        <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
        <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
        <ConfirmPopup
          title="Notification"
          content={message}
          cancelText={message === ONLY_DELETE_DRAFT_CANCEL ? 'Ok' : 'No'}
          okText={message === ONLY_DELETE_DRAFT_CANCEL ? 'Ok' : 'Yes'}
          btnCancelVariant={message === ONLY_DELETE_DRAFT_CANCEL ? 'filled' : 'outlined'}
          btnOkVariant={message === ONLY_DELETE_DRAFT_CANCEL ? 'filled' : 'danger'}
          open={isShowConfirmPopup}
          onCancel={handleToggleConfirmPopup}
          onOk={handleConfirmMessage}
          hiddenBtnOk={message === ONLY_DELETE_DRAFT_CANCEL}
          hiddenBtnCancel={false}
          disableActionButton={actionLoading}
        />

        <Heading>EVENT LIST</Heading>
        <Formik
          initialValues={{
            eventName: '',
            start_time: '',
            end_time: '',
            category: 'default',
            type: 'default',
            eventStatus: 'default',
          }}
          onSubmit={(values) => {
            setSearching(true);
            const data = {
              start_time: moment(values.start_time).isValid() ? moment(+values.start_time).format('YYYY/MM/DD') : null,
              end_time: moment(values.end_time).isValid() ? moment(values.end_time).format('YYYY/MM/DD') : null,
              category: values.category !== 'default' ? values.category : null,
              type: values.type !== 'default' ? values.type : null,
              status: values.eventStatus !== 'default' ? values.eventStatus : null,
              search: values.eventName !== 'default' ? values.eventName?.trim() : null,
            };
            setFormSearch(data);
          }}
          validationSchema={Yup.object({
            eventName: Yup.string().min(3, ENTER_AT_LEAST_3_CHARACTER).notRequired(),
          })}
        >
          {(formik) => {
            const handleDateChange =
              (direction: 'start_time' | 'end_time') => (date: any, _: string | null | undefined) => {
                if (date) {
                  formik.setFieldValue(direction, date);
                }
              };
            const handleReset = () => {
              formik.resetForm();
              setSearching(true);
              setFormSearch({ category: '', end_time: '', start_time: '', status: '', type: '' });
            };

            return (
              <Form>
                <SearchSection>
                  <SearchItem>
                    <Box>
                      <Label>Event name</Label>
                      <FormTextField
                        name="eventName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        variant="outlined"
                        fullWidth
                        value={formik.values.eventName}
                        inputProps={{ tabIndex: 1 }}
                        placeholder="Enter at latest  3 character"
                        error={formik.touched.eventName && !!formik.errors.eventName}
                        helperText={formik.touched.eventName && formik.errors.eventName}
                      />
                    </Box>
                    <Box>
                      <Label>Category</Label>
                      <FormTextField
                        variant="outlined"
                        name="category"
                        fullWidth
                        size="small"
                        value={formik.values.category}
                        onChange={formik.handleChange}
                        select
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          },
                          inputProps: {
                            tabIndex: 4,
                          },
                        }}
                        onBlur={formik.handleBlur}
                      >
                        <FormMenuItem value="default">All Category</FormMenuItem>
                        {EventCategory.map(({ value, label }, idx) => (
                          <FormMenuItem key={idx} value={value}>
                            {label}
                          </FormMenuItem>
                        ))}
                      </FormTextField>
                    </Box>
                  </SearchItem>
                  <SearchItem>
                    <Box>
                      <Label>From Date</Label>
                      <GeneralInformationDatePicker
                        fullWidth
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format={DATE_PICKER_DISPLAY_FORMAT}
                        name="start_time"
                        value={formik.values.start_time === '' ? null : formik.values.start_time}
                        InputAdornmentProps={{ position: 'end' }}
                        onChange={handleDateChange('start_time')}
                        invalidDateMessage=""
                        placeholder={PLACEHOLDER_DATE_PICKER}
                        InputProps={{ readOnly: true, tabIndex: 2 }}
                        keyboardIcon={<img src={CalenderURL} alt="Calender" />}
                        maxDate={formik.values.end_time || undefined}
                      />
                    </Box>
                    <Box>
                      <Label>Type</Label>
                      <FormTextField
                        variant="outlined"
                        name="type"
                        fullWidth
                        size="small"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.type}
                        select
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          },
                          inputProps: {
                            tabIndex: 5,
                          },
                        }}
                      >
                        <FormMenuItem value="default">All Type</FormMenuItem>
                        {EventType.map(({ value, label }, idx) => (
                          <FormMenuItem key={idx} value={value}>
                            {label}
                          </FormMenuItem>
                        ))}
                      </FormTextField>
                    </Box>
                  </SearchItem>
                  <SearchItem>
                    <Box>
                      <Label>To Date</Label>
                      <GeneralInformationDatePicker
                        fullWidth
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format={DATE_PICKER_DISPLAY_FORMAT}
                        name="end_time"
                        value={formik.values.end_time === '' ? null : formik.values.end_time}
                        InputAdornmentProps={{ position: 'end' }}
                        onChange={handleDateChange('end_time')}
                        invalidDateMessage=""
                        minDate={formik.values.start_time || undefined}
                        placeholder={PLACEHOLDER_DATE_PICKER}
                        InputProps={{ readOnly: true, tabIndex: 3 }}
                        clearable
                        keyboardIcon={<img src={CalenderURL} alt="Calender" />}
                      />
                    </Box>
                    <Box>
                      <Label>Event Status</Label>
                      <FormTextField
                        variant="outlined"
                        name="eventStatus"
                        fullWidth
                        size="small"
                        select
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.eventStatus}
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          },
                          inputProps: {
                            tabIndex: 6,
                          },
                        }}
                      >
                        <FormMenuItem value="default">All Status</FormMenuItem>
                        {EventStatus.map(({ value, label }, idx) => (
                          <FormMenuItem key={idx} value={value}>
                            {label}
                          </FormMenuItem>
                        ))}
                      </FormTextField>
                    </Box>
                  </SearchItem>
                  <SearchItem>
                    <SearchButton>
                      {formik.dirty && <ButtonReset onClick={handleReset}>Reset</ButtonReset>}
                      <ButtonSearch type="submit">Search</ButtonSearch>
                    </SearchButton>
                  </SearchItem>
                </SearchSection>
              </Form>
            );
          }}
        </Formik>
        <ListingSection>
          <ListingCreate>
            <ListingTitle>Listing</ListingTitle>
            <ButtonCreate onClick={handleTogglePopup}>
              <PlusIcon fill="white" />
              <Typography>Create</Typography>
            </ButtonCreate>
          </ListingCreate>
          <Box px={2}>
            <NSAATable
              isShowPagination
              fetchData={fetchData}
              builderColumns={builderColumns}
              builderDataSource={builderDataSource}
              loading={loading}
              paginationTitle="events"
              isSearchReset={isSearching}
            />
          </Box>
        </ListingSection>
        <CreateEventDialog open={isOpenPopup} onClose={handleTogglePopup} />
      </SearchContainer>
    </Box>
  );
};

export default Search;
