export const EventType = [
  { label: 'Location Based', value: 'location-based' },
  { label: 'Online Event', value: 'online-event' },
] as const;

export const EventStatus = [
  { label: 'Completed', value: 'completed' },
  { label: 'Cancelled', value: 'cancelled' },
  { label: 'Draft', value: 'draft' },
  { label: 'On-going', value: 'on-going' },
  { label: 'Pending', value: 'pending' },
];
export const EventCategory = [
  { label: 'Tuan Bai', value: 'tuan-bai' },
  { label: 'P1 Registration Talk', value: 'p1-registration-talk' },
  { label: "Founder's Day Luncheon/Dinner", value: 'founder-s-day-luncheon-dinner' },
  { label: 'GTKY Party', value: 'gtky-party' },
  { label: 'Value Talk', value: 'value-talk' },
  { label: 'Others', value: 'others' },
];

export const EventSearchHomePage = [
  { label: 'Event require volunteer work', value: 'yes' },
  { label: 'Event does not require volunteer work', value: 'no' },
];
