export const sliderData = [
  {
    url: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/1.jpg',
    dialogTitle: `NANYANG 102ND FOUNDER'S DAY`,
    dialogContent:
      '今年母校将庆祝成立102周年，与此同时也欢庆30及50年南洋毕业生的团聚。今年校庆的主题是“回忆”。我们诚挚的邀请南洋的学子们与1989年及1969年的毕业生参与庆典活动，回来母校和同窗好友',
    link: '/cn/events/5cae6985-adca-49d0-9884-24dbb90927d2',
  },
  {
    url: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/2.jpg',
    dialogTitle: '南洋新春团拜2016',
    dialogContent: '南洋新春团拜2016',
    link: '/events/9b42f791-6d0e-4b90-b3bb-968d8a219b35',
  },
  {
    url: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/3.jpg',
    dialogTitle: '1965 毕业班同学在母校98校庆对母校的感恩',
    dialogContent: '1965 毕业班同学在母校98校庆对母校的感恩',
    link: '/events/e43c63ef-6517-44d5-945e-63cf04ece4f2',
  },
  {
    url: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/4.jpg',
    dialogTitle: "98TH FOUNDER'S DAY",
    dialogContent: '98校庆的主题是‘叙’，母校校庆之际，她像慈母般热情的期待我们的归来共叙旧谊。',
    link: '/events/f5f585b9-79ad-4738-aa42-600ff3447cd1',
  },
  {
    url: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/5.jpg',
    dialogTitle: '98校庆',
    dialogContent: '餐费每位50元/特价每位40元*',
    link: '/events/fa88a14f-358f-475d-853c-d9d53591f76b',
  },
  {
    url: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/6.jpg',
    dialogTitle: '南洋校友会柬埔寨爱心之旅 (一) 和 (二)',
    dialogContent: `南洋校友会柬埔寨爱心之旅 (一) 和 (二)`,
    link: '/events/b321cfba-eb29-49ec-9873-84f081da9bab',
  },
  {
    url: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/7.jpg',
    dialogTitle: '悼念敬爱的建国总理李光耀国父',
    dialogContent: '南洋中小幼校友会谨此以万二分悲痛的心情向您致以最崇高的敬意。',
    link: '/cn/news/80af457d-e9ba-4e70-bb59-9fb1ed75e5af',
  },
  {
    url: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/8.jpg',
    dialogTitle: '团拜 2015',
    dialogContent:
      '在新春伊始与 南洋大家庭同欢共庆，保持团拜传统，与退休校长及老师，校友，三校师生及家长，为新的一年献上彼此的祝福。 节目包括舞狮，团体书法，歌舞表演，动手制作灯笼，剪纸，新',
    link: '/events/e254d773-8ece-4398-bd66-2166a6d00778',
  },
  {
    url: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/9.jpg',
    dialogTitle: '探访陈金浪老师',
    dialogContent:
      '像往年一样，中秋佳节是南洋中小幼校友会去探访退休老师的日子。2014年8月30日是许多校友会成员，校友们和南洋中小幼校友合唱团团员与陈金浪老师和家人的中秋佳节庆团员的相约之日。',
    link: '/events/86376a30-d2dd-40f9-967a-fa45db05b82b',
  },
  {
    url: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/10.jpg',
    dialogTitle: '校友回校日嘉年华会',
    dialogContent:
      '南洋女中在8月16日早上9点到傍晚6点举办了校友回校日嘉年华会。嘉年华会所筹得的款项将用于学校的各项发展计划与添置配备，并将部分的款项捐给弱势人士。',
    link: '/events/0925179e-89f4-454b-a891-131a7fb677c6',
  },
  {
    url: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/11.png',
    dialogTitle: '小一入学讲座',
    dialogContent: '南洋中小幼校友会于2014年6月28日星期六，假南洋小学礼堂举办一年一度的小一入学讲座',
    link: '/events/78f54221-ac94-4b54-b2f6-d5301197bcb8',
  },
];
