import { createSlice } from '@reduxjs/toolkit';

import { localStorageHelper } from 'src/helpers';
import { IUser } from 'src/services/users';

import type { RootState } from '../index';

import { logInWithPasswordAction } from './action';
// Define a type for the slice state
interface AuthState {
  loading: boolean;
  isLoggedIn: boolean;
  loginStatus: {
    message: string | undefined;
    statusCode: number | undefined;
  };
  isChildError: boolean;
  userProfile: IUser | undefined;
  previousPath: string;
  countLoginError: number;
}

// Define the initial state using that type
const initialState: AuthState = {
  loading: false,
  isLoggedIn: false,
  loginStatus: {
    message: '',
    statusCode: undefined,
  },
  isChildError: true,
  userProfile: undefined,
  previousPath: '',
  countLoginError: 0,
};

export const authSlice = createSlice({
  name: 'authentication',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setLoginState: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    logout: (state) => {
      localStorageHelper.removeItem(process.env.REACT_APP_ACCESS_TOKEN_KEY!);
      const newState = { ...initialState };
      // Redirect user to home page when logout success.
      window.location.href = '/';
      return newState;
    },
    resetLoginStatus: (state) => {
      state.loginStatus = {
        message: '',
        statusCode: undefined,
      };
      return state;
    },
    updateStatusValidChild: (state, action) => {
      state.isChildError = action.payload;

      return state;
    },
    saveProfile: (state, action) => {
      state.userProfile = action.payload;

      return state;
    },
    saveCurrentPath: (state, action) => {
      state.previousPath = action.payload.path;
      localStorage.setItem('previousPath', action.payload.path);
    },
    removeCurrentPath: (state) => {
      state.previousPath = '';
      localStorage.removeItem('previousPath');
    },
    resetCountLoginError: (state) => {
      state.countLoginError = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logInWithPasswordAction.pending, (state, action) => {
      state.loading = true;
      return state;
    });
    builder.addCase(logInWithPasswordAction.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.error) {
        state.countLoginError = state.countLoginError + 1;
        state.loginStatus = { message: action.payload?.message, statusCode: action.payload?.statusCode };
      } else {
        state.loginStatus = { message: action.payload?.message, statusCode: action.payload?.statusCode };
        state.isLoggedIn = true;
        localStorageHelper.setItem(process.env.REACT_APP_ACCESS_TOKEN_KEY!, action?.payload?.data?.jwtAccessToken);

        return state;
      }
    });
    builder.addCase(logInWithPasswordAction.rejected, (state, action) => {
      state.loading = false;
      return state;
    });
  },
});

export const {
  logout,
  setLoginState,
  updateStatusValidChild,
  saveProfile,
  saveCurrentPath,
  removeCurrentPath,
  resetLoginStatus,
  resetCountLoginError,
} = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
