import React, { useState } from 'react';

import { Box, Grid, IconButton, InputAdornment, Link, makeStyles } from '@material-ui/core';

import { CustomButton, InputField } from 'src/components';
import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import { CALL_SUCCESS } from 'src/constants/common';
import { RESET_PASSWORD } from 'src/constants/users.constants';
import LogoJSA from 'src/medias/images/main-nsaa-logo.png';
import SignUpBackground from 'src/medias/images/signup-background.jpg';
import { forgotPassword } from 'src/services/auth';
import useResponsive from 'src/utils/responsive';

import ResetPasswordSuccess from '../ResetPasswordSuccess';
import {
  CloseIconCustom,
  CustomTextS,
  Form,
  Logo,
  MAX_LENGTH_EMAIL,
  NavLinkCustom,
  Note,
  NYSImage,
  REQUIRED_FIELD,
  TitleLogin,
  USER_NOT_FOUND,
  WRONG_EMAIL_FORMAT,
} from '../types';

const useStyles = makeStyles(() => ({
  left: {
    height: 300,
    position: 'relative',
    marginTop: 110,
  },
  right: {
    top: 202,
    width: '100%',
  },
}));
function ForgotPasswordMobile() {
  const classes = useStyles();
  const { isTablet, isMobile } = useResponsive();
  const [data, setData] = useState<{ email: string }>({ email: '' });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSentEmail, setIsSentEmai] = useState<boolean>(false);
  const [onClicking, setOnClicking] = useState<boolean>(false);
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    setOnClicking(true);
    setErrorMessage('');
    e.preventDefault();
    if (!data.email) {
      setOnClicking(false);
      setErrorMessage(REQUIRED_FIELD);
      return;
    }
    const response = await forgotPassword(data.email);
    if (response?.statusCode === CALL_SUCCESS) {
      setIsSentEmai(true);
      setOnClicking(false);
    } else {
      setOnClicking(false);
      if (response?.message === 'User not found') {
        setErrorMessage(USER_NOT_FOUND);
      } else {
        setErrorMessage(WRONG_EMAIL_FORMAT);
      }
    }
  }
  function handleClear() {
    setData({ ...data, email: '' });
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setErrorMessage('');
    setData({ ...data, [e.target.name]: e.target.value });
  }
  if (!isSentEmail)
    return (
      <Grid container>
        <LoadingCustom loading={onClicking} loadingIcon />
        <Grid item xs={12} className={classes.left}>
          <Box display="flex" width={'100%'} height={'100%'}>
            <NYSImage src={SignUpBackground} objectFitContain />
            <Link href="/">
              <Logo src={LogoJSA} />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.right}>
          <Box
            width={'100%'}
            height={'100%'}
            p={2}
            pl={isTablet ? '100px' : '16px'}
            pr={isTablet ? '100px' : '16px'}
            display="flex"
            bgcolor="#F9F9F4"
            minHeight={isTablet ? '996px' : '588px'}
          >
            <Form onSubmit={handleSubmit}>
              <TitleLogin isTablet={isTablet}>Forgot password?</TitleLogin>
              <Box>
                <CustomTextS isTablet={isTablet}>
                  We'll send you a link by email to reset your password. Please enter your email address.
                </CustomTextS>
              </Box>
              <Box mt={isTablet ? '48px' : '0px'}>
                <InputField
                  lableStyle={{ marginBottom: '10px' }}
                  error={errorMessage ? true : false}
                  title="Email Address"
                  hiddenLabel
                  fullWidth
                  type="text"
                  value={data.email}
                  name="email"
                  onChange={handleChange}
                  autoComplete="off"
                  errormessage={errorMessage}
                  InputProps={{
                    inputProps: {
                      maxLength: MAX_LENGTH_EMAIL,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClear} edge="end">
                          {data.email !== '' && <CloseIconCustom isError={errorMessage ? true : false} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box
                mt={isTablet ? '270px' : '154px'}
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Note isTablet={isTablet}>
                  <NavLinkCustom to="/forgot-email">I don’t remember</NavLinkCustom>
                </Note>
              </Box>
              {isMobile && (
                <Box mb={5} display="flex" justifyContent="center">
                  <CustomButton type="submit" disable={onClicking}>
                    Reset Password
                  </CustomButton>
                </Box>
              )}
              {isTablet && (
                <Box position={'absolute'} bottom={0} left={0} width={'100%'} display="flex" flex={1}>
                  <CustomButton style={{ width: '100%', height: 83 }} type="submit" disabled={onClicking}>
                    RESET PASSWORD
                  </CustomButton>
                </Box>
              )}
            </Form>
          </Box>
        </Grid>
      </Grid>
    );
  return <ResetPasswordSuccess message={RESET_PASSWORD.sentEmail} />;
}

export default ForgotPasswordMobile;
