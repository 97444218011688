import {
  ELEMENT_PARAGRAPH,
  ELEMENT_IMAGE,
  ExitBreakPluginOptions,
  createPlateOptions,
  KEYS_HEADING,
  WithAutoformatOptions,
} from '@udecode/plate';

import { autoformatRules } from './autoformat/autoformatRules';

export const options = createPlateOptions();

export const optionsExitBreakPlugin: ExitBreakPluginOptions = {
  rules: [
    {
      hotkey: 'mod+enter',
    },
    {
      hotkey: 'mod+shift+enter',
      before: true,
    },
    {
      hotkey: 'enter',
      query: {
        start: true,
        end: true,
        allow: KEYS_HEADING,
      },
    },
    {
      hotkey: 'enter',
      query: {
        allow: [options[ELEMENT_IMAGE].type],
      },
    },
    {
      hotkey: 'enter',
      before: true,
      query: {
        start: true,
        allow: [options[ELEMENT_PARAGRAPH].type],
      },
    },
  ],
};

export const optionsAutoformat: WithAutoformatOptions = {
  rules: autoformatRules,
};

export const editableProps = {
  placeholder: '',
  spellCheck: false,
  padding: '0 30px',
};

export const editablePreviewProps = {
  spellCheck: false,
  padding: '0 30px',
  readOnly: true,
};
