import React, { useState } from 'react';

import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';

import { CustomDatePicker, InputField, CustomInputSelect } from 'src/components';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { ISignUpReq } from 'src/services/auth';
import {
  capitalizeFirstLetter,
  emailIsValid,
  intRegex,
  containsUpperAndLowerCaseRegexp,
  isSpace,
  containsNumericCharacters,
  containsSpecialCharacters,
} from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { customLabels } from '../../../utils/areaCode';

import { InputCheckBox } from './InputCheckbox';
import { CustomLabel, genders, ReactFlagsSelectStyled, TextNote } from './types';

const MAXIMUM_USERNAME_LENGTH = 50;
const MAXIMUM_PHONE_NUMBER_LENGTH = 15;
const MAXIMUM_MAILING_LENGTH = 128;
const TEXT_REQUIRE = 'This field is required';
const EMAIL_TEXT_CORRECT = 'Please fill in a correct email';
const PHONE_TEXT_REQUIRE = 'This field must be a number';
const TOOLTIP_PASSWORD_MESSAGE = `- Your password MUST be between 8 and 32 characters in length. 
  - Your password MUST have at least one UPPERCASE character. 
  - Your password MUST have at least one LOWERCASE character. 
  - Your password MUST have at least one number. 
  - Your password MUST have at least one Special (Non-Alphanumeric) character (eg. ! @ # $ % ^ & * ). 
  - Your password MUST NOT contain spaces.`;
const ERROR_PASSWORD_MESSAGE = 'Please input a valid password';
const MINIMUM_PASSWORD_LENGTH = 8;
const MAXIMUM_PASSWORD_LENGTH = 32;
const POSTAL_CODE_LENGTH = 6;
const POSTAL_CODE_MESSAGE = 'Postal Code must be 6 number';
const GRADUATE_YEAR_GREATER_MESSAGE = 'Year of graduation must be greater than birth year';
const GRADUATE_YEAR_LESS_THAN_MESSAGE = 'Graduate year must be less than current year';
const ERROR_VERIFY_PASSWORD_MESSAGE = 'Passwords do not match';
const CORRECT_YEAR = 'Please fill a correct year';
const useStyles = makeStyles((theme) => ({
  noticeRequire: {
    color: 'red',
    verticalAlign: 'middle',
    marginLeft: 4,
  },
}));

export const ErrorMessage = styled.span(({ theme, isError }: { theme: Theme; isError: boolean }) => ({
  color: '#f44336',
  opacity: isError ? 1 : 0,
  fontSize: 14,
  fontWeight: 400,
  marginTop: 4,
}));

interface IBasicInformation {
  data: ISignUpReq;
  setData: React.Dispatch<React.SetStateAction<ISignUpReq>>;
  youthMember?: boolean;
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
  currentYear: number;
}

const BasicInformation = ({ data, setData, youthMember, errors, setErrors, currentYear }: IBasicInformation) => {
  const { isTabletOrMobile } = useResponsive();
  const classes = useStyles();
  useScrollToTop();
  const date = data.dateOfBirth ?? '';

  const dob = new Date(date.toString()).getFullYear();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showVerifyPassword, setShowVerifyPassword] = useState<boolean>(false);

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleClickShowVerifyPassword() {
    setShowVerifyPassword(!showVerifyPassword);
  }

  function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
  }

  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      setData({ ...data, dateOfBirth: date });
      setValidate('dateOfBirth', date.toString());
    }
  };

  const _handleChangeArea = (areaCode: string) => {
    setData({ ...data, areaCode: areaCode });
  };
  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [e.target.name]: e.target.value });

    setValidate(e.target.name, e.target.value);

    if (e.target.name === 'emailAddress') {
      setValidate('emailAddress', emailIsValid(e.target.value) ? e.target.value : '');
    }

    if (e.target.name === 'mobilePhone') {
      setValidate('mobilePhone', intRegex.test(e.target.value) ? e.target.value : '');
    }

    if (e.target.name === 'password') {
      const passwordLongEnough = _.size(e.target.value) >= MINIMUM_PASSWORD_LENGTH;

      const isPasswordValid =
        passwordLongEnough &&
        containsUpperAndLowerCaseRegexp.test(e.target.value) &&
        containsSpecialCharacters.test(e.target.value) &&
        !isSpace(e.target.value) &&
        containsNumericCharacters.test(e.target.value);

      setValidate('password', isPasswordValid ? e.target.value : '');
    }

    if (e.target.name === 'postalCode') {
      const postalCodeLongEnough = _.size(e.target.value) >= POSTAL_CODE_LENGTH;
      const isPostalCodeValid = postalCodeLongEnough && intRegex.test(e.target.value);

      setValidate('postalCode', isPostalCodeValid ? e.target.value : '');
    }

    return;
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimed = e.target.value.trim();
    setData({ ...data, [e.target.name]: valueTrimed });
  };
  const periodStudies = [
    {
      label: 'Nanyang Kindergarten',
      name: 'periodStudyInNYK',
      checked: Boolean(data.periodStudyInNYK),
      graduateYear: data.periodStudyInNYK,
    },
    {
      label: 'Nanyang Pre-Primary',
      name: 'periodStudyInNPP',
      checked: Boolean(data.periodStudyInNPP),
      graduateYear: data.periodStudyInNPP,
    },
    {
      label: 'Nanyang Primary',
      name: 'periodStudyInNYP',
      checked: Boolean(data.periodStudyInNYP),
      graduateYear: data.periodStudyInNYP,
    },
    {
      label: "Nanyang Girls' High",
      name: 'periodStudyInNYGH',
      checked: Boolean(data.periodStudyInNYGH),
      graduateYear: data.periodStudyInNYGH,
    },
  ];

  return (
    <Box>
      <Box justifyContent="center" display="flex">
        <TextNote isTabletOrMobile={isTabletOrMobile}>
          {youthMember ? `Student's Information` : `Let’s start with some basic information`}
        </TextNote>
      </Box>
      <Grid container>
        <Grid item xs={isTabletOrMobile ? 12 : 6}>
          <Box flex={1} pr={isTabletOrMobile ? 0 : 1}>
            <InputField
              value={data.surname}
              onChange={_handleChange}
              onBlur={onBlurField}
              require
              title="Surname (as in NRIC)"
              name="surname"
              margin="dense"
              fullWidth
              InputProps={{
                inputProps: {
                  maxLength: MAXIMUM_USERNAME_LENGTH,
                },
              }}
              error={errors.surname}
              errormessage={errors.surname && TEXT_REQUIRE}
            />
          </Box>
        </Grid>
        <Grid item xs={isTabletOrMobile ? 12 : 6}>
          <Box flex={1} pl={isTabletOrMobile ? 0 : 1}>
            <InputField
              value={data.givenName}
              onChange={_handleChange}
              onBlur={onBlurField}
              require
              title="Given Name (as in NRIC)"
              name="givenName"
              margin="dense"
              fullWidth
              InputProps={{
                inputProps: {
                  maxLength: MAXIMUM_USERNAME_LENGTH,
                },
              }}
              error={errors.givenName}
              errormessage={errors.givenName && TEXT_REQUIRE}
            />
          </Box>
        </Grid>
      </Grid>
      <Box>
        <InputField
          value={data.chineseName}
          onChange={_handleChange}
          onBlur={onBlurField}
          title="中文名字"
          name="chineseName"
          margin="dense"
          fullWidth
          InputProps={{
            inputProps: {
              maxLength: MAXIMUM_USERNAME_LENGTH,
            },
          }}
        />
      </Box>
      <Grid container>
        <Grid item xs={isTabletOrMobile ? 12 : 6}>
          <Box flex={1} pr={isTabletOrMobile ? 0 : 1} mt={2}>
            <FormControl fullWidth>
              <CustomLabel>
                Date of Birth
                <Typography component="span" className={classes.noticeRequire}>
                  *
                </Typography>
              </CustomLabel>
              <CustomDatePicker
                isError={errors.dateOfBirth}
                onChange={handleDateChange}
                value={data.dateOfBirth}
                maxDate={youthMember ? moment().subtract(0, 'years') : moment().subtract(21, 'years')}
                minDate={youthMember ? moment().subtract(20, 'years') : moment().subtract(100, 'years')}
                name="dateOfBirth"
              />
              {errors.dateOfBirth && <ErrorMessage isError={errors.dateOfBirth}>{TEXT_REQUIRE}</ErrorMessage>}
            </FormControl>
          </Box>
        </Grid>
        <Grid item xs={isTabletOrMobile ? 12 : 6}>
          <Box flex={1} pl={isTabletOrMobile ? 0 : 1}>
            <CustomInputSelect
              title="Gender"
              name="gender"
              value={data.gender}
              onChange={_handleChange}
              error={errors.gender}
              errormessage={TEXT_REQUIRE}
            >
              {genders.map((gender) => (
                <MenuItem key={gender} value={gender}>
                  {capitalizeFirstLetter(gender)}
                </MenuItem>
              ))}
            </CustomInputSelect>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <InputField
          value={data.emailAddress}
          onChange={_handleChange}
          onBlur={onBlurField}
          name="emailAddress"
          title="Email Address"
          type="email"
          margin="dense"
          require
          fullWidth
          InputProps={{
            inputProps: {
              maxLength: MAXIMUM_USERNAME_LENGTH,
            },
          }}
          error={errors.emailAddress}
          errormessage={
            errors.emailAddress && data.emailAddress === '' ? TEXT_REQUIRE : errors.emailAddress && EMAIL_TEXT_CORRECT
          }
        />
      </Box>
      <Box display="flex" mt={3}>
        <Box width={isTabletOrMobile ? 130 : 145} mt={1}>
          <CustomLabel>
            Phone Number
            <Typography component="span" className={classes.noticeRequire}>
              *
            </Typography>
          </CustomLabel>
          <ReactFlagsSelectStyled
            fullWidth
            selected={data.areaCode}
            onSelect={_handleChangeArea}
            searchable
            customLabels={customLabels}
            showSecondaryOptionLabel
            showSelectedLabel={false}
          />
        </Box>
        <Box flex={1} pl={2} mt={2}>
          <InputField
            placeholder="Phone number (Including area code, if there is)"
            value={data.mobilePhone}
            onChange={_handleChange}
            onBlur={onBlurField}
            margin="dense"
            name="mobilePhone"
            fullWidth
            InputProps={{
              inputProps: {
                maxLength: MAXIMUM_PHONE_NUMBER_LENGTH,
              },
            }}
            error={errors.mobilePhone}
            errormessage={
              errors.mobilePhone && data.mobilePhone === '' ? TEXT_REQUIRE : errors.mobilePhone && PHONE_TEXT_REQUIRE
            }
          />
        </Box>
      </Box>
      <Box>
        <InputField
          value={data.mailingAddress}
          onChange={_handleChange}
          onBlur={onBlurField}
          margin="dense"
          name="mailingAddress"
          title="Mailing Address"
          require
          fullWidth
          InputProps={{
            inputProps: {
              maxLength: MAXIMUM_MAILING_LENGTH,
            },
          }}
          error={errors.mailingAddress}
          errormessage={errors.mailingAddress && TEXT_REQUIRE}
        />
      </Box>
      <Box>
        <InputField
          placeholder="E.g: 569933"
          value={data.postalCode}
          onChange={_handleChange}
          onBlur={onBlurField}
          margin="dense"
          name="postalCode"
          title="Postal Code"
          require
          fullWidth
          InputProps={{
            inputProps: {
              maxLength: POSTAL_CODE_LENGTH,
            },
          }}
          error={errors.postalCode}
          errormessage={
            errors.postalCode && data.postalCode === '' ? TEXT_REQUIRE : errors.postalCode && POSTAL_CODE_MESSAGE
          }
        />
      </Box>
      <Box>
        <InputField
          value={data.password}
          onChange={_handleChange}
          margin="dense"
          name="password"
          title="Password"
          require
          fullWidth
          type={showPassword ? 'text' : 'password'}
          error={errors.password}
          errormessage={errors.password && data.password === '' ? TEXT_REQUIRE : ERROR_PASSWORD_MESSAGE}
          notice
          noticeYellow
          noticeTitle={
            <Typography style={{ whiteSpace: 'pre-line', fontSize: 14 }} color="inherit">
              {TOOLTIP_PASSWORD_MESSAGE}
            </Typography>
          }
          InputProps={{
            inputProps: {
              maxLength: MAXIMUM_PASSWORD_LENGTH,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box>
        <InputField
          value={data.verifyPassword}
          onChange={_handleChange}
          margin="dense"
          name="verifyPassword"
          title="Verify Your Password"
          require
          fullWidth
          type={showVerifyPassword ? 'text' : 'password'}
          error={data.password !== data.verifyPassword}
          errormessage={
            data.verifyPassword === ''
              ? TEXT_REQUIRE
              : data.password !== data.verifyPassword && ERROR_VERIFY_PASSWORD_MESSAGE
          }
          InputProps={{
            inputProps: {
              maxLength: MAXIMUM_PASSWORD_LENGTH,
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={handleClickShowVerifyPassword} onMouseDown={handleMouseDownPassword} edge="end">
                  {showVerifyPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box display="flex" mt={3}>
        <Box flex={1} pr={1}>
          <CustomLabel>
            {youthMember ? `Which School Have Your Child Attended?` : `Which Schools Have You Attended?`}
            <Typography component="span" className={classes.noticeRequire}>
              *
            </Typography>
          </CustomLabel>
          {errors.graduateSchool && (
            <ErrorMessage isError={errors.graduateSchool}>
              {!(
                data.periodStudyInNYK !== null ||
                data.periodStudyInNPP !== null ||
                data.periodStudyInNYGH !== null ||
                data.periodStudyInNYP !== null
              ) && TEXT_REQUIRE}
            </ErrorMessage>
          )}
        </Box>
        {!isTabletOrMobile && (
          <Box flex={1} pl={1}>
            <CustomLabel>
              Year of Graduation / Leave School
              <Typography component="span" className={classes.noticeRequire}>
                *
              </Typography>
            </CustomLabel>

            {errors.graduateSchool && (
              <ErrorMessage isError={errors.graduateSchool}>
                {!(
                  data.periodStudyInNYK !== null ||
                  data.periodStudyInNPP !== null ||
                  data.periodStudyInNYGH !== null ||
                  data.periodStudyInNYP !== null
                )
                  ? TEXT_REQUIRE
                  : (data.periodStudyInNYK !== null && !intRegex.test(data.periodStudyInNYK)) ||
                    (data.periodStudyInNPP !== null && !intRegex.test(data.periodStudyInNPP)) ||
                    (data.periodStudyInNYGH !== null && !intRegex.test(data.periodStudyInNYGH)) ||
                    (data.periodStudyInNYP !== null && !intRegex.test(data.periodStudyInNYP))
                  ? CORRECT_YEAR
                  : (data.periodStudyInNYK !== null && Number(data.periodStudyInNYK) <= dob) ||
                    (data.periodStudyInNPP !== null && Number(data.periodStudyInNPP) <= dob) ||
                    (data.periodStudyInNYGH !== null && Number(data.periodStudyInNYGH) <= dob) ||
                    (data.periodStudyInNYP !== null && Number(data.periodStudyInNYP) <= dob)
                  ? GRADUATE_YEAR_GREATER_MESSAGE
                  : (data.periodStudyInNYK !== null && Number(data.periodStudyInNYK) >= currentYear) ||
                    (data.periodStudyInNPP !== null && Number(data.periodStudyInNPP) >= currentYear) ||
                    (data.periodStudyInNYGH !== null && Number(data.periodStudyInNYGH) >= currentYear) ||
                    (data.periodStudyInNYP !== null && Number(data.periodStudyInNYP) >= currentYear)
                  ? GRADUATE_YEAR_LESS_THAN_MESSAGE
                  : ''}
              </ErrorMessage>
            )}
          </Box>
        )}
      </Box>
      {periodStudies.map((school) => (
        <InputCheckBox
          errors={errors}
          setErrors={setErrors}
          key={school.name}
          data={data}
          setData={setData}
          school={school}
          youthMember={youthMember}
        />
      ))}
    </Box>
  );
};

export default BasicInformation;
