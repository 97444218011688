import { Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header4.fontSize};
    font-weight: ${theme.fonts.header4.fontWeight};
    line-height: 24px;
  `}
`;
