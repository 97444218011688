import React, { useState, useEffect, useMemo } from 'react';

import { Box, Typography } from '@material-ui/core';
import {
  createAlignPlugin,
  createBoldPlugin,
  createItalicPlugin,
  createHighlightPlugin,
  createUnderlinePlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
  createKbdPlugin,
  createNodeIdPlugin,
  createDndPlugin,
  createExitBreakPlugin,
  createSelectOnBackspacePlugin,
} from '@udecode/plate';
import { createHistoryPlugin, createReactPlugin, Plate, PlatePlugin, SPEditor } from '@udecode/plate-core';
import { createHeadingPlugin } from '@udecode/plate-heading';
import { createImagePlugin, ELEMENT_IMAGE } from '@udecode/plate-image';
import { createLinkPlugin } from '@udecode/plate-link';
import { createListPlugin, createTodoListPlugin } from '@udecode/plate-list';
import { createMediaEmbedPlugin } from '@udecode/plate-media-embed';
import { createParagraphPlugin } from '@udecode/plate-paragraph';
import { createTablePlugin } from '@udecode/plate-table';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';

import Banner from 'src/components/banner';
import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import { CustomButton } from 'src/components/button';
import { HeaderMobile } from 'src/components/headerMobile';
import { HeaderMobileChinese } from 'src/components/headerMobileChinese';
import { optionsExitBreakPlugin, editablePreviewProps } from 'src/components/slateEditor/config/pluginOptions';
import { CALL_SUCCESS } from 'src/constants/common';
import useFetchMe from 'src/hooks/use-fetch-me';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { components } from 'src/pages/admin/components/Preview';
import { getCMSData, ICms } from 'src/services/cms';
import useResponsive from 'src/utils/responsive';

import AboutUsRightImage from '../../../../medias/images/about_us_right.png';

type TEditor = SPEditor & ReactEditor & HistoryEditor;
const Nsaa = () => {
  const history = useHistory();
  const isChinesePage = history.location.pathname.slice(0, 3) === '/cn';
  const { path } = useRouteMatch();
  const PreviewId = 'preview';
  const responsive = useResponsive();
  const { me } = useFetchMe();
  useScrollToTop();
  const [data, setData] = useState<ICms>({
    type: '',
    banner_image: '',
    banner_title: '',
    banner_description: '',
    content_title: '',
    content_body: '',
    background_image: '',
    item_image: '',
    item_date: '',
    item_image_description: '',
  });
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const response = await getCMSData('about');
    if (response.statusCode === CALL_SUCCESS) {
      setData(response.data as ICms);
    }
  };
  const pluginsMemo: PlatePlugin<TEditor>[] = useMemo(() => {
    const plugins = [
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createMediaEmbedPlugin(),
      createAlignPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createDndPlugin(),
      createExitBreakPlugin(optionsExitBreakPlugin),
      createSelectOnBackspacePlugin({
        allow: [ELEMENT_IMAGE],
      }),
    ];

    return plugins;
  }, []);

  return (
    <Box pt={responsive.isTabletOrMobile ? 15 : 3.75}>
      {responsive.isTabletOrMobile && !isChinesePage ? (
        <HeaderMobile title={`About NSAA`} userProfile={me} />
      ) : (
        <HeaderMobileChinese title={`关于校友会`} />
      )}

      {!responsive.isTabletOrMobile && <NSAABreadcrumbs path={path} />}
      <Banner
        image={data.banner_image}
        title={data.banner_title}
        description={data.banner_description}
        responsive={responsive}
      />
      {responsive.isTabletOrMobile && (
        <TypographyStyled responsive={responsive}>
          (Nanyang Girls’ High School, Nanyang Primary School, Nanyang Kindergarten)
        </TypographyStyled>
      )}
      <NSAAContainer maxWidth="lg" responsive={responsive}>
        <Box display="flex" flexDirection="column" maxWidth={730} mx="auto">
          <Plate
            id={PreviewId}
            plugins={pluginsMemo}
            components={components}
            editableProps={editablePreviewProps}
            value={data?.item_content && JSON.parse(data?.item_content)}
          />
          <Link href="/about-us/history">
            <CustomButtonStyled responsive={responsive} disableRipple>
              Learn more about our history
            </CustomButtonStyled>
          </Link>
        </Box>
        <Box flex="1" display="flex" justifyContent="center" alignItems="flex-start">
          <Image responsive={responsive} src={AboutUsRightImage} />
        </Box>
      </NSAAContainer>
    </Box>
  );
};

export default Nsaa;

const NSAAContainer = styled(Box)<{ responsive?: any }>`
  ${({ responsive }) => css`
    padding: 50px;
    padding-left: 190px;
    display: flex;
    gap: 60px;
    text-align: justify;

    ${responsive.isTabletOrMobile &&
    css`
      flex-direction: ${responsive.isTabletOrMobile ? 'column-reverse' : 'row'};
      padding: 16px;
      gap: 0;
    `}
  `}
`;

const CustomButtonStyled = styled(CustomButton)<{ responsive?: any }>`
  && {
    ${({ theme }) => ({
      ...theme.fonts.textSs,
    })}
  }
  ${({ theme, responsive }) => css`
    && {
      width: 270px;
      height: 56px;
      margin-top: 20px;
      padding: 20px;
      border-radius: 7px;
      align-self: center;
      ${responsive.isMobile &&
      css`
        width: 240px;
        height: 44px;
      `}
    }
  `}
`;

const Image = styled.img<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    width: 100%;
    max-width: 410px;
    ${responsive.isMobile &&
    css`
      width: 100%;
      max-width: 100%;
    `}
    ${responsive.isTablet &&
    css`
      width: 100%;
      max-width: 383px;
    `}
  `}
`;

const Link = styled.a`
  text-decoration: none;
  color: white;
  margin-left: auto;
  margin-right: auto;
`;

export const TypographyStyled = styled(Typography)<{ responsive?: any }>`
  ${({ responsive }) => css`
    color: #718096;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    padding: 16px 16px 24px;
  `}
`;

export const BannerMainTitle = styled(Box)`
  font-size: 32px;
  padding: 20px 0 0;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
`;
