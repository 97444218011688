import { useEffect, useState } from 'react';

import { IUser, getMe } from 'src/services/users';
import { saveProfile } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';

const useFetchMe = (deps?: []) => {
  const [me, setMe] = useState<IUser | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(
    () => {
      (async () => {
        setLoading(true);
        const response = await getMe();
        if (response?.statusCode === 200 && response?.data) {
          setMe(response.data);
          dispatch(saveProfile(response.data));
        }
        if (response?.statusCode !== 200) {
          setMe(null);
        }
        setLoading(false);
      })();
    },
    deps ? deps : [],
  );

  return { me, loading } as const;
};

export default useFetchMe;
