import React from 'react';

import { Box } from '@material-ui/core';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import ProfileTabs from 'src/pages/profile/shared/profile.tabs';
import { Container } from 'src/pages/user-management/types';
import { IEvent } from 'src/services/events';

import Question from './components/question';
import ResponseList from './components/response';
import Setting from './components/setting';

interface IQuizProps {
  event: IEvent;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Quiz = ({ event, setDirty }: IQuizProps) => {
  useScrollToTop();

  let tabs = [
    { label: 'Question', disabled: false },
    { label: 'Response', disabled: false },
    { label: 'Setting', disabled: false },
  ];
  const panels = [
    <Question event={event} setDirty={setDirty} />,
    <ResponseList event={event} />,
    <Setting event={event} />,
  ];

  if (event?.status === 'draft') {
    tabs = [
      { label: 'Question', disabled: false },
      { label: 'Response', disabled: true },
      { label: 'Setting', disabled: true },
    ];
  }

  return (
    <Box>
      <Container minHeight={500} p={4} bgcolor="white">
        <ProfileTabs panels={panels} tabs={tabs} />
      </Container>
    </Box>
  );
};

export default Quiz;
