import React from 'react';

import { AppBar, Box, IconButton, makeStyles, Popper, Toolbar } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import styled, { css } from 'styled-components';

import SideMenuChinese from 'src/layouts/chineseLayout/main/components/SideMenu';
import { menuUser, menuUserMobile, menuUserNotLoginChinese } from 'src/layouts/main/type';
import DefaultAvatar from 'src/medias/images/default_avatar.png';
import headerLogo from 'src/medias/images/main-nsaa-logo.png';
import { IUser } from 'src/services/users';
import { logout } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';
import useResponsive from 'src/utils/responsive';

import { BoxShadowStyled, MenuItemStyled } from './headerMobile';

const useStyles = makeStyles(() => ({
  menuButton: {
    color: '#979168',
  },
}));

interface IHeaderMobile {
  userProfile?: IUser | null;
  title?: string;
  hasLogo?: boolean;
}

export const HeaderMobileChinese = ({ title, hasLogo, userProfile }: IHeaderMobile) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isMobile, isTablet } = useResponsive();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  let menuLogged = menuUser;

  if (userProfile?.canScanTicket) {
    menuLogged = menuUserMobile;
  }

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    setOpenDrawer(open);
  };
  const _onClickMenu = () => {
    setOpenDrawer(true);
  };

  const redirectHome = () => {
    // window.location.href = '/cn';
  };

  const handleClickAvatar = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const _onClickMenuItem = (menu: { title: string; link: string }) => {
    if (menu.title === 'Log out') {
      dispatch(logout());
      return;
    }

    window.location.href = menu.link;
    return;
  };

  return (
    <Box>
      <AppBarStyled>
        <ToolbarCustom isMobile={isMobile}>
          <SideMenuChinese open={openDrawer} toggleDrawer={toggleDrawer} isMobile={isMobile} isTablet={isTablet} />
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon onClick={_onClickMenu} />
          </IconButton>
          <Box display={'flex'} alignItems={'center'}>
            <LogoStyled src={headerLogo} />
            {/* <HeaderTitle>{title ?? ''}</HeaderTitle> */}
          </Box>
          <Box>
            <Box onClick={redirectHome}>
              <IconButton
                onClick={handleClickAvatar}
                edge="end"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
              >
                {userProfile ? (
                  <>
                    <Avatar imageUrl={userProfile?.avatar || DefaultAvatar} />
                    <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 1500, backgroundColor: 'white' }}>
                      <BoxShadowStyled>
                        {menuLogged.map((menu) => (
                          <MenuItemStyled onClick={_onClickMenuItem.bind(null, menu)}>{menu.title}</MenuItemStyled>
                        ))}
                      </BoxShadowStyled>
                    </Popper>
                  </>
                ) : (
                  <>
                    <PersonIcon />
                    <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 1500, backgroundColor: 'white' }}>
                      <BoxShadowStyled>
                        {menuUserNotLoginChinese.map((menu) => (
                          <MenuItemStyled onClick={_onClickMenuItem.bind(null, menu)}>{menu.title}</MenuItemStyled>
                        ))}
                      </BoxShadowStyled>
                    </Popper>
                  </>
                )}
              </IconButton>
            </Box>
          </Box>
        </ToolbarCustom>
      </AppBarStyled>
    </Box>
  );
};

const LogoStyled = styled.img(() => ({
  height: '90px',
  width: '70px',
}));
export const Avatar = styled(Box)<{ imageUrl: string }>`
  ${({ theme, imageUrl }) => css`
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-image: url(${imageUrl});
    background-size: cover;
    background-position: center;
  `}
`;

// const HeaderTitle = styled(Typography)(() => ({
//   color: '#979168',
//   fontSize: 20,
//   fontWeight: 700,
//   display: 'flex',
//   alignItems: 'center',
// }));

const AppBarStyled = styled(AppBar)`
  && {
    background: ${({ theme }) => theme.palette.common.white};
    position: fixed;
    z-index: 2;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }
`;

const ToolbarCustom = styled(Toolbar)<{ isMobile?: boolean }>`
  ${({ theme, isMobile }) => css`
    && {
      display: flex;
      justify-content: space-between;
      padding: ${isMobile ? '24px 20px 16px 20px' : '24px 68px 16px 68px'};
      height: 120px;
      align-items: center;
    }
  `};
`;
