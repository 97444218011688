import React from 'react';

import { Box, FormControlLabel, InputAdornment, Radio, RadioGroup, Typography, Link } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { useFetchProfileQuery } from 'src/api/endpoints/profiles.endpoint';
import { useFetchUserByIdQuery, useUpdateUserByIdMutation } from 'src/api/endpoints/users.endpoint';
import { NASSRadio } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { DATE_PICKER_DISPLAY_FORMAT } from 'src/constants/common';
import { TOAST } from 'src/constants/toasts.constant';
import { NATIONALITY } from 'src/constants/users.constants';
import { VERIFY_STATUS } from 'src/constants/verify.constant';
import AttachmentIcon from 'src/icons/attachment';
import {
  ActionButton,
  FormTextField,
  GeneralInformationDatePicker,
  FormMenuItem,
} from 'src/pages/profile/components/general-information.styled';
import VerifyStatus from 'src/pages/profile/shared/verify.message';
import { ReactFlagsSelectStyled } from 'src/pages/signup/components/types';
import { customLabels, getAreaKey } from 'src/utils/areaCode';
import { openBase64InNewTab } from 'src/utils/common';

import AdditionalInformationPopup from './user-detail.additional-information.popup';

const StyledLinkBase64 = styled(Box)`
  color: #979168;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

interface IAdditionalInformationProps {
  userId: string;
}

const AdditionalInformation = ({ userId }: IAdditionalInformationProps) => {
  const { data, refetch } = useFetchUserByIdQuery(userId);
  const { data: me } = useFetchProfileQuery();
  const profile = data?.data;
  const [isShowEditPopup, setShowPopupEdit] = React.useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = React.useState(false);
  const [isShowSnackBarError, setShowSnackBarError] = React.useState(false);
  const [isHaveChildren, setHaveChildren] = React.useState<boolean>(
    profile?.child && profile?.child.length > 0 ? true : false,
  );
  const isShow = me?.data?.roleName !== 'it-admin';
  React.useEffect(() => {
    refetch();
  }, []);
  const [
    update,
    { isLoading: isUpdating, isError: isUpdatingError, isSuccess: isUpdatingSuccess, error: updatedError },
  ] = useUpdateUserByIdMutation();

  React.useEffect(() => {
    setHaveChildren(profile?.child && profile?.child.length > 0 ? true : false);
  }, [profile]);

  React.useEffect(() => {
    if (isUpdatingSuccess) {
      setShowPopupEdit(false);
      setShowSnackBarSuccess(true);
    }
    if (isUpdatingError) {
      setShowPopupEdit(false);
      setShowSnackBarError(true);
    }
  }, [isUpdatingError, isUpdatingSuccess]);

  function handleClosePopup() {
    setShowPopupEdit(false);
  }
  function handleOpenPopup() {
    setShowPopupEdit(true);
  }
  function handleOnSavePopup(values: any) {
    const payload = {
      ...profile,
      ...values,
    };
    delete payload?.payment;
    update(payload);
  }
  function handleCloseSnackBarSuccess() {
    setShowSnackBarSuccess(false);
  }
  function handleCloseSnackBarError() {
    setShowSnackBarError(false);
  }

  return (
    <Box mt={3}>
      <SuccessSnackBar
        open={isShowSnackBarSuccess}
        handleClose={handleCloseSnackBarSuccess}
        message={TOAST.UPDATE_PROFILE_SUCCESS}
      />
      <ErrorSnackBar
        open={isShowSnackBarError}
        handleClose={handleCloseSnackBarError}
        message={(updatedError as any)?.data?.message}
      />
      <Box>
        <TitleStyled>Parent/Guardian Information</TitleStyled>
        <Box my={3} display="flex" style={{ gap: 24 }}>
          <Box flex="1">
            <Label>Surname</Label>
            <FormTextField variant="outlined" fullWidth disabled value={profile?.parentSurname || ''} />
          </Box>
          <Box flex="1">
            <Label>Given Name</Label>
            <FormTextField variant="outlined" fullWidth disabled value={profile?.parentGivenName || ''} />
          </Box>
        </Box>
        <Box mb={3}>
          <Label>Relationship</Label>
          <RadioGroup value={profile?.relationship!} onChange={(e, v) => {}} row>
            <FormControlLabel disabled value="parent" control={<Radio disabled />} label="Parent" />
            <Box width={160} />
            <FormControlLabel disabled value="guardian" control={<Radio disabled />} label="Guardian" />
          </RadioGroup>
        </Box>
        <Box mb={3}>
          <Label>Email Address</Label>
          <FormTextField variant="outlined" fullWidth disabled value={profile?.parentEmail || ''} />
        </Box>
        <Box mb={3} display="flex" style={{ gap: 24 }} alignItems="flex-end">
          <Box flex="0.5">
            <Label>Phone number</Label>
            <ReactFlagsSelectStyled
              fullWidth
              selected={profile?.parentAreaCode ? getAreaKey(profile?.parentAreaCode) : ''}
              searchable
              onSelect={() => {}}
              customLabels={customLabels}
              showSecondaryOptionLabel
              showSelectedLabel={false}
              disabled
            />
          </Box>
          <Box flex="0.5">
            <FormTextField
              disabled
              variant="outlined"
              fullWidth
              size="small"
              value={profile?.parentMobilePhone}
              onChange={(v) => {}}
              name="parentAreaCode"
            />
          </Box>
        </Box>
        <Box mb={4}>
          <Label>Parent’s Consent Proof</Label>
          <FormTextField
            variant="outlined"
            value=""
            fullWidth
            disabled
            style={{ overflow: 'hidden' }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachmentIcon />
                  <Box mx={1}>
                    {profile?.signature ? (
                      profile?.signature.includes('https') ? (
                        <Link target="_blank" href={profile.signature}>
                          {profile.signature}
                        </Link>
                      ) : (
                        <StyledLinkBase64 onClick={() => openBase64InNewTab(profile.signature)}>
                          {profile.signature}
                        </StyledLinkBase64>
                      )
                    ) : (
                      ''
                    )}
                  </Box>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box pb={3}>
        <TitleStyled>Children Information</TitleStyled>
        <Box my={3}>
          <Label>Do You Have Children?</Label>
          <RadioGroup
            value={isHaveChildren && profile?.child && profile?.child?.length > 0 ? 'yes' : 'no'}
            onChange={(e, v) => {
              setHaveChildren(v === 'no' ? false : true);
            }}
            row
          >
            <FormControlLabel value="yes" control={<NASSRadio disabled />} label="Yes" disabled />
            <Box width={160} />
            <FormControlLabel value="no" control={<NASSRadio disabled />} label="No" disabled />
          </RadioGroup>
        </Box>
        {isHaveChildren && profile?.child && profile?.child?.length > 0 && (
          <Box mb={0.5} display="flex" style={{ gap: 16 }}>
            <Box flex="1">
              <Label>Child’s Surname</Label>
            </Box>
            <Box flex="1">
              <Label>Child’s Given Name</Label>
            </Box>
            <Box flex="1">
              <Label>Child’s Date Of Birth</Label>
            </Box>
            <Box flex="1">
              <Label>Nationality</Label>
            </Box>
            <Box flex="1.1" />
          </Box>
        )}

        {isHaveChildren &&
          profile?.child &&
          profile?.child?.length > 0 &&
          profile?.child.map((item: any) => (
            <Box mb={3} display="flex" style={{ gap: 16 }}>
              <Box flex="1">
                <FormTextField variant="outlined" fullWidth disabled value={item.surname} />
              </Box>
              <Box flex="1">
                <FormTextField variant="outlined" fullWidth disabled value={item.givenName} />
              </Box>
              <Box flex="1">
                <GeneralInformationDatePicker
                  disabled
                  fullWidth
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format={DATE_PICKER_DISPLAY_FORMAT}
                  value={item.dateOfBirth}
                  InputAdornmentProps={{ position: 'end' }}
                  onChange={(date) => {}}
                />
              </Box>
              <Box flex="1">
                <FormTextField
                  ellipsis
                  variant="outlined"
                  name="gender"
                  fullWidth
                  disabled
                  size="small"
                  value={item.nationality}
                  onChange={() => {}}
                  select={true}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    },
                  }}
                >
                  {NATIONALITY.map(({ value, label }, idx) => (
                    <FormMenuItem key={idx} value={value}>
                      {label}
                    </FormMenuItem>
                  ))}
                </FormTextField>
              </Box>
              <Box flex="1.1">
                <VerifyStatus variants={item.verificationStatus ?? VERIFY_STATUS.UNKNOWN} onRemove={() => {}} />
              </Box>
            </Box>
          ))}

        <Box display="flex" justifyContent="center">
          {isShow && (
            <ActionButton variants="yellow" onClick={handleOpenPopup}>
              Edit
            </ActionButton>
          )}
        </Box>
      </Box>
      <AdditionalInformationPopup
        open={isShowEditPopup}
        onClose={handleClosePopup}
        onSave={handleOnSavePopup}
        profile={profile}
        isSaveLoading={isUpdating}
      />
    </Box>
  );
};

export const Label = styled(Box)<{ required?: boolean }>`
  ${({ theme, required }) => css`
    font-size: ${theme.fonts.header6.fontSize};
    font-weight: ${theme.fonts.header6.fontWeight};
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.6);
    ${required &&
    css`
      &::after {
        content: '*';
        display: inline-block;
        color: red;
        margin-left: 3px;
      }
    `};
  `}
`;

export const RadioStyled = styled(Radio)`
  & .MuiIconButton-root {
    padding: 0 !important;
  }
  & .MuiButtonBase-root {
    padding: 0 !important;
  }
  & .MuiRadio-root {
    padding: 0 !important;
  }
`;

export const TitleStyled = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header4.fontSize};
    font-weight: ${theme.fonts.header4.fontWeight};
    line-height: 24px;
    color: ${theme.palette.yellow?.[900]};
  `}
`;

export default AdditionalInformation;
