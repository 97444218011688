import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { CALL_SUCCESS } from 'src/constants/common';
import { getEligibleUsers } from 'src/services/users';
import { IPagination, ISearchUser, IUser } from 'src/services/users/users.interface';
import { selectUserProfile } from 'src/store/authentication/selector';

import { Container, defaultSearchEligibleUser } from '../types';

import Listing from './Listing';
import SearchEligibleUser from './SearchEligibleUser';

const EligibleUser = () => {
  const history = useHistory();
  const userProfile = useSelector(selectUserProfile);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [data, setData] = useState<IPagination>();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<ISearchUser>(defaultSearchEligibleUser);
  const [userDetailId, setUserDetailId] = useState<string>();
  const [isShowUserDetail, setShowDetailUser] = useState(false);

  async function getData({ search, currentPage, limit }: { search?: ISearchUser; currentPage: number; limit: number }) {
    setLoading(true);
    const response = await getEligibleUsers({ search, currentPage, limit });

    if (response?.statusCode === CALL_SUCCESS) {
      setUsers(response.data.items);
      setData(response.data.meta);
    }
    setLoading(false);
  }

  const _onSearch = (searchData: ISearchUser) => () => {
    setSearch(searchData);
    getData({
      search: searchData,
      currentPage: 1,
      limit,
    });
    setCurrentPage(1);
  };

  const _onReset = async () => {
    setLoading(true);
    setSearch(defaultSearchEligibleUser);
    setCurrentPage(1);

    const response = await getEligibleUsers({
      search: defaultSearchEligibleUser,
      currentPage: 1,
      limit,
    });

    if (response?.statusCode === CALL_SUCCESS) {
      setUsers(response.data.items);
      setData(response.data.meta);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData({
      search,
      currentPage,
      limit,
    });
  }, [currentPage, limit]);

  useEffect(() => {
    if (userProfile && userProfile.roleName !== 'secretariat-admin' && userProfile.roleName !== 'membership-admin') {
      history.push('/not-access');
    }
  }, [userProfile]);

  return (
    <Container minHeight={500} p={4} pt={2} bgcolor="white">
      <SearchEligibleUser searchData={search} loading={loading} _onSearch={_onSearch} _onReset={_onReset} />
      <Listing
        eligibility
        users={users}
        data={data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        limit={limit}
        setLimit={setLimit}
        getData={getData}
        search={search}
        setShowDetailUser={setShowDetailUser}
        setUserDetailId={setUserDetailId}
        isShowUserDetail={isShowUserDetail}
        userDetailId={userDetailId}
      />
    </Container>
  );
};

export default EligibleUser;
