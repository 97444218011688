import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { FieldArray, Form, Formik } from 'formik';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import ConfirmPopup from 'src/components/confirm';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import DeleteIconDisabled from 'src/medias/icons/delete-disabled.png';
import DeleteIcon from 'src/medias/icons/delete-icon.png';
import PlusIconImage from 'src/medias/icons/plus.png';
import FormikPromptIfDirty from 'src/pages/event-management/shared/prompt';
import { ActionButton, FormTextField } from 'src/pages/profile/components/general-information.styled';
import { IconButtonStyled } from 'src/pages/user-management/types';
import { IEvent } from 'src/services/events';
import { createQuiz, getQuizByEventId, updateQuizByEventId } from 'src/services/quiz';

import { TextStyled } from '../../../volunteer/types';
import { ImageIconStyled } from '../types';

import {
  ActionButtons,
  AddQuestion,
  AddQuizIcon,
  CreateQuizButton,
  NoHaveQuestion,
  QuestionBox,
  QuestionTabContainer,
  QuizLabel,
  QuizTitle,
} from './index.styled';
import Question from './question.component';

interface IQuiz {
  title: string;
  question: {
    questionText: string;
    questionType: string;
    point: string;
    answer: {
      answer: string;
      isTrue: boolean;
    }[];
  }[];
}
interface IQuizProps {
  event: IEvent | null;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const Quiz = ({ event, setDirty }: IQuizProps) => {
  const MAX_LENGTH_128 = 128;
  const SINGLE_CHOICE = 'single-choice';
  const SHORT_PARAGRAPH = 'short-paragraph';
  const THIS_FIELD_IS_REQUIRED = 'This field is required';
  const EMPTY_TEXT = '';
  const AT_LATEST_ONE_QUESTION = 'There must be at least 1 question in the quiz';
  const [isHaveQuestions, setIsHaveQuestions] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [disableCreateQuiz, setDisableCreateQuiz] = useState(true);
  const [disabledEditQuiz, setDisabledEditQuiz] = useState(false);
  const [isShowConfirmPopup, setShowConfirmPopup] = useState(false);
  const handleToggleConfirmPopup = () => setShowConfirmPopup((show) => !show);
  const { id: eventId } = useParams<{ id: string }>();
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [isEditable, setEditable] = useState(false);
  const [quiz, setQuiz] = useState<IQuiz | null>();
  const [formStatus, setFormStatus] = useState<{ isReset: boolean }>({ isReset: false });

  useEffect(() => {
    if (event) {
      const disabledEdit = event.status === 'cancelled' || event.status === 'completed';
      setDisabledEditQuiz(disabledEdit);

      const disabledCreate =
        event.status === 'cancelled' || event.status === 'completed' || event.status === 'deleted' || event.is_public;
      setDisableCreateQuiz(disabledCreate);
    }
  }, [event]);

  useEffect(() => {
    (async () => {
      const response = await getQuizByEventId(eventId);
      if (response.statusCode === CALL_SUCCESS) {
        setQuiz(response.data);
      } else {
        setQuiz(null);
        setEditable(true);
        setLoadingQuestions(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (quiz) {
      setIsHaveQuestions(true);
      setLoadingQuestions(false);
    }
  }, [quiz]);

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };

  const INIT_VALUES = {
    title: EMPTY_TEXT,
    question: [
      {
        questionText: EMPTY_TEXT,
        questionType: SINGLE_CHOICE,
        point: EMPTY_TEXT,
        answer: [
          {
            answer: EMPTY_TEXT,
            isTrue: true,
          },
        ],
      },
    ],
  };

  const VALIDATION_SCHEMA = Yup.object({
    title: Yup.string().required(THIS_FIELD_IS_REQUIRED),
    question: Yup.array(
      Yup.object({
        questionText: Yup.string().required(THIS_FIELD_IS_REQUIRED),
        questionType: Yup.string().oneOf([SINGLE_CHOICE, SHORT_PARAGRAPH]),
        point: Yup.number().required(THIS_FIELD_IS_REQUIRED),
        answer: Yup.mixed().when('questionType', {
          is: (questionType: string) => {
            return questionType === SINGLE_CHOICE;
          },
          then: Yup.array(
            Yup.object({
              answer: Yup.string().required(THIS_FIELD_IS_REQUIRED),
              isTrue: Yup.boolean().required(THIS_FIELD_IS_REQUIRED),
            }),
          ),
          otherwise: Yup.array().notRequired(),
        }),
      }),
    ),
  });

  const handleQuizFormSubmit = async (values: any, resetForm: (values: any) => void) => {
    let payload = { ...values, eventId, title: values?.title?.trim() };

    const question = payload.question.map((item: any, index: number) => {
      const answer =
        item.questionType === SHORT_PARAGRAPH
          ? []
          : item.answer?.map((element: any) => ({ ...element, answer: element?.answer?.trim() }));

      return {
        ...item,
        questionText: item.questionText?.trim(),
        questionType: item.questionType,
        point: Number(item.point),
        answer,
        order: index + 1,
      };
    });

    payload = { ...payload, question } as any;

    if (quiz) {
      setLoading(true);
      const response = await updateQuizByEventId(eventId, payload);
      if (response?.statusCode === CALL_SUCCESS) {
        setLoading(false);
        resetForm({ values: { ...payload } });
        setShowSnackBarSuccess(true);
        setMessage(response.message);
        setEditable(false);
        setDirty(false);
        setFormStatus((status) => ({ ...status, isReset: true }));
      } else {
        setLoading(false);
        setShowSnackBarError(true);
        setMessage(response.message);
        setDirty(false);
      }
    } else {
      setLoading(true);
      const response = await createQuiz(payload);
      if (response?.statusCode === CALL_SUCCESS) {
        setLoading(false);
        setShowSnackBarSuccess(true);
        setMessage(response.message);
        setEditable(false);
        resetForm({ values: { ...payload } });
        setDirty(false);
        setFormStatus((status) => ({ ...status, isReset: true }));
      } else {
        setLoading(false);
        setShowSnackBarError(true);
        setMessage(response.message);
        setDirty(false);
      }
    }
  };

  if (loadingQuestions) return <Typography style={{ marginTop: '2rem' }}>Loading...</Typography>;

  return isHaveQuestions ? (
    <QuestionTabContainer>
      <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
      <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
      <ConfirmPopup
        title="Notification"
        content={AT_LATEST_ONE_QUESTION}
        cancelText={'Cancel'}
        okText={''}
        btnCancelVariant={'filled'}
        btnOkVariant={'danger'}
        open={isShowConfirmPopup}
        onCancel={handleToggleConfirmPopup}
        onOk={handleToggleConfirmPopup}
        hiddenBtnOk={true}
        hiddenBtnCancel={false}
      />
      <Formik
        initialValues={quiz ? quiz : INIT_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, { resetForm }) => {
          handleQuizFormSubmit(values, resetForm);
        }}
      >
        {(formik) => {
          setDirty(formik.dirty);
          const handleCancelEditQuiz = () => {
            formik.resetForm();
            setEditable(false);
            setFormStatus((status) => ({ ...status, isReset: true }));
          };
          return (
            <Form>
              <FormikPromptIfDirty />
              <Box mr={6}>
                <QuizTitle>
                  <QuizLabel required>Quiz Title</QuizLabel>
                  <FormTextField
                    inputProps={{ maxLength: MAX_LENGTH_128 }}
                    name="title"
                    onChange={formik.handleChange}
                    variant="outlined"
                    fullWidth
                    error={formik.touched.title && !!formik.errors.title}
                    helperText={formik.touched.title && formik.errors.title}
                    onBlur={formik.handleBlur}
                    disabled={!isEditable}
                    value={formik.values.title}
                    autoFocus
                  />
                </QuizTitle>
              </Box>
              <FieldArray
                name="question"
                render={(helper) => {
                  const handleAddNewQuestion = () => {
                    helper.push({
                      questionType: SINGLE_CHOICE,
                      point: EMPTY_TEXT,
                      questionText: EMPTY_TEXT,
                      answer: [
                        {
                          answer: EMPTY_TEXT,
                          isTrue: true,
                        },
                      ],
                    });
                  };
                  return (
                    <>
                      {formik.values.question.map((question, index) => {
                        return (
                          <QuestionBox>
                            <Question
                              disabled={!isEditable}
                              type={question.questionType as any}
                              questionIndex={`${index}`}
                              formik={formik}
                              formStatus={formStatus}
                            />
                            <Box width={32} height={32} ml={2}>
                              <IconButtonStyled
                                onClick={() => {
                                  if (formik.values.question.length === 1 || !isEditable) return;
                                  helper.remove(index);
                                }}
                              >
                                {formik.values.question.length === 1 || !isEditable ? (
                                  <ImageIconStyled src={DeleteIconDisabled} />
                                ) : (
                                  <ImageIconStyled src={DeleteIcon} />
                                )}
                              </IconButtonStyled>
                            </Box>
                          </QuestionBox>
                        );
                      })}

                      {isEditable && (
                        <AddQuestion onClick={handleAddNewQuestion}>
                          <AddQuizIcon src={PlusIconImage} />
                          <Typography> Add question</Typography>
                        </AddQuestion>
                      )}
                    </>
                  );
                }}
              />
              <ActionButtons>
                {isEditable ? (
                  <>
                    <ActionButton variants="outlined" onClick={handleCancelEditQuiz}>
                      Cancel
                    </ActionButton>
                    <ActionButton variants="yellow" onClick={() => formik.submitForm()} disabled={loading}>
                      Save
                    </ActionButton>
                  </>
                ) : (
                  <ActionButton
                    disabled={disabledEditQuiz}
                    variants="yellow"
                    onClick={() => setEditable((status) => !status)}
                  >
                    Edit
                  </ActionButton>
                )}
              </ActionButtons>
            </Form>
          );
        }}
      </Formik>
      <Box display="flex" justifyContent="center" mt={3}>
        <TextStyled>
          Created on {event?.createdAt ? new Date(event?.createdAt).toLocaleDateString('en-GB') : ''} by{' '}
          {event?.createdBy ? event.createdBy : ''} | Last Updated on{' '}
          {event?.updatedAt ? new Date(event?.updatedAt).toLocaleDateString('en-GB') : ''} by:{' '}
          {event?.updatedBy ? event.updatedBy : ''}
        </TextStyled>
      </Box>
    </QuestionTabContainer>
  ) : (
    <NoHaveQuestion>
      <CreateQuizButton onClick={() => setIsHaveQuestions(true)} variants="yellow" disabled={disableCreateQuiz}>
        Create Quiz
      </CreateQuizButton>
    </NoHaveQuestion>
  );
};

export default Quiz;
