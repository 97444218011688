export const data: { image: string; header: string; age: number; title: string; description: string }[] = [
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1945.png',
    header: '第一次成立校友会',
    age: 1945,
    title: '1945年 第一次成立校友会',
    description:
      '10月7日， “新加坡南洋女中学校校友会”在亚洲太平洋战争和平后由三十多位校友推动而成立。简称“南中校友会”。主要任务为筹款和协助母校复办',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1952.png',
    header: '殖民地政府解散华校校友会',
    age: 1952,
    title: '1952年 殖民地政府解散华校校友会',
    description:
      '因为新加坡人民为实现民族自觉与民主政治的诉求，反对殖民地统治，导致学潮与工潮相继发生。华校校友会因受到这场浪潮的冲击，随之为殖民地政府下令解散。',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1976.png',
    header: '庆祝六十周年校庆兼催生校友会校友回校日',
    age: 1976,
    title: '1976年 庆祝六十周年校庆兼催生校友会校友回校日',
    description:
      '6月26日庆祝校庆并举行校友回校联欢晚宴，出席者逾六百名。刘蕙霞博士及李芳娇校友上台代表校友致词时，呼吁多举办校友回校晚宴。从而催生重组校友会的力量',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1984.png',
    header: '成立新校友会：“南洋女中校友会”。大会选出黄美云校友为会长。',
    age: 1984,
    title: '1984年 成立新校友会：“南洋女中校友会”。大会选出黄美云校友为会长。',
    description: '申请获得注册局批准，成为合法的团体。3月24日召开第一次会员大会，并选举21名理事。',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1987.png',
    header: '李淑端校友接任为第二任校友会会长',
    age: 1987,
    title: '1987年 李淑端校友接任为第二任校友会会长',
    description:
      'Amidst anti-colonial sentiments, labour unrest and student strikes were rife and Chinese schools’ alumni associations were implicated in such activities. As such, Nanyang Girls’ High Alumni Association was dissolved.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1995.png',
    header: '成立南洋中小幼校友会合唱团',
    age: 1995,
    title: '1987年 李淑端校友接任为第二任校友会会长',
    description: '积极主办南洋大家庭音乐会及专场音乐会，并活跃于本地和海外的音乐界。',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1998.png',
    header: '校友会正名为“南洋中小幼校友会”',
    age: 1998,
    title: '1998年 校友会正名为“南洋中小幼校友会”',
    description:
      '获得社团注册局批准。校友会原本就是包括南洋女中，南洋小学和 南洋幼稚园的校友，正名目的是使得三校的毕业生更加了解南洋三校是个大家庭。',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2000a.png',
    header: '首次主办南洋小学小一新生报名及预备入学讲座',
    age: 2000,
    title: '2000年 首次主办南洋小学小一新生报名及预备入学讲座',
    description: '讲座目的让家长了解小学生报名程序的问题。从此每年都主办这个讲座。',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2000b.png',
    header: '赠林秀梅铜像给南洋女中，纪念她对母校的贡献',
    age: 2000,
    title: '2000年 赠林秀梅铜像给南洋女中，纪念她对母校的贡献',
    description: '校友会特请雕塑家塑造一尊半身铜像，8月15日竖立于秀梅大会堂门前并邀请前总统王鼎昌先生主持揭幕仪式',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2001a.png',
    header: '永久会员的会费提高为一千元',
    age: 2001,
    title: '2001年 永久会员的会费提高为一千元',
    description: '1月1日起生效。55岁以上的永久会费则为两百零五元',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2000b.png',
    header: '2001年 主办《七彩寻乐画展》',
    age: 2001,
    title: '2001年 永久会员的会费提高为一千元',
    description: '为退休老师与校友福利基金筹款。',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2004.png',
    header: '特在南洋小学广场竖立刘韵仙校长与林秀梅校友的铜像',
    age: 2004,
    title: '2004年 特在南洋小学广场竖立刘韵仙校长与林秀梅校友的铜像',
    description: '8月13日举办揭幕礼。为纪念及表扬两位南洋功臣对三校的巨大贡献。也借此激励学弟妹们学习先贤们的榜样',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2007a.png',
    header: 'Celebrated the schools’ 90th anniversary with a musical and a commemorative book',
    age: 2007,
    title: '2007年 为母校举行九十周年校庆，举行千人晚宴大庆、出版史纪念特刊中文版“南洋九十年的故事——璀璨南洋”',
    description:
      '8月11日联合三校为母校举行千人晚宴大庆。敦请内阁资政李光耀先生为主宾。出版历史纪念特刊中文版“南洋九十年的故事——璀璨南洋”，配合校庆发行。',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2007b.png',
    header: 'Reconstruction of the Chin Tian Pavilion and Yuen Sien Pavilion',
    age: 2007,
    title: '2007年 重建“韵仙亭”与“振殿亭”',
    description:
      '8月15日为重建的两座亭举行揭幕礼。“振殿亭”建在南小校园，“韵仙亭”则建在南中校园。这两座纪念亭在肯士路南中旧校舍于1999年6月6日被拆除。',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2009.png',
    header: '庆祝校友会成立二十五周年，出版纪念会刊',
    age: 2009,
    title: '2009年 庆祝校友会成立二十五周年，出版纪念会刊',
    description: '纪念特刊“南洋绵绵情”于8月15日与母校校庆日，同日出版。',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2012.png',
    header: '李淑端校友卸任，蔡莹莹校友接任为第三任校友会会长。',
    age: 2012,
    title: '2012年 李淑端校友卸任，蔡莹莹校友接任为第三任校友会会长',
    description: '李淑端校友在校友会服务了28年后，在2012年卸任，蔡莹莹校友接棒成为第三任会长。',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2012b.png',
    header: '与母校联合庆祝九十五校庆，筹办晚宴、大型音乐剧“南洋馨”及出版纪念特刊英文版“璀璨南洋”',
    age: 2012,
    title: '2012年 与母校联合庆祝九十五校庆，筹办晚宴、大型音乐剧“南洋馨”及出版纪念特刊英文版“璀璨南洋”',
    description:
      '晚宴于8月15至18日在南洋女中的爱礼堂举行。多媒体音乐舞台剧“南洋馨”在南洋女中广场掀开序幕。面向南洋女中广场的校舍楼层化身为音乐剧的舞台。观众们在星光闪烁的夜幕下观赏这场绝然不同的表演。800名来自三校的学生和校友参与演出。英文版的校史特刊“璀璨南洋 Nanyang Glory – 95 Years of Splendour”，及华文版五年校史续篇于8月出版，配合校庆发行。',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2013.png',
    header: '修改章程纳入青年会员',
    age: 2013,
    title: '2013年 修改章程纳入青年会员',
    description:
      '2013年5月25日召开会员特别大会，通过议决修改校友会章程。申请获得社团注册局批准。10月26日举行会员特别会员大会，接受社团注册局针对校友会所建议的修改章程议决作出的更改。',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2014.png',
    header: '校友会启用新徽标',
    age: 2014,
    title: '2014年 校友会启用新徽标',
    description: '理事会议决改进旧徽标。新徽标的诞生代表了校友会与时并进的步伐。',
  },
];
