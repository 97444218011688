import React from 'react';

import { Box, Tooltip, Typography } from '@material-ui/core';
import html2canvas from 'html2canvas';
import QRCode from 'react-qr-code';
import styled from 'styled-components';

import downloadIcon from 'src/medias/icons/download-icon.svg';
import { IconButtonStyled } from 'src/pages/user-management/types';
import { IEvent } from 'src/services/events';

interface ICheckInCheckOut {
  eventId: string;
  event: IEvent | null;
}

const CheckInCheckOut = ({ eventId, event }: ICheckInCheckOut) => {
  const checkInURL = `${window.location.origin}/login?type=check-in&eventId=${eventId}`;
  const checkOutURL = `${window.location.origin}/login?type=check-out&eventId=${eventId}`;

  const _onCaptureQRCode = (id: string) => {
    const htmlData = document.getElementById(id);
    if (htmlData) {
      html2canvas(htmlData).then((canvas) => {
        const img = canvas.toDataURL('image/png');
        let link = document.createElement('a');
        link.download =
          id === 'check-in-qr-capture'
            ? `QR Code_Check-in_${event?.title}.png`
            : `QR Code_Check-out_${event?.title}.png`;
        link.href = 'data:' + img;
        document.body.appendChild(link);
        link.click();
      });
    }
  };

  return (
    <Box display="flex" justifyContent="center" m={7}>
      <Box flex={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Box id="check-in-qr-capture" p={2}>
          <QRCode value={checkInURL} size={136} />
        </Box>
        <Box mt={1}>
          <Tooltip title={'Download'}>
            <IconButtonStyled onClick={_onCaptureQRCode.bind(null, 'check-in-qr-capture')}>
              <DownloadIconStyled src={downloadIcon} />
            </IconButtonStyled>
          </Tooltip>
        </Box>
        <TypoStyled>Check-in QR Code</TypoStyled>
      </Box>
      <Box flex={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box id="check-out-qr-capture" p={2}>
          <QRCode value={checkOutURL} size={136} />
        </Box>
        <Box mt={1}>
          <Tooltip title={'Download'}>
            <IconButtonStyled onClick={_onCaptureQRCode.bind(null, 'check-out-qr-capture')}>
              <DownloadIconStyled src={downloadIcon} />
            </IconButtonStyled>
          </Tooltip>
        </Box>
        <TypoStyled>Check-out QR Code</TypoStyled>
      </Box>
    </Box>
  );
};

export default CheckInCheckOut;

const TypoStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
  marginTop: 8,
}));

const DownloadIconStyled = styled.img(() => ({}));
