import { Box, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const menus = ['Volunteer Check in/out', 'Ticket Check in'];

export const defaultSearchVolunteer = {
  eventName: '',
  volunteer: '',
  eventCategory: '',
  volunteerStatus: '',
  fromDate: null,
  toDate: null,
};

export const defaultSearchTicket = {
  eventName: '',
  ticketCode: '',
  eventCategory: '',
  ticketStatus: '',
  fromDate: null,
  toDate: null,
};

export const ticketStatus = [
  { key: 'checked-in', value: 'Checked In' },
  { key: 'pending', value: 'Pending' },
];

export const ButtonStatus = styled(Typography)<{
  variants?: 'pending' | 'incomplete' | 'complete' | 'confirmed' | undefined;
}>`
  ${({ theme, variants }) => css`
    padding: 6px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    background-color: ${variants === 'pending'
      ? theme.palette.yellow?.[150]
      : variants === 'incomplete'
      ? theme.palette.red?.[150]
      : variants === 'complete'
      ? theme.palette.green?.[900]
      : variants === 'confirmed'
      ? theme.palette.yellow?.[1000]
      : null};
    font-size: 13px;
    color: white;
    text-transform: capitalize;
  `}
`;

export const ButtonStatusTicket = styled(Typography)<{
  variants?: 'pending' | 'checked-in' | 'deactivated' | undefined;
}>`
  ${({ theme, variants }) => css`
    padding: 6px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    background-color: ${variants === 'pending'
      ? theme.palette.yellow?.[150]
      : variants === 'checked-in'
      ? theme.palette.green?.[900]
      : variants === 'deactivated'
      ? theme.palette.red?.[150]
      : null};
    font-size: 13px;
    color: white;
    text-transform: capitalize;
  `}
`;

export const AttendedManagementContainer = styled(Box)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    ${responsive?.isTabletOrMobile &&
    css`
      width: 1440px;
    `}
    ${responsive?.isDesktopOrLaptop &&
    css`
      width: 100%;
    `}
  `}
`;
