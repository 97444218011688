import React from 'react';

import { Box } from '@material-ui/core';
import moment from 'moment';
import QRCode from 'react-qr-code';

import { CustomButton } from 'src/components';
import BankSupportedURL from 'src/medias/images/banks-supported.png';
import { CaptionStyled, ImageStyled, SubCaptionStyled } from 'src/pages/donation/types';
import { TextNote } from 'src/pages/signup/components/types';
import PaynowQR from 'src/utils/paynow-code-generator';
import useResponsive from 'src/utils/responsive';

interface IPaymentMethod {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  amount: number;
  fullName: string;
  paymentId?: string;
}

const FORMAT = 'YYYYMMDD';
const COMPANY_NAME = 'VMO';
const UEN = 'S83SS0071H';
const QR_SIZE = 136;

const PaymentMethod = ({ activeStep, setActiveStep, amount, fullName, paymentId }: IPaymentMethod) => {
  const { isTabletOrMobile, isTablet } = useResponsive();

  let qrCode = new PaynowQR({
    uen: UEN,
    amount: amount,
    editable: false,
    expiry: moment().add(1, 'day').format(FORMAT),
    company: COMPANY_NAME,
    refNumber: `${paymentId} - ${fullName} - Pay membership fee`,
  });

  let QRstring = qrCode?.output();

  const _onSubmit = async () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      flex={1}
      flexDirection="column"
      mt={isTabletOrMobile ? 0 : 6}
      p={isTabletOrMobile ? 0 : 3}
    >
      {isTabletOrMobile ? (
        <TextNote isTabletOrMobile={isTabletOrMobile}>Scan below QR Code using your internet banking app</TextNote>
      ) : (
        <CaptionStyled>Scan below QR Code using your internet banking app</CaptionStyled>
      )}
      <SubCaptionStyled>QR Code will expire in 1 day</SubCaptionStyled>
      <Box m={3}>
        <QRCode value={QRstring} size={QR_SIZE} />
      </Box>
      {!isTabletOrMobile && <ImageStyled src={BankSupportedURL} />}
      {!isTabletOrMobile && (
        <Box>
          <SubCaptionStyled>
            If you finish the payment, please click Finish to complete your registration
          </SubCaptionStyled>
        </Box>
      )}
      <Box
        mt={4}
        display="flex"
        justifyContent="center"
        flex={1}
        width={'100%'}
        alignItems={isTabletOrMobile ? 'flex-end' : 'flex-start'}
        mb={4}
      >
        <CustomButton style={{ width: isTablet && '100%' }} onClick={_onSubmit}>
          Finish
        </CustomButton>
      </Box>
    </Box>
  );
};

export default PaymentMethod;
