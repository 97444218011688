import React from 'react';

import { Box } from '@material-ui/core';
import moment from 'moment';

import NSAATable, { INSAAColumn, INSAADataSource } from 'src/components/table';
import { capitalizeFirstLetter } from 'src/utils/common';

interface IEventsCompleted {
  events:
    | {
        id: string;
        type: string;
        event: {
          category: string;
          duration: number;
          end_time: string;
          start_time: string;
          title: string;
        };
      }[]
    | undefined;
}

const FORMAT_DATE = 'DD/MM/YYYY hh:mm A';
const VOLUNTEER_HOUR = 1;

const EventsCompleted = ({ events }: IEventsCompleted) => {
  const columns = React.useMemo(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'No',
        dataIndex: 'No',
        title: 'No.',
        width: 20,
      },
      {
        key: 'Event',
        dataIndex: 'Event',
        title: 'Event',
        width: 150,
      },
      {
        key: 'Role',
        dataIndex: 'Role',
        title: 'Role',
        width: 30,
      },
      {
        key: 'Event Date',
        dataIndex: 'EventDate',
        title: 'Event Date',
        width: 200,
      },
      {
        key: 'Session',
        dataIndex: 'Session',
        title: 'Session',
        width: 30,
      },
    ];
    return columns;
  }, []);

  const dataSource = React.useMemo(() => {
    const dataSource = events?.map((item, idx) => ({
      key: idx,
      id: item,
      No: idx + 1,
      Event:
        item?.event?.title && item?.event?.title.length > 55
          ? item.event?.title.slice(0, 55) + '...'
          : item?.event?.title,
      Role: item?.type ? capitalizeFirstLetter(item.type) : '',
      EventDate: `${item?.event?.start_time ? moment(+item?.event?.start_time).format(FORMAT_DATE) : ''} - ${
        item?.event?.end_time ? moment(+item?.event?.end_time).format(FORMAT_DATE) : ''
      }`,
      Session:
        item?.type === 'guest'
          ? '1.0'
          : item?.type === 'volunteer' && item?.event?.duration >= VOLUNTEER_HOUR
          ? '1.0'
          : '0.5',
    }));
    return dataSource as unknown as INSAADataSource[];
  }, [events]);

  return (
    <Box mt={2}>
      <NSAATable columns={columns} dataSource={dataSource} disabledSort />
    </Box>
  );
};

export default EventsCompleted;
