import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@material-ui/core';

import { useUpdateProfileAvatarMutation } from 'src/api/endpoints/profiles.endpoint';
import { useDeleteUserByIdMutation } from 'src/api/endpoints/users.endpoint';
import ConfirmPopup from 'src/components/confirm';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { ACCEPT_TYPE, CALL_SUCCESS } from 'src/constants/common';
import { uploadCoverPhoto } from 'src/services/events';

import { ActionButton, Avatar, BasicInfo, Container, Label, Text } from './profile.image.styled';

interface IProfileImageInformationProps {
  imageUrl: string;
  nsaaId: string;
  accountType: string;
  variants?: 'user-detail';
  userId?: string;
}

const ProfileImageInformation = ({
  accountType,
  imageUrl,
  nsaaId,
  variants,
  userId,
}: IProfileImageInformationProps) => {
  const [deleteUserById, deleteUserDeps] = useDeleteUserByIdMutation();
  const [updateProfileAvatar, updateProfileAvatarDeps] = useUpdateProfileAvatarMutation();
  const [removeAvatar, removeAvatarDeps] = useUpdateProfileAvatarMutation();

  useEffect(() => {
    if (deleteUserDeps.isError || updateProfileAvatarDeps.isError || removeAvatarDeps.isError) {
      setShowSnackBarError(true);
      setMessage(
        (deleteUserDeps.data as any)?.message ||
          (updateProfileAvatarDeps.data as any)?.message ||
          (removeAvatarDeps.data as any)?.message,
      );
    }

    if (deleteUserDeps.isSuccess || updateProfileAvatarDeps.isSuccess || removeAvatarDeps.isSuccess) {
      setShowSnackBarSuccess(true);
      setMessage(
        (deleteUserDeps.data as any)?.message ||
          (updateProfileAvatarDeps.data as any)?.message ||
          (removeAvatarDeps.data as any)?.message,
      );
      (updateProfileAvatarDeps.isSuccess || removeAvatarDeps.isSuccess) && window.location.reload();
    }
    return () => {
      setShowSnackBarError(false);
      setShowSnackBarSuccess(false);
    };
  }, [deleteUserDeps, updateProfileAvatarDeps, removeAvatarDeps]);

  const refInput = useRef<HTMLInputElement>();

  const [isShowConfirmPopup, setShowConfirmPopup] = useState(false);
  const [loading, setLoading] = useState<{ uploadImage: boolean }>({ uploadImage: false });

  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [message, setMessage] = useState('');

  const ONE_MB_FILE_SIZE = 1024;
  const UPLOAD_FILE_SIZE_MESSAGE = 'Uploaded file’s format must be PNG, JPG, IMG and size must be less than 1MB';

  const handleOkConfirmPopup = () => {
    handleTogglePopup();
    deleteUserById(userId!);
  };
  const handleTogglePopup = () => {
    setShowConfirmPopup((status) => !status);
  };

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };
  const handleUpdateAvatar = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileSize = e.target?.files?.[0].size;
    const fileS = Math.round(fileSize! / ONE_MB_FILE_SIZE);
    const type = e.target?.files?.[0].type;
    if (fileS > ONE_MB_FILE_SIZE || !Object.values(ACCEPT_TYPE).includes(type!)) {
      setShowSnackBarError(true);
      setMessage(UPLOAD_FILE_SIZE_MESSAGE);
      e.target.value = '';
      return;
    }
    if (e.target.files) {
      const formData = new FormData();
      formData.append('image', e.target.files[0]);
      setLoading({ ...loading, uploadImage: true });
      const response = await uploadCoverPhoto(formData);
      if (response?.statusCode === CALL_SUCCESS) {
        updateProfileAvatar({ avatar: response?.data.name! });
        setLoading({ ...loading, uploadImage: false });
        e.target.value = '';
      } else {
        setShowSnackBarError(true);
        setMessage(response?.message);
        setLoading({ ...loading, uploadImage: false });
        e.target.value = '';
      }
    }
  };

  const handleRemoveAvatar = () => {
    removeAvatar({ avatar: '' });
  };

  const setRef = (ref: HTMLInputElement | null) => {
    refInput.current = ref!;
  };

  const handleOpenImageFileDialog = () => {
    if (refInput.current) {
      refInput.current.click();
    }
  };

  const handleDeleteAccount = () => {
    handleTogglePopup();
  };

  return (
    <Container>
      <ConfirmPopup
        open={isShowConfirmPopup}
        title="Notification"
        content="Are you sure you want to delete this user?"
        onCancel={handleTogglePopup}
        onOk={handleOkConfirmPopup}
        btnCancelVariant="outlined"
        btnOkVariant="danger"
      />
      <SuccessSnackBar open={isShowSnackBarSuccess} message={message} handleClose={handleCloseSnackBar('success')} />
      <ErrorSnackBar open={isShowSnackBarError} message={message} handleClose={handleCloseSnackBar('error')} />

      <Avatar imageUrl={imageUrl} />
      <BasicInfo>
        <Box>
          <Box mb={2.5} display="flex">
            <Label>NSAA ID:</Label>
            <Text>{nsaaId || ''}</Text>
          </Box>
          <Box mb={5} display="flex">
            <Label>Account Type:</Label>
            <Text>{accountType || ''}</Text>
          </Box>
        </Box>
        <Box display="flex">
          <input accept=".png, .jpeg" hidden type="file" onChange={handleUpdateAvatar} ref={setRef} />
          {variants === 'user-detail' ? (
            <ActionButton variants="remove" onClick={handleDeleteAccount} disabled={deleteUserDeps.isLoading}>
              Delete Account
            </ActionButton>
          ) : (
            <Box>
              <ActionButton
                variants="upload"
                color="primary"
                onClick={handleOpenImageFileDialog}
                disabled={loading.uploadImage}
              >
                Upload Photo
              </ActionButton>
              {!imageUrl.startsWith('data:') && (
                <ActionButton variants="remove" onClick={handleRemoveAvatar} disabled={removeAvatarDeps.isLoading}>
                  Remove Photo
                </ActionButton>
              )}
            </Box>
          )}
        </Box>
      </BasicInfo>
    </Container>
  );
};

export default ProfileImageInformation;
