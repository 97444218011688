import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import { useRouteMatch } from 'react-router';
import styled, { css } from 'styled-components';

import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import { HeaderMobile } from 'src/components/headerMobile';
import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import { CALL_SUCCESS } from 'src/constants/common';
import useFetchMe from 'src/hooks/use-fetch-me';
import { Header } from 'src/layouts/login/components/header';
import { getRegisterVolunteer, IVolunteerData } from 'src/services/users';
import useResponsive from 'src/utils/responsive';

import { IResponsive } from '../home/homepageStyled';

import MenuBar from './components/MenuBar';
import VolunteerEventContent from './components/VolunteerEventContent';
import { menus } from './types';

export interface IMenuActive {
  text: string;
  value: string;
}
const GridContainer = styled(Grid)<{ responsive?: IResponsive; factoryScale: number; w: number; h: number }>`
  ${({ theme, responsive, factoryScale, w, h }) => css`
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    transform-origin: top left;
    width: ${w * (1 / factoryScale!)}px;
    height: ${responsive?.isTabletOrMobile ? h + 300 + 'px' : 'auto'};
    ${responsive?.isDesktopOrLaptop &&
    css`
      max-width: 100%;
    `}
    transform: ${`scale(${factoryScale})`};
  `}
`;
function MyVolunteerEvent() {
  const { path } = useRouteMatch();
  const responsive = useResponsive();
  const { me } = useFetchMe();

  const [menuActive, setMenuActive] = useState<IMenuActive>(menus[0]);
  const [data, setData] = useState<IVolunteerData | null>(null);
  const [loading, setIsLoading] = useState<boolean>();
  const getData = async () => {
    setIsLoading(true);
    const response = await getRegisterVolunteer(menuActive.value);
    if (response && response.statusCode === CALL_SUCCESS) {
      setData(response.data);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getData();
  }, [menuActive]);
  return (
    <>
      {responsive.isTabletOrMobile && <HeaderMobile title="My Volunteer Event" userProfile={me} />}
      {responsive.isDesktopOrLaptop && <Header />}
      <Box pt={responsive.isTabletOrMobile ? 15 : 3.75} />
      <LoadingCustom loading={loading} loadingIcon />
      {responsive.isDesktopOrLaptop && <NSAABreadcrumbs path={path} />}
      <GridContainer
        container
        justifyContent="center"
        responsive={responsive}
        factoryScale={responsive.isMobile ? 0.3 : responsive.isTablet ? 0.6 : 1}
        w={window.innerWidth}
        h={window.innerHeight}
      >
        <Box>
          <MenuBar setMenuActive={setMenuActive} menuActive={menuActive} />
          <VolunteerEventContent menuActive={menuActive} data={data} setMenuActive={setMenuActive} />
        </Box>
      </GridContainer>
    </>
  );
}
export default MyVolunteerEvent;
