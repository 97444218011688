import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import { CALL_SUCCESS } from 'src/constants/common';
import { getEventStatisticById, IEvent, IStatistic } from 'src/services/events';

import { StatisticContainer, StatisticItem, StatisticLabel, StatisticHeader } from './index.styled';

const GuestStatistic = ({ event }: { event: IEvent }) => {
  const [statistic, setStatistic] = useState<IStatistic>();
  const REGISTRATION_STATISTIC = [
    {
      label: 'Number of pending registrations',
      value: statistic?.pending,
    },
    {
      label: 'Number of Confirmed Registrations',
      value: statistic?.confirmed,
    },
    {
      label: 'Number of Expired Registration',
      value: statistic?.expired,
    },
    {
      label: 'Number of Pending & Confirmed Registration',
      value: statistic?.pendingConfirmed,
    },
    {
      label: 'Number of Attendance',
      value: statistic?.attended,
    },
  ];
  const TICKET_SOLD_STATISTIC = [
    { item: 'Number of unit - Standard tickets (A)', unit: statistic?.standard, amount: statistic?.amountStandard },
    {
      item: 'Number of unit - Vegetarian tickets (B)',
      unit: statistic?.vegetarian,
      amount: statistic?.amountVegetarian,
    },
    { item: 'Number of unit - Halal tickets (C)', unit: statistic?.halal, amount: statistic?.halal },
    { item: 'Total (A+B+C)', unit: '', amount: statistic?.total },
  ];
  useEffect(() => {
    (async () => {
      const response = await getEventStatisticById(event.id);
      if (response.statusCode === CALL_SUCCESS) {
        setStatistic(response?.data! as any);
      }
    })();
  }, []);

  return (
    <Box>
      <StatisticLabel>Registration Statistic</StatisticLabel>
      <StatisticContainer>
        <StatisticHeader display="flex">
          <StatisticItem w={792} pl={24} bold>
            Items
          </StatisticItem>
          <StatisticItem w={260} pl={24} bold>
            Amount
          </StatisticItem>
        </StatisticHeader>
        {REGISTRATION_STATISTIC.map((item, index) => {
          return index < REGISTRATION_STATISTIC.length - 1 ? (
            <Box display="flex">
              <StatisticItem w={792} pl={24} item>
                {item.label}
              </StatisticItem>
              <StatisticItem w={260} pl={24} item>
                {item.value}
              </StatisticItem>
            </Box>
          ) : (
            <Box display="flex">
              <StatisticItem w={792} pl={24} item bold>
                {item.label}
              </StatisticItem>
              <StatisticItem w={260} pl={24} item bold>
                {item.value}
              </StatisticItem>
            </Box>
          );
        })}
        <Box />
      </StatisticContainer>

      {event.require_ticket && (
        <>
          <StatisticLabel>Ticket Sold Statistic</StatisticLabel>
          <StatisticContainer border>
            <StatisticHeader display="flex">
              <StatisticItem w={532} pl={24} bold>
                Items
              </StatisticItem>
              <StatisticItem w={260} pl={24} bold>
                Name of unit
              </StatisticItem>
              <StatisticItem w={260} pl={24} bold>
                Amount
              </StatisticItem>
            </StatisticHeader>
            {TICKET_SOLD_STATISTIC.map((item, index) => {
              return index < TICKET_SOLD_STATISTIC.length - 1 ? (
                <Box display="flex">
                  <StatisticItem w={532} pl={24} item>
                    {item.item}
                  </StatisticItem>
                  <StatisticItem w={260} pl={24} item>
                    {item.unit}
                  </StatisticItem>
                  <StatisticItem w={260} pl={24} item>
                    {item.amount}
                  </StatisticItem>
                </Box>
              ) : (
                <Box display="flex">
                  <StatisticItem w={532} pl={24} item bold>
                    {item.item}
                  </StatisticItem>
                  <StatisticItem w={260} pl={24} item bold>
                    {item.unit}
                  </StatisticItem>
                  <StatisticItem w={260} pl={24} item bold>
                    {item.amount}
                  </StatisticItem>
                </Box>
              );
            })}
            <Box />
          </StatisticContainer>
        </>
      )}
    </Box>
  );
};

export default GuestStatistic;
