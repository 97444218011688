import React from 'react';

interface IAttachmentIconProps {
  width?: number;
  height?: number;
}

const AttachmentIcon = ({
  width = 23,
  height = 23,
  ...props
}: IAttachmentIconProps & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19.167 10.97l-7.703 7.58A5.073 5.073 0 017.907 20a5.073 5.073 0 01-3.558-1.45 4.912 4.912 0 01-1.474-3.502c0-1.313.53-2.572 1.474-3.501l7.702-7.58A3.382 3.382 0 0114.423 3c.89 0 1.743.348 2.372.967.629.619.982 1.459.982 2.334 0 .876-.353 1.715-.982 2.334l-7.71 7.58a1.69 1.69 0 01-1.187.484 1.69 1.69 0 01-1.186-.484 1.637 1.637 0 01-.491-1.167c0-.437.177-.857.491-1.167l7.116-6.994"
        stroke={'#C5BE8B'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AttachmentIcon;
