import React from 'react';

import { Box, FormControlLabel, InputAdornment, Link, Radio, RadioGroup } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';

import { ReactFlagsSelectStyled } from 'src/pages/signup/components/types';
import { IUser } from 'src/services/users';
import { customLabels, getAreaKey } from 'src/utils/areaCode';

import { Title } from '../components/additional-information.styled';
import { Label, FormTextField } from '../components/general-information.styled';

interface IAdditionalInformationYouthFormProps {
  profile: IUser | null;
}

const AdditionalInformationYouthForm = ({ profile }: IAdditionalInformationYouthFormProps) => {
  if (!profile) return <div />;
  return (
    <Box px={3}>
      <Title>Your Parent/Guardian Info</Title>
      <Box my={3} display="flex" style={{ gap: 24 }}>
        <Box flex="1">
          <Label>Surname</Label>
          <FormTextField variant="outlined" fullWidth disabled value={profile.parentSurname} />
        </Box>
        <Box flex="1">
          <Label>Given Name</Label>
          <FormTextField variant="outlined" fullWidth disabled value={profile.parentGivenName} />
        </Box>
      </Box>
      <Box mb={3}>
        <Label>Relationship</Label>
        <RadioGroup value={profile?.relationship!} onChange={(e, v) => {}} row>
          <FormControlLabel disabled value="parent" control={<Radio disabled />} label="Parent" />
          <Box width={160} />
          <FormControlLabel disabled value="guardian" control={<Radio disabled />} label="Guardian" />
        </RadioGroup>
      </Box>
      <Box mb={3}>
        <Label>Email Address</Label>
        <FormTextField variant="outlined" fullWidth disabled value={profile.parentEmail} />
      </Box>
      <Box mb={3} display="flex" style={{ gap: 24 }} alignItems="flex-end">
        <Box flex="0.5">
          <Label>Phone number</Label>
          <ReactFlagsSelectStyled
            fullWidth
            selected={profile.parentAreaCode ? getAreaKey(profile.parentAreaCode) : ''}
            onSelect={() => {}}
            searchable
            customLabels={customLabels}
            showSecondaryOptionLabel
            showSelectedLabel={false}
            disabled
          />
        </Box>
        <Box flex="0.5">
          <FormTextField
            disabled
            variant="outlined"
            fullWidth
            size="small"
            value={profile.parentMobilePhone}
            onChange={(v) => {}}
            name="parentAreaCode"
          />
        </Box>
      </Box>
      <Box mb={4}>
        <Label>Parent’s Consent Proof</Label>
        <FormTextField
          variant="outlined"
          value=""
          fullWidth
          disabled
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AddCircleOutline />
                <Box mx={1}>
                  {profile?.signature ? (
                    <Link target="_blank" href={profile?.signature || ''}>
                      Attach File
                    </Link>
                  ) : (
                    'Attach File'
                  )}
                </Box>
              </InputAdornment>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default AdditionalInformationYouthForm;
