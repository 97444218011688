import React, { useState } from 'react';

import { Box, FormControl, FormControlLabel, MenuItem, RadioGroup } from '@material-ui/core';

import { CustomButton, CustomInputSelect, InputField, NASSRadio } from 'src/components';
import { INPUT_MAX_LENGTH_1000 } from 'src/constants/users.constants';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { ISignUpReq } from 'src/services/auth';
import { _checkErrors } from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { ErrorMessage } from './BasicInformation';
import { Child } from './Child';
import { Skills } from './Skills';
import { CustomLabel, TextNote, nationalities } from './types';

interface IGetToKnowYou {
  data: ISignUpReq;
  setData: React.Dispatch<React.SetStateAction<ISignUpReq>>;
  checkChild: boolean;
  setCheckChild: React.Dispatch<React.SetStateAction<boolean>>;
  isHaveChildren: string;
  _onHandleClickHaveChild: (event: {
    target: {
      value: string;
      name: string;
    };
  }) => void;
}

const GetToKnowYou = ({
  data,
  setData,
  checkChild,
  setCheckChild,
  isHaveChildren,
  _onHandleClickHaveChild,
}: IGetToKnowYou) => {
  useScrollToTop();
  const { isTabletOrMobile } = useResponsive();
  const [errors, setErrors] = useState({
    surname: false,
    givenName: false,
    dateOfBirth: false,
  });

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [e.target.name]: e.target.value });
  }
  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setData({ ...data, [e.target.name]: valueTrimmed });
  };
  function toggleCheckboxValue(item: string) {
    let skillsChecked = data.skills === '' ? [] : data?.skills?.split(',');

    if (skillsChecked?.includes(item)) {
      var idx = skillsChecked?.indexOf(item);
      skillsChecked?.splice(idx, 1);

      setData({ ...data, skills: skillsChecked?.join(',') });
    } else {
      skillsChecked?.push(item);

      setData({ ...data, skills: skillsChecked?.join(',') });
    }
    // }
  }

  function _onAddChild() {
    if (data.child) {
      const newChild = [
        ...data.child,
        { givenName: '', surname: '', dateOfBirth: null, nationality: nationalities[0].key },
      ];
      setData({ ...data, child: newChild });
    }
  }

  function _onDeleteChild(idx: number) {
    let newChild = data.child;
    if (newChild) {
      newChild.splice(idx, 1);
      setData({ ...data, child: newChild });
    }
  }

  const skillsRender = [
    {
      label: 'Editorial',
      name: 'Editorial',
      checked: data.skills?.includes('Editorial'),
    },
    {
      label: 'Translation',
      name: 'Translation',
      checked: data.skills?.includes('Translation'),
    },
    {
      label: 'Legal Advice',
      name: 'Legal Advice',
      checked: data.skills?.includes('Legal Advice'),
    },
    {
      label: 'Building/Architecture/Estate Management',
      name: 'Building/Architecture/Estate Management',
      checked: data.skills?.includes('Building/Architecture/Estate Management'),
    },
    {
      label: 'Artistic Design',
      name: 'Artistic Design',
      checked: data.skills?.includes('Artistic Design'),
    },
  ];
  return (
    <Box>
      <Box justifyContent="center" display="flex">
        <TextNote isTabletOrMobile={isTabletOrMobile}>Let’s get to know each other!</TextNote>
      </Box>
      <Box>
        <CustomInputSelect
          notRequired
          title="Nationality"
          name="nationality"
          value={data.nationality}
          onChange={_handleChange}
        >
          {nationalities.map((nationality) => (
            <MenuItem key={nationality.key} value={nationality.key}>
              {nationality.value}
            </MenuItem>
          ))}
        </CustomInputSelect>
      </Box>
      <Box>
        <InputField
          value={data.jobTitle}
          onChange={_handleChange}
          onBlur={onBlurField}
          margin="dense"
          name="jobTitle"
          title="Job Title"
          fullWidth
          InputProps={{
            inputProps: {
              maxLength: INPUT_MAX_LENGTH_1000,
            },
          }}
        />
      </Box>
      <Box>
        <InputField
          value={data.companyName}
          onChange={_handleChange}
          onBlur={onBlurField}
          margin="dense"
          name="companyName"
          title="Company Name"
          fullWidth
          InputProps={{
            inputProps: {
              maxLength: INPUT_MAX_LENGTH_1000,
            },
          }}
        />
      </Box>
      <Box display="flex" mt={1}>
        <Box flex={1}>
          <CustomLabel>Skills</CustomLabel>
        </Box>
      </Box>
      {skillsRender.map((item) => {
        return <Skills key={item.name} item={item} toggleCheckboxValue={toggleCheckboxValue} />;
      })}
      <Box>
        <InputField
          value={data.otherSkills}
          margin="dense"
          name="otherSkills"
          title="Other Skills"
          fullWidth
          placeholder="Example: Doctors,Engineer,Accountant"
          onChange={_handleChange}
          onBlur={onBlurField}
          InputProps={{
            inputProps: {
              maxLength: INPUT_MAX_LENGTH_1000,
            },
          }}
        />
      </Box>
      <Box flex={1} mt={2} mb={1}>
        <CustomLabel>Do You Have Children?</CustomLabel>
      </Box>
      <Box>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="confirm-old"
            value={isHaveChildren}
            onChange={_onHandleClickHaveChild}
            name="radio-buttons-group"
            row
          >
            <FormControlLabel value="yes" control={<NASSRadio />} label="Yes" />
            <Box width={'250px'} />
            <FormControlLabel value="no" control={<NASSRadio />} label="No" />
          </RadioGroup>
        </FormControl>
      </Box>

      {isHaveChildren === 'yes' && _checkErrors(errors) && (
        <ErrorMessage isError={_checkErrors(errors)}>Please correct your child information</ErrorMessage>
      )}

      {isHaveChildren === 'yes' && data?.child?.length === 0 && (
        <ErrorMessage isError={isHaveChildren === 'yes' && data?.child?.length === 0}>
          Please correct your child information
        </ErrorMessage>
      )}

      {isHaveChildren === 'yes' &&
        data.child?.map((child, index) => (
          <Child
            key={index}
            data={data}
            setData={setData}
            child={child}
            index={index}
            _onDeleteChild={_onDeleteChild}
            checkChild={checkChild}
            setCheckChild={setCheckChild}
            errors={errors}
            setErrors={setErrors}
          />
        ))}

      {isHaveChildren === 'yes' && (
        <Box mt={2} mb={2} display="flex" justifyContent="center">
          <CustomButton outlineButton onClick={_onAddChild} style={{ width: '100%' }}>
            Add
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};

export default GetToKnowYou;
