import React from 'react';

import { Box, Link, Tooltip } from '@material-ui/core';
import moment from 'moment';

import { CustomPagination } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import NSAATable, { INSAAColumn, INSAADataSource } from 'src/components/table';
import { CALL_SUCCESS, FORMAT_DATE_DMY } from 'src/constants/common';
import EditIcon from 'src/medias/icons/edit-icon.svg';
import IconSuccess from 'src/medias/icons/icon-success.png';
import UpdateVolunteerModal from 'src/pages/event-management/components/volunteer/components/confirm-list/update-volunter-modal';
import { ConfirmModal } from 'src/pages/user-management/components/ConfirmModal';
import UserInformation from 'src/pages/user-management/components/UserInformation';
import {
  Actions,
  IconButtonStyled,
  IconVerifyStyled,
  ImageIconStyled,
  LabelStyled,
  Title,
} from 'src/pages/user-management/types';
import { IUser } from 'src/services/users';
import { ISearchVolunteerAttendance, IVolunteer, updateVolunteer } from 'src/services/volunteer';
import { IMeta } from 'src/types';
import { convertSlugToUpperCase, intRegex, shallowEqual } from 'src/utils/common';

import { ButtonStatus, defaultSearchVolunteer } from '../types';

interface ITable {
  volunteers: IVolunteer[];
  data: IMeta | undefined;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  loading?: boolean;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  getData({
    search,
    currentPage,
    limit,
  }: {
    search: ISearchVolunteerAttendance;
    currentPage: number;
    limit: number;
  }): Promise<void>;
  currentPage: number;
  search: ISearchVolunteerAttendance;
}

const Listing = ({
  volunteers,
  data,
  currentPage,
  setCurrentPage,
  loading,
  limit,
  setLimit,
  getData,
  search,
}: ITable) => {
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [disableSearchPage, setDisableSearchPage] = React.useState(false);
  const [searchPage, setSearchPage] = React.useState('');
  const [openConfirmActionModal, setOpenConfirmActionModal] = React.useState(false);
  const [actionSelected, setActionSelected] = React.useState('');
  const [isActionSuccess, setActionSuccess] = React.useState(false);
  const [isActionFailed, setActionFailed] = React.useState(false);
  const [messageResponse, setMessageResponse] = React.useState('');
  const [itemSelected, setItemSelected] = React.useState<IVolunteer[]>([]);
  const [openUpdateVolunteerModal, setOpenUpdateVolunteerModal] = React.useState(false);
  const [updateVolunteerInfo, setUpdateVolunteerInfo] = React.useState<
    | {
        scanTicket: string;
        attendanceStatus: string;
        checkinTime: null | string | Date;
        checkoutTime: null | string | Date;
      }
    | undefined
  >();
  const [userClicked, setUserClicked] = React.useState<IUser>();
  const [openDrawer, setOpenDrawer] = React.useState({
    userInformation: false,
  });

  const handleClose = () => {};

  const handleUpdateVolunteerModal = () => {
    setOpenUpdateVolunteerModal(!openUpdateVolunteerModal);
    setItemSelected([]);
  };

  const handleChangePagination = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || e.target.value === '0') {
      setSearchPage('');
      return;
    }
    if (e.target.name === 'searchPage') {
      if (data?.totalPages) {
        if (!intRegex.test(e.target.value)) {
          setDisableSearchPage(true);
        } else {
          if (Number(e.target.value) > data.totalPages) {
            setDisableSearchPage(true);
          } else {
            setDisableSearchPage(false);
            setSearchPage(e.target.value);
          }
        }
      } else {
        setDisableSearchPage(true);
      }
    }
    if (e.target.name === 'records/page') {
      setLimit(Number(e.target.value));
      setSearchPage('');
      setCurrentPage(1);
    }
  };

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setSearchPage(valueTrimmed);
  };

  const _onCloseModalConfirmAction = () => {
    setOpenConfirmActionModal(false);
  };

  const _onSelectedAction = async () => {
    setLoadingAction(true);
    if (actionSelected === 'update-volunteer') {
      if (itemSelected) {
        const response = await updateVolunteer(itemSelected[0].id, updateVolunteerInfo);
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleUpdateVolunteerModal();
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
  };

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const onClickChangePage = () => {
    setCurrentPage(Number(searchPage));
  };

  const _onSubmitSendMail = () => {
    setOpenConfirmActionModal(true);
  };

  const _onClickUserName = (user: IUser) => {
    setOpenDrawer({ ...openDrawer, userInformation: true });
    setUserClicked(user);
  };

  const toggleDrawer = (name: string, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    setOpenDrawer({ ...openDrawer, [name]: open });
  };

  const columns = React.useMemo(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'EventName',
        dataIndex: 'EventName',
        title: 'Event Name',
        width: 250,
      },
      {
        key: 'EventCategory',
        dataIndex: 'EventCategory',
        title: 'Event Category',
        width: 170,
        ellipsis: true,
      },
      {
        key: 'StartDate',
        dataIndex: 'StartDate',
        title: 'Start Date',
        width: 60,
      },
      {
        key: 'Volunteer',
        dataIndex: 'Volunteer',
        title: 'Volunteer',
        width: 130,
        render: (value, record) => {
          return (
            <Link onClick={_onClickUserName.bind('', record.id?.user)} underline="none">
              <LabelStyled>
                {(record.id?.user?.surname + ', ' + record.id?.user?.givenName).length > 18
                  ? (record.id?.user?.surname + ', ' + record.id?.user?.givenName)?.slice(0, 18) + '...'
                  : record.id?.user?.surname + ', ' + record.id?.user?.givenName}
                {record.id?.user?.verificationStatus === 'verified' && <IconVerifyStyled src={IconSuccess} />}
              </LabelStyled>
            </Link>
          );
        },
      },
      {
        key: 'VolunteerStatus',
        dataIndex: 'VolunteerStatus',
        title: 'Volunteer Status',
        width: 80,
        render: (value, record) => {
          let variants: 'pending' | 'incomplete' | 'complete' | 'confirmed' | undefined =
            value === 'pending'
              ? 'pending'
              : value === 'incomplete'
              ? 'incomplete'
              : value === 'complete'
              ? 'complete'
              : value === 'confirmed'
              ? 'confirmed'
              : undefined;
          return <ButtonStatus variants={variants}>{value}</ButtonStatus>;
        },
      },
      {
        key: 'Actions',
        title: '',
        dataIndex: ' Actions',
        width: 50,
        render: (value, record) => {
          return (
            <Actions>
              <Tooltip title={'Edit'}>
                <IconButtonStyled
                  onClick={() => {
                    setItemSelected([record.id]);
                    setActionSelected('update-volunteer');
                    setOpenUpdateVolunteerModal(true);
                  }}
                >
                  <ImageIconStyled src={EditIcon} />
                </IconButtonStyled>
              </Tooltip>
            </Actions>
          );
        },
      },
    ];
    return columns;
  }, []);

  const dataSource = React.useMemo(() => {
    const dataSource = volunteers.map((item, idx) => ({
      key: idx,
      id: item,
      EventName:
        item?.event?.title && item?.event?.title.length > 55
          ? item.event?.title.slice(0, 55) + '...'
          : item?.event?.title,
      EventCategory: convertSlugToUpperCase(item?.event?.category ?? ''),
      StartDate: item.event?.start_time ? moment(+item.event?.start_time).format(FORMAT_DATE_DMY) : '',
      Volunteer: item?.user?.surname,
      VolunteerStatus: item.attendanceStatus,
    }));
    return dataSource as unknown as INSAADataSource[];
  }, [volunteers]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="left">
        <Title>{shallowEqual(search, defaultSearchVolunteer) ? `Listing` : `Results`}</Title>
      </Box>
      <Box mt={2} mb={2}>
        <NSAATable loading={loading} columns={columns} dataSource={dataSource} />
      </Box>
      <CustomPagination
        itemName={'volunteer(s)'}
        data={data}
        limit={limit}
        handleChangePagination={handleChangePagination}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onClickChangePage={onClickChangePage}
        disableSearchPage={disableSearchPage || searchPage === ''}
        searchPage={searchPage}
        onBlurField={onBlurField}
      />
      {openUpdateVolunteerModal && (
        <UpdateVolunteerModal
          event={itemSelected[0]?.event}
          loading={loading}
          open={openUpdateVolunteerModal}
          _onCloseModal={handleUpdateVolunteerModal}
          _onSubmit={_onSubmitSendMail}
          userSelected={itemSelected[0]}
          setUpdateVolunteerInfo={setUpdateVolunteerInfo}
        />
      )}
      {openDrawer.userInformation && (
        <UserInformation userClicked={userClicked} open={openDrawer.userInformation} toggleDrawer={toggleDrawer} />
      )}
      {openConfirmActionModal && (
        <ConfirmModal
          open={openConfirmActionModal}
          handleClose={_onCloseModalConfirmAction}
          onSubmit={_onSelectedAction}
          actionSelected={actionSelected}
          disabled={loadingAction}
        />
      )}
      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </Box>
  );
};

export default Listing;
