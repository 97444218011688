import React from 'react';

import { membershipInformation } from '../../data';
import Membership from '../../shared/membership.component';

const MembershipInformation = () => {
  return <Membership data={membershipInformation} />;
};

export default MembershipInformation;
