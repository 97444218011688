import React from 'react';

import { Box, Collapse, IconButton, makeStyles, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useFetchProfileQuery } from 'src/api/endpoints/profiles.endpoint';
import { CustomDrawer } from 'src/components';

import { menuAboutUs, menuContactUs, menuMembership } from '../type';

import BasicMenu from './BasicMenu';

interface IRightDrawer {
  toggleDrawer: (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  open: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isLogin?: boolean;
  userProfile?: any;
  redirectURL?: string;
  isCommitteePortal?: boolean;
}

function SideMenu({
  toggleDrawer,
  open,
  isMobile,
  isTablet,
  isLogin,
  userProfile,
  redirectURL,
  isCommitteePortal,
}: IRightDrawer) {
  const classes = useStyles();
  const [expandedAboutUs, setExpandedAboutUs] = React.useState(false);
  const [expandedContactUs, setExpandedContactUs] = React.useState(false);
  const [expandedMembership, setExpandedMembership] = React.useState(false);
  const { data: profile } = useFetchProfileQuery();
  const ExpandAboutUsClick = () => {
    setExpandedAboutUs(!expandedAboutUs);
    setExpandedContactUs(false);
    setExpandedMembership(false);
  };
  const ExpandContactUsClick = () => {
    setExpandedContactUs(!expandedContactUs);
    setExpandedAboutUs(false);
    setExpandedMembership(false);
  };
  const ExpandMembershipClick = () => {
    setExpandedMembership(!expandedMembership);
    setExpandedAboutUs(false);
    setExpandedContactUs(false);
  };
  return (
    <CustomDrawerCustom anchor="left" open={open} onClose={toggleDrawer(false)}>
      <Box className={classes.container} width={isMobile ? 300 : 500} />
      {!isCommitteePortal ? (
        <Box mt={10}>
          <BoxStyled>
            <NavLinkResponsiveStyled exact activeClassName="header__nav-active" to="/">
              <BasicMenu text={'Home'} link="/" grayColor isMobile />
            </NavLinkResponsiveStyled>
          </BoxStyled>
          <BoxStyled
            onClick={ExpandAboutUsClick}
            active={window.location.pathname.includes('about-us')}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <BasicMenu text={'ABOUT US'} grayColor />
            <IconButton
              className={expandedAboutUs ? classes.expand : classes.expandOpen}
              aria-expanded={expandedAboutUs}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </BoxStyled>
          <Collapse in={expandedAboutUs} timeout="auto" unmountOnExit>
            <ExpandMenu>
              {menuAboutUs.map((item) => (
                <ExpandItem>
                  <LiNavLink to={item.link}>{item.title}</LiNavLink>
                </ExpandItem>
              ))}
            </ExpandMenu>
          </Collapse>
          <BoxStyled>
            <NavLinkResponsiveStyled activeClassName="header__nav-active" to="/events">
              <BasicMenu text={'events & activities'} link="/events" grayColor />
            </NavLinkResponsiveStyled>
          </BoxStyled>
          <BoxStyled>
            <NavLinkResponsiveStyled activeClassName="header__nav-active" to="/news">
              <BasicMenu text={'NEWS'} link="/news" grayColor isMobile />
            </NavLinkResponsiveStyled>
          </BoxStyled>
          <BoxStyled
            onClick={ExpandMembershipClick}
            active={window.location.pathname.includes('membership')}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <BasicMenu text={'MEMBERSHIP'} grayColor />
            <IconButton
              className={expandedMembership ? classes.expand : classes.expandOpen}
              aria-expanded={expandedMembership}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </BoxStyled>
          <Collapse in={expandedMembership} timeout="auto" unmountOnExit>
            <ExpandMenu>
              {menuMembership.map((item) => (
                <ExpandItem>
                  <LiNavLink to={item.link}>{item.title}</LiNavLink>
                </ExpandItem>
              ))}
            </ExpandMenu>
          </Collapse>
          <BoxStyled
            onClick={ExpandContactUsClick}
            active={window.location.pathname.includes('contact-us')}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <BasicMenu text={'CONTACT US'} grayColor />
            <IconButton
              className={expandedContactUs ? classes.expand : classes.expandOpen}
              aria-expanded={expandedContactUs}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </BoxStyled>
          <Collapse in={expandedContactUs} timeout="auto" unmountOnExit>
            <ExpandMenu>
              {menuContactUs.map((item) => (
                <ExpandItem>
                  <LiNavLink to={item.link}>{item.title}</LiNavLink>
                </ExpandItem>
              ))}
            </ExpandMenu>
          </Collapse>
          {isLogin &&
            ((userProfile && userProfile.committeeMember === 'yes') ||
              (profile?.data && profile?.data?.committeeMember === 'yes')) && (
              <>
                <BoxStyled>
                  <NavLinkResponsiveStyled activeClassName="header__nav-active" to="/donation">
                    <BasicMenu text={'donation'} grayColor />
                  </NavLinkResponsiveStyled>
                </BoxStyled>
                <BoxStyled>
                  <NavLinkResponsiveStyled
                    activeClassName="header__nav-active"
                    to={redirectURL ? redirectURL : '/user-management'}
                  >
                    <BasicMenu text={'Committee portal'} grayColor />
                  </NavLinkResponsiveStyled>
                </BoxStyled>
              </>
            )}
        </Box>
      ) : (
        <Box mt={10}>
          <BoxStyled>
            <NavLinkResponsiveStyled exact activeClassName="header__nav-active" to="/">
              <BasicMenu text={'HOME'} link="/" grayColor isMobile />
            </NavLinkResponsiveStyled>
          </BoxStyled>
          <BoxStyled active={window.location.pathname.includes('user-management')}>
            <NavLinkResponsiveStyled exact activeClassName="header__nav-active" to="/user-management">
              <BasicMenu text={'USER MANAGEMENT'} link="/user-management" grayColor isMobile />
            </NavLinkResponsiveStyled>
          </BoxStyled>
          <BoxStyled active={window.location.pathname.includes('event-management')}>
            <NavLinkResponsiveStyled exact activeClassName="header__nav-active" to="/event-management">
              <BasicMenu text={'EVENT MANAGEMENT'} link="/event-management" grayColor isMobile />
            </NavLinkResponsiveStyled>
          </BoxStyled>
          <BoxStyled active={window.location.pathname.includes('attendance-management')}>
            <NavLinkResponsiveStyled exact activeClassName="header__nav-active" to="/attendance-management">
              <BasicMenu text={'ATTENDANCE MANAGEMENT'} link="/attendance-management" grayColor isMobile />
            </NavLinkResponsiveStyled>
          </BoxStyled>
          <BoxStyled active={window.location.pathname.includes('payment-management')}>
            <NavLinkResponsiveStyled exact activeClassName="header__nav-active" to="/payment-management">
              <BasicMenu text={'PAYMENT MANAGEMENT'} link="/payment-management" grayColor isMobile />
            </NavLinkResponsiveStyled>
          </BoxStyled>
          <BoxStyled active={window.location.pathname.includes('gtky-applicant')}>
            <NavLinkResponsiveStyled exact activeClassName="header__nav-active" to="/gtky-applicant">
              <BasicMenu text={'GTKY APPLICANT'} link="/gtky-applicant" grayColor isMobile />
            </NavLinkResponsiveStyled>
          </BoxStyled>
          <BoxStyled active={window.location.pathname.includes('email-tracking')}>
            <NavLinkResponsiveStyled exact activeClassName="header__nav-active" to="/email-tracking">
              <BasicMenu text={'EMAIL TRACKING'} link="/email-tracking" grayColor isMobile />
            </NavLinkResponsiveStyled>
          </BoxStyled>
          <BoxStyled active={window.location.pathname.includes('cms')}>
            <NavLinkResponsiveStyled exact activeClassName="header__nav-active" to="/cms">
              <BasicMenu text={'CMS'} link="/cms" grayColor isMobile />
            </NavLinkResponsiveStyled>
          </BoxStyled>
        </Box>
      )}
      {!isCommitteePortal && (
        <LanguageBox>
          <NavLinkStyled to="/">
            <Text isMobile={isMobile}>English</Text>
          </NavLinkStyled>
          <Text isMobile={isMobile}>|</Text>
          <NavLinkStyled to={'/cn'}>
            <Text isMobile={isMobile}>
              <span>中文</span>
            </Text>
          </NavLinkStyled>
        </LanguageBox>
      )}
    </CustomDrawerCustom>
  );
}

export default SideMenu;

const useStyles = makeStyles((theme) => ({
  container: {
    transition: '0.4s',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));
const BoxStyled = styled(Box)<{ active?: boolean }>`
  &.MuiBox-root {
    &::after {
      content: '';
      display: inline-block;
      position: absolute;
      width: calc(100% - 32px);
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      bottom: 0;
      left: 18px;
      right: 32px;
    }
  }
  ${({ theme, active }) => css`
    position: relative;
    width: 95%;
    padding-right: 16px;
    height: 60px;
    cursor: pointer;
    ${active &&
    css`
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
    `}

    .MuiButtonBase-root.MuiIconButton-root {
      padding: 0;
    }
  `}
`;
const CustomDrawerCustom = styled(CustomDrawer)`
  &.MuiDrawer-paperAnchorLeft {
    overflow: hidden;
  }
`;
const NavLinkResponsiveStyled = styled(NavLink)`
  && {
    font-size: 16px;
    text-decoration: none;
    height: 100%;
    margin-right: 15px;
    color: rgba(0, 0, 0, 0.6);
  }
  ${({ theme }) => css`
    position: relative;
    &.header__nav-active {
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
    }
  `}
`;
const LiNavLink = styled(NavLink)`
  && {
    font-size: 16px;
    text-decoration: none;
    height: 100%;
    margin-right: 15px;
    color: rgba(0, 0, 0, 0.6);
    width: calc(100% - 32px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  ${({ theme }) => css`
    position: relative;
    &.header__nav-active {
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
    }
  `}
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const ExpandMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: disc;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 0;
  margin: 0;
  margin-left: 54px;
`;
const ExpandItem = styled.li`
  ${({ theme }) => ({
    ...theme.fonts.textS,
    height: '60px',
  })}
`;
const LanguageBox = styled(Box)`
  display: flex;
  justify-content: center;
  padding: 24px;
  gap: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;
const Text = styled(Typography)<{ isMobile?: boolean }>`
  ${({ theme, isMobile }) => ({
    ...theme.fonts.textSSBold,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontSize: isMobile ? '18px' : '24px',
    color: '#00000080',
    userSelect: 'none',
    cursor: 'default',
  })}
  span {
    ${({ theme, isMobile }) => ({
      ...theme.fonts.textSS,
      fontSize: isMobile ? '18px' : '24px',
      userSelect: 'all',
      cursor: 'pointer',
    })}
  }
`;
const NavLinkStyled = styled(NavLink)`
  text-decoration: none;
`;
