import React, { useEffect } from 'react';

import { Box, Link, Popover, Tooltip } from '@material-ui/core';
import moment from 'moment';

import { Loading, CustomPagination } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import NSAATable, { INSAAColumn, INSAADataSource } from 'src/components/table';
import { CALL_SUCCESS, FORMAT_TIME_AM_PM } from 'src/constants/common';
import DeleteIcon from 'src/medias/icons/delete-icon.png';
import EditIcon from 'src/medias/icons/edit-icon.svg';
import IconSuccess from 'src/medias/icons/icon-success.png';
import SendMailIcon from 'src/medias/icons/send-mail-icon.svg';
import { ConfirmModal } from 'src/pages/user-management/components/ConfirmModal';
import {
  CustomButtonStyled,
  MenuItemStyled,
  KeyboardArrowDownIconStyled,
  Title,
  Actions,
  IconButtonStyled,
  ImageIconStyled,
  LabelStyled,
  IconVerifyStyled,
} from 'src/pages/user-management/types';
import { IEvent } from 'src/services/events';
import { sendEmail } from 'src/services/users';
import {
  assignETicket,
  exportConfirmListCSV,
  getVolunteerByEventId,
  ISearchVolunteer,
  IVolunteer,
  removeVolunteerConfirmedList,
  updateVolunteer,
} from 'src/services/volunteer';
import { IMeta } from 'src/types';
import { convertSlugToUpperCase, intRegex, shallowEqual } from 'src/utils/common';

import { actionsFlag, defaultSearchVolunteer, ButtonStatus } from '../../types';

import Popup from './popup';
import RemoveVolunteerModal from './remove-volunteer';
import SendEmailModal from './send-mail';
import UpdateVolunteerModal from './update-volunter-modal';
import RightDrawer from './volunteerInformation';

interface ITable {
  volunteers: IVolunteer[];
  data: IMeta | undefined;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  loading?: boolean;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  getData({
    search,
    currentPage,
    limit,
  }: {
    search: ISearchVolunteer;
    currentPage: number;
    limit: number;
  }): Promise<void>;
  currentPage: number;
  search: ISearchVolunteer;
  eventId: string;
  event: IEvent | null;
}

const Listing = ({
  volunteers,
  data,
  currentPage,
  setCurrentPage,
  loading,
  limit,
  setLimit,
  getData,
  search,
  eventId,
  event,
}: ITable) => {
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [downloading, setDownloading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [openDrawer, setOpenDrawer] = React.useState({
    userInformation: false,
  });
  const [menuPosition, setMenuPosition] = React.useState<any>(null);
  const [itemClicked, setItemClicked] = React.useState<IVolunteer>();
  const [disableSearchPage, setDisableSearchPage] = React.useState(false);
  const [searchPage, setSearchPage] = React.useState('');
  const [idsSelected, setIdsSelected] = React.useState<IVolunteer[]>();
  const [openConfirmActionModal, setOpenConfirmActionModal] = React.useState(false);
  const [actionSelected, setActionSelected] = React.useState('');
  const [isActionSuccess, setActionSuccess] = React.useState(false);
  const [isActionFailed, setActionFailed] = React.useState(false);
  const [messageResponse, setMessageResponse] = React.useState('');
  const [openSendEmailModal, setOpenSendEmailModal] = React.useState(false);
  const [openUpdateVolunteerModal, setOpenUpdateVolunteerModal] = React.useState(false);
  const [template, setTemplate] = React.useState('none');
  const [itemSelected, setItemSelected] = React.useState<IVolunteer[]>([]);
  const [updateVolunteerInfo, setUpdateVolunteerInfo] = React.useState<
    | {
        scanTicket: string;
        attendanceStatus: string;
        checkinTime: null | string | Date;
        checkoutTime: null | string | Date;
      }
    | undefined
  >();
  const [openRemoveModal, setOpenRemoveModal] = React.useState(false);
  const [reasonRemove, setReasonRemove] = React.useState('');
  const [method, setMethod] = React.useState<string>('auto');
  const [volunteerWaitList, setVolunteerWaitList] = React.useState<IVolunteer[]>([]);
  const [waitedList, setWaitedList] = React.useState<IVolunteer[]>([]);
  const [openNoWaitedListModal, setOpenNoWaitedListModal] = React.useState(false);
  const [openDontEnoughWaitedListModal, setOpenDontEnoughWaitedListModal] = React.useState(false);

  useEffect(() => {
    async function getWaitedList() {
      const response = await getVolunteerByEventId({
        eventId: eventId,
        type: 'waitlist',
        currentPage: 1,
        limit: 10,
      });
      if (response?.statusCode === CALL_SUCCESS) {
        setWaitedList(response?.data?.items);
      }
    }
    getWaitedList();
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuPosition(null);
  };

  const handleSendEmailModal = () => {
    setOpenSendEmailModal(!openSendEmailModal);
    setTemplate('none');
    setItemSelected([]);
  };

  const handleUpdateVolunteerModal = () => {
    setOpenUpdateVolunteerModal(!openUpdateVolunteerModal);
    setTemplate('none');
    setItemSelected([]);
  };

  const handleRemoveVolunteerModal = () => {
    setOpenRemoveModal(!openRemoveModal);
    setItemSelected([]);
    setReasonRemove('');
  };

  const toggleDrawer = (name: string, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    setOpenDrawer({ ...openDrawer, [name]: open });
  };

  const _onClickUserName = (user: IVolunteer) => {
    setOpenDrawer({ ...openDrawer, userInformation: true });
    setItemClicked(user);
  };

  const onSelectedItems = (ids: IVolunteer[]) => {
    setIdsSelected(ids);
  };

  const handleChangePagination = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || e.target.value === '0') {
      setSearchPage('');
      return;
    }
    if (e.target.name === 'searchPage') {
      if (data?.totalPages) {
        if (!intRegex.test(e.target.value)) {
          setDisableSearchPage(true);
        } else {
          if (Number(e.target.value) > data.totalPages) {
            setDisableSearchPage(true);
          } else {
            setDisableSearchPage(false);
            setSearchPage(e.target.value);
          }
        }
      } else {
        setDisableSearchPage(true);
      }
    }
    if (e.target.name === 'records/page') {
      setLimit(Number(e.target.value));
      setSearchPage('');
      setCurrentPage(1);
    }
  };

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setSearchPage(valueTrimmed);
  };

  const _onSubmitSendMail = async () => {
    if (actionSelected === 'remove-volunteer') {
      if (itemSelected && idsSelected) {
        const data = itemSelected.length > 0 ? itemSelected : idsSelected;

        let idsToRemove: string[] = [];
        let idsToAdd: string[] = [];

        data.map((item) => {
          return idsToRemove.push(item.id);
        });

        volunteerWaitList?.map((item) => {
          return idsToAdd.push(item.id);
        });

        let response;

        if (method === 'auto') {
          if (!waitedList.length && !openNoWaitedListModal) {
            setOpenNoWaitedListModal(true);
            return;
          }
          response = await removeVolunteerConfirmedList(eventId, {
            idsToRemove,
            reason: reasonRemove,
            method,
          });
        } else {
          if (waitedList.length <= idsToRemove.length) {
            idsToAdd = [];
            waitedList.map((item) => idsToAdd.push(item.id));
          }
          response = await removeVolunteerConfirmedList(eventId, {
            idsToRemove,
            reason: reasonRemove,
            method,
            idsToAdd,
          });
        }

        if (response) {
          if (response.statusCode === CALL_SUCCESS) {
            getData({ search, currentPage, limit });
            setActionSuccess(true);
            setMessageResponse(response.message);
          } else {
            setActionFailed(true);
            setMessageResponse(response.message);
          }
          setLoadingAction(false);
          handleRemoveVolunteerModal();
          handleClose();
          _onCloseModalConfirmAction();
          setOpenNoWaitedListModal(false);
          setOpenDontEnoughWaitedListModal(false);
          return;
        }
      }
      return;
    }
    setOpenConfirmActionModal(true);
  };

  const _onSelectAction = (action: string) => () => {
    if (action === 'Send Email') {
      setActionSelected(action);
      handleSendEmailModal();
      handleClose();
      return;
    }
    if (action === 'update-volunteer') {
      setActionSelected(action);
      handleUpdateVolunteerModal();
      handleClose();
      return;
    }

    if (action === 'remove-volunteer') {
      setActionSelected(action);
      handleRemoveVolunteerModal();
      handleClose();
      return;
    }

    setOpenConfirmActionModal(true);
    setActionSelected(action);
  };

  const _onCloseModalConfirmAction = () => {
    setOpenConfirmActionModal(false);
  };

  const _onSelectedAction = async () => {
    setLoadingAction(true);
    if (actionSelected === 'Send Email') {
      if (itemSelected && idsSelected) {
        const data = itemSelected.length > 0 ? itemSelected : idsSelected;

        let emails: string[] = [];

        data.map((item) => {
          return emails.push(item.user.emailAddress);
        });
        const response = await sendEmail({
          emails,
          templateId: template,
        });
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleSendEmailModal();
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }

    if (actionSelected === 'update-volunteer') {
      if (itemSelected) {
        const response = await updateVolunteer(itemSelected[0].id, updateVolunteerInfo);
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleUpdateVolunteerModal();
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
    if (actionSelected === 'allow-access') {
      if (idsSelected) {
        let ids: string[] = [];

        idsSelected.map((item) => {
          return ids.push(item.user.id);
        });
        const response = await assignETicket(eventId, {
          ids: ids,
          action: 'set',
        });
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
    if (actionSelected === 'remove-access') {
      if (idsSelected) {
        let ids: string[] = [];

        idsSelected.map((item) => {
          return ids.push(item.user.id);
        });
        const response = await assignETicket(eventId, {
          ids: ids,
          action: 'remove',
        });
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        handleClose();
        _onCloseModalConfirmAction();
        return;
      }
    }
  };

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const onClickChangePage = () => {
    setCurrentPage(Number(searchPage));
  };

  const exportCSV = async () => {
    setDownloading(true);
    setLoadingAction(true);

    const response = await exportConfirmListCSV({ search, currentPage, limit, event });

    if (response) {
      setDownloading(false);
      setLoadingAction(false);
    }
  };

  const columns = React.useMemo(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'NSAAID',
        dataIndex: 'NSAAID',
        title: 'NSAA ID',
        width: 50,
      },
      {
        key: 'Name',
        dataIndex: 'Name',
        title: 'Name',
        width: 120,
        render: (value, record) => {
          return (
            <Link onClick={_onClickUserName.bind('', record.id)} underline="none">
              <LabelStyled>
                {(record.id?.user?.surname + ', ' + record.id?.user?.givenName).length > 10
                  ? (record.id?.user?.surname + ', ' + record.id?.user?.givenName).slice(0, 10) + '...'
                  : record.id?.user?.surname + ', ' + record.id?.user?.givenName}
                {record.id?.user?.verificationStatus === 'verified' && <IconVerifyStyled src={IconSuccess} />}
              </LabelStyled>
            </Link>
          );
        },
      },
      {
        key: 'EmailAddress',
        dataIndex: 'EmailAddress',
        title: 'Email Address',
        width: 130,
        ellipsis: true,
      },
      {
        key: 'PhoneNumber',
        dataIndex: 'PhoneNumber',
        title: 'Phone Number',
        width: 100,
      },
      {
        key: 'Status',
        dataIndex: 'Status',
        title: 'Status',
        width: 100,
        render: (value, record) => {
          let variants: 'pending' | 'incomplete' | 'complete' | 'confirmed' | undefined =
            value === 'pending'
              ? 'pending'
              : value === 'incomplete'
              ? 'incomplete'
              : value === 'complete'
              ? 'complete'
              : value === 'confirmed'
              ? 'confirmed'
              : undefined;
          return <ButtonStatus variants={variants}>{value}</ButtonStatus>;
        },
      },
      {
        key: 'RegistrationTime',
        dataIndex: 'RegistrationTime',
        title: 'Registration Time',
        width: 180,
      },
      {
        key: 'AccessETicket',
        dataIndex: 'AccessETicket',
        title: 'Access eTicket checking',
        width: 130,
      },
      {
        key: 'Actions',
        title: '',
        dataIndex: ' Actions',
        width: 130,
        render: (value, record) => {
          return (
            <Actions>
              <Tooltip title={'Send EMail'}>
                <IconButtonStyled
                  onClick={() => {
                    setItemSelected([record.id]);
                    setActionSelected('Send Email');
                    setOpenSendEmailModal(true);
                  }}
                >
                  <ImageIconStyled src={SendMailIcon} />
                </IconButtonStyled>
              </Tooltip>
              <Tooltip title={'Edit'}>
                <IconButtonStyled
                  onClick={() => {
                    setItemSelected([record.id]);
                    setActionSelected('update-volunteer');
                    setOpenUpdateVolunteerModal(true);
                  }}
                >
                  <ImageIconStyled src={EditIcon} />
                </IconButtonStyled>
              </Tooltip>

              <Tooltip title="Remove Volunteer">
                <IconButtonStyled
                  onClick={() => {
                    setItemSelected([record.id]);
                    setActionSelected('remove-volunteer');
                    setOpenRemoveModal(true);
                  }}
                >
                  <ImageIconStyled src={DeleteIcon} />
                </IconButtonStyled>
              </Tooltip>
            </Actions>
          );
        },
      },
    ];
    return columns;
  }, []);

  const dataSource = React.useMemo(() => {
    let dataSource: any[] = [];
    volunteers.forEach((item, idx) => {
      if (item?.userId && item?.user !== null) {
        dataSource.push({
          key: idx,
          id: item,
          NSAAID: item.user?.username,
          Name: item?.user?.surname,
          EmailAddress:
            item.user?.emailAddress.length > 13
              ? item.user?.emailAddress.slice(0, 13) + '...'
              : item.user?.emailAddress,
          PhoneNumber:
            item.user?.areaCode +
            (item.user?.mobilePhone.length > 6 ? item.user?.mobilePhone.slice(0, 6) + '...' : item.user?.mobilePhone),
          Status: item?.attendanceStatus,
          RegistrationTime: item.registrationTime ? moment(+item.registrationTime).format(FORMAT_TIME_AM_PM) : '',
          AccessETicket: convertSlugToUpperCase(item.scanTicket),
        });
      }
    });

    return dataSource as unknown as INSAADataSource[];
  }, [volunteers]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="left">
        <Title>{shallowEqual(search, defaultSearchVolunteer) ? `Listing` : `Results`}</Title>
        <Box>
          <CustomButtonStyled outlineButton onClick={exportCSV} disabled={downloading || loadingAction || loading}>
            {downloading ? <Loading /> : `Export CSV`}
          </CustomButtonStyled>
          <CustomButtonStyled disabled={idsSelected && idsSelected.length === 0} outlineButton onClick={handleClick}>
            Action
            <KeyboardArrowDownIconStyled />
          </CustomButtonStyled>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {actionsFlag?.map((action) => {
              return (
                <MenuItemStyled onClick={_onSelectAction(action.key)} key={action.key} value={action.key}>
                  {action.value}
                </MenuItemStyled>
              );
            })}
          </Popover>
        </Box>
      </Box>
      <Box mt={2} mb={2}>
        <NSAATable
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          isShowCheckBoxes
          onSelectedItems={onSelectedItems}
        />
      </Box>
      <CustomPagination
        itemName={'volunteer'}
        data={data}
        limit={limit}
        handleChangePagination={handleChangePagination}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onClickChangePage={onClickChangePage}
        disableSearchPage={disableSearchPage || searchPage === ''}
        searchPage={searchPage}
        onBlurField={onBlurField}
      />
      {openDrawer.userInformation && (
        <RightDrawer userClicked={itemClicked} open={openDrawer.userInformation} toggleDrawer={toggleDrawer} />
      )}
      {openSendEmailModal && (
        <SendEmailModal
          loading={loading}
          open={openSendEmailModal}
          _onCloseModal={handleSendEmailModal}
          _onSubmit={_onSubmitSendMail}
          userSelected={itemSelected.length > 0 ? itemSelected : idsSelected}
          template={template}
          setTemplate={setTemplate}
        />
      )}
      {openUpdateVolunteerModal && (
        <UpdateVolunteerModal
          event={event}
          loading={loading}
          open={openUpdateVolunteerModal}
          _onCloseModal={handleUpdateVolunteerModal}
          _onSubmit={_onSubmitSendMail}
          userSelected={itemSelected[0]}
          setUpdateVolunteerInfo={setUpdateVolunteerInfo}
        />
      )}
      {openRemoveModal && (
        <RemoveVolunteerModal
          loading={loading}
          open={openRemoveModal}
          _onCloseModal={handleRemoveVolunteerModal}
          _onSubmit={_onSubmitSendMail}
          userSelected={itemSelected.length > 0 ? itemSelected : idsSelected}
          reasonRemove={reasonRemove}
          setReasonRemove={setReasonRemove}
          method={method}
          setMethod={setMethod}
          eventId={eventId}
          setVolunteerWaitList={setVolunteerWaitList}
          volunteerWaitList={volunteerWaitList}
          waitedList={waitedList}
          openNoWaitedListModal={openNoWaitedListModal}
          setOpenNoWaitedListModal={setOpenNoWaitedListModal}
          setOpenDontEnoughWaitedListModal={setOpenDontEnoughWaitedListModal}
        />
      )}
      {openConfirmActionModal && (
        <ConfirmModal
          open={openConfirmActionModal}
          handleClose={_onCloseModalConfirmAction}
          onSubmit={_onSelectedAction}
          actionSelected={actionSelected}
          disabled={loadingAction}
        />
      )}
      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
      {openNoWaitedListModal && (
        <Popup
          open={openNoWaitedListModal}
          onClose={() => setOpenNoWaitedListModal(false)}
          onSubmit={_onSubmitSendMail}
          title="Notification"
          content="There is no volunteer on the current waitlist. New register will be added later."
        />
      )}
      {openDontEnoughWaitedListModal && (
        <Popup
          open={openDontEnoughWaitedListModal}
          onClose={() => setOpenDontEnoughWaitedListModal(false)}
          onSubmit={_onSubmitSendMail}
          title="Notification"
          content={`Number of volunteers on waitlist is equal or less than number of removed volunteers.
          System will auto add all volunteers from the waitlist to the confirmed list`}
        />
      )}
    </Box>
  );
};

export default Listing;
