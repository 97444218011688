import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { IResponsive } from '../home/homepageStyled';

export const menus = [
  'Homepage',
  'Create News',
  'About NSAA',
  'Committee',
  'Constitution',
  'Events & Activities',
  'Donation',
  'Contact Us',
];

export const CMSContainer = styled(Box)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    ${responsive?.isTabletOrMobile &&
    css`
      width: 1440px;
    `}
    ${responsive?.isDesktopOrLaptop &&
    css`
      width: 100%;
    `}
  `}
`;
