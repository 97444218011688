import React from 'react';

import { Box, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

import EventDetailBackground from 'src/medias/images/event-detail-background.png';
import { IResponsive } from 'src/pages/home/homepageStyled';

interface IBannerProps {
  image: string;
  title: string;
  description: string;
  isSmallTitle?: boolean;
  bannerDescWidth?: string;
  responsive?: IResponsive;
  isNews?: boolean;
}

const Banner = ({
  image,
  title,
  description,
  isSmallTitle = false,
  bannerDescWidth,
  responsive,
  isNews,
}: IBannerProps) => {
  return (
    <BoxStyled>
      {responsive?.isDesktopOrLaptop && <PlaceholderBackground src={EventDetailBackground} />}
      <BannerContainer image={image} responsive={responsive} title={title}>
        <BannerContent>
          <BannerTitle isSmallTitle={isSmallTitle} responsive={responsive}>
            {/* {title} */}
          </BannerTitle>
          <BannerDescription width={bannerDescWidth}>{''}</BannerDescription>
        </BannerContent>
      </BannerContainer>
    </BoxStyled>
  );
};

const BannerContainer = styled(Box)<{ image: string; responsive?: IResponsive; title?: string }>`
  box-sizing: border-box;
  ${({ theme, image, responsive, title }) => css`
    width: auto;
    height: ${responsive?.isMobile ? '200px' : responsive?.isTablet ? '280px' : '350px'};
    background-image: url(${image});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
    padding-left: ${responsive?.isMobile ? '16px' : responsive?.isTablet ? '36px' : '190px'};
    padding-top: ${responsive?.isMobile ? '32px' : 0};
    display: flex;
    align-items: ${responsive?.isMobile ? 'flex-start' : 'center'};
    position: relative;
    overflow: hidden;
    ${!Boolean(title) &&
    css`
      padding: 0;
    `}
    ${responsive?.isTabletOrMobile &&
    css`
      background-size: cover;
    `}
  `}
`;

const BannerTitle = styled(Typography)<{ isSmallTitle: boolean; responsive?: IResponsive }>`
  font-size: ${({ isSmallTitle, responsive }) => (responsive?.isTabletOrMobile ? 24 : isSmallTitle ? 34 : 48)}px;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  line-height: ${({ responsive }) => (responsive?.isTabletOrMobile ? 1.5 : `px`)};
  margin-bottom: ${({ responsive }) => (responsive?.isTabletOrMobile ? 8 : 24)}px;
`;
const BannerDescription = styled(Typography)<{ width?: string }>`
  ${({ theme, width }) => css`
    width: ${width ? width : '375px'};
    font-size: ${theme.fonts.header6.fontSize};
    line-height: ${theme.fonts.header6.lineHeight};
    color: white;
  `}
`;
export const BannerContent = styled(Box)`
  max-width: 1440px;
  width: 100%;
  margin: auto;
`;

const PlaceholderBackground = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
`;

const BoxStyled = styled(Box)<{ responsive?: IResponsive }>`
  box-sizing: border-box;
  ${({ theme, responsive }) => css`
    width: 100%;
    position: relative;
    ${responsive?.isTabletOrMobile &&
    css`
      height: auto;
    `}
  `}
`;

export default Banner;
