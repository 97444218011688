import React, { useMemo } from 'react';

import { Box, Typography } from '@material-ui/core';
import {
  createAlignPlugin,
  createBoldPlugin,
  createDndPlugin,
  createExitBreakPlugin,
  createFontBackgroundColorPlugin,
  createFontColorPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createHistoryPlugin,
  createImagePlugin,
  createItalicPlugin,
  createKbdPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createReactPlugin,
  createSelectOnBackspacePlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  ELEMENT_IMAGE,
  Plate,
  PlatePlugin,
  SPEditor,
} from '@udecode/plate';
import moment from 'moment';
import { useHistory } from 'react-router';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';

import ShareToButton from 'src/components/button/ShareToButton';
import { editablePreviewProps, optionsExitBreakPlugin } from 'src/components/slateEditor/config/pluginOptions';
import { FORMAT_DATE_YMD } from 'src/constants/common';
import YellowCalendar from 'src/medias/icons/yellow-calendar.png';
import FBLogo from 'src/medias/images/facebook-logo.png';
import TwitterLogo from 'src/medias/images/twitter-logo.png';
import { components } from 'src/pages/admin/components/Preview';
import { IEvent } from 'src/services/events';
import useResponsive from 'src/utils/responsive';

interface IEventDetail {
  event: IEvent;
}

const Event = styled(Box)`
  flex: 1;
`;

const EventShare = styled(Box)`
  ${({ theme }) => css`
    color: #cfcfcf;
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing(3)}px;
    margin-bottom: ${theme.spacing(3)}px;
  `}
`;
export const ShareIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 9px;
  border-radius: 5px;
`;
const EventContent = styled(Box)`
  ${({ theme }) => css`
    color: ${theme.palette.gray?.[650]};
    line-height: 27px;
    text-align: justify;
  `}
`;
const EventDetailContentContainer = styled(Box)`
  display: flex;
  gap: 130px;
`;
const EventInterestedHeader = styled(Typography)`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-size: ${theme.fonts.header3.fontSize};
    font-weight: ${theme.fonts.header3.fontWeight};
    margin-bottom: ${theme.spacing(3)}px;
  `}
`;

type TEditor = SPEditor & ReactEditor & HistoryEditor;

function EventDetailContent({ event }: IEventDetail) {
  const history = useHistory();
  const responsive = useResponsive();

  const pluginsMemo: PlatePlugin<TEditor>[] = useMemo(() => {
    const plugins = [
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createMediaEmbedPlugin(),
      createAlignPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createDndPlugin(),
      createExitBreakPlugin(optionsExitBreakPlugin),
      createSelectOnBackspacePlugin({
        allow: [ELEMENT_IMAGE],
      }),
    ];

    return plugins;
  }, []);

  if (!event) return <div />;

  return responsive.isTabletOrMobile ? (
    <Box maxWidth={846} mx="auto" my={2}>
      <Box>
        <Box display="flex" justifyContent="space-between" mt={2} pl={2} pr={2} alignItems="center">
          <Box display="flex" alignItems="center" style={{ gap: 10 }}>
            <img src={YellowCalendar} alt="yellow calendar" />
            <DateTime>{moment.utc(event?.updatedAt).format(FORMAT_DATE_YMD)}</DateTime>
          </Box>
          <NewsShare>
            <Box mr={1}>Share on</Box>
            <ShareToButton children={<Logo src={FBLogo} />} url={history.location.pathname} facebook />
            <ShareToButton children={<Logo src={TwitterLogo} />} url={history.location.pathname} twitter />
          </NewsShare>
        </Box>
        <Title responsive={responsive}>{event?.title || ''}</Title>
      </Box>
      <Box pl={2} pr={2}>
        <Plate
          id={'NEWS_DETAIL'}
          plugins={pluginsMemo}
          components={components}
          editableProps={editablePreviewProps}
          value={event.description}
        />
      </Box>
    </Box>
  ) : (
    <Box maxWidth={846} mx="auto">
      <EventDetailContentContainer py={10} pt={14}>
        <Event>
          <EventInterestedHeader>DESCRIPTION</EventInterestedHeader>
          <EventContent>
            <Box pl={2} pr={2}>
              <Plate
                id={'EVENT_DETAIL'}
                plugins={pluginsMemo}
                components={components}
                editableProps={editablePreviewProps}
                value={event.description}
              />
            </Box>
          </EventContent>
          <EventShare>
            <Box mr={1}>Share on</Box>
            <ShareToButton children={<ShareIcon src={FBLogo} />} url={history.location.pathname} facebook />
            <ShareToButton children={<ShareIcon src={TwitterLogo} />} url={history.location.pathname} twitter />
          </EventShare>
        </Event>
      </EventDetailContentContainer>
    </Box>
  );
}

export default EventDetailContent;

const DateTime = styled(Box)`
  ${({ theme }) => css`
    color: ${theme.palette.yellow?.[900]};
  `}
`;
const Title = styled(Typography)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    margin: ${theme.spacing(3)}px 0;
    font-size: ${responsive?.isTabletOrMobile ? '20px' : '45px'};
    font-weight: 700;
    padding: ${responsive?.isTabletOrMobile ? '0px 16px' : '0'};
  `}
`;
const Logo = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 3px;
`;

const NewsShare = styled(Box)`
  ${({ theme }) => css`
    color: #cfcfcf;
    display: flex;
    align-items: center;
  `}
`;
