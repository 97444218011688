import React from 'react';

import { Html5Qrcode } from 'html5-qrcode';

const Scanner = (props: { start: any; success: any; errors: any }) => {
  const { start, success, errors } = props;

  React.useEffect(() => {
    let html5QrCode: Html5Qrcode;
    if (start) {
      Html5Qrcode.getCameras()
        .then((devices) => {
          if (devices && devices.length) {
            html5QrCode = new Html5Qrcode('qrcode-scanner');
            html5QrCode.start(
              { facingMode: 'environment' },
              {
                fps: 10,
                qrbox: 250,
              },
              success,
              errors,
            );
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }

    return () => {
      if (html5QrCode) {
        html5QrCode.stop();
      }
    };
  }, [start]);

  return <div id="qrcode-scanner" style={{ width: 383 }} />;
};

export default Scanner;
