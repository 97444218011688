import React, { useEffect, useState } from 'react';

import { Box, Grid, IconButton, InputAdornment, Link, makeStyles, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import _ from 'lodash';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import { CustomButton, InputField } from 'src/components';
import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import { CALL_SUCCESS } from 'src/constants/common';
import { RESET_PASSWORD } from 'src/constants/users.constants';
import LogoJSA from 'src/medias/images/main-nsaa-logo.png';
import SignUpBackground from 'src/medias/images/signup-background.jpg';
import { checkTokenResetPass, resetPassword } from 'src/services/auth';
import {
  containsNumericCharacters,
  containsSpecialCharacters,
  containsUpperAndLowerCaseRegexp,
  isSpace,
} from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { Form } from '..';
import ResetPasswordSuccess from '../ResetPasswordSuccess';

import ResetPasswordMobile from './ResetPasswordMobile';

const ERROR_PASSWORD_MESSAGE = `Password must have at least eight characters. The password must contain at least four character categories among the following: uppercase characters (A-Z), lowercase characters (a-z), a number (0-9), and special characters (~!@#$%)`;
const ERROR_VERIFY_MESSAGE = `Passwords do not match`;
const ERROR_SAME_PASSWORD_MESSAGE = `New password must be different from your last 6 passwords.`;
const MAXIMUM_PASSWORD_LENGTH = 32;
const MINIMUM_PASSWORD_LENGTH = 8;
const Title = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header4S,
  color: theme.palette.yellow?.[900],
}));
const TextBox = styled(Box)`
  ${({ theme }) => css`
    margin: 10px 0 32px 0;
    p {
      margin: 0;
      font-size: ${theme.typography.h6.fontSize};
      font-weight: ${theme.typography.h6.fontWeight};
      line-height: ${theme.typography.h6.lineHeight};
      color: ${theme.palette.common.black};
      span {
        color: ${theme.palette.yellow?.[900]};
      }
    }
  `}
`;
const SubmitButton = styled(CustomButton)<{ disable?: boolean }>`
  ${({ disable }) => css`
    && {
      pointer-events: ${disable ? 'none' : 'all'};
    }
  `}
`;
const ErrorMess = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textS,
  color: 'red',
  marginTop: '10px',
}));
export const NYSImage = styled.img<{ objectFitContain?: boolean }>(({ theme, objectFitContain }) => ({
  width: '100%',
  minHeight: '100vh',
  ...(objectFitContain
    ? {
        objectFit: 'contain',
      }
    : {}),
}));
const Logo = styled.img(({ theme }) => ({
  position: 'absolute',
  top: 54,
  left: 80,
  height: 90,
  width: 70,
}));
export const BoxPosition = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '20%',
  top: '40%',
}));
export const TitleBoxPosition = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
  color: 'white',
  fontSize: 22,
}));
export const DesBoxPosition = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textS,
  color: 'white',
  textAlign: 'center',
  marginTop: 16,
  lineHeight: 2,
}));
const useStyles = makeStyles((theme) => ({
  left: {
    minHeight: 966,
    position: 'relative',
  },
}));
const InputFieldStyled = styled(InputField)`
  ${({ theme }) => css`
    & p {
      color: ${theme.palette.common.white};
    }
  `}
`;
function ResetPassword() {
  const classes = useStyles();
  const responsive = useResponsive();
  const history = useHistory();
  const token = history.location.search.slice(7);
  const userId = history.location.pathname.slice(16);
  const [data, setData] = useState<{ newPassword: string; username: string; token: string }>({
    newPassword: '',
    username: userId,
    token,
  });
  const [verifyPassword, setVerifyPassword] = useState<string>('');
  const [errors, setErrors] = useState<{ newPassword: boolean; verifyPassword: boolean }>({
    newPassword: false,
    verifyPassword: false,
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showVerify, setShowVerify] = useState<boolean>(false);
  const [isResetPassword, setIsResetPassword] = useState<boolean>(false);
  const [onClicking, setOnClicking] = useState<boolean>(false);
  const [valid, setValid] = useState<boolean>(true);
  const [checkToken, setCheckToken] = useState<any>();

  useEffect(() => {
    const checkToken = async () => {
      const response = await checkTokenResetPass(token);
      setCheckToken(response);
    };
    checkToken();
  }, []);
  if (checkToken && checkToken.statusCode !== CALL_SUCCESS) {
    return <ResetPasswordSuccess message={RESET_PASSWORD.tokenExpired} />;
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    setOnClicking(true);
    setErrors({ newPassword: false, verifyPassword: false });
    e.preventDefault();
    const passwordLongEnough = _.size(data.newPassword) >= MINIMUM_PASSWORD_LENGTH;
    const isPasswordValid =
      passwordLongEnough &&
      containsUpperAndLowerCaseRegexp.test(data.newPassword) &&
      containsSpecialCharacters.test(data.newPassword) &&
      !isSpace(data.newPassword) &&
      containsNumericCharacters.test(data.newPassword);

    setErrors({ ...errors, newPassword: !isPasswordValid });
    if (verifyPassword !== data.newPassword) {
      setErrors({ ...errors, verifyPassword: true });
      setOnClicking(false);
      return;
    }
    const response = await resetPassword(data);
    if (response?.statusCode === CALL_SUCCESS) {
      setIsResetPassword(true);
      setOnClicking(false);
    } else {
      if (response?.message === 'New password must be different from your last 6 passwords.') {
        setValid(false);
        setOnClicking(false);
      }
    }
    setOnClicking(false);
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setErrors({ newPassword: false, verifyPassword: false });
    setValid(true);
    if (e.target.name === 'verify') {
      setVerifyPassword(e.target.value);
    } else {
      setData({ ...data, newPassword: e.target.value });
    }
  }
  function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
  }
  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }
  function handleClickShowVerify() {
    setShowVerify(!showVerify);
  }

  if (!isResetPassword)
    return responsive.isTabletOrMobile ? (
      <ResetPasswordMobile />
    ) : (
      <Grid container>
        <LoadingCustom loading={onClicking} loadingIcon />
        <Grid item xs={6} className={classes.left}>
          <Box display="flex" width={'100%'} height={'100%'}>
            <NYSImage src={SignUpBackground} objectFitContain />
            <Link href="/">
              <Logo src={LogoJSA} />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            width={'100%'}
            height={'100%'}
            justifyContent="center"
            display="flex"
            alignItems="center"
            bgcolor="#f9f9f9"
          >
            <Form onSubmit={handleSubmit}>
              <Title>Reset Password</Title>
              <TextBox>
                <p>
                  Hi Nanyang (NSAAID: <span>{userId}</span>)
                </p>
                <p>Let’s start reset your password!</p>
              </TextBox>
              <InputFieldStyled
                customBoxTitle={{ marginBottom: '10px' }}
                title="Enter your new password"
                hiddenLabel
                fullWidth
                type={showPassword ? 'text' : 'password'}
                name="password"
                onChange={handleChange}
                notice
                noticeYellow
                noticeTitle={<Typography color="inherit">{ERROR_PASSWORD_MESSAGE}</Typography>}
                autoComplete="off"
                InputProps={{
                  inputProps: {
                    maxLength: MAXIMUM_PASSWORD_LENGTH,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.newPassword}
                errormessage={errors.newPassword && ERROR_PASSWORD_MESSAGE}
              />
              {!valid && <ErrorMess>{ERROR_SAME_PASSWORD_MESSAGE}</ErrorMess>}
              <Box mt={2} />
              <InputFieldStyled
                customBoxTitle={{ marginBottom: '10px' }}
                title="Verify your password"
                hiddenLabel
                fullWidth
                notice
                noticeYellow
                noticeTitle={<Typography color="inherit">{ERROR_PASSWORD_MESSAGE}</Typography>}
                type={showVerify ? 'text' : 'password'}
                name="verify"
                onChange={handleChange}
                autoComplete="off"
                InputProps={{
                  inputProps: {
                    maxLength: MAXIMUM_PASSWORD_LENGTH,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowVerify} onMouseDown={handleMouseDownPassword} edge="end">
                        {showVerify ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.verifyPassword}
                errormessage={errors.verifyPassword && ERROR_VERIFY_MESSAGE}
              />

              <Box mb={4} mt={4} display="flex" justifyContent="center">
                <SubmitButton type="submit" disable={onClicking}>
                  SUBMIT
                </SubmitButton>
              </Box>
            </Form>
          </Box>
        </Grid>
      </Grid>
    );
  return <ResetPasswordSuccess message={RESET_PASSWORD.resetPassword} />;
}

export default ResetPassword;
