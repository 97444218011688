import React from 'react';

import { Box, Checkbox, RadioGroup } from '@material-ui/core';
import { FormikProps } from 'formik';

import { NASSRadio } from 'src/components';
import { ErrorMessage, FormTextField, Label } from 'src/pages/profile/components/general-information.styled';
import { IEvent } from 'src/services/events';
import { customLabels, getAreaKey, getAreaCode } from 'src/utils/areaCode';
import { isInputNumber } from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { ReactFlagsSelectStyled } from '../signup/components/types';
import { FormControlLabelStyled } from '../user-management/shared/user-detail.account-settings';

interface Props {
  formik: FormikProps<any>;
  event: IEvent;
  isLoggedIn?: boolean;
  isAdminForm?: boolean;
}

const FillForm = ({ formik, event, isLoggedIn, isAdminForm }: Props) => {
  const responsive = useResponsive();
  const INPUT_MAX_LENGTH_128 = 128;
  const INPUT_MAX_LENGTH_50 = 50;
  const INPUT_MAX_LENGTH_15 = 15;
  const INPUT_YEAR_LENGTH = 4;
  const NO = 'no';
  const THIS_FIELD_IS_REQUIRED = 'This field is required';
  const EMPTY = '';
  const MAX_NUMBER_PER_PURCHASE = event?.max_number_per_purchase;
  const MAX_GUEST = event?.max_guest;
  const TEN_NUMBER = 10;
  const IS_NOT_INCLUDE_MEAL = !formik.values.include_meal;

  const TOTAL_TICKETS = IS_NOT_INCLUDE_MEAL
    ? formik.values.totalTicket
    : +formik.values.ticketForStandardMeal + +formik.values.ticketForVegetarian + +formik.values.ticketForHalal;

  const TICKET_FOR_STANDARD_MEAL = +formik.values.ticketForStandardMeal;
  const TICKET_FOR_VEGETARIAN = +formik.values.ticketForVegetarian;
  const TICKET_FOR_HALAL = +formik.values.ticketForHalal;

  const IS_MULTIPLE_10 = TOTAL_TICKETS % TEN_NUMBER === 0;
  const IS_MULTIPLE_10_STANDARD_MEAL = TICKET_FOR_STANDARD_MEAL % TEN_NUMBER === 0;
  const IS_MULTIPLE_10_VEGETARIAN = TICKET_FOR_VEGETARIAN % TEN_NUMBER === 0;
  const IS_MULTIPLE_10_HALAL = TICKET_FOR_HALAL % TEN_NUMBER === 0;

  const ATTENDED_AGE_MUST_LEST_THAN_TOTAL_TICKETS = 'Number of attended age ticket must be less than number of tickets';
  const NUMBER_OF_TICKETS_MUST_BE_A_MULTIPLE_10 = 'Number of tickets must be a multiple of 10';
  const TOTAL_TICKET_EXCEEDS_ALLOW_MAXIMUM = 'Total number of ticket exceeds the allowed maximum number.';
  const MAX_NUMBER_OF_GUEST_MESSAGE = 'Total number of ticket exceeds the allowed maximum number of guest.';
  const TICKET_TYPE_2 = 2;

  const SHOW_TICKET_FOR_STANDARD_MEAL =
    formik.touched.ticketForStandardMeal && formik.errors.ticketForStandardMeal
      ? formik.errors.ticketForStandardMeal
      : !IS_MULTIPLE_10_STANDARD_MEAL
      ? NUMBER_OF_TICKETS_MUST_BE_A_MULTIPLE_10
      : EMPTY;
  const SHOW_TICKET_FOR_VEGETARIAN =
    formik.touched.ticketForVegetarian && formik.errors.ticketForVegetarian
      ? formik.errors.ticketForVegetarian
      : !IS_MULTIPLE_10_VEGETARIAN
      ? NUMBER_OF_TICKETS_MUST_BE_A_MULTIPLE_10
      : EMPTY;
  const SHOW_TICKET_FOR_HALAL =
    formik.touched.ticketForHalal && formik.errors.ticketForHalal
      ? formik.errors.ticketForHalal
      : !IS_MULTIPLE_10_HALAL
      ? NUMBER_OF_TICKETS_MUST_BE_A_MULTIPLE_10
      : EMPTY;
  const SHOW_TICKET_FOR_TOTAL_TICKET =
    formik.touched.totalTicket && !!formik.errors.totalTicket
      ? formik.errors.totalTicket
      : !IS_MULTIPLE_10
      ? NUMBER_OF_TICKETS_MUST_BE_A_MULTIPLE_10
      : TOTAL_TICKETS > MAX_NUMBER_PER_PURCHASE
      ? TOTAL_TICKET_EXCEEDS_ALLOW_MAXIMUM
      : EMPTY;

  function handleGraduateChange(fieldName: string, resetField: string) {
    return function (_: any, value: boolean) {
      formik.setFieldValue(fieldName, value);
      formik.setFieldValue(resetField, EMPTY);
    };
  }

  const handleInputChange = (name: string) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.target.value.includes('+') || e.target.value.includes('-') || isNaN(e.target.value as any)) return;
      formik.setFieldValue(name, e.target.value);
    };
  };

  const handleInputNumberChange =
    (name: string) => async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!isInputNumber(e.target.value)) return;
      formik.setFieldValue(name, e.target.value);
    };

  const handleTicketChange = (name: string) => {
    return (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (e.target.value.includes('+') || e.target.value.includes('-') || isNaN(e.target.value as any)) return;
      formik.setFieldValue(name, e.target.value);
      formik.setFieldValue('totalTicket', TOTAL_TICKETS);
    };
  };

  const setEmptyChildAttended = () => {
    formik.setFieldValue('attendedNsaa', false);
    formik.setFieldValue('isCheckPeriodStudyInNYK', false);
    formik.setFieldValue('isCheckPeriodStudyInNPP', false);
    formik.setFieldValue('isCheckPeriodStudyInNYP', false);
    formik.setFieldValue('isCheckPeriodStudyInNYGH', false);
    formik.setFieldValue('periodStudyInNPP', EMPTY);
    formik.setFieldValue('periodStudyInNYGH', EMPTY);
    formik.setFieldValue('periodStudyInNYK', EMPTY);
    formik.setFieldValue('periodStudyInNYP', EMPTY);
    formik.setFieldValue('isChildAttend', EMPTY);
  };

  return (
    <Box
      width={responsive.isTabletOrMobile ? '100%' : 776}
      mx={'auto'}
      px={responsive.isTabletOrMobile ? 2 : 0}
      mb={3.75}
    >
      <Box mb={3.75}>
        <Label required>Event</Label>
        <FormTextField
          onChange={(e) => {}}
          variant="outlined"
          fullWidth
          size="small"
          onBlur={formik.handleBlur}
          value={formik.values.eventTitle}
          error={formik.touched.eventId && !!formik.errors.eventId}
          helperText={formik.touched.eventId && formik.errors.eventId}
          name="eventId"
          inputProps={{ maxLength: INPUT_MAX_LENGTH_128 }}
          disabled
        />
      </Box>
      <Box display="flex" style={{ gap: responsive.isTabletOrMobile ? 20 : 143 }}>
        <Box flex="0.5">
          <Label required>Surname</Label>
        </Box>
        <Box flex="0.5">
          <Label required>Given name</Label>
        </Box>
      </Box>
      <Box display="flex" style={{ gap: responsive.isTabletOrMobile ? 20 : 143 }} mb={3.75}>
        <FormTextField
          onChange={formik.handleChange}
          variant="outlined"
          fullWidth
          size="small"
          onBlur={formik.handleBlur}
          value={formik.values.surName}
          error={formik.touched.surName && !!formik.errors.surName}
          helperText={formik.touched.surName && formik.errors.surName}
          name="surName"
          inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
        />
        <FormTextField
          onChange={formik.handleChange}
          variant="outlined"
          fullWidth
          size="small"
          onBlur={formik.handleBlur}
          value={formik.values.givenName}
          error={formik.touched.givenName && !!formik.errors.givenName}
          helperText={formik.touched.givenName && formik.errors.givenName}
          name="givenName"
          inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
        />
      </Box>
      <Box mb={3.75}>
        <Label required>Email address</Label>
        <FormTextField
          onChange={formik.handleChange}
          variant="outlined"
          fullWidth
          size="small"
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.touched.email && !!formik.errors.email}
          helperText={formik.touched.email && formik.errors.email}
          name="email"
          inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
        />
      </Box>
      <Box mb={3}>
        <Label required>Phone number</Label>
        <Box display="flex" alignItems="flex-start" style={{ gap: 24 }}>
          <Box flex={responsive.isMobile ? '0.3' : '0.2'}>
            <ReactFlagsSelectStyled
              fullWidth
              selected={formik.values?.areaCode ? getAreaKey(formik.values.areaCode) : ''}
              onSelect={(v, ...props) => {
                formik.setFieldValue('areaCode', getAreaCode(v));
              }}
              searchable
              customLabels={customLabels}
              showSecondaryOptionLabel
              showSelectedLabel={false}
            />
          </Box>
          <Box flex={responsive.isMobile ? '0.7' : '0.8'}>
            <FormTextField
              variant="outlined"
              fullWidth
              size="small"
              value={formik.values.mobilePhone}
              onChange={handleInputNumberChange('mobilePhone')}
              name="mobilePhone"
              onBlur={formik.handleBlur}
              error={formik.touched.mobilePhone && !!formik.errors.mobilePhone}
              helperText={formik.touched.mobilePhone && formik.errors.mobilePhone}
              inputProps={{ maxLength: INPUT_MAX_LENGTH_15 }}
              placeholder="Phone number including Area code, if there is"
            />
          </Box>
        </Box>
      </Box>
      <Box mb={3}>
        <Label>Which schools have you attended?</Label>
        <RadioGroup
          name="attendedNsaa"
          row
          value={formik.values.attendedNsaa}
          onChange={(e, v) => {
            if (v === 'no') {
              setEmptyChildAttended();
              formik.setFieldValue('attendedNsaa', v);
            } else {
              formik.setFieldValue('attendedNsaa', v);
            }
          }}
        >
          <FormControlLabelStyled value="yes" control={<NASSRadio />} label="Yes" />
          <Box width={100} />
          <FormControlLabelStyled value="no" control={<NASSRadio />} label="No" />
        </RadioGroup>
      </Box>
      <Box mb={3}>
        <Box display="flex">
          <Box flex="0.5">
            <Label required>School Attended</Label>
            {formik.values.attendedNsaa === 'yes' && formik.errors.isChildAttend && (
              <ErrorMessage>{THIS_FIELD_IS_REQUIRED}</ErrorMessage>
            )}
          </Box>
          <Box flex="0.5">
            <Label required>Year of Graduation / Leave School</Label>
          </Box>
        </Box>
        <Box display="flex" mb={3}>
          <Box flex="0.5">
            <FormControlLabelStyled
              label="Nanyang Kindergarten"
              control={
                <Checkbox
                  disabled={formik.values.attendedNsaa === NO}
                  color="primary"
                  checked={formik.values.isCheckPeriodStudyInNYK}
                  onChange={handleGraduateChange('isCheckPeriodStudyInNYK', 'periodStudyInNYK')}
                />
              }
            />
          </Box>
          <Box flex="0.5">
            <FormTextField
              variant="outlined"
              color="primary"
              fullWidth
              size="small"
              disabled={!formik.values.isCheckPeriodStudyInNYK}
              value={formik.values.periodStudyInNYK}
              onChange={handleInputChange('periodStudyInNYK')}
              name="periodStudyInNYK"
              onBlur={formik.handleBlur}
              error={
                formik.values.isCheckPeriodStudyInNYK &&
                formik.touched.periodStudyInNYK &&
                !!formik.errors.periodStudyInNYK
              }
              helperText={
                formik.values.isCheckPeriodStudyInNYK &&
                formik.touched.periodStudyInNYK &&
                formik.errors.periodStudyInNYK
              }
              inputProps={{ maxLength: INPUT_YEAR_LENGTH }}
            />
          </Box>
        </Box>
        <Box display="flex" mb={3}>
          <Box flex="0.5">
            <FormControlLabelStyled
              label="Nanyang Pre-Primary"
              control={
                <Checkbox
                  disabled={formik.values.attendedNsaa === NO}
                  color="primary"
                  checked={formik.values.isCheckPeriodStudyInNPP}
                  onChange={handleGraduateChange('isCheckPeriodStudyInNPP', 'periodStudyInNPP')}
                />
              }
            />
          </Box>
          <Box flex="0.5">
            <FormTextField
              variant="outlined"
              fullWidth
              size="small"
              disabled={!formik.values.isCheckPeriodStudyInNPP}
              value={formik.values.periodStudyInNPP}
              onChange={handleInputChange('periodStudyInNPP')}
              name="periodStudyInNPP"
              onBlur={formik.handleBlur}
              error={
                formik.values.isCheckPeriodStudyInNPP &&
                formik.touched.periodStudyInNPP &&
                !!formik.errors.periodStudyInNPP
              }
              helperText={
                formik.values.isCheckPeriodStudyInNPP &&
                formik.touched.periodStudyInNPP &&
                formik.errors.periodStudyInNPP
              }
              inputProps={{ maxLength: INPUT_YEAR_LENGTH }}
            />
          </Box>
        </Box>
        <Box display="flex" mb={3}>
          <Box flex="0.5">
            <FormControlLabelStyled
              label="Nanyang Primary"
              control={
                <Checkbox
                  disabled={formik.values.attendedNsaa === NO}
                  color="primary"
                  checked={formik.values.isCheckPeriodStudyInNYP}
                  onChange={handleGraduateChange('isCheckPeriodStudyInNYP', 'periodStudyInNYP')}
                />
              }
            />
          </Box>
          <Box flex="0.5">
            <FormTextField
              variant="outlined"
              fullWidth
              size="small"
              disabled={!formik.values.isCheckPeriodStudyInNYP}
              value={formik.values.periodStudyInNYP}
              onChange={handleInputChange('periodStudyInNYP')}
              name="periodStudyInNYP"
              onBlur={formik.handleBlur}
              error={
                formik.values.isCheckPeriodStudyInNYP &&
                formik.touched.periodStudyInNYP &&
                !!formik.errors.periodStudyInNYP
              }
              helperText={
                formik.values.isCheckPeriodStudyInNYP &&
                formik.touched.periodStudyInNYP &&
                formik.errors.periodStudyInNYP
              }
              inputProps={{ maxLength: INPUT_YEAR_LENGTH }}
            />
          </Box>
        </Box>
        <Box display="flex" mb={3}>
          <Box flex="0.5">
            <FormControlLabelStyled
              label="Nanyang Girls' High"
              control={
                <Checkbox
                  disabled={formik.values.attendedNsaa === NO}
                  color="primary"
                  checked={formik.values.isCheckPeriodStudyInNYGH}
                  onChange={handleGraduateChange('isCheckPeriodStudyInNYGH', 'periodStudyInNYGH')}
                />
              }
            />
          </Box>
          <Box flex="0.5">
            <FormTextField
              variant="outlined"
              fullWidth
              size="small"
              disabled={!formik.values.isCheckPeriodStudyInNYGH}
              value={formik.values.periodStudyInNYGH}
              onChange={handleInputChange('periodStudyInNYGH')}
              name="periodStudyInNYGH"
              onBlur={formik.handleBlur}
              error={
                formik.values.isCheckPeriodStudyInNYGH &&
                formik.touched.periodStudyInNYGH &&
                !!formik.errors.periodStudyInNYGH
              }
              helperText={
                formik.values.isCheckPeriodStudyInNYGH &&
                formik.touched.periodStudyInNYGH &&
                formik.errors.periodStudyInNYGH
              }
              inputProps={{ maxLength: INPUT_YEAR_LENGTH }}
            />
          </Box>
        </Box>
      </Box>

      {IS_NOT_INCLUDE_MEAL ? (
        <Box mb={3.75}>
          <Label required>Number of tickets</Label>
          <FormTextField
            onChange={handleInputNumberChange('totalTicket')}
            variant="outlined"
            fullWidth
            size="small"
            onBlur={formik.handleBlur}
            value={formik.values.totalTicket}
            error={
              (formik.touched.totalTicket && !!formik.errors.totalTicket) ||
              (formik.touched.totalTicket &&
                formik.values.is_public &&
                formik.values.require_ticket &&
                +TOTAL_TICKETS > MAX_NUMBER_PER_PURCHASE) ||
              (formik.touched.totalTicket && !formik.values.is_public && +TOTAL_TICKETS > MAX_GUEST) ||
              (formik.touched.totalTicket &&
                formik.values.is_public &&
                !formik.values.require_ticket &&
                +TOTAL_TICKETS > MAX_GUEST)
            }
            helperText={
              formik.touched.totalTicket && !formik.values.is_public && +TOTAL_TICKETS > MAX_GUEST
                ? MAX_NUMBER_OF_GUEST_MESSAGE
                : formik.touched.totalTicket && formik.values.require_ticket && +TOTAL_TICKETS > MAX_NUMBER_PER_PURCHASE
                ? TOTAL_TICKET_EXCEEDS_ALLOW_MAXIMUM
                : formik.touched.totalTicket && !formik.values.require_ticket && +TOTAL_TICKETS > MAX_GUEST
                ? MAX_NUMBER_OF_GUEST_MESSAGE
                : formik.touched.totalTicket && formik.errors.totalTicket
            }
            name="totalTicket"
            inputProps={{ maxLength: INPUT_MAX_LENGTH_128, readOnly: false }}
          />
        </Box>
      ) : (
        <Box>
          <Box>
            <Box mb={3.75}>
              <Label required>Number of tickets for standard meal</Label>
              <FormTextField
                onChange={handleTicketChange('ticketForStandardMeal')}
                variant="outlined"
                fullWidth
                size="small"
                onBlur={formik.handleBlur}
                value={formik.values.ticketForStandardMeal}
                error={
                  (formik.touched.ticketForStandardMeal && !!formik.errors.ticketForStandardMeal) ||
                  !IS_MULTIPLE_10_STANDARD_MEAL
                }
                helperText={
                  !isAdminForm
                    ? SHOW_TICKET_FOR_STANDARD_MEAL
                    : formik.touched.ticketForStandardMeal && formik.errors.ticketForStandardMeal
                }
                name="ticketForStandardMeal"
                inputProps={{ maxLength: INPUT_MAX_LENGTH_128 }}
                InputProps={{ inputProps: { min: 0, step: 1 } }}
              />
            </Box>
            <Box mb={3.75}>
              <Label required>NUMBER OF TICKETS FOR VEGETARIAN</Label>
              <FormTextField
                onChange={handleTicketChange('ticketForVegetarian')}
                variant="outlined"
                fullWidth
                size="small"
                onBlur={formik.handleBlur}
                value={formik.values.ticketForVegetarian}
                error={
                  (formik.touched.ticketForVegetarian && !!formik.errors.ticketForVegetarian) ||
                  !IS_MULTIPLE_10_VEGETARIAN
                }
                helperText={
                  !isAdminForm
                    ? SHOW_TICKET_FOR_VEGETARIAN
                    : formik.touched.ticketForVegetarian && formik.errors.ticketForVegetarian
                }
                name="ticketForVegetarian"
                inputProps={{ maxLength: INPUT_MAX_LENGTH_128 }}
                InputProps={{ inputProps: { min: 0, step: 1 } }}
              />
            </Box>
            <Box mb={3.75}>
              <Label required>NUMBER OF TICKETS FOR HALAL</Label>
              <FormTextField
                onChange={handleTicketChange('ticketForHalal')}
                variant="outlined"
                fullWidth
                size="small"
                onBlur={formik.handleBlur}
                value={formik.values.ticketForHalal}
                error={(formik.touched.ticketForHalal && !!formik.errors.ticketForHalal) || !IS_MULTIPLE_10_HALAL}
                helperText={
                  !isAdminForm ? SHOW_TICKET_FOR_HALAL : formik.touched.ticketForHalal && formik.errors.ticketForHalal
                }
                name="ticketForHalal"
                inputProps={{ maxLength: INPUT_MAX_LENGTH_128 }}
                InputProps={{ inputProps: { min: 0, step: 1 } }}
              />
            </Box>
            <Box mb={3.75}>
              <Label required>Number of tickets</Label>
              <FormTextField
                onChange={handleInputNumberChange('totalTicket')}
                variant="outlined"
                fullWidth
                size="small"
                onBlur={formik.handleBlur}
                value={TOTAL_TICKETS}
                error={
                  (formik.touched.totalTicket && !!formik.errors.totalTicket) ||
                  !IS_MULTIPLE_10 ||
                  +TOTAL_TICKETS > MAX_NUMBER_PER_PURCHASE
                }
                helperText={
                  !isAdminForm ? SHOW_TICKET_FOR_TOTAL_TICKET : formik.touched.totalTicket && formik.errors.totalTicket
                }
                name="totalTicket"
                inputProps={{ maxLength: INPUT_MAX_LENGTH_128, readOnly: true }}
                disabled
                InputProps={{ inputProps: { min: 0, step: 1 } }}
              />
            </Box>
          </Box>
        </Box>
      )}
      {event.ticket_type === TICKET_TYPE_2 && event.is_public && (
        <Box mb={3.75}>
          <Label required>Number of attendees age above [{formik.values.discount_for_age || 0}]</Label>
          <FormTextField
            onChange={formik.handleChange}
            variant="outlined"
            fullWidth
            size="small"
            onBlur={formik.handleBlur}
            value={formik.values.numberAttendedAge}
            error={
              (formik.touched.numberAttendedAge && !!formik.errors.numberAttendedAge) ||
              (formik.touched.numberAttendedAge && +formik.values.numberAttendedAge > +TOTAL_TICKETS)
            }
            helperText={
              !isAdminForm && formik.touched.numberAttendedAge && !!formik.errors.numberAttendedAge
                ? formik.errors.numberAttendedAge
                : formik.touched.numberAttendedAge && +formik.values.numberAttendedAge > +TOTAL_TICKETS
                ? ATTENDED_AGE_MUST_LEST_THAN_TOTAL_TICKETS
                : EMPTY
            }
            name="numberAttendedAge"
            inputProps={{ maxLength: INPUT_MAX_LENGTH_128 }}
            InputProps={{ inputProps: { min: 0, step: 1 } }}
          />
        </Box>
      )}
    </Box>
  );
};

export default FillForm;
