import React, { useEffect } from 'react';

import { Box, FormControl, Grid, IconButton, makeStyles, MenuItem, Typography } from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import styled from 'styled-components';

import { CustomDatePicker, CustomInputSelect, InputField } from 'src/components';
import CloseIcon from 'src/medias/icons/close-icon.svg';
import { ISignUpReq } from 'src/services/auth';
import { updateStatusValidChild } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';
import { _checkErrors } from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { CustomLabel, nationalities } from './types';

const useStyles = makeStyles((theme) => ({
  noticeRequire: {
    color: 'red',
    verticalAlign: 'middle',
  },
  clearIcon: {
    position: 'absolute',
    right: -50,
    bottom: 12,
  },
  container: {
    position: 'relative',
  },
  empty: {},
}));

export const CloseIconStyled = styled.img(() => ({}));

type KeyChild = { givenName: boolean; surname: boolean; dateOfBirth: boolean };

interface IChild {
  child: { givenName: string; surname: string; dateOfBirth: Date | null; nationality: string };
  index: number;
  data: ISignUpReq;
  setData: React.Dispatch<React.SetStateAction<ISignUpReq>>;
  _onDeleteChild: (idx: number) => void;
  checkChild: boolean;
  setCheckChild: React.Dispatch<React.SetStateAction<boolean>>;
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
}

export const Child = ({
  data,
  setData,
  child,
  index,
  _onDeleteChild,
  checkChild,
  setCheckChild,
  errors,
  setErrors,
}: IChild) => {
  const { isTabletOrMobile } = useResponsive();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    let newChild = data.child;

    if (newChild) {
      switch (e.target.name) {
        case 'surname': {
          newChild[index].surname = e.target.value;
          setData({ ...data, child: newChild });

          break;
        }
        case 'givenName': {
          newChild[index].givenName = e.target.value;
          setData({ ...data, child: newChild });

          break;
        }
        case 'nationality': {
          newChild[index].nationality = e.target.value;
          setData({ ...data, child: newChild });

          break;
        }
      }
    }
    setValidate(e.target.name, e.target.value);
  }

  useEffect(() => {
    if (data.child && checkChild) {
      // eslint-disable-next-line array-callback-return
      data.child.map((item) => {
        let validErrors = errors;
        for (const [key, value] of Object.entries(item)) {
          if (value === '') {
            validErrors[key as keyof KeyChild] = true;
            setErrors({ ...validErrors, [key]: true });
          }
        }
      });
      setCheckChild(false);
    }
  }, [data.child, checkChild]);

  useEffect(() => {
    if (checkChild) {
      if (!_checkErrors(errors)) {
        dispatch(updateStatusValidChild(false));
      }
    }
  }, [errors, checkChild]);

  const handleDateChange = (date: Date | null) => {
    if (date) {
      let newChild = data.child;
      if (newChild) {
        newChild[index].dateOfBirth = date;
        setData({ ...data, child: newChild });
      }
      setValidate('dateOfBirth', date.toString());
    }
  };

  function handleDelete() {
    _onDeleteChild(index);
  }
  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    if (data.child) {
      let newChild = data.child;
      const valueTrimed = e.target.value.trim();
      switch (e.target.name) {
        case 'surname': {
          newChild[index].surname = valueTrimed;
          setData({ ...data, child: newChild });

          break;
        }
        case 'givenName': {
          newChild[index].givenName = valueTrimed;
          setData({ ...data, child: newChild });

          break;
        }
        case 'nationality': {
          newChild[index].nationality = e.target.value;
          setData({ ...data, child: newChild });

          break;
        }
      }
    }
  };
  return (
    <Grid container key={index} className={isTabletOrMobile ? classes.empty : classes.container}>
      <Grid item xs={isTabletOrMobile ? 12 : 3}>
        <Box flex={1}>
          <InputField
            value={child.surname}
            onChange={_handleChange}
            onBlur={onBlurField}
            title="Child's Surname"
            margin="dense"
            name="surname"
            fullWidth
            require
          />
        </Box>
      </Grid>
      <Grid item xs={isTabletOrMobile ? 12 : 3}>
        <Box flex={1} pl={isTabletOrMobile ? 0 : 3}>
          <InputField
            value={child.givenName}
            onChange={_handleChange}
            onBlur={onBlurField}
            margin="dense"
            title="Child's Given Name"
            fullWidth
            name="givenName"
            require
          />
        </Box>
      </Grid>
      <Grid item xs={isTabletOrMobile ? 12 : 3}>
        <Box flex={1} pl={isTabletOrMobile ? 0 : 3} mt={3} display="flex">
          <FormControl fullWidth>
            <CustomLabel>
              Date of Birth
              <Typography component="span" className={classes.noticeRequire}>
                *
              </Typography>
            </CustomLabel>
            <CustomDatePicker
              onChange={handleDateChange}
              value={child.dateOfBirth}
              maxDate={moment().subtract(0, 'years')}
              name="dateOfBirth"
            />
          </FormControl>
        </Box>
      </Grid>
      <Grid item xs={isTabletOrMobile ? 12 : 3}>
        <Box flex={1} pl={isTabletOrMobile ? 0 : 3} mt={1}>
          <CustomInputSelect title="Nationality" name="nationality" value={child.nationality} onChange={_handleChange}>
            {nationalities.map((nationality) => (
              <MenuItem key={nationality.key} value={nationality.key}>
                {nationality.value}
              </MenuItem>
            ))}
          </CustomInputSelect>
        </Box>
      </Grid>
      {isTabletOrMobile && (
        <Box
          flex={1}
          mt={3}
          pb={2}
          borderBottom={'1px solid #ccc'}
          justifyContent="center"
          display="flex"
          alignItems="center"
        >
          <IconButton style={{ padding: 0 }} size="medium" onClick={handleDelete}>
            <CloseIconStyled src={CloseIcon} />
          </IconButton>
        </Box>
      )}
      {!isTabletOrMobile && (
        <IconButton style={{ padding: 0 }} size="medium" className={classes.clearIcon} onClick={handleDelete}>
          <CloseIconStyled src={CloseIcon} />
        </IconButton>
      )}
    </Grid>
  );
};
