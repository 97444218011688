import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

import BGImage from 'src/medias/images/background-signup.png';
import { IResponsive } from 'src/pages/home/homepageStyled';

export const LayoutContainer = styled(Box)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    ${responsive?.isTabletOrMobile &&
    css`
      width: 1440px;
    `}
    ${responsive?.isDesktopOrLaptop &&
    css`
      width: 100%;
    `}
  `}
`;

export const LayoutHeader = styled(Box)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    border-bottom: 1px solid lightgray;
    flex-grow: 0;
    position: ${responsive?.isTabletOrMobile ? 'sticky' : 'fixed'};
    top: 150px;
    background: white;
    z-index: 99;
    left: 0;
    right: 0;
  `}
`;

export const LayoutContent = styled(Box)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    display: flex;
    min-height: 100vh;
  `}
`;

export const LayoutNavigation = styled(Box)`
  ${({ theme }) => css`
    width: 240px;
    border-right: 1px solid ${theme.palette.lightGray?.[25]};
    flex-shrink: 0;
  `}
`;

export const LayoutChildren = styled(Box)`
  ${({ theme }) => css`
    text-align: justify;
    background-image: url(${BGImage});
    background-size: cover;
    background-repeat: no-repeat;
    padding: 40px 60px;
    flex-grow: 1;
    min-height: 940px;
    max-width: calc(100% - 240px);
    margin-top: 60px;
  `}
`;
