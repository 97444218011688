import React from 'react';

import { AppBar, Box, IconButton, makeStyles, MenuItem, Popper, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import SideMenu from 'src/layouts/main/components/SideMenu';
import { menuUserNotLogin, menuUserMobile, menuUser } from 'src/layouts/main/type';
import DefaultAvatar from 'src/medias/images/default_avatar.png';
import headerLogo from 'src/medias/images/main-nsaa-logo.png';
import { IUser } from 'src/services/users';
import { selectLoginState } from 'src/store/authentication/selector';
import { logout } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';
import useResponsive from 'src/utils/responsive';

const useStyles = makeStyles(() => ({
  menuButton: {
    color: '#979168',
  },
}));

interface IHeaderMobile {
  userProfile?: IUser | null;
  title?: string;
  hasLogo?: boolean;
}

export const HeaderMobile = ({ title, userProfile, hasLogo }: IHeaderMobile) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isMobile, isTablet } = useResponsive();
  const isLogin = useSelector(selectLoginState);
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    setOpenDrawer(open);
  };
  const _onClickMenu = () => {
    setOpenDrawer(true);
  };
  const handleClickAvatar = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const _onClickMenuItem = (menu: { title: string; link: string }) => {
    if (menu.title === 'Log out') {
      dispatch(logout());
      return;
    }

    window.location.href = menu.link;
    return;
  };

  let menuLogged = menuUser;

  if (userProfile?.canScanTicket) {
    menuLogged = menuUserMobile;
  }

  const redirectHome = () => {
    window.location.href = '/';
  };

  const adminRoutes = [
    'user-management',
    'event-management',
    'payment-management',
    'attendance-management',
    'event-management',
    'cms',
    'email-tracking',
    'gtky-applicant',
  ];

  let isAdminRoute = false;

  adminRoutes.forEach((item) => {
    if (item === window.location.pathname.split('/')[1]) {
      isAdminRoute = true;
    }
  });

  let redirectURL = '/user-management';
  if (userProfile && userProfile.roleName === 'event-ic') {
    redirectURL = '/event-management';
  }

  if (userProfile && userProfile.committeeMember === 'yes' && userProfile.roleName === null) {
    redirectURL = '/gtky-applicant';
  }
  if (userProfile && userProfile.roleName === 'accountant') {
    redirectURL = '/payment-management';
  }

  return (
    <Box>
      <AppBarStyled>
        <ToolbarCustom isMobile={isMobile}>
          <SideMenu
            open={openDrawer}
            toggleDrawer={toggleDrawer}
            isMobile={isMobile}
            isTablet={isTablet}
            isLogin={isLogin}
            userProfile={userProfile}
            isCommitteePortal={userProfile?.committeeMember === 'yes' && isAdminRoute}
            redirectURL={redirectURL}
          />
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon onClick={_onClickMenu} />
          </IconButton>
          <Box>
            {hasLogo ? (
              <Box onClick={redirectHome}>
                <LogoStyled src={headerLogo} />
              </Box>
            ) : (
              <HeaderTitle>{title ?? ''}</HeaderTitle>
            )}
          </Box>
          <IconButton
            onClick={handleClickAvatar}
            edge="end"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            {userProfile ? (
              <>
                <Avatar imageUrl={userProfile?.avatar || DefaultAvatar} />
                <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 1500, backgroundColor: 'white' }}>
                  <BoxShadowStyled>
                    {menuLogged.map((menu) => (
                      <MenuItemStyled onClick={_onClickMenuItem.bind(null, menu)}>{menu.title}</MenuItemStyled>
                    ))}
                  </BoxShadowStyled>
                </Popper>
              </>
            ) : (
              <>
                <PersonIcon />
                <Popper id={id} open={open} anchorEl={anchorEl} style={{ zIndex: 1500, backgroundColor: 'white' }}>
                  <BoxShadowStyled>
                    {menuUserNotLogin.map((menu) => (
                      <MenuItemStyled onClick={_onClickMenuItem.bind(null, menu)}>{menu.title}</MenuItemStyled>
                    ))}
                  </BoxShadowStyled>
                </Popper>
              </>
            )}
          </IconButton>
        </ToolbarCustom>
      </AppBarStyled>
    </Box>
  );
};

const LogoStyled = styled.img(() => ({
  height: '90px',
  width: '70px',
  cursor: 'pointer',
}));

export const BoxShadowStyled = styled(Box)(() => ({
  boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.15)',
}));

export const Avatar = styled(Box)<{ imageUrl: string }>`
  ${({ theme, imageUrl }) => css`
    width: 24px;
    height: 24px;
    border-radius: 12px;
    background-image: url(${imageUrl});
    background-size: cover;
    background-position: center;
  `}
`;

export const MenuItemStyled = styled(MenuItem)`
  ${({ theme }) => css`
    min-width: 160px;
    padding: 0px 8px 0px 8px;
    height: 40px;
    min-height: 40px;
    &&:hover {
      background-color: ${theme.palette.yellow?.[900]};
      a {
        color: white;
      }
    }
  `}
`;

const HeaderTitle = styled(Typography)`
  color: #979168;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 0px 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const AppBarStyled = styled(AppBar)`
  && {
    background: ${({ theme }) => theme.palette.common.white};
    position: fixed;
    z-index: 999;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }
`;

const ToolbarCustom = styled(Toolbar)<{ isMobile?: boolean }>`
  ${({ theme, isMobile }) => css`
    && {
      display: flex;
      justify-content: space-between;
      padding: ${isMobile ? '24px 20px 16px 20px' : '24px 68px 16px 68px'};
      height: 120px;
    }
  `};
`;
