import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

import BackgroundImage from 'src/medias/images/background-signup.png';
import { IResponsive } from 'src/pages/home/homepageStyled';

export const ProfileLayoutContainer = styled(Box)`
  ${({ theme }) => css`
    max-width: 1440px;
    margin: auto;
    padding: 30px 80px 48px;
  `}
`;

export const LayoutContainer = styled(Box)<{ responsive: IResponsive; factoryScale: number; w: number; h: number }>`
  ${({ theme, responsive, factoryScale, w, h }) => css`
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    transform-origin: top left;
    width: ${w * (1 / factoryScale!)}px;
    height: ${responsive.isTabletOrMobile ? h + 300 + 'px' : 'auto'};
    ${responsive.isDesktopOrLaptop &&
    css`
      max-width: 100%;
    `}
    transform: ${`scale(${factoryScale})`};
  `}
`;

export const LayoutWrapper = styled(Box)`
  ${({ theme }) => css`
    background-image: url(${BackgroundImage});
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    hight: 100%;
  `}
`;
