import React from 'react';

import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import LockIcon from 'src/icons/lock';
import { IResponsive } from 'src/pages/home/homepageStyled';
import { IEvent } from 'src/services/events';
import useResponsive from 'src/utils/responsive';

interface INavigationProps {
  event?: IEvent;
}

const Navigation = ({ event }: INavigationProps) => {
  const responsive = useResponsive();
  const DRAFT = 'draft';
  const links = [
    { link: `/event-management/${event?.id}/general`, label: 'General Information' },
    { link: `/event-management/${event?.id}/volunteer`, label: 'Volunteer Management' },
    { link: `/event-management/${event?.id}/ticket`, label: 'Ticket Setting' },
    { link: `/event-management/${event?.id}/quiz`, label: 'Quiz Setting', isLocked: event?.is_public },
    { link: `/event-management/${event?.id}/guest`, label: 'Guest List', isLocked: event?.status === DRAFT },
  ];
  return (
    <NavigationContainer responsive={responsive}>
      {links.map((item) => (
        <NavLinkStyled exact to={item.link} activeClassName="navigation__active" disabled={item.isLocked}>
          {item.label}
          {item.isLocked ? <LockIcon width={20} height={20} fill="#00000099" /> : null}
        </NavLinkStyled>
      ))}
    </NavigationContainer>
  );
};

export default Navigation;

const NavLinkStyled = styled(NavLink)<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    ${disabled &&
    css`
      pointer-events: none;
    `}
    user-select: none;
    color: rgba(0, 0, 0, 0.6);
    display: block;
    text-decoration: none;
    height: 52px;
    width: 100%;
    border-left: 4px solid transparent;
    display: flex;
    align-items: center;
    padding: 20px;
    color: #00000099;
    font-size: ${theme.fonts.textM.fontSize};
    font-weight: ${theme.fonts.textM.fontWeight};
    line-height: ${theme.fonts.textM.lineHeight};
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    justify-content: space-between;

    &.navigation__active {
      color: ${theme.palette.yellow?.[900]};
      border-left: 4px solid ${theme.palette.yellow?.[900]};
      font-weight: 700;
      svg path {
        stroke: ${theme.palette.yellow?.[900]};
      }
    }

    &:hover {
      background-color: #d6cc86;
      color: white;
    }
  `}
`;
export const NavigationContainer = styled(Box)<{ responsive?: IResponsive }>`
  ${({ responsive }) => css`
    position: ${responsive?.isTabletOrMobile ? 'sticky' : 'fixed'};
    top: 220px;
  `}
`;
