import { Box, Button, Grid, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import styled, { css } from 'styled-components';

export interface IResponsive {
  isDesktopOrLaptop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isTabletOrMobile: boolean;
}

export const Section = styled.section`
  && {
    position: relative;
    width: 100%;
    height: 80vh;
  }
`;
export const GridItem = styled(Grid)`
  padding: 16px;
`;
export const NsaaEventContainer = styled(Box)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    && {
      width: 100%;
      height: 100%;

      padding: ${responsive?.isMobile ? '0' : '80px'};
      background-color: ${theme.palette.gray?.[200]};
      margin-bottom: ${responsive?.isMobile ? '48px' : '0'};
      padding-top: ${responsive?.isMobile ? '32px' : '0'};
      margin-top: ${responsive?.isMobile ? '0px' : '80px'};
    }
  `}
`;
export const MaxWidth1440 = styled(Box)`
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
`;
export const NsaaTitle = styled(Grid)<{ responsive?: IResponsive }>`
  width: 100%;
  margin-bottom: 5px;
  ${({ theme, responsive }) => ({
    alignItems: responsive?.isTabletOrMobile ? 'flex-start' : 'center',
    padding: '0px 16px',
  })}
  margin-bottom: 24px;
`;
export const BigTitle = styled(Grid)<{ responsive?: IResponsive }>`
  && {
    ${({ theme, responsive }) => ({
      ...theme.fonts.header1,
      fontSize: responsive?.isMobile
        ? theme.fonts.header4.fontSize
        : responsive?.isTablet
        ? theme.fonts.header3.fontSize
        : theme.fonts.header1.fontSize,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 0,
    })}
  }
`;
export const ButtonBox = styled(Grid)`
  && {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
`;
export const NavLinkStyled = styled(NavLink)`
  && {
    text-decoration: none;
    color: ${({ theme }) => theme.palette.common.black};
    display: flex;
    flex-direction: column;
  }
`;
export const MoreBtn = styled(Grid)<{ responsive?: IResponsive }>`
  width: max-content;
  && {
    color: ${({ theme }) => theme.palette.yellow?.[900]};
    font-size: ${({ theme, responsive }) =>
      responsive?.isTabletOrMobile ? theme.fonts.textS.fontSize : theme.fonts.textL.fontSize};
    font-weight: ${({ theme }) => theme.fonts.textL.fontWeight};
    line-height: ${({ theme }) => theme.fonts.textL.lineHeight};
  }
`;
export const DiaLogContent = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textL,
  margin: '10px 0px',
}));
export const CustomBtn = styled(Button)`
  && {
    ${({ theme }) => css`
      font-size: ${theme.fonts.textSs.fontSize};
      font-weight: ${theme.fonts.textSs.fontWeight};
      line-height: ${theme.fonts.textSs.lineHeight};
      border: 1px solid ${theme.palette.yellow?.[900]};
      color: ${theme.palette.yellow?.[900]};
    `}
  }
`;
export const SliderCustom = styled(Slider)`
  width: 100%;
  padding-left: 16px;
  & .slick-slide {
    padding-right: 16px;
  }
  /* & .slick-track {
    width: max-content !important;
  } */
`;
export const ThumbnailPost = styled(Box)`
  && {
    position: relative;
    width: 100%;
    height: auto;
  }
`;
export const CustomImg = styled.img<{}>`
  && {
    width: 100%;
    height: auto;
    border-radius: 6px;
    height: 500px;
    object-fit: contain;
  }
`;
export const LabelTime = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: 'fit-content',
  gap: 5,
}));

export const TimeEvent = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textLL,
  color: theme.palette.gray?.[60],
}));
export const TitleEvent = styled(Typography)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => ({
    ...theme.fonts.header4,
    marginTop: '10px',
    marginBottom: '10px',
    whiteSpace: responsive?.isMobile ? 'nowrap' : 'unset',
    overflow: responsive?.isMobile ? 'hidden' : 'unset',
    textOverflow: responsive?.isMobile ? 'ellipsis' : 'unset',
  })}
`;
export const ContentBox = styled(Box)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    && {
      position: absolute;
      left: 0;
      bottom: 0;
      background-color: ${theme.palette.common.white};
      margin: 0;
      width: 80%;
      padding: ${responsive?.isTabletOrMobile ? '16px' : '24px'};
      max-height: ${responsive?.isMobile ? '50%' : '100%'};
    }
  `}
`;
