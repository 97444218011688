import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ImageStyled = styled.img(() => ({}));

export const TypographyStyled = styled(Typography)(() => ({
  color: 'rgba(197, 190, 139, 1)',
  fontSize: 14,
  fontWeight: 400,
  marginTop: 24,
  fontStyle: 'italic',
}));

export const BoxStyled = styled(Box)(({ isTablet }: { isTablet: boolean }) => ({
  marginTop: isTablet ? '260px' : '80px',
  marginBottom: isTablet ? '260px' : '80px',
}));
