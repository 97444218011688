import React from 'react';

import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { CustomButton } from 'src/components';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import ErrorIcon from 'src/medias/images/error-icon.svg';

export const Forbidden = () => {
  useScrollToTop();

  const _onClick = () => {
    window.location.href = '/';
  };

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      minHeight={890}
      maxHeight={890}
    >
      <Box mb={8}>
        <ImageStyled src={ErrorIcon} />
      </Box>
      <TitleStyled>You are not allowed to access this feature!</TitleStyled>
      <Box mt={8}>
        <CustomButton onClick={_onClick}>Go Back To Home Page</CustomButton>
      </Box>
    </Box>
  );
};

const TitleStyled = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 700,
  color: 'rgba(51, 51, 51, 1)',
}));

const ImageStyled = styled.img(() => ({}));
