import { ReactNode } from 'react';

import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

const LabelStyled = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textSBold,
  '&:hover': {
    cursor: 'default',
  },
}));
export const TypographyInfo = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: 'rgba(51, 51, 51, 1)',
  wordBreak: 'break-all',
}));
export const InfoText = ({
  label,
  value,
  valueWidth,
  labelWidth,
}: {
  label: string;
  value: string | ReactNode;
  valueWidth?: string;
  labelWidth?: string;
}) => {
  return (
    <Box display="flex" mt={2} alignItems="center">
      <Box width={labelWidth ?? '40%'}>
        <LabelStyled>{label}</LabelStyled>
      </Box>
      <Box width={'5%'}>
        <LabelStyled>:</LabelStyled>
      </Box>
      <Box width={valueWidth ?? '55%'}>
        <TypographyInfo>{value}</TypographyInfo>
      </Box>
    </Box>
  );
};
