import React from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardTimePicker, KeyboardTimePickerProps, MuiPickersUtilsProvider } from '@material-ui/pickers';
import styled from 'styled-components';

import ClockURL from 'src/medias/icons/clock-icon.svg';

const CustomTimePickerStyled = styled(KeyboardTimePicker)(({ theme, error, disabled }) => ({
  marginTop: 0,
  border: error ? `1px solid red` : `1px solid rgba(0,0,0,0.1)`,
  borderRadius: 6,
  height: 40,
  padding: 5,
  paddingLeft: 14,
  backgroundColor: disabled ? '#E3E6EA' : 'white',
}));

const CalenderIconStyled = styled.img(() => ({
  top: -2,
}));

interface ICustomDatePicker {
  value: Date | null;
  isError?: boolean;
  name?: string;
  variant?: string;
  disabled?: boolean;
}

export const CustomTimePicker = ({
  value,
  isError,
  name,
  variant = 'inline',
  disabled,
  ...props
}: ICustomDatePicker & KeyboardTimePickerProps) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <CustomTimePickerStyled
        {...props}
        disabled={disabled}
        error={isError}
        variant={variant}
        margin="normal"
        value={value}
        InputProps={{
          disableUnderline: true,
          readOnly: true,
        }}
        autoOk
        name={name}
        fullWidth
        invalidDateMessage=""
        minDateMessage=""
        maxDateMessage=""
        keyboardIcon={<CalenderIconStyled style={{ position: 'absolute' }} src={ClockURL} />}
      />
    </MuiPickersUtilsProvider>
  );
};
