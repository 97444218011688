import React from 'react';

import { Box, Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

import useResponsive from 'src/utils/responsive';

interface ISuccessSnackBar {
  open: boolean;
  handleClose(event: React.SyntheticEvent | React.MouseEvent, reason?: string | undefined): void;
  message: string | undefined;
}

const SuccessSnackBar = ({ open, handleClose, message }: ISuccessSnackBar) => {
  const { isTabletOrMobile } = useResponsive();

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Box width={isTabletOrMobile ? '300px' : '500px'}>
        <Alert severity="success" onClose={handleClose}>
          {message}
        </Alert>
      </Box>
    </Snackbar>
  );
};

export default SuccessSnackBar;
