import React from 'react';

interface IArrowRightAltIcon {
  width?: number;
  height?: number;
}

const ArrowRightAltIcon = ({
  width = 23,
  height = 23,
  ...props
}: IArrowRightAltIcon & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginTop: 2 }}
      {...props}
    >
      <path
        d="M16.292 11.5H6.708m9.584 0l-3.834 3.833m3.834-3.833l-3.834-3.833"
        stroke={props.fill || '#979168'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowRightAltIcon;
