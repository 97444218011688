import React, { useEffect, useState } from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { InputField } from 'src/components';
import CustomFormDialog from 'src/components/formDialog';
import { IVolunteer } from 'src/services/volunteer';
import { sortUserName } from 'src/utils/common';

const useStyles = makeStyles(() => ({
  input: {
    '&  .MuiOutlinedInput-root': {
      height: '205px !important',
    },
  },
}));

const MAXIMUM_LENGTH = 255;

interface IRemoveVolunteerModal {
  _onCloseModal: () => void;
  open: boolean;
  _onSubmit?: () => void;
  userSelected: IVolunteer[] | undefined;
  loading: boolean | undefined;
  reasonRemove: string;
  setReasonRemove: React.Dispatch<React.SetStateAction<string>>;
}
const RemoveVolunteerModal = ({
  loading,
  _onCloseModal,
  open,
  _onSubmit,
  userSelected,
  reasonRemove,
  setReasonRemove,
}: IRemoveVolunteerModal) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({
    hasReason: false,
  });

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setReasonRemove(e.target.value);
    if (e.target.value !== '') {
      setErrors({ ...errors, hasReason: false });
    } else {
      setErrors({ ...errors, hasReason: true });
    }
  }
  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setReasonRemove(valueTrimmed);
  };

  const handleSubmit = () => {
    if (reasonRemove === '') {
      setErrors({ ...errors, hasReason: true });
      return;
    }

    _onSubmit?.();
  };

  const handleClose = () => {
    _onCloseModal?.();
  };

  useEffect(() => {
    if (open) {
      setErrors({ ...errors, hasReason: false });
      setReasonRemove('');
    }
  }, [open]);

  return (
    <CustomFormDialog
      title="Remove Volunteer from Wait List"
      open={open}
      _onCloseModal={handleClose}
      submitText="Submit"
      _onSubmit={handleSubmit}
      disabled={loading}
    >
      <Box display="flex" flex={1} mt={1} mb={2}>
        <Box width={'50%'}>
          <LabelStyled>Removed Volunteer: [x] person(s)</LabelStyled>
          <SelectBox mt={1} style={{ overflowY: 'auto' }}>
            {[...new Set(userSelected)]?.sort(sortUserName)?.map((user, index) => {
              return (
                <SelectBoxItem p={1} index={index}>
                  <TextStyled>
                    {`${user.user.surname}, ${user.user.givenName} ( ${user.user.emailAddress} )`.length > 45
                      ? `${user.user.surname}, ${user.user.givenName} ( ${user.user.emailAddress} )`.slice(0, 45) +
                        '...'
                      : `${user.user.surname}, ${user.user.givenName} ( ${user.user.emailAddress} )`}
                  </TextStyled>
                </SelectBoxItem>
              );
            })}
          </SelectBox>
        </Box>
        <Box width={'50%'}>
          <Box ml={3}>
            <LabelStyled display="inline">
              Reason
              <Typography display="inline" color="error">
                {' '}
                *
              </Typography>
            </LabelStyled>
            <Box>
              <InputField
                className={classes.input}
                value={reasonRemove}
                onChange={_handleChange}
                onBlur={onBlurField}
                name="reasonRemove"
                multiline
                rows={9}
                rowsMax={9}
                fullWidth
                error={errors.hasReason}
                errorMessage="This field is required"
                InputProps={{
                  inputProps: {
                    maxLength: MAXIMUM_LENGTH,
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomFormDialog>
  );
};

export default RemoveVolunteerModal;

const LabelStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.6)',
}));

const SelectBox = styled(Box)(() => ({
  border: '1px solid #ccc',
  height: 205,
  borderRadius: 6,
}));

const SelectBoxItem = styled(Box)(({ index }: { index: number }) => ({
  backgroundColor: index % 2 ? '#F8F9FA' : 'white',
  borderBottom: '1px solid #ccc',
}));

const TextStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
}));
