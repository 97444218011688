import { CALL_SUCCESS } from 'src/constants/common';
import { IResponseList } from 'src/types';

export const REGISTRATION_STATUS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Attended',
    value: 'attended',
  },
  {
    label: 'Confirmed',
    value: 'confirmed',
  },
  {
    label: 'Expired',
    value: 'expired',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
];
export const GUEST_ACTIONS = [
  {
    label: 'Send Email',
    value: 'send-email',
  },
  {
    label: 'Resend Ticket',
    value: 'resend-ticket',
  },
  {
    label: 'Mark as Attended',
    value: 'mark-as-attended',
  },
];

export const DEFAULT_RESPONSE: IResponseList<any> = {
  data: {
    items: [],
    meta: {
      currentPage: 0,
      itemCount: 0,
      itemsPerPage: 0,
      totalItems: 0,
      totalPages: 0,
    },
  },
  message: '',
  statusCode: CALL_SUCCESS,
};
