import React, { useState } from 'react';

import { Box, Grid, IconButton, InputAdornment, Link, makeStyles, Typography } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

import { InputField } from 'src/components';
import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import { CALL_SUCCESS } from 'src/constants/common';
import { RESET_PASSWORD } from 'src/constants/users.constants';
import LogoJSA from 'src/medias/images/main-nsaa-logo.png';
import SignUpBackground from 'src/medias/images/signup-background.jpg';
import { resetPassword } from 'src/services/auth';
import {
  containsNumericCharacters,
  containsSpecialCharacters,
  containsUpperAndLowerCaseRegexp,
  isSpace,
} from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import ResetPasswordSuccess from '../ResetPasswordSuccess';
import { ErrorMess, Form, Logo, NYSImage, SubmitButton, TextBox, TitleResetPassword } from '../types';

const ERROR_PASSWORD_MESSAGE = `Password must have at least eight characters. The password must contain at least four character categories among the following: uppercase characters (A-Z), lowercase characters (a-z), a number (0-9), and special characters (~!@#$%)`;
const ERROR_VERIFY_MESSAGE = `Passwords do not match`;
const ERROR_SAME_PASSWORD_MESSAGE = `New password must be different from your last 6 passwords.`;
const MAXIMUM_PASSWORD_LENGTH = 32;
const MINIMUM_PASSWORD_LENGTH = 8;
const useStyles = makeStyles(() => ({
  left: {
    height: 300,
    position: 'relative',
    marginTop: 110,
  },
  right: {
    top: 202,
    width: '100%',
  },
}));
function ResetPasswordMobile() {
  const classes = useStyles();
  const history = useHistory();
  const token = history.location.search.slice(7);
  const userId = history.location.pathname.slice(16);
  const { isTablet, isMobile } = useResponsive();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [verifyPassword, setVerifyPassword] = useState<string>('');
  const [valid, setValid] = useState<boolean>(true);
  const [showVerify, setShowVerify] = useState<boolean>(false);
  const [isResetPassword, setIsResetPassword] = useState<boolean>(false);
  const [onClicking, setOnClicking] = useState<boolean>(false);
  const [data, setData] = useState<{ newPassword: string; username: string; token: string }>({
    newPassword: '',
    username: userId,
    token,
  });
  const [errors, setErrors] = useState<{ newPassword: boolean; verifyPassword: boolean }>({
    newPassword: false,
    verifyPassword: false,
  });
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    setOnClicking(true);
    setErrors({ newPassword: false, verifyPassword: false });
    e.preventDefault();
    const passwordLongEnough = _.size(data.newPassword) >= MINIMUM_PASSWORD_LENGTH;
    const isPasswordValid =
      passwordLongEnough &&
      containsUpperAndLowerCaseRegexp.test(data.newPassword) &&
      containsSpecialCharacters.test(data.newPassword) &&
      !isSpace(data.newPassword) &&
      containsNumericCharacters.test(data.newPassword);

    setErrors({ ...errors, newPassword: !isPasswordValid });
    if (verifyPassword !== data.newPassword) {
      setErrors({ ...errors, verifyPassword: true });
      setOnClicking(false);
      return;
    }
    const response = await resetPassword(data);
    if (response?.statusCode === CALL_SUCCESS) {
      setIsResetPassword(true);
      setOnClicking(false);
    } else {
      if (response?.message === 'New password must be different from your last 6 passwords.') {
        setValid(false);
        setOnClicking(false);
      }
    }
    setOnClicking(false);
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setErrors({ newPassword: false, verifyPassword: false });
    setValid(true);
    setOnClicking(false);
    if (e.target.name === 'verify') {
      setVerifyPassword(e.target.value);
    } else {
      setData({ ...data, newPassword: e.target.value });
    }
  }
  function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
  }
  function handleClickShowVerify() {
    setShowVerify(!showVerify);
  }
  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }
  if (!isResetPassword)
    return (
      <Grid container>
        <LoadingCustom loading={onClicking} loadingIcon />
        <Grid item xs={12} className={classes.left}>
          <Box display="flex" width={'100%'} height={'100%'}>
            <NYSImage src={SignUpBackground} objectFitContain />
            <Link href="/">
              <Logo src={LogoJSA} />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={12} className={classes.right}>
          <Box
            width={'100%'}
            height={'100%'}
            p={2}
            pl={isTablet ? '100px' : '16px'}
            pr={isTablet ? '100px' : '16px'}
            pt={'52px'}
            display="flex"
            bgcolor="#F9F9F4"
            minHeight={isTablet ? '996px' : '588px'}
            flexDirection={'column'}
          >
            <Box display={'flex'} flexDirection={'column'}>
              <TitleResetPassword isMobile={isMobile}>Reset Password</TitleResetPassword>
              <TextBox isMobile={isMobile}>
                <p>
                  Hi Nanyang (NSAAID: <span>{userId}</span>)
                </p>
                <p>Let’s start reset your password!</p>
              </TextBox>
            </Box>
            <Form onSubmit={handleSubmit}>
              <InputField
                customBoxTitle={{ marginBottom: '10px' }}
                title="Enter your new password"
                hiddenLabel
                fullWidth
                type={showPassword ? 'text' : 'password'}
                name="password"
                onChange={handleChange}
                notice
                noticeYellow
                noticeTitle={<Typography color="inherit">{ERROR_PASSWORD_MESSAGE}</Typography>}
                autoComplete="off"
                InputProps={{
                  inputProps: {
                    maxLength: MAXIMUM_PASSWORD_LENGTH,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.newPassword}
                errormessage={errors.newPassword && ERROR_PASSWORD_MESSAGE}
              />
              {!valid && <ErrorMess>{ERROR_SAME_PASSWORD_MESSAGE}</ErrorMess>}
              <InputField
                customBoxTitle={{ marginBottom: '10px' }}
                title="Verify your password"
                hiddenLabel
                fullWidth
                notice
                noticeYellow
                noticeTitle={<Typography color="inherit">{ERROR_PASSWORD_MESSAGE}</Typography>}
                type={showVerify ? 'text' : 'password'}
                name="verify"
                onChange={handleChange}
                autoComplete="off"
                InputProps={{
                  inputProps: {
                    maxLength: MAXIMUM_PASSWORD_LENGTH,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowVerify} onMouseDown={handleMouseDownPassword} edge="end">
                        {showVerify ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.verifyPassword}
                errormessage={errors.verifyPassword && ERROR_VERIFY_MESSAGE}
              />
              <Box mb={4} mt={4} display="flex" justifyContent="center">
                <SubmitButton type="submit" disable={onClicking}>
                  SUBMIT
                </SubmitButton>
              </Box>
            </Form>
          </Box>
        </Grid>
      </Grid>
    );
  return <ResetPasswordSuccess message={RESET_PASSWORD.resetPassword} />;
}

export default ResetPasswordMobile;
