import * as Yup from 'yup';

import { PROFILE_VALIDATION_MESSAGE } from 'src/constants/validation-message.constant';
import { IEvent } from 'src/services/events';
import { IUser } from 'src/services/users';
import { emailIsValid } from 'src/utils/common';

const THIS_FIELD_IS_REQUIRED = 'This field is required';
const SURNAME_MUST_LESS_MORE_50_CHARACTER = 'Surname must less more 50 character';
const TICKET_TYPE_1 = 1;
const THIS_FIELD_MUST_BE_POSITIVE = 'Total number of ticket must be greater than 0';
const VALUE_CANNOT_EXCEED_TOTAL_NUMBER_OF_TICKET = 'Value can not exceed total number of tickets';

const YES = 'yes';
const NO = 'no';

const isYearMustBeNumber = (val: any) => (Number(val) ? true : false);

const GRADUATE_YEAR_VALIDATE_NORMAL_USER = Yup.string()
  .required(PROFILE_VALIDATION_MESSAGE.GRADUATE_YEAR_EMPTY)
  .test('validate_year', PROFILE_VALIDATION_MESSAGE.GRADUATE_YEAR, isYearMustBeNumber);

export const REGISTRATION_FORMS_VALIDATION = ({
  includeMeals,
  isRequiredTicket,
  isMemberOnly,
  isAdmin,
}: {
  includeMeals?: boolean;
  isRequiredTicket?: boolean;
  isMemberOnly?: boolean;
  isAdmin?: boolean;
}) => {
  return Yup.object({
    surName: Yup.string().max(50, SURNAME_MUST_LESS_MORE_50_CHARACTER).required(PROFILE_VALIDATION_MESSAGE.SURNAME),
    givenName: Yup.string()
      .max(50, SURNAME_MUST_LESS_MORE_50_CHARACTER)
      .required(PROFILE_VALIDATION_MESSAGE.GIVEN_NAME),
    email: Yup.string()
      .max(50)
      .email(PROFILE_VALIDATION_MESSAGE.EMAIL)
      .test('EMAIL', PROFILE_VALIDATION_MESSAGE.EMAIL, (value: any) => {
        return emailIsValid(value);
      })
      .required(PROFILE_VALIDATION_MESSAGE.EMAIL),
    areaCode: Yup.string().required(PROFILE_VALIDATION_MESSAGE.AREA_CODE),
    mobilePhone: Yup.number().required(THIS_FIELD_IS_REQUIRED).typeError(PROFILE_VALIDATION_MESSAGE.PHONE_NUMBER),

    isCheckPeriodStudyInNYK: Yup.boolean().optional(),
    isCheckPeriodStudyInNYP: Yup.boolean().optional(),
    isCheckPeriodStudyInNPP: Yup.boolean().optional(),
    isCheckPeriodStudyInNYGH: Yup.boolean().optional(),

    periodStudyInNPP: Yup.string().when('isCheckPeriodStudyInNPP', {
      is: true,
      then: GRADUATE_YEAR_VALIDATE_NORMAL_USER,
      otherwise: Yup.string().nullable(),
    }),
    periodStudyInNYGH: Yup.string().when('isCheckPeriodStudyInNYGH', {
      is: true,
      then: GRADUATE_YEAR_VALIDATE_NORMAL_USER,
      otherwise: Yup.string().nullable(),
    }),
    periodStudyInNYK: Yup.string().when('isCheckPeriodStudyInNYK', {
      is: true,
      then: GRADUATE_YEAR_VALIDATE_NORMAL_USER,
      otherwise: Yup.string().nullable(),
    }),
    periodStudyInNYP: Yup.string().when('isCheckPeriodStudyInNYP', {
      is: true,
      then: GRADUATE_YEAR_VALIDATE_NORMAL_USER,
      otherwise: Yup.string().nullable(),
    }),
    isChildAttend: Yup.mixed().when(
      [
        'isCheckPeriodStudyInNYK',
        'isCheckPeriodStudyInNPP',
        'isCheckPeriodStudyInNYP',
        'isCheckPeriodStudyInNYGH',
        'attendedNsaa',
      ],
      {
        is: (
          isCheckPeriodStudyInNYK: any,
          isCheckPeriodStudyInNPP: any,
          isCheckPeriodStudyInNYP: any,
          isCheckPeriodStudyInNYGH: any,
          attendedNsaa: any,
        ) => {
          if (attendedNsaa === NO) {
            return true;
          } else {
            return (
              isCheckPeriodStudyInNYK ||
              isCheckPeriodStudyInNPP ||
              isCheckPeriodStudyInNYP ||
              isCheckPeriodStudyInNYGH ||
              attendedNsaa === NO
            );
          }
        },
        then: Yup.string().notRequired(),
        otherwise: Yup.string().required(THIS_FIELD_IS_REQUIRED),
      },
    ),
    totalTicket: Yup.number()
      .typeError(THIS_FIELD_IS_REQUIRED)
      .required(THIS_FIELD_IS_REQUIRED)
      .test(
        'VALUE_CANNOT_EXCEED_TOTAL_NUMBER_OF_TICKET',
        VALUE_CANNOT_EXCEED_TOTAL_NUMBER_OF_TICKET,
        function (value: any) {
          const ticketForStandardMeal = +this.parent.ticketForStandardMeal;
          const ticketForVegetarian = +this.parent.ticketForVegetarian;
          const ticketForHalal = +this.parent.ticketForHalal;
          const include_meal = this.parent.include_meal;
          const total_ticket = include_meal
            ? ticketForStandardMeal + ticketForVegetarian + ticketForHalal
            : +this.parent.totalTicket;
          return total_ticket <= this.parent.max_guest;
        },
      )
      .test('THIS_FIELD_MUST_BE_POSITIVE', THIS_FIELD_MUST_BE_POSITIVE, function (value: any) {
        const ticketForStandardMeal = +this.parent.ticketForStandardMeal;
        const ticketForVegetarian = +this.parent.ticketForVegetarian;
        const ticketForHalal = +this.parent.ticketForHalal;
        const include_meal = this.parent.include_meal;
        const total_ticket = include_meal
          ? ticketForStandardMeal + ticketForVegetarian + ticketForHalal
          : +this.parent.totalTicket;

        return total_ticket >= 1;
      }),
    ticketForStandardMeal: Yup.mixed().when(['include_meal'], {
      is: (value: any) => {
        return value || includeMeals;
      },
      then: Yup.number().required(THIS_FIELD_IS_REQUIRED),
      otherwise: Yup.number().notRequired(),
    }),
    ticketForVegetarian: Yup.mixed().when(['include_meal'], {
      is: (value: any) => {
        return value || includeMeals;
      },
      then: Yup.number().required(THIS_FIELD_IS_REQUIRED),
      otherwise: Yup.number().notRequired(),
    }),
    ticketForHalal: Yup.mixed().when(['include_meal'], {
      is: (value: any) => {
        return value || includeMeals;
      },
      then: Yup.number().required(THIS_FIELD_IS_REQUIRED),
      otherwise: Yup.number().notRequired().optional().nullable(),
    }),
    numberAttendedAge: Yup.mixed().when(['ticket_type'], {
      is: (type: any) => {
        return type === TICKET_TYPE_1 || isMemberOnly || isAdmin;
      },
      then: Yup.string().notRequired(),
      otherwise: Yup.number().required(THIS_FIELD_IS_REQUIRED),
    }),
  });
};

export const REGISTRATION_FORMS_INIT_VALUE = (event: IEvent, userProfile?: IUser) => {
  return {
    eventId: event?.id || '',
    eventTitle: event?.title || '',
    surName: userProfile?.surname || '',
    givenName: userProfile?.givenName || '',
    email: userProfile?.emailAddress || '',
    areaCode: userProfile?.areaCode || 'SG',
    mobilePhone: userProfile?.mobilePhone || '',
    attendedNsaa:
      userProfile?.periodStudyInNYK ||
      userProfile?.periodStudyInNPP ||
      userProfile?.periodStudyInNYP ||
      userProfile?.periodStudyInNYGH
        ? YES
        : NO,
    periodStudyInNYK: userProfile?.periodStudyInNYK || '',
    isCheckPeriodStudyInNYK: userProfile?.periodStudyInNYK ? true : false,
    periodStudyInNPP: userProfile?.periodStudyInNPP || '',
    isCheckPeriodStudyInNPP: userProfile?.periodStudyInNPP ? true : false,
    periodStudyInNYP: userProfile?.periodStudyInNYP || '',
    isCheckPeriodStudyInNYP: userProfile?.periodStudyInNYP ? true : false,
    periodStudyInNYGH: userProfile?.periodStudyInNYGH || '',
    isCheckPeriodStudyInNYGH: userProfile?.periodStudyInNYGH ? true : false,
    totalTicket: 0,
    ticketForStandardMeal: 0,
    ticketForVegetarian: 0,
    ticketForHalal: 0,
    numberAttendedAge: 0,
    include_meal: !!event?.include_meal,
    discount_for_age: event?.discount_for_age,
    ticket_type: event?.ticket_type || '',
    totalPayment: '',
    require_ticket: event?.require_ticket,
    is_public: event?.is_public,
    max_guest: event?.max_guest,
  };
};
