import React from 'react';

interface ICloseIcon {
  width?: number;
  height?: number;
}

const CloseIcon = ({ width = 24, height = 24, ...props }: ICloseIcon & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M19 5L5 19M5 5l14 14"
        stroke={props.fill}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CloseIcon;
