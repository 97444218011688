import React from 'react';

import { Box, FormControl, Grid, Link, makeStyles, RadioGroup } from '@material-ui/core';

import { CustomButton, NASSRadio } from 'src/components';
import LogoJSA from 'src/medias/images/main-nsaa-logo.png';
import SignUpBackground from 'src/medias/images/signup-background.jpg';

import { Logo, Note, NYSImage, TitleSmall } from '../../login';

import { CustomFormControlLabel, LinkButton, TextBold } from './types';

const IS_OLD_ENOUGH = 'yes';
const IS_NOT_OLD_ENOUGH = 'no';

const useStyles = makeStyles((theme) => ({
  left: {
    minHeight: 966,
    position: 'relative',
  },
}));

interface IConfirmOldStep {
  _onConfirmOld(): void;
  isOldEnough: string;
  setIsOldEnough: React.Dispatch<React.SetStateAction<string>>;
}

const ConfirmOldStep = ({ _onConfirmOld, isOldEnough, setIsOldEnough }: IConfirmOldStep) => {
  const classes = useStyles();

  const _onChange = () => (event: { target: { value: string; name: string } }) => {
    setIsOldEnough(event.target.value);
  };

  function _onClickLogin() {
    window.location.href = '/login';
  }

  return (
    <Grid container>
      <Grid item xs={6} className={classes.left}>
        <Box display="flex" width={'100%'} height={'100%'}>
          <NYSImage src={SignUpBackground} objectFitContain />
          <Link href="/">
            <Logo src={LogoJSA} />
          </Link>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          width={'100%'}
          height={'100%'}
          alignItems="center"
          justifyContent="center"
          display="flex"
          pt={'150px'}
          flexDirection="column"
          bgcolor="#f9f9f9"
        >
          <Box textAlign="center">
            <TitleSmall>Welcome to Nanyang Schools Alumni Association!</TitleSmall>
            <Box>
              <TextBold mt={false}>Dear alumni, please tell us some information:</TextBold>
              <TextBold mt={true}>Are you over 21 years old?</TextBold>
            </Box>
            <Box mt={2} mb={1} justifyContent="center">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="confirm-old"
                  value={isOldEnough}
                  name="radio-buttons-group"
                  onChange={_onChange()}
                  row
                >
                  <CustomFormControlLabel value={IS_OLD_ENOUGH} control={<NASSRadio />} label="Yes" />
                  <CustomFormControlLabel value={IS_NOT_OLD_ENOUGH} control={<NASSRadio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box mb={4} mt={4} display="flex" justifyContent="center">
              <CustomButton onClick={_onConfirmOld}>Next</CustomButton>
            </Box>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Note>
                Already have an account?
                <LinkButton onClick={_onClickLogin} variant="text">
                  Log in
                </LinkButton>
              </Note>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConfirmOldStep;
