import { IResponse } from 'src/types';

import { rootApi } from '../api';

const uploadApi = rootApi.injectEndpoints({
  endpoints: ({ mutation, query }) => ({
    uploadImage: mutation<IResponse<{ name: string; createdAt: number }>, FormData>({
      query: (body) => ({
        url: '/fileupload',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useUploadImageMutation } = uploadApi;
