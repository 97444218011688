import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
import styled, { css } from 'styled-components';

import { CustomButton } from 'src/components';
import CommonSlide from 'src/components/sliders';
import { FORMAT_DATE_DMY } from 'src/constants/common';
import EventItemMobile from 'src/pages/events/EventItemMobile';
import { IResponsive } from 'src/pages/home/homepageStyled';
import { IEvent } from 'src/services/events/events.interface';
import { IMeta } from 'src/types';
import useResponsive from 'src/utils/responsive';

import CardEvent from './CardEvent';

interface IEventListProps {
  onViewMoreEvent: () => void;
  events: IEvent[];
  meta: IMeta;
}

function EventList({ events, meta, onViewMoreEvent }: IEventListProps) {
  const [splitEvents, setSplitEvents] = useState<any>([]);
  const responsive = useResponsive();
  const DEFAULT_EVENT_PHOTO = 'https://gcdn.pbrd.co/images/kgk0b3FBYfYJ.png';

  useEffect(() => {
    let array = [];
    if (events) {
      let i,
        j,
        temporary,
        chunk = 3;
      for (i = 0, j = events.length; i < j; i += chunk) {
        temporary = events.slice(i, i + chunk);
        array.push(temporary);
      }
    }
    setSplitEvents(array);
  }, [events]);
  const renderEvent = (event: IEvent) => {
    return <EventItemMobile event={event} />;
  };
  return (
    <Box display={'flex'} flex={1} flexDirection={'column'}>
      {responsive.isTabletOrMobile ? (
        splitEvents?.map((splitPost: any) => {
          return (
            <NsaaEventContainer>
              <CommonSlide data={splitPost} render={renderEvent} />
            </NsaaEventContainer>
          );
        })
      ) : (
        <Box maxWidth={1440} mx={'auto'} pt={8}>
          <PostContainer
            postLength={events && events?.length > 0 ? true : false}
            display="flex"
            flexWrap="wrap"
            style={{ gap: 24 }}
          >
            {events?.map((event) => {
              return (
                <CardEvent
                  key={event.id}
                  title={event.title}
                  image={event.cover_photo || DEFAULT_EVENT_PHOTO}
                  eventId={event.id}
                  date={moment(+event.start_time).format(FORMAT_DATE_DMY)}
                />
              );
            })}
          </PostContainer>
        </Box>
      )}
      {(events.length === 0 || splitEvents.length === 0) && (
        <NoSearchData>There is no event at the moment. Please come back later</NoSearchData>
      )}
      {(events.length > 0 || splitEvents.length > 0) && (
        <Box justifyContent="center" display="flex" flex={1} mb={12.5} mt={5}>
          <CustomButton
            disabled={meta?.currentPage === meta?.totalPages}
            style={{ width: 250, borderRadius: 7 }}
            onClick={onViewMoreEvent}
          >
            MORE EVENTS
          </CustomButton>
        </Box>
      )}
    </Box>
  );
}

export default EventList;

const PostContainer = styled(Box)<{ postLength?: boolean }>`
  ${({ theme, postLength }) => ({})}
`;

export const NoSearchData = styled(Box)`
  display: flex;
  justify-content: center;
  font-size: 24;
`;

const NsaaEventContainer = styled(Grid)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    && {
      width: 100%;
      height: auto;
      margin: auto;
      margin-bottom: ${responsive?.isMobile ? '64px' : '0px'};
    }
  `}
`;
