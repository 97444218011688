import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import { CALL_SUCCESS } from 'src/constants/common';
import { IEvent } from 'src/services/events';
import { getResponseListQuiz } from 'src/services/quiz';
import { IQuizResponseList } from 'src/services/quiz/quiz.interface';
import { IMeta } from 'src/types';

import Listing from './listing';

interface IResponseQuiz {
  event: IEvent | null;
}

const ResponseList = ({ event }: IResponseQuiz) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [quiz, setQuiz] = useState<IQuizResponseList[]>([]);
  const [data, setData] = useState<IMeta>();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalGuest, setTotalGuest] = useState(0);

  async function getData({ currentPage, limit }: { currentPage: number; limit: number }) {
    setLoading(true);
    if (event) {
      const response = await getResponseListQuiz(event.id, {
        currentPage,
        limit,
      });
      if (response?.statusCode === CALL_SUCCESS) {
        setQuiz(response?.data?.items);
        setData(response?.data?.meta);
        if (response?.data?.totalGuest) {
          setTotalGuest(response?.data?.totalGuest);
        }
      }
    }

    setLoading(false);
  }

  useEffect(() => {
    getData({
      currentPage,
      limit,
    });
  }, [currentPage, limit]);

  return (
    <Box mt={3} mb={3}>
      <Listing
        quiz={quiz}
        data={data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        limit={limit}
        setLimit={setLimit}
        totalGuest={totalGuest}
      />
    </Box>
  );
};

export default ResponseList;
