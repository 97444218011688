import React, { useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import ErrorSnackBar from 'src/components/snackBar/error';

import { IPost, IPostCmsNews } from '../../../../services/news';

import ListCMSNews from './ListCMSNews/ListCMSNews';
import SearchCMSNews from './SearchCMSNews/SearchCMSNews';
import { defaultSearchCMSNews, IErrorMessage, ISearchNewsCMS } from './type';

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  padding: 8,
}));

const Title = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
}));
export const News = ({ setMenuActive, setRightType, setPost }: any) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState<ISearchNewsCMS>(defaultSearchCMSNews);
  const [searchValue, setSearchValue] = useState<ISearchNewsCMS>(defaultSearchCMSNews);
  const [isOnSearch, setIsOnSearch] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<IErrorMessage>();
  const [data, setData] = useState<IPostCmsNews[] | []>([]);
  const _onSearch = (searchData: ISearchNewsCMS) => () => {
    setIsOnSearch(true);
    setSearch({ ...searchData });
  };
  const _onReset = async () => {
    setLoading(true);
    setIsOnSearch(true);

    setSearch(defaultSearchCMSNews);
    setSearchValue(defaultSearchCMSNews);
    setIsOnSearch(false);
    setLoading(false);
  };

  function _onCreatePost() {
    setMenuActive('Create News');
    setRightType('create');
  }

  const _onClickView = (post: IPost) => () => {
    setPost(post);
    setMenuActive('Create News');
    setRightType('detailNews');
  };
  const handleCloseSnackBar = () => {
    setErrorMessage({
      isError: false,
      message: '',
    });
  };
  return (
    <Box minHeight={500} p={4} bgcolor="white" boxShadow={'1px 1px 4px #9E9E9E'} borderRadius={4}>
      <ErrorSnackBar
        open={errorMessage?.isError || false}
        handleClose={handleCloseSnackBar}
        message={errorMessage?.message}
      />
      <LoadingCustom loading={loading} loadingIcon />
      <CustomBox>
        <Title>Create News</Title>
      </CustomBox>
      <SearchCMSNews loading={loading} _onSearch={_onSearch} _onReset={_onReset} _setSearchValue={setSearchValue} />
      <ListCMSNews
        searchData={search}
        loading={loading}
        _onCreatePost={_onCreatePost}
        _onClickView={_onClickView}
        setLoading={setLoading}
        setSearch={setSearch}
        _setErrorMessage={setErrorMessage}
        isOnSearch={isOnSearch}
        _setIsOnSearch={setIsOnSearch}
        searchValue={searchValue}
        data={data}
        setData={setData}
      />
      <Box />
    </Box>
  );
};
