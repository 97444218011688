import React, { useEffect } from 'react';

import { AppBar, Box, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import useFetchMe from 'src/hooks/use-fetch-me';
import BasicMenu from 'src/layouts/main/components/BasicMenu';
import { ButtonNavLink, LoginBtn, SignupBtn } from 'src/layouts/main/components/header';
import { menuUser, menuUserMobile } from 'src/layouts/main/type';
import headerLogo from 'src/medias/images/main-nsaa-logo.png';
import { IResponsive } from 'src/pages/home/homepageStyled';
import { selectLoginState } from 'src/store/authentication/selector';
import { logout } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';
import useResponsive from 'src/utils/responsive';

import { menuAboutUs, menuMembership } from '../type';

// import BasicMenu from './BasicMenu';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    color: '#979168',
  },
}));
const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.yellow?.[900],
  height: theme.spacing(3.75),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: '68px',
  paddingLeft: '68px',
}));

const Text = styled(Typography)<{ bold?: boolean }>`
  ${({ theme, bold = true }) => ({
    color: theme.palette.common.black,
    ...theme.fonts.textSSBold,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontWeight: bold ? 700 : 300,
    fontSize: 14,
  })}
  span {
    ${({ theme }) => ({
      color: theme.palette.common.black,
      ...theme.fonts.textSS,
    })}
  }
`;

const ToolbarCustom = styled(Toolbar)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    && {
      display: flex;
      justify-content: space-between;
      padding: ${responsive && responsive.isMobile ? '24px 20px 16px 20px' : '32px 68px 32px 68px'};
      height: 120px;
    }
  `};
`;

const BoxStyled = styled(Box)<{ active?: boolean }>`
  ${({ theme, active }) => css`
    position: relative;
    &:not(:first-child) {
      margin-left: 22px;
    }
    ${active &&
    css`
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
      .MuiButtonBase-root {
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 100%;
          height: 5px;
          background-color: ${theme.palette.yellow?.[900]};
          bottom: 0;
          left: 0;
        }
      }
    `}
  `}
`;
const BoxText = styled(Box)`
  gap: 10px;
`;
export const Header = () => {
  const history = useHistory();
  const path = history.location.pathname.slice(3);
  const classes = useStyles();
  const responsive = useResponsive();
  const [, setOpenDrawer] = React.useState(false);
  const isLogin = useSelector(selectLoginState);
  const dispatch = useAppDispatch();
  const { me } = useFetchMe();

  function _onLogout() {
    dispatch(logout());
  }

  useEffect(() => {
    setOpenDrawer(false);
  }, [responsive.isDesktopOrLaptop]);

  // const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
  //   setOpenDrawer(open);
  // };
  const _onClickMenu = () => {
    setOpenDrawer(true);
  };
  return (
    <Box>
      {responsive.isMobile || responsive.isTablet ? (
        <Box>
          <AppBarStyled>
            <ToolbarCustom responsive={responsive}>
              {/* <SideMenuChinese
                open={openDrawer}
                toggleDrawer={toggleDrawer}
                isMobile={responsive.isMobile}
                isTablet={responsive.isTablet}
              /> */}
              <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu">
                <MenuIcon onClick={_onClickMenu} />
              </IconButton>
              <Box>
                <NavLink to="/cn">
                  <LogoHeader src={headerLogo} alt="" />
                </NavLink>
              </Box>
            </ToolbarCustom>
          </AppBarStyled>
        </Box>
      ) : (
        <AppBarStyled>
          <Container>
            <Box>
              <Text>Nanyang Schools Alumni Association | 南洋中小幼校友会</Text>
            </Box>
            <BoxText display={'flex'}>
              <NavLinkStyled to={path}>
                <Text bold={false}>
                  <span>English</span>
                </Text>
              </NavLinkStyled>
              <Text>|</Text>
              <NavLinkStyled to="/cn">
                <Text bold={false}>中文</Text>
              </NavLinkStyled>
            </BoxText>
          </Container>
          <ToolbarCustom>
            <Box>
              <NavLink to="/cn">
                <LogoHeader src={headerLogo} alt="" />
              </NavLink>
            </Box>
            <BoxCustom sx={{ flexGrow: 1 }} color="black" display="flex" height={'100%'}>
              <BoxStyled>
                <NavLinkStyled exact activeClassName="header__nav-active" to="/cn">
                  <BasicMenu text={'主页'} link="/cn" />
                </NavLinkStyled>
              </BoxStyled>
              <BoxStyled active={window.location.pathname.includes('/cn/about-us')}>
                <BasicMenu text={'关于校友会'} menuItem={menuAboutUs} />
              </BoxStyled>
              <BoxStyled>
                <NavLinkStyled activeClassName="header__nav-active" to="/cn/events">
                  <BasicMenu text={'活动与项目'} link="/events" />
                </NavLinkStyled>
              </BoxStyled>
              <BoxStyled>
                <NavLinkStyled activeClassName="header__nav-active" to="/cn/news">
                  <BasicMenu text={'消息'} link="/cn/news" />
                </NavLinkStyled>
              </BoxStyled>
              <BoxStyled active={window.location.pathname.includes('membership')}>
                <BasicMenu text={'会籍'} menuItem={menuMembership} />
              </BoxStyled>
              <BoxStyled>
                <NavLinkStyled activeClassName="header__nav-active" to="/cn/contact-us">
                  <BasicMenu text={'联络我们'} link="/cn/contact-us" />
                </NavLinkStyled>
              </BoxStyled>
            </BoxCustom>
            {!isLogin ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <ButtonNavLink to="/login">
                  <LoginBtn>登录</LoginBtn>
                </ButtonNavLink>
                <ButtonNavLink to="/signup">
                  <SignupBtn white>报名</SignupBtn>
                </ButtonNavLink>
              </Box>
            ) : (
              <BasicMenu
                extend={true}
                me={me!}
                menuItem={me?.canScanTicket ? menuUserMobile : menuUser}
                logout={_onLogout}
                isLogin={isLogin}
              />
            )}
          </ToolbarCustom>
        </AppBarStyled>
      )}
    </Box>
  );
};

const LogoHeader = styled.img`
  && {
    height: 90px;
    width: 70px;
  }
`;
const BoxCustom = styled(Box)`
  && {
    padding-left: 5%;
  }
`;
const AppBarStyled = styled(AppBar)`
  && {
    background: ${({ theme }) => theme.palette.common.white};
    position: fixed;
    z-index: 999;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }
`;
const NavLinkStyled = styled(NavLink)`
  && {
    font-size: 20px;
    text-decoration: none;
    height: 100%;
  }
  ${({ theme }) => css`
    position: relative;
    &.header__nav-active {
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
      .MuiButtonBase-root {
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 100%;
          height: 5px;
          background-color: ${theme.palette.yellow?.[900]};
          bottom: 0px;
          left: 0;
        }
      }
    }
  `}
`;
