import React, { useState, useEffect } from 'react';

import { AnyObject, TNode } from '@udecode/plate';

import RichEditor from 'src/components/slateEditor';
import { CALL_SUCCESS } from 'src/constants/common';
import { getPost, IPost } from 'src/services/news';

export const PublicPost: React.FC = () => {
  const [value, setValue] = useState<TNode<AnyObject>[]>();
  const [post, setPost] = useState<IPost | null>();

  async function getData(id: string | number) {
    const response = await getPost(id);

    if (response?.statusCode === CALL_SUCCESS) {
      setValue(response?.data?.content);
      setPost(response.data);
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('id');
    if (id) {
      getData(id);
    }
  }, []);

  return <RichEditor type="post" value={value} setValue={setValue} post={post} />;
};
