import { Tab, Tabs, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const ProfileTab = styled(Tab)`
  ${({ theme }) => css`
    &:not(:last-child) {
      margin-right: 24px;
    }
    .MuiTab-wrapper {
      text-transform: capitalize;
      font-size: ${theme.fonts.textS.fontSize};
      font-weight: ${theme.fonts.textS.fontWeight};
      color: rgba(0, 0, 0, 0.6);
    }
    &.MuiTab-textColorInherit {
      opacity: 1;
    }
    &.Mui-selected .MuiTab-wrapper {
      color: ${theme.palette.yellow?.[900]};
      font-size: ${theme.fonts.textS.fontSize};
      font-weight: 700;
    }
  `}
`;
export const ProfileTabMobile = styled(Tab)`
  ${({ theme }) => css`
    display: 'flex';
    flex: 1;
    &:not(:last-child) {
      margin-right: 24px;
    }
    .MuiTab-wrapper {
      text-transform: capitalize;
      font-size: ${theme.fonts.textS.fontSize};
      font-weight: ${theme.fonts.textS.fontWeight};
      color: rgba(0, 0, 0, 0.6);
    }
    &.MuiTab-textColorInherit {
      opacity: 1;
    }
    &.Mui-selected .MuiTab-wrapper {
      color: ${theme.palette.yellow?.[900]};
      font-size: ${theme.fonts.textS.fontSize};
      font-weight: 700;
    }
  `}
`;
export const ProfileTabsContainer = styled(Tabs)<{ isTabletOrMobile: any }>`
  ${({ theme, isTabletOrMobile }) => css`
    background-color: white;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    .MuiTabs-indicator {
      height: 5px;
      background: ${isTabletOrMobile ? 'transparent' : theme.palette.yellow?.[900]};
    }
  `}
`;

export const TextProfileTabStyled = styled(Typography)`
  ${({ theme }) => css`
  text-align: 'center',
  vertical-align: 'center',
  font-size: 14,
  font-weight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
  `}
`;
