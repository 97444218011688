export const PROFILE_VALIDATION_MESSAGE = {
  SURNAME: 'This field is required',
  GIVEN_NAME: 'This field is required',
  CHINESE_NAME: 'This field is required',
  DOB: 'This field is required',
  GENDER: 'This field is required',
  EMAIL: 'Please fill in a correct email',
  AREA_CODE: 'This field is required',
  PHONE_NUMBER: 'This field must be a number',
  PASSWORD: 'This field is required',
  MAILING_ADDRESS: 'This field is required',
  POSTAL_CODE: 'Postal Code must be 6 number',
  SCHOOL_ATTENDED: 'This field is required',
  GRADUATE_YEAR_EMPTY: 'This field is required',
  GRADUATE_YEAR: 'Please fill a correct year',
  GRADUATE_YEAR_LESS_CURRENT_YEAR: 'Graduate year must be less than current year',
  GRADUATE_YEAR_LESS_MORE_BIRTH_YEAR: 'Year of graduation must be greater than birth year',
  NATIONALITY: 'This field is required',
  JOB_TITLE: 'This field is required',
  SKILLS: 'This field is required',
  OTHER_SKILLS: 'This field is required',
  COMPANY_NAME: 'This field is required',
  PASSWORD_STRONG:
    'Password must have at least 8 characters. The password must contain at least three character categories among the following: uppercase characters (A-Z), lowercase characters (a-z), and special characters (~!@#$%)',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  PASSWORD_AT_LATEST_ONE_UPPERCASE: 'Your password MUST have at least one UPPERCASE character.',
  PASSWORD_AT_LATEST_ONE_LOWERCASE: 'Your password MUST have at least one LOWERCASE character.',
  PASSWORD_AT_LATEST_ONE_SPECIAL_CHARACTER:
    'Your password MUST have at least one Special (Non-Alphanumeric) character (eg. ! @ # $ % ^ & * ).',
  PASSWORD_AT_LATEST_ONE_NUMBER: 'Your password MUST have at least one number.',
  PASSWORD_MUST_HAVE_SPACE: 'Your password MUST NOT contain spaces.',
  PASSWORD_LENGTH: 'Your password MUST be between 8 and 32 characters in length.',
  PASSWORD_TOOLTIP: `- Your password MUST be between 8 and 32 characters in length.
- Your password MUST have at least one UPPERCASE character.
- Your password MUST have at least one LOWERCASE character.
- Your password MUST have at least one number.
- Your password MUST have at least one Special (Non-Alphanumeric) character (eg. ! @ # $ % ^ & * ).
- Your password MUST NOT contain spaces.`,
};

export const EVENT_VALIDATION_MESSAGE = {
  EVENT_NAME_REQUIRED: 'This field is required',
  START_DATE_REQUIRED: 'This field is required',
  END_DATE_REQUIRED: 'This field is required',
  START_TIME_REQUIRED: 'This field is required',
  END_TIME_REQUIRED: 'This field is required',
  CATEGORY_REQUIRED: 'This field is required',
  COVER_PHOTO_REQUIRED: 'This field is required',
  DURATION_REQUIRED: 'This field is required',
  MAX_NUMBER_REQUIRED: 'This field is required',
  LOCATION_REQUIRED: 'This field is required',
};

export const TICKET_VALIDATION_MESSAGE = {
  PRICE_PER_TICKET_REQUIRED: 'This field is required',
  MAX_NUMBER_OF_TICKET_PURCHASE_REQUIRED: 'This field is required',
  TICKET_TYPE_REQUIRED: 'This field is required',
};
