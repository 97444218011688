import React, { useRef } from 'react';

import { Box, FormControlLabel, FormGroup, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SignaturePad from 'react-signature-canvas';

import { CheckboxCustom, InputField } from 'src/components';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { ISignUpReq } from 'src/services/auth';
import useResponsive from 'src/utils/responsive';

import { CustomButtonStyled, CustomLabel, CustomLabelMedium } from './types';

const useStyles = makeStyles((theme) => ({
  noticeRequire: {
    color: 'red',
    verticalAlign: 'middle',
  },
  signatureCanvas: {
    border: '1px solid rgba(51, 51, 51, 1)',
    borderRadius: 5,
    width: 381,
    height: 149,
  },
  sigImage: {
    width: 100,
    height: 39,
  },
}));

interface IPrivacyPolicy {
  data: ISignUpReq;
  setData: React.Dispatch<React.SetStateAction<ISignUpReq>>;
  youthMember?: boolean;
}

const PrivacyPolicy = ({ data, setData, youthMember }: IPrivacyPolicy) => {
  const { isTabletOrMobile } = useResponsive();
  const classes = useStyles();
  useScrollToTop();

  const sigCanvas = useRef<any>();

  const _onClearSignature = () => {
    sigCanvas.current?.clear();
    setData({ ...data, signature: null });
  };

  const _onSaveSignature = () => {
    setData({ ...data, signature: sigCanvas.current.getTrimmedCanvas().toDataURL('image/png') });
  };

  const _onConfirmPolicy = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, isConfirmPolicy: e.target.checked });
  };

  const _onParentConfirmPolicy = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, isParentConfirmPolicy: e.target.checked });
  };

  const termsAndPrivacy = `Nanyang Schools Alumni Association (NSAA) may collect, use and disclose my personal data, as provided in this application form, or otherwise obtained by NSAA as a result of my association with NSAA, in accordance with NSAA's data protection policy (available at www.nanyang.org.sg) and the Personal Data Protection Act 2012, for the following purposes: (A) processing of my membership application; (B) administering my association and membership with NSAA; (C) informing me of NSAA events and activities; (D) informing me of volunteering opportunities with Nanyang Schools and NSAA; (E) attending to my enquiries and feedback; (F) sending me NSAA publications; and (G) processing the membership confirmation letter for use in connection with the Phase 2A1 Primary One Registration Exercise.
  \n
When volunteering my services to NSAA and/or Nanyang Schools for specific events, NSAA may, for operational reasons, share my personal data, including my phone number and email address, with other volunteers and/or external organisations involved in such events.
  \n
I can withdraw my consent to the collection, use or disclosure of your personal data as stated in NSAA’s website (www.nanyang.org.sg). I understand that if I withdraw my consent, NSAA may not be able to provide some of its services to me or accept your participation or involvement in (as a volunteer or otherwise) of NSAA activities.
  \n
In the course of volunteering my services, I may come across personal data of others. I hereby give my commitment to safeguard the personal data I have access to according to the guidelines provided by NSAA. Amongst others, I will not wilfully disclose or remove any personal data without the explicit permission of NSAA.`;

  return (
    <Box>
      <Box>
        <InputField
          margin="dense"
          value={termsAndPrivacy}
          multiline
          rows={25}
          rowsMax={25}
          fullWidth
          InputProps={{ readOnly: true }}
        />
      </Box>
      <Box display="flex" alignItems="center" mt={2} mb={youthMember ? 4 : 14} flexDirection="column">
        {youthMember && (
          <Box mb={3}>
            <CustomLabel>
              Signature
              <Typography component="span" className={classes.noticeRequire}>
                *
              </Typography>
            </CustomLabel>
            {data.signature ? <img alt="Signature" className={classes.sigImage} src={data.signature} /> : null}
            <Box mt={2} display="flex" flexDirection={isTabletOrMobile ? 'column' : 'row'}>
              <SignaturePad ref={sigCanvas} canvasProps={{ className: classes.signatureCanvas }} />
              <Box
                mt={isTabletOrMobile ? 2 : 0}
                flexDirection={isTabletOrMobile ? 'row' : 'column'}
                display="flex"
                justifyContent="center"
              >
                <CustomButtonStyled onClick={_onSaveSignature}>Save</CustomButtonStyled>
                <Box mt={isTabletOrMobile ? 0 : 2}>
                  <CustomButtonStyled outlineButton onClick={_onClearSignature}>
                    Reset
                  </CustomButtonStyled>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        <FormGroup>
          <FormControlLabel
            control={<CheckboxCustom value={data.isConfirmPolicy} onChange={_onConfirmPolicy()} />}
            label={
              <CustomLabelMedium>
                I have read and agreed to the{' '}
                <Link style={{ textDecoration: 'underline', color: 'inherit' }} to={'/privacy-policy'}>
                  Privacy Policy
                </Link>
                <Box component={'span'} mx={0.5}>
                  &
                </Box>
                <Link style={{ textDecoration: 'underline', color: 'inherit' }} to={'/terms-of-use'}>
                  Terms Of Use
                </Link>
              </CustomLabelMedium>
            }
          />
          {youthMember && (
            <FormControlLabel
              control={<CheckboxCustom value={data.isParentConfirmPolicy} onChange={_onParentConfirmPolicy()} />}
              label={
                <CustomLabelMedium>
                  I, parent/guardian of the above mentioned student, do hereby give <br /> my consent to my child/ward
                  to join NSAA as a youth member.
                </CustomLabelMedium>
              }
            />
          )}
        </FormGroup>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
