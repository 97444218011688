import React from 'react';

interface ICalendarIcon {
  width?: number;
  height?: number;
}

const CalendarIcon = ({ width = 23, height = 23, ...props }: ICalendarIcon & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 23" xmlns="http://www.w3.org/2000/svg" {...props} fill="none">
      <path
        d="M14.375 2.875v3.833m-5.75-3.833v3.833m-4.792 3.834h15.334m0 0v7.666a1.917 1.917 0 01-1.917 1.917H5.75a1.917 1.917 0 01-1.917-1.917v-11.5c0-1.058.858-1.916 1.917-1.916h11.5c1.059 0 1.917.858 1.917 1.916v3.834z"
        stroke={props.fill || '#000'}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
