import React from 'react';

import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FORMAT_DATE_DMY } from 'src/constants/common';
import { IPost } from 'src/services/news';
import useResponsive from 'src/utils/responsive';

import { IResponsive } from '../../homepageStyled';

const DEFAULT_IMAGE = 'https://i.stack.imgur.com/y9DpT.jpg';
const Title = styled(Typography)(
  ({ theme }) =>
    css`
      ${{
        ...theme.fonts.header5,
        height: '60px',
      }}
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    `,
);

const Description = styled(Typography)(
  ({ theme }) => css`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    ${{
      ...theme.fonts.textS,
      marginTop: 12,
      wordWrap: 'break-word',
      color: theme.palette.gray?.[60],
    }}
  `,
);

const TextTime = styled(Typography)(
  ({ theme }) => css`
    ${{
      ...theme.fonts.textSs,
      textAlign: 'left',
      position: 'static',
      color: theme.palette.yellow?.[1000],
      marginBottom: 12,
    }}
  `,
);

const PostItem = styled(Box)<{ width?: string }>`
  ${({ theme, width }) => css`
    position: relative;
    width: ${width ? width : `auto`};
    box-sizing: border-box;
    background-color: #fafafa;
    border-radius: 3px;
    overflow: hidden;
    flex-grow: 1;
  `}
`;

const ThumbnailPost = styled.img`
  && {
    width: 100%;
    min-height: 250px;
    height: 250px;
    object-fit: contain;
    object-position: center center;
  }
`;

const NavLinkCustom = styled(NavLink)(({ theme }) => ({
  ...theme.fonts.header3,
  textDecoration: 'none',
  color: '#000000',
}));
const BoxContent = styled(Box)<{ responsive?: IResponsive }>(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 250px;
    background-color: #f9f9f4;
  `,
);

const Post = ({ post, width }: { post: IPost; width?: string }) => {
  const responsive = useResponsive();
  return (
    <PostItem key={post?.id} width={width}>
      <NavLink to={`/cn/news/${post.id}`} style={{ textDecoration: 'none' }}>
        <ThumbnailPost src={post?.coverPhoto || DEFAULT_IMAGE} />
        <BoxContent p={3} responsive={responsive}>
          <TextTime>{moment(+post?.publishTime!).format(FORMAT_DATE_DMY)}</TextTime>
          <NavLinkCustom to={`/cn/news/${post.id}`}>
            <Title>{post?.title}</Title>
          </NavLinkCustom>
          <Description>{post?.shortDescription || 'No description'}</Description>
        </BoxContent>
      </NavLink>
    </PostItem>
  );
};

export default Post;
