import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';
import React from 'react';

import { BorderAll } from '@styled-icons/material/BorderAll';
import { BorderBottom } from '@styled-icons/material/BorderBottom';
import { BorderClear } from '@styled-icons/material/BorderClear';
import { BorderLeft } from '@styled-icons/material/BorderLeft';
import { BorderRight } from '@styled-icons/material/BorderRight';
import { BorderTop } from '@styled-icons/material/BorderTop';
import { FontDownload } from '@styled-icons/material/FontDownload';
import { FormatAlignCenter } from '@styled-icons/material/FormatAlignCenter';
import { FormatAlignJustify } from '@styled-icons/material/FormatAlignJustify';
import { FormatAlignLeft } from '@styled-icons/material/FormatAlignLeft';
import { FormatAlignRight } from '@styled-icons/material/FormatAlignRight';
import { FormatBold } from '@styled-icons/material/FormatBold';
import { FormatColorText } from '@styled-icons/material/FormatColorText';
import { FormatItalic } from '@styled-icons/material/FormatItalic';
import { FormatListBulleted } from '@styled-icons/material/FormatListBulleted';
import { FormatListNumbered } from '@styled-icons/material/FormatListNumbered';
import { FormatUnderlined } from '@styled-icons/material/FormatUnderlined';
import { Highlight } from '@styled-icons/material/Highlight';
import { Image } from '@styled-icons/material/Image';
import { Keyboard } from '@styled-icons/material/Keyboard';
import { Link } from '@styled-icons/material/Link';
import { Looks3 } from '@styled-icons/material/Looks3';
import { Looks4 } from '@styled-icons/material/Looks4';
import { Looks5 } from '@styled-icons/material/Looks5';
import { Looks6 } from '@styled-icons/material/Looks6';
import { LooksOne } from '@styled-icons/material/LooksOne';
import { LooksTwo } from '@styled-icons/material/LooksTwo';
import {
  addColumn,
  addRow,
  deleteColumn,
  deleteRow,
  deleteTable,
  ELEMENT_ALIGN_CENTER,
  ELEMENT_ALIGN_JUSTIFY,
  ELEMENT_ALIGN_RIGHT,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_OL,
  ELEMENT_UL,
  insertTable,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_KBD,
  MARK_UNDERLINE,
  ToolbarAlign,
  ToolbarElement,
  ToolbarList,
  ToolbarMark,
  ToolbarTable,
  ToolbarLink,
  ToolbarImage,
  useStoreEditorRef,
  useEventEditorId,
  getPlatePluginType,
  MARK_HIGHLIGHT,
  MARK_COLOR,
  MARK_BG_COLOR,
  ToolbarColorPicker,
} from '@udecode/plate';

export const ToolbarButtonsBasicElements = () => {
  const editor = useStoreEditorRef(useEventEditorId('focus'));

  return (
    <>
      <ToolbarElement type={getPlatePluginType(editor, ELEMENT_H1)} icon={<LooksOne />} />
      <ToolbarElement type={getPlatePluginType(editor, ELEMENT_H2)} icon={<LooksTwo />} />
      <ToolbarElement type={getPlatePluginType(editor, ELEMENT_H3)} icon={<Looks3 />} />
      <ToolbarElement type={getPlatePluginType(editor, ELEMENT_H4)} icon={<Looks4 />} />
      <ToolbarElement type={getPlatePluginType(editor, ELEMENT_H5)} icon={<Looks5 />} />
      <ToolbarElement type={getPlatePluginType(editor, ELEMENT_H6)} icon={<Looks6 />} />
    </>
  );
};

export const ToolbarButtonsList = () => {
  const editor = useStoreEditorRef(useEventEditorId('focus'));

  return (
    <>
      <ToolbarList type={getPlatePluginType(editor, ELEMENT_UL)} icon={<FormatListBulleted />} />
      <ToolbarList type={getPlatePluginType(editor, ELEMENT_OL)} icon={<FormatListNumbered />} />
    </>
  );
};

export const ToolbarButtonsAlign = () => {
  const editor = useStoreEditorRef(useEventEditorId('focus'));

  return (
    <>
      <ToolbarAlign icon={<FormatAlignLeft />} />
      <ToolbarAlign type={getPlatePluginType(editor, ELEMENT_ALIGN_CENTER)} icon={<FormatAlignCenter />} />
      <ToolbarAlign type={getPlatePluginType(editor, ELEMENT_ALIGN_RIGHT)} icon={<FormatAlignRight />} />
      <ToolbarAlign type={getPlatePluginType(editor, ELEMENT_ALIGN_JUSTIFY)} icon={<FormatAlignJustify />} />
    </>
  );
};

export const ToolbarButtonsBasicMarks = () => {
  const editor = useStoreEditorRef(useEventEditorId('focus'));

  return (
    <>
      <ToolbarMark type={getPlatePluginType(editor, MARK_BOLD)} icon={<FormatBold />} />
      <ToolbarMark type={getPlatePluginType(editor, MARK_ITALIC)} icon={<FormatItalic />} />
      <ToolbarMark type={getPlatePluginType(editor, MARK_UNDERLINE)} icon={<FormatUnderlined />} />
    </>
  );
};

export const ToolbarKbd = () => {
  const editor = useStoreEditorRef(useEventEditorId('focus'));

  return <ToolbarMark type={getPlatePluginType(editor, MARK_KBD)} icon={<Keyboard />} />;
};

export const ToolbarHighlight = () => {
  const editor = useStoreEditorRef(useEventEditorId('focus'));

  return <ToolbarMark type={getPlatePluginType(editor, MARK_HIGHLIGHT)} icon={<Highlight />} />;
};

export const ToolbarButtonsTable = () => (
  <>
    <ToolbarTable icon={<BorderAll />} transform={insertTable} />
    <ToolbarTable icon={<BorderClear />} transform={deleteTable} />
    <ToolbarTable icon={<BorderBottom />} transform={addRow} />
    <ToolbarTable icon={<BorderTop />} transform={deleteRow} />
    <ToolbarTable icon={<BorderLeft />} transform={addColumn} />
    <ToolbarTable icon={<BorderRight />} transform={deleteColumn} />
  </>
);

export const ToolbarButtons = () => (
  <>
    <ToolbarButtonsBasicMarks />
    <ToolbarButtonsList />
    <ToolbarButtonsBasicElements />
    <ToolbarColorPicker pluginKey={MARK_COLOR} icon={<FormatColorText />} />
    <ToolbarColorPicker pluginKey={MARK_BG_COLOR} icon={<FontDownload />} />
    <ToolbarButtonsAlign />
    <ToolbarLink icon={<Link />} />
    <ToolbarImage icon={<Image />} />
    <ToolbarButtonsTable />
  </>
);
