import React, { useEffect, useState } from 'react';

import { Box, Button, Dialog, makeStyles, Tooltip, Typography } from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';

import ConfirmPopup from 'src/components/confirm';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import { changeEventStatus, cloneEventById, deleteEventById, getEventById, IEvent } from 'src/services/events';
import { delayFn } from 'src/utils/common';

import Preview from '../Preview';

interface IHeaderProps {
  event: IEvent;
  isDirtyForm: boolean;
  setEvent: React.Dispatch<React.SetStateAction<IEvent | undefined>>;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const Header = ({ event, isDirtyForm, setEvent, setDirty }: IHeaderProps) => {
  const [message, setMessage] = useState('');
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [isShowConfirm, setShowConfirmPopup] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [isOpenPreview, setOpenPreview] = useState(false);
  const ARE_YOU_SURE_DELETE_THIS_EVENT = 'Are you sure you want to delete this event';
  const ARE_YOU_SURE_PUBLISH_THIS_EVENT = 'Are you sure you want to publish this event?';
  const ARE_YOU_SURE_CANCEL_THIS_EVENT = 'Are you sure you want to cancel this event?';
  const DEFAULT_REDIRECT_MESSAGE =
    'Your updates are not saved. This action will cancel all of your updates, are you sure you want to proceed with this action?';
  const CANCELLED_STATUS = 'cancelled';
  const DRAFT_STATUS = 'draft';
  const PENDING_STATUS = 'pending';
  const [confirmMessage, setConfirmMessage] = useState('');
  const EVENT_MANAGEMENT_PATH = '/event-management';

  const [action, setAction] = useState({
    delete: false,
    publish: false,
    cancelled: false,
    clone: false,
  });

  const fetchData = async () => {
    const response = await getEventById(event.id!);
    if (response.statusCode === CALL_SUCCESS) {
      setEvent(response.data!);
    }
  };
  useEffect(() => {
    if (!action.cancelled && !action.delete && !action.publish) return;
    if (!action.delete) {
      fetchData();
    }
  }, [action]);

  const history = useHistory();
  const useStyles = makeStyles(() => ({
    paper: {
      maxWidth: '90%',
      borderRadius: 12,
      height: '90%',
      width: '80%',
    },
  }));

  const { paper } = useStyles();

  const handleBack = async () => {
    const EVENT_MANAGEMENT_PATH = '/event-management';
    history.push(EVENT_MANAGEMENT_PATH);
  };
  const handleClone = async () => {
    const response = await cloneEventById(event.id);

    if (response?.statusCode === CALL_SUCCESS) {
      setShowSnackBarSuccess(true);
      setShowConfirmPopup(false);
      setMessage(response.message);
      setActionLoading(false);
      const EVENT_MANAGEMENT_PATH = `/event-management/${response.data}/general`;
      delayFn(() => {
        window.location.pathname = EVENT_MANAGEMENT_PATH;
      }, 0);
    } else {
      setShowSnackBarError(() => true);
      setShowConfirmPopup(() => false);
      setMessage(response.message);
      setActionLoading(false);
    }
  };
  const handleDelete = async () => {
    setConfirmMessage(ARE_YOU_SURE_DELETE_THIS_EVENT);
    setShowConfirmPopup(true);
  };
  const handleCancel = async () => {
    setConfirmMessage(ARE_YOU_SURE_CANCEL_THIS_EVENT);
    setShowConfirmPopup(true);
  };
  const handlePublish = async () => {
    if (isDirtyForm) {
      const confirm = window.confirm(DEFAULT_REDIRECT_MESSAGE);
      if (confirm) {
        setOpenPreview(true);
        setDirty(false);
        return;
      }
    } else {
      setOpenPreview(true);
    }
  };

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };

  if (!event) return <HeaderContainer />;

  const handleToggleConfirmPopup = () => setShowConfirmPopup((status) => !status);
  const handleConfirmPopup = async () => {
    if (confirmMessage === ARE_YOU_SURE_DELETE_THIS_EVENT) {
      setActionLoading(true);
      const response = await deleteEventById(event.id);
      if (response?.statusCode === CALL_SUCCESS) {
        setAction((actions) => ({ ...actions, delete: true }));
        setShowSnackBarSuccess(true);
        setShowConfirmPopup(false);
        setMessage(response.message);
        setActionLoading(false);
        delayFn(() => history.push(EVENT_MANAGEMENT_PATH), 0);
      } else {
        setShowSnackBarError(() => true);
        setShowConfirmPopup(() => false);
        setMessage(response.message);
        setActionLoading(false);
      }
    }
    if (confirmMessage === ARE_YOU_SURE_PUBLISH_THIS_EVENT) {
      setActionLoading(true);
      const response = await changeEventStatus(event.id, PENDING_STATUS);
      if (response?.statusCode === CALL_SUCCESS) {
        setAction((actions) => ({ ...actions, publish: true }));
        setShowSnackBarSuccess(true);
        setShowConfirmPopup(false);
        setMessage(response.message);
        setActionLoading(false);
        setOpenPreview(false);
      } else {
        setShowSnackBarError(() => true);
        setShowConfirmPopup(() => false);
        setMessage(response.message);
        setActionLoading(false);
      }
    }
    if (confirmMessage === ARE_YOU_SURE_CANCEL_THIS_EVENT) {
      setActionLoading(true);
      const response = await changeEventStatus(event.id, CANCELLED_STATUS);
      if (response?.statusCode === CALL_SUCCESS) {
        setAction((actions) => ({ ...actions, cancelled: true }));
        setShowSnackBarSuccess(true);
        setShowConfirmPopup(false);
        setMessage(response.message);
        setActionLoading(false);
      } else {
        setShowSnackBarError(() => true);
        setShowConfirmPopup(() => false);
        setMessage(response.message);
        setActionLoading(false);
      }
    }
  };

  const SHOW_BACK = true;
  const SHOW_CANCEL = event.status === PENDING_STATUS;
  const SHOW_DELETE = event.status === DRAFT_STATUS || event.status === CANCELLED_STATUS;
  const SHOW_CLONE = true;
  const SHOW_PUBLISHED = event.status === DRAFT_STATUS && moment(+event.start_time) > moment();
  const handleTogglePreview = () => {
    setOpenPreview((status) => !status);
  };
  const handleCancelPreview = () => {
    setOpenPreview(false);
  };
  const handlePublishedPreview = () => {
    setConfirmMessage(ARE_YOU_SURE_PUBLISH_THIS_EVENT);
    setShowConfirmPopup(true);
  };
  return (
    <HeaderContainer>
      <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
      <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
      <ConfirmPopup
        open={isShowConfirm}
        onCancel={handleToggleConfirmPopup}
        onOk={handleConfirmPopup}
        okText="Yes"
        cancelText="No"
        title="Notification"
        btnCancelVariant="outlined"
        btnOkVariant={
          confirmMessage === ARE_YOU_SURE_DELETE_THIS_EVENT || confirmMessage === ARE_YOU_SURE_CANCEL_THIS_EVENT
            ? 'danger'
            : confirmMessage === ARE_YOU_SURE_DELETE_THIS_EVENT || confirmMessage === ARE_YOU_SURE_PUBLISH_THIS_EVENT
            ? 'filled'
            : 'outlined'
        }
        content={confirmMessage}
        hiddenBtnCancel={false}
        hiddenBtnOk={false}
        disableActionButton={actionLoading}
      />
      <HeaderLeft>
        <HeaderLeftTitle>Event ID: {event?.event_id}</HeaderLeftTitle>
        <SeparatorCircle />
        <Tooltip title={event?.title} placement="top">
          <HeaderLeftTitle>
            {event?.title?.length >= 50 ? event?.title?.slice(0, 50) + '...' : event?.title}
          </HeaderLeftTitle>
        </Tooltip>
        <SeparatorCircle />
        <HeaderLeftTitle>{event.status.toUpperCase()}</HeaderLeftTitle>
      </HeaderLeft>
      <HeaderRight>
        {SHOW_BACK && (
          <HeaderButton onClick={handleBack} variants="cancel">
            Back
          </HeaderButton>
        )}
        {SHOW_DELETE && (
          <HeaderButton onClick={handleDelete} variants="delete">
            Delete
          </HeaderButton>
        )}
        {SHOW_CANCEL && (
          <HeaderButton onClick={handleCancel} variants="delete">
            Cancel Event
          </HeaderButton>
        )}
        {SHOW_CLONE && (
          <HeaderButton onClick={handleClone} variants="clone">
            Clone
          </HeaderButton>
        )}
        {SHOW_PUBLISHED && (
          <HeaderButton onClick={handlePublish} variants="publish">
            Preview
          </HeaderButton>
        )}
      </HeaderRight>
      <Dialog classes={{ paper }} open={isOpenPreview} onClose={handleTogglePreview} disableBackdropClick>
        <Preview event={event} onCancel={handleCancelPreview} onPublished={handlePublishedPreview} />
      </Dialog>
    </HeaderContainer>
  );
};

export default Header;

const HeaderContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  min-height: 68px;
  box-sizing: border-box;
  align-items: center;
`;
const SeparatorCircle = styled(Box)`
  ${({ theme }) => css`
    width: 6px;
    height: 6px;
    background-color: ${theme.palette.yellow?.[900]};
    border-radius: 50%;
  `}
`;
const HeaderLeft = styled(Box)`
  display: flex;
  gap: 16px;
  align-items: center;
`;
const HeaderLeftTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header6.fontSize};
    font-weight: ${theme.fonts.header6.fontWeight};
    line-height: ${theme.fonts.header6.lineHeight};
  `}
`;
const HeaderRight = styled(Box)`
  display: flex;
  gap: 16px;
`;
const HeaderButton = styled(Button)<{ variants: 'cancel' | 'clone' | 'delete' | 'publish' }>`
  ${({ theme, variants }) => css`
    width: 120px;
    font-size: 16px;
    height: 45px;
    border-radius: 6px;
    text-transform: capitalize;
    ${(variants === 'cancel' || variants === 'clone') &&
    css`
      border: 1px solid ${theme.palette.yellow?.[900]};
      color: ${theme.palette.yellow?.[900]};
    `}
    ${variants === 'delete' &&
    css`
      border: 1px solid ${theme.palette.red?.[250]};
      color: ${theme.palette.red?.[250]};
    `}
    ${variants === 'publish' &&
    css`
      background: ${theme.palette.yellow?.[900]};
      color: white;
      &:hover {
        background: ${theme.palette.yellow?.[900]};
        color: white;
      }
    `}
  `}
`;
