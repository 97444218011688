import React from 'react';

import { Box, Tooltip } from '@material-ui/core';
import { Theme, withStyles } from '@material-ui/core/styles';
import { FormikProps } from 'formik';
import styled from 'styled-components';

import useFetchMe from 'src/hooks/use-fetch-me';
import noticeBlackIcon from 'src/medias/icons/notice-black.svg';
import { IEvent } from 'src/services/events';
import useResponsive from 'src/utils/responsive';

import { CheckoutContainer, Line, OrderLabel, OrderTitle } from './index.styled';

interface ICheckoutProps {
  formik: FormikProps<any>;
  event: IEvent;
}

const Checkout = ({ formik, event }: ICheckoutProps) => {
  const responsive = useResponsive();
  const [subTotal, setSubTotal] = React.useState(0);
  const [discount, setDiscount] = React.useState(0);
  const { loading, me: userProfile } = useFetchMe();
  const [total, setTotal] = React.useState(0);
  const TICKET_TYPE_1 = 1;
  const TICKET_TYPE_2 = 2;
  const PRICE_PER_TICKET = event.price_per_ticket!;
  const DEFAULT_DISCOUNT = event.discount;

  const TOTAL_TICKETS =
    +formik.values.ticketForStandardMeal + +formik.values.ticketForVegetarian + +formik.values.ticketForHalal ||
    formik.values.totalTicket;

  const ONE_HUNDRED = 100;

  const IS_TICKET_TYPE_1 = formik.values.ticket_type === TICKET_TYPE_1;
  const IS_TICKET_TYPE_2 = formik.values.ticket_type === TICKET_TYPE_2;

  React.useEffect(() => {
    if (
      IS_TICKET_TYPE_1 &&
      (userProfile?.accountType === 'lifetime-membership' || userProfile?.accountType === 'youth-member')
    ) {
      setSubTotal(TOTAL_TICKETS * PRICE_PER_TICKET);
      setDiscount(PRICE_PER_TICKET);
      setTotal(subTotal - discount);
      return;
    }

    if (IS_TICKET_TYPE_1 && !userProfile) {
      setSubTotal(TOTAL_TICKETS * PRICE_PER_TICKET);
      setDiscount(0);
      setTotal(subTotal - discount);
      return;
    }

    if (IS_TICKET_TYPE_2) {
      setSubTotal(TOTAL_TICKETS * PRICE_PER_TICKET);
      const discount_temp = PRICE_PER_TICKET * (DEFAULT_DISCOUNT! / ONE_HUNDRED) * +formik.values.numberAttendedAge;
      setDiscount(Math.floor(discount_temp));
      setTotal(subTotal - discount);
      return;
    }
  }, [IS_TICKET_TYPE_1, discount, subTotal, userProfile]);

  React.useEffect(() => {
    formik.setFieldValue('totalPayment', total.toString());
  }, [total]);

  if (loading) return <div />;

  return (
    <CheckoutContainer responsive={responsive}>
      <OrderTitle>ORDER INFORMATION</OrderTitle>
      <Box display="flex" justifyContent="space-between" mb={2.5}>
        <OrderLabel>Number of Ticket</OrderLabel>
        <OrderLabel>{TOTAL_TICKETS}</OrderLabel>
      </Box>
      <Box display="flex" justifyContent="space-between" mb={2.5}>
        <OrderLabel>Sub Total</OrderLabel>
        <OrderLabel>${subTotal}</OrderLabel>
      </Box>
      {!!true && (
        <Box display="flex" justifyContent="space-between" mb={2.5}>
          <OrderLabel>
            Discount
            <LightTooltip title={'Discount'} placement="top" arrow>
              <Icon src={noticeBlackIcon} />
            </LightTooltip>
          </OrderLabel>
          <OrderLabel>${discount}</OrderLabel>
        </Box>
      )}
      <Line />
      <Box display="flex" justifyContent="space-between" mb={2.5}>
        <OrderLabel bold>Total</OrderLabel>
        <OrderLabel bold>${total}</OrderLabel>
      </Box>
    </CheckoutContainer>
  );
};

export default Checkout;

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#E1DFC5',
    color: '#6B695D',
    fontSize: 16,
    borderRadius: 4,
    fontWeight: 400,
  },
  arrow: {
    color: '#E1DFC5',
  },
}))(Tooltip);

const Icon = styled.img(() => ({
  marginLeft: 8,
  marginTop: 2,
}));
