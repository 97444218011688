import { Box, Button, IconButton, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import styled, { css } from 'styled-components';

import { CustomButton } from 'src/components';
import EventBackgroundSource from 'src/medias/images/background-signup.png';

export const BoxContainer = styled(Box)`
  background: url(${EventBackgroundSource});
`;
export const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  padding: 8,
}));
export const SearchSection = styled(Box)`
  ${({ theme }) => css`
    padding: 25px 16px;
    margin-bottom: 34px;
    display: flex;
    gap: 26px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: white;
  `}
`;
export const ListingSection = styled(Box)`
  ${({ theme }) => css`
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: white;
  `}
`;
export const HeaderGroup = styled(Box)(({ theme }) => ({
  gap: '10px',
}));
export const BigTitle = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
  width: '100%',
}));

export const CustomButtonStyled = styled(CustomButton)(({ theme }) => ({
  width: '120px !important',
  height: '44px !important',
  marginLeft: 16,
}));
export const Title = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header4,
}));
export const Actions = styled(Box)`
  display: flex;
  gap: 5px;
`;
export const IconButtonStyled = styled(IconButton)(() => ({
  padding: 2,
}));
export const ImageIconStyled = styled.img(() => ({}));
export const ButtonStatus = styled(Button)<{
  variants?: 'confirmed' | 'pending' | 'expired';
}>`
  ${({ theme, variants }) => css`
    width: 112px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: ${variants === 'confirmed'
      ? theme.palette.yellow?.[1000]
      : variants === 'pending'
      ? theme.palette.yellow?.[150]
      : variants === 'expired'
      ? theme.palette.red?.[150]
      : null};
    font-size: 13px;
    color: white;
    text-transform: capitalize;
    cursor: default;
    &:hover {
      background-color: ${variants === 'confirmed'
        ? theme.palette.yellow?.[1000]
        : variants === 'pending'
        ? theme.palette.yellow?.[150]
        : variants === 'expired'
        ? theme.palette.red?.[150]
        : null};
    }
  `}
`;
export const CaptionStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  color: 'rgba(197, 190, 139, 1)',
}));
export const TypographyInfo = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: 'rgba(51, 51, 51, 1)',
  wordBreak: 'break-word',
}));
export const LabelStyled = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textSBold,
  width: 'max-content',
}));
export const CloseIconStyled = styled(CloseIcon)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#DC1A1A',
  width: '30px',
  height: '30px',
  borderRadius: '6px',
}));
export const CheckIconStyled = styled(CheckIcon)(({ theme }) => ({
  color: 'white',
  backgroundColor: '#C5BE8B',
  width: '30px',
  height: '30px',
  borderRadius: '6px',
}));
export const defaultSearch = {
  payerName: '',
  id: '',
  paymentType: '',
  status: '',
};
export const paymentType = [
  { key: 'buy-ticket', value: 'Buy event ticket' },
  { key: 'donation', value: 'Donation' },
  { key: 'pay-membership-fee', value: 'Pay membership fee' },
];
export const paymentStatus = [
  { key: 'confirmed', value: 'Confirmed' },
  { key: 'expired', value: 'Expired' },
  { key: 'pending', value: 'Pending' },
];

export const PaymentManagementContainer = styled(Box)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    ${responsive?.isTabletOrMobile &&
    css`
      width: 1440px;
    `}
    ${responsive?.isDesktopOrLaptop &&
    css`
      width: 100%;
    `}
  `}
`;
