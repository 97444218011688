import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import { CALL_SUCCESS } from 'src/constants/common';
import { getVolunteerByEventId, ISearchVolunteer, IVolunteer } from 'src/services/volunteer';

import { defaultSearchVolunteer } from '../../../types';

import Listing from './listing';
import SearchVolunteer from './search-volunteer';

interface IConfirmList {
  eventId: string;
  setVolunteerWaitList: React.Dispatch<React.SetStateAction<IVolunteer[]>>;
  volunteerWaitList: IVolunteer[];
  userSelected: IVolunteer[] | undefined;
}

const WaitList = ({ eventId, setVolunteerWaitList, volunteerWaitList, userSelected }: IConfirmList) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<ISearchVolunteer>(defaultSearchVolunteer);
  const [volunteers, setVolunteers] = useState<IVolunteer[]>([]);
  const [limit] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);

  async function getData({
    search,
    currentPage,
    limit,
  }: {
    search?: ISearchVolunteer;
    currentPage: number;
    limit: number;
  }) {
    setLoading(true);
    const response = await getVolunteerByEventId({
      eventId: eventId,
      type: 'waitlist',
      search,
      currentPage,
      limit,
    });
    if (response?.statusCode === CALL_SUCCESS) {
      setVolunteers(response?.data?.items);
    }

    setLoading(false);
  }

  const _onSearch = (searchData: ISearchVolunteer) => () => {
    setSearch(searchData);
    getData({
      search: searchData,
      currentPage: 1,
      limit,
    });
    setCurrentPage(1);
  };

  const _onReset = async () => {
    setLoading(true);
    setSearch(defaultSearchVolunteer);
    setCurrentPage(1);
    const response = await getVolunteerByEventId({
      eventId: eventId,
      type: 'waitlist',
      search: defaultSearchVolunteer,
      currentPage: 1,
      limit,
    });
    if (response?.statusCode === CALL_SUCCESS) {
      setVolunteers(response?.data?.items);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData({
      search,
      currentPage,
      limit,
    });
  }, [currentPage, limit]);

  return (
    <Box>
      <SearchVolunteer searchData={search} loading={loading} _onSearch={_onSearch} _onReset={_onReset} />
      <Listing
        volunteers={volunteers}
        loading={loading}
        search={search}
        setVolunteerWaitList={setVolunteerWaitList}
        volunteerWaitList={volunteerWaitList}
        userSelected={userSelected}
      />
    </Box>
  );
};

export default WaitList;
