import queryString, { stringify } from 'query-string';

import httpClient from 'src/configs/http-client';
import { IResponse, IResponseList } from 'src/types';

import { IEvent, IPhotoCover } from './events.interface';

import { IEventGuestRegistration, ISearchEvent } from '.';

const DEFAULT_LIMIT_10_ITEM = 10;
const DEFAULT_PAGE = 1;

export const uploadCoverPhoto = async (formData: FormData) => {
  const response = await httpClient.post<IResponse<IPhotoCover>>(
    `${process.env.REACT_APP_API_URL}/fileupload`,
    formData,
  );
  return response?.data;
};

export const createEventDraft = async (data: any) => {
  const response = await httpClient.post<IResponse<{ id: string }>>(`${process.env.REACT_APP_API_URL}/event`, data);
  return response?.data;
};

export const getEventById = async (id: string) => {
  const response = await httpClient.get<IResponse<IEvent>>(`${process.env.REACT_APP_API_URL}/event/${id}`);
  return response?.data;
};
export const updateEventById = async (event: Partial<IEvent>) => {
  const response = await httpClient.patch<IResponse<IEvent>>(
    `${process.env.REACT_APP_API_URL}/event/${event.id}`,
    event,
  );
  return response?.data;
};

export const getPublicEvents = async ({ page, limit }: { page?: number; limit?: number }) => {
  const DEFAULT_LIMIT = 1;
  const DEFAULT_PAGE = 6;

  const queryString = stringify({
    page: page ?? DEFAULT_PAGE,
    limit: limit ?? DEFAULT_LIMIT,
  });
  const response = await httpClient.get<IResponseList<IEvent>>(
    `${process.env.REACT_APP_API_URL}/event/public?${queryString}`,
  );
  return response?.data;
};

export const getPublicEventById = async (id: string) => {
  const response = await httpClient.get<IResponse<IEvent>>(`${process.env.REACT_APP_API_URL}/event/public/${id}`);
  return response?.data;
};

export const registrationEventByVolunteer = async (eventId: string, action: 'register' | 'unregister') => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/volunteer`,
    {
      action,
    },
  );
  return response?.data;
};

export const registrationEvent = async (payload: any) => {
  const response = await httpClient.post<IResponse<IEventGuestRegistration>>(
    `${process.env.REACT_APP_API_URL}/guest/registration`,
    payload,
  );
  return response?.data;
};

export const checkInCheckOut = async (eventId: string, body: { type: string }) => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/attendance-volunteer`,
    body,
  );
  return response?.data;
};
export const registrationEventAdmin = async (payload: any) => {
  const response = await httpClient.post<IResponse<IEventGuestRegistration>>(
    `${process.env.REACT_APP_API_URL}/guest/admin-registration`,
    payload,
  );
  return response?.data;
};

export const getAllEvents = async (search: ISearchEvent = { limit: DEFAULT_LIMIT_10_ITEM, page: DEFAULT_PAGE }) => {
  const query = queryString.stringify(search);
  const response = await httpClient.get<IResponseList<IEvent>>(`${process.env.REACT_APP_API_URL}/event?${query}`);
  return response?.data;
};

export const deleteEventById = async (id: string) => {
  const response = await httpClient.delete<IResponse<any>>(`${process.env.REACT_APP_API_URL}/event/${id}`);
  return response?.data;
};

type TEventStatus = 'completed' | 'cancelled' | 'draft' | 'deleted' | 'on-going' | 'pending' | 'published';
export const changeEventStatus = async (eventId: string, status: TEventStatus) => {
  const response = await httpClient.patch<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/change-status`,
    { status },
  );
  return response?.data;
};
export const registerVolunteer = async (token: string) => {
  const response = await httpClient.patch<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/users-event/change-status`,
    {
      token,
    },
  );
  return response?.data;
};

export const updateTicketSettingByEventId = async (eventId: string, payload: any) => {
  const response = await httpClient.patch<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/ticket-setting`,
    payload,
  );
  return response?.data;
};

export const cancelVolunteer = async (id: string) => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${id}/cancel-volunteer`,
  );
  return response?.data;
};

export const registerEventGTKY = async (eventId: string) => {
  const response = await httpClient.post<IResponse<IEvent>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/register-gtky`,
  );
  return response?.data;
};

export const updateEventGeneralInformation = async (event: Partial<IEvent>) => {
  const response = await httpClient.patch<IResponse<IEvent>>(
    `${process.env.REACT_APP_API_URL}/event/${event.id}/general-info`,
    event,
  );
  return response?.data;
};

export const cloneEventById = async (eventId: string) => {
  const response = await httpClient.post<IResponse<{ data: string }>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/clone`,
  );
  return response?.data;
};

export const getPublicEventNonLoggedIn = async ({
  page,
  limit,
  category,
  requireVolunteer,
  fromDate,
  toDate,
  search,
  language,
}: {
  page?: number;
  limit?: number;
  search?: string;
  fromDate?: any;
  toDate?: any;
  category?: string;
  requireVolunteer?: boolean;
  language?: string;
}) => {
  const DEFAULT_LIMIT = 1;
  const DEFAULT_PAGE = 6;

  const queryString = stringify({
    page: page ?? DEFAULT_PAGE,
    limit: limit ?? DEFAULT_LIMIT,
    search,
    fromDate,
    toDate,
    category,
    requireVolunteer,
    language,
    isHide: false,
  });

  const response = await httpClient.get<IResponseList<IEvent>>(
    `${process.env.REACT_APP_API_URL}/event/non-logged?${queryString}`,
  );

  return response?.data;
};
export const getPublicEventLoggedIn = async ({
  page,
  limit,
  category,
  requireVolunteer,
  fromDate,
  toDate,
  search,
  language,
}: {
  page?: number;
  limit?: number;
  search?: string;
  fromDate?: any;
  toDate?: any;
  category?: string;
  requireVolunteer?: boolean;
  language?: string;
}) => {
  const DEFAULT_LIMIT = 1;
  const DEFAULT_PAGE = 6;

  const queryString = stringify({
    page: page ?? DEFAULT_PAGE,
    limit: limit ?? DEFAULT_LIMIT,
    search,
    fromDate,
    toDate,
    category,
    requireVolunteer,
    language,
  });
  const response = await httpClient.get<IResponseList<IEvent>>(
    `${process.env.REACT_APP_API_URL}/event/logged?${queryString}`,
  );
  return response?.data;
};

export const getEventStatisticById = async (eventId: string) => {
  const response = await httpClient.get<IResponse<{ data: string }>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/statistic`,
  );
  return response?.data;
};
