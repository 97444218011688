import React, { useEffect } from 'react';

import { Box, FormControl, Grid, MenuItem } from '@material-ui/core';

import { CustomDatePicker, CustomInputSelect, InputField } from 'src/components';
import { FORMAT_DATE_DMY } from 'src/constants/common';
import { ISearchCMSHomepage } from 'src/services/cms';
import { shallowEqual } from 'src/utils/common';

import { CustomLabel } from '../../News/type';
import { CMSFeatureOptions, CMSHomepageTypes, CustomButtonStyled, defaultSearchCMSHomepage } from '../type';

interface ISearchCMSHomepageView {
  _onSearch: (search: ISearchCMSHomepage) => void;
  _onReset: () => void;
  loading: boolean;
  searchData: ISearchCMSHomepage;
  _setSearchValue: React.Dispatch<React.SetStateAction<ISearchCMSHomepage>>;
}
const LEAST_CHAR_FOR_SEARCH = 3;
function SearchCMSHomepage({ searchData, loading, _onSearch, _onReset, _setSearchValue }: ISearchCMSHomepageView) {
  const [search, setSearch] = React.useState<ISearchCMSHomepage>(defaultSearchCMSHomepage);
  const [isOnChangeSearch, setIsOnChangeSearch] = React.useState<boolean>(false);
  const reset = () => {
    _onReset();
    setSearch(defaultSearchCMSHomepage);
    _setSearchValue(defaultSearchCMSHomepage);
    setIsOnChangeSearch(false);
  };
  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch({ ...search, [e.target.name]: e.target.value === 'all' ? '' : e.target.value });
    _setSearchValue({ ...search, [e.target.name]: e.target.value === 'all' ? '' : e.target.value });
    setIsOnChangeSearch(true);
  }
  useEffect(() => {
    if (!shallowEqual(search, searchData)) {
      setSearch({ ...searchData });
    }
  }, []);
  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setSearch({ ...search, startDate: date });
      _setSearchValue({ ...search, startDate: date });
      setIsOnChangeSearch(true);
    }
  };
  const handleEndDateChange = (date: Date | null) => {
    if (date) {
      setSearch({ ...search, endDate: date });
      _setSearchValue({ ...search, endDate: date });
      setIsOnChangeSearch(true);
    }
  };
  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    if (valueTrimmed === '') {
      setIsOnChangeSearch(false);
    }
    setSearch({ ...search, title: valueTrimmed });
    _setSearchValue({ ...search, title: valueTrimmed });
  };

  return (
    <Box mb={4}>
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1} pr={2}>
              <InputField
                onBlur={onBlurField}
                title="Title"
                placeholder="Enter at least 3 characters"
                name="title"
                value={search.title}
                onChange={_handleChange}
                margin="dense"
                marginTop={'10px'}
                fullWidth
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
                error={search.title && search.title !== '' && search?.title?.length < LEAST_CHAR_FOR_SEARCH}
                errorMessage="Enter at least 3 characters"
              />
            </Box>
            <Box flex={1} pr={2}>
              <CustomInputSelect
                title="Type"
                name="type"
                value={search.type === '' ? 'all' : search.type}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
                marginTop={'10px'}
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {CMSHomepageTypes.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.value}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
            <Box flex={1}>
              <CustomInputSelect
                title="Is Feature Item?"
                name="isFeatureItem"
                value={search.isFeatureItem === '' ? 'all' : search.isFeatureItem}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
                marginTop={'10px'}
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {CMSFeatureOptions.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.value}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1} pr={2} display="flex" mt={3}>
              <FormControl fullWidth>
                <CustomLabel>From</CustomLabel>
                <CustomDatePicker
                  onChange={handleStartDateChange}
                  value={search.startDate ? search.startDate : null}
                  name="fromDate"
                  placeholder={FORMAT_DATE_DMY}
                  maxDate={search.endDate ? search.endDate : undefined}
                  marginTop={'10px'}
                />
              </FormControl>
            </Box>
            <Box flex={1} display="flex" mt={3}>
              <FormControl fullWidth>
                <CustomLabel>To</CustomLabel>
                <CustomDatePicker
                  onChange={handleEndDateChange}
                  value={search.endDate ? search.endDate : null}
                  name="toDate"
                  placeholder={FORMAT_DATE_DMY}
                  minDate={search.startDate ? search.startDate : undefined}
                  marginTop={'10px'}
                />
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end" mt={6}>
            {isOnChangeSearch && (
              <Box display="flex">
                <CustomButtonStyled disabled={loading} outlineButton onClick={reset}>
                  Reset
                </CustomButtonStyled>
              </Box>
            )}
            <Box display="flex">
              <CustomButtonStyled
                disabled={
                  loading || (search.title && search.title !== '' && search.title.length < LEAST_CHAR_FOR_SEARCH)
                }
                onClick={_onSearch(search)}
              >
                Search
              </CustomButtonStyled>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SearchCMSHomepage;
