export interface IMenu {
  title: string;
  link: string;
  logout?: boolean;
}

export const menuAboutUs: IMenu[] = [
  {
    title: '关于校友会',
    link: '/cn/about-us/nsaa',
  },
  {
    title: '校友会历史',
    link: '/cn/about-us/history',
  },
  {
    title: '校友会执委',
    link: '/cn/about-us/committee',
  },
];
export const menuContactUs: IMenu[] = [
  {
    title: '联络我们',
    link: '/cn/contact-us',
  },
  {
    title: '加入我们的邮件列表',
    link: '/cn/subcription-center',
  },
];

export const menuMembership: IMenu[] = [
  {
    title: '会籍',
    link: '/cn/membership/information',
  },
  {
    title: '永久会籍常见问题',
    link: '/cn/membership/life',
  },
  {
    title: '青年会籍常见问题',
    link: '/cn/membership/youth',
  },
  {
    title: '会籍申请',
    link: '/cn/events/5efa5c3b-15ec-48a0-84fe-ad63b8c78ebb',
  },
];
