import React from 'react';

import { Box, DialogTitle, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { CustomDialog } from 'src/components';

const DialogTitleStyled = styled(DialogTitle)(() => ({
  '& > h2': {
    fontSize: 20,
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.8)',
  },
  marginTop: 16,
  alignSelf: 'center',
}));

const DialogContentTextStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
}));

const Label = styled(Typography)(() => ({
  color: '#C5BE8B',
  fontSize: 16,
  fontWeight: 700,
}));

interface INotification {
  open: boolean;
  handleClose: () => void;
}

export const Notification = ({ open, handleClose }: INotification) => {
  return (
    <CustomDialog open={open} handleClose={handleClose} hiddenCancelButton submitText="OK" isConfirmPopup>
      <DialogTitleStyled id="form-dialog-title">Notification</DialogTitleStyled>
      <Box mb={3} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <Box mb={2}>
          <Label>Thank you for your generosity.</Label>
        </Box>
        <DialogContentTextStyled>
          <Typography style={{ textAlign: 'center' }}>
            To donate to NYPS Bursary Fund, please send <br />
            email to nyps@moe.edu.sg for further instruction
          </Typography>
        </DialogContentTextStyled>
      </Box>
    </CustomDialog>
  );
};
