import React from 'react';

import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

import { useFetchUserByIdQuery } from 'src/api/endpoints/users.endpoint';
import { ACCOUNT_TYPE } from 'src/constants/users.constants';
import CloseIcon from 'src/icons/close';
import DefaultAvatar from 'src/medias/images/default_avatar.png';
import ProfileImageInformation from 'src/pages/profile/shared/profile.image';
import ProfileTabs from 'src/pages/profile/shared/profile.tabs';

import AccountSetting from './user-detail.account-settings';
import AdditionalInformation from './user-detail.additional-information';
import EventAttendance from './user-detail.event-attendance';
import GeneralInformation from './user-detail.general-information';
import PaymentHistory from './user-detail.payment-history';

interface IUserDetailProps {
  userId: string;
  onClose: () => void;
}

export const Close = styled(Box)`
  ${({ theme }) => css`
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background-color: ${theme.palette.yellow?.[900]};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  `}
`;
const Heading = styled(Box)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header3.fontSize};
    font-weight: ${theme.fonts.header3.fontWeight};
    line-height: 32px;
    margin-bottom: 24px;
  `}
`;

const UserDetail = ({ userId, onClose }: IUserDetailProps) => {
  const { data: profile } = useFetchUserByIdQuery(userId);
  const tabs = [
    { label: 'General Information', disabled: false },
    { label: 'Additional Information', disabled: false },
    { label: 'Account Settings', disabled: false },
    { label: 'Event Attendance', disabled: false },
    { label: 'Payment History', disabled: false },
  ];
  const panels = [
    <GeneralInformation userId={userId} />,
    <AdditionalInformation userId={userId} />,
    <AccountSetting userId={userId} />,
    <EventAttendance userId={userId} />,
    <PaymentHistory userId={userId} />,
  ];
  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Heading>User Detail</Heading>
        <Close onClick={onClose}>
          <CloseIcon fill="white" />
        </Close>
      </Box>
      <ProfileImageInformation
        variants="user-detail"
        nsaaId={profile?.data?.username ? profile?.data?.username : ''}
        imageUrl={profile?.data?.avatar || DefaultAvatar}
        accountType={ACCOUNT_TYPE.find((i) => i.value === profile?.data?.accountType)?.label || ''}
        userId={userId}
      />
      <ProfileTabs panels={panels} tabs={tabs} />
    </>
  );
};

export default UserDetail;
