import React, { useState } from 'react';

import {
  Box,
  DialogContentText,
  FormControlLabel,
  FormHelperText,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  RadioProps,
  TextareaAutosize,
  Typography,
  withStyles,
} from '@material-ui/core';
import _ from 'lodash';
import styled, { css } from 'styled-components';

import { CustomButton, CustomDialog, InputField } from 'src/components';
import { CALL_SUCCESS, MAX_LENGTH_15, MAX_LENGTH_50 } from 'src/constants/common';
import { IContactForm, postContactUs } from 'src/services/contact-us';

const TEXT_REQUIRE_CHINESE = '这是必填栏';
const useStyles = makeStyles((theme) => ({
  noticeRequire: {
    color: 'red',
    verticalAlign: 'middle',
    marginLeft: 4,
  },
}));
const ContactFormContainer = styled(Box)`
  ${({ theme }) => css`
    padding: 20px;
  `}
`;
const Form = styled.form`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  `}
`;
const InputFieldCustom = styled(InputField)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing(2)}px;
  `}
`;
const RadioGroupCustom = styled(RadioGroup)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing(2)}px;
  `}
`;
const NASSRadio = withStyles((theme) => ({
  root: {
    color: `${theme.palette.yellow?.[900]} !important`,
    '&$checked': {
      color: `${theme.palette.yellow?.[900]} !important`,
    },
  },
}))((props: RadioProps) => <Radio {...props} />);
const FormLabelCustom = styled(FormControlLabel)`
  ${({ theme }) => css`
    color: ${theme.palette.gray?.[1000]};
  `}
`;
const Title = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 700;
    vertical-align: middle;
    max-height: 18px;
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.6);
  }
`;
const Textarea = styled(TextareaAutosize)<{ error?: boolean }>`
  ${({ theme, error }) => ({
    border: error ? '1px solid red' : '1px solid black',
  })}
  width: 100%;
  padding: 10px;
`;
const SubmitBtn = styled(CustomButton)`
  ${({ theme }) => css`
    && {
      width: 100%;
    }
  `}
`;
const FormHelperTextCustom = styled(FormHelperText)(({ theme }) => ({
  ...theme.fonts.textS,
  marginTop: '10px',
  color: 'red !important',
}));
const DialogContentTextStyled = styled(DialogContentText)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
}));
function ContactForm() {
  const classes = useStyles();
  const [showBox, setShowBox] = useState<boolean>(false);
  const [data, setData] = useState<IContactForm>({
    subjectEmail: 'enquiry@nanyang.org.sg',
    name: '',
    mobilePhone: '',
    emailAddress: '',
    memberType: 'member',
    comments: '',
  });
  const [errors, setErrors] = useState({
    subjectEmail: false,
    name: false,
    mobilePhone: false,
    emailAddress: false,
    memberType: false,
    comments: false,
  });
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    let validErrors = errors;
    if (_.isEmpty(data.name)) {
      validErrors.name = true;
      setErrors({ ...errors, name: true });
    }
    if (_.isEmpty(data.emailAddress)) {
      validErrors.emailAddress = true;
      setErrors({ ...errors, emailAddress: true });
    }
    if (_.isEmpty(data.comments)) {
      validErrors.comments = true;
      setErrors({ ...errors, comments: true });
    }
    if (_.isEmpty(data.mobilePhone)) {
      validErrors.mobilePhone = true;
      setErrors({ ...errors, mobilePhone: true });
    }
    if (validErrors.name || validErrors.emailAddress || validErrors.comments || validErrors.mobilePhone) {
      return;
    } else {
      const response = await postContactUs(data);
      if (response.statusCode === CALL_SUCCESS) {
        setShowBox(true);
      }
    }
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: false });
  }
  function handleTextAreaChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setData({ ...data, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: false });
  }
  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setData({ ...data, [e.target.name]: valueTrimmed });
  };
  const _onCloseBox = () => {
    setShowBox(false);
    setData({
      subjectEmail: 'enquiry@nanyang.org.sg',
      name: '',
      mobilePhone: '',
      emailAddress: '',
      memberType: 'member',
      comments: '',
    });
  };
  return (
    <ContactFormContainer>
      <Form onSubmit={handleSubmit}>
        <Box mb={4}>
          <InputFieldCustom
            lableStyle={{ color: ' rgba(0, 0, 0, 0.6)' }}
            value={data.subjectEmail}
            title="主题"
            fullWidth
            select
            name="subjectEmail"
            onChange={handleChange}
            colorTitle="black"
            margin="dense"
            require
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              选择主题
            </MenuItem>
            <MenuItem value={'enquiry@nanyang.org.sg'}>一般查询</MenuItem>
            <MenuItem value={'events@nanyang.org.sg'}>活动</MenuItem>
            <MenuItem value={'membership@nanyang.org.sg'}>会员资格</MenuItem>
            <MenuItem value={'volunteers@nanyang.org.sg'}>做志愿者</MenuItem>
          </InputFieldCustom>
          <InputFieldCustom
            lableStyle={{ color: ' rgba(0, 0, 0, 0.6)' }}
            value={data.name}
            title="名称"
            fullWidth
            type="text"
            name="name"
            onChange={handleChange}
            colorTitle="black"
            margin="dense"
            require
            error={errors.name}
            errormessage={errors.name && TEXT_REQUIRE_CHINESE}
            InputProps={{
              inputProps: {
                maxLength: MAX_LENGTH_50,
              },
            }}
            onBlur={onBlurField}
          />
          <InputFieldCustom
            lableStyle={{ color: ' rgba(0, 0, 0, 0.6)' }}
            value={data.mobilePhone}
            title="联系电话
            "
            fullWidth
            type="number"
            name="mobilePhone"
            onChange={handleChange}
            colorTitle="black"
            margin="dense"
            require
            error={errors.mobilePhone}
            errormessage={errors.mobilePhone && TEXT_REQUIRE_CHINESE}
            InputProps={{
              inputProps: {
                maxLength: MAX_LENGTH_15,
              },
            }}
            onBlur={onBlurField}
          />
          <InputFieldCustom
            lableStyle={{ color: ' rgba(0, 0, 0, 0.6)' }}
            value={data.emailAddress}
            title="
            电子邮件地址"
            fullWidth
            type="email"
            name="emailAddress"
            onChange={handleChange}
            colorTitle="black"
            onBlur={onBlurField}
            margin="dense"
            require
            error={errors.emailAddress}
            errormessage={errors.emailAddress && TEXT_REQUIRE_CHINESE}
            InputProps={{
              inputProps: {
                maxLength: MAX_LENGTH_50,
              },
            }}
          />
          <Title>
            会员类型
            <Typography component="span" className={classes.noticeRequire}>
              *
            </Typography>
          </Title>
          <RadioGroupCustom aria-label="confirm-old" value={data.memberType} onChange={handleChange} name="memberType">
            <FormLabelCustom value="member" control={<NASSRadio />} label="NSAA member" />
            <FormLabelCustom value="alumni" control={<NASSRadio />} label="Nanyang Alumni (non - NSAA member)" />
            <FormLabelCustom value="public" control={<NASSRadio />} label="Public (Non Nanyang Alumni)" />
          </RadioGroupCustom>
          <Title>
            评论
            <Typography component="span" className={classes.noticeRequire}>
              *
            </Typography>
          </Title>
          <Textarea
            aria-label="minimum height"
            minRows={10}
            value={data.comments}
            onChange={handleTextAreaChange}
            name="comments"
            error={errors.comments}
          />
          {errors.comments && <FormHelperTextCustom>{TEXT_REQUIRE_CHINESE}</FormHelperTextCustom>}
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" width="25%">
          <SubmitBtn type="submit">提交</SubmitBtn>
        </Box>
        {showBox && (
          <CustomDialog open={showBox} hiddenCancelButton onSubmit={_onCloseBox} isConfirmPopup={true}>
            <Box
              alignSelf="center"
              display="flex"
              flex={1}
              justifyContent="center"
              alignItems="center"
              textAlign="center"
            >
              <DialogContentTextStyled>您的问题已发送！</DialogContentTextStyled>
            </Box>
          </CustomDialog>
        )}
      </Form>
    </ContactFormContainer>
  );
}

export default ContactForm;
