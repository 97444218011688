import { Box, Button, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Container = styled(Box)`
  display: flex;
  gap: 50px;
  margin-bottom: 28px;
`;
export const Avatar = styled(Box)<{ imageUrl: string; width?: string; height?: string }>`
  ${({ theme, imageUrl, width, height }) => css`
    width: ${width ? width : '160px'};
    height: ${height ? height : '160px'};
    border-radius: 999999px;
    background-image: url(${imageUrl});
    background-size: cover;
    background-position: center;
  `}
`;
export const BasicInfo = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;
export const Label = styled(Typography)`
  ${({ theme }) => css`
    width: 120px;
    font-size: ${theme.fonts.header6.fontSize};
    font-weight: ${theme.fonts.header6.fontWeight};
    line-height: 20px;
    color: rgba(0, 0, 0, 0.6);
  `}
`;
export const Text = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header6.fontSize};
    font-weight: 400;
    line-height: 20px;
    color: ${theme.palette.gray?.[1000]};
  `}
`;
export const ActionButton = styled(Button)<{ variants: 'upload' | 'remove' }>`
  ${({ theme, variants, disabled }) => css`
    color: ${theme.palette.common.white};
    width: 160px;
    height: 44px;
    border-radius: 6px;
    text-transform: capitalize;
    font-size: 14px;
    ${variants === 'upload' &&
    css`
      background-color: ${theme.palette.yellow?.[900]};
      &:hover {
        background-color: ${theme.palette.yellow?.[900]};
      }
      margin-right: 24px;
    `}
    ${variants === 'remove' &&
    css`
      background-color: ${theme.palette.red?.[150]};
      &:hover {
        background-color: ${theme.palette.red?.[150]};
      }
    `};
    ${disabled &&
    css`
      background-color: #dcdcdd;
    `}
  `}
`;
