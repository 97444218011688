import React from 'react';

interface IPlusIcon {
  width?: number;
  height?: number;
}

const PlusIcon = ({ width = 24, height = 24, ...props }: IPlusIcon & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M12.75 7.667a.75.75 0 00-1.5 0h1.5zm-1.5 7.666a.75.75 0 001.5 0h-1.5zM8 10.75a.75.75 0 000 1.5v-1.5zm8 1.5a.75.75 0 100-1.5v1.5zm-4.75-4.583v7.666h1.5V7.667h-1.5zM8 12.25h8v-1.5H8v1.5zm0-7.667h8v-1.5H8v1.5zm11.25 3.084v7.666h1.5V7.667h-1.5zM16 18.417H8v1.5h8v-1.5zM4.75 15.333V7.667h-1.5v7.666h1.5zM8 18.417c-1.825 0-3.25-1.41-3.25-3.084h-1.5c0 2.561 2.157 4.584 4.75 4.584v-1.5zm11.25-3.084c0 1.673-1.425 3.084-3.25 3.084v1.5c2.593 0 4.75-2.023 4.75-4.584h-1.5zM16 4.583c1.825 0 3.25 1.41 3.25 3.084h1.5c0-2.561-2.157-4.584-4.75-4.584v1.5zm-8-1.5c-2.593 0-4.75 2.023-4.75 4.584h1.5c0-1.673 1.425-3.084 3.25-3.084v-1.5z" />
    </svg>
  );
};

export default PlusIcon;
