export const privacyPolicyData = [
  {
    type: 'h1',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: 'PRIVACY POLICY',
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        bold: true,
        underline: true,
      },
    ],
  },
  {
    type: 'h2',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165452,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'INTRODUCTION',
        bold: true,
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165466,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'We, at Nanyang Schools Alumni Association, recognize the importance of the protection of the personal data of applicants, youth and life members, Board Directors, retired teachers and volunteers. We believe that it is our responsibility to properly manage, protect and process your personal data in accordance with the Personal Data Protection Act of Singapore 2012 (the “PDPA”).',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165477,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'This data protection notice will assist you in understanding how we collect, use, disclose and manage your personal data with us.',
      },
    ],
  },
  {
    type: 'h2',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165482,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'PERSONAL DATA',
        bold: true,
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165487,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'The PDPA defines personal data as “data, whether true or not, about an individual who can be identified (a) from that data; or (b) from that data and other information to which the organisation has or is likely to have access.”',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165492,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'Such personal data may include but are not limited to your name, address, contact details, name and date of birth of your child, etc.',
      },
    ],
  },
  {
    type: 'h2',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165498,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'COLLECTION OF PERSONAL DATA',
        bold: true,
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165503,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'We collect your personal data when you:',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165507,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'a. Apply for our membership',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165509,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'b. Provide your personal data to us upon your appointment to School Boards',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165510,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'c. Give us your personal data so that we can keep in touch with you',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165512,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'We may ask you to bring the documents containing personal data to our Get-to-know-you Party for verification purposes but we will not keep copies of such documents.',
      },
    ],
  },
  {
    type: 'h2',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165514,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'PURPOSES OF PROCESSING OF PERSONAL DATA',
        bold: true,
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165516,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'As a platform for our alumni to network and connect, NSAA’s purposes for processing personal data are as follows:',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165517,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'a. To process membership applications;',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165519,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'b. To update members of our events and activities;',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165520,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'c. To facilitate organising of events and activities, especially those that involve external organisations;',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165521,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'd. To inform our members of volunteering opportunities in Nanyang Schools and NSAA;',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165522,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'e. To process the membership confirmation letter for use in connection with the Phase 2A1 Primary One Registration Exercise;',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165523,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'f. To attend to your enquiries and feedback;',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165524,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'g. To invite Board Directors of Schools and retired teachers to selected events, like Founders’ Day and New Year Celebrations; and',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165525,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'h. To send greeting cards and keep in touch with you.',
      },
    ],
  },
  {
    type: 'h2',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165526,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'ACCURACY',
        bold: true,
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165527,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'When we collect your personal data from you directly, it is your responsibility to ensure that the personal data that you disclose to us is accurate and complete. We will take reasonable and appropriate measures to maintain the personal data accurately.',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165529,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'To help us maintain the accuracy of the personal data, we encourage you to promptly inform us when there are changes to the personal data that you have provided to us. From time to time, we may do a data verification exercise with you to update us on any changes to your personal data.',
      },
    ],
  },
  {
    type: 'h2',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165533,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'ACCESS AND CORRECTION OF PERSONAL DATA',
        bold: true,
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165534,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'You may write in to our Data Protection Officer (DPO) to find out how we have been using or disclosing your personal data over the past one year. Before we accede to your request, we may need to verify your identity. We will respond to your request as soon as possible, or within 30 days, as stipulated in the PDPA. If we are unable to do so within the 30 days, we will let you know and give you an estimate of how much longer we require. We may also charge you a reasonable fee for the cost involved in processing your access request and we will notify you in advance.',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165536,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'You may also ask us to correct an error or omission in the personal data we hold about you by writing to our DPO. We will correct the personal data as soon as practicable, or within 30 days, unless we are satisﬁed on reasonable grounds that a correction should not be made.',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165538,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'To find out more about how you can contact our DPO, kindly refer to the section on “Contacting Us” at the end of this document.',
      },
    ],
  },
  {
    type: 'h2',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165540,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'PROTECTION OF PERSONAL DATA',
        bold: true,
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165542,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'We have implemented appropriate security measures (such as access control and password protection) to ensure that your personal data is adequately protected and secured and to prevent the unauthorized access, use and disclosure. We will take reasonable and appropriate measures to ensure the confidentiality and integrity of your personal data',
      },
    ],
  },
  {
    type: 'h2',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165543,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'CONTACTING US',
        bold: true,
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165545,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'If you have any query or feedback regarding this Notice, or any complaint relating to how we manage your personal data, you may contact our DPO at: dpo@nanyang.org.sg﻿',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165547,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'Kindly include the following details in your query or complaint:',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165548,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: '﻿',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165549,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'Your full name and contact information',
      },
    ],
  },
  {
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    id: 1647396165550,
    children: [
      {
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: 'Brief description of your query or complaint',
      },
    ],
  },
];
