import { ReactNode } from 'react';

import { Box } from '@material-ui/core';
import moment from 'moment';

import { InfoText } from 'src/components';
import { IUsersToEvent } from 'src/services/users';
import useResponsive from 'src/utils/responsive';

import { CustomBox, CustomFormDialogBox, FORMAT_TIME } from './types';

interface IMyVolunteerDetail {
  open: boolean;
  _onCloseModal: () => void;
  event?: IUsersToEvent;
  status: ReactNode;
}
function MyVolunteerDetail({ open, _onCloseModal, event, status }: IMyVolunteerDetail) {
  const responsive = useResponsive();
  return (
    <CustomFormDialogBox
      open={open}
      _onCloseModal={_onCloseModal}
      title="Volunteer Detail"
      isConfirmPopup
      hiddenSubmitButton
      responsive={responsive}
    >
      <CustomBox responsive={responsive}>
        <Box display="flex" justifyContent="space-between">
          <Box mr={2} flex={1}>
            <InfoText label="Event" value={event?.event.title ? event?.event.title : '-'} />
            <InfoText
              label="Time"
              value={
                <>
                  {moment(new Date(+event?.event.start_time!))
                    .local()
                    .format(FORMAT_TIME)}{' '}
                  - <br />
                  {moment(new Date(+event?.event.end_time!))
                    .local()
                    .format(FORMAT_TIME)}
                </>
              }
            />
            <InfoText label="Location" value={event?.event.location ? event?.event.location : '-'} />
            <InfoText
              label="Registration Time"
              value={
                event?.registrationTime
                  ? moment(new Date(+event.registrationTime))
                      .local()
                      .format(FORMAT_TIME)
                  : ''
              }
            />
          </Box>
          <Box flex={1}>
            <InfoText label="Status" value={status} />
            <InfoText
              label="Check In Time"
              value={
                event?.checkinTime
                  ? moment(new Date(+event.checkinTime))
                      .local()
                      .format(FORMAT_TIME)
                  : ''
              }
            />
            <InfoText
              label="Check Out Time"
              value={
                event?.checkoutTime
                  ? moment(new Date(+event.checkoutTime))
                      .local()
                      .format(FORMAT_TIME)
                  : ''
              }
            />
          </Box>
        </Box>
      </CustomBox>
    </CustomFormDialogBox>
  );
}

export default MyVolunteerDetail;
