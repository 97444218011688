export interface IMenu {
  title: string;
  link: string;
  logout?: boolean;
}

export const menuAboutUs: IMenu[] = [
  {
    title: 'NSAA',
    link: '/about-us/nsaa',
  },
  {
    title: 'History',
    link: '/about-us/history',
  },
  {
    title: 'Committee',
    link: '/about-us/committee',
  },
  {
    title: 'Constitution',
    link: '/about-us/constitution/#name',
  },
];
export const menuContactUs: IMenu[] = [
  {
    title: 'Contact Us',
    link: '/contact-us',
  },
  {
    title: 'Join Our Mailing List',
    link: '/subcription-center',
  },
];
export const menuMembership: IMenu[] = [
  {
    title: 'MEMBERSHIP INFORMATION',
    link: '/membership/information',
  },
  {
    title: 'LIFE MEMBERSHIP FAQ',
    link: '/membership/life',
  },
  {
    title: 'YOUTH MEMBERSHIP FAQ',
    link: '/membership/youth',
  },
  {
    title: 'MEMBERSHIP APPLICATION',
    link: '/events/5efa5c3b-15ec-48a0-84fe-ad63b8c78ebb',
  },
];
export const menuUser: IMenu[] = [
  {
    title: 'My profile',
    link: '/my-profile',
  },
  {
    title: 'My volunteer event',
    link: '/my-volunteer-event',
  },
  {
    title: 'Log out',
    link: '#',
    logout: true,
  },
];
export const menuUserMobile: IMenu[] = [
  {
    title: 'My profile',
    link: '/my-profile',
  },
  {
    title: 'My volunteer event',
    link: '/my-volunteer-event',
  },
  {
    title: 'E-ticket Checking',
    link: '/e-ticket-checking',
  },
  {
    title: 'Log out',
    link: '#',
    logout: true,
  },
];
export const menuUserNotLogin: IMenu[] = [
  {
    title: 'Login',
    link: '/login',
  },
  {
    title: 'Sign up',
    link: '/signup',
  },
];

export const menuUserNotLoginChinese: IMenu[] = [
  {
    title: '登录',
    link: '/login',
  },
  {
    title: '报名',
    link: '/signup',
  },
];
