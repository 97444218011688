import React from 'react';

import { Box, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { CustomButton } from 'src/components';
import letter from 'src/medias/images/letter.png';
import paperFlight from 'src/medias/images/paper-flight.png';

import { IResponsive } from '../homepageStyled';

interface ISubscribe {
  responsive: IResponsive;
}
const MidBox = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin: 0px 10px;
    justify-content: center;
    align-items: center;
  `}
`;
const Text = styled(Typography)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    color: ${theme.palette.yellow?.[900]};
    font-size: ${responsive?.isMobile ? theme.fonts.header4.fontSize : theme.fonts.textXX.fontSize};
    font-weight: ${theme.fonts.textXX.fontWeight};
    line-height: ${theme.fonts.textXX.lineHeight};
    line-height: 60px;
    text-align: center;
    width: ${responsive?.isMobile ? '366px' : '500px'};
    word-break: break-word;
    line-height: 40px; ;
  `}
`;
const SucribeBtn = styled(CustomButton)`
  ${({ theme }) => css`
    width: 40%;
    max-width: 200px;
    max-height: 56px;
    margin: 30px 20px 0px;
  `}
`;
const Container = styled(Box)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    background-color: rgba(249, 249, 244, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${responsive?.isTabletOrMobile ? '95%' : '100%'};
    margin: auto;
    height: 352px;
    position: relative;
  `}
`;
const NavLinkCustom = styled(NavLink)`
  ${({ theme }) => css`
    text-decoration: none;
  `}
`;
const LetterImg = styled.img<{ responsive?: IResponsive }>`
  ${({ responsive }) => ({
    position: responsive?.isTabletOrMobile ? 'absolute' : 'unset',
    bottom: '100px',
    left: 0,
  })}
`;
const PlaneImg = styled.img<{ responsive?: IResponsive }>`
  ${({ responsive }) => ({
    position: responsive?.isTabletOrMobile ? 'absolute' : 'unset',
    top: responsive?.isTabletOrMobile ? '50px' : '0',
    right: 0,
  })}
`;
function Subscribe({ responsive }: ISubscribe) {
  return (
    <Container>
      <LetterImg responsive={responsive} src={letter} alt="letter" />
      <MidBox>
        <Text responsive={responsive}>Subscribe to our mailing list for our latest news and updates! </Text>
        <NavLinkCustom to="/subcription-center">
          <SucribeBtn>SUBSCRIBE</SucribeBtn>
        </NavLinkCustom>
      </MidBox>
      <PlaneImg responsive={responsive} src={paperFlight} alt="paperFlight" />
    </Container>
  );
}

export default Subscribe;
