import React, { useMemo } from 'react';

import { Box, Typography } from '@material-ui/core';
import {
  createAlignPlugin,
  createBoldPlugin,
  createDndPlugin,
  createExitBreakPlugin,
  createFontBackgroundColorPlugin,
  createFontColorPlugin,
  createHighlightPlugin,
  createItalicPlugin,
  createKbdPlugin,
  createNodeIdPlugin,
  createSelectOnBackspacePlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createUnderlinePlugin,
} from '@udecode/plate';
import { createHistoryPlugin, createReactPlugin, Plate, PlatePlugin, SPEditor } from '@udecode/plate-core';
import { AnyObject, TNode } from '@udecode/plate-core';
import { createHeadingPlugin } from '@udecode/plate-heading';
import { createImagePlugin, ELEMENT_IMAGE } from '@udecode/plate-image';
import { createLinkPlugin } from '@udecode/plate-link';
import { createListPlugin, createTodoListPlugin } from '@udecode/plate-list';
import { createMediaEmbedPlugin } from '@udecode/plate-media-embed';
import { createParagraphPlugin } from '@udecode/plate-paragraph';
import { createTablePlugin } from '@udecode/plate-table';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';

import { CustomButton } from 'src/components';
import Banner from 'src/components/banner';
import { editablePreviewProps, optionsExitBreakPlugin } from 'src/components/slateEditor/config/pluginOptions';
import AboutUsRightImage from 'src/medias/images/about_us_right.png';
import { components } from 'src/pages/admin/components/Preview';
import { donations } from 'src/pages/donation/types';
import { IResponsive } from 'src/pages/home/homepageStyled';
import { ICms } from 'src/services/cms';

interface IPreview {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  postData: ICms;
  title?: string;
  hasContent?: boolean;
  content?: TNode<AnyObject>[];
  emptyContent?: boolean;
}
type TEditor = SPEditor & ReactEditor & HistoryEditor;
function CMSPreview({ setOpen, postData, title, content, emptyContent }: IPreview) {
  const PreviewId = 'preview';
  const handleClose = () => {
    setOpen(false);
  };
  const pluginsMemo: PlatePlugin<TEditor>[] = useMemo(() => {
    const plugins = [
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createMediaEmbedPlugin(),
      createAlignPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createDndPlugin(),
      createExitBreakPlugin(optionsExitBreakPlugin),
      createSelectOnBackspacePlugin({
        allow: [ELEMENT_IMAGE],
      }),
    ];

    return plugins;
  }, []);
  return (
    <PreviewContainer>
      <OverflowBox bgcolor="white" maxHeight={'100vh'} width={'100%'}>
        <HeaderTitle display="flex" justifyContent="space-between" alignItems={'center'} pl={4} pr={4}>
          <NewPreview>{`${title} Preview`}</NewPreview>
          <CustomButtonStyled outlineButton onClick={handleClose}>
            Close
          </CustomButtonStyled>
        </HeaderTitle>
        <Banner
          image={postData?.banner_image}
          title={postData?.banner_title}
          description={postData.banner_description}
        />
        {content && postData && postData.type === 'about' ? (
          <NSAAContainer maxWidth="lg">
            <Box display="flex" flexDirection="column" maxWidth={730} mx="auto">
              <Plate
                id={PreviewId}
                plugins={pluginsMemo}
                components={components}
                editableProps={editablePreviewProps}
                value={content}
              />
              <NSAAButton disableRipple>Learn more about our history</NSAAButton>
            </Box>
            <Box flex="1" display="flex" justifyContent="center" alignItems="flex-start">
              <Image src={AboutUsRightImage} />
            </Box>
          </NSAAContainer>
        ) : postData.type === 'donation' ? (
          <DonationContainer maxWidth="lg">
            <Box display="flex" flexDirection="column" maxWidth={730} mx="auto">
              <Plate
                id={PreviewId}
                plugins={pluginsMemo}
                components={components}
                editableProps={editablePreviewProps}
                value={content}
              />
            </Box>
            <DonateHeader>Donate Online</DonateHeader>
            <DonationBox overflow={'visible'} justifyContent={'center'}>
              {donations.map((item) => {
                return (
                  <BoxDonation display="flex" justifyContent="center" alignItems="center" flexDirection="column">
                    <Box position="relative">
                      <ImgCustom src={item.image} alt="" />
                      <Typography
                        style={{
                          fontSize: 24,
                          fontWeight: 700,
                          color: '#fff',
                          position: 'absolute',
                          left: '50%',
                          bottom: 0,
                          transform: 'translate(-50%, -50%)',
                        }}
                      >
                        {item.title}
                      </Typography>
                    </Box>
                    <DonateBtn>Donate Now</DonateBtn>
                  </BoxDonation>
                );
              })}
            </DonationBox>
          </DonationContainer>
        ) : null}
        {emptyContent && <EmptyContent>YOUR DEFAULT CONTENT IS HERE...</EmptyContent>}
      </OverflowBox>
    </PreviewContainer>
  );
}

export default CMSPreview;
const NewPreview = styled(Typography)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    font-size: ${theme.fonts.header3S.fontSize};
    font-weight: 700;
    padding: 16px;
    text-align: center;
  `}
`;
const HeaderTitle = styled(Box)`
  position: sticky;
  background: white;
  width: 100%;
  top: 0;
  z-index: 1;
`;
const OverflowBox = styled(Box)`
  overflow: auto;
  height: 90vh;
`;
const CustomButtonStyled = styled(CustomButton)(({ theme }) => ({
  width: '200px !important',
  height: '44px !important',
}));
const NSAAButton = styled(CustomButton)`
  && {
    width: 270px;
    height: 56px;
    margin-top: 20px;
    padding: 20px;
    border-radius: 7px;
    align-self: center;
    cursor: default;
  }
`;

const NSAAContainer = styled(Box)`
  ${({ theme }) => css`
    padding: 50px;
    display: flex;
    gap: 60px;
    text-align: justify;
  `}
`;
const DonationContainer = styled(Box)`
  ${({ theme }) => css`
    padding: 50px;
    gap: 60px;
    text-align: justify;
  `}
`;
const DonationBox = styled(Box)`
  ${({ theme }) => css`
    && {
      max-width: 1200px;
      display: flex;
      align-items: center;
      margin: 32px auto 64px;
      gap: 24px;
    }
  `}
`;
const BoxDonation = styled(Box)`
  && {
    gap: 24px;
  }
`;
const ImgCustom = styled.img`
  && {
    width: 100%;
    height: auto;
  }
`;
const DonateBtn = styled(CustomButton)`
  && {
    ${({ theme }) => ({
      ...theme.fonts.textM,
      fontWeight: 500,
    })}
    margin: auto;
    cursor: default;
  }
`;
const EmptyContent = styled(Box)`
  ${({ theme }) => css`
    font-size: 56px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    margin-top: 160px;
  `}
`;

const Image = styled.img`
  ${({ theme }) => css`
    width: 100%;
    max-width: 410px;
  `}
`;
const PreviewContainer = styled(Box)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;
const DonateHeader = styled(Typography)`
  && {
    ${({ theme }) => ({
      ...theme.fonts.header4S,
    })}
    ${({ theme }) => css`
      text-align: center;
    `}
    margin-top: 64px;
  }
`;
