import React, { useEffect, useState } from 'react';

import { Box, FormControl, Grid, MenuItem } from '@material-ui/core';

import { CustomDatePicker, CustomInputSelect, InputField } from 'src/components';
import { CustomLabel } from 'src/pages/signup/components/types';
import { CustomButtonStyled, eventCategory } from 'src/pages/user-management/types';
import { ISearchTicketAttendance } from 'src/services/tickets/ticket.interface';
import { shallowEqual } from 'src/utils/common';

import { defaultSearchTicket, ticketStatus } from '../types';

const LEAST_CHAR_FOR_SEARCH = 2;
const LEAST_CHAR_FOR_TICKET_CODE = 8;

interface ISearchTicket {
  _onSearch: (search: ISearchTicketAttendance) => void;
  _onReset: () => void;
  loading: boolean;
  searchData: ISearchTicketAttendance;
}

const SearchTicket = ({ searchData, loading, _onSearch, _onReset }: ISearchTicket) => {
  const [search, setSearch] = useState<ISearchTicketAttendance>(defaultSearchTicket);

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch({ ...search, [e.target.name]: e.target.value === 'all' ? '' : e.target.value });
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setSearch({ ...search, [e.target.name]: valueTrimmed });
  };

  const handleFromDateChange = (date: Date | null) => {
    if (date) {
      setSearch({ ...search, fromDate: date });
    }
  };
  const handleToDateChange = (date: Date | null) => {
    if (date) {
      setSearch({ ...search, toDate: date });
    }
  };

  const reset = () => {
    _onReset();
    setSearch(defaultSearchTicket);
  };

  useEffect(() => {
    if (!shallowEqual(search, searchData)) {
      setSearch(searchData);
    }
  }, []);

  return (
    <Box mb={4}>
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1} pr={2}>
              <InputField
                title="Event Name"
                placeholder="Enter at least 2 characters"
                name="eventName"
                value={search.eventName}
                onChange={_handleChange}
                onBlur={onBlurField}
                margin="dense"
                fullWidth
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
                error={search.eventName !== '' && search.eventName.length < LEAST_CHAR_FOR_SEARCH}
                errorMessage="Enter at least 2 characters"
              />
            </Box>
            <Box flex={1} pr={2}>
              <InputField
                title="Ticket Code"
                placeholder="Enter ticket code"
                name="ticketCode"
                value={search.ticketCode}
                onChange={_handleChange}
                onBlur={onBlurField}
                margin="dense"
                fullWidth
                InputProps={{
                  inputProps: {
                    maxLength: 8,
                  },
                }}
                error={search.ticketCode !== '' && search.ticketCode.length < LEAST_CHAR_FOR_TICKET_CODE}
                errorMessage="Ticket code must be 8 characters"
              />
            </Box>
            <Box flex={1}>
              <CustomInputSelect
                title="Ticket Status"
                name="ticketStatus"
                value={search.ticketStatus === '' ? 'all' : search.ticketStatus}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {ticketStatus.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.value}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1} pr={2}>
              <CustomInputSelect
                title="Event Category"
                name="eventCategory"
                value={search.eventCategory === '' ? 'all' : search.eventCategory}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {eventCategory.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.value}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
            <Box flex={1} pr={2} display="flex" mt={3}>
              <FormControl fullWidth>
                <CustomLabel>From Date</CustomLabel>
                <CustomDatePicker
                  onChange={handleFromDateChange}
                  value={search.fromDate ? new Date(search.fromDate) : null}
                  name="fromDate"
                  maxDate={search.toDate ? search.toDate : undefined}
                />
              </FormControl>
            </Box>
            <Box flex={1} display="flex" mt={3}>
              <FormControl fullWidth>
                <CustomLabel>To Date</CustomLabel>
                <CustomDatePicker
                  onChange={handleToDateChange}
                  value={search.toDate ? new Date(search.toDate) : null}
                  name="toDate"
                  minDate={search.fromDate ? search.fromDate : undefined}
                />
              </FormControl>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end" mt={6}>
            {!shallowEqual(searchData, defaultSearchTicket) && (
              <Box display="flex">
                <CustomButtonStyled disabled={loading} outlineButton onClick={reset}>
                  Reset
                </CustomButtonStyled>
              </Box>
            )}
            <Box display="flex">
              <CustomButtonStyled
                disabled={
                  loading ||
                  (search.eventName !== '' && search.eventName.length < LEAST_CHAR_FOR_SEARCH) ||
                  (search.ticketCode !== '' && search.ticketCode.length < LEAST_CHAR_FOR_TICKET_CODE)
                }
                onClick={_onSearch(search)}
              >
                Search
              </CustomButtonStyled>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchTicket;
