import React, { useState } from 'react';

import { Button, Popover } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styled, { css } from 'styled-components';

import { CustomButton } from 'src/components';
import { MenuItemStyled } from 'src/pages/user-management/types';

interface IActionButtonPopoverProps {
  label: string;
  disabled?: boolean;
  items: { label: string; value: string }[];
  onChange: (action: string) => void;
}

export const ActionButtonPopover = ({ disabled, label, items, onChange }: IActionButtonPopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [menuPosition, setMenuPosition] = useState<any>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setMenuPosition(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
    setAnchorEl(event.currentTarget);
  };
  const handleMenuItemSelect = (action: string) => {
    return () => {
      onChange(action);
      handleClose();
    };
  };

  return (
    <>
      <CustomButtonStyled disabled={disabled} outlineButton onClick={handleClick}>
        Action
        <KeyboardArrowDownIconStyled />
      </CustomButtonStyled>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {items.map(({ label, value }, index) => (
          <MenuItemStyled key={index} value={value} onClick={handleMenuItemSelect(value)}>
            {label}
          </MenuItemStyled>
        ))}
      </Popover>
    </>
  );
};

export const ActionButton = styled(Button)<{ variants: 'ghost' | 'yellow' | 'outlined' }>`
  ${({ theme, variants, disabled }) => css`
    font-size: ${theme.fonts.textSBold.fontSize};
    font-weight: ${theme.fonts.textSBold.fontWeight};
    width: 120px;
    height: 44px;
    border-radius: 6px;
    background-color: ${variants === 'yellow' ? theme.palette.yellow?.[900] : theme.palette.common.white};
    color: ${variants === 'yellow' ? theme.palette.common.white : theme.palette.gray?.[60]};
    ${variants === 'ghost' &&
    css`
      border: 1px solid ${theme.palette.lightGray?.[25]};
      color: ${theme.palette.gray?.[1000]};
    `}
    text-transform: capitalize;
    ${variants === 'yellow' &&
    css`
      &:hover {
        background-color: ${theme.palette.yellow?.[900]};
      }
    `}
    ${variants === 'outlined' &&
    css`
      border: 1px solid ${theme.palette.yellow?.[900]};
      color: ${theme.palette.yellow?.[900]};
    `}
    ${disabled &&
    css`
      background-color: #dcdcdd;
      border: none;
    `}
  `}
`;

export const KeyboardArrowDownIconStyled = styled(KeyboardArrowDownIcon)(() => ({
  marginLeft: 12,
}));
export const CustomButtonStyled = styled(CustomButton)(({ theme }) => ({
  width: '120px !important',
  height: '44px !important',
}));
