import React, { useMemo } from 'react';

import { Box } from '@material-ui/core';
import {
  createAlignPlugin,
  createBoldPlugin,
  createDndPlugin,
  createExitBreakPlugin,
  createFontBackgroundColorPlugin,
  createFontColorPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createHistoryPlugin,
  createImagePlugin,
  createItalicPlugin,
  createKbdPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createPlateComponents,
  createReactPlugin,
  createSelectOnBackspacePlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  ELEMENT_IMAGE,
  ELEMENT_PARAGRAPH,
  MARK_BG_COLOR,
  MARK_COLOR,
  Plate,
  PlatePlugin,
  SPEditor,
  StyledElement,
  StyledLeaf,
  withProps,
  withStyledProps,
} from '@udecode/plate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import Banner from 'src/components/banner';
import { HeaderMobileChinese } from 'src/components/headerMobileChinese';
import { editablePreviewProps, optionsExitBreakPlugin } from 'src/components/slateEditor/config/pluginOptions';
import useFetchMe from 'src/hooks/use-fetch-me';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import MembershipBanner from 'src/medias/images/membership-banner.jpg';
import useResponsive from 'src/utils/responsive';

import { Content } from './membership.styled';

let components = createPlateComponents({
  [MARK_COLOR]: withStyledProps(StyledLeaf, {
    leafProps: {
      [MARK_COLOR]: ['color'],
    },
  }),
  [MARK_BG_COLOR]: withStyledProps(StyledLeaf, {
    leafProps: {
      [MARK_BG_COLOR]: ['backgroundColor'],
    },
  }),
  [ELEMENT_PARAGRAPH]: withProps(StyledElement, {
    styles: {
      root: {
        fontSize: '1rem',
      },
    },
  }),
});

type TEditor = SPEditor & ReactEditor & HistoryEditor;
const Membership = ({ data, titleMobile }: { data: any[]; titleMobile?: string }) => {
  useScrollToTop();
  // const { path } = useRouteMatch();
  const responsive = useResponsive();
  const { me } = useFetchMe();
  const pluginsMemo: PlatePlugin<TEditor>[] = useMemo(() => {
    const plugins = [
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createMediaEmbedPlugin(),
      createAlignPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createDndPlugin(),
      createExitBreakPlugin(optionsExitBreakPlugin),
      createSelectOnBackspacePlugin({
        allow: [ELEMENT_IMAGE],
      }),
    ];

    return plugins;
  }, []);

  return (
    <Box style={{ scrollBehavior: 'smooth', scrollPaddingTop: 500 }} pt={responsive.isTabletOrMobile ? 15 : 3.75}>
      {responsive.isTabletOrMobile && <HeaderMobileChinese title={titleMobile || `会籍`} userProfile={me} />}
      <Banner image={MembershipBanner} title={''} description={''} responsive={responsive} />
      <Content responsive={responsive}>
        <Plate
          id={`membership`}
          plugins={pluginsMemo}
          components={components}
          editableProps={editablePreviewProps}
          value={data}
        />
      </Content>
    </Box>
  );
};

export default Membership;
