import httpClient from 'src/configs/http-client';
import { IResponse, IResponseList } from 'src/types';
import { convertURL } from 'src/utils/common';

import { ISearchTicketAttendance, ITicketAttendance } from './ticket.interface';

export const verifyTicket = async (body: { ticketCode: string }) => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/ticket/verify`, body);
  return response?.data;
};

export const getTicketsAttendance = async ({
  search,
  currentPage,
  limit,
}: {
  search?: ISearchTicketAttendance;
  currentPage: number;
  limit: number;
}) => {
  const response = await httpClient.get<IResponseList<ITicketAttendance>>(
    `${process.env.REACT_APP_API_URL}/ticket?eventName=${
      search?.eventName ? convertURL(search.eventName) : ''
    }&ticketCode=${search?.ticketCode ? convertURL(search.ticketCode) : ''}&ticketStatus=${
      search?.ticketStatus ?? ''
    }&eventCategory=${search?.eventCategory ?? ''}&fromDate=${
      search?.fromDate ? new Date(search?.fromDate).toLocaleDateString('en-GB') : ''
    }&toDate=${
      search?.toDate ? new Date(search?.toDate).toLocaleDateString('en-GB') : ''
    }&page=${currentPage}&limit=${limit}`,
  );
  return response?.data;
};

export const checkInTicket = async (body: { ticketCode: string }) => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/ticket/check-in`, body);
  return response?.data;
};

export const resendTicket = async (eventId: string, ids: any) => {
  const response = await httpClient.patch<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/resend-ticket`,
    {
      ids,
    },
  );
  return response?.data;
};

export const markAsAttended = async (eventId: string, ids: any) => {
  const response = await httpClient.patch<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/mark-attended`,
    {
      ids,
    },
  );
  return response?.data;
};
