import React from 'react';

import { Switch, SwitchClassKey, SwitchProps, Theme } from '@material-ui/core';
import { withStyles, createStyles } from '@material-ui/core/styles';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

export const EventSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 40,
      height: 20,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      color: theme.palette.yellow?.[900],
      padding: 1,
      '&$focusVisible $thumb': {
        color: theme.palette.yellow?.[900],
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 18,
      height: 18,
    },
    track: {
      borderRadius: 19,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: '#fff',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
