import React from 'react';

import { Box, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { useSelector } from 'react-redux';

import { CustomPagination } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import NSAATable, { INSAAColumn, INSAADataSource } from 'src/components/table';
import { CALL_SUCCESS, FORMAT_DATE_DMY, FORMAT_TIME_AM_PM } from 'src/constants/common';
import VerifyIcon from 'src/medias/icons/verify-icon.png';
import { ConfirmModal } from 'src/pages/user-management/components/ConfirmModal';
import { Actions, IconButtonStyled, ImageIconStyled, Title } from 'src/pages/user-management/types';
import { checkInTicket } from 'src/services/tickets';
import { ISearchTicketAttendance, ITicketAttendance } from 'src/services/tickets/ticket.interface';
import { IPagination } from 'src/services/users';
import { selectUserProfile } from 'src/store/authentication/selector';
import { convertSlugToUpperCase, intRegex, shallowEqual } from 'src/utils/common';

import { ButtonStatusTicket, defaultSearchTicket } from '../types';

interface ITable {
  tickets: ITicketAttendance[];
  data: IPagination | undefined;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  loading?: boolean;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  getData({
    search,
    currentPage,
    limit,
  }: {
    search: ISearchTicketAttendance;
    currentPage: number;
    limit: number;
  }): Promise<void>;
  currentPage: number;
  search: ISearchTicketAttendance;
}

const Listing = ({ tickets, data, currentPage, setCurrentPage, loading, limit, setLimit, getData, search }: ITable) => {
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [disableSearchPage, setDisableSearchPage] = React.useState(false);
  const [searchPage, setSearchPage] = React.useState('');
  const userProfile = useSelector(selectUserProfile);
  const [openConfirmActionModal, setOpenConfirmActionModal] = React.useState(false);
  const [actionSelected, setActionSelected] = React.useState('');
  const [isActionSuccess, setActionSuccess] = React.useState(false);
  const [isActionFailed, setActionFailed] = React.useState(false);
  const [messageResponse, setMessageResponse] = React.useState('');
  const [ticketSelected, setTicketSelected] = React.useState<ITicketAttendance>();

  const handleChangePagination = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || e.target.value === '0') {
      setSearchPage('');
      return;
    }
    if (e.target.name === 'searchPage') {
      if (data?.totalPages) {
        if (!intRegex.test(e.target.value)) {
          setDisableSearchPage(true);
        } else {
          if (Number(e.target.value) > data.totalPages) {
            setDisableSearchPage(true);
          } else {
            setDisableSearchPage(false);
            setSearchPage(e.target.value);
          }
        }
      } else {
        setDisableSearchPage(true);
      }
    }
    if (e.target.name === 'records/page') {
      setLimit(Number(e.target.value));
      setSearchPage('');
      setCurrentPage(1);
    }
  };

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setSearchPage(valueTrimmed);
  };

  const _onCloseModalConfirmAction = () => {
    setOpenConfirmActionModal(false);
    setTicketSelected(undefined);
  };

  const _onSelectedAction = async () => {
    setLoadingAction(true);

    if (actionSelected === 'check-in-ticket') {
      if (ticketSelected) {
        const response = await checkInTicket({
          ticketCode: ticketSelected.code,
        });
        if (response.statusCode === CALL_SUCCESS) {
          getData({ search, currentPage, limit });
          setActionSuccess(true);
          setMessageResponse(response.message);
        } else {
          setActionFailed(true);
          setMessageResponse(response.message);
        }
        setLoadingAction(false);
        _onCloseModalConfirmAction();
        return;
      }
    }
  };

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const onClickChangePage = () => {
    setCurrentPage(Number(searchPage));
  };

  const columns = React.useMemo(() => {
    const hasRole =
      (userProfile && userProfile?.roleName === 'membership-admin') ||
      (userProfile && userProfile?.roleName === 'secretariat-admin');

    const columns: INSAAColumn[] = [
      {
        key: 'EventName',
        dataIndex: 'EventName',
        title: 'Event Name',
        width: 250,
      },
      {
        key: 'EventCategory',
        dataIndex: 'EventCategory',
        title: 'Event Category',
        width: 130,
        ellipsis: true,
      },
      {
        key: 'StartDate',
        dataIndex: 'StartDate',
        title: 'Start Date',
        width: 60,
      },
      {
        key: 'TicketCode',
        dataIndex: 'TicketCode',
        title: 'Ticket Code',
        width: 60,
      },
      {
        key: 'TicketStatus',
        dataIndex: 'TicketStatus',
        title: 'Ticket Status',
        width: 85,
        render: (value, record) => {
          let variants: 'pending' | 'checked-in' | 'deactivated' | undefined =
            value === 'pending'
              ? 'pending'
              : value === 'checked-in'
              ? 'checked-in'
              : value === 'deactivated'
              ? 'deactivated'
              : undefined;
          return <ButtonStatusTicket variants={variants}>{convertSlugToUpperCase(value)}</ButtonStatusTicket>;
        },
      },
      {
        key: 'CheckInTime',
        dataIndex: 'CheckInTime',
        title: 'Check-in Time',
        width: 115,
      },
      {
        key: 'UpdateBy',
        dataIndex: 'UpdateBy',
        title: 'Update By',
        width: 115,
      },
      {
        key: 'Actions',
        title: '',
        dataIndex: ' Actions',
        width: 50,
        render: (value, record) => {
          return (
            <Actions>
              {record.TicketStatus === 'pending' && (
                <Tooltip title="CheckIn">
                  <IconButtonStyled
                    onClick={() => {
                      setTicketSelected(record.id);
                      setActionSelected('check-in-ticket');
                      setOpenConfirmActionModal(true);
                    }}
                    disabled={!hasRole}
                  >
                    <ImageIconStyled src={VerifyIcon} />
                  </IconButtonStyled>
                </Tooltip>
              )}
            </Actions>
          );
        },
      },
    ];
    return columns;
  }, [userProfile]);

  const dataSource = React.useMemo(() => {
    const dataSource = tickets.map((item, idx) => ({
      key: idx,
      id: item,
      EventName:
        item?.usersToEvent.event?.title && item?.usersToEvent?.event?.title.length > 55
          ? item.usersToEvent?.event?.title.slice(0, 55) + '...'
          : item?.usersToEvent?.event?.title,
      EventCategory: convertSlugToUpperCase(item.usersToEvent?.event?.category ?? ''),
      StartDate: item.usersToEvent?.event?.start_time
        ? moment(+item.usersToEvent?.event?.start_time).format(FORMAT_DATE_DMY)
        : '',
      TicketCode: item.code,
      TicketStatus: item.status,
      CheckInTime: item?.checkinTime ? moment(+item?.checkinTime).format(FORMAT_TIME_AM_PM) : '',
      UpdateBy: item?.updatedUser
        ? (item?.updatedUser?.surname + ', ' + item?.updatedUser?.givenName)?.length > 25
          ? item?.updatedUser?.surname + ', ' + item?.updatedUser?.givenName?.slice(0, 25) + '...'
          : item?.updatedUser?.surname + ', ' + item?.updatedUser?.givenName
        : '',
    }));
    return dataSource as unknown as INSAADataSource[];
  }, [tickets]);

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="left">
        <Title>{shallowEqual(search, defaultSearchTicket) ? `Listing` : `Results`}</Title>
      </Box>
      <Box mt={2} mb={2}>
        <NSAATable loading={loading} columns={columns} dataSource={dataSource} />
      </Box>
      <CustomPagination
        itemName={'ticket(s)'}
        data={data}
        limit={limit}
        handleChangePagination={handleChangePagination}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onClickChangePage={onClickChangePage}
        disableSearchPage={disableSearchPage || searchPage === ''}
        searchPage={searchPage}
        onBlurField={onBlurField}
      />
      {openConfirmActionModal && (
        <ConfirmModal
          open={openConfirmActionModal}
          handleClose={_onCloseModalConfirmAction}
          onSubmit={_onSelectedAction}
          actionSelected={actionSelected}
          disabled={loadingAction}
        />
      )}
      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </Box>
  );
};

export default Listing;
