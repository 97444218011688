import { Box, IconButton } from '@material-ui/core';
import styled from 'styled-components';

export const Actions = styled(Box)`
  display: flex;
  gap: 5px;
`;
export const IconButtonStyled = styled(IconButton)(() => ({
  padding: 2,
}));
export const ImageIconStyled = styled.img(() => ({}));
