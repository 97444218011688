/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';

import { Box, Popover } from '@material-ui/core';
import { find } from 'lodash';
import moment from 'moment';

import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import NSAATable, { INSAAColumn, INSAADataSource, INSAATableSearch } from 'src/components/table';
import { CALL_SUCCESS, FORMAT_DATE_DMY } from 'src/constants/common';
import { EventSwitch } from 'src/pages/event-management/shared/switch';
import { postCMSHomepage, ICmsHomepage, getCMSHomepage, ISearchCMSHomepage } from 'src/services/cms';

import {
  CustomButtonStyled,
  KeyboardArrowDownIconStyled,
  MenuItemStyled,
  MOCK_UP_FAIL_RESPONSE,
  NavLinkCustom,
  Title,
} from '../type';

interface IHomepageCMSList {
  data: ICmsHomepage[];
  setData: any;
  loading: boolean;
  searchData: ISearchCMSHomepage;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSearch: React.Dispatch<React.SetStateAction<ISearchCMSHomepage>>;
  searchValue: ISearchCMSHomepage;
  isOnSearch: boolean;
  _setIsOnSearch: React.Dispatch<React.SetStateAction<boolean>>;
}
interface IParams {
  action: string;
  events: string[];
  news: string[];
}
interface IDataSource {
  key: number;
  id: string;
  Title: string;
  Type: string;
  StartDate: string;
  SetAsFeature: string;
}
let homepageAction = [
  { action: 'Set as feature item', isDisable: false },
  { action: 'Remove as feature item', isDisable: true },
];
function HomepageCMSList({
  data,
  setData,
  loading,
  searchData,
  setLoading,
  setSearch,
  searchValue,
  isOnSearch,
  _setIsOnSearch,
}: IHomepageCMSList) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [menuPosition, setMenuPosition] = React.useState<{ top: number; left: number } | null>(null);
  const [idsSelected, setIdsSelected] = React.useState<any>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuPosition(null);
  };
  const _onSelectAction = (action: string) => async () => {
    setIsLoading(true);
    if (idsSelected && idsSelected.length > 0) {
      let params: IParams = {
        action: action === 'Set as feature item' ? 'set' : 'remove',
        events: [],
        news: [],
      };

      idsSelected.forEach((item: string) => {
        const findData = find(data, (obj: ICmsHomepage) => {
          return obj.id === item;
        });
        if (findData && findData.is_event && findData.id) return params.events.push(findData.id);
        else if (findData?.id) {
          return params.news.push(findData.id);
        }
      });

      const response = await postCMSHomepage(params);
      if (response.statusCode === CALL_SUCCESS) {
        setSearch({ ...searchValue });
      }
      setAnchorEl(null);
      setMenuPosition(null);
    }
    setIsLoading(false);
  };
  const setFeature = useCallback(
    (value: IDataSource, currentFeatureValue: boolean) => async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      setIsLoading(true);
      let params: IParams = {
        action: currentFeatureValue ? 'remove' : 'set',
        events: [],
        news: [],
      };
      if (value?.Type === 'Events & Activities') {
        params.events.push(value.id);
      }
      if (value?.Type === 'News') {
        params.news.push(value.id);
      }
      const response = await postCMSHomepage(params);
      if (response.statusCode === CALL_SUCCESS) {
        setSearch({ ...searchData });
      }
      setIsLoading(false);
    },
    [],
  );
  const onSelectedItems = (ids: string) => {
    setIdsSelected(ids);
  };
  const fetchData = useCallback(
    async (search: INSAATableSearch) => {
      setLoading(true);
      const defaultPage = 1;
      const params = {
        page: isOnSearch ? defaultPage : search.page,
        limit: search.limit,
      };
      const response = await getCMSHomepage({ ...params, search: { ...searchValue } });

      if (response?.statusCode === CALL_SUCCESS && response.data) {
        setData(response.data.items);
        _setIsOnSearch(false);
        setLoading(false);
        return response;
      } else {
        setLoading(false);
        return MOCK_UP_FAIL_RESPONSE;
      }
    },
    [searchData, isOnSearch],
  );
  const builderColumns = useCallback(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'Title',
        dataIndex: 'Title',
        title: 'Title',
        width: 150,
        ellipsis: true,
        render: (value, record) => {
          if (record.Type === 'Events & Activities') {
            return (
              <div>
                <NavLinkCustom target="_blank" to={`/events/${record.id}`}>
                  {value}
                </NavLinkCustom>
              </div>
            );
          } else {
            return (
              <div>
                <NavLinkCustom target="_blank" to={`/news/${record.id}`}>
                  {value}
                </NavLinkCustom>
              </div>
            );
          }
        },
      },
      {
        key: 'Type',
        dataIndex: 'Type',
        title: 'Type',
        width: 115,
      },
      {
        key: 'StartDate',
        dataIndex: 'StartDate',
        title: 'Publish Date/Start Date',
        width: 115,
      },
      {
        key: 'SetAsFeature',
        dataIndex: 'SetAsFeature',
        title: 'Set as feature',
        width: 115,
        render: (value, record) => {
          return <EventSwitch checked={record.SetAsFeature} onClick={setFeature(record, record.SetAsFeature)} />;
        },
      },
    ];
    return columns;
  }, []);
  const builderDataSource = (data: any[]) => {
    const dataSource = data.map((item: ICmsHomepage, idx: number) => ({
      key: idx,
      id: item.id,
      Title: item.title,
      Type: item.is_event ? 'Events & Activities' : 'News',
      StartDate: item.start_time
        ? moment(+item.start_time).format(FORMAT_DATE_DMY)
        : moment.utc(item.updated_at).format(FORMAT_DATE_DMY),
      SetAsFeature: item.is_feature_item === 'yes' ? true : false,
    }));
    return dataSource as unknown as INSAADataSource[];
  };
  return (
    <Box>
      <LoadingCustom loading={isLoading} loadingIcon />
      <Box display="flex" justifyContent="space-between" alignItems="left">
        <Title>Listing</Title>
        <Box>
          <CustomButtonStyled disabled={idsSelected && idsSelected.length === 0} outlineButton onClick={handleClick}>
            Action
            <KeyboardArrowDownIconStyled />
          </CustomButtonStyled>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {homepageAction.map((action) => {
              const SelectedItems: string[] | null | undefined = [];
              if (idsSelected && idsSelected.length > 0) {
                idsSelected.forEach((id: string) => {
                  const findData = find(data, (obj) => {
                    return obj.id === id;
                  });
                  if (findData?.is_feature_item) {
                    SelectedItems.push(findData.is_feature_item);
                  }
                });
              }
              let isAllFeature = false;
              let isAllNotFeature = false;
              if (SelectedItems && SelectedItems.length > 0) {
                isAllFeature = SelectedItems.every((value: string) => value === 'yes');
                isAllNotFeature = SelectedItems.every((value: string) => value === 'no');
              }
              if (isAllFeature && !isAllNotFeature) {
                homepageAction[0].isDisable = true;
                homepageAction[1].isDisable = false;
              }
              if (!isAllFeature && isAllNotFeature) {
                homepageAction[0].isDisable = false;
                homepageAction[1].isDisable = true;
              }
              if (!isAllFeature && !isAllNotFeature) {
                homepageAction[0].isDisable = true;
                homepageAction[1].isDisable = true;
              }
              return (
                <MenuItemStyled
                  onClick={_onSelectAction(action.action)}
                  key={action.action}
                  value={action.action}
                  disabled={action.isDisable}
                >
                  {action.action}
                </MenuItemStyled>
              );
            })}
          </Popover>
        </Box>
      </Box>
      <Box mt={2} mb={2}>
        <NSAATable
          loading={loading}
          builderColumns={builderColumns}
          builderDataSource={builderDataSource}
          isShowCheckBoxes
          isShowPagination={true}
          fetchData={fetchData}
          onSelectedItems={onSelectedItems}
          isSearchReset={isOnSearch}
        />
      </Box>
    </Box>
  );
}
export default HomepageCMSList;
