import React from 'react';

import { Box, Typography } from '@material-ui/core';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FORMAT_DATE_DMY } from 'src/constants/common';
import { IPost } from 'src/services/news';
import useResponsive from 'src/utils/responsive';

import { IResponsive } from '../home/homepageStyled';

const Title = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header5,
  wordWrap: 'break-word',
}));

const Description = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textS,
  marginTop: 12,
  wordWrap: 'break-word',
  color: theme.palette.gray?.[60],
}));

const TextTime = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textSs,
  textAlign: 'left',
  position: 'static',
  color: theme.palette.yellow?.[1000],
  marginBottom: 12,
}));

const Post = styled(Box)<{ width?: string }>`
  ${({ theme, width }) => css`
    position: relative;
    width: ${width ? width : '30%'};
    box-sizing: border-box;
    background-color: #fafafa;
    border-radius: 3px;
    overflow: hidden;
  `}
`;

const ThumbnailPost = styled.img`
  && {
    width: 100%;
    min-height: 250px;
    height: 250px;
    object-fit: contain;
    object-position: center bottom;
  }
`;

const NavLinkCustom = styled(NavLink)(({ theme }) => ({
  ...theme.fonts.header3,
  textDecoration: 'none',
  color: '#000000',
}));
const BoxContent = styled(Box)<{ responsive?: IResponsive }>(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: 250px;
  `,
);
const PostItem = ({ post, width, isChinesePage }: { post: IPost; width?: string; isChinesePage?: boolean }) => {
  const responsive = useResponsive();
  return (
    <Post key={post?.id} width={width}>
      <NavLink to={isChinesePage ? `/cn/news/${post.id}` : `/news/${post.id}`} style={{ textDecoration: 'none' }}>
        <ThumbnailPost src={post?.coverPhoto || 'https://i.stack.imgur.com/y9DpT.jpg'} />
        <BoxContent p={3} responsive={responsive}>
          <TextTime>{post?.publishTime && moment(+post?.publishTime).format(FORMAT_DATE_DMY)}</TextTime>
          <NavLinkCustom to={isChinesePage ? `/cn/news/${post.id}` : `/news/${post.id}`}>
            <Title>{post?.title?.length > 45 ? post?.title.slice(0, 45) + '...' : post?.title || 'Untitled'}</Title>
          </NavLinkCustom>
          <Description>
            {post?.shortDescription?.length > 70
              ? post?.shortDescription.slice(0, 70) + '...'
              : post?.shortDescription || 'No description'}
          </Description>
        </BoxContent>
      </NavLink>
    </Post>
  );
};

export default PostItem;
