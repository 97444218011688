import React from 'react';

import { Box, Grid, MenuItem } from '@material-ui/core';

import { CustomInputSelect, InputField } from 'src/components';
import { ISearchPayment } from 'src/services/payment';

import { CustomButtonStyled, defaultSearch, paymentStatus, paymentType, SearchSection } from '../type';

const LEAST_CHAR_FOR_SEARCH = 3;
const PAYMENT_ID_MAXLENGTH = 12;
interface ISearchPaymentProps {
  search: ISearchPayment;
  setSearch: React.Dispatch<React.SetStateAction<ISearchPayment>>;
  _onSearch: (searchData: ISearchPayment) => void;
  loading: boolean;
  _onReset: () => void;
  _setSearchValue: React.Dispatch<React.SetStateAction<ISearchPayment>>;
}
function SearchPayment({ _onSearch, loading, _onReset, _setSearchValue }: ISearchPaymentProps) {
  const [search, setSearch] = React.useState<any>(defaultSearch);
  const [isOnChangeSearch, setIsOnChangeSearch] = React.useState<boolean>(false);

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch({ ...search, [e.target.name]: e.target.value === 'all' ? '' : e.target.value });
    _setSearchValue({ ...search, [e.target.name]: e.target.value === 'all' ? '' : e.target.value });
    setIsOnChangeSearch(true);
  }
  const onReset = () => {
    _onReset();
    setSearch(defaultSearch);
    _setSearchValue(defaultSearch);
    setIsOnChangeSearch(false);
  };
  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    if (valueTrimmed === '') {
      setIsOnChangeSearch(false);
    }
    setSearch({ ...search, title: valueTrimmed });
    _setSearchValue({ ...search, title: valueTrimmed });
  };
  return (
    <SearchSection mb={4}>
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1} pr={2}>
              <InputField
                title="Payment ID"
                placeholder="Enter at least 3 characters"
                name="id"
                value={search.id}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                onBlur={onBlurField}
                InputProps={{
                  inputProps: {
                    maxLength: PAYMENT_ID_MAXLENGTH,
                  },
                }}
                error={search.id && search.id !== '' && search?.id?.length < LEAST_CHAR_FOR_SEARCH}
                errorMessage="Enter at least 3 characters"
              />
            </Box>
            <Box flex={1} pr={2}>
              <CustomInputSelect
                title="Payment Type"
                name="paymentType"
                value={search.paymentType === '' ? 'all' : search.paymentType}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {paymentType.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.value}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1} pr={2}>
              <InputField
                title="Payer Name"
                placeholder="Enter at least 3 characters"
                name="payerName"
                value={search.payerName}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
                error={search.payerName && search.payerName !== '' && search?.payerName?.length < LEAST_CHAR_FOR_SEARCH}
                errorMessage="Enter at least 3 characters"
              />
            </Box>
            <Box flex={1} pr={2}>
              <CustomInputSelect
                title=" Status"
                name="status"
                value={search.status === '' ? 'all' : search.status}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {paymentStatus.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {item.value}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end" mt={6}>
            {isOnChangeSearch && (
              <Box display="flex">
                <CustomButtonStyled disabled={loading} outlineButton onClick={onReset}>
                  Reset
                </CustomButtonStyled>
              </Box>
            )}
            <Box display="flex">
              <CustomButtonStyled
                disabled={
                  loading ||
                  (search.id && search.id !== '' && search?.id?.length < LEAST_CHAR_FOR_SEARCH) ||
                  (search.payerName && search.payerName !== '' && search?.payerName?.length < LEAST_CHAR_FOR_SEARCH)
                }
                onClick={_onSearch(search)}
              >
                Search
              </CustomButtonStyled>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </SearchSection>
  );
}

export default SearchPayment;
