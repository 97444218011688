import React, { useState } from 'react';

import { Box, CircularProgress, FormControlLabel, IconButton, RadioGroup, Typography } from '@material-ui/core';
import { FieldArray, Form, Formik } from 'formik';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';

import { NASSRadio } from 'src/components';
import ConfirmPopup from 'src/components/confirm';
import { DATE_PICKER_DISPLAY_FORMAT, PLACEHOLDER_DATE_PICKER } from 'src/constants/common';
import { INPUT_MAX_LENGTH_50, NATIONALITY } from 'src/constants/users.constants';
import { PROFILE_VALIDATION_MESSAGE } from 'src/constants/validation-message.constant';
import CloseIcon from 'src/icons/close';
import { Title } from 'src/pages/profile/components/additional-information.styled';
import {
  ActionButton,
  FormMenuItem,
  FormTextField,
  GeneralInformationDatePicker,
  Label,
} from 'src/pages/profile/components/general-information.styled';
import { Close } from 'src/pages/user-management/shared/user-detail.component';
import { IUser } from 'src/services/users';

export const HeaderSticky = styled(Box)`
  ${({ theme }) => css`
    position: sticky;
    top: 0;
    background-color: ${theme.palette.common.white};
    z-index: 20;
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
  `}
`;

export const FooterSticky = styled(Box)`
  display: flex;
  gap: 24px;
  position: sticky;
  bottom: 0;
  background: white;
  padding-bottom: 24px;
  padding-top: 24px;
  justify-content: center;
`;

export const BigTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header3.fontSize};
    font-weight: ${theme.fonts.header3.fontWeight};
    line-height: 32px;
  `}
`;

interface Props {
  onClose: () => void;
  onSave: (applicant: any) => void;
  onSaveAndVerify: (applicant: any) => void;
  isSaveLoading?: boolean;
  isSaveAndVerifyLoading?: boolean;
  applicant: IUser | null;
}

const AdditionalInformationPopup = ({
  onClose,
  onSave,
  onSaveAndVerify,
  applicant,
  isSaveLoading,
  isSaveAndVerifyLoading,
}: Props) => {
  const SAVE_MESSAGE = 'Are you sure you want to save this change?';
  const SAVE_AND_VERIFY_MESSAGE = 'Are you sure you want to save and verify this change?';
  const [isShowConfirmPopup, setShowConfirmPopup] = React.useState(false);
  const [confirmMessage, setConfirmMessage] = useState(SAVE_MESSAGE);

  const [isHaveChildren, setHaveChildren] = useState<boolean>(
    applicant?.child && applicant?.child.length > 0 ? true : false,
  );

  function handleTogglePopup() {
    setShowConfirmPopup((status) => !status);
  }

  return (
    <Box px={3}>
      <HeaderSticky>
        <BigTitle>Additional Information</BigTitle>
        <Close onClick={onClose}>
          <CloseIcon fill="white" />
        </Close>
      </HeaderSticky>
      <Box mb={1.8}>
        <Box mb={3}>
          <Title>Your Children Info</Title>
        </Box>
        <Label>Do You Have Children?</Label>
        <RadioGroup
          value={isHaveChildren ? 'yes' : 'no'}
          onChange={(e, v) => {
            setHaveChildren(v === 'no' ? false : true);
          }}
          row
        >
          <FormControlLabel value="yes" control={<NASSRadio />} label="Yes" />
          <Box width={160} />
          <FormControlLabel value="no" control={<NASSRadio />} label="No" />
        </RadioGroup>
      </Box>

      <Formik
        onSubmit={(values) => {}}
        validationSchema={Yup.object({
          child: Yup.array(
            Yup.object({
              surname: Yup.string().required(PROFILE_VALIDATION_MESSAGE.SURNAME),
              givenName: Yup.string().required(PROFILE_VALIDATION_MESSAGE.GIVEN_NAME),
              dateOfBirth: Yup.string().required(PROFILE_VALIDATION_MESSAGE.DOB),
            }),
          ),
        })}
        initialValues={{
          child:
            applicant?.child && applicant?.child?.length > 0
              ? applicant.child
              : [
                  {
                    surname: '',
                    givenName: '',
                    dateOfBirth: '',
                    nationality: 'singapore-citizen',
                    verificationStatus: 'unverified',
                  },
                ],
        }}
      >
        {({ values, touched, errors, isValid, handleChange, handleBlur, setFieldValue, submitForm }) => {
          const e = errors as any;
          const handleOkConfirmPopup = (confirmPopupName: 'SAVE' | 'SAVE_AND_VERIFY') => async () => {
            await submitForm();
            if (!Object.keys(errors).length) {
              if (confirmPopupName === 'SAVE') {
                setConfirmMessage(SAVE_MESSAGE);
                setShowConfirmPopup(true);
              }
              if (confirmPopupName === 'SAVE_AND_VERIFY') {
                setConfirmMessage(SAVE_AND_VERIFY_MESSAGE);
                setShowConfirmPopup(true);
              }
            }
          };
          const handleConfirmPopup = () => {
            if (confirmMessage === SAVE_MESSAGE) {
              if (!isHaveChildren) {
                onSave({
                  child: [],
                });
                return;
              }
              onSave(values);
              setShowConfirmPopup(false);
            }
            if (confirmMessage === SAVE_AND_VERIFY_MESSAGE) {
              if (!isHaveChildren) {
                onSaveAndVerify({
                  child: [],
                });
                return;
              }
              onSaveAndVerify(values);
              setShowConfirmPopup(false);
            }
          };
          return (
            <Form>
              <ConfirmPopup
                open={isShowConfirmPopup}
                title="Notification"
                content={confirmMessage}
                onCancel={handleTogglePopup}
                onOk={handleConfirmPopup}
                btnCancelVariant="outlined"
                btnOkVariant="filled"
              />
              {isHaveChildren && values.child.length > 0 && (
                <Box mb={0.5} display="flex" style={{ gap: 16 }}>
                  <Box flex="1">
                    <Label>Child’s Surname</Label>
                  </Box>
                  <Box flex="1">
                    <Label>Child’s GivenName</Label>
                  </Box>
                  <Box flex="1">
                    <Label>Child’s Date Of Birth</Label>
                  </Box>
                  <Box flex="1">
                    <Label>Child’s Nationality</Label>
                  </Box>
                  <Box flex="1.3" />
                </Box>
              )}
              {isHaveChildren && (
                <FieldArray name="child">
                  {({ push, remove }) => {
                    return (
                      <>
                        {values.child.map((item, index) => {
                          return (
                            <Box mb={3} display="flex" style={{ gap: 16 }}>
                              <Box flex="1" flexShrink="0">
                                <FormTextField
                                  variant="outlined"
                                  fullWidth
                                  value={item.surname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name={`child[${index}].surname`}
                                  inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
                                  error={touched?.child?.[index]?.surname && !!e?.child?.[index]?.surname}
                                  helperText={touched?.child?.[index]?.surname && e?.child?.[index]?.surname}
                                />
                              </Box>
                              <Box flex="1" flexShrink="0">
                                <FormTextField
                                  variant="outlined"
                                  fullWidth
                                  value={item.givenName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name={`child[${index}].givenName`}
                                  inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
                                  error={touched?.child?.[index]?.givenName && !!e?.child?.[index]?.givenName}
                                  helperText={touched?.child?.[index]?.givenName && e?.child?.[index]?.givenName}
                                />
                              </Box>
                              <Box flex="1" flexShrink="0">
                                <GeneralInformationDatePicker
                                  fullWidth
                                  autoOk
                                  variant="inline"
                                  inputVariant="outlined"
                                  format={DATE_PICKER_DISPLAY_FORMAT}
                                  value={item.dateOfBirth}
                                  placeholder={PLACEHOLDER_DATE_PICKER}
                                  InputProps={{ readOnly: true }}
                                  InputAdornmentProps={{ position: 'end' }}
                                  error={touched?.child?.[index]?.dateOfBirth && !!e?.child?.[index]?.dateOfBirth}
                                  invalidDateMessage={
                                    (touched?.child?.[index]?.dateOfBirth && e?.child?.[index]?.dateOfBirth) || ''
                                  }
                                  onChange={(date) => {
                                    if (!date) return;
                                    setFieldValue(`child[${index}]`, {
                                      ...values.child[index],
                                      dateOfBirth: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                                    });
                                  }}
                                />
                              </Box>
                              <Box flex="1" flexShrink="0">
                                <FormTextField
                                  ellipsis
                                  variant="outlined"
                                  name="nationality"
                                  fullWidth
                                  size="small"
                                  value={item.nationality}
                                  onChange={(e) => {
                                    setFieldValue(`child[${index}]`, {
                                      ...values.child[index],
                                      nationality: e.target.value,
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  select={true}
                                  SelectProps={{
                                    MenuProps: {
                                      anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      },
                                      getContentAnchorEl: null,
                                    },
                                  }}
                                >
                                  {NATIONALITY.map(({ value, label }, idx) => (
                                    <FormMenuItem key={idx} value={value}>
                                      {label}
                                    </FormMenuItem>
                                  ))}
                                </FormTextField>
                              </Box>
                              <Box
                                flex="1"
                                mb={
                                  touched?.child?.[index] &&
                                  !!(errors as any)?.child?.[index] &&
                                  touched?.child?.[index].givenName
                                    ? 3
                                    : 0.5
                                }
                                display="flex"
                                alignItems="center"
                                style={{ gap: 10 }}
                              >
                                <FormTextField
                                  ellipsis
                                  variant="outlined"
                                  name="verificationStatus"
                                  fullWidth
                                  size="small"
                                  value={item.verificationStatus}
                                  onChange={(e) => {
                                    setFieldValue(`child[${index}]`, {
                                      ...values.child[index],
                                      verificationStatus: e.target.value,
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  select={true}
                                  SelectProps={{
                                    MenuProps: {
                                      anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      },
                                      getContentAnchorEl: null,
                                    },
                                  }}
                                >
                                  <FormMenuItem value="verified">Verified</FormMenuItem>
                                  <FormMenuItem value="unverified">Unverified</FormMenuItem>
                                </FormTextField>
                                <Box flexShrink="0">
                                  <Close onClick={() => remove(index)}>
                                    <CloseIcon fill="white" />
                                  </Close>
                                </Box>
                              </Box>
                            </Box>
                          );
                        })}
                        <Box width="100%">
                          <ActionButton
                            variants="outlined"
                            style={{ width: '100%' }}
                            onClick={() =>
                              push({
                                surname: '',
                                givenName: '',
                                dateOfBirth: '',
                                nationality: 'singapore-citizen',
                                verificationStatus: 'unverified',
                              })
                            }
                          >
                            Add More Child
                          </ActionButton>
                        </Box>
                      </>
                    );
                  }}
                </FieldArray>
              )}
              <FooterSticky>
                <ActionButton onClick={onClose} variants="outlined">
                  Cancel
                </ActionButton>
                <ActionButton
                  disabled={isSaveLoading}
                  type="button"
                  variants="yellow"
                  onClick={handleOkConfirmPopup('SAVE')}
                >
                  {isSaveLoading && (
                    <IconButton>
                      <CircularProgress size={20} />
                    </IconButton>
                  )}
                  Save
                </ActionButton>
                <ActionButton
                  type="button"
                  variants="yellow"
                  disabled={isSaveAndVerifyLoading}
                  onClick={handleOkConfirmPopup('SAVE_AND_VERIFY')}
                >
                  {isSaveAndVerifyLoading && (
                    <IconButton>
                      <CircularProgress size={20} />
                    </IconButton>
                  )}
                  Save & Verify
                </ActionButton>
              </FooterSticky>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AdditionalInformationPopup;
