import React, { useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { useRouteMatch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import { HeaderMobile } from 'src/components/headerMobile';
import { HeaderMobileChinese } from 'src/components/headerMobileChinese';
import useFetchMe from 'src/hooks/use-fetch-me';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import useResponsive from 'src/utils/responsive';

import { data } from './data';
import HistoryCard from './shared/HistoryCard';

const HistoryWrapper = styled(Box)`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  margin: 0 16px;
`;

const HistoryControl = styled(Box)`
  display: flex;
  width: 230px;
  margin: 35px auto 89px auto;
  gap: 15px;
`;
const Item = styled(Box)<{ active?: boolean }>`
  ${({ theme, active }) => css`
    border-radius: 5px;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    & > svg {
      fill: white;
    }
    background: ${active ? theme.palette.yellow?.[900] : '#CFCFCF'};
  `}
`;
const Year = styled(Box)`
  ${({ theme }) => css`
    height: 45px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background: ${theme.palette.yellow?.[900]};
    border-radius: 5px;
    user-select: none;
  `}
`;

const HistoryCardContent = styled(Box)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    max-width: 578px;
    border-radius: 16px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.15);
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    background: white;
    padding: 29px 35px;
    margin-bottom: 115px;
    ${responsive.isTabletOrMobile &&
    css`
      border: 1px solid ${theme.palette.yellow?.[900]};
    `}
    ${responsive.isMobile &&
    css`
      max-width: 382px;
    `}
  `}
`;
const HistoryCardHeader = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header5.fontSize};
    line-height: ${theme.fonts.header5.lineHeight};
    font-weight: ${theme.fonts.header5.fontWeight};
    margin-bottom: 15px;
  `}
`;
const HistoryCardDescription = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.textM.fontSize};
    line-height: ${theme.fonts.textM.lineHeight};
    font-weight: ${theme.fonts.textM.fontWeight};
  `}
`;
const HistorySlide = styled(Box)`
  width: 1290px;
  max-width: 100%;
  margin: 26px auto 35px auto;
  overflow-x: hidden;
  transition: 0.4s;
`;
const HistoryCardContainer = styled(Box)<{ activeIdx: number; responsive?: any }>`
  ${({ theme, activeIdx, responsive }) => css`
    transition: 0.4s;
    gap: 29px;
    display: flex;
    margin-left: ${activeIdx <= 1 ? 0 : (activeIdx - 1) * -430}px;
    ${responsive.isMobile &&
    css`
      margin-left: ${+activeIdx < 1 ? 0 : activeIdx * -430}px;
    `}
  `}
`;

export const MobileHistoryHeader = styled(Box)`
  margin-top: 16px;
  color: #718096;
  text-align: justify;
`;

const History = () => {
  const history = useHistory();
  const isChinesePage = history.location.pathname.slice(0, 3) === '/cn';
  useScrollToTop();
  const [currentActive, setCurrentActive] = useState(0);
  const { path } = useRouteMatch();
  const responsive = useResponsive();
  const { me } = useFetchMe();
  function handleNext() {
    if ((responsive.isTablet || responsive.isDesktopOrLaptop) && currentActive === 0) {
      setCurrentActive((current) => (current < data.length - 1 ? current + 2 : current));
    } else {
      setCurrentActive((current) => (current < data.length - 1 ? current + 1 : current));
    }
  }
  function handlePrev() {
    setCurrentActive((current) => (current < 1 ? current : current - 1));
  }

  return (
    <Box pt={responsive.isTabletOrMobile ? 15 : 3.75}>
      {!responsive.isTabletOrMobile && <NSAABreadcrumbs path={path} />}
      <HistoryWrapper>
        {responsive.isTabletOrMobile && !isChinesePage ? (
          <HeaderMobile title={`About NSAA`} userProfile={me} />
        ) : (
          <HeaderMobileChinese title={`校友会历史`} />
        )}
        <HistorySlide>
          <HistoryCardContainer responsive={responsive} activeIdx={currentActive}>
            {data.map(({ image, header }, idx) => (
              <Box>
                <HistoryCard key={idx} image={image} header={header} responsive={responsive} />
                {responsive.isTabletOrMobile && <MobileHistoryHeader>{header}</MobileHistoryHeader>}
              </Box>
            ))}
          </HistoryCardContainer>
        </HistorySlide>
        {responsive.isDesktopOrLaptop && (
          <HistoryControl>
            <Item active={currentActive > 0} onClick={handlePrev}>
              <ChevronLeft fontSize="small" />
            </Item>
            <Year>{data[currentActive].age}</Year>
            <Item active={currentActive < data.length - 1} onClick={handleNext}>
              <ChevronRight fontSize="small" />
            </Item>
          </HistoryControl>
        )}
        <HistoryCardContent responsive={responsive}>
          <HistoryCardHeader>{data[currentActive].title}</HistoryCardHeader>
          <HistoryCardDescription>{data[currentActive].description}</HistoryCardDescription>
        </HistoryCardContent>
        {responsive.isTabletOrMobile && (
          <HistoryControl>
            <Item active={currentActive > 0} onClick={handlePrev}>
              <ChevronLeft fontSize="small" />
            </Item>
            <Year>{data[currentActive].age}</Year>
            <Item active={currentActive < data.length - 1} onClick={handleNext}>
              <ChevronRight fontSize="small" />
            </Item>
          </HistoryControl>
        )}
      </HistoryWrapper>
    </Box>
  );
};

export default History;
