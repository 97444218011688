import React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { CustomButton } from 'src/components';
import { RESET_PASSWORD } from 'src/constants/users.constants';
import errorLogo from 'src/medias/icons/Fail.svg';
import successLogo from 'src/medias/icons/icon-success.png';
import useResponsive from 'src/utils/responsive';

export const NYSImage = styled.img(({ theme }) => ({
  width: '100%',
  height: '100vh',
}));
export const Container = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'absolute',
  top: '40%',
  width: '100%',
  textAlign: 'center',
}));
export const Header = styled(Typography)<{ isTabletOrMobile?: boolean }>(({ theme, isTabletOrMobile }) => ({
  ...theme.fonts.textXL,
  fontSize: isTabletOrMobile ? theme.fonts.header3.fontSize : theme.fonts.textXL.fontSize,
  margin: '40px',
  color: '#2d2d2d',
}));
export const OkBtn = styled(CustomButton)(({ theme }) => ({}));
export const NavLinkCustom = styled(NavLink)(({ theme }) => ({
  textDecoration: 'none',
}));
export const SuccessLogo = styled.img<{ isTabletOrMobile?: boolean }>(({ theme, isTabletOrMobile }) => ({
  width: isTabletOrMobile ? '90px' : 'auto',
}));
export const ErrorLogo = styled.img<{ isTabletOrMobile?: boolean }>(({ theme, isTabletOrMobile }) => ({
  width: isTabletOrMobile ? '90px' : '131px',
}));

function ResetPasswordSuccess({ message }: { message: string }) {
  const { isTabletOrMobile } = useResponsive();
  return (
    <Grid xs={12}>
      <Box display="flex" position="relative">
        <NYSImage src="https://nsaa-images.s3.ap-southeast-1.amazonaws.com/1642753206-20210902_091153000000NSAA%20Thank%20You%20%28640x360%29.jpg" />
        <Container>
          {message === RESET_PASSWORD.tokenExpired ? (
            <ErrorLogo isTabletOrMobile={isTabletOrMobile} src={errorLogo} />
          ) : (
            <SuccessLogo isTabletOrMobile={isTabletOrMobile} src={successLogo} />
          )}
          <Header isTabletOrMobile={isTabletOrMobile}>
            {message === RESET_PASSWORD.sentEmail
              ? 'Email has been sent. Please check your mail!'
              : message === RESET_PASSWORD.tokenExpired
              ? 'Sorry, the link has expired!'
              : 'Your password is updated!'}
          </Header>
          {message === RESET_PASSWORD.sentEmail || RESET_PASSWORD.tokenExpired ? (
            <NavLinkCustom to="/">
              <OkBtn>GO TO HOMEPAGE</OkBtn>
            </NavLinkCustom>
          ) : (
            <NavLinkCustom to="/login">
              <OkBtn>LOGIN</OkBtn>
            </NavLinkCustom>
          )}
        </Container>
      </Box>
    </Grid>
  );
}

export default ResetPasswordSuccess;
