import React from 'react';

import Button from '@material-ui/core/Button';
import styled, { css } from 'styled-components';

const StyledButton = styled(Button)`
  && {
    background-color: #c5be8b;
    color: white;
    text-transform: none;
    border-radius: 6px;
    width: 193px;
    height: 44px;
    font-size: 14px;
    font-weight: 500;
    &:hover {
    }
    span {
      pointer-events: none;
    }
    span p {
      pointer-events: none;
    }
  }
`;

const WhiteButton = styled(Button)`
  && {
    background-color: white;
    text-transform: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    width: 193px;
    height: 44px;
    span {
      pointer-events: none;
    }
    span p {
      pointer-events: none;
    }
  }
`;

const OutlineButton = styled(Button)<{ width?: string; maxWidth?: string }>`
  && {
    background-color: transparent;
    border: 1px solid #c5be8b;
    text-transform: none;
    border-radius: 6px;
    width: 193px;
    height: 44px;
    color: #979168;
    font-size: 14px;
    font-weight: 500;
    ${({ maxWidth, width }) => css`
      ${maxWidth &&
      css`
        max-width: ${maxWidth};
      `}

      ${width &&
      css`
        width: ${width};
      `}
    `}
    span {
      pointer-events: none;
    }
    span p {
      pointer-events: none;
    }
  }
`;

const DisabledButton = styled(Button)`
  && {
    background-color: #dcdcdd;
    text-transform: none;
    border-radius: 6px;
    width: 193px;
    height: 44px;
    font-size: 14px;
    font-weight: 500;
    span {
      pointer-events: none;
    }
    span p {
      pointer-events: none;
    }
  }
`;

export const CustomButton = ({ children = false, outlineButton, disabled, white = false, ...rest }: any) => {
  if (disabled) {
    return (
      <DisabledButton disabled variant="contained" {...rest}>
        {children}
      </DisabledButton>
    );
  }
  if (outlineButton) {
    return (
      <OutlineButton variant="outlined" {...rest}>
        {children}
      </OutlineButton>
    );
  }
  if (white) {
    return (
      <WhiteButton variant="contained" {...rest}>
        {children}
      </WhiteButton>
    );
  }
  return (
    <StyledButton variant="contained" {...rest}>
      {children}
    </StyledButton>
  );
};
