import { Box, Button, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const defaultSearch = {
  gtkySearch: '',
  interviewId: '',
  eventId: '',
  userStatus: '',
};

export const defaultSearchInterview = {
  search: '',
};

export const actions = ['Assign Interviewer'];

export const LabelStyled = styled(Typography)(() => ({
  fontSize: 20,
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.6)',
}));

export const NoteStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  fontStyle: 'italic',
  color: 'rgba(0, 0, 0, 0.6)',
}));

export const SelectBox = styled(Box)(() => ({
  border: '1px solid #ccc',
  height: 246,
  borderRadius: 6,
}));

export const ApplicationActionButton = styled(Button)<{
  variants: 'ghost' | 'yellow' | 'outlined';
  isBig?: boolean;
  disabled?: boolean;
}>`
  ${({ theme, variants, isBig, disabled }) => css`
    font-size: ${theme.fonts.textSBold.fontSize};
    font-weight: ${theme.fonts.textSBold.fontWeight};
    width: 160px;
    height: 44px;
    border-radius: 6px;
    background-color: ${variants === 'yellow' ? theme.palette.yellow?.[900] : theme.palette.common.white};
    color: ${variants === 'yellow' ? theme.palette.common.white : theme.palette.gray?.[60]};
    ${variants === 'ghost' &&
    css`
      border: 1px solid ${theme.palette.lightGray?.[25]};
      color: ${theme.palette.gray?.[1000]};
    `}
    text-transform: capitalize;
    ${variants === 'yellow' &&
    css`
      &:hover {
        background-color: ${theme.palette.yellow?.[900]};
      }
    `}
    ${variants === 'outlined' &&
    css`
      border: 1px solid ${theme.palette.yellow?.[900]};
      color: ${theme.palette.yellow?.[900]};
    `}
      ${disabled &&
    css`
      && {
        background-color: #dcdcdd;
        border-color: transparent;
        span {
          pointer-events: none;
        }
        span p {
          pointer-events: none;
        }
      }
    `}
  `}
`;

export const Actions = styled(Box)`
  display: flex;
  gap: 16px;
  align-self: flex-end;
  justify-content: flex-end;
  flex: 1;
`;

export const ApplicantTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  color: #000000cc;
  padding-top: 64px;
  padding-bottom: 32px;
`;

export const Container = styled(Box)`
  display: flex;
  gap: 50px;
  margin-bottom: 28px;
  width: 100%;
  align-items: center;
`;
export const BoxStyled = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const GTKYContainer = styled(Box)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    ${responsive?.isTabletOrMobile &&
    css`
      width: 1440px;
    `}
    ${responsive?.isDesktopOrLaptop &&
    css`
      width: 100%;
    `}
  `}
`;
