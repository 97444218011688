import React from 'react';

import { Box, Grid, Link, makeStyles } from '@material-ui/core';

import LogoJSA from 'src/medias/images/main-nsaa-logo.png';
import SignInBackground from 'src/medias/images/signin-background.jpg';
import useResponsive from 'src/utils/responsive';

import { ContactBtn, Logo, NavLinkStyled, NoteGroup, NYSImage, SmallNote, Title } from '../types';

const useStyles = makeStyles(() => ({
  left: {
    height: 300,
    position: 'relative',
    marginTop: 110,
  },
  right: {
    top: 202,
    width: '100%',
  },
}));
function ForgotEmailMobile() {
  const classes = useStyles();
  const { isTablet } = useResponsive();
  return (
    <Grid container>
      <Grid item xs={12} className={classes.left}>
        <Box display="flex" width={'100%'} height={'100%'}>
          <NYSImage src={SignInBackground} objectFitContain />
          <Link href="/">
            <Logo src={LogoJSA} />
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.right}>
        <Box
          width={'100%'}
          height={'100%'}
          pl={2}
          pr={2}
          pt={8}
          display="flex"
          minHeight={isTablet ? '996px' : '588px'}
          flexDirection={'column'}
          justifyContent={'flex-start'}
          bgcolor="#fff"
        >
          <Title isTablet={isTablet} style={{ textAlign: 'center' }}>
            Don’t remember an email address you’ve used in connection with Nanyang School Alumni Association?
          </Title>
          <NoteGroup isTablet={isTablet}>
            <SmallNote isTablet={isTablet} style={{ textAlign: 'center' }}>
              No problem! Contact our alumni association department, and we’ll look you up.
            </SmallNote>
          </NoteGroup>
          <NavLinkStyled to="/contact-us">
            <ContactBtn variant="contained">CONTACT US</ContactBtn>
          </NavLinkStyled>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ForgotEmailMobile;
