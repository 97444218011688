import React, { useState } from 'react';

import { Box } from '@material-ui/core';

import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import ProfilePopupLayout from 'src/pages/profile/layout/profile-popup.layout';
import { getUserApplicantById, IApplicant, updateApplicantGtkyById, verifyUserById } from 'src/services/gtky-applicant';
import { dateString2String } from 'src/utils/common';

import GeneralInformationForm from '../forms/GeneralInformationForm';
import GeneralInformationPopup from '../popup/GeneralInformationPopup';

interface IGeneralInformationProps {
  applicant?: IApplicant;
  setApplicationInfo: React.Dispatch<React.SetStateAction<IApplicant | undefined>>;
}

const GeneralInformation = ({ applicant, setApplicationInfo }: IGeneralInformationProps) => {
  const [isOpenPopup, setOpenPopup] = useState(false);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [isSaveAndVerifyLoading, setSaveAndVerifyLoading] = useState(false);
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const handleTogglePopup = () => setOpenPopup((status) => !status);
  const handleSave = async (values: any) => {
    setSaveLoading(true);
    const response = await updateApplicantGtkyById(applicant?.id!, {
      ...values,
      skills: [{ ...values.skills, 'other-skills': values.skills['other-skills']?.toString().trim() || '' }],
      dateOfBirth: dateString2String(values?.dateOfBirth!),
    });

    if (response.statusCode === CALL_SUCCESS) {
      setSaveLoading(false);
      setMessage(response.message);
      setShowSnackBarSuccess(true);
      setOpenPopup(false);
      (async () => {
        const response = await getUserApplicantById(applicant?.id!);
        if (response.statusCode === CALL_SUCCESS) {
          setApplicationInfo(response.data);
        }
      })();
    } else {
      setSaveLoading(false);
      setMessage(response.message);
      setShowSnackBarError(true);
    }
  };
  const handleSaveAndVerify = async (values: any) => {
    setSaveAndVerifyLoading(true);
    Promise.all([
      updateApplicantGtkyById(applicant?.id!, {
        ...values,
        skills: [{ ...values.skills, 'other-skills': values.skills['other-skills']?.toString().trim() || '' }],
        dateOfBirth: dateString2String(values?.dateOfBirth!),
      }),
      verifyUserById(applicant?.id!),
    ]).then(
      (response) => {
        setSaveAndVerifyLoading(false);
        setMessage(response[0].message);
        setShowSnackBarSuccess(true);
        setOpenPopup(false);
        (async () => {
          const response = await getUserApplicantById(applicant?.id!);
          if (response.statusCode === CALL_SUCCESS) {
            setApplicationInfo(response.data);
          }
        })();
      },
      (err) => {
        setSaveAndVerifyLoading(false);
        setMessage(err[0]?.message);
        setShowSnackBarError(true);
      },
    );
  };
  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };
  return (
    <Box mt={4}>
      <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
      <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
      <GeneralInformationForm
        onOpenEditGeneralInformation={handleTogglePopup}
        applicant={applicant}
        showActionButton={true}
      />
      <ProfilePopupLayout open={isOpenPopup} onClose={handleTogglePopup}>
        <GeneralInformationPopup
          onClose={handleTogglePopup}
          applicant={applicant}
          onSave={handleSave}
          onSaveAndVerify={handleSaveAndVerify}
          isSaveLoading={isSaveLoading}
          isSaveAndVerifyLoading={isSaveAndVerifyLoading}
        />
      </ProfilePopupLayout>
    </Box>
  );
};

export default GeneralInformation;
