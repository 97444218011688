import React, { useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import {
  ELEMENT_IMAGE,
  PlatePlugin,
  Plate,
  createAlignPlugin,
  createBoldPlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createHistoryPlugin,
  createKbdPlugin,
  createImagePlugin,
  createItalicPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createReactPlugin,
  createSelectOnBackspacePlugin,
  createDndPlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  SPEditor,
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
} from '@udecode/plate';
import moment from 'moment';
import { useRouteMatch, useParams, useHistory } from 'react-router-dom';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';

import Banner from 'src/components/banner';
import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import ShareToButton from 'src/components/button/ShareToButton';
import { HeaderMobile } from 'src/components/headerMobile';
import { HeaderMobileChinese } from 'src/components/headerMobileChinese';
import { editablePreviewProps, optionsExitBreakPlugin } from 'src/components/slateEditor/config/pluginOptions';
import { CALL_SUCCESS, FORMAT_DATE_DMY } from 'src/constants/common';
import useFetchMe from 'src/hooks/use-fetch-me';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { IResponsive } from 'src/pages/home/homepageStyled';
import { getPost, IPost } from 'src/services/news';
import useResponsive from 'src/utils/responsive';

import FBLogo from '../../../medias/images/facebook-logo.png';
import TwitterLogo from '../../../medias/images/twitter-logo.png';
import { components } from '../../admin/components/Preview';

interface IPostDetailParams {
  id: string;
}
type TEditor = SPEditor & ReactEditor & HistoryEditor;

const DateTime = styled(Box)`
  ${({ theme }) => css`
    color: ${theme.palette.yellow?.[900]};
    margin-bottom: ${theme.spacing(1.5)}px;
  `}
`;
const Title = styled(Typography)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    margin-bottom: ${theme.spacing(3)}px;
    font-size: ${responsive?.isTabletOrMobile ? '20px' : '45px'};
    font-weight: 700;
    padding: ${responsive?.isTabletOrMobile ? '0px 16px' : '0'};
  `}
`;
const Logo = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 3px;
`;

const DividerLine = styled(Box)`
  ${({ theme }) => css`
    width: 100%;
    border: 1px solid #e8e7e7;
    margin-bottom: ${theme.spacing(4.5)}px;
  `}
`;
const NewsShare = styled(Box)`
  ${({ theme }) => css`
    color: #cfcfcf;
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing(3)}px;
    margin-bottom: ${theme.spacing(3)}px;
  `}
`;
export const PostDetail = () => {
  useScrollToTop();
  const history = useHistory();
  const responsive = useResponsive();
  const isChinesePage = history.location.pathname.slice(0, 3) === '/cn';
  const { me } = useFetchMe();
  const { path } = useRouteMatch();
  const { id } = useParams<IPostDetailParams>();
  const [post, setPost] = useState<IPost>();
  const pluginsMemo: PlatePlugin<TEditor>[] = useMemo(() => {
    const plugins = [
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createMediaEmbedPlugin(),
      createAlignPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createDndPlugin(),
      createExitBreakPlugin(optionsExitBreakPlugin),
      createSelectOnBackspacePlugin({
        allow: [ELEMENT_IMAGE],
      }),
    ];

    return plugins;
  }, []);

  useEffect(() => {
    (async () => {
      const response = await getPost(id);
      if (response.statusCode === CALL_SUCCESS && response.data) setPost(response.data);
    })();
  }, [id]);

  return (
    <Box pt={responsive.isTabletOrMobile ? 5 : 4}>
      {post ? (
        responsive.isTabletOrMobile && !isChinesePage ? (
          <HeaderMobile
            title={post?.title.length > 20 ? post?.title.slice(0, 20) + '...' : post?.title}
            userProfile={me}
          />
        ) : (
          <HeaderMobileChinese title={post?.title.length > 20 ? post?.title.slice(0, 20) + '...' : post?.title} />
        )
      ) : null}
      {!responsive.isTabletOrMobile && !isChinesePage ? (
        <NSAABreadcrumbs path={path} appendItems={[post?.title!]} />
      ) : null}
      {responsive.isTabletOrMobile ? (
        <Box maxWidth={846} mx="auto" my={10}>
          <Box>
            <Banner isNews responsive={responsive} image={post?.coverPhoto || ''} title="" description=" " />
            <Box display="flex" justifyContent="space-between" mt={2} pl={2} pr={2}>
              <DateTime>{moment(+post?.publishTime!).format(FORMAT_DATE_DMY)}</DateTime>
              <NewsShare>
                <Box mr={1}>Share on</Box>
                <ShareToButton children={<Logo src={FBLogo} />} url={history.location.pathname} facebook />
                <ShareToButton children={<Logo src={TwitterLogo} />} url={history.location.pathname} twitter />
              </NewsShare>
            </Box>
            <Title responsive={responsive}>{post?.title || ''}</Title>
          </Box>
          <Box pl={2} pr={2}>
            <Plate
              id={'NEWS_DETAIL'}
              plugins={pluginsMemo}
              components={components}
              editableProps={editablePreviewProps}
              value={post && post.description}
            />
          </Box>
        </Box>
      ) : (
        <Box maxWidth={846} mx="auto" my={10}>
          <Box mb={3}>
            <DateTime>{moment(+post?.publishTime!).format(FORMAT_DATE_DMY)}</DateTime>
            <Title>{post?.title || ''}</Title>
            <NewsShare>
              <Box mr={1}>Share on</Box>
              <ShareToButton children={<Logo src={FBLogo} />} url={history.location.pathname} facebook />
              <ShareToButton children={<Logo src={TwitterLogo} />} url={history.location.pathname} twitter />
            </NewsShare>
            <DividerLine />
            <Banner image={post?.coverPhoto || ''} title="" description="" isNews />
          </Box>
          <Plate
            id={'NEWS_DETAIL'}
            plugins={pluginsMemo}
            components={components}
            editableProps={editablePreviewProps}
            value={post && post.description}
          />
        </Box>
      )}
    </Box>
  );
};
