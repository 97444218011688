import React, { useEffect, useState } from 'react';

import { Box, Grid, Typography, Link, makeStyles, InputAdornment, IconButton, Theme, Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import _ from 'lodash';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { CustomButton, InputField } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import { RECAPTCHA_SITE_KEY } from 'src/constants/recaptcha';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import LogoJSA from 'src/medias/images/main-nsaa-logo.png';
import SignInBackground from 'src/medias/images/signin-background.jpg';
import { selectAuth } from 'src/store/authentication/slices';
import {
  isSpace,
  intRegex,
  containsNumericCharacters,
  containsUpperAndLowerCaseRegexp,
  validEmailRegex,
  containsSpecialCharacters,
} from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { logInWithPasswordAction } from '../../store/authentication/action';
import { selectLoginState, selectLoginStatus } from '../../store/authentication/selector';
import { useAppDispatch } from '../../store/hooks';

const TEXT_REQUIRE = 'This field is required';
const ERROR_PASSWORD_MESSAGE = 'Please input a valid password';
const ERROR_USERNAME_MESSAGE = 'Username must be an email address or a user-id has 10 number';
const MINIMUM_PASSWORD_LENGTH = 8;
const MAXIMUM_PASSWORD_LENGTH = 32;
const USER_ID_LENGTH = 10;

const useStyles = makeStyles(() => ({
  left: {
    height: 300,
    position: 'relative',
    marginTop: 110,
  },
  right: {
    top: 202,
  },
}));

export const NYSImage = styled.img<{ objectFitContain?: boolean }>(({ theme, objectFitContain }) => ({
  width: '100%',
  height: 300,
  ...(objectFitContain
    ? {
        objectFit: 'contain',
      }
    : {}),
}));

export const Logo = styled.img(({ theme }) => ({
  position: 'absolute',
  top: -50,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 70,
  height: 90,
}));

export const TitleLogin = styled(Typography)(({ theme, isTablet }: { theme: Theme; isTablet: boolean }) => ({
  marginBottom: 8,
  marginTop: 52,
  fontSize: isTablet ? 36 : 20,
  fontWeight: 700,
  color: '#979168',
}));

export const TitleSmall = styled(Typography)(({ theme }) => ({
  marginBottom: 32,
  ...theme.fonts.header3,
  color: theme.palette.yellow?.[900],
}));

const CustomLink = styled.div(({ theme }) => ({
  textAlign: 'right',
  marginTop: 8,
  marginBottom: 16,
}));

export const Form = styled.form(({ theme }) => ({
  width: '100% ',
}));

export const Note = styled(Typography)(({ theme, isTablet }: { theme: Theme; isTablet: boolean }) => ({
  fontSize: isTablet ? 18 : 16,
  fontWeight: isTablet ? 700 : 400,
  marginBottom: 32,
  color: '#333333',
}));

export const CustomTextS = styled(Typography)(({ theme, isTablet }: { theme: Theme; isTablet: boolean }) => ({
  color: 'rgba(113, 128, 150, 1)',
  fontSize: isTablet ? 16 : 12,
  fontWeight: 400,
}));

export const LinkButton = styled(Button)`
  ${({ theme, isTablet }: { theme: Theme; isTablet: boolean }) => css`
    color: ${theme.palette.yellow?.[900]};
    font-size: ${isTablet ? '18px' : '16px'};
    font-weight: ${isTablet ? 700 : 400};
    text-transform: none;
    margin-left: '4px';
  `}
`;

export const LoginMobile = () => {
  useScrollToTop();
  const classes = useStyles();
  const isLogin = useSelector(selectLoginState);
  const { loading } = useSelector(selectAuth);
  const { isTablet, isMobile } = useResponsive();

  const loginStatus = useSelector(selectLoginStatus);
  const dispatch = useAppDispatch();
  const [data, setData] = useState<{ username: string; password: string }>({ username: '', password: '' });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errors, setErrors] = useState({
    username: false,
    password: false,
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const environment = process.env.REACT_APP_ENV;

  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  const params = new URLSearchParams(window.location.search);
  let type: string | null;
  let eventId: string | null;
  if (params) {
    eventId = params.get('eventId');
    type = params.get('type');
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [e.target.name]: e.target.value });

    if (e.target.name === 'username') {
      if (validEmailRegex.test(e.target.value)) {
        setValidate('username', validEmailRegex.test(e.target.value) ? e.target.value : '');
        return;
      }

      if (e.target.value.length !== USER_ID_LENGTH || !intRegex.test(e.target.value)) {
        setValidate('username', '');
        return;
      } else {
        setValidate('username', e.target.value);
        return;
      }
    }

    if (e.target.name === 'password') {
      const passwordLongEnough = _.size(e.target.value) >= MINIMUM_PASSWORD_LENGTH;

      const isPasswordValid =
        passwordLongEnough &&
        containsUpperAndLowerCaseRegexp.test(e.target.value) &&
        containsSpecialCharacters.test(e.target.value) &&
        !isSpace(e.target.value) &&
        containsNumericCharacters.test(e.target.value);

      setValidate('password', isPasswordValid ? e.target.value : '');
    }
  }

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  }

  function _onClickSignUp() {
    window.location.href = '/signup';
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    dispatch(logInWithPasswordAction({ ...data, recaptchaToken }));
    setOpenSnackBar(true);
  }

  function handleClickShowPassword() {
    setShowPassword(!showPassword);
  }

  function handleClear() {
    setData({ ...data, username: '' });
  }

  function handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
  }

  const onChangeCaptcha = (token?: string | null) => {
    if (token) {
      setRecaptchaToken(token);
    }
  };

  useEffect(() => {
    if (isLogin) {
      if (type && (type === 'check-in' || type === 'check-out') && eventId) {
        window.location.href = `/check-in-check-out?type=${type}&eventId=${eventId}`;
      } else {
        let timeOut = setTimeout(() => {
          window.location.href = '/';
        }, 2000);

        return () => {
          clearTimeout(timeOut);
        };
      }
    }
  }, [isLogin]);
  useEffect(() => {
    if (environment === 'development') {
      setRecaptchaToken('development');
    }
  }, []);

  useEffect(() => {
    if (loading) {
      recaptchaRef?.current?.reset();
      if (environment !== 'development') {
        setRecaptchaToken('');
      }
    }
  }, [loading]);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.left}>
        <Box display="flex" width={'100%'} height={'100%'}>
          <NYSImage src={SignInBackground} objectFitContain />
          <Link href="/">
            <Logo src={LogoJSA} />
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.right}>
        <Box
          width={'100%'}
          height={'100%'}
          p={2}
          pl={isTablet ? '100px' : '16px'}
          pr={isTablet ? '100px' : '16px'}
          display="flex"
          bgcolor="#F9F9F4"
          minHeight={isTablet ? '996px' : '588px'}
        >
          <Form onSubmit={handleSubmit}>
            <TitleLogin isTablet={isTablet}>Login</TitleLogin>
            <Box>
              <CustomTextS isTablet={isTablet}>
                We have a new look, but there’s no need to create a new account if you already have an NSAA ID. You can
                continue to use your NSAA ID or your registered email address to set a new password.
              </CustomTextS>
            </Box>
            <Box mt={isTablet ? '48px' : '0px'}>
              <InputField
                value={data.username}
                title="Username"
                fullWidth
                type="text"
                name="username"
                onChange={handleChange}
                margin="dense"
                notice
                noticeYellow
                noticeTitle={<Typography color="inherit">{ERROR_USERNAME_MESSAGE}</Typography>}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClear} edge="end">
                        {data.username !== '' && <ClearIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={errors.username}
                errormessage={errors.username && ERROR_USERNAME_MESSAGE}
              />
            </Box>
            <Box mt={2} />
            <InputField
              fullWidth
              value={data.password}
              type={showPassword ? 'text' : 'password'}
              onChange={handleChange}
              title="Password"
              name="password"
              margin="dense"
              InputProps={{
                inputProps: {
                  maxLength: MAXIMUM_PASSWORD_LENGTH,
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} edge="end">
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={errors.password}
              errormessage={errors.password && data.password === '' ? TEXT_REQUIRE : ERROR_PASSWORD_MESSAGE}
            />
            <CustomLink>
              <Link style={{ color: '#C5BE8B' }} href="/forgot-password" underline="none">
                {'Forgot password?'}
              </Link>
            </CustomLink>
            <Box mt={'24px'} display="flex" justifyContent="center" alignItems="center">
              <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_SITE_KEY} onChange={onChangeCaptcha} />
            </Box>
            <Box
              mt={isTablet ? '89px' : '24px'}
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >
              <Note isTablet={isTablet}>
                Don’t have an account yet?
                <LinkButton isTablet={isTablet} onClick={_onClickSignUp} variant="text">
                  Sign up
                </LinkButton>
              </Note>
            </Box>
            {(isMobile || isTablet) && (
              <Box mb={5} display="flex" justifyContent="center">
                <CustomButton disabled={environment === 'production' && recaptchaToken === ''} type="submit">
                  LOG IN
                </CustomButton>
              </Box>
            )}
          </Form>
        </Box>
        {!loading && loginStatus.statusCode === CALL_SUCCESS && openSnackBar && (
          <SuccessSnackBar message={loginStatus.message} open={openSnackBar} handleClose={handleCloseSnackBar} />
        )}
        {!loading &&
          loginStatus.statusCode !== undefined &&
          loginStatus.statusCode !== CALL_SUCCESS &&
          openSnackBar && (
            <ErrorSnackBar message={loginStatus.message} open={openSnackBar} handleClose={handleCloseSnackBar} />
          )}
      </Grid>
    </Grid>
  );
};
