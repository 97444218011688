import jwt from 'jsonwebtoken';

export const localStorageHelper = {
  setItem: (key: string, value: any) => {
    let valueStringify = value;
    if (typeof value === 'object') {
      valueStringify = JSON.stringify(value);
    }
    localStorage.setItem(key, valueStringify);
  },
  getItem: (key: string, options?: { isObject: boolean }) => {
    const value = localStorage.getItem(key);
    if (value && options?.isObject) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    }
    return value;
  },
  removeItem: (key: string) => {
    localStorage.removeItem(key);
  },
  isLogin: () => {
    return !!localStorageHelper.getItem(process.env.REACT_APP_ACCESS_TOKEN_KEY!);
  },
  expired: () => {
    const MINI_SECOND = 1000;

    if (localStorageHelper.getItem(process.env.REACT_APP_ACCESS_TOKEN_KEY!)) {
      const decodedToken = jwt.decode(localStorageHelper.getItem(process.env.REACT_APP_ACCESS_TOKEN_KEY!), {
        complete: true,
      });
      const dateNow = new Date();
      const exp: number = decodedToken?.payload.exp ?? 0;

      // JWT exp is in seconds
      if (exp !== 0 && exp * MINI_SECOND < dateNow.getTime()) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },
};
