import React, { useEffect, useState } from 'react';

import { Box, Grid, MenuItem } from '@material-ui/core';

import { CustomInputSelect, InputField } from 'src/components';
import { CustomButtonStyled } from 'src/pages/user-management/types';
import { IEventGTKY, IInterview, ISearchGTKYApplication } from 'src/services/volunteer';
import { shallowEqual } from 'src/utils/common';

import { defaultSearch } from '../types';

const LEAST_CHAR_FOR_SEARCH = 2;

interface ISearchGTKY {
  _onSearch: (search: ISearchGTKYApplication) => () => void;
  _onReset: () => void;
  loading: boolean;
  searchData: ISearchGTKYApplication;
  interviewers: IInterview[];
  events: IEventGTKY[];
}

const userStatus = [
  { id: 'verified', title: 'Verified' },
  { id: 'unverified', title: 'UnVerified' },
];

const SearchGTKY = ({ events, interviewers, searchData, loading, _onSearch, _onReset }: ISearchGTKY) => {
  const [search, setSearch] = useState<ISearchGTKYApplication>(defaultSearch);

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch({ ...search, [e.target.name]: e.target.value === 'all' ? '' : e.target.value });
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setSearch({ ...search, [e.target.name]: valueTrimmed });
  };

  const reset = () => {
    _onReset();
    setSearch(defaultSearch);
  };

  useEffect(() => {
    if (!shallowEqual(search, searchData)) {
      setSearch(searchData);
    }
  }, []);

  const handleOnKeydown = (e: any) => e?.code === 'Enter' && search.gtkySearch?.length >= 3 && _onSearch(search)();

  return (
    <Box display="flex" width={'100%'} mt={5} mb={5} p={3} border={'1px solid rgba(0, 0, 0, 0.1)'} borderRadius={8}>
      <Grid container justifyContent="space-between">
        <Grid item xs={9}>
          <Box mt={-3} display="flex" justifyContent="space-between">
            <Box flex={1} pr={2}>
              <InputField
                title="GTKY Applicant"
                placeholder="Enter at least 2 characters"
                name="gtkySearch"
                value={search.gtkySearch}
                onChange={_handleChange}
                onBlur={onBlurField}
                margin="dense"
                fullWidth
                notice
                noticeTitle={'You can search by NSAA ID, Name, Email Address or Phone number'}
                noticeYellow
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
                error={search.gtkySearch !== '' && search.gtkySearch.length < LEAST_CHAR_FOR_SEARCH}
                errorMessage="Enter at least 2 characters"
                _onKeyDown={handleOnKeydown}
              />
            </Box>
            <Box flex={1} pr={2}>
              <CustomInputSelect
                title="Interviewer"
                name="interviewId"
                value={search.interviewId === '' ? 'all' : search.interviewId}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {interviewers?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.surname}, {item.givenName}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
            <Box flex={1} pr={2}>
              <CustomInputSelect
                title="Event"
                name="eventId"
                value={search.eventId === '' ? 'all' : search.eventId}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {events?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
            <Box flex={1}>
              <CustomInputSelect
                title="User status"
                name="userStatus"
                value={search.userStatus === '' ? 'all' : search.userStatus}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {userStatus?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box display="flex" justifyContent="flex-end" mt={3}>
            {!shallowEqual(searchData, defaultSearch) && (
              <Box display="flex">
                <CustomButtonStyled disabled={loading} outlineButton onClick={reset}>
                  Reset
                </CustomButtonStyled>
              </Box>
            )}
            <Box display="flex">
              <CustomButtonStyled
                disabled={loading || (search.gtkySearch !== '' && search.gtkySearch.length < LEAST_CHAR_FOR_SEARCH)}
                onClick={_onSearch(search)}
              >
                Search
              </CustomButtonStyled>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchGTKY;
