import React from 'react';

import { Box, IconButton } from '@material-ui/core';

import { CustomDrawer } from 'src/components';
import { IUser } from 'src/services/users';
import { capitalizeFirstLetter } from 'src/utils/common';

import { BoxUserInfoStyled, ClearIconStyled, LabelStyled, TypographyUserInfo, UserInfoHeaderTitle } from '../types';

interface IRightDrawer {
  toggleDrawer: (name: 'userInformation', open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  open: boolean;
  userClicked: IUser | undefined;
}
const RightDrawer = ({ toggleDrawer, open, userClicked }: IRightDrawer) => {
  let skills: string[] = [];

  if (userClicked?.skills !== null && userClicked?.skills[0]['artistic-design']) {
    skills.push('Artistic Design');
  }
  if (userClicked?.skills !== null && userClicked?.skills[0]['building-architecture']) {
    skills.push('Building/architecture/estate Management');
  }
  if (userClicked?.skills !== null && userClicked?.skills[0]['editorial']) {
    skills.push('Editorial');
  }
  if (userClicked?.skills !== null && userClicked?.skills[0]['legal-advice']) {
    skills.push('Legal Advice');
  }
  if (userClicked?.skills !== null && userClicked?.skills[0]['translation']) {
    skills.push('Translation');
  }

  return (
    <CustomDrawer anchor="right" open={open} onClose={toggleDrawer('userInformation', false)}>
      <Box width={436} maxWidth={'100%'}>
        <BoxUserInfoStyled>
          <UserInfoHeaderTitle>USER INFORMATION</UserInfoHeaderTitle>
          <IconButton onClick={toggleDrawer('userInformation', false)}>
            <ClearIconStyled />
          </IconButton>
        </BoxUserInfoStyled>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>NSAA ID</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>{userClicked?.username}</TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Name</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>{userClicked?.surname + ', ' + userClicked?.givenName}</TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>中文名字</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>{userClicked?.chineseName}</TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>DOB</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>
              {userClicked?.dateOfBirth ? new Date(userClicked.dateOfBirth).toLocaleDateString('en-GB') : ''}
            </TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Gender</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>
              {userClicked?.gender ? capitalizeFirstLetter(userClicked?.gender) : ''}
            </TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Email Address</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>{userClicked?.emailAddress}</TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Phone Number</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>
              {userClicked?.areaCode} {userClicked?.mobilePhone}
            </TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>School Attended</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            {userClicked?.periodStudyInNYK && (
              <TypographyUserInfo>Nanyang Kindergarten - {userClicked?.periodStudyInNYK}</TypographyUserInfo>
            )}
            {userClicked?.periodStudyInNPP && (
              <TypographyUserInfo>Nanyang Pre-Primary - {userClicked?.periodStudyInNPP}</TypographyUserInfo>
            )}
            {userClicked?.periodStudyInNYP && (
              <TypographyUserInfo>Nanyang Primary - {userClicked?.periodStudyInNYP}</TypographyUserInfo>
            )}
            {userClicked?.periodStudyInNYGH && (
              <TypographyUserInfo>Nanyang Girls' High - {userClicked?.periodStudyInNYGH}</TypographyUserInfo>
            )}
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Job Title</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>{userClicked?.jobTitle}</TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Skills</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>{skills ? skills.join(', ') : ''}</TypographyUserInfo>
          </Box>
        </Box>
        <Box display="flex" mt={2} ml={6} mr={2}>
          <Box width={'30%'}>
            <LabelStyled>Other skill</LabelStyled>
          </Box>
          <Box width={'10%'}>
            <LabelStyled>:</LabelStyled>
          </Box>
          <Box width={'60%'}>
            <TypographyUserInfo>
              {userClicked?.skills !== null && userClicked?.skills[0]['other-skills']}
            </TypographyUserInfo>
          </Box>
        </Box>
      </Box>
    </CustomDrawer>
  );
};

export default RightDrawer;
