import React from 'react';

import { Box, List, ListItem, ListItemText } from '@material-ui/core';
import styled from 'styled-components';

import useResponsive from 'src/utils/responsive';

import { menus } from '../types';

const CustomList = styled(List)(() => ({
  padding: 0,
}));

const CustomListItemActive = styled(ListItem)`
  color: #c5be8b;
  border-left: 4px solid #c5be8b;
  border-bottom: 1px solid #ccc;
  .MuiListItemIcon-root {
    justify-content: flex-end;
    color: #c5be8b;
  }
  .MuiTypography-root {
    ${({ theme }) => ({
      ...theme.fonts.header6,
    })}
  }
`;
const BorderBottomLine = styled(Box)`
  margin: auto;
`;
const LeftMenuBox = styled(Box)`
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  .MuiListItemIcon-root {
    justify-content: flex-end;
  }
  min-width: 245px;
`;

const CustomListItem = styled(ListItem)`
  background-color: white;
  border-bottom: 1px solid #ccc;
  .MuiTypography-root {
    ${({ theme }) => ({
      ...theme.fonts.header6,
      fontWeight: 400,
    })}
  }
`;

interface ILeftContent {
  setMenuActive: any;
  menuActive: string;
}

const LeftMenu = ({ setMenuActive, menuActive }: ILeftContent) => {
  const _onClickMenuItem = (menu: string) => () => {
    setMenuActive(menu);
  };

  const responsive = useResponsive();

  return (
    <LeftMenuBox bgcolor="white" position={responsive.isDesktopOrLaptop ? 'fixed' : 'static'} width={'16.666667%'}>
      <CustomList>
        {menus.map((menu, index) => {
          return (
            <Box>
              {menu === menuActive ? (
                <CustomListItemActive button onClick={_onClickMenuItem(menu)}>
                  <ListItemText primary={menu} />
                </CustomListItemActive>
              ) : (
                <CustomListItem button onClick={_onClickMenuItem(menu)}>
                  <ListItemText primary={menu} />
                </CustomListItem>
              )}
              <BorderBottomLine />
            </Box>
          );
        })}
      </CustomList>
    </LeftMenuBox>
  );
};

export default LeftMenu;
