import React, { useCallback, useEffect, useState } from 'react';

import { Box, FormControl, FormControlLabel, RadioGroup } from '@material-ui/core';
import moment from 'moment';

import { InputField, NASSRadio } from 'src/components';
import CustomFormDialog from 'src/components/formDialog';
import { CALL_SUCCESS, FORMAT_TIME_AM_PM } from 'src/constants/common';
import IconSuccess from 'src/medias/icons/icon-success.png';
import { questionTypes, ScorePoint, TextScore } from 'src/pages/event-quiz/types';
import { CustomLabel } from 'src/pages/signup/components/types';
import { IconVerifyStyled } from 'src/pages/user-management/types';
import { getResponseQuizDetail } from 'src/services/quiz';
import { IQuizResponseDetails, IQuizResponseList } from 'src/services/quiz/quiz.interface';

import { LabelStyled, TextStyled } from '../types';

interface IResponseDetail {
  _onCloseModal: () => void;
  open: boolean;
  itemSelected: IQuizResponseList | undefined;
}

export const ResponseDetail = ({ open, _onCloseModal, itemSelected }: IResponseDetail) => {
  const [surveyResponse, setSurveyResponse] = useState<IQuizResponseDetails | null>();

  const getData = useCallback(async () => {
    if (itemSelected) {
      const response = await getResponseQuizDetail(itemSelected.id);
      if (response && response?.statusCode === CALL_SUCCESS) {
        setSurveyResponse(response?.data);
      }
    }
  }, [itemSelected]);

  useEffect(() => {
    if (itemSelected) {
      getData();
    }
  }, [getData, itemSelected]);

  return (
    <CustomFormDialog
      title="Response Detail"
      open={open}
      _onCloseModal={_onCloseModal}
      hiddenSubmitButton
      cancelText="Close"
    >
      <Box display="flex" flex={1} flexDirection="column">
        <Box mt={3} pb={3} borderBottom="1px solid rgba(0, 0, 0, 0.1)">
          <Box>
            <LabelStyled display="inline">
              User:{' '}
              <TextStyled display="inline">
                {`${surveyResponse?.user?.surname}, ${surveyResponse?.user?.givenName}`}{' '}
                {surveyResponse?.user?.verificationStatus === 'verified' && <IconVerifyStyled src={IconSuccess} />}
              </TextStyled>
            </LabelStyled>
          </Box>
          <Box mt={1}>
            <LabelStyled display="inline">
              Response Time:{' '}
              <TextStyled display="inline">
                {surveyResponse?.createdAt ? moment(new Date(surveyResponse.createdAt)).format(FORMAT_TIME_AM_PM) : ''}
              </TextStyled>
            </LabelStyled>
          </Box>
          <Box mt={1}>
            <LabelStyled display="inline">
              Total Score: <TextStyled display="inline">{surveyResponse?.totalPoint}</TextStyled>
            </LabelStyled>
          </Box>
        </Box>
        {surveyResponse?.responseQuestion?.map((question, index) => {
          return (
            <Box mt={3} display="flex" flexDirection="row">
              <Box width={'85%'} display="flex" flexDirection="column" flex={1}>
                <Box flex={1} mt={2}>
                  <CustomLabel style={{ height: 'auto', whiteSpace: 'initial' }}>
                    Question {index + 1}: {question.question.questionText}
                  </CustomLabel>
                </Box>
                <Box>
                  {question.question.questionType === questionTypes.singleChoice ? (
                    <FormControl component="fieldset">
                      <RadioGroup value={question.answerId}>
                        {question?.question.answer?.map((option) => {
                          return (
                            <>
                              <FormControlLabel value={option.id} control={<NASSRadio />} label={option.answer} />
                              <Box width={'180px'} />
                            </>
                          );
                        })}
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <Box>
                      <InputField
                        value={question.answerText}
                        name={questionTypes.shortParagraph}
                        multiline
                        rows={5}
                        rowsMax={5}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
              <Box ml={3} mt={2} display="flex" width={'15%'}>
                <TextScore display="inline">
                  Scored: <ScorePoint display="inline">{question.question.point}</ScorePoint> point
                </TextScore>
              </Box>
            </Box>
          );
        })}
      </Box>
    </CustomFormDialog>
  );
};
