import React from 'react';

import { IconCustom, ItemBox, TextCustom } from './type';

function Item({ icon, children, isQualified }: any) {
  return (
    <ItemBox display="flex" justifyContent="center" alignItems="center">
      <IconCustom src={icon} />
      <TextCustom isQualified={isQualified}>{children}</TextCustom>
    </ItemBox>
  );
}

export default Item;
