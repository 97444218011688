import createTheme from '@material-ui/core/styles/createTheme';
import { Shadows } from '@material-ui/core/styles/shadows';

interface CustomPalette {
  Promo?: string;
  '10'?: string;
  '25'?: string;
  '60'?: string;
  '75'?: string;
  '100'?: string;
  '150'?: string;
  '200'?: string;
  '250'?: string;
  '300'?: string;
  '400'?: string;
  '500'?: string;
  '550'?: string;
  '600'?: string;
  '650'?: string;
  '700'?: string;
  '800'?: string;
  '850'?: string;
  '900'?: string;
  '1000'?: string;
}

interface CustomFonts {
  fontSize: string;
  lineHeight: number;
  fontWeight: number;
}

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    fonts: {
      textXL: CustomFonts;
      textXX: CustomFonts;
      textLL: CustomFonts;
      textL: CustomFonts;
      textM: CustomFonts;
      textS: CustomFonts;
      textSs: CustomFonts;
      textSBold: CustomFonts;
      textSS: CustomFonts;
      textSSBold: CustomFonts;
      header1: CustomFonts;
      header2: CustomFonts;
      header2S: CustomFonts;
      header3: CustomFonts;
      header3S: CustomFonts;
      header4: CustomFonts;
      header4S: CustomFonts;
      header5: CustomFonts;
      header6: CustomFonts;
      cardEvent: CustomFonts;
    };
    radius: {
      xl: number;
      l: number;
      m: number;
      xs: number;
    };
    shadow: {
      card: string;
      cardElevated: string;
      cardPopup: string;
      cardBottomUp: string;
    };
  }
  interface ThemeOptions {
    fonts: {
      textXL: CustomFonts;
      textXX: CustomFonts;
      textLL: CustomFonts;
      textL: CustomFonts;
      textM: CustomFonts;
      textS: CustomFonts;
      textSs: CustomFonts;
      textSBold: CustomFonts;
      textSS: CustomFonts;
      textSSBold: CustomFonts;
      header1: CustomFonts;
      header2: CustomFonts;
      header2S: CustomFonts;
      header3: CustomFonts;
      header3S: CustomFonts;
      header4: CustomFonts;
      header4S: CustomFonts;
      header5: CustomFonts;
      header6: CustomFonts;
      cardEvent: CustomFonts;
    };
    radius: {
      xl: number;
      l: number;
      m: number;
      xs: number;
    };
    shadow: {
      card: string;
      cardElevated: string;
      cardPopup: string;
      cardBottomUp: string;
    };
  }
}

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    gray?: PaletteOptions['primary'] & CustomPalette;
    lightGray?: PaletteOptions['primary'] & CustomPalette;
    green?: PaletteOptions['primary'] & CustomPalette;
    yellow?: PaletteOptions['primary'] & CustomPalette;
    red?: PaletteOptions['primary'] & CustomPalette;
    greenLight?: PaletteOptions['primary'] & CustomPalette;
    greenDark?: PaletteOptions['primary'] & CustomPalette;
    blue?: PaletteOptions['primary'] & CustomPalette;
  }
  interface PaletteOptions {
    gray?: PaletteOptions['primary'] & CustomPalette;
    lightGray?: PaletteOptions['primary'] & CustomPalette;
    green?: PaletteOptions['primary'] & CustomPalette;
    yellow?: PaletteOptions['primary'] & CustomPalette;
    red?: PaletteOptions['primary'] & CustomPalette;
    greenLight?: PaletteOptions['primary'] & CustomPalette;
    greenDark?: PaletteOptions['primary'] & CustomPalette;
    blue?: PaletteOptions['primary'] & CustomPalette;
  }
}

export const theme = createTheme({
  typography: {
    fontFamily: ['Roboto'].join(', '),
    h1: {},
    h2: {
      fontSize: '41px',
      lineHeight: 62 / 41,
      fontWeight: 700,
    },
    h3: {
      fontSize: '24px',
      lineHeight: 41 / 27,
      fontWeight: 700,
    },
    h4: {
      fontSize: '20px',
      lineHeight: 30 / 20,
      fontWeight: 700,
    },
    h5: {
      fontSize: '18px',
      lineHeight: 28 / 18,
      fontWeight: 700,
    },
    h6: {
      fontSize: '16px',
      lineHeight: 24 / 16,
      fontWeight: 700,
    },
    body1: {},
    body2: {},
    caption: {},
    subtitle1: {},
    subtitle2: {},
    button: {
      fontSize: 12,
    },
    overline: {},
  },
  palette: {
    primary: {
      main: '#979168',
    },
    background: {
      default: 'white',
    },
    gray: {
      '900': '#1C1C1C',
      '850': '#292929',
      '800': '#303030',
      '700': '#424242',
      '650': '#575757',
      '550': '#6B6B6B',
      '500': '#7D7D7D',
      '400': '#919191',
      '300': '#A3A3A3',
      '250': '#B8B8B8',
      '150': '#CCCCCC',
      '100': '#E0E0E0',
      '75': '#E5E5E5',
      '60': '#333333',
      '50': '#EDEDED',
      '25': '#F3F3F3',
      '10': '#EEEEEE',
      '200': '#F9F9F4',
      '600': '#5F6D81',
      '1000': '#718096',
    },
    lightGray: {
      '10': '#F8F9FA',
      '25': '#B1B1B1',
    },
    green: {
      '900': '#0FA958',
      '800': '#147975',
      '700': '#0D9B94',
      '600': '#00BCB4',
      '500': '#25C7C0',
      '400': '#4FD3CD',
      '300': '#7DDEDA',
      '200': '#B0EAE7',
      '100': '#CEF3F1',
      '10': '#EDFCFC',
      '150': '#0FA958',
    },
    yellow: {
      '1000': '#979168',
      '900': '#C5BE8B',
      '800': '#A5851A',
      '700': '#CFA210',
      '600': '#F9BE00',
      '500': '#FACA2E',
      '400': '#FAD55D',
      '300': '#FBE18C',
      '200': '#FCEDBB',
      '100': '#FCF2D3',
      '10': '#FCF8EB',
      Promo: '#FFE500',
      '150': '#EA7D18',
    },

    red: {
      '900': '#331211',
      '800': '#8A2320',
      '700': '#B52520',
      '600': '#E1221C',
      '500': '#E84944',
      '400': '#EF736F',
      '300': '#F69E9B',
      '200': '#FCCCCA',
      '100': '#FEDFDE',
      '75': '#FF000066',
      '25': '#F3F1DF',
      '60': '#FFE7E7',
      '10': '#FFF3F2',
      '150': '#DC1A1A',
      '250': '#FF1515',
    },
    blue: {
      '1000': '#007AFF',
      '900': '#1A273D',
      '800': '#183871',
      '700': '#0F3C8B',
      '600': '#003CA5',
      '500': '#2258B7',
      '400': '#4B79C9',
      '300': '#7A9DDB',
      '200': '#B0C6ED',
      '100': '#CEDDF6',
      '10': '#EDF4FF',
      '150': '#2B457D',
    },
    greenLight: {
      '900': '#303311',
      '800': '#798304',
      '700': '#9EAB09',
      '600': '#C3D311',
      '500': '#CEDC38',
      '400': '#DAE562',
      '300': '#E6ED8F',
      '200': '#F2F6BF',
      '100': '#F8FBD8',
      '10': '#FEFFF2',
    },
    greenDark: {
      '100': '#006451',
    },
  },
  fonts: {
    header1: {
      fontSize: '48px',
      lineHeight: 62 / 41,
      fontWeight: 700,
    },
    header2: {
      fontSize: '41px',
      lineHeight: 62 / 41,
      fontWeight: 700,
    },
    header2S: {
      fontSize: '26px',
      lineHeight: 62 / 41,
      fontWeight: 700,
    },
    header3: {
      fontSize: '24px',
      lineHeight: 41 / 27,
      fontWeight: 700,
    },
    header4: {
      fontSize: '20px',
      lineHeight: 30 / 20,
      fontWeight: 700,
    },
    header5: {
      fontSize: '18px',
      lineHeight: 28 / 18,
      fontWeight: 700,
    },
    header6: {
      fontSize: '16px',
      lineHeight: 24 / 16,
      fontWeight: 700,
    },
    textXL: {
      fontSize: '34px',
      lineHeight: 28 / 18,
      fontWeight: 700,
    },
    textXX: {
      fontSize: '32px',
      lineHeight: 28 / 18,
      fontWeight: 700,
    },
    textLL: {
      fontSize: '18px',
      lineHeight: 28 / 18,
      fontWeight: 500,
    },
    textL: {
      fontSize: '18px',
      lineHeight: 28 / 18,
      fontWeight: 400,
    },
    textM: {
      fontSize: '16px',
      lineHeight: 24 / 16,
      fontWeight: 400,
    },
    textS: {
      fontSize: '14px',
      lineHeight: 20 / 14,
      fontWeight: 400,
    },
    textSs: {
      fontSize: '14px',
      lineHeight: 20 / 14,
      fontWeight: 500,
    },
    textSBold: {
      fontSize: '14px',
      lineHeight: 20 / 14,
      fontWeight: 700,
    },
    textSS: {
      fontSize: '12px',
      lineHeight: 18 / 12,
      fontWeight: 400,
    },
    textSSBold: {
      fontSize: '12px',
      lineHeight: 18 / 12,
      fontWeight: 700,
    },
    header3S: {
      fontSize: '24px',
      lineHeight: 41 / 27,
      fontWeight: 400,
    },
    header4S: {
      fontSize: '36px',
      lineHeight: 41 / 27,
      fontWeight: 700,
    },
    cardEvent: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: 1,
    },
  },
  radius: {
    xs: 6,
    m: 8,
    l: 12,
    xl: 24,
  },
  overrides: {
    MuiContainer: {
      // TODO: remove this instruction
      root: {},
      maxWidthSm: {
        padding: 0,
      },
      maxWidthXl: {
        maxWidth: 1500,
      },
    },
    MuiSelect: {
      root: {
        padding: 13,
      },
    },
    MuiStepIcon: {
      root: {
        width: 45,
        height: 45,
        '&$active': {
          color: '#0FA958',
        },
        '&$completed': {
          color: '#0FA958',
        },
      },
    },
    MuiStepConnector: {
      active: {
        '& $line': {
          borderColor: '#0FA958',
        },
      },
      completed: {
        '& $line': {
          borderColor: '#0FA958',
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        height: 40,
        minHeight: 40,
        '&$expanded': {
          backgroundColor: 'rgba(214, 204, 134, 1)',
          minHeight: 40,
          color: 'white',
          fontWeight: 700,
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'rgba(0, 0, 0, 0.1)',
      },
    },
    MuiListItem: {
      button: {
        '&$selected, &$selected:hover': {
          backgroundColor: 'rgba(214, 204, 134, 1)',
          color: 'white',
          fontWeight: 700,
        },
        '&:hover': {
          backgroundColor: 'rgba(214, 204, 134, 1)',
          color: 'white',
        },
      },
    },
    MuiPopover: {
      paper: {
        boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.15)',
      },
    },
    MuiMenu: {
      paper: {
        boxShadow: '0px 3px 20px rgba(0, 0, 0, 0.15)',
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: '#C5BE8B',
        },
      },
    },
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: 'white',
          '& + $track': {
            backgroundColor: '#C5BE8B',
            opacity: 1,
          },
        },
        '&$disabled': {
          color: '#C5BE8B',
          '& + $track': {
            backgroundColor: '#fff !important',
            border: `1px solid #000`,
          },
        },
      },
    },
    MuiTab: {
      root: {
        minWidth: `50px !important`,
        maxWidth: 500,
      },
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: '#C5BE8B',
      },
    },
  },
  shadow: {
    card: '0px 2px 12px rgba(0, 0, 0, 0.02',
    cardElevated: '0px 0px 4px rgba(0, 0, 0, 0.08), 0px 6px 24px rgba(0, 0, 0, 0.1)',
    cardPopup: '0px 0px 4px rgba(0, 0, 0, 0.08), 0px 12px 28px rgba(0, 0, 0, 0.16)',
    cardBottomUp: '0px 0px 4px rgba(0, 0, 0, 0.08), 0px -4px 32px rgba(0, 0, 0, 0.1)',
  },
  shadows: Array(25).fill('none') as Shadows,
  breakpoints: {},
});

export type CustomizeTheme = typeof theme;

declare module 'styled-components' {
  interface DefaultTheme extends CustomizeTheme {}
}
