import React from 'react';

import { Box } from '@material-ui/core';

import { menus } from '../types';

import Ticket from './Ticket';
import Volunteer from './Volunteer';

interface IRightContent {
  menuActive: string;
}

const RightContent = ({ menuActive }: IRightContent) => {
  return (
    <Box m={4}>
      {menuActive === menus[0] && <Volunteer />}
      {menuActive === menus[1] && <Ticket />}
    </Box>
  );
};

export default RightContent;
