import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

import CustomFormDialog from 'src/components/formDialog';

import image1 from '../../medias/images/my-volunteer-mockup-1.png';
import image2 from '../../medias/images/my-volunteer-mockup-2.png';
import image3 from '../../medias/images/my-volunteer-mockup-3.png';
import image4 from '../../medias/images/my-volunteer-mockup-4.png';
import image5 from '../../medias/images/my-volunteer-mockup-5.png';
import { IResponsive } from '../home/homepageStyled';

export const FORMAT_TIME = 'DD/MM/YYYY hh:mm A';
export const DEFAULT_IMAGE = 'https://via.placeholder.com/300';
export interface IMenuItemData {
  text: string;
  value: string;
}
export const CustomFormDialogBox = styled(CustomFormDialog)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    ${responsive?.isMobile &&
    css`
      zoom: 0.3;
    `}
    ${responsive?.isTablet &&
    css`
      zoom: 0.6;
    `}
  `}
`;
export const menus = [
  { text: 'All Events', value: 'all' },
  { text: 'Up-coming Event', value: 'upcoming' },
  { text: 'Complete Event', value: 'completed' },
];
export const CustomBox = styled(Box)<{ responsive?: IResponsive }>`
  margin: 40px 48px;
  padding: 48px 24px;
  border: 1px solid rgba(197, 190, 139, 1);
  border-radius: 4;

  ${({ theme, responsive }) => css`
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    ${responsive?.isMobile &&
    css`
      zoom: 0.3;
    `}
    ${responsive?.isTablet &&
    css`
      zoom: 0.6;
    `}
  `}
`;
export const eventData = [
  {
    image: image1,
    title: 'Get-To-Know-You Party Q1/2020',
    duration: '21/12/2021 08:00 AM - 21/12/2021 09:00 AM',
    typeEvent: 'up-coming',
    locationName: 'Location Name',
  },
  {
    image: image2,
    title: 'Get-To-Know-You Party Q1/2020',
    duration: '21/12/2021 08:00 AM - 21/12/2021 09:00 AM',
    typeEvent: 'complete',
    locationName: 'Location Name',
  },
  {
    image: image3,
    title: 'Get-To-Know-You Party Q1/2020',
    duration: '21/12/2021 08:00 AM - 21/12/2021 09:00 AM',
    typeEvent: 'up-coming',
    locationName: 'Location Name',
  },
  {
    image: image4,
    title: 'Get-To-Know-You Party Q1/2020',
    duration: '21/12/2021 08:00 AM - 21/12/2021 09:00 AM',
    typeEvent: 'complete',
    locationName: 'Location Name',
  },
  {
    image: image5,
    title: 'Get-To-Know-You Party Q1/2020',
    duration: '21/12/2021 08:00 AM - 21/12/2021 09:00 AM',
    typeEvent: 'up-coming',
    locationName: 'Location Name',
  },
];
