export const NSAALeftContent = [
  {
    children: [
      {
        text: '关于校友会',
      },
    ],
  },
  {
    children: [
      {
        text: '南洋中小幼校友会',
        bold: true,
      },
    ],
    type: 'h1',
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1639621571672,
  },
  {
    children: [
      {
        text: '南洋中小幼校友会是为新旧校友提供一个重拾友谊、促进校友的社交活动交流平台。更是校友联系感情的桥梁。另一方面我们也希望能推动校友与母校建立深切的情感联系与合作。并团结与加强校友们对母校的凝聚力量。希望通过各类丰富精彩有深度的活动情牵校友〜不忘母校的培育之恩〜“饮水思源”“心系南洋”。同心协力支持母校发展，献身为母校的建设发展出谋献策，为筹备和募捐作出贡献回馈母校。秉承一贯老中青校友的南洋精神，为南洋大家庭的未来不断奠定坚实的基础。',
      },
    ],
  },
  {
    children: [
      {
        text: '',
      },
    ],
  },
  {
    children: [
      {
        text: '南洋校友桃李满天下、英才汇聚各方，齐济一堂。常聚集参与各类文化，教育和娱乐等活动。青年校友会更进一步招揽到五湖四海高等学府的在籍南洋校友。南洋精神因着历代校友们的奉献，源远流长，生生不息！校友会诚邀会友们积极参与和策划各类活动，通过不同形式，各显才能，共同为校友会出力。呼吁大家，现在就报名加入南洋中小幼校友会，成为我们这个生气勃勃大家庭中的一份子！',
      },
    ],
  },
];
