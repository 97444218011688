import React, { ReactNode } from 'react';

import { makeStyles } from '@material-ui/core';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

interface IShareToButton {
  facebook?: boolean;
  twitter?: boolean;
  children: ReactNode;
  url: string;
  disabled?: boolean;
}
const useStyles = makeStyles(() => ({
  logoCustom: {
    marginRight: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));
function ShareToButton({ children, facebook, url, twitter, ...rest }: IShareToButton) {
  const classes = useStyles();
  if (facebook) {
    return (
      <FacebookShareButton
        url={`${window.location.host}${url}`}
        children={children}
        className={classes.logoCustom}
        {...rest}
      />
    );
  }
  if (twitter) {
    return (
      <TwitterShareButton
        url={`${window.location.host}${url}`}
        children={children}
        className={classes.logoCustom}
        {...rest}
      />
    );
  }
  return null;
}

export default ShareToButton;
