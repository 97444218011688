import React, { useEffect, useState } from 'react';

import { Box, Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { HeaderMobile } from 'src/components/headerMobile';
import { CALL_SUCCESS } from 'src/constants/common';
import { Header } from 'src/layouts/admin/components/header';
import background from 'src/medias/images/background-signup.png';
import { getMe } from 'src/services/users';
import { selectUserProfile } from 'src/store/authentication/selector';
import { saveProfile } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';
import useResponsive from 'src/utils/responsive';

import LeftMenu from './components/LeftMenu';
import RightContent from './components/RightContent';
import { menus, UserManagementContainer } from './types';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  left: {
    boxShadow: '1px 1px 4px #9E9E9E',
  },
}));

export const UserManagement: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userProfile = useSelector(selectUserProfile);
  const responsive = useResponsive();

  const [menuActive, setMenuActive] = useState<string>(menus[0]);

  const getData = async () => {
    const response = await getMe();
    if (response?.statusCode === CALL_SUCCESS) {
      dispatch(saveProfile(response?.data));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (
      userProfile &&
      userProfile.roleName !== 'it-admin' &&
      userProfile.roleName !== 'membership-admin' &&
      userProfile.roleName !== 'secretariat-admin'
    ) {
      history.push('/not-access');
    }
  }, [userProfile]);

  return (
    <Box width="100%" overflow="auto">
      {responsive.isTabletOrMobile && <HeaderMobile title="User Management" userProfile={userProfile} />}
      {responsive.isDesktopOrLaptop && <Header />}
      <Box pt={responsive.isTabletOrMobile ? 15 : 3.75} />
      <UserManagementContainer display="flex" minHeight={894} responsive={responsive}>
        <Grid container>
          <Grid item xs={2} className={classes.left}>
            <LeftMenu setMenuActive={setMenuActive} menuActive={menuActive} />
          </Grid>
          <Grid item xs={10} className={classes.container}>
            <RightContent menuActive={menuActive} />
          </Grid>
        </Grid>
      </UserManagementContainer>
    </Box>
  );
};
