export const youthMembershipFAQ = [
  {
    type: 'h1',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '青年会籍常见问题',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524152,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '1. 什么是南洋中小幼校友会青年会籍？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524152,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '在2013年5月25日的特别常年会员大会上，通过了增添校友会青年会籍的章程，同时也阐明有关青年会籍将能包括在籍学生的动议。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524153,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '南洋中小幼校友会青年会籍为年轻的在籍学生及校友们提供与母校联系和促进南洋三校校友交流的平台。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524154,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '2. 如何成为校友会的青年团会员？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524154,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '申请程序如下：',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524155,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    i. 您需要先参加“准新会员茶聚”(Get-To-Know-You Session)，并呈交所需文件供核实。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524155,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    ii. 未满21岁的学生和校友需要呈交家长同意书以证明父母或监护人给予同意 。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524156,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    iii. 校友会理事会批准您的申请后，您就是校友会的青年团会员。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524156,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    注：若您曾通过学校管道申请校友会青年会籍，无需再次报名。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524157,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '3. 加入校友会青年会籍须缴付会费吗？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524157,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '加入校友会青年会籍是免费的，直至满25岁那年的12月31日时青年会籍自动终止，或当您将青年会籍转成永久会籍。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1646033679341,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: '',
      },
    ],
  },
  {
    id: 1645429524158,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '您可以在满21岁后，缴付 $1,000 的入会费和永久会员费，转换成永久会员。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524159,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '4. 成为校友会的青年团会员需要什么条件？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524159,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '只要您未满25岁，是南洋女子中学校、南洋小学或南洋幼稚园任何一间学校的在籍学生或校友，就可以申请成为校友会的青年团会员。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524160,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '5. 加入校友会的条件是否包括在校就读须有最短期限的规定？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524160,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '只要您曾在南洋中小幼任何一间学校就读学习过，就能申请加入校友会。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524160,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '6. 如何证明我曾在南洋中小幼任何一间学校学习过？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524161,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '您需要提交一份属于该校学生的文件证明，如：学校证书、会考成绩单、学校成绩单或成绩册。我们可能需要您提供证件正本。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524162,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '7. 我在校友会会员大会上有投票权吗？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524162,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '青年团会员没有投票权。 要加入成为校友会的永久会员才有投票权。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524162,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '8. 校友会青年团会员在报读小学一年级有优先权吗？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524163,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '没有，校友会青年会籍并不符合小一报名条件。预知小一报名制度的最新消息与程序，请浏览教育部的网站。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524163,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '9. 为什么要加入校友会青年会籍？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524164,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    i. 您会及时收到校友会的最新消息与活动资讯。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524164,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    ii. 您将有许多机会回到南洋参加南洋三校所举办的活动和学习活动策划与管理。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524165,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    iii. 您可以认识来自不同年代和行业的校友，扩大社交圈。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524165,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    iv. 您可以在活动和恩师欢聚、和同窗保持联系，也能与学弟学妹们分享您的经验。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524166,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    v. 您也可以认识南洋的退休校长和老师们，并了解南洋丰富多彩的历史进程 。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645429524167,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    vi. 您的学张会和您分享他们学习及事业生涯的心得。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
];

export const membershipInformation = [
  {
    type: 'h1',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '会籍',
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1645804146108,
  },
  {
    id: 1645428208323,
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '校友会有二种不同类别的会籍，分别为永久会籍和青年会籍。',
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428208325,
    type: 'h2',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '永久会籍',
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428208326,
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '南洋三校的校友可在年满21岁时申请成为永久会员。',
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428208326,
    type: 'h2',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '青年会籍',
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428208327,
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '南洋三校的学生和校友可在25岁前申请成为青年团会员。未满21岁的学生和校友需要呈交家长同意书以证明父母或监护人给予同意。青年会籍将在会员满25岁那年 12月 31日或当青年会籍转换成永久会籍时而终止。',
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428208329,
    type: 'h2',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '会籍费',
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428208330,
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '永久会籍：年满55岁以上的会籍费是新币 205，55岁以下则是新币 1,000。',
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428208331,
    type: 'p',
    __source: {
      fileName: 'components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '青年会籍：加入校友会青年会籍是免费的，直至满25岁那年的12月31日时青年会籍自动终止，或当您将青年会籍转成永久会籍。',
        __source: {
          fileName: 'components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
];

export const lifeMembershipFAQ = [
  {
    type: 'h1',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '永久会籍常见问题',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658630,
    type: 'h2',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '关于申请入会',
        color: '#000000',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658631,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '1. 如何成为校友会的永久会员？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658632,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '我们在此温馨提醒会籍审批将需要至少1年的时间处理。申请程序如下：',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658633,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    i. 您需要先参加“准新会员茶聚”(Get-To-Know-You Session)，并呈交所需文件供核实。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658635,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    ii. 您的申请被接纳后，您将会收到通知参加南洋品德教育讲座和至少两项校友会或学校的活动当义工（服务时间至少8小时)。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428827641,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    iii. 参加讲座和完成义工服务后，您的申请将会被提交给校友会理事会审核。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658638,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    iv. 批准入会后，您会收到通知缴付 $1,000 的入会费和永久会员费。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658638,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '    v. 本会获收您的会费后，您会收到确认您成为永久会员的信件。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658639,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '2. 成为校友会的永久会员需要什么条件？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658640,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '如果您曾是南洋三校 - 南洋女子中学校、南洋小学或南洋幼稚园 - 任何一间学校的学生，就可以申请加校友会。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658641,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '3. 加入校友会的条件是否包括在校就读须有最短期限的规定？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658642,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '只要您曾在南洋中小幼任何一间学校就读学习过，就能申请加入校友会。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658642,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '4. 如何证明我曾在南洋中小幼任何一间学校学习过？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658643,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '您需要提交一份属于该校学生的文件证明，如：学校证书、会考成绩单、学校成绩单或成绩册。我们可能需要您提供证件正本。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658644,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '5. 若我遗失了关于我曾在南洋中小幼学习的文件，应该怎么办？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658645,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '您可以点击',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
      {
        id: 1645802132801,
        url: 'https://www.seab.gov.sg/home/services/statements-of-results',
        type: 'a',
        children: [
          {
            text: '这里',
            __source: {
              fileName: 'src/components/slateEditor/config/initialValues.tsx',
              lineNumber: 40,
              columnNumber: 7,
            },
          },
        ],
      },
      {
        text: '申请索取一份您当年会考成绩的证件。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658646,
    type: 'h2',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '关于申请进入南洋幼稚园',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658647,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '预知南洋幼稚园的申请程序，请点击',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
      {
        id: 1645802104752,
        url: 'http://www.nanyangkindergarten.com/registration_procedure.html',
        type: 'a',
        children: [
          {
            text: '这里',
            __source: {
              fileName: 'src/components/slateEditor/config/initialValues.tsx',
              lineNumber: 40,
              columnNumber: 7,
            },
          },
        ],
      },
      {
        text: '。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658648,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '1. 校友会会员的孩子是否有优先权进入南洋幼稚园？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1646033060357,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '校友会会员的孩子比非校友的孩子更有优先权进入南洋幼稚园。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658649,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '2. 我曾在南洋幼稚园就读，又加入校友会，我的孩子是否有更高的机会进入南洋幼稚园？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658650,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '若您曾在南幼就读 K2 并且从南幼毕业，您的孩子已经比校友会会员的孩子享有更高的优先权。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1646033122702,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
        text: '',
      },
    ],
  },
  {
    id: 1645428658653,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '可是，若您曾在南幼就读但并没有从南幼毕业，您的孩子将没有优先权。成为校友会的会员后，您的孩子将会比非校友的孩子享有更高的优先权。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658654,
    type: 'h2',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '关于申请进入南洋小学',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658655,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '重要通知：',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658655,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '新加坡教育部在2021年9月9日宣布修订小学一年级的报名制度，从2022年开始生效。若想获取小一报名制度的最新消息与程序，请浏览教育部的网站。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658657,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '1. 我曾在南洋小学就读。如果加入校友会，我的孩子可以在哪个阶段报名进入南洋小学？加入校友会，我的孩子是否有更高的机会进入南洋小学？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658658,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '若您曾就读南洋小学，您的孩子能够在2A阶段报名申请南洋小学。校友会会员的孩子不会比非校友会会员的孩子享有更高的优先权。依照教育部的网站，所有小学校友的孩子都可以在2A阶段报名申请小学，这也包括校友会会员的孩子。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658659,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '2. 我只在南洋女子中学校就读过。加入校友会后，我是否可以在2A阶段为我的孩子报读南小一年纪？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658661,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '这取决于您何时就读于南洋女中，因南洋小学在1978年之前是南洋女中的附小。如果您在1978年或之前就读于南洋女中，您就是南洋小学的校友，您的孩子就可以在2A阶段报名申请南洋小学。否则，就不符合2A阶段报名条件 。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658662,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '3. 我只在南洋幼稚园就读过。加入校友会后，我是否可以在2A阶段为我的孩子报读南小一年纪？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658663,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '不可以。只有南洋小学校友的孩子才可以在2A阶段报名申请南洋小学。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658664,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '4. 如果我不曾在南洋小学就读，加入校友会是否可以帮助我的孩子进入南洋小学？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658665,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '若您是校友会的会员，校友会可以向校长推荐您加入家长义工服务，您可能会符合2B阶段的报名条件。然而，是否被录取成为义工将由南洋小学校方全权决定。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658667,
    type: 'h2',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '关于南洋女子中学校入学',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658667,
    type: 'h3',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        bold: true,
        text: '1. 身为校友会会员，我的女儿是否有进入南洋女中的优先权？',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
  {
    id: 1645428658668,
    type: 'p',
    __source: {
      fileName: 'src/components/slateEditor/config/initialValues.tsx',
      lineNumber: 39,
      columnNumber: 5,
    },
    children: [
      {
        text: '没有。所有进入中学的小学六年级学生须严格按照小六离校会考成绩，或通过直接收生计划入学。',
        __source: {
          fileName: 'src/components/slateEditor/config/initialValues.tsx',
          lineNumber: 40,
          columnNumber: 7,
        },
      },
    ],
  },
];

export const membershipApplicant = [
  {
    type: 'h1',
    children: [
      {
        bold: true,
        text: 'Membership Applicant',
        underline: true,
      },
    ],
    id: 1644939787054,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787054,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '1. What is the NSAA’s Youth Membership?',
      },
    ],
    id: 1644939787054,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787055,
  },
  {
    type: 'p',
    children: [
      {
        text: 'The Youth Membership was established on 25 May 2013 during the NSAA’s Extraordinary General Meeting (EGM). The main objectives of the Youth Membership are to provide a platform for young students and graduates of Nanyang Kindergarten (NYK), Nanyang Primary School (NYPS) and Nanyang Girls’ High School (NYGH) to foster close ties with their alma mater, and to build camaraderie among the alumni from the Nanyang Family of Schools.',
      },
    ],
    id: 1644939787055,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787055,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '2. What is the membership approval process for joining as a Youth Member?',
      },
    ],
    id: 1644939787056,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787056,
  },
  {
    type: 'p',
    children: [
      {
        text: 'The process is as follows:',
      },
    ],
    id: 1644939787056,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787057,
  },
  {
    type: 'p',
    children: [
      {
        text: '    i. Attend our Get-To-Know-You (GTKY) Session, which is held 4 times a year. Your documents will be verified at the session.',
      },
    ],
    id: 1644939787057,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787057,
  },
  {
    type: 'p',
    children: [
      {
        text: '    ii. Applicants below the age of 21 will need to submit the parental consent form.',
      },
    ],
    id: 1644939787058,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787058,
  },
  {
    type: 'p',
    children: [
      {
        text: '    iii. You will be accepted as a member once your application is verified.',
      },
    ],
    id: 1644939787058,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787058,
  },
  {
    type: 'p',
    children: [
      {
        text: 'If you had previously signed up for the NSAA Youth Membership through the schools, you are',
      },
    ],
    id: 1644939787059,
  },
  {
    type: 'p',
    children: [
      {
        text: 'not required to reapply again.',
      },
    ],
    id: 1644939787059,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787060,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '3. What are the charges to join as a Youth Member?',
      },
    ],
    id: 1644939787060,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787060,
  },
  {
    type: 'p',
    children: [
      {
        text: 'It is free of charge until 31 December of the year you attain the age of 25 when your Youth Membership is terminated, or when you become a Life Member.',
      },
    ],
    id: 1644939787061,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787061,
  },
  {
    type: 'p',
    children: [
      {
        text: 'You may convert to Life Membership at any time after the age of 21, by paying the entrance and subscription fees.',
      },
    ],
    id: 1644939787062,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787062,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '4. What are the criteria to join as a Youth Member?',
      },
    ],
    id: 1644939787062,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787063,
  },
  {
    type: 'p',
    children: [
      {
        text: 'You are below the age of 25, and must be a former student from NYK, NYPS or NYGH. If you are below the age of 21, consent from your parents or guardian is required.',
      },
    ],
    id: 1644939787063,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787064,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '5. Is there a required minimum period of study in a Nanyang School to join as a Youth Member?',
      },
    ],
    id: 1644939787064,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787065,
  },
  {
    type: 'p',
    children: [
      {
        text: 'No.',
      },
    ],
    id: 1644939787066,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787066,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '6. How do I prove that I have studied in a Nanyang School?',
      },
    ],
    id: 1644939787067,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787068,
  },
  {
    type: 'p',
    children: [
      {
        text: 'You are required to show us your original documents during the GTKY Session. Examples of acceptable documents are certificates, result slips, report books and report cards.',
      },
    ],
    id: 1644939787068,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787069,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '7. Do I have voting rights to cast votes at NSAA’s Annual General Meetings (AGM)?',
      },
    ],
    id: 1644939787070,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787070,
  },
  {
    type: 'p',
    children: [
      {
        text: 'No. You will have voting rights after you convert to Life Membership.',
      },
    ],
    id: 1644939787074,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787074,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '8. As a Youth Member, which phase can I register in during the Primary 1 Registration to enter NYPS?',
      },
    ],
    id: 1644939787075,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787075,
  },
  {
    type: 'p',
    children: [
      {
        text: 'The NSAA Youth Membership is not accepted by the Ministry of Education (MOE) for Primary 1 Registration. Please refer to MOE’s website for the latest updates on the process of Primary 1 Registration.',
      },
    ],
    id: 1644939787076,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939828472,
  },
  {
    type: 'h2',
    id: 1644939828319,
    children: [
      {
        text: '9. What is the difference between NSAA’s Youth Membership and NYPS’ Junior Alumni Membership?',
        bold: true,
      },
    ],
  },
  {
    type: 'p',
    id: 1644939828573,
    children: [
      {
        text: '﻿',
      },
    ],
  },
  {
    type: 'p',
    id: 1644939828574,
    children: [
      {
        text: 'Joining NSAA’s Youth Membership gives you the added advantage of being updated with the events and activities organised by NSAA for all three Nanyang Schools.',
      },
    ],
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787077,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '10. Why should I join as a Youth Member?',
      },
    ],
    id: 1644939787077,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787078,
  },
  {
    type: 'p',
    children: [
      {
        text: 'i. You will be updated on the events organised by NSAA via regular e-Notices.',
      },
    ],
    id: 1644939787078,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787079,
  },
  {
    type: 'p',
    children: [
      {
        text: 'ii. You can participate in these events for all three Nanyang Schools, learn about event management and find out how we work with external organisations for our activities, some of which have become our long-time sponsors.',
      },
    ],
    id: 1644939787079,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787080,
  },
  {
    type: 'p',
    children: [
      {
        text: 'iii. You can broaden your network as you connect with alumni from different generations, industries and professions.',
      },
    ],
    id: 1644939787081,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787081,
  },
  {
    type: 'p',
    children: [
      {
        text: 'iv. At the events, you can reminisce with your ex-teachers, stay connected with your ex-classmates and share your experiences with your juniors.',
      },
    ],
    id: 1644939787082,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787083,
  },
  {
    type: 'p',
    children: [
      {
        text: 'v. You can also meet the retired Principals and teachers, and through the interesting stories from their past, learn how Nanyang has evolved over the decades.',
      },
    ],
    id: 1644939787083,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787084,
  },
  {
    type: 'p',
    children: [
      {
        text: 'vi. Your seniors will share how their journey in Nanyang has helped them in their career as working adults.',
      },
    ],
    id: 1644939787084,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787085,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '11. After becoming a Youth Member, I did not receive any email notifications of NSAA’s events and activities. Why is this so?',
      },
    ],
    id: 1644939787086,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787086,
  },
  {
    type: 'p',
    children: [
      {
        text: 'Please check your email’s junk or spam folder as the e-Notices may have been filtered there.',
      },
    ],
    id: 1644939787087,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787088,
  },
  {
    type: 'p',
    children: [
      {
        text: 'You may also visit our NSAA website regularly for the latest updates.',
      },
    ],
    id: 1644939787088,
  },
];
