export const data: { image: string; header: string; age: number; title: string; description: string }[] = [
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1945.png',
    header: 'Formation of the 1st Alumni Association',
    age: 1945,
    title: '1945 Formation of the 1st Alumni Association',
    description:
      'Against a backdrop of a newfound peace and a horizon of hope, some 30 odd alumni gathered to elect a pro tem committee to look into the formation of the Alumni Association of Nanyang Girls’ High School on 7 October. The main objective was to restore our school swiftly.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1952.png',
    header: 'Dissolving of the Chinese schools’ alumni association by order of the colonial government',
    age: 1952,
    title: '1952 Dissolving of the Chinese schools’ alumni association by order of the colonial government',
    description:
      'Amidst anti-colonial sentiments, labour unrest and student strikes were rife and Chinese schools’ alumni associations were implicated in such activities. As such, Nanyang Girls’ High Alumni Association was dissolved.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1976.png',
    header: 'Celebrate the school’s 60th anniversary',
    age: 1976,
    title: '1976 Celebrate the school’s 60th anniversary',
    description:
      'Former students, Board Members and teachers attended a 60-table homecoming gala dinner held on 26 June to celebrate the school’s anniversary. The school had been organising many homecoming dinners and this fostered the desire to re-establish the Alumni Association.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1984.png',
    header: 'Formation of the new Alumni Association. Madam Wee Bee Hoon was elected as President',
    age: 1984,
    title: '1984 Formation of the new Alumni Association. Madam Wee Bee Hoon was elected as President',
    description:
      'Upon receiving the approval from the Registry of Societies, the 1st Annual General Meeting was held on 24 March and 21 committee members were duly elected.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1987.png',
    header: 'Celebrated the schools’ 70th anniversary',
    age: 1987,
    title: '1987 Celebrated the schools’ 70th anniversary Celebrated the schools’ 70th anniversary',
    description:
      'The Alumni Association helped to organise a 100-table banquet at the Westin Stamford Hotel on 15 August. The Alumni Association has since celebrated Founder’s Day annually. Hosting it on a larger scale once every 5 years.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1995.png',
    header: 'Establishment of the Alumni Association Choir',
    age: 1995,
    title: '1995 Establishment of the Alumni Association Choir',
    description:
      'Regularly organised musical events for Nanyang Family. Actively participated in local and overseas musical events.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/1998.png',
    header: 'Change the name of the Alumni Association to “Nanyang Schools Alumni Association”',
    age: 1998,
    title: '1998 Change the name of the Alumni Association to “Nanyang Schools Alumni Association”',
    description:
      'Registry of Societies approved to change the name to “Nanyang Schools Alumni Association (Nanyang Girls’ High School, Nanyang Primary School, Nanyang Kindergarten)” to reflect that all three schools are part of the Nanyang Family.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2000a.png',
    header: 'Hosted the inaugural talk on Primary One Registration',
    age: 2000,
    title: '2000 Hosted the inaugural talk on Primary One Registration',
    description: 'The Alumni Association has since organized this talk annually.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2000b.png',
    header: 'Commissioned a bronze bust for alumna Madam Ling Siew May, in memory of her contributions',
    age: 2000,
    title: '2000 Commissioned a bronze bust for alumna Madam Ling Siew May, in memory of her contributions',
    description:
      'The bronze bust was unveiled by Mr Ong Teng Cheong at a ceremony on the 15 August in front of the Siew May Auditorium at NYGH.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2001a.png',
    header: 'The Life Membership fees for the Alumni Association was revised to $1,000',
    age: 2001,
    title: '2001 The Life Membership fees for the Alumni Association was revised to $1,000',
    description:
      'The fees revision took effect from 1 January. Alumni above the age of 55 years old at the time of application need only pay $205.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2000b.png',
    header: 'Held a fundraising exhibition title “Rhapsody of Colours”',
    age: 2001,
    title: '2001 Held a fundraising exhibition title “Rhapsody of Colours”',
    description: 'Funds were raised for the welfare of retired teachers and for needy alumni.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2004.png',
    header:
      'Commissioned the bronze busts for Madam Liew Yuen Sien and Madam Ling Siew May to be installed in the courtyard of NYPS',
    age: 2004,
    title:
      '2004 Commissioned the bronze busts for Madam Liew Yuen Sien and Madam Ling Siew May to be installed in the courtyard of NYPS',
    description:
      'To commemorate the significant contributions made by these 2 great luminaries; our former Principal, Mdm Liew, as well as our former Board Chairlady and First Lady, Mdm Ling.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2007a.png',
    header: 'Celebrated the schools’ 90th anniversary with a musical and a commemorative book',
    age: 2007,
    title: '2007 Celebrated the schools’ 90th anniversary with a musical and a commemorative book',
    description:
      'The gala dinner was held on 11 August. A musical titled “Towards a Dazzling Future” which told stories about our school’s history was performed. The Guest-of-Honour was Minister Mentor Lee Kuan Yew. A history book on our schools was published.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2007b.png',
    header: 'Reconstruction of the Chin Tian Pavilion and Yuen Sien Pavilion',
    age: 2007,
    title: '2007 Reconstruction of the Chin Tian Pavilion and Yuen Sien Pavilion',
    description:
      'Chin Tian Pavilion was reconstructed in the NYPS and Yuen Sien Pavilion in NYGH. The opening ceremony for the 2 pavilions was held on the 15 August. These 2 pavilions were removed on 6 June to make way for construction of the new primary school at the King’s Road premise.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2009.png',
    header: 'Publication of a commemorative book to celebrate the Alumni Association’s 25th anniversary',
    age: 2009,
    title: '2009 Publication of a commemorative book to celebrate the Alumni Association’s 25th anniversary',
    description:
      'The commemorative book “To Nanyang with Love” depicted the history of the Alumni Association. The book was published on 15 August to coincide with Founder’s Day celebration.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2012.png',
    header: 'Madam Chua Eng Eng succeeded Madam Lee Siok Tuan as the third President of NSAA.',
    age: 2012,
    title: 'Madam Chua Eng Eng succeeded Madam Lee Siok Tuan as the third President of NSAA.',
    description:
      'After serving NSAA for 28 years, Madam Lee Siok Tuan stepped down in 2012 and Madam Chua Eng Eng succeeded her as our third President.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2012b.png',
    header:
      'Commemorated 95th anniversary with a musical titled “Nanyang, Sing!” cum dinner and publication of “Nanyang Glory – 95 Years of Splendour”',
    age: 2012,
    title:
      '2012 Commemorated 95th anniversary with a musical titled “Nanyang, Sing!” cum dinner and publication of “Nanyang Glory – 95 Years of Splendour”',
    description:
      'The celebration was held for 4 nights from 15 to 18 August. The musical production involved more than 800 students from all three schools and alumni association. The sit-down dinner was held at NYGH’s hall while the musical was performed outdoors under the stars in the courtyard.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2013.png',
    header: 'Amendment of the constitution to include Youth Membership',
    age: 2013,
    title: '2013 Amendment of the constitution to include Youth Membership',
    description:
      'An Extraordinary General Meeting (EGM) was held on 25 May to amend the Alumni Association’s constitution. Another EGM was subsequently held on 26 October to accept the amendments the Registry of Societies made to the proposed amendments to the constitution resolved at the EGM held in May.',
  },
  {
    image: 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/history/2014.png',
    header: 'Launch of new NSAA logo',
    age: 2014,
    title: '2014 Launch of new NSAA logo',
    description: 'The Alumni Association made a decision to modify our old logo to reflect our changes with times.',
  },
];
