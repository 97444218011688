import { IUser } from 'src/services/users/users.interface';

import { IResponse } from '../../types';
import { rootApi } from '../api';

const profileApi = rootApi.injectEndpoints({
  endpoints: ({ query, mutation }) => ({
    fetchProfile: query<IResponse<IUser>, void>({
      query: () => `/users/me`,
      providesTags: [{ type: 'USERS', id: 'PROFILE' }],
    }),
    updateProfileAvatar: mutation<any, { avatar: string }>({
      query: (body) => ({
        url: '/users/update-avatar',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'USERS', id: 'PROFILE' }],
    }),
    updateProfileChildren: mutation<any, any>({
      query: (body) => ({
        url: '/users/update-child',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [{ type: 'USERS', id: 'PROFILE' }],
    }),
    updateProfile: mutation<any, Partial<IUser>>({
      query: ({ id, ...body }) => ({
        url: `/users/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: [{ type: 'USERS', id: 'PROFILE' }],
    }),
    updateProfilePassword: mutation<any, { password: string; newPassword: string }>({
      query: (body) => ({
        url: '/users/password',
        method: 'PATCH',
        body,
      }),
    }),
    fetchAllPaymentHistory: query<any, any>({
      query: () => '',
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchProfileQuery,
  useUpdateProfilePasswordMutation,
  useUpdateProfileMutation,
  useUpdateProfileAvatarMutation,
  useUpdateProfileChildrenMutation,
} = profileApi;
