import React, { useState, useEffect } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import { AnyObject, TNode } from '@udecode/plate';
import _ from 'lodash';

import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import RichEditor from 'src/components/slateEditor';
import ErrorSnackBar from 'src/components/snackBar/error';
import {
  ACCEPT_TYPE,
  CALL_SUCCESS,
  ERROR_MESSAGE_IMAGE,
  IMAGE_1MB_SIZE,
  MAX_LENGTH_128,
  MAX_LENGTH_225,
  TEXT_REQUIRE,
} from 'src/constants/common';
import { uploadCoverPhoto } from 'src/services/events';
import { createDraft, deletePost, getPost, updatePost } from 'src/services/news';

import {
  SectionTitle,
  useStyles,
  CustomBox,
  HeaderGroup,
  TitleH3,
  InputFieldCustom,
  ContainerUploadImage,
  UploadBox,
  Label,
  IconBox,
  AddPhotoAlternateIconCustom,
  UploadText,
  BannerPhoto,
  ErrorMessage,
  PreviewContainer,
  IErrorMessage,
} from '../type';

import ConfirmModal from './ConfirmModal';
import Preview from './Preview';
import RightSection from './RightSection';

interface INewsPostCMS {
  rightType?: string | null;
  setRightType: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  postData?: any;
  _setPost: React.Dispatch<any>;
  _setMenuActive: React.Dispatch<React.SetStateAction<string>>;
}
export interface IConfirmModal {
  action: string;
  open: boolean;
}

export const NewsPostCMS = ({ rightType, setRightType, postData, _setMenuActive, _setPost }: INewsPostCMS) => {
  const classes = useStyles();
  const [value, setValue] = useState<TNode<AnyObject>[]>();
  const [initialValue, setInitialValue] = useState<TNode<AnyObject>[]>();
  const [initialPost, setInitialPost] = useState<any | null>();
  const [post, setPost] = useState<any | null>();
  const [dataCreate, setDataCreate] = useState<any | null>();
  const [isCreateAction, setIsCreateAction] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<IErrorMessage>();
  const [openConfirmModal, setOpenConfirmModal] = useState<IConfirmModal>({
    action: '',
    open: false,
  });
  const [errors, setErrors] = useState({
    coverPhoto: false,
    title: false,
    content: false,
  });
  useEffect(() => {
    setIsCreateAction(rightType === 'create');
    setIsEditMode(rightType === 'create' ? true : false);
  }, [rightType]);
  async function getData(id: string | number) {
    setLoading(true);
    const response = await getPost(id);

    if (response?.statusCode === CALL_SUCCESS) {
      setPost(response.data);
      setInitialPost(response.data);
      _setPost(response.data);
      setLoading(false);
      if (response.data && response.data.description) {
        setValue(response.data?.description);
        setInitialValue(response.data?.description);
      }
    }
    setLoading(false);
  }

  useEffect(() => {
    const id = postData && postData.id ? postData.id : null;
    if (id) {
      getData(id);
    }
  }, []);
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPost({ ...post, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: false });
  }
  async function _onCreateDraftNew() {
    let validErrors = errors;
    if (_.isEmpty(post?.coverPhoto)) {
      validErrors.coverPhoto = true;
      setErrors({ ...errors, coverPhoto: true });
    }
    if (_.isEmpty(post?.title)) {
      validErrors.title = true;
      setErrors({ ...errors, title: true });
    }
    if (!value) {
      validErrors.content = true;
      setErrors({ ...errors, content: true });
    } else if (value?.length === 1) {
      let array = value;
      if (!Array.isArray(array)) return;
      array.forEach((item) => {
        let text;
        // eslint-disable-next-line array-callback-return
        item.children.map((textValue: { text: string }) => {
          text = textValue.text;
          if (!text.replace(/\s/g, '').length) {
            validErrors.content = true;
            setErrors({ ...errors, content: true });
          } else {
            validErrors.content = false;
            setErrors({ ...errors, content: false });
          }
        });
      });
    } else {
      validErrors.content = false;
      setErrors({ ...errors, content: false });
    }
    if (validErrors.title || validErrors.coverPhoto || validErrors.content) {
      return;
    } else {
      setOpenConfirmModal({
        action: 'save create',
        open: true,
      });
    }
  }
  async function _OnUpdateClick() {
    setOpenConfirmModal({
      action: 'update',
      open: true,
    });
  }
  async function uploadChange(event: React.ChangeEvent<HTMLInputElement>) {
    setErrors({ ...errors, coverPhoto: false });
    if (event.target.files) {
      const fsize = event.target.files[0]?.size;
      const ftype = event.target.files[0]?.type;
      const file = Math.round(fsize / IMAGE_1MB_SIZE);
      const formData = new FormData();
      formData.append('image', event.target.files[0]);
      if (
        file >= IMAGE_1MB_SIZE ||
        (ftype !== ACCEPT_TYPE.jpeg && ftype !== ACCEPT_TYPE.png && ftype !== ACCEPT_TYPE.jpg)
      ) {
        setErrorMessage({
          isError: true,
          message: ERROR_MESSAGE_IMAGE,
        });
        return;
      }
      setIsUploading(true);
      const response = await uploadCoverPhoto(formData);
      setIsUploading(false);
      if (isCreateAction && response.data?.name) {
        setDataCreate({ ...dataCreate, coverPhoto: response.data?.name });
      }
      if (response.data?.name) {
        setPost({ ...post, coverPhoto: response.data?.name });
      }
    }
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setPost({ ...post, [e.target.name]: valueTrimmed });
  };
  const _setInitialData = () => {
    setValue(initialValue);
    setPost(initialPost);
    setIsEditMode(false);
  };
  const _onCloseConfirmModal = () => {
    setOpenConfirmModal({
      action: '',
      open: false,
    });
  };
  const onSubmitConfirmModal = async () => {
    if (openConfirmModal.action === 'cancel') {
      setRightType('news');
    }
    if (openConfirmModal.action === 'cancel edit') {
      _setInitialData();
    }
    if (openConfirmModal.action === 'update and preview') {
      handleUpdate();
    }
    if (openConfirmModal.action === 'update') {
      handleUpdate();
    }
    if (openConfirmModal.action === 'save create') {
      handleCreate();
    }
    if (openConfirmModal.action === 'delete') {
      handleDelete([postData.id]);
    }
    setOpenConfirmModal({
      action: '',
      open: false,
    });
  };
  const handleUpdate = async () => {
    setLoading(true);
    const body = {
      title: post.title,
      shortDescription: post.shortDescription,
      description: value,
      coverPhoto: post.coverPhoto,
    };

    const res = await updatePost(post.id, body);
    if (res && res.statusCode === CALL_SUCCESS) {
      setIsEditMode(false);
      if (openConfirmModal.action === 'update and preview') {
        setOpen(true);
        setIsEditMode(false);
      }
      getData(postData.id);
    }
    setLoading(false);
  };
  const handleCreate = async () => {
    setLoading(true);
    const body = {
      title: post.title,
      shortDescription: post.shortDescription,
      description: value,
      coverPhoto: post.coverPhoto ? post.coverPhoto : post.coverPhoto,
    };

    const res = await createDraft(body);
    if (res.statusCode === CALL_SUCCESS) {
      _setMenuActive('Create News');
      setRightType('detailNews');
      getData(res.data.id);
    }
    setLoading(false);
  };
  const handleDelete = async (ids: string[]) => {
    setLoading(true);
    const response = await deletePost(ids);
    if (response?.statusCode === CALL_SUCCESS) {
      setRightType('news');
    }
    setLoading(false);
  };
  const handleCloseSnackBar = () => {
    setErrorMessage({
      isError: false,
      message: '',
    });
  };
  return (
    <>
      {<LoadingCustom loading={loading || isUploading} loadingIcon />}
      <Grid container justifyContent="space-between">
        <ErrorSnackBar
          open={errorMessage?.isError || false}
          handleClose={handleCloseSnackBar}
          message={errorMessage?.message}
        />
        <Grid item xs={8}>
          <Box minHeight={500} p={4} mr={3} bgcolor="white" boxShadow={'1px 1px 4px #9E9E9E'} borderRadius={4}>
            <CustomBox>
              <HeaderGroup display="flex" justifyContent="space-between" alignItems="center">
                <TitleH3>{isCreateAction ? `News Create` : 'News Details'}</TitleH3>
              </HeaderGroup>
              <InputFieldCustom
                lableStyle={{ color: ' rgba(0, 0, 0, 0.6)' }}
                title="Title"
                fullWidth
                value={post && post.title}
                disabled={!isEditMode}
                type="text"
                name="title"
                onChange={handleChange}
                colorTitle="black"
                require
                onBlur={onBlurField}
                error={errors.title}
                errormessage={errors.title && TEXT_REQUIRE}
                InputProps={{
                  inputProps: {
                    maxLength: MAX_LENGTH_128,
                  },
                }}
              />
              <Box mt={3} display="flex" flexDirection="row">
                <SectionTitle>
                  Cover Photo
                  <Typography component="span" className={classes.noticeRequire}>
                    *
                  </Typography>
                </SectionTitle>
              </Box>
              <ContainerUploadImage mt={0.5} display="flex" alignItems="center" position="relative">
                {isEditMode || loading ? (
                  <>
                    <UploadBox type="file" id="contained-button-file" onChange={uploadChange} accept=".png, .jpg" />
                    <Label htmlFor="contained-button-file" backgroundImg={post?.coverPhoto}>
                      <IconBox position="absolute" display="flex">
                        <AddPhotoAlternateIconCustom />
                        <UploadText>Upload Cover photo</UploadText>
                      </IconBox>
                    </Label>
                  </>
                ) : (
                  <BannerPhoto backgroundImg={post?.coverPhoto} />
                )}
              </ContainerUploadImage>

              {errors.coverPhoto && (
                <Box mt={1}>
                  <ErrorMessage>{TEXT_REQUIRE}</ErrorMessage>
                </Box>
              )}
              <InputFieldCustom
                lableStyle={{ color: ' rgba(0, 0, 0, 0.6)' }}
                title="Short Content"
                fullWidth
                type="text"
                value={post && post.shortDescription}
                name={'shortDescription'}
                onChange={handleChange}
                colorTitle="black"
                onBlur={onBlurField}
                disabled={!isEditMode}
                InputProps={{
                  inputProps: {
                    maxLength: MAX_LENGTH_225,
                  },
                }}
              />
              <Box mt={3} mb={0.5} display="flex" flexDirection="row">
                <SectionTitle>
                  Full Content
                  <Typography component="span" className={classes.noticeRequire}>
                    *
                  </Typography>
                </SectionTitle>
              </Box>
              {isEditMode ? (
                <RichEditor
                  type="event"
                  value={value}
                  setValue={setValue}
                  headerType="event"
                  require={errors.content}
                />
              ) : (
                <RichEditor
                  type="event"
                  value={value}
                  setValue={setValue}
                  headerType={'event'}
                  disable={true}
                  bgColor={'rgba(0, 0, 0, 0.1)'}
                  require={errors.content}
                />
              )}
              {errors.content && (
                <Box mt={1}>
                  <ErrorMessage>{TEXT_REQUIRE}</ErrorMessage>
                </Box>
              )}
            </CustomBox>
          </Box>
        </Grid>
        <RightSection
          rightType={rightType}
          setRightType={setRightType}
          postData={post}
          setIsEditMode={setIsEditMode}
          isEditMode={isEditMode}
          _onCreateDraftNew={_onCreateDraftNew}
          _OnUpdateClick={_OnUpdateClick}
          _setMenuActive={_setMenuActive}
          _setPost={_setPost}
          setLoading={setLoading}
          setOpen={setOpen}
          _setOpenConfirmModal={setOpenConfirmModal}
          getData={getData}
        />
      </Grid>
      {open ? (
        <PreviewContainer>
          <Box width={'100%'}>
            <Preview
              setOpen={setOpen}
              postData={postData}
              _setMenuActive={_setMenuActive}
              setRightType={setRightType}
            />
          </Box>
        </PreviewContainer>
      ) : null}
      {openConfirmModal && (
        <ConfirmModal
          open={openConfirmModal.open}
          action={openConfirmModal.action}
          onSubmit={onSubmitConfirmModal}
          handleClose={_onCloseConfirmModal}
        />
      )}
    </>
  );
};
