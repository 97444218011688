import React, { useEffect, useState } from 'react';

import { Box, Grid, IconButton, InputAdornment } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

import { InputField } from 'src/components';
import { ISearchInterview } from 'src/services/volunteer';
import { shallowEqual } from 'src/utils/common';

import { defaultSearchInterview } from '../types';

const LEAST_CHAR_FOR_SEARCH = 3;

interface ISearchInterviewer {
  _onSearch: (search: ISearchInterview) => void;
  _onReset: () => void;
  loading: boolean;
  searchData: ISearchInterview;
}

const SearchInterviewer = ({ searchData, loading, _onSearch, _onReset }: ISearchInterviewer) => {
  const [search, setSearch] = useState<ISearchInterview>(defaultSearchInterview);

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch({ ...search, [e.target.name]: e.target.value === 'all' ? '' : e.target.value });
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setSearch({ ...search, [e.target.name]: valueTrimmed });
  };

  const reset = () => {
    _onReset();
    setSearch(defaultSearchInterview);
  };

  useEffect(() => {
    if (!shallowEqual(search, searchData)) {
      setSearch(searchData);
    }
  }, []);

  return (
    <Box display="flex" width={'100%'} mt={-2}>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1}>
              <InputField
                placeholder="Enter at least 3 characters"
                name="search"
                value={search.search}
                onChange={_handleChange}
                onBlur={onBlurField}
                margin="dense"
                fullWidth
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={reset} disabled={loading} edge="end">
                        {!shallowEqual(searchData, defaultSearchInterview) && <ClearIcon />}
                      </IconButton>
                      <IconButton
                        onClick={_onSearch.bind(null, search)}
                        disabled={loading || (search.search !== '' && search.search.length < LEAST_CHAR_FOR_SEARCH)}
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={search.search !== '' && search.search.length < LEAST_CHAR_FOR_SEARCH}
                errorMessage="Enter at least 3 characters"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchInterviewer;
