import React, { useState } from 'react';

import { Box, MenuItem, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { CustomInputSelect } from 'src/components';
import CustomFormDialog from 'src/components/formDialog';
import { IUser } from 'src/services/users';

import { blockingReason } from '../types';

interface IBlockAccountModal {
  _onCloseModal: () => void;
  open: boolean;
  _onSubmit?: () => void;
  userSelected: IUser[] | undefined;
  blockReason: string;
  setBlockReason: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean | undefined;
}
const BlockAccountModal = ({
  _onCloseModal,
  open,
  _onSubmit,
  userSelected,
  blockReason,
  setBlockReason,
  loading,
}: IBlockAccountModal) => {
  const [errors, setErrors] = useState({
    hasBlockReason: false,
  });

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setBlockReason(e.target.value);
    if (e.target.value !== 'none') {
      setErrors({ ...errors, hasBlockReason: false });
    } else {
      setErrors({ ...errors, hasBlockReason: true });
    }
  }

  const handleSubmit = () => {
    if (blockReason === 'none') {
      setErrors({ ...errors, hasBlockReason: true });
      return;
    }

    _onSubmit?.();
  };

  const handleClose = () => {
    _onCloseModal?.();
    setErrors({ ...errors, hasBlockReason: false });
  };

  return (
    <CustomFormDialog
      title="Block Account"
      open={open}
      _onCloseModal={handleClose}
      submitText="Submit"
      _onSubmit={handleSubmit}
      disabled={loading}
    >
      <Box display="flex" flex={1} mt={1} mb={2}>
        <Box width={'60%'}>
          <LabelStyled>Selected User</LabelStyled>
          <SelectBox mt={1} style={{ overflowY: 'auto' }}>
            {[...new Set(userSelected)]?.map((user, index) => {
              return (
                <SelectBoxItem p={1} index={index}>
                  <TextStyled>
                    {`${user.surname}, ${user.givenName} ( ${user.emailAddress} )`.length > 60
                      ? `${user.surname}, ${user.givenName} ( ${user.emailAddress} )`.slice(0, 60) + '...'
                      : `${user.surname}, ${user.givenName} ( ${user.emailAddress} )`}
                  </TextStyled>
                </SelectBoxItem>
              );
            })}
          </SelectBox>
        </Box>
        <Box width={'40%'}>
          <Box ml={3}>
            <LabelStyled display="inline">
              Blocking Reason{' '}
              <Typography display="inline" color="error">
                *
              </Typography>
            </LabelStyled>
            <Box>
              <CustomInputSelect
                name="blockReason"
                value={blockReason}
                fullWidth
                notRequired
                onChange={_handleChange}
                error={errors.hasBlockReason}
                errorMessage="This field is required"
              >
                <MenuItem disabled style={{ display: 'none' }} key="none" value="none">
                  <TextStyled>Choose a blocking reason</TextStyled>
                </MenuItem>
                {blockingReason.map((item) => {
                  return (
                    <MenuItem key={item} value={item}>
                      <TextStyled>{item.length > 40 ? item.slice(0, 40) + '...' : item}</TextStyled>
                    </MenuItem>
                  );
                })}
              </CustomInputSelect>
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomFormDialog>
  );
};

export default BlockAccountModal;

const LabelStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.6)',
}));

export const SelectBox = styled(Box)(() => ({
  border: '1px solid #ccc',
  height: 205,
  borderRadius: 6,
}));

export const SelectBoxItem = styled(Box)(({ index }: { index: number }) => ({
  backgroundColor: index % 2 ? '#F8F9FA' : 'white',
  borderBottom: '1px solid #ccc',
}));

export const TextStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: '#718096',
  paddingLeft: 6,
  paddingRight: 6,
}));
export const InterviewNameStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: '#718096',
  paddingRight: 6,
}));
