import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ImageIconStyled = styled.img(() => ({}));

export const LabelStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 700,
  color: 'rgba(0, 0, 0, 0.6)',
}));

export const TextStyled = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: 400,
  color: 'rgba(113, 128, 150, 1)',
}));
