import React, { useRef, useState } from 'react';

import { Box, CircularProgress, RadioGroup } from '@material-ui/core';
import { AnyObject, TNode } from '@udecode/plate-core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { NASSRadio } from 'src/components';
import ConfirmPopup from 'src/components/confirm';
import RichEditor from 'src/components/slateEditor';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { ACCEPT_TYPE, CALL_SUCCESS, DATE_PICKER_DISPLAY_FORMAT, PLACEHOLDER_DATE_PICKER } from 'src/constants/common';
import { EventCategory } from 'src/constants/events.constant';
import { EVENT_VALIDATION_MESSAGE } from 'src/constants/validation-message.constant';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import UploadPictureIcon from 'src/icons/upload-picture';
import CalenderURL from 'src/medias/icons/calendar-icon.svg';
import ClockURL from 'src/medias/icons/clock-icon.svg';
import UploadImageDisabledImage from 'src/medias/icons/upload-image-disabled.png';
import {
  ActionButton,
  ErrorMessage,
  FormMenuItem,
  FormTextField,
  GeneralInformationDatePicker,
  GeneralInformationTimePicker,
} from 'src/pages/profile/components/general-information.styled';
import { FormControlLabelStyled, Label } from 'src/pages/user-management/shared/user-detail.account-settings';
import { getEventById, IEvent, updateEventGeneralInformation, uploadCoverPhoto } from 'src/services/events';
import { isInputNumber } from 'src/utils/common';

import FormikPromptIfDirty from '../../shared/prompt';
import { EventSwitch } from '../../shared/switch';
import { TextStyled } from '../volunteer/types';

import {
  GeneralContainer,
  GeneralInformationContainer,
  PreviewImage,
  ShortDescription,
  UploadText,
} from './index.styled';

interface IGeneralProps {
  event: IEvent;
  setEvent: React.Dispatch<React.SetStateAction<IEvent | undefined>>;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}
const General = ({ event, setEvent, setDirty }: IGeneralProps) => {
  useScrollToTop();
  const [durationMessage, setDurationMessage] = useState<any>();
  const { id } = useParams<{ id: string }>();
  const [isUploading, setIsUploading] = useState(false);
  const photoRef = useRef<HTMLDivElement>();
  const [isEditable, setEditable] = useState(false);
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDescriptionEmpty, setDescriptionEmpty] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const UPLOAD_FILE_SIZE_MESSAGE = 'Uploaded file’s format must be PNG, JPG, IMG and size must be less than 1MB';
  const DATE_FORMAT = 'YYYY/MM/DD';
  const TIME_FORMAT = 'HH:mm:ss';
  const DEFAULT_CATEGORY = 'default';
  const DEFAULT_EVENT_TYPE = 'location-based';
  const EMPTY = '';
  const OPTIONS = { YES: 'yes', NO: 'no' };
  const MAX_LENGTH_128 = 128;
  const MAX_LENGTH_255 = 255;
  const ONE_MB_FILE_SIZE = 1024;
  const GTKY_CATEGORY = 'gtky-party';
  const COMPLETED = 'completed';
  const CANCELLED = 'cancelled';
  const DRAFT = 'draft';

  if (!event) return <div />;

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };
  const handleEmptyChange = (status: any) => setDescriptionEmpty(status);

  const VALIDATION_SCHEMA = Yup.object({
    title: Yup.string().required(EVENT_VALIDATION_MESSAGE.EVENT_NAME_REQUIRED),
    start_date: Yup.string().required(EVENT_VALIDATION_MESSAGE.START_DATE_REQUIRED),
    end_date: Yup.string().required(EVENT_VALIDATION_MESSAGE.END_DATE_REQUIRED),
    start_time: Yup.string().required(EVENT_VALIDATION_MESSAGE.START_TIME_REQUIRED),
    end_time: Yup.string().required(EVENT_VALIDATION_MESSAGE.END_TIME_REQUIRED),
    cover_photo: Yup.string().required(EVENT_VALIDATION_MESSAGE.COVER_PHOTO_REQUIRED),
    category: Yup.string()
      .notOneOf(['default'], EVENT_VALIDATION_MESSAGE.CATEGORY_REQUIRED)
      .required(EVENT_VALIDATION_MESSAGE.CATEGORY_REQUIRED),
    duration: Yup.string()
      .required(EVENT_VALIDATION_MESSAGE.DURATION_REQUIRED)
      .test(
        'EVENT_VALIDATION_MESSAGE',
        `Duration hours can not exceed ${durationMessage} hours`,
        function (duration: any) {
          const { start_time, end_time } = this.parent;
          const start_time_string = moment(start_time);
          const end_time_string = moment(end_time);
          const hours = end_time_string.diff(start_time_string, 'hours');
          setDurationMessage(duration);
          return +duration <= hours;
        },
      ),
    max_guest: Yup.string().required(EVENT_VALIDATION_MESSAGE.MAX_NUMBER_REQUIRED),
    location: Yup.string().required(EVENT_VALIDATION_MESSAGE.LOCATION_REQUIRED),
    registration_close_date: Yup.string().required(EVENT_VALIDATION_MESSAGE.START_TIME_REQUIRED),
    registration_close_time: Yup.string().required(EVENT_VALIDATION_MESSAGE.START_TIME_REQUIRED),
  });
  const INIT_VALUES = {
    title: event?.title || EMPTY,
    start_date: event?.start_time ? moment(+event?.start_time) : EMPTY,
    end_date: event?.end_time ? moment(+event?.end_time) : EMPTY,
    start_time: event?.start_time ? moment(+event?.start_time) : EMPTY,
    end_time: event?.end_time ? moment(+event?.end_time) : EMPTY,
    category: event?.category || DEFAULT_CATEGORY,
    event_type: event?.event_type || DEFAULT_EVENT_TYPE,
    duration: event?.duration || EMPTY,
    location: event?.location || EMPTY,
    max_guest: event?.max_guest,
    registration_close_date: event?.registration_close_time ? moment(+event?.registration_close_time) : EMPTY,
    registration_close_time: event?.registration_close_time ? moment(+event?.registration_close_time) : EMPTY,
    is_public: event?.is_public ? OPTIONS.YES : OPTIONS.NO,
    link_for_registration: event?.link_for_registration || event?.link_for_volunteer_registration,
    cover_photo: event?.cover_photo || EMPTY,
    short_description: event?.short_description || EMPTY,
    description: event?.description || undefined,
  };

  const handleEventFormSubmit = (values: any, resetForm: (values: any) => void) => {
    if (isDescriptionEmpty) return;
    const end_time_string =
      moment(values.end_date).format(DATE_FORMAT) + ' ' + moment(values.end_time).format(TIME_FORMAT);
    const start_time_string =
      moment(values.start_date).format(DATE_FORMAT) + ' ' + moment(values.start_time).format(TIME_FORMAT);
    const registration_close_time_string =
      moment(values.registration_close_date).format(DATE_FORMAT) +
      ' ' +
      moment(values.registration_close_time).format(TIME_FORMAT);

    const data = {
      ...values,
      is_public: values.is_public === OPTIONS.YES,
      id,
      description: values.description,
      end_time: end_time_string,
      start_time: start_time_string,
      registration_close_time: moment(registration_close_time_string).utc().valueOf(),
      short_description: values.short_description.trim(),
    };
    delete data.start_date;
    delete data.end_date;

    (async () => {
      setLoading(true);
      const response = await updateEventGeneralInformation(data as any);
      if (response?.statusCode === CALL_SUCCESS) {
        resetForm({ values: { ...event, ...values } });
        setShowSnackBarSuccess(true);
        setMessage(response.message);
        setEditable(false);
        setLoading(false);
        setDirty(false);
        resetForm({ values: { ...event, ...values } });
        const getEventResponse = await getEventById(event.id);
        if (getEventResponse.statusCode === CALL_SUCCESS) {
          setEvent({
            ...event,
            ...values,
            ...data,
            ...getEventResponse.data,
            is_public: values.is_public === OPTIONS.YES ? true : false,
          } as any);
        }
      } else {
        setShowSnackBarError(true);
        setMessage(response.message);
        setLoading(false);
        setDirty(false);
      }
    })();
  };

  if (!event) return <div />;

  return (
    <GeneralInformationContainer>
      <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
      <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
      <Formik
        initialValues={INIT_VALUES}
        validationSchema={VALIDATION_SCHEMA}
        onSubmit={(values, { resetForm }) => handleEventFormSubmit(values, resetForm)}
      >
        {(formik) => {
          const formikHandleSubmit = formik.handleSubmit;
          setDirty(formik.dirty);
          const startTimeString =
            moment(formik.values.start_date).format(DATE_FORMAT) +
            ' ' +
            moment(formik.values.start_time).format(TIME_FORMAT);

          const startTime = moment(startTimeString);

          async function handleUploadCoverPhoto(e: React.ChangeEvent<HTMLInputElement>) {
            const fileSize = e.target?.files?.[0].size;
            const type = e.target?.files?.[0].type;
            const file = Math.round(fileSize! / ONE_MB_FILE_SIZE);
            if (file > ONE_MB_FILE_SIZE || !Object.values(ACCEPT_TYPE).includes(type!)) {
              setShowSnackBarError(true);
              setMessage(UPLOAD_FILE_SIZE_MESSAGE);
              e.target.value = '';
              return;
            }

            if (e.target.files) {
              const formData = new FormData();
              formData.append('image', e.target.files[0]);
              setIsUploading(true);
              const response = await uploadCoverPhoto(formData);
              setIsUploading(false);
              if (response.statusCode === CALL_SUCCESS) {
                setShowSnackBarSuccess(true);
                setMessage(response.message);
                formik.setFieldValue('cover_photo', response.data?.name);
                e.target.value = '';
              } else {
                setShowSnackBarError(true);
                setMessage(response.message);
                e.target.value = '';
              }
            }
          }
          const setRef = (ref: HTMLInputElement | null) => {
            photoRef.current = ref!;
          };
          const handleOpenFile = () => {
            if (photoRef.current) {
              isEditable && photoRef.current.click();
            }
          };
          const handleToggleEditable = () => setEditable((edit) => !edit);
          const handleCancel = () => {
            handleToggleEditable();
            formik.resetForm();
            setDirty(false);
          };

          const handleInputChange =
            (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (name === 'title' || name === 'location' || name === 'short_description')
                formik.setFieldValue(name, e.target.value);

              if (name === 'duration') {
                if (!isInputNumber(e.target.value)) return;
                if (+e.target.value >= 0) formik.setFieldValue(name, e.target.value);
              }

              if (name === 'max_guest') {
                if (!isInputNumber(e.target.value)) return;
                if (+e.target.value >= 0 && !e.target.value.includes('.')) formik.setFieldValue(name, e.target.value);
              }
            };
          const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            if (e.target.value === GTKY_CATEGORY) {
              formik.setFieldValue('is_public', 'no');
              formik.setFieldValue('category', e.target.value);
            } else {
              formik.setFieldValue('category', e.target.value);
            }
          };
          const handleDateChange = (name: string) => (date: any, value?: string | null | undefined) => {
            formik.setFieldValue(name, date);
          };
          const handleRadioButtonChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
            formik.setFieldValue(name, value);
          };
          const handleSwitchChange =
            (name: string) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
              formik.setFieldValue(name, checked);
            };
          return (
            <Form>
              <FormikPromptIfDirty />
              <GeneralContainer>
                <Box mb={3}>
                  <Label required>Event name</Label>
                  <FormTextField
                    name="title"
                    onChange={handleInputChange('title')}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: MAX_LENGTH_128 }}
                    error={formik.touched.title && !!formik.errors.title}
                    helperText={formik.touched.title && formik.errors.title}
                    disabled={!isEditable}
                    value={formik.values.title}
                  />
                </Box>
                <Box mb={3}>
                  <Box display="flex" style={{ gap: 36 }}>
                    <Box flex="50%">
                      <Label required>Start Date</Label>
                    </Box>
                    <Box flex="50%">
                      <Label required>End Date</Label>
                    </Box>
                  </Box>
                  <Box display="flex" style={{ gap: 24 }}>
                    <Box width="100%" display="flex" alignItems="flex-start" style={{ gap: 12 }}>
                      <Box flex="25%">
                        <GeneralInformationDatePicker
                          name="start_date"
                          fullWidth
                          inputVariant="outlined"
                          format={DATE_PICKER_DISPLAY_FORMAT}
                          value={formik.values.start_date}
                          InputAdornmentProps={{ position: 'end' }}
                          invalidDateMessage=""
                          placeholder={PLACEHOLDER_DATE_PICKER}
                          InputProps={{ readOnly: true }}
                          error={formik.touched.start_date && !!formik.errors.start_date}
                          helperText={formik.touched.start_date && formik.errors.start_date}
                          disabled={!isEditable}
                          keyboardIcon={<img src={CalenderURL} alt="Calender" />}
                          onChange={handleDateChange('start_date')}
                          minDate={moment()}
                          maxDate={formik.values.end_date}
                          autoOk
                        />
                      </Box>
                      <Box flex="25%">
                        <GeneralInformationTimePicker
                          name="start_time"
                          fullWidth
                          inputVariant="outlined"
                          value={formik.values.start_time}
                          InputAdornmentProps={{ position: 'end' }}
                          onChange={handleDateChange('start_time')}
                          invalidDateMessage=""
                          InputProps={{ readOnly: true }}
                          error={formik.touched.start_time && !!formik.errors.start_time}
                          helperText={formik.touched.start_time && formik.errors.start_time}
                          disabled={!isEditable}
                          keyboardIcon={<img src={ClockURL} alt="ClockURL" />}
                          variant="inline"
                          autoOk
                        />
                      </Box>
                      <Box mx={0.5} />
                      <Box flex="25%">
                        <GeneralInformationDatePicker
                          name="end_date"
                          fullWidth
                          inputVariant="outlined"
                          format={DATE_PICKER_DISPLAY_FORMAT}
                          value={formik.values.end_date}
                          InputAdornmentProps={{ position: 'end' }}
                          onChange={handleDateChange('end_date')}
                          invalidDateMessage=""
                          placeholder={PLACEHOLDER_DATE_PICKER}
                          InputProps={{ readOnly: true }}
                          minDate={formik.values.start_date}
                          error={formik.touched.end_date && !!formik.errors.end_date}
                          helperText={formik.touched.end_date && formik.errors.end_date}
                          disabled={!isEditable}
                          keyboardIcon={<img src={CalenderURL} alt="Calender" />}
                          autoOk
                        />
                      </Box>
                      <Box flex="25%">
                        <GeneralInformationTimePicker
                          name="end_time"
                          fullWidth
                          inputVariant="outlined"
                          value={formik.values.end_time}
                          InputAdornmentProps={{ position: 'end' }}
                          onChange={handleDateChange('end_time')}
                          invalidDateMessage=""
                          InputProps={{ readOnly: true }}
                          error={formik.touched.end_time && !!formik.errors.end_time}
                          helperText={formik.touched.end_time && formik.errors.end_time}
                          disabled={!isEditable}
                          keyboardIcon={<img src={ClockURL} alt="ClockURL" />}
                          variant="inline"
                          autoOk
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box mb={3}>
                  <Label required>Duration (hours)</Label>
                  <FormTextField
                    value={formik.values.duration}
                    onChange={handleInputChange('duration')}
                    onBlur={formik.handleBlur}
                    error={formik.touched.duration && !!formik.errors.duration}
                    helperText={formik.touched.duration && formik.errors.duration}
                    name="duration"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={!isEditable}
                  />
                </Box>
                <Box mb={3}>
                  <Label required>Category</Label>
                  <FormTextField
                    variant="outlined"
                    name="category"
                    fullWidth
                    size="small"
                    value={formik.values.category}
                    onChange={handleCategoryChange}
                    select
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      },
                    }}
                    onBlur={formik.handleBlur}
                    error={formik.touched.category && !!formik.errors.category}
                    helperText={formik.touched.category && formik.errors.category}
                    disabled={!isEditable || event.status !== DRAFT}
                  >
                    <FormMenuItem value="default">All Category</FormMenuItem>
                    {EventCategory.map(({ value, label }, idx) => (
                      <FormMenuItem key={idx} value={value}>
                        {label}
                      </FormMenuItem>
                    ))}
                  </FormTextField>
                </Box>
                <Box mb={3}>
                  <Label required>Event Type</Label>
                  <RadioGroup
                    name="event_type"
                    row
                    value={formik.values.event_type}
                    onChange={handleRadioButtonChange('event_type')}
                  >
                    <FormControlLabelStyled
                      value="location-based"
                      control={<NASSRadio disabled={!isEditable} />}
                      label="Location Based"
                      disabled={!isEditable}
                    />
                    <Box width={100} />
                    <FormControlLabelStyled
                      value="online-event"
                      control={<NASSRadio disabled={!isEditable} />}
                      label="Online Event"
                      disabled={!isEditable}
                    />
                  </RadioGroup>
                </Box>
                <Box mb={3}>
                  <Label required>Location/Link For Virtual Event</Label>
                  <FormTextField
                    id="location"
                    name="location"
                    value={formik.values.location}
                    onChange={handleInputChange('location')}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={formik.touched.location && !!formik.errors.location}
                    helperText={formik.touched.location && formik.errors.location}
                    disabled={!isEditable}
                    inputProps={{ maxLength: MAX_LENGTH_255 }}
                  />
                </Box>
                <Box mb={3}>
                  <Label required>Max Number of guest</Label>
                  <FormTextField
                    value={formik.values.max_guest}
                    onChange={handleInputChange('max_guest')}
                    onBlur={formik.handleBlur}
                    error={formik.touched.max_guest && !!formik.errors.max_guest}
                    helperText={formik.touched.max_guest && formik.errors.max_guest}
                    name="max_guest"
                    variant="outlined"
                    size="small"
                    fullWidth
                    disabled={!isEditable}
                  />
                </Box>
                <Box mb={3}>
                  <Label>Does This Event Open For Public</Label>
                  <RadioGroup
                    name="is_public"
                    row
                    value={formik.values.is_public}
                    onChange={handleRadioButtonChange('is_public')}
                  >
                    <FormControlLabelStyled
                      value="yes"
                      control={<NASSRadio disabled={!isEditable || formik.values.category === GTKY_CATEGORY} />}
                      label="Yes"
                      disabled={!isEditable || formik.values.category === GTKY_CATEGORY}
                    />
                    <Box width={100} />
                    <FormControlLabelStyled
                      value="no"
                      control={<NASSRadio disabled={!isEditable || formik.values.category === GTKY_CATEGORY} />}
                      label="No"
                      disabled={!isEditable || formik.values.category === GTKY_CATEGORY}
                    />
                  </RadioGroup>
                </Box>
                <Box mb={3} display="flex" alignItems="center">
                  <Label style={{ marginBottom: -5 }}>Turn on/off Link for registration</Label>
                  <Box width={100} />
                  <EventSwitch
                    checked={formik.values.link_for_registration}
                    onChange={
                      !isEditable || startTime < moment() ? undefined : handleSwitchChange('link_for_registration')
                    }
                  />
                </Box>
                <Box mb={3} display="flex" style={{ gap: 24 }}>
                  <Box width="50%">
                    <Label required>Registration Link auto close at</Label>
                    <Box display="flex" style={{ gap: 12, alignItems: 'flex-start' }}>
                      <GeneralInformationDatePicker
                        name="registration_close_date"
                        fullWidth
                        inputVariant="outlined"
                        format={DATE_PICKER_DISPLAY_FORMAT}
                        value={formik.values.registration_close_date}
                        InputAdornmentProps={{ position: 'end' }}
                        onChange={handleDateChange('registration_close_date')}
                        invalidDateMessage=""
                        placeholder={PLACEHOLDER_DATE_PICKER}
                        InputProps={{ readOnly: true }}
                        error={formik.touched.registration_close_date && !!formik.errors.registration_close_date}
                        helperText={formik.touched.registration_close_date && formik.errors.registration_close_date}
                        disabled={!isEditable}
                        keyboardIcon={<img src={CalenderURL} alt="Calender" />}
                        maxDate={formik.values.start_date}
                        variant="inline"
                        autoOk
                      />
                      <GeneralInformationTimePicker
                        name="registration_close_time"
                        fullWidth
                        inputVariant="outlined"
                        value={formik.values.registration_close_time}
                        InputAdornmentProps={{ position: 'end' }}
                        onChange={handleDateChange('registration_close_time')}
                        invalidDateMessage=""
                        InputProps={{ readOnly: true }}
                        keyboardIcon={<img src={ClockURL} alt="ClockURL" />}
                        error={formik.touched.registration_close_time && !!formik.errors.registration_close_time}
                        helperText={formik.touched.registration_close_time && formik.errors.registration_close_time}
                        disabled={!isEditable}
                        variant="inline"
                        autoOk
                      />
                    </Box>
                  </Box>
                  <Box display="flex" style={{ gap: 12, alignItems: 'flex-end' }}>
                    <Box flex="50%" flexShrink="0" />
                    <Box flex="50%" flexShrink="0" />
                  </Box>
                </Box>
                <Box mb={3} onClick={handleOpenFile} display="inline-block">
                  <Label required>Cover photo (Recommended size to 16 x 9. 1920 x 1080 pixels)</Label>
                  <PreviewImage
                    src={formik.values.cover_photo!}
                    disabled={!isEditable}
                    error={formik.touched.cover_photo && !!formik.errors.cover_photo}
                  >
                    {!isUploading && isEditable && formik.values.cover_photo && (
                      <Box
                        width="100%"
                        height="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                        position="absolute"
                        left="0"
                        bottom="0"
                        right="0"
                        top="0"
                        style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1))' }}
                      >
                        <UploadPictureIcon />
                        <UploadText>Cover photo</UploadText>
                      </Box>
                    )}
                    <Box textAlign="center">
                      {isUploading && <CircularProgress size={20} />}
                      {!formik.values.cover_photo && isEditable && !isUploading && (
                        <>
                          <UploadPictureIcon />
                          <UploadText>Cover photo</UploadText>
                        </>
                      )}
                      {!formik.values.cover_photo && !isEditable && (
                        <>
                          <img src={UploadImageDisabledImage} alt="Upload Disable" />
                          <UploadText disabled={true}>Cover photo</UploadText>
                        </>
                      )}
                    </Box>
                  </PreviewImage>
                  <input accept=".png, .jpg" type="file" ref={setRef} hidden onChange={handleUploadCoverPhoto} />
                  <Box mt={1}>
                    {formik.touched.cover_photo && <ErrorMessage>{formik.errors.cover_photo}</ErrorMessage>}
                  </Box>
                </Box>
                <Box mb={3}>
                  <Label>Short Description</Label>
                  <ShortDescription
                    value={formik.values.short_description!}
                    name="short_description"
                    onChange={handleInputChange('short_description')}
                    disable={!isEditable}
                    maxLength={MAX_LENGTH_255}
                    onBlur={formik.handleBlur}
                  />
                </Box>
                <Box mb={3}>
                  <Label required>Full Description</Label>
                  <RichEditor
                    type="event"
                    value={formik.values.description as TNode<AnyObject>[]}
                    onChange={(val) => {
                      formik.setFieldValue('description', val);
                    }}
                    headerType="event"
                    disable={!isEditable}
                    bgColor={!isEditable ? '#0000001A' : ''}
                    radius="6px"
                    border={!isEditable ? '1px solid #0000001a' : '1px solid transparent'}
                    require={formik.touched.short_description && isDescriptionEmpty}
                    empty={handleEmptyChange}
                  />
                  {isDescriptionEmpty && formik.touched.short_description && (
                    <ErrorMessage>​This field is required</ErrorMessage>
                  )}
                </Box>
                <Box display="flex" justifyContent="flex-end" alignItems="center" style={{ gap: 16 }}>
                  {isEditable ? (
                    <>
                      <ActionButton variants="outlined" onClick={handleCancel}>
                        Cancel
                      </ActionButton>
                      <ActionButton
                        variants="yellow"
                        disabled={loading}
                        onClick={() => {
                          setOpenConfirmModal(true);
                        }}
                      >
                        Save
                      </ActionButton>
                    </>
                  ) : (
                    <ActionButton
                      variants="yellow"
                      onClick={handleToggleEditable}
                      disabled={event.status === CANCELLED || event.status === COMPLETED}
                    >
                      Edit
                    </ActionButton>
                  )}
                </Box>
                {openConfirmModal && (
                  <ConfirmPopup
                    open={openConfirmModal}
                    title="Notification"
                    content="Are you sure you want to save this change?"
                    onCancel={() => setOpenConfirmModal(false)}
                    onOk={() => {
                      setOpenConfirmModal(false);
                      formikHandleSubmit();
                    }}
                    btnCancelVariant="outlined"
                    btnOkVariant="filled"
                  />
                )}
              </GeneralContainer>
            </Form>
          );
        }}
      </Formik>
      <Box pb={3} mt={3} display="flex" justifyContent="center">
        <TextStyled>
          Created on {event?.createdAt ? new Date(event?.createdAt).toLocaleDateString('en-GB') : ''} by{' '}
          {event?.createdBy ? event.createdBy : ''} | Last Updated on{' '}
          {event?.updatedAt ? new Date(event?.updatedAt).toLocaleDateString('en-GB') : ''} by:{' '}
          {event?.updatedBy ? event.updatedBy : ''}
        </TextStyled>
      </Box>
    </GeneralInformationContainer>
  );
};

export default General;
