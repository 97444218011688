import React from 'react';

import { Box } from '@material-ui/core';

import CorrectImageSrc from 'src/medias/icons/icon-success.png';
import useResponsive from 'src/utils/responsive';

import { BigTitle, CorrectImage, Subtitle, SuccessContainer } from './index.styled';

interface Props {}

const Success = (props: Props) => {
  const responsive = useResponsive();
  return (
    <SuccessContainer responsive={responsive}>
      <BigTitle>Register successful!</BigTitle>
      <CorrectImage src={CorrectImageSrc} />
      <Box mt={7.5}>
        <Subtitle>Your reservation will be saved.</Subtitle>
      </Box>
      <Box mb={7.5}>
        <Subtitle variants="success" responsive={responsive}>
          Please be patient! We will send you tickets via email within 7 days if we received your payment. If we don’t
          receive your payment, your reservation will be expired after 7 days
        </Subtitle>
      </Box>
    </SuccessContainer>
  );
};

export default Success;
