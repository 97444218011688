import React from 'react';

import { Box } from '@material-ui/core';

import { LabelStyled, TypographyUserInfo } from '../types';

export const InfoText = ({
  label,
  value,
  valueWidth,
  labelWidth,
}: {
  label: string;
  value: string;
  valueWidth?: string;
  labelWidth?: string;
}) => {
  return (
    <Box display="flex" mt={2}>
      <Box width={labelWidth ?? '30%'}>
        <LabelStyled>{label}</LabelStyled>
      </Box>
      <Box width={'5%'}>
        <LabelStyled>:</LabelStyled>
      </Box>
      <Box width={valueWidth ?? '65%'}>
        <TypographyUserInfo>{value}</TypographyUserInfo>
      </Box>
    </Box>
  );
};
