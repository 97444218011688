import React from 'react';

import { Box, Theme } from '@material-ui/core';
import styled from 'styled-components';

import { FooterLinkStyled } from 'src/layouts/main/components/footer';
import useResponsive from 'src/utils/responsive';

const FooterBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.yellow?.[1000],
  width: '100%',
  height: 30,
  display: 'flex',
  alignItems: 'center',
  padding: '30px 0px',
  gap: '20px',
  justifyContent: 'space-between',
}));
const Copyright = styled(Box)(({ theme, isTabletOrMobile }: { theme: Theme; isTabletOrMobile: boolean }) => ({
  color: theme.palette.common.white,
  marginLeft: isTabletOrMobile ? 16 : 50,
}));
export const Footer = () => {
  const { isTabletOrMobile, isDesktopOrLaptop } = useResponsive();
  return (
    <Box position="relative" display={'flex'} justifyContent="space-between">
      <FooterBox>
        <Copyright isTabletOrMobile={isTabletOrMobile}>Copyright © 2022 Nanyang Schools Alumni Association</Copyright>
        <Box pr={isDesktopOrLaptop ? '50px' : '0px'} display="flex" style={{ gap: 10 }}>
          <FooterLinkStyled to={'/terms-of-use'}>Terms Of Use</FooterLinkStyled>
          <Box style={{ color: '#fff' }}>|</Box>
          <FooterLinkStyled to={'/privacy-policy'}>Privacy Policy</FooterLinkStyled>
        </Box>
      </FooterBox>
    </Box>
  );
};
