import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import { ACCOUNT_TYPE } from 'src/constants/users.constants';
import ProfileTabs from 'src/pages/profile/shared/profile.tabs';
import { getUserApplicantById, IApplicant, verifyUserById } from 'src/services/gtky-applicant';
import { assignInterviewer } from 'src/services/users';

import ApplicationInformationHeader from '../shared/ApplicationInformationHeader';
import { ApplicantTitle } from '../types';

import AdditionalInformation from './AdditionalInformation';
import AssignModal from './AssignModal';
import GeneralInformation from './GeneralInformation';

interface IApplicantInfoProps {
  userId?: string;
  registrationId?: string;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const ApplicantInfo = ({ userId, registrationId, setActiveTab }: IApplicantInfoProps) => {
  const [loading, setLoading] = useState(false);
  const [applicantInfo, setApplicationInfo] = useState<IApplicant>();
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [openAssignModal, setOpenAssignModal] = useState(false);
  const [idItemSelected, setIdItemSelected] = useState<string[]>([]);

  const tabs = [
    { label: 'General Information', disabled: false },
    { label: 'Additional Information', disabled: false },
  ];

  const panels = [
    <GeneralInformation applicant={applicantInfo} setApplicationInfo={setApplicationInfo} />,
    <AdditionalInformation applicant={applicantInfo} setApplicationInfo={setApplicationInfo} />,
  ];

  useEffect(() => {
    if (userId) {
      (async () => {
        const response = await getUserApplicantById(userId);
        if (response.statusCode === CALL_SUCCESS) {
          setApplicationInfo(response.data);
        }
      })();
    }
  }, [userId]);

  const handleAssignModal = () => {
    setOpenAssignModal(!openAssignModal);
    setIdItemSelected([]);
  };

  const _onAssignInterview = async () => {
    setLoading(true);
    if (registrationId) {
      const response = await assignInterviewer({
        registrationIds: [registrationId],
        interviewIds: idItemSelected,
      });

      if (response?.statusCode === CALL_SUCCESS) {
        (async () => {
          const response = await getUserApplicantById(applicantInfo?.id!);
          if (response.statusCode === CALL_SUCCESS) {
            setApplicationInfo(response.data);
            setShowSnackBarSuccess(true);
            setMessage(response.message);
          } else {
            setShowSnackBarError(true);
            setMessage(response.message);
          }
        })();
      } else {
        setShowSnackBarError(true);
        setMessage(response.message);
      }
      handleAssignModal();
      setLoading(false);
      return;
    }
    setLoading(false);
  };

  const handleBack = () => {
    window.location.reload();
  };

  const handleVerify = () => {
    if (!applicantInfo) return;
    (async () => {
      const response = await verifyUserById(applicantInfo?.id!);
      if (response.statusCode === CALL_SUCCESS) {
        (async () => {
          const response = await getUserApplicantById(applicantInfo?.id!);
          if (response.statusCode === CALL_SUCCESS) {
            setApplicationInfo(response.data);
            setShowSnackBarSuccess(true);
            setMessage(response.message);
          } else {
            setShowSnackBarError(true);
            setMessage(response.message);
          }
        })();
      }
    })();
  };

  const handleAssignInterview = () => {
    setOpenAssignModal(true);
  };

  if (!applicantInfo) return <div />;

  const interviewers = applicantInfo?.interviewers?.map((item) => `${item?.surname}, ${item?.givenName}; `).join('');
  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };
  return (
    <Box maxWidth={1280} mx="auto">
      <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
      <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
      <ApplicantTitle>Applicant Information</ApplicantTitle>
      <ApplicationInformationHeader
        nsaaId={applicantInfo.username}
        accountType={ACCOUNT_TYPE.find((i) => i.value === applicantInfo.accountType)?.label || ''}
        interviewer={interviewers}
        imageUrl={applicantInfo.avatar}
        handleBack={handleBack}
        handleVerify={handleVerify}
        handleAssignInterview={handleAssignInterview}
        applicant={applicantInfo}
      />
      <Box mt={4}>
        <ProfileTabs panels={panels} tabs={tabs} />
      </Box>
      {openAssignModal && (
        <AssignModal
          loading={loading}
          open={openAssignModal}
          _onCloseModal={handleAssignModal}
          application={applicantInfo}
          idItemSelected={idItemSelected}
          setIdItemSelected={setIdItemSelected}
          _onSubmit={_onAssignInterview}
        />
      )}
    </Box>
  );
};

export default ApplicantInfo;
