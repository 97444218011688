import React, { useState } from 'react';

import { Box, CircularProgress, FormControlLabel, IconButton, RadioGroup, Typography } from '@material-ui/core';
import { Form, FieldArray, Formik } from 'formik';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';

import { NASSRadio } from 'src/components';
import { DATE_PICKER_DISPLAY_FORMAT, PLACEHOLDER_DATE_PICKER } from 'src/constants/common';
import { INPUT_MAX_LENGTH_50, NATIONALITY } from 'src/constants/users.constants';
import { PROFILE_VALIDATION_MESSAGE } from 'src/constants/validation-message.constant';
import { VERIFY_STATUS } from 'src/constants/verify.constant';
import CloseIcon from 'src/icons/close';
import { Close } from 'src/pages/user-management/shared/user-detail.component';
import { IUser } from 'src/services/users';

import { Title } from '../components/additional-information.styled';
import {
  Label,
  FormTextField,
  FormMenuItem,
  GeneralInformationDatePicker,
  ActionButton,
} from '../components/general-information.styled';
import VerifyStatus from '../shared/verify.message';

export const HeaderSticky = styled(Box)`
  ${({ theme }) => css`
    /* margin-bottom: 24px; */
    position: sticky;
    top: 0;
    background-color: ${theme.palette.common.white};
    z-index: 20;
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
  `}
`;

export const FooterSticky = styled(Box)`
  display: flex;
  gap: 24px;
  position: sticky;
  bottom: 0;
  background: white;
  padding-bottom: 24px;
  padding-top: 24px;
  justify-content: center;
`;

export const BigTitle = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header3.fontSize};
    font-weight: ${theme.fonts.header3.fontWeight};
    line-height: 32px;
  `}
`;

interface Props {
  onClose: () => void;
  onSave: (profile: any) => void;
  isSaveLoading?: boolean;
  profile: IUser | null;
}

const AdditionalInformationPopup = ({ onClose, onSave, profile, isSaveLoading }: Props) => {
  const [isHaveChildren, setHaveChildren] = useState<boolean>(
    profile?.child && profile?.child.length > 0 ? true : false,
  );

  function handleSubmitWhenChooseNo() {
    if (!isHaveChildren) {
      onSave({
        child: [],
      });
    }
  }

  return (
    <Box px={3}>
      <HeaderSticky>
        <BigTitle>Additional Information</BigTitle>
        <Close onClick={onClose}>
          <CloseIcon fill="white" />
        </Close>
      </HeaderSticky>
      <Box mb={1.8}>
        <Box mb={3}>
          <Title>Your Children Info</Title>
        </Box>
        <Label>Do You Have Children?</Label>
        <RadioGroup
          value={isHaveChildren ? 'yes' : 'no'}
          onChange={(e, v) => {
            setHaveChildren(v === 'no' ? false : true);
          }}
          row
        >
          <FormControlLabel value="yes" control={<NASSRadio />} label="Yes" />
          <Box width={160} />
          <FormControlLabel value="no" control={<NASSRadio />} label="No" />
        </RadioGroup>
      </Box>

      <Formik
        onSubmit={(values) => {
          onSave({
            child: isHaveChildren
              ? values.child.map((item) => ({
                  ...item,
                  verificationStatus: item.verificationStatus === '' ? null : item.verificationStatus,
                }))
              : [],
          });
        }}
        validationSchema={Yup.object({
          child: Yup.array(
            Yup.object({
              surname: Yup.string().required(PROFILE_VALIDATION_MESSAGE.SURNAME),
              givenName: Yup.string().required(PROFILE_VALIDATION_MESSAGE.GIVEN_NAME),
              dateOfBirth: Yup.string().required(PROFILE_VALIDATION_MESSAGE.DOB),
            }),
          ),
        })}
        initialValues={{
          child:
            profile?.child && profile?.child?.length > 0
              ? profile.child
              : [
                  {
                    surname: '',
                    givenName: '',
                    dateOfBirth: '',
                    nationality: 'singapore-citizen',
                    verificationStatus: null,
                  },
                ],
        }}
      >
        {({ values, touched, errors, handleChange, handleBlur, setFieldValue }) => {
          const e = errors as any;
          return (
            <Form>
              {isHaveChildren && values.child.length > 0 && (
                <Box mb={0.5} display="flex" style={{ gap: 16 }}>
                  <Box flex="1">
                    <Label>Child’s Surname</Label>
                  </Box>
                  <Box flex="1">
                    <Label>Child’s GivenName</Label>
                  </Box>
                  <Box flex="1">
                    <Label>Child’s Date Of Birth</Label>
                  </Box>
                  <Box flex="1">
                    <Label>Child’s Nationality</Label>
                  </Box>
                  <Box flex="1.3" />
                </Box>
              )}
              {isHaveChildren && (
                <FieldArray name="child">
                  {({ push, remove }) => {
                    return (
                      <>
                        {values.child.map((item, index) => {
                          return (
                            <Box mb={3} display="flex" style={{ gap: 16 }}>
                              <Box flex="1" flexShrink="0">
                                <FormTextField
                                  variant="outlined"
                                  fullWidth
                                  value={item.surname}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name={`child[${index}].surname`}
                                  inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
                                  error={touched?.child?.[index]?.surname && !!e?.child?.[index]?.surname}
                                  helperText={touched?.child?.[index]?.surname && e?.child?.[index]?.surname}
                                />
                              </Box>
                              <Box flex="1" flexShrink="0">
                                <FormTextField
                                  variant="outlined"
                                  fullWidth
                                  value={item.givenName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  name={`child[${index}].givenName`}
                                  inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
                                  error={touched?.child?.[index]?.givenName && !!e?.child?.[index]?.givenName}
                                  helperText={touched?.child?.[index]?.givenName && e?.child?.[index]?.givenName}
                                />
                              </Box>
                              <Box flex="1" flexShrink="0">
                                <GeneralInformationDatePicker
                                  fullWidth
                                  autoOk
                                  variant="inline"
                                  inputVariant="outlined"
                                  format={DATE_PICKER_DISPLAY_FORMAT}
                                  value={item.dateOfBirth}
                                  placeholder={PLACEHOLDER_DATE_PICKER}
                                  InputProps={{ readOnly: true }}
                                  InputAdornmentProps={{ position: 'end' }}
                                  error={touched?.child?.[index]?.dateOfBirth && !!e?.child?.[index]?.dateOfBirth}
                                  invalidDateMessage={
                                    (touched?.child?.[index]?.dateOfBirth && e?.child?.[index]?.dateOfBirth) || ''
                                  }
                                  onChange={(date) => {
                                    if (!date) return;
                                    setFieldValue(`child[${index}]`, {
                                      ...values.child[index],
                                      dateOfBirth: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                                    });
                                  }}
                                />
                              </Box>
                              <Box flex="1" flexShrink="0">
                                <FormTextField
                                  ellipsis
                                  variant="outlined"
                                  name="nationality"
                                  fullWidth
                                  size="small"
                                  value={item.nationality}
                                  onChange={(e) => {
                                    setFieldValue(`child[${index}]`, {
                                      ...values.child[index],
                                      nationality: e.target.value,
                                    });
                                  }}
                                  onBlur={handleBlur}
                                  select={true}
                                  SelectProps={{
                                    MenuProps: {
                                      anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                      },
                                      getContentAnchorEl: null,
                                    },
                                  }}
                                >
                                  {NATIONALITY.map(({ value, label }, idx) => (
                                    <FormMenuItem key={idx} value={value}>
                                      {label}
                                    </FormMenuItem>
                                  ))}
                                </FormTextField>
                              </Box>
                              <Box
                                flex="1.3"
                                mb={
                                  touched?.child?.[index] && !!e?.child?.[index] && touched?.child?.[index].givenName
                                    ? 3
                                    : 0.5
                                }
                              >
                                <VerifyStatus
                                  variants={item.verificationStatus ?? VERIFY_STATUS.UNKNOWN}
                                  onRemove={() => {
                                    remove(index);
                                  }}
                                />
                              </Box>
                            </Box>
                          );
                        })}
                        <Box width="100%">
                          <ActionButton
                            variants="outlined"
                            style={{ width: '100%' }}
                            onClick={() =>
                              push({
                                surname: '',
                                givenName: '',
                                dateOfBirth: '',
                                nationality: 'singapore-citizen',
                                verificationStatus: null,
                              })
                            }
                          >
                            Add More Child
                          </ActionButton>
                        </Box>
                      </>
                    );
                  }}
                </FieldArray>
              )}
              <FooterSticky>
                <ActionButton onClick={onClose} variants="outlined">
                  Cancel
                </ActionButton>
                <ActionButton
                  disabled={isSaveLoading}
                  type="submit"
                  variants="yellow"
                  onClick={handleSubmitWhenChooseNo}
                >
                  {isSaveLoading && (
                    <IconButton>
                      <CircularProgress size={20} />
                    </IconButton>
                  )}
                  Save
                </ActionButton>
              </FooterSticky>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default AdditionalInformationPopup;
