import React, { useState, useEffect } from 'react';

import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import _ from 'lodash';
import styled, { css } from 'styled-components';

import { CustomButton, InputField } from 'src/components';
import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import ErrorSnackBar from 'src/components/snackBar/error';
import {
  ACCEPT_TYPE,
  CALL_SUCCESS,
  ERROR_MESSAGE_IMAGE,
  IMAGE_1MB_SIZE,
  MAX_LENGTH_128,
  MAX_LENGTH_225,
} from 'src/constants/common';
import { getCMSData, ICms, postCMSData } from 'src/services/cms';
import { uploadCoverPhoto } from 'src/services/events';

import CMSConfirmModal from '../../CMSConfirmModal';
import CMSPreview from '../CMSPreview';

const CMS_TYPE = 'committee';
interface IErrorMessage {
  isError: boolean;
  message: string;
}
interface IConfirmModal {
  action: string;
  open: boolean;
}

const TEXT_REQUIRE = 'This field is required';
const useStyles = makeStyles((theme) => ({
  noticeRequire: {
    color: 'red',
    verticalAlign: 'middle',
    marginLeft: 4,
  },
}));
const ErrorMessage = styled(Box)`
  color: #f44336;
  font-size: 12px;
`;
const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  padding: 8,
}));
const HeaderGroup = styled(Box)(({ theme }) => ({
  gap: '10px',
}));
const Title = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
  width: '100%',
}));
const InputFieldCustom = styled(InputField)`
  ${({ theme }) => css`
    & input {
      padding: 10px 16px;
    }
    & .MuiFormControl-root.MuiTextField-root {
      margin: 0;
      margin-top: 4px;
    }
    & .MuiBox-root {
      margin-top: 24px;
    }
  `}
`;
const SectionTitle = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 700;
    vertical-align: middle;
    max-height: 18px;
    color: rgba(0, 0, 0, 0.6);
  }
`;
const ContainerUploadImage = styled(Box)`
  height: 180px;
`;
const UploadBox = styled.input`
  display: none;
`;
const Label = styled.label<{ backgroundImg?: string }>`
  ${({ theme, backgroundImg }) => css`
    background-blend-mode: overlay;
    background-color: #0000004a;
    width: 25%;
    height: 100%;
    display: flex;
    background-repeat: no-repeat;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-image: url(${backgroundImg});
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
  `}
`;
const IconBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
const AddPhotoAlternateIconCustom = styled(AddPhotoAlternateIcon)`
  color: white;
  width: 32px;
  height: 32px;
`;
const UploadText = styled(Typography)`
  ${({ theme }) => ({
    ...theme.fonts.textM,
  })}
  color: white;
`;
const BannerPhoto = styled.img<{ backgroundImg?: string }>`
  ${({ theme, backgroundImg }) => css`
    width: 25%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(${backgroundImg});
    border-radius: 8px;
  `}
`;
const ButtonGroup = styled(Box)`
  display: flex;
  gap: 10px;
`;
const CancelButton = styled(Button)`
  ${({ theme }) => css`
    border: 1px solid ${theme.palette.red?.[250]};
    color: ${theme.palette.red?.[250]};
    width: 193px;
    height: 44px;
  `}}
`;
function Committee() {
  const classes = useStyles();
  const [data, setData] = useState<ICms>({
    type: '',
    banner_image: '',
    banner_title: '',
    banner_description: '',
    content_title: '',
    content_body: '',
    background_image: '',
    item_image: '',
    item_date: '',
    item_image_description: '',
  });
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const [initialValue, setInitialValue] = useState<ICms>({
    type: '',
    banner_image: '',
    banner_title: '',
    banner_description: '',
    content_title: '',
    content_body: '',
    background_image: '',
    item_image: '',
    item_date: '',
    item_image_description: '',
  });
  const [isUploading, setIsUploading] = useState(false);
  const [errors, setErrors] = useState({
    banner_image: false,
    banner_title: false,
  });
  const [errorMessage, setErrorMessage] = useState<IErrorMessage>();
  const [openConfirmModal, setOpenConfirmModal] = useState<IConfirmModal>({
    action: '',
    open: false,
  });
  useEffect(() => {
    getData();
  }, []);
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: false });
  }
  const getData = async () => {
    setIsUploading(true);

    const response = await getCMSData(CMS_TYPE);
    if (response.statusCode === CALL_SUCCESS) {
      setInitialValue(response.data as ICms);
      setData(response.data as ICms);
    }
    setIsUploading(false);
  };
  async function uploadChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      const fsize = event.target.files[0].size;
      const ftype = event.target.files[0].type;
      const file = Math.round(fsize / IMAGE_1MB_SIZE);
      const formData = new FormData();
      formData.append('image', event.target.files[0]);
      if (
        file >= IMAGE_1MB_SIZE ||
        (ftype !== ACCEPT_TYPE.jpeg && ftype !== ACCEPT_TYPE.png && ftype !== ACCEPT_TYPE.jpg)
      ) {
        setErrorMessage({
          isError: true,
          message: ERROR_MESSAGE_IMAGE,
        });
        return;
      }
      setIsUploading(true);
      const response = await uploadCoverPhoto(formData);
      setIsUploading(false);
      if (response.data?.name) {
        setData({ ...data, banner_image: response.data?.name });
      }
    }
  }

  const EditClick = () => {
    setIsEditMode(true);
  };
  const previewClick = () => {
    setOpen(true);
  };
  const CancelClick = () => {
    setOpenConfirmModal({
      action: 'cancel',
      open: true,
    });
  };
  const onSaveClick = async () => {
    let validErrors = errors;
    if (_.isEmpty(data.banner_image)) {
      validErrors.banner_image = true;
      setErrors({ ...errors, banner_image: true });
    }
    if (_.isEmpty(data.banner_title)) {
      validErrors.banner_title = true;
      setErrors({ ...errors, banner_title: true });
    }
    if (validErrors.banner_title || validErrors.banner_image) {
      return;
    } else {
      setOpenConfirmModal({
        action: 'save',
        open: true,
      });
    }
  };
  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setData({ ...data, [e.target.name]: valueTrimmed });
  };
  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setErrorMessage({
          isError: false,
          message: '',
        });
      }
    };
  };
  const onSubmitConfirmModal = async () => {
    if (openConfirmModal.action === 'cancel') {
      handleCancel();
    }
    if (openConfirmModal.action === 'save') {
      handleSave();
    }
    setOpenConfirmModal({
      action: '',
      open: false,
    });
  };
  const _onCloseConfirmModal = () => {
    setOpenConfirmModal({
      action: '',
      open: false,
    });
  };
  const handleCancel = () => {
    setErrors({
      banner_image: false,
      banner_title: false,
    });
    setData(initialValue);
    setIsEditMode(false);
  };
  const handleSave = async () => {
    setIsUploading(true);
    const { banner_title, banner_description, banner_image } = data;
    const params = {
      type: CMS_TYPE,
      banner_image,
      banner_description,
      banner_title,
    };
    const response = await postCMSData(params);
    if (response.statusCode === CALL_SUCCESS) {
      getData();
      setIsEditMode(false);
    }
  };
  return (
    <Box minHeight={500} p={4} bgcolor="white" boxShadow={'1px 1px 4px #9E9E9E'} borderRadius={4}>
      {<LoadingCustom loading={isUploading} loadingIcon />}
      <ErrorSnackBar
        open={errorMessage?.isError || false}
        handleClose={handleCloseSnackBar('error')}
        message={errorMessage?.message}
      />
      <CustomBox>
        <HeaderGroup display="flex" justifyContent="space-between" alignItems="center">
          <Title>Committee</Title>
          <ButtonGroup display="flex" alignItems="center">
            {isEditMode && <CancelButton onClick={CancelClick}>Cancel</CancelButton>}
            <CustomButton outlineButton onClick={previewClick}>
              Preview
            </CustomButton>
            {isEditMode ? (
              <CustomButton onClick={onSaveClick}>Save</CustomButton>
            ) : (
              <CustomButton onClick={EditClick}>Edit</CustomButton>
            )}
          </ButtonGroup>
        </HeaderGroup>
        <InputFieldCustom
          lableStyle={{ color: ' rgba(0, 0, 0, 0.6)' }}
          title="Banner Title"
          fullWidth
          value={data.banner_title}
          disabled={!isEditMode}
          type="text"
          name="banner_title"
          onChange={handleChange}
          colorTitle="black"
          require
          onBlur={onBlurField}
          error={errors.banner_title}
          errormessage={errors.banner_title && TEXT_REQUIRE}
          InputProps={{
            inputProps: {
              maxLength: MAX_LENGTH_128,
            },
          }}
        />
        <Box mt={3} display="flex" flexDirection="row">
          <SectionTitle>
            Banner Photo (Recommended image size is 1440px x 350px)
            <Typography component="span" className={classes.noticeRequire}>
              *
            </Typography>
          </SectionTitle>
        </Box>
        <ContainerUploadImage mt={0.5} display="flex" alignItems="center" position="relative">
          {isEditMode ? (
            <>
              <UploadBox type="file" id="contained-button-file" onChange={uploadChange} accept=".png, .jpg" />
              <Label htmlFor="contained-button-file" backgroundImg={data.banner_image}>
                <IconBox position="absolute" display="flex">
                  <AddPhotoAlternateIconCustom />
                  <UploadText>
                    Upload <br /> Cover photo
                  </UploadText>
                </IconBox>
              </Label>
            </>
          ) : (
            <BannerPhoto backgroundImg={data.banner_image && data.banner_image} />
          )}
        </ContainerUploadImage>
        {errors.banner_image && (
          <Box mt={1}>
            <ErrorMessage>{TEXT_REQUIRE}</ErrorMessage>
          </Box>
        )}
        <InputFieldCustom
          lableStyle={{ color: ' rgba(0, 0, 0, 0.6)' }}
          title="Banner Description"
          fullWidth
          type="text"
          value={data.banner_description}
          name="banner_description"
          onChange={handleChange}
          colorTitle="black"
          disabled={!isEditMode}
          onBlur={onBlurField}
          InputProps={{
            inputProps: {
              maxLength: MAX_LENGTH_225,
            },
          }}
        />
        {openConfirmModal && (
          <CMSConfirmModal
            openConfirmModal={openConfirmModal}
            onSubmit={onSubmitConfirmModal}
            handleClose={_onCloseConfirmModal}
          />
        )}
      </CustomBox>
      {open && <CMSPreview setOpen={setOpen} postData={data} title={'Committee'} emptyContent={true} />}
    </Box>
  );
}

export default Committee;
