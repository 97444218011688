import { Box, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const GuestContainer = styled(Box)`
  ${({ theme }) => css`
    border-radius: 16px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 1px 1px 4px #9e9e9e;
    border-radius: 4px;
    padding: 24px;
    max-width: 100%;
  `}
`;

export const ActionBar = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListingLabel = styled(Typography)`
  ${({ theme }) => css`
    color: #000000cc;
    font-size: ${theme.fonts.header4.fontSize};
    font-weight: ${theme.fonts.header4.fontWeight};
  `}
`;

export const GuestLabelItem = styled(Box)<{ variants?: 'name' }>`
  ${({ theme, variants }) => css`
    color: ${variants === 'name' ? theme.palette.yellow?.[900] : '#00000099'}};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  `}
`;

export const RegistrationInformationLabel = styled(Typography)<{ mt?: string }>`
  color: #333333;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  ${({ mt }) => css`
    margin-top: ${mt ? mt : ''};
  `};
`;
export const TextLabel = styled(Typography)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #00000099;
  text-transform: capitalize;
`;
export const TextContent = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #00000099;
  margin-left: 10px;
`;

export const StatisticLabel = styled(Typography)<{ header?: boolean }>`
  ${({ theme, header }) => css`
    color: #000000cc;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin: 24px 0;
    ${header &&
    css`
      color: white;
      margin: 0;
    `}
  `}
`;
export const StatisticContent = styled(Typography)`
  ${({ theme }) => css`
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    height: 48px;
  `}
`;

export const StatisticHeader = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.palette.yellow?.[900]};
  `}
`;

export const StatisticItem = styled(Box)<{ w?: number; pl?: number; item?: boolean; bold?: boolean }>`
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0 24px;
  color: white;
  ${({ w }) => css`
    min-width: ${w}px;
  `}
  ${({ pl }) => css`
    padding-left: ${pl}px;
  `}
  ${({ item }) =>
    item &&
    css`
      color: #333333;
      background-color: white;
    `}
  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}
`;
export const StatisticHeaderLabel = styled(Typography)``;

export const StatisticContainer = styled(Box)<{ border?: boolean }>`
  ${({ theme, border }) => css`
    ${border &&
    css`
      border: thin solid ${theme.palette.yellow?.[900]};
    `}
  `}
`;

export const RegistrationStatus = styled(Typography)<{
  variants?: 'pending' | 'expired' | 'confirmed' | 'checked-in' | 'attended' | 'verified' | 'deactivated' | undefined;
}>`
  ${({ theme, variants }) => css`
    padding: 6px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    background-color: ${variants === 'pending'
      ? theme.palette.yellow?.[150]
      : variants === 'expired' || variants === 'deactivated'
      ? theme.palette.red?.[150]
      : variants === 'confirmed' || variants === 'attended'
      ? theme.palette.yellow?.[1000]
      : variants === 'checked-in' || variants === 'verified'
      ? theme.palette.green?.[150]
      : null};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: white;
    height: 34px;
    text-transform: capitalize;
    box-sizing: border-box;
    width: 112px;
  `};
`;

export const ButtonStatus = styled(Typography)<{
  variants?: 'pending' | 'expired' | 'confirmed' | undefined;
}>`
  ${({ theme, variants }) => css`
    padding: 6px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    background-color: ${variants === 'pending'
      ? theme.palette.yellow?.[150]
      : variants === 'expired'
      ? theme.palette.red?.[150]
      : variants === 'confirmed'
      ? theme.palette.yellow?.[1000]
      : null};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: white;
    height: 34px;
    text-transform: capitalize;
    box-sizing: border-box;
    max-width: 112px;
  `};
`;
