import React from 'react';

import { Box, DialogContentText, DialogTitle } from '@material-ui/core';
import styled from 'styled-components';

import { CustomDialog } from 'src/components';

const DialogTitleStyled = styled(DialogTitle)(() => ({
  '& > h2': {
    fontSize: 20,
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.8)',
  },
  marginTop: 16,
  alignSelf: 'center',
}));

const DialogContentTextStyled = styled(DialogContentText)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
}));

interface IConfirmModal {
  open: boolean;
  handleClose: () => void;
  onSubmit: () => Promise<void>;
  actionSelected?: string;
  disabled?: boolean;
}

export const ConfirmModal = ({ disabled, open, handleClose, onSubmit, actionSelected }: IConfirmModal) => {
  let action = {
    title: 'Remove Role?',
    content: 'Are you sure you want to remove role ?',
  };

  if (actionSelected === 'event-ic') {
    action = {
      title: 'Assign role as Event IC?',
      content: 'Are you sure you want to proceed this action ?',
    };
  }
  if (actionSelected === 'membership-admin') {
    action = {
      title: 'Assign role as Membership Admin?',
      content: 'Are you sure you want to proceed this action ?',
    };
  }
  if (actionSelected === 'secretariat-admin') {
    action = {
      title: 'Assign role as Secretariat Admin?',
      content: 'Are you sure you want to proceed this action ?',
    };
  }
  if (actionSelected === 'it-admin') {
    action = {
      title: 'Assign role as IT Admin?',
      content: 'Are you sure you want to proceed this action ?',
    };
  }
  if (actionSelected === 'accountant') {
    action = {
      title: 'Assign role as Accountant?',
      content: 'Are you sure you want to proceed this action ?',
    };
  }
  if (actionSelected === 'Block Account') {
    action = {
      title: 'Block Account?',
      content: 'Are you sure you want to block this account(s) ?',
    };
  }
  if (actionSelected === 'Unblock Account') {
    action = {
      title: 'Unblock Account?',
      content: 'Are you sure you want to unblock this account(s) ?',
    };
  }
  if (actionSelected === 'Send Email' || actionSelected === 'send-email') {
    action = {
      title: 'Send Email?',
      content: 'Are you sure you want to send email ?',
    };
  }
  if (actionSelected === 'Set as Committee Member') {
    action = {
      title: 'Set as Committee Member?',
      content: 'Are you sure you want to set as commitee member ?',
    };
  }
  if (actionSelected === 'Delete User') {
    action = {
      title: 'Delete User?',
      content: 'Are you sure you want to delete this user?',
    };
  }
  if (actionSelected === 'Approve Membership') {
    action = {
      title: 'Approve Membership?',
      content: 'Are you sure you want to approve membership?',
    };
  }
  if (actionSelected === 'allow-access') {
    action = {
      title: 'Assign access to E-ticket checking?',
      content: 'Are you sure you want to allow these users to access E-ticket checking feature?',
    };
  }
  if (actionSelected === 'remove-access') {
    action = {
      title: 'Remove access to E-ticket checking?',
      content: 'Are you sure you want to remove permission to access E-ticket checking feature of these user?',
    };
  }
  if (actionSelected === 'update-volunteer') {
    action = {
      title: 'Update Volunteer?',
      content: 'Are you sure you want to update volunteer?',
    };
  }
  if (actionSelected === 'remove-volunteer') {
    action = {
      title: 'Remove Volunteer?',
      content: 'Are you sure you want to remove volunteer?',
    };
  }
  if (actionSelected === 'add-to-confirmed-list') {
    action = {
      title: 'Add to confirmed list?',
      content: 'Are you sure you want to add volunteer(s) to confirmed list?',
    };
  }
  if (actionSelected === 'check-in-ticket') {
    action = {
      title: 'Check In Ticket?',
      content: 'Are you sure you want to check-in this ticket?',
    };
  }
  if (actionSelected === 'Remove Committee Member') {
    action = {
      title: 'Remove committee member?',
      content: 'Are you sure you want to remove committee member?',
    };
  }

  return (
    <CustomDialog
      disabled={disabled}
      open={open}
      handleClose={handleClose}
      onSubmit={onSubmit}
      cancelText="No"
      submitText="Yes"
      deleteButton={actionSelected === 'Delete User'}
      isConfirmPopup={true}
    >
      <DialogTitleStyled id="form-dialog-title">{action.title}</DialogTitleStyled>
      <Box
        width={412}
        alignSelf="center"
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        maxWidth="100%"
      >
        <DialogContentTextStyled>{action.content}</DialogContentTextStyled>
      </Box>
    </CustomDialog>
  );
};
