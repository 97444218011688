export const GENDER = [
  { label: 'Female', value: 'female' },
  { label: 'Male', value: 'male' },
];

export const NATIONALITY = [
  {
    value: 'singapore-citizen',
    label: 'Singapore Citizen',
  },
  {
    value: 'permanent-resident',
    label: 'Permanent Resident (PR)',
  },
  {
    value: 'foreigner',
    label: 'Foreigner',
  },
];

export const SKILLS = [
  { label: 'Editorial', value: 'editorial' },
  { label: 'Translation', value: 'translation' },
  { label: 'Legal advice', value: 'legal-advice' },
  { label: 'Building/architecture/estate Management', value: 'building-architecture' },
  { label: 'Artistic Design', value: 'artistic-design' },
];

export const SKILLS_OTHERS = { label: 'other', value: 'Other Skills' };

export const ACCOUNT_TYPE = [
  {
    label: 'Youth Member',
    value: 'youth-member',
  },
  {
    label: 'Applicant',
    value: 'applicant',
  },
  {
    label: 'Life Membership',
    value: 'lifetime-membership',
  },
];

export const VERIFY_STATUS = [
  {
    label: 'Unverified',
    value: 'unverified',
  },
  {
    label: 'Verified',
    value: 'Verified',
  },
];

export const USER_MESSAGE = {
  CHANGE_ROLE_CURRENT_USER: "Can't change role of current user.",
  CHANGE_STATUS_OF_ADMIN: "Can't change status of admin.",
  NOT_FOUND_EMAIL: 'No user with given email.',
  NOT_FOUND_USER: 'Not found user.',
  WRONG_PASSWORD: 'Wrong password.',
  EDIT_PASSWORD_SUCCESS: 'Edit password success.',
  UPDATE_INFO_SUCCESS: 'Update info success.',
  EMAIL_ALREADY_EXIST: 'Email already exists.',
  MISSING_FIELDS: 'This form is only suitable for under 21 years old users.',
  DUPLICATE_PASSWORD: 'New password must be different from your last 6 passwords.',
  SAME_CURRENT_PASSWORD: 'New password must different with current password.',
  DELETE_USER_SUCCESS: 'Delete user successfully.',
  NOT_FOUND_AREA_CODE: 'No area code was found.',
  SENT_EMAIL_TO_PARENT_SUCESS: 'Sent email to parent success.',
  CREATE_USER_SUCESS: 'Create user success',
  USER_IS_VERIFIED: 'User is verified.',
  BIRTH_YEAR_HIGHER_THAN_CURRENT: 'Birth year is higher than current.',
  GRADUATE_YEAR_HIGHER_THAN_CURRENT: 'Graduate year is higher than current.',
  GRADUATE_YEAR_MUST_BE_HIGHER_THAN_BIRTH_YEAR: 'Year of graduation must be greater than birth year',
  NO_ACCOUNT_TYPE_SPECIFIED: 'No account type specified.',
  WRONG_FORM: 'This form is only suitable for over 21 years old users.',
  ASSIGN_INTERVIEW_SUCCESS: 'Assign interview success.',
  REGISTER_GTKY_SUCCESS: 'Register gtky event success.',
  EXPORT_CSV_SUCCESS: 'Export csv success.',
  REGISTER_UNREGISTER_VOLUNTEER_SUCCESS: 'Register/Unregister volunteer success.',
  NEED_A_REASON_TO_BLOCK: 'Need a reason to block.',
  NEED_A_ROLE_TO_UPDATE: 'Need a role to update.',
  GET_TEMPLATE_SENDGRID_SUCCESS: 'Get template sendgrid success.',
  SEND_EMAIL_WITH_TEMPLATE_SUCCESS: 'Send email with template template success.',
  APPROVE_MEMBERSHIP_SUCCESS: 'Approve membership success.',
  UPDATE_AVATAR_SUCCESS: 'Update avatar success.',
  PICK_1_FROM_2_EVENTS: 'Pick 1 from 2 events.',
  ASSIGN_SCAN_TICKET_SUCCESS: 'Assign scan ticket success.',
  ASSIGN_COMMITEE_MEMBERS_SUCCESS: 'Assign commitee members success.',
  SEARCH_IS_TOO_SHORT: 'Search field requires at least 3 characters.',
  CANT_REMOVE_YOUR_ACCOUNT: "Can't remove your account.",
  CANT_REMOVE_ADMIN: "Can't remove admin.",
  YOUTH_MEMBER_NOT_BE_OVER_25: 'Youth member’s age must not be over 25.',
  CURRENT_PASSWORD_NOT_CORRECT: 'The current password is not correct. Please try again.',
};

export const USER_ROLE = [
  {
    label: 'IT Admin',
    value: 'it-admin',
  },
  {
    label: 'Secretariat Admin',
    value: 'secretariat-admin',
  },
  {
    label: 'Membership Admin',
    value: 'membership-admin',
  },
  {
    label: 'Event Ic',
    value: 'event-ic',
  },
  {
    label: 'Accountant',
    value: 'accountant',
  },
];

export const INPUT_MAX_LENGTH_50 = 50;
export const INPUT_MAX_LENGTH_1000 = 1000;

export const BLOCKING_REASON = [
  { label: 'User passed away', value: 'User passed away' },
  {
    label: 'User violate privacy policy and  terms of service',
    value: 'User violate privacy policy and  terms of service',
  },
  {
    label: 'Email bounced',
    value: 'Email bounced',
  },
  {
    label: 'Youth membership Expired',
    value: 'Youth membership Expired',
  },
];

export const RESET_PASSWORD = {
  sentEmail: 'sent-email',
  tokenExpired: 'token-expired',
  resetPassword: 'reset-password',
};
