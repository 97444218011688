/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';

import { Box, Dialog, RadioGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useFormik } from 'formik';
import _ from 'lodash';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import { NASSRadio } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS, DATE_PICKER_DISPLAY_FORMAT, PLACEHOLDER_DATE_PICKER } from 'src/constants/common';
import { EventCategory } from 'src/constants/events.constant';
import { EVENT_VALIDATION_MESSAGE } from 'src/constants/validation-message.constant';
import CloseIcon from 'src/icons/close';
import CalenderURL from 'src/medias/icons/calendar-icon.svg';
import ClockURL from 'src/medias/icons/clock-icon.svg';
import {
  FormMenuItem,
  FormTextField,
  GeneralInformationDatePicker,
  GeneralInformationTimePicker,
} from 'src/pages/profile/components/general-information.styled';
import { FormControlLabelStyled, Label } from 'src/pages/user-management/shared/user-detail.account-settings';
import { createEventDraft } from 'src/services/events';
import { eventActions } from 'src/store/events/slices';
import { useAppDispatch } from 'src/store/hooks';
import useResponsive from 'src/utils/responsive';

import {
  Action,
  Cancel,
  Create,
  CreateEventDialogContainer,
  FormStyled,
  Header,
  Icon,
  Title as TopTitle,
} from './index.styled';

interface ICreateEventDialogProps {
  open: boolean;
  onClose: () => void;
}
const useStyles = makeStyles(() => ({
  paper: {
    width: 960,
    borderRadius: 12,
    height: '100%',
    maxWidth: '100%',
  },
}));

const CreateEventDialog = ({ open, onClose }: ICreateEventDialogProps) => {
  const { paper } = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const INPUT_MAX_LENGTH_128 = 128;
  const DATE_FORMAT = 'YYYY/MM/DD';
  const TIME_FORMAT = 'HH:mm:ss';
  const GTYK_PARTY = 'gtky-party';

  const responsive = useResponsive();

  function handleOnClose() {
    formik.resetForm();
    onClose();
  }
  const handleDateChange = (direction: 'start_date' | 'end_date') => {
    return function (date: any, _: string | null | undefined) {
      if (date) {
        formik.setFieldValue(direction, date);
      }
    };
  };

  const formik = useFormik({
    initialValues: {
      title: '',
      start_date: '',
      end_date: '',
      start_time: '',
      end_time: '',
      category: 'default',
      event_type: 'location-based',
      is_public: 'yes',
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required(EVENT_VALIDATION_MESSAGE.EVENT_NAME_REQUIRED)
        .test('EVENT_NAME_REQUIRED', EVENT_VALIDATION_MESSAGE.EVENT_NAME_REQUIRED, (value: any) => {
          return value?.trim()?.length;
        }),
      start_time: Yup.string().required(EVENT_VALIDATION_MESSAGE.START_TIME_REQUIRED),
      end_time: Yup.string().required(EVENT_VALIDATION_MESSAGE.END_TIME_REQUIRED),
      start_date: Yup.string().required(EVENT_VALIDATION_MESSAGE.START_DATE_REQUIRED),
      end_date: Yup.string().required(EVENT_VALIDATION_MESSAGE.END_DATE_REQUIRED),
      category: Yup.string()
        .notOneOf(['default'], EVENT_VALIDATION_MESSAGE.CATEGORY_REQUIRED)
        .required(EVENT_VALIDATION_MESSAGE.CATEGORY_REQUIRED),
    }),
    onSubmit: (values) => {
      const start_time_string =
        moment(values.start_date).format(DATE_FORMAT) + ' ' + moment(values.start_time).format(TIME_FORMAT);
      const end_time_string =
        moment(values.end_date).format(DATE_FORMAT) + ' ' + moment(values.end_time).format(TIME_FORMAT);

      const formData = {
        ...values,
        is_public: values.is_public === 'yes' ? true : false,
        start_time: start_time_string,
        end_time: end_time_string,
        title: values.title.trim(),
      };

      const payload = _.omit(formData, ['start_date', 'end_date']);

      (async () => {
        setLoading(true);
        const response = await createEventDraft(payload);
        if (response?.statusCode === CALL_SUCCESS) {
          dispatch(eventActions.setCurrentEventId(response.data?.id!));
          setShowSnackBarSuccess(true);
          setMessage(response.message);
          setTimeout(() => {
            history.push(`/event-management/${response.data?.id}/general`);
          }, 500);
          setLoading(false);
        } else {
          setShowSnackBarError(true);
          setMessage(response.message);
          setLoading(false);
        }
      })();
    },
  });

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };
  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.value === GTYK_PARTY) {
      formik.setFieldValue('is_public', 'no');
    }
    formik.setFieldValue('category', e.target.value);
  };

  return (
    <Dialog classes={{ paper }} open={open} onClose={onClose} disableBackdropClick>
      <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
      <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
      <FormStyled onSubmit={formik.handleSubmit} responsive={responsive}>
        <CreateEventDialogContainer>
          <Box>
            <Header>
              <TopTitle>Create Event</TopTitle>
              <Icon onClick={onClose}>
                <CloseIcon fill="white" />
              </Icon>
            </Header>
            <Box mb={3}>
              <Label required>Event name</Label>
              <FormTextField
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                fullWidth
                inputProps={{ maxLength: INPUT_MAX_LENGTH_128 }}
                error={formik.touched.title && !!formik.errors.title}
                helperText={formik.touched.title && formik.errors.title}
              />
            </Box>
            <Box display="flex" style={{ gap: 36 }}>
              <Box flex="50%">
                <Label required>Start Date</Label>
              </Box>
              <Box flex="50%">
                <Label required>End Date</Label>
              </Box>
            </Box>
            <Box mb={3} display="flex" style={{ gap: 24 }}>
              <Box width="100%" display="flex" style={{ gap: 12, alignItems: 'flex-start' }}>
                <Box flex="25%">
                  <GeneralInformationDatePicker
                    name="start_date"
                    fullWidth
                    inputVariant="outlined"
                    format={DATE_PICKER_DISPLAY_FORMAT}
                    value={formik.values.start_date}
                    InputAdornmentProps={{ position: 'end' }}
                    onChange={handleDateChange('start_date')}
                    invalidDateMessage=""
                    placeholder={PLACEHOLDER_DATE_PICKER}
                    InputProps={{ readOnly: true }}
                    error={formik.touched.start_date && !!formik.errors.start_date}
                    helperText={formik.touched.start_date && formik.errors.start_date}
                    keyboardIcon={<img src={CalenderURL} alt="Calender" />}
                    minDate={moment()}
                    maxDate={formik.values.end_date || undefined}
                    variant="inline"
                    autoOk
                  />
                </Box>
                <Box flex="25%">
                  <GeneralInformationTimePicker
                    keyboardIcon={<img src={ClockURL} alt="ClockURL" />}
                    name="start_time"
                    fullWidth
                    inputVariant="outlined"
                    value={formik.values.start_time}
                    InputAdornmentProps={{ position: 'end' }}
                    onChange={(e) => formik.setFieldValue('start_time', e)}
                    invalidDateMessage=""
                    InputProps={{ readOnly: true }}
                    mask="__:__ _M"
                    error={formik.touched.start_time && !!formik.errors.start_time}
                    helperText={formik.touched.start_time && formik.errors.start_time}
                    variant="inline"
                    autoOk
                  />
                </Box>
                <Box mx={0.5} />
                <Box flex="25%">
                  <GeneralInformationDatePicker
                    name="end_date"
                    fullWidth
                    inputVariant="outlined"
                    format={DATE_PICKER_DISPLAY_FORMAT}
                    value={formik.values.end_date}
                    InputAdornmentProps={{ position: 'end' }}
                    onChange={handleDateChange('end_date')}
                    invalidDateMessage=""
                    placeholder={PLACEHOLDER_DATE_PICKER}
                    InputProps={{ readOnly: true }}
                    minDate={formik.values.start_date || moment()}
                    error={formik.touched.end_date && !!formik.errors.end_date}
                    helperText={formik.touched.end_date && formik.errors.end_date}
                    keyboardIcon={<img src={CalenderURL} alt="Calender" />}
                    variant="inline"
                    autoOk
                  />
                </Box>
                <Box flex="25%">
                  <GeneralInformationTimePicker
                    name="end_time"
                    fullWidth
                    inputVariant="outlined"
                    value={formik.values.end_time}
                    InputAdornmentProps={{ position: 'end' }}
                    onChange={(e) => formik.setFieldValue('end_time', e)}
                    invalidDateMessage=""
                    mask="__:__ _M"
                    InputProps={{ readOnly: true }}
                    error={formik.touched.end_time && !!formik.errors.end_time}
                    helperText={formik.touched.end_time && formik.errors.end_time}
                    keyboardIcon={<img src={ClockURL} alt="ClockURL" />}
                    variant="inline"
                    autoOk
                  />
                </Box>
              </Box>
            </Box>
            <Box mb={3}>
              <Label required>Category</Label>
              <FormTextField
                variant="outlined"
                name="category"
                fullWidth
                size="small"
                value={formik.values.category}
                onChange={handleCategoryChange}
                select
                SelectProps={{
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  },
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.category && !!formik.errors.category}
                helperText={formik.touched.category && formik.errors.category}
              >
                <FormMenuItem value="default">All Category</FormMenuItem>
                {EventCategory.map(({ value, label }, idx) => (
                  <FormMenuItem key={idx} value={value}>
                    {label}
                  </FormMenuItem>
                ))}
              </FormTextField>
            </Box>
            <Box mb={3}>
              <Label required>Event Type</Label>
              <RadioGroup
                name="event_type"
                row
                defaultValue={formik.values.event_type}
                onChange={(e, v) => formik.setFieldValue(e.target.name, v)}
              >
                <FormControlLabelStyled value="location-based" control={<NASSRadio />} label="Location Based" />
                <Box width={100} />
                <FormControlLabelStyled value="online-event" control={<NASSRadio />} label="Online Event" />
              </RadioGroup>
            </Box>
            <Box mb={3}>
              <Label>Does This Event Open For Public</Label>
              <RadioGroup
                name="is_public"
                row
                value={formik.values.is_public}
                onChange={(e, v) => formik.setFieldValue(e.target.name, v)}
              >
                <FormControlLabelStyled
                  value="yes"
                  control={<NASSRadio />}
                  disabled={formik.values.category === GTYK_PARTY}
                  label="Yes"
                />
                <Box width={100} />
                <FormControlLabelStyled
                  value="no"
                  control={<NASSRadio />}
                  label="No"
                  disabled={formik.values.category === GTYK_PARTY}
                />
              </RadioGroup>
            </Box>
          </Box>
          <Action>
            <Cancel onClick={handleOnClose}>Cancel</Cancel>
            <Create type="submit" disabled={loading}>
              Create
            </Create>
          </Action>
        </CreateEventDialogContainer>
      </FormStyled>
    </Dialog>
  );
};

export default CreateEventDialog;
