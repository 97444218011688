import React, { useEffect, useState } from 'react';

import { Box, Divider, Grid, IconButton, InputAdornment, Link, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { InputField } from 'src/components';
import { CustomButton } from 'src/components/button';
import { CALL_SUCCESS } from 'src/constants/common';
import { RESET_PASSWORD } from 'src/constants/users.constants';
import LogoJSA from 'src/medias/images/main-nsaa-logo.png';
import SignUpBackground from 'src/medias/images/signup-background.jpg';
import { forgotPassword } from 'src/services/auth';
import { selectLoginState } from 'src/store/authentication/selector';
import useResponsive from 'src/utils/responsive';

import ResetPasswordSuccess from '../ResetPasswordSuccess';

import ForgotPasswordMobile from './ForgotPasswordMobile';

const MAX_LENGTH_EMAIL = 50;
const useStyles = makeStyles((theme) => ({
  left: {
    minHeight: 966,
    position: 'relative',
  },
}));
const Logo = styled.img(({ theme }) => ({
  position: 'absolute',
  top: 54,
  left: 80,
  width: 70,
  height: 90,
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: 30,
  ...theme.fonts.header4S,
  color: theme.palette.yellow?.[900],
}));

const Form = styled.form(({ theme }) => ({
  width: '60%',
  color: 'white',
  paddingTop: 150,
}));

const Note = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textSs,
  marginBottom: '10px',
  color: 'black',
}));
const NoteBox = styled(Box)(({ theme }) => ({
  ...theme.fonts.textSs,
  margin: '30px 0',
}));
const ForgotPasswordBtn = styled(CustomButton)`
  ${({ theme, disable }) => css`
    min-width: fit-content;
    &&:hover {
      background-color: #c5be8b;
    }
  `}
`;
const DontRememberBtn = styled(ForgotPasswordBtn)`
  ${({ theme }) => css`
    && {
      border-radius: 5px;
      background-color: rgb(0, 0, 9);
      color: white;
      border: 1px solid white;
      width: 100%;
    }
  `}
`;
const ResetBtn = styled(ForgotPasswordBtn)<{ disable?: boolean }>`
  ${({ disable }) => css`
    && {
      border-radius: 5px;

      background-color: rgb(0, 0, 9);
      color: white;
      border: 1px solid white;
      width: 35%;
      padding: 10px;
      pointer-events: ${disable ? 'none' : 'all'};
    }
  `}
`;
const CloseIconCustom = styled(CloseIcon)<{ isError?: boolean }>`
  ${({ theme, isError }) => css`
    color: ${isError ? theme.palette.red?.[600] : theme.palette.common.black};
  `}
`;
const NavLinkCustom = styled(NavLink)(({ theme }) => ({
  ...theme.fonts.header3,
  textDecoration: 'none',
  color: '#000000',
  width: '35%',
  minWidth: 'fit-content',
}));
export const NYSImage = styled.img<{ objectFitContain?: boolean }>(({ theme, objectFitContain }) => ({
  width: '100%',
  minHeight: '100vh',
  ...(objectFitContain
    ? {
        objectFit: 'contain',
      }
    : {}),
}));
export const BoxPosition = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '20%',
  top: '40%',
}));
export const TitleBoxPosition = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
  color: 'white',
  fontSize: 22,
}));
export const DesBoxPosition = styled(Typography)(({ theme }) => ({
  ...theme.fonts.textS,
  color: 'white',
  textAlign: 'center',
  marginTop: 16,
  lineHeight: 2,
}));
const InputFieldStyled = styled(InputField)<{ isError?: boolean }>`
  ${({ theme, isError }) => css`
    & input {
      color: ${isError ? theme.palette.red?.[600] : theme.palette.common.black};
      font-size: ${theme.fonts.textS.fontSize};
      font-weight: ${theme.fonts.textS.fontWeight};
      line-height: ${theme.fonts.textS.lineHeight};
    }
  `}
`;
export const ForgotPassword = () => {
  const classes = useStyles();
  const isLogin = useSelector(selectLoginState);
  const responsive = useResponsive();
  const [data, setData] = useState<{ email: string }>({ email: '' });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSentEmail, setIsSentEmai] = useState<boolean>(false);
  const [onClicking, setOnClicking] = useState<boolean>(false);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setErrorMessage('');
    setData({ ...data, [e.target.name]: e.target.value });
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    setOnClicking(true);
    setErrorMessage('');
    e.preventDefault();
    if (!data.email) {
      setOnClicking(false);
      setErrorMessage('This field is required');
      return;
    }
    const response = await forgotPassword(data.email);
    if (response?.statusCode === CALL_SUCCESS) {
      setIsSentEmai(true);
      setOnClicking(false);
    } else {
      setOnClicking(false);
      if (response?.message === 'User not found') {
        setErrorMessage(
          'This email address does not exist in our system. Please try submitting a different email address',
        );
      } else {
        setErrorMessage('Please fill in a correct email');
      }
    }
  }
  function handleClose() {
    setErrorMessage('');
    setData({ ...data, email: '' });
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    if (isLogin) {
      window.location.href = '/login';
    }
  }, [isLogin]);
  if (!isSentEmail)
    return responsive.isTabletOrMobile ? (
      <ForgotPasswordMobile />
    ) : (
      <Grid container>
        <Grid item xs={6} className={classes.left}>
          <Box display="flex" width={'100%'} height={'100%'}>
            <NYSImage src={SignUpBackground} objectFitContain />
            <Link href="/">
              <Logo src={LogoJSA} />
            </Link>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            width={'100%'}
            height={'100%'}
            justifyContent="center"
            display="flex"
            alignItems="center"
            bgcolor="#f9f9f9"
          >
            <Form onSubmit={handleSubmit}>
              <Title>Forgot Your Password?</Title>
              <NoteBox>
                <Note>
                  Don't worry, it happens. We'll send you a link by email to reset your password. Please enter your
                  email address.
                </Note>
                <Note>Noted: You’ll only receive email if the email address is valid and file in NSAA database</Note>
              </NoteBox>
              <Box position="relative">
                <InputFieldStyled
                  lableStyle={{ marginBottom: '10px' }}
                  error={errorMessage ? true : false}
                  title="Email Address"
                  hiddenLabel
                  fullWidth
                  type="text"
                  value={data.email}
                  name="email"
                  onChange={handleChange}
                  autoComplete="off"
                  errormessage={errorMessage}
                  InputProps={{
                    inputProps: {
                      maxLength: MAX_LENGTH_EMAIL,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClose} edge="end">
                          {data.email !== '' && <CloseIconCustom isError={errorMessage ? true : false} />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box mt={4} mb={4} display="flex" justifyContent="space-between">
                <NavLinkCustom to="/forgot-email">
                  <DontRememberBtn>I DON’T REMEMBER</DontRememberBtn>
                </NavLinkCustom>
                <ResetBtn type="submit" disable={onClicking}>
                  RESET PASSWORD
                </ResetBtn>
              </Box>
              <Divider />
            </Form>
          </Box>
        </Grid>
      </Grid>
    );
  return <ResetPasswordSuccess message={RESET_PASSWORD.sentEmail} />;
};
