import React from 'react';

import { Box } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import ProfileTabs from 'src/pages/profile/shared/profile.tabs';
import { Container } from 'src/pages/user-management/types';
import { IEvent } from 'src/services/events';
import { eventActions } from 'src/store/events/slices';
import { useAppDispatch } from 'src/store/hooks';

import CheckInCheckOut from './components/check-in-check-out/';
import ConfirmList from './components/confirm-list';
import OverView from './components/overview';
import Waitlist from './components/wait-list';

interface IVolunteerProps {
  event: IEvent;
  setEvent: React.Dispatch<React.SetStateAction<IEvent | undefined>>;
}

export const Volunteer = ({ event, setEvent }: IVolunteerProps) => {
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  useScrollToTop();

  React.useEffect(() => {
    id && dispatch(eventActions.setCurrentEventId(id));
  }, [dispatch, id]);

  let tabs = [
    { label: 'Overview', disabled: false },
    { label: 'Confirmed List', disabled: false },
    { label: 'Waitlist', disabled: false },
    { label: 'Check-in/Check-out', disabled: false },
  ];
  const panels = [
    <OverView event={event} setEvent={setEvent} />,
    <ConfirmList eventId={id} event={event} />,
    <Waitlist eventId={id} />,
    <CheckInCheckOut eventId={id} event={event} />,
  ];

  if (event?.status === 'draft' || !event?.require_volunteer) {
    tabs = [
      { label: 'Overview', disabled: false },
      { label: 'Confirmed List', disabled: true },
      { label: 'Waitlist', disabled: true },
      { label: 'Check-in/Check-out', disabled: true },
    ];
  }

  return (
    <Box>
      <Container minHeight={500} p={4} bgcolor="white">
        <ProfileTabs panels={panels} tabs={tabs} />
      </Container>
    </Box>
  );
};

export default Volunteer;
