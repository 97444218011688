import React, { useEffect, useState } from 'react';

import { Box, FormControl, FormControlLabel, MenuItem, RadioGroup } from '@material-ui/core';
import moment from 'moment';

import { CustomDatePicker, CustomInputSelect, InputField, NASSRadio, CustomTimePicker } from 'src/components';
import CustomFormDialog from 'src/components/formDialog';
import { FORMAT_TIME_AM_PM } from 'src/constants/common';
import ResetIcon from 'src/medias/icons/reset-icon.svg';
import { ErrorMessage } from 'src/pages/signup/components/BasicInformation';
import { CustomLabel } from 'src/pages/signup/components/types';
import { IconButtonStyled, ImageIconStyled } from 'src/pages/user-management/types';
import { IEvent } from 'src/services/events';
import { IVolunteer } from 'src/services/volunteer';

import { attendanceStatus, volunteerStatus } from '../../types';

export const TEXT_REQUIRED = 'This field is required';
const TEXT_CHECK_IN_ERROR = 'Check-out time must be later than check-in time.';
const TEXT_CHECK_OUT_ERROR = 'Check-out time must be later than check-in time.';
const TEXT_STATUS_ERROR = '';
const TEXT_STATUS_INCOMPLETE_ERROR =
  'If there is only check-in time or check-out time, volunteer status must be Incomplete';

interface IUpdateVolunteerModal {
  _onCloseModal: () => void;
  open: boolean;
  _onSubmit?: () => void;
  userSelected: IVolunteer | undefined;
  loading: boolean | undefined;
  setUpdateVolunteerInfo: React.Dispatch<
    React.SetStateAction<
      | {
          scanTicket: string;
          attendanceStatus: string;
          checkinTime: null | string | Date;
          checkoutTime: null | string | Date;
        }
      | undefined
    >
  >;
  event?: IEvent | undefined | null;
}
const UpdateVolunteerModal = ({
  loading,
  _onCloseModal,
  open,
  _onSubmit,
  userSelected,
  setUpdateVolunteerInfo,
  event,
}: IUpdateVolunteerModal) => {
  const [errors, setErrors] = useState({
    checkIn: false,
    checkOut: false,
    status: false,
  });

  const [data, setData] = useState<{
    scanTicket: string;
    attendanceStatus: string;
    checkinTime: null | string | Date;
    checkoutTime: null | string | Date;
  }>({
    scanTicket: '',
    attendanceStatus: '',
    checkinTime: null,
    checkoutTime: null,
  });

  let checkInTimeToCompare = new Date();
  let checkOutTimeToCompare = new Date();

  if (data.checkinTime) {
    checkInTimeToCompare = new Date(data.checkinTime);
  }
  if (data.checkoutTime) {
    checkOutTimeToCompare = new Date(data.checkoutTime);
  }

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.name === 'attendanceStatus') {
      if (e.target.value === attendanceStatus.pending || e.target.value === attendanceStatus.confirmed) {
        setData({ ...data, [e.target.name]: e.target.value, checkinTime: null, checkoutTime: null });
      } else {
        setData({ ...data, [e.target.name]: e.target.value });
      }

      if (e.target.value === attendanceStatus.complete) {
        if (data.checkinTime && data.checkoutTime) {
          setErrors({ ...errors, status: false });
        } else {
          setErrors({ ...errors, status: true });
        }
      }

      if (e.target.value === attendanceStatus.incomplete) {
        if (data.checkinTime && data.checkoutTime === null) {
          setErrors({ ...errors, status: false });
        } else if (data.checkinTime == null && data.checkoutTime) {
          setErrors({ ...errors, status: false });
        } else {
          setErrors({ ...errors, status: true });
        }
      }
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
    }
  }

  const handleCheckInChange = (date: Date | null) => {
    if (date) {
      const currentTime = new Date();
      if (currentTime.getTime() >= date.getTime()) {
        if (data.checkoutTime === null) {
          setData({ ...data, checkinTime: date, attendanceStatus: attendanceStatus.incomplete });
          setErrors({ ...errors, checkOut: false, status: false, checkIn: false });
        } else {
          setData({ ...data, checkinTime: date, attendanceStatus: attendanceStatus.complete });
        }
      }
    }
  };

  const handleCheckOutChange = (date: Date | null) => {
    if (date) {
      const currentTime = new Date();
      if (currentTime.getTime() >= date.getTime()) {
        if (data.checkinTime === null) {
          setData({ ...data, checkoutTime: date, attendanceStatus: attendanceStatus.incomplete });
          setErrors({ ...errors, checkIn: false, status: false, checkOut: false });
        } else {
          setData({ ...data, checkoutTime: date, attendanceStatus: attendanceStatus.complete });
        }
      }
    }
  };

  const handleSubmit = () => {
    let validate = errors;
    let error = false;

    if (data.checkinTime && data.checkoutTime === null) {
      if (data.attendanceStatus !== attendanceStatus.incomplete) {
        error = true;
        validate.status = true;
        setErrors({ ...errors, status: true });
      }
    }

    if (data.checkoutTime && data.checkinTime === null) {
      if (data.attendanceStatus !== attendanceStatus.incomplete) {
        error = true;
        validate.status = true;
        setErrors({ ...errors, status: true });
      }
    }

    if (data.attendanceStatus === attendanceStatus.complete && data.checkinTime === null) {
      validate.checkIn = true;
      setErrors({ ...errors, checkIn: true });
      error = true;
    }

    if (data.attendanceStatus === attendanceStatus.complete && data.checkoutTime === null) {
      validate.checkOut = true;
      setErrors({ ...errors, checkOut: true });
      error = true;
    }

    if (data.checkinTime && data.checkoutTime) {
      if (data.attendanceStatus !== attendanceStatus.complete) {
        validate.status = true;
        setErrors({ ...errors, status: true });
        error = true;
      } else {
        validate.status = false;
        setErrors({ ...errors, status: false });
        error = false;
      }

      if (checkOutTimeToCompare.getTime() <= checkInTimeToCompare.getTime()) {
        error = true;
        validate.checkIn = true;
        validate.checkOut = true;
        setErrors({ ...errors, checkIn: true, checkOut: true });
      } else {
        error = false;
        validate.checkIn = false;
        validate.checkOut = false;
        setErrors({ ...errors, checkIn: false, checkOut: false });
      }
    }

    if (data.attendanceStatus === attendanceStatus.incomplete) {
      if (data.checkinTime === null && data.checkoutTime === null) {
        error = true;
        validate.checkIn = true;
        validate.checkOut = true;
        setErrors({ ...errors, checkIn: true, checkOut: true });
      }
      if (data.checkinTime && data.checkoutTime) {
        error = true;
        validate.status = true;
        setErrors({ ...errors, status: true });
      }
    }

    if (!error) {
      setErrors({ ...errors, checkOut: false, checkIn: false, status: false });
      setUpdateVolunteerInfo?.(data);
      _onSubmit?.();
    }
  };

  const handleClose = () => {
    _onCloseModal?.();
    setErrors({ ...errors, checkIn: false, checkOut: false, status: false });
  };

  const _onResetCheckIn = () => {
    setData({ ...data, checkinTime: null });
    setErrors({ ...errors, checkIn: false });
    if (data.attendanceStatus === attendanceStatus.incomplete) {
      if (data.checkoutTime) {
        setErrors({ ...errors, status: false });
      } else {
        setErrors({ ...errors, status: true });
      }
    }
  };

  const _onResetCheckout = () => {
    setData({ ...data, checkoutTime: null });
    setErrors({ ...errors, checkOut: false });
    if (data.attendanceStatus === attendanceStatus.incomplete) {
      if (data.checkinTime) {
        setErrors({ ...errors, status: false });
      } else {
        setErrors({ ...errors, status: true });
      }
    }
  };

  useEffect(() => {
    if (userSelected) {
      setData({
        scanTicket: userSelected.scanTicket,
        attendanceStatus: userSelected.attendanceStatus,
        checkinTime: userSelected.checkinTime,
        checkoutTime: userSelected.checkoutTime,
      });
    }
  }, [userSelected]);

  let status = volunteerStatus;

  return (
    <CustomFormDialog
      title="Update Volunteer Info"
      open={open}
      _onCloseModal={handleClose}
      submitText="Save"
      _onSubmit={handleSubmit}
      disabled={loading}
      modalStyles={{
        marginLeft: 0,
        marginRight: 0,
      }}
      isConfirmPopup={true}
    >
      <Box display="flex" flex={1} mb={2}>
        <Box width={'50%'}>
          {event && <InputField title="Event" margin="dense" fullWidth disabled value={`${event?.title}`} />}
          <InputField
            title="Volunteer"
            margin="dense"
            fullWidth
            disabled
            value={`${userSelected?.user.surname}, ${userSelected?.user.givenName}`}
          />
          <InputField
            title="Registration Time"
            margin="dense"
            fullWidth
            disabled
            value={`${
              userSelected?.registrationTime ? moment(+userSelected.registrationTime).format(FORMAT_TIME_AM_PM) : ''
            }`}
          />
          <Box flex={1} mt={4} mb={1}>
            <CustomLabel>Access to e-ticket checking?</CustomLabel>
          </Box>
          <Box>
            <FormControl component="fieldset">
              <RadioGroup value={data.scanTicket} onChange={_handleChange} name="scanTicket" row>
                <FormControlLabel value="yes" control={<NASSRadio />} label="Yes" />
                <Box width={'250px'} />
                <FormControlLabel value="no" control={<NASSRadio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box width={'50%'} ml={3}>
          <CustomInputSelect
            title="Status"
            name="attendanceStatus"
            value={data.attendanceStatus}
            onChange={_handleChange}
            margin="dense"
            fullWidth
            notRequired
            error={errors.status}
            errormessage={
              data.checkinTime && data.checkoutTime === null
                ? TEXT_STATUS_INCOMPLETE_ERROR
                : data.checkoutTime && data.checkinTime === null
                ? TEXT_STATUS_INCOMPLETE_ERROR
                : TEXT_STATUS_ERROR
            }
          >
            <MenuItem disabled style={{ display: 'none' }} key="none" value="none">
              Choose a status
            </MenuItem>
            {status.map((status) => (
              <MenuItem key={status.key} value={status.key}>
                {status.value}
              </MenuItem>
            ))}
          </CustomInputSelect>
          <Box flex={1} display="flex" mt={3} style={{ gap: 12 }} justifyContent="space-between">
            <Box display="flex" flex={1}>
              <FormControl fullWidth>
                <CustomLabel display="inline">Check-in</CustomLabel>
                <CustomDatePicker
                  disabled={
                    data.attendanceStatus === attendanceStatus.pending ||
                    data.attendanceStatus === attendanceStatus.confirmed
                  }
                  isError={errors.checkIn}
                  onChange={handleCheckInChange}
                  value={data.checkinTime ? new Date(data.checkinTime) : null}
                  name="checkInDate"
                  maxDate={
                    data.checkoutTime ? moment(data.checkoutTime).subtract(0, 'years') : moment().subtract(0, 'years')
                  }
                />
              </FormControl>
            </Box>
            <Box display="flex" flex={1} mt={'26px'}>
              <CustomTimePicker
                disabled={
                  data.attendanceStatus === attendanceStatus.pending ||
                  data.attendanceStatus === attendanceStatus.confirmed
                }
                isError={errors.checkIn}
                onChange={handleCheckInChange}
                value={data.checkinTime ? new Date(data.checkinTime) : null}
                name="checkInTime"
              />
            </Box>
            <Box>
              <IconButtonStyled
                disabled={
                  data.attendanceStatus === attendanceStatus.pending ||
                  data.attendanceStatus === attendanceStatus.confirmed
                }
                style={{ marginTop: 32 }}
                onClick={_onResetCheckIn}
              >
                <ImageIconStyled src={ResetIcon} />
              </IconButtonStyled>
            </Box>
          </Box>
          {errors.checkIn && (
            <ErrorMessage isError={errors.checkIn}>
              {data.checkinTime === null ? TEXT_REQUIRED : TEXT_CHECK_IN_ERROR}
            </ErrorMessage>
          )}

          <Box flex={1} display="flex" mt={3} style={{ gap: 12 }} justifyContent="space-between">
            <Box display="flex" flex={1}>
              <FormControl fullWidth>
                <CustomLabel display="inline">Check-out</CustomLabel>
                <CustomDatePicker
                  disabled={
                    data.attendanceStatus === attendanceStatus.pending ||
                    data.attendanceStatus === attendanceStatus.confirmed
                  }
                  isError={errors.checkOut}
                  onChange={handleCheckOutChange}
                  value={data.checkoutTime ? new Date(data.checkoutTime) : null}
                  name="checkOutDate"
                  maxDate={moment().subtract(0, 'years')}
                  minDate={moment(data?.checkinTime).subtract(0, 'years')}
                />
              </FormControl>
            </Box>
            <Box display="flex" flex={1} mt={'26px'}>
              <CustomTimePicker
                disabled={
                  data.attendanceStatus === attendanceStatus.pending ||
                  data.attendanceStatus === attendanceStatus.confirmed
                }
                isError={errors.checkOut}
                onChange={handleCheckOutChange}
                value={data.checkoutTime ? new Date(data.checkoutTime) : null}
                name="checkOutTime"
              />
            </Box>
            <Box>
              <IconButtonStyled
                disabled={
                  data.attendanceStatus === attendanceStatus.pending ||
                  data.attendanceStatus === attendanceStatus.confirmed
                }
                style={{ marginTop: 32 }}
                onClick={_onResetCheckout}
              >
                <ImageIconStyled src={ResetIcon} />
              </IconButtonStyled>
            </Box>
          </Box>
          {errors.checkOut && (
            <ErrorMessage isError={errors.checkOut}>
              {data.checkoutTime === null ? TEXT_REQUIRED : TEXT_CHECK_OUT_ERROR}
            </ErrorMessage>
          )}
        </Box>
      </Box>
    </CustomFormDialog>
  );
};

export default UpdateVolunteerModal;
