export const youthMembershipFAQ = [
  {
    type: 'h1',
    children: [
      {
        bold: true,
        text: 'Youth Membership FAQ',
        underline: true,
      },
    ],
  },
  {
    id: 1642691767634,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746113,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '1. What is the NSAA’s Youth Membership?',
      },
    ],
  },
  {
    id: 1642691858143,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746122,
    type: 'p',
    children: [
      {
        text: 'The Youth Membership was established on 25 May 2013 during the NSAA’s Extraordinary General Meeting (EGM). The main objectives of the Youth Membership are to provide a platform for young students and graduates of Nanyang Kindergarten (NYK), Nanyang Primary School (NYPS) and Nanyang Girls’ High School (NYGH) to foster close ties with their alma mater, and to build camaraderie among the alumni from the Nanyang Family of Schools.',
      },
    ],
  },
  {
    id: 1642691746161,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746166,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '2. What is the membership approval process for joining as a Youth Member?',
      },
    ],
  },
  {
    id: 1642691860333,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746172,
    type: 'p',
    children: [
      {
        text: 'The process is as follows:',
      },
    ],
  },
  {
    id: 1642691842729,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746177,
    type: 'p',
    children: [
      {
        text: '    i. Attend our Get-To-Know-You (GTKY) Session, which is held 4 times a year. Your documents will be verified at the session.',
      },
    ],
  },
  {
    id: 1642691746181,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746184,
    type: 'p',
    children: [
      {
        text: '    ii. Applicants below the age of 21 will need to submit the parental consent form.',
      },
    ],
  },
  {
    id: 1642691746186,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746188,
    type: 'p',
    children: [
      {
        text: '    iii. You will be accepted as a member once your application is verified.',
      },
    ],
  },
  {
    id: 1642691746190,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746194,
    type: 'p',
    children: [
      {
        text: 'If you had previously signed up for the NSAA Youth Membership through the schools, you are not required to reapply again.',
      },
    ],
  },
  {
    id: 1642691746200,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746202,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '3. What are the charges to join as a Youth Member?',
      },
    ],
  },
  {
    id: 1642691862421,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746206,
    type: 'p',
    children: [
      {
        text: 'It is free of charge until 31 December of the year you attain the age of 25 when your Youth Membership is terminated, or when you become a Life Member.',
      },
    ],
  },
  {
    id: 1642691746210,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746212,
    type: 'p',
    children: [
      {
        text: 'You may convert to Life Membership at any time after the age of 21, by paying the entrance and subscription fees.',
      },
    ],
  },
  {
    id: 1642691746217,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746220,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '4. What are the criteria to join as a Youth Member?',
      },
    ],
  },
  {
    id: 1642691870981,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746222,
    type: 'p',
    children: [
      {
        text: 'You are below the age of 25, and must be a former student from NYK, NYPS or NYGH. If you are below the age of 21, consent from your parents or guardian is required.',
      },
    ],
  },
  {
    id: 1642691746226,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746228,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '5. Is there a required minimum period of study in a Nanyang School to join as a Youth Member?',
      },
    ],
  },
  {
    id: 1642691923585,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746233,
    type: 'p',
    children: [
      {
        text: 'No.',
      },
    ],
  },
  {
    id: 1642691746236,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746238,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '6. How do I prove that I have studied in a Nanyang School?',
      },
    ],
  },
  {
    id: 1642691938789,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746240,
    type: 'p',
    children: [
      {
        text: 'You are required to show us your original documents during the GTKY Session. Examples of acceptable documents are certificates, result slips, report books and report cards.',
      },
    ],
  },
  {
    id: 1642691746244,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746246,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '7. Do I have voting rights to cast votes at NSAA’s Annual General Meetings (AGM)?',
      },
    ],
  },
  {
    id: 1642691960225,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746247,
    type: 'p',
    children: [
      {
        text: 'No. You will have voting rights after you convert to Life Membership.',
      },
    ],
  },
  {
    id: 1642691746250,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746251,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '8. As a Youth Member, which phase can I register in during the Primary 1 Registration to enter NYPS?',
      },
    ],
  },
  {
    id: 1642691976047,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746257,
    type: 'p',
    children: [
      {
        text: 'The NSAA Youth Membership is not accepted by the Ministry of Education (MOE) for Primary 1 Registration. Please refer to MOE’s website for the latest updates on the process of Primary 1 Registration.',
      },
    ],
  },
  {
    id: 1642691746273,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746275,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '9. Why should I join as a Youth Member?',
      },
    ],
  },
  {
    id: 1642692094417,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746276,
    type: 'p',
    children: [
      {
        text: 'i. You will be updated on the events organised by NSAA via regular e-Notices.',
      },
    ],
  },
  {
    id: 1642692088529,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746280,
    type: 'p',
    children: [
      {
        text: 'ii. You can participate in these events for all three Nanyang Schools, learn about event management and find out how we work with external organisations for our activities, some of which have become our long-time sponsors.',
      },
    ],
  },
  {
    id: 1642692081789,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746288,
    type: 'p',
    children: [
      {
        text: 'iii. You can broaden your network as you connect with alumni from different generations, industries and professions.',
      },
    ],
  },
  {
    id: 1642691746292,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746294,
    type: 'p',
    children: [
      {
        text: 'iv. At the events, you can reminisce with your ex-teachers, stay connected with your ex-classmates and share your experiences with your juniors.',
      },
    ],
  },
  {
    id: 1642691746298,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746299,
    type: 'p',
    children: [
      {
        text: 'v. You can also meet the retired Principals and teachers, and through the interesting stories from their past, learn how Nanyang has evolved over the decades.',
      },
    ],
  },
  {
    id: 1642691746303,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746305,
    type: 'p',
    children: [
      {
        text: 'vi. Your seniors will share how their journey in Nanyang has helped them in their career as working adults.',
      },
    ],
  },
  {
    id: 1642691746309,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746311,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '10. After becoming a Youth Member, I did not receive any email notifications of NSAA’s events and activities. Why is this so?',
      },
    ],
  },
  {
    id: 1642692122006,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746314,
    type: 'p',
    children: [
      {
        text: 'Please check your email’s junk or spam folder as the e-Notices may have been filtered there.',
      },
    ],
  },
  {
    id: 1642691746316,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691746318,
    type: 'p',
    children: [
      {
        text: 'You may also visit our NSAA website regularly for the latest updates.',
      },
    ],
  },
];

export const membershipInformation = [
  {
    type: 'h1',
    children: [
      {
        text: 'MEMBERSHIP INFORMATION',
        bold: true,
      },
    ],
    id: 1644937957157,
  },
  {
    type: 'h1',
    children: [
      {
        bold: true,
        text: 'Types of Membership',
        underline: true,
      },
    ],
  },
  {
    id: 1642691608722,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691531972,
    type: 'p',
    children: [
      {
        text: 'There are 2 categories of membership that are open for application: Life Membership and Youth Membership.',
      },
    ],
  },
  {
    id: 1642691531980,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691531982,
    type: 'h2',
    children: [
      {
        bold: true,
        text: 'Life Membership',
      },
    ],
  },
  {
    id: 1642691531984,
    type: 'p',
    children: [
      {
        text: 'All former students of the Nanyang Schools above the age of 21 are eligible for Life Membership.',
      },
    ],
  },
  {
    id: 1642691531988,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691531994,
    type: 'h2',
    children: [
      {
        bold: true,
        text: 'Youth Membership',
      },
    ],
  },
  {
    id: 1642691531998,
    type: 'p',
    children: [
      {
        text: 'All former students of the Nanyang Schools below the age of 25 are eligible for Youth Membership. For those below the age of 21 years, written consent from their parents or guardian is required. For Youth Members, membership will terminate on 31 December in the year they attain the age of 25, or when they become a life member.',
      },
    ],
  },
  {
    id: 1642691532010,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691532015,
    type: 'h1',
    children: [
      {
        bold: true,
        text: 'Membership Fees',
        underline: true,
      },
    ],
  },
  {
    id: 1642691610568,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691532019,
    type: 'p',
    children: [
      {
        text: 'Membership fees are used by the NSAA to fund most of our activities and annual donations to Nanyang Primary School (NYPS) and Nanyang Girls’ High School (NYGH). You may read more about our activities ',
      },
      {
        type: 'a',
        url: '/about-us/nsaa',
        children: [
          {
            text: 'here',
          },
        ],
        id: 1644938233348,
      },
      {
        text: '.',
      },
    ],
  },
  {
    id: 1642691532028,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691532030,
    type: 'p',
    children: [
      {
        text: 'For Life Membership, the entrance and subscription fees are SGD 205 for members at and above the age of 55, and SGD 1,000 for members below the age of 55.',
      },
    ],
  },
  {
    id: 1642691532034,
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
  },
  {
    id: 1642691532036,
    type: 'p',
    children: [
      {
        text: 'For Youth Membership, it is free of charge until 31 December of the year members attain the age of 25 when their Youth Membership is terminated, or when they become a Life Member.',
      },
    ],
  },
];

export const lifeMembershipFAQ = [
  {
    id: 1642689804613,
    type: 'h1',
    children: [
      {
        bold: true,
        text: 'Life Membership FAQ',
        color: '#000000',
        underline: true,
      },
    ],
  },
  {
    id: 1642689811206,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811208,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '1. What is the membership approval process for joining as a Life Member?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689936593,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811211,
    type: 'p',
    children: [
      {
        text: 'It will take about one year for your membership application to be approved. The process is as follows:',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689966670,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689911566,
    type: 'p',
    children: [
      {
        text: '    i. Attend our Get-To-Know-You (GTKY) Session, which is held 4 times a year. Your documents will be verified at the session.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811222,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811225,
    type: 'p',
    children: [
      {
        text: '   ii. Once your application is accepted, you will be informed to attend our Nanyang Values Talk and volunteer in at least 2 NSAA or school events (clocking about eight hours of volunteering time).',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811232,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811234,
    type: 'p',
    children: [
      {
        text: '    iii. After your completion of the talk and events, your application will be deliberated at our NSAA Committee meeting.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811237,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811239,
    type: 'p',
    children: [
      {
        text: '    iv. Once your application is approved, we will notify you to make payment of entrance and subscription fees of $1,000.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689986613,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811243,
    type: 'p',
    children: [
      {
        text: '  v. After we receive your payment and you are accepted as a member, you will be notified of the decision through a membership confirmation letter by post.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811247,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811249,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '2. What are the criteria to join as a Life Member?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690006508,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811251,
    type: 'p',
    children: [
      {
        text: 'You must be a former student from Nanyang Kindergarten (NYK), Nanyang Primary School (NYPS) or Nanyang Girls’ High School (NYGH).',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811255,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811256,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '3. Is there a required minimum period of study in a Nanyang School to join as a Life Member?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690030270,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811261,
    type: 'p',
    children: [
      {
        text: 'No.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811263,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811265,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '4. How do I prove that I have studied in a Nanyang School?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690048804,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811267,
    type: 'p',
    children: [
      {
        text: 'You are required to show us your original documents during the GTKY Session. Examples of acceptable documents are certificates, result slips, report books and report cards.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811270,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811272,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '5. I have misplaced the documents related to my studies in Nanyang Schools. What can I do?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690102867,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811274,
    type: 'p',
    children: [
      {
        text: 'You may retrieve a copy of your Statement of Results online. For more details, please ',
        color: '#000000',
      },
      {
        id: 1645780563520,
        url: 'https://www.seab.gov.sg/home/services/statements-of-results',
        type: 'a',
        children: [
          {
            text: 'click here',
            color: '#24abff',
          },
        ],
      },
      {
        text: '',
      },
    ],
  },
  {
    id: 1642689811277,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811280,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '6. After becoming a Life Member, I did not receive any email notifications of NSAA’s events and activities. Why is this so?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690132788,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811284,
    type: 'p',
    children: [
      {
        text: 'Please check your email’s junk or spam folder as the e-Notices may have been filtered there.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811285,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811287,
    type: 'p',
    children: [
      {
        text: 'You may also visit our NSAA website regularly for the latest updates.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811293,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811294,
    type: 'h1',
    children: [
      {
        bold: true,
        text: 'Admission to Nanyang Kindergarten (NYK)',
        color: '#000000',
        underline: true,
      },
    ],
  },
  {
    id: 1642690181324,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811296,
    type: 'p',
    children: [
      {
        text: 'For details on the process of NYK registration, please ',
        color: '#000000',
      },
      {
        id: 1645780584833,
        url: 'http://www.nanyangkindergarten.com/registration_procedure.html',
        type: 'a',
        children: [
          {
            text: 'click here.',
            color: '#24abff',
          },
        ],
      },
      {
        text: '',
      },
    ],
  },
  {
    id: 1642689811298,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811299,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '1. Do children of NSAA members have higher priority for admission to NYK?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690234173,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811302,
    type: 'p',
    children: [
      {
        text: 'Yes. As a member of NSAA, your child has priority over children of the public who have no affiliation with NYK.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811308,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811310,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '2. I am a former student of NYK. Does NSAA membership improve the chances of getting my child into NYK?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690251607,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811313,
    type: 'p',
    children: [
      {
        text: 'It depends on whether you graduated from NYK.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811315,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811317,
    type: 'p',
    children: [
      {
        text: 'If you graduated from NYK in K2, your child already has higher priority than children of NSAA Members.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811320,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811322,
    type: 'p',
    children: [
      {
        text: 'However, if you attended but did not graduate from NYK, your child will not have priority. With NSAA membership, your child will have higher priority over children of the public who have no affiliation with NYK.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811328,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811330,
    type: 'h1',
    children: [
      {
        bold: true,
        text: 'Admission to Nanyang Primary School (NYPS)',
        color: '#000000',
        underline: true,
      },
    ],
  },
  {
    id: 1642689811332,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811334,
    type: 'p',
    children: [
      {
        bold: true,
        text: '* IMPORTANT NOTICE',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811337,
    type: 'p',
    children: [
      {
        bold: true,
        text: 'The Ministry of Education (MOE) announced on 9 September 2021 that there will be changes to the P1 Registration Framework starting from the 2022 P1 Registration Exercise (for children starting P1 in 2023).',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811358,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811362,
    type: 'p',
    children: [
      {
        bold: true,
        text: 'For the latest updates on the process of P1 Registration, please refer to the MOE website.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811369,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811373,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '1. I am a former student of NYPS. Which phase can I register my child in during Primary 1 Registration to enter NYPS? Does NSAA membership improve the chances of getting my child into NYPS?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690331962,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811386,
    type: 'p',
    children: [
      {
        text: 'If you are a former student of NYPS, your child is eligible for Phase 2A. Children of NSAA members do not have priority over children of non members.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811396,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811400,
    type: 'p',
    children: [
      {
        text: 'As stated on MOE’s website, Phase 2A is for children whose parent or sibling is a former student of the primary school, including those who have joined the alumni association as a member.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811404,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811405,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '2. I have only studied in Nanyang Girls’ High School (NYGH). Can I register my child under Phase 2A?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690350893,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811409,
    type: 'p',
    children: [
      {
        text: 'It depends on when you studied in NYGH.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811411,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811412,
    type: 'p',
    children: [
      {
        text: 'NYPS was the primary section of NYGH before 1978.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811414,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811416,
    type: 'p',
    children: [
      {
        text: 'If you studied in NYGH in 1978 or earlier, you are considered as a former student of NYPS, so your child will be eligible to register under Phase 2A.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811419,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811421,
    type: 'p',
    children: [
      {
        text: 'If you studied in NYGH after 1978, your child is not eligible for Phase 2A.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811423,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811424,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '3. I have only studied in NYK or Nanyang Pre-Primary (NPP). Can I register my child under Phase 2A?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690365049,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811428,
    type: 'p',
    children: [
      {
        text: 'No. Only former students of NYPS can register their children under Phase 2A.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811430,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811432,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '4. If I am not a former student of NYPS, does NSAA membership improve the chances of getting my child into NYPS?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690380965,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811437,
    type: 'p',
    children: [
      {
        text: 'If you are a Member of NSAA, the NSAA Committee can recommend you to the Principal of NYPS for the Parent Volunteer (PV) interview.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1645801085653,
    type: 'p',
    children: [
      {
        color: '#000000',
        text: '',
      },
    ],
  },
  {
    id: 1642689811440,
    type: 'p',
    children: [
      {
        text: 'However, the school has the final and sole discretion in the selection of PVs.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811442,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811444,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '5. If I am a former student of NYPS, can NSAA recommend me to the Principal of NYPS for the selection of PV?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690392882,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811448,
    type: 'p',
    children: [
      {
        text: 'Based on the current selection criteria by NYPS, former students of NYPS are not eligible for the selection of PV.',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811451,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811453,
    type: 'h1',
    children: [
      {
        bold: true,
        text: 'Admission to Nanyang Girls’ High School (NYGH)',
        color: '#000000',
        underline: true,
      },
    ],
  },
  {
    id: 1642689811457,
    type: 'h2',
    children: [
      {
        bold: true,
        text: '1. As a Life Member, will my daughter have priority for admission to NYGH?',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642690465271,
    type: 'p',
    children: [
      {
        text: '',
        color: '#000000',
      },
    ],
  },
  {
    id: 1642689811459,
    type: 'p',
    children: [
      {
        text: 'No. Admission to all secondary schools is based on the child’s PSLE results or acceptance during the Direct School Admission exercise.',
        color: '#000000',
      },
    ],
  },
];

export const membershipApplicant = [
  {
    type: 'h1',
    children: [
      {
        bold: true,
        text: 'Membership Applicant',
        underline: true,
      },
    ],
    id: 1644939787054,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787054,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '1. What is the NSAA’s Youth Membership?',
      },
    ],
    id: 1644939787054,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787055,
  },
  {
    type: 'p',
    children: [
      {
        text: 'The Youth Membership was established on 25 May 2013 during the NSAA’s Extraordinary General Meeting (EGM). The main objectives of the Youth Membership are to provide a platform for young students and graduates of Nanyang Kindergarten (NYK), Nanyang Primary School (NYPS) and Nanyang Girls’ High School (NYGH) to foster close ties with their alma mater, and to build camaraderie among the alumni from the Nanyang Family of Schools.',
      },
    ],
    id: 1644939787055,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787055,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '2. What is the membership approval process for joining as a Youth Member?',
      },
    ],
    id: 1644939787056,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787056,
  },
  {
    type: 'p',
    children: [
      {
        text: 'The process is as follows:',
      },
    ],
    id: 1644939787056,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787057,
  },
  {
    type: 'p',
    children: [
      {
        text: '    i. Attend our Get-To-Know-You (GTKY) Session, which is held 4 times a year. Your documents will be verified at the session.',
      },
    ],
    id: 1644939787057,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787057,
  },
  {
    type: 'p',
    children: [
      {
        text: '    ii. Applicants below the age of 21 will need to submit the parental consent form.',
      },
    ],
    id: 1644939787058,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787058,
  },
  {
    type: 'p',
    children: [
      {
        text: '    iii. You will be accepted as a member once your application is verified.',
      },
    ],
    id: 1644939787058,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787058,
  },
  {
    type: 'p',
    children: [
      {
        text: 'If you had previously signed up for the NSAA Youth Membership through the schools, you are',
      },
    ],
    id: 1644939787059,
  },
  {
    type: 'p',
    children: [
      {
        text: 'not required to reapply again.',
      },
    ],
    id: 1644939787059,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787060,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '3. What are the charges to join as a Youth Member?',
      },
    ],
    id: 1644939787060,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787060,
  },
  {
    type: 'p',
    children: [
      {
        text: 'It is free of charge until 31 December of the year you attain the age of 25 when your Youth Membership is terminated, or when you become a Life Member.',
      },
    ],
    id: 1644939787061,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787061,
  },
  {
    type: 'p',
    children: [
      {
        text: 'You may convert to Life Membership at any time after the age of 21, by paying the entrance and subscription fees.',
      },
    ],
    id: 1644939787062,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787062,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '4. What are the criteria to join as a Youth Member?',
      },
    ],
    id: 1644939787062,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787063,
  },
  {
    type: 'p',
    children: [
      {
        text: 'You are below the age of 25, and must be a former student from NYK, NYPS or NYGH. If you are below the age of 21, consent from your parents or guardian is required.',
      },
    ],
    id: 1644939787063,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787064,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '5. Is there a required minimum period of study in a Nanyang School to join as a Youth Member?',
      },
    ],
    id: 1644939787064,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787065,
  },
  {
    type: 'p',
    children: [
      {
        text: 'No.',
      },
    ],
    id: 1644939787066,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787066,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '6. How do I prove that I have studied in a Nanyang School?',
      },
    ],
    id: 1644939787067,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787068,
  },
  {
    type: 'p',
    children: [
      {
        text: 'You are required to show us your original documents during the GTKY Session. Examples of acceptable documents are certificates, result slips, report books and report cards.',
      },
    ],
    id: 1644939787068,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787069,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '7. Do I have voting rights to cast votes at NSAA’s Annual General Meetings (AGM)?',
      },
    ],
    id: 1644939787070,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787070,
  },
  {
    type: 'p',
    children: [
      {
        text: 'No. You will have voting rights after you convert to Life Membership.',
      },
    ],
    id: 1644939787074,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787074,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '8. As a Youth Member, which phase can I register in during the Primary 1 Registration to enter NYPS?',
      },
    ],
    id: 1644939787075,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787075,
  },
  {
    type: 'p',
    children: [
      {
        text: 'The NSAA Youth Membership is not accepted by the Ministry of Education (MOE) for Primary 1 Registration. Please refer to MOE’s website for the latest updates on the process of Primary 1 Registration.',
      },
    ],
    id: 1644939787076,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939828472,
  },
  {
    type: 'h2',
    id: 1644939828319,
    children: [
      {
        text: '9. What is the difference between NSAA’s Youth Membership and NYPS’ Junior Alumni Membership?',
        bold: true,
      },
    ],
  },
  {
    type: 'p',
    id: 1644939828573,
    children: [
      {
        text: '﻿',
      },
    ],
  },
  {
    type: 'p',
    id: 1644939828574,
    children: [
      {
        text: 'Joining NSAA’s Youth Membership gives you the added advantage of being updated with the events and activities organised by NSAA for all three Nanyang Schools.',
      },
    ],
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787077,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '10. Why should I join as a Youth Member?',
      },
    ],
    id: 1644939787077,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787078,
  },
  {
    type: 'p',
    children: [
      {
        text: 'i. You will be updated on the events organised by NSAA via regular e-Notices.',
      },
    ],
    id: 1644939787078,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787079,
  },
  {
    type: 'p',
    children: [
      {
        text: 'ii. You can participate in these events for all three Nanyang Schools, learn about event management and find out how we work with external organisations for our activities, some of which have become our long-time sponsors.',
      },
    ],
    id: 1644939787079,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787080,
  },
  {
    type: 'p',
    children: [
      {
        text: 'iii. You can broaden your network as you connect with alumni from different generations, industries and professions.',
      },
    ],
    id: 1644939787081,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787081,
  },
  {
    type: 'p',
    children: [
      {
        text: 'iv. At the events, you can reminisce with your ex-teachers, stay connected with your ex-classmates and share your experiences with your juniors.',
      },
    ],
    id: 1644939787082,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787083,
  },
  {
    type: 'p',
    children: [
      {
        text: 'v. You can also meet the retired Principals and teachers, and through the interesting stories from their past, learn how Nanyang has evolved over the decades.',
      },
    ],
    id: 1644939787083,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787084,
  },
  {
    type: 'p',
    children: [
      {
        text: 'vi. Your seniors will share how their journey in Nanyang has helped them in their career as working adults.',
      },
    ],
    id: 1644939787084,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787085,
  },
  {
    type: 'h2',
    children: [
      {
        bold: true,
        text: '11. After becoming a Youth Member, I did not receive any email notifications of NSAA’s events and activities. Why is this so?',
      },
    ],
    id: 1644939787086,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787086,
  },
  {
    type: 'p',
    children: [
      {
        text: 'Please check your email’s junk or spam folder as the e-Notices may have been filtered there.',
      },
    ],
    id: 1644939787087,
  },
  {
    type: 'p',
    children: [
      {
        text: '',
      },
    ],
    id: 1644939787088,
  },
  {
    type: 'p',
    children: [
      {
        text: 'You may also visit our NSAA website regularly for the latest updates.',
      },
    ],
    id: 1644939787088,
  },
];
