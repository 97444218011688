import React from 'react';

import { Box, makeStyles } from '@material-ui/core';

import { CustomButton } from 'src/components';
import SuccessIconSrc from 'src/medias/icons/icon-success.png';

import { Title } from '../../login';

import { CustomLabel } from './types';

const useStyles = makeStyles(() => ({
  container: {
    minHeight: 'calc(100vh - 270px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 40,
  },
}));

const VerifyEmail = ({ youthMember }: { youthMember?: boolean }) => {
  const classes = useStyles();

  function _onGotoSignIn() {
    window.location.href = '/login';
  }

  return (
    <Box textAlign="center">
      <Box className={classes.container}>
        <Box display="flex" justifyContent="center">
          <img src={SuccessIconSrc} alt="Success" />
        </Box>
        <Title>Welcome to Nanyang Schools Alumni Association!</Title>
        <Box mt={1}>
          <CustomLabel>You have successfully created an account.</CustomLabel>
        </Box>
      </Box>
      <Box mb={3} mt={2} display="flex" justifyContent="center">
        <CustomButton onClick={_onGotoSignIn}>LOG IN</CustomButton>
      </Box>
    </Box>
  );
};

export default VerifyEmail;
