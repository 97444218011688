import React, { useState } from 'react';

import { Box } from '@material-ui/core';

import { useFetchProfileQuery } from 'src/api/endpoints/profiles.endpoint';
import { useFetchUserByIdQuery, useUpdateUserByIdMutation } from 'src/api/endpoints/users.endpoint';
import SuccessSnackBar from 'src/components/snackBar/success';
import { TOAST } from 'src/constants/toasts.constant';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { GeneralInformationContainer } from 'src/pages/profile/components/general-information.styled';
import GeneralInformationForm from 'src/pages/profile/forms/general-information.form';
import ProfilePopupLayout from 'src/pages/profile/layout/profile-popup.layout';
import GeneralInformationPopup from 'src/pages/profile/shared/general-information.popup';

interface IUserDetailGeneralInformationProps {
  userId: string | undefined;
}

const UserDetailGeneralInformation = ({ userId }: IUserDetailGeneralInformationProps) => {
  useScrollToTop();
  const [isOpenEditGeneralInformation, setOpenGeneralInformation] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const {
    data: profile,
    isLoading: isFetchingProfile,
    isError: isFetchingProfileError,
    isSuccess: isFetchingSuccess,
    refetch,
  } = useFetchUserByIdQuery(userId!);
  const { data: me } = useFetchProfileQuery();

  React.useEffect(() => {
    refetch();
  }, []);

  const [
    updateProfile,
    { isLoading: isUpdatingProfile, isSuccess: isUpdatingProfileSuccess, isError: isUpdatingProfileError },
  ] = useUpdateUserByIdMutation();

  React.useEffect(() => {
    if (isUpdatingProfileSuccess) {
      setShowSnackBarSuccess(true);
      setOpenGeneralInformation(false);
    }
  }, [isUpdatingProfileSuccess, isUpdatingProfileError]);

  function handleCloseSnackBarSuccess() {
    setShowSnackBarSuccess(false);
  }

  function handleOpenGeneralInformation() {
    setOpenGeneralInformation(true);
  }

  function handleCloseGeneralInformation() {
    setOpenGeneralInformation(false);
  }

  function handleUpdateProfile(profileUpdate: any) {
    updateProfile(profileUpdate);
  }

  return (
    <Box mt={3}>
      <GeneralInformationContainer variants={'user-detail'}>
        <SuccessSnackBar
          open={isShowSnackBarSuccess}
          handleClose={handleCloseSnackBarSuccess}
          message={TOAST.UPDATE_PROFILE_SUCCESS}
        />
        {isFetchingProfileError && <div />}
        {isFetchingProfile && <div />}
        {isFetchingSuccess && (
          <GeneralInformationForm
            onOpenEditGeneralInformation={handleOpenGeneralInformation}
            variants={'user-detail'}
            profile={profile?.data || null}
            showActionButton={me?.data?.roleName !== 'it-admin'}
            showRemarkField
          />
        )}
        <ProfilePopupLayout open={isOpenEditGeneralInformation} onClose={handleCloseGeneralInformation}>
          <GeneralInformationPopup
            onClose={handleCloseGeneralInformation}
            profile={profile?.data || null}
            onSave={handleUpdateProfile}
            isSaveLoading={isUpdatingProfile}
            variants="admin-edit"
            showRemarkField
          />
        </ProfilePopupLayout>
      </GeneralInformationContainer>
    </Box>
  );
};

export default UserDetailGeneralInformation;
