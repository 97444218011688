import React, { useCallback, useState } from 'react';

import { Box, Tooltip } from '@material-ui/core';
import moment from 'moment';

import ErrorSnackBar from 'src/components/snackBar/error';
import NSAATable, { INSAAColumn, INSAADataSource, INSAATableSearch } from 'src/components/table';
import { CALL_SUCCESS, FORMAT_TIME_AM_PM } from 'src/constants/common';
import ViewIcon from 'src/medias/icons/view-icon.png';
import { IErrorMessage } from 'src/pages/admin/components/News/type';
import { getPaymentData, IDataDetail, IDataPayment, ISearchPayment, postPaymentData } from 'src/services/payment';

import {
  Actions,
  ButtonStatus,
  CheckIconStyled,
  CloseIconStyled,
  IconButtonStyled,
  ImageIconStyled,
  ListingSection,
  Title,
} from '../type';

import PaymentDetail from './PaymentDetail';

interface IListPayment {
  data: IDataDetail[];
  isShowPaymentDetail: boolean;
  setShowPaymentDetail: React.Dispatch<React.SetStateAction<boolean>>;
  paymentDetailId: string | undefined;
  setPaymentDetailId: React.Dispatch<React.SetStateAction<string | undefined>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  searchData: ISearchPayment;
  setData: React.Dispatch<any>;
  setSearch: React.Dispatch<React.SetStateAction<ISearchPayment>>;
  isOnSearch: boolean;
  _setIsOnSearch: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: ISearchPayment;
}
function ListPayment({
  data,
  isShowPaymentDetail,
  setShowPaymentDetail,
  paymentDetailId,
  setPaymentDetailId,
  loading,
  setLoading,
  searchData,
  setData,
  setSearch,
  isOnSearch,
  _setIsOnSearch,
  searchValue,
}: IListPayment) {
  const [errorMessage, setErrorMessage] = useState<IErrorMessage>();
  function handleViewDetailUser(id?: string) {
    return function () {
      setShowPaymentDetail?.(true);
      setPaymentDetailId?.(id);
    };
  }
  function handleActionStatus(id: string, type: 'reverse' | 'verify') {
    return async function () {
      setLoading(true);
      const response = await postPaymentData(id, type);
      if (response?.statusCode === CALL_SUCCESS) {
        setSearch((item: ISearchPayment) => ({ ...searchValue }));
      } else {
        setErrorMessage({
          isError: true,
          message: response?.message,
        });
      }
      setLoading(false);
    };
  }
  function handleCloseUserDetail() {
    setShowPaymentDetail?.(false);
    setPaymentDetailId?.(undefined);
  }
  const fetchData = useCallback(
    async (search: INSAATableSearch) => {
      setLoading(true);
      const defaultPage = 1;
      const params = {
        page: isOnSearch ? defaultPage : search.page,
        limit: search.limit,
      };
      const response = await getPaymentData({ ...params, search: { ...searchValue } });

      if (response?.statusCode === CALL_SUCCESS) {
        setData(response.data.items);
        _setIsOnSearch(false);
        setLoading(false);
        return response;
      } else {
        setLoading(false);
        return {
          statusCode: 1,
          message: response?.message,
          data: {
            items: [],
            meta: {
              currentPage: 0,
              itemCount: 0,
              itemsPerPage: 0,
              totalItems: 0,
              totalPages: 0,
            },
          },
        };
      }
    },
    [searchData, isOnSearch],
  );
  const builderColumns = useCallback(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'Id',
        dataIndex: 'Id',
        title: 'Payment ID',
        width: 130,
      },
      {
        key: 'PayerName',
        dataIndex: 'PayerName',
        title: `Payer's Name`,
        width: 115,
      },
      {
        key: 'PaymentType',
        dataIndex: 'PaymentType',
        title: 'Payment Type',
        width: 115,
        render: (value, record) => {
          const paymentType =
            value === 'donation' ? 'Donation' : value === 'buy-ticket' ? 'Buy event ticket' : 'Pay membership fee';
          return <div>{paymentType}</div>;
        },
      },
      {
        key: 'Amount',
        dataIndex: 'Amount',
        title: 'Amount',
        width: 115,
      },
      {
        key: 'Reference',
        dataIndex: 'Reference',
        title: 'Reference',
        width: 115,
        ellipsis: true,
      },
      {
        key: 'Status',
        dataIndex: 'Status',
        title: 'Status',
        width: 115,
        render: (value, record) => {
          let variants: 'confirmed' | 'pending' | 'expired' =
            value === 'confirmed' ? 'confirmed' : value === 'pending' ? 'pending' : 'expired';
          return (
            <ButtonStatus variants={variants} disableRipple>
              {value}
            </ButtonStatus>
          );
        },
      },
      {
        key: 'LastModified',
        dataIndex: 'LastModified',
        title: 'Last Modified At',
        width: 115,
        render: (value, record) => {
          return <div>{moment(+value).format(FORMAT_TIME_AM_PM)}</div>;
        },
      },

      {
        key: 'Actions',
        dataIndex: 'Actions',
        title: '',
        width: 115,
        render: (value, record) => {
          const id = record.key;
          return (
            <Actions>
              <Tooltip title="View details">
                <IconButtonStyled onClick={handleViewDetailUser(record.key)}>
                  <ImageIconStyled src={ViewIcon} />
                </IconButtonStyled>
              </Tooltip>
              {value?.status === 'pending' ? (
                <Tooltip title="Confirm">
                  <IconButtonStyled onClick={handleActionStatus(id, 'verify')}>
                    <CheckIconStyled />
                  </IconButtonStyled>
                </Tooltip>
              ) : value?.status === 'confirmed' ? (
                <Tooltip title="Reverse">
                  <IconButtonStyled onClick={handleActionStatus(id, 'reverse')}>
                    <CloseIconStyled />
                  </IconButtonStyled>
                </Tooltip>
              ) : null}
            </Actions>
          );
        },
      },
    ];
    return columns;
  }, []);
  const builderDataSource = (data: IDataPayment[]) => {
    const dataSource = data?.map((item: IDataPayment, idx: number) => ({
      key: item.id,
      Id: item.code,
      PayerName: item.guest ? item.guest.fullName : item.user ? `${item.user.surname}, ${item.user.givenName}` : '-',
      PaymentType: item.type,
      Reference:
        item.type === 'buy-ticket'
          ? item.event?.title
          : item.type === 'donation'
          ? item.receiveAccount === 'S83SS0071H'
            ? 'NSAA'
            : 'NSAA - NYPS Bursary Fund'
          : item.guest
          ? item.guest.fullName
          : item.user
          ? `${item.user.surname}, ${item.user.givenName}`
          : '-',
      Status: item.status,
      LastModified: item.updatedAt,
      Amount: item.amount,
      Actions: item,
    }));
    return dataSource as unknown as INSAADataSource[];
  };
  const handleCloseSnackBar = () => {
    setErrorMessage({
      isError: false,
      message: '',
    });
  };
  return (
    <ListingSection>
      <ErrorSnackBar
        open={errorMessage?.isError || false}
        handleClose={handleCloseSnackBar}
        message={errorMessage?.message}
      />
      <Box display="flex" justifyContent="space-between" alignItems="left" p={2}>
        <Title>Listing</Title>
      </Box>
      <Box pl={2} pr={3}>
        <NSAATable
          loading={loading}
          isShowPagination={true}
          builderColumns={builderColumns}
          builderDataSource={builderDataSource}
          fetchData={fetchData}
          isSearchReset={isOnSearch}
        />
      </Box>
      {isShowPaymentDetail && (
        <PaymentDetail
          open={true}
          _onCloseModal={handleCloseUserDetail}
          paymentId={paymentDetailId}
          data={data}
          setSearch={setSearch}
          searchData={searchData}
          _setErrorMessage={setErrorMessage}
        />
      )}
    </ListingSection>
  );
}

export default ListPayment;
