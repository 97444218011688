import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import _ from 'lodash';

import { CustomButton, InputField } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import { verifyTicket } from 'src/services/tickets';
import { _checkErrors } from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

const MAXIMUM_TICKET_CODE_LENGTH = 8;
const TEXT_REQUIRE = 'This field is required';

const InputManual = () => {
  const { isTablet } = useResponsive();
  const [data, setData] = useState({
    ticketCode: '',
  });
  const [errors, setErrors] = useState({
    ticketCode: false,
  });
  const [loading, setLoading] = useState(false);
  const [isActionSuccess, setActionSuccess] = useState(false);
  const [isActionFailed, setActionFailed] = useState(false);
  const [messageResponse, setMessageResponse] = useState('');

  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [e.target.name]: e.target.value });
    setValidate(e.target.name, e.target.value);
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setData({ ...data, [e.target.name]: valueTrimmed });
  };

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const _onCheckIn = async () => {
    if (_.isEmpty(data.ticketCode)) {
      setErrors({ ...errors, ticketCode: true });
      return;
    }

    if (!_.isEmpty(data.ticketCode) && !_checkErrors(errors)) {
      setLoading(true);

      const response = await verifyTicket({ ticketCode: data.ticketCode });

      if (response.statusCode === CALL_SUCCESS) {
        setActionSuccess(true);
        setMessageResponse(response.message);
      } else {
        setActionFailed(true);
        setMessageResponse(response.message);
      }
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      p={isTablet ? '131px' : '16px'}
    >
      <Box mt={isTablet ? '280px' : '16px'} />
      <InputField
        title="Ticket Code"
        value={data.ticketCode}
        onChange={_handleChange}
        onBlur={onBlurField}
        name="ticketCode"
        margin="dense"
        fullWidth
        InputProps={{
          inputProps: {
            maxLength: MAXIMUM_TICKET_CODE_LENGTH,
          },
        }}
        error={errors.ticketCode}
        errormessage={TEXT_REQUIRE}
      />
      <Box mb={isTablet ? '280px' : '16px'} />
      <CustomButton disabled={loading} onClick={_onCheckIn} style={{ width: isTablet && '100%' }}>
        Check-in
      </CustomButton>
      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </Box>
  );
};

export default InputManual;
