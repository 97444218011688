import React, { useState } from 'react';

import { Box } from '@material-ui/core';

import { CustomButton } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import ScanIcon from 'src/medias/images/scan-image.svg';
import { verifyTicket } from 'src/services/tickets';
import useResponsive from 'src/utils/responsive';

import Scanner from './Scanner';
import { BoxStyled, ImageStyled, TypographyStyled } from './types';

const ScanTicket = () => {
  const { isTablet } = useResponsive();
  const [isOpenCamera, setIsOpenCamera] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActionSuccess, setActionSuccess] = useState(false);
  const [isActionFailed, setActionFailed] = useState(false);
  const [messageResponse, setMessageResponse] = useState('');

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const handleOpenCamera = () => {
    setIsOpenCamera(true);
  };

  const _closeCamera = () => {
    setIsOpenCamera(false);
  };

  const _onScanSuccess = async (value: any) => {
    setLoading(true);
    let data = JSON.parse(value);
    setIsOpenCamera(false);

    if (data && data.type === 'ticket') {
      const response = await verifyTicket({ ticketCode: data.ticketCode });

      if (response.statusCode === CALL_SUCCESS) {
        setActionSuccess(true);
        setMessageResponse(response.message);
      } else {
        setActionFailed(true);
        setMessageResponse(response.message);
      }
    } else {
      setActionFailed(true);
      setMessageResponse('QR Code is invalid.');
      return;
    }
    setLoading(false);
  };

  const _onScanError = (value: any) => {};

  return (
    <>
      <Box
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        p={isTablet ? '131px' : '16px'}
      >
        {isOpenCamera ? (
          <>
            <Box
              mt={isTablet ? '175px' : '80px'}
              mb={isTablet ? '175px' : '80px'}
              border="5px solid white"
              borderRadius={16}
              overflow="auto"
            >
              <Scanner success={_onScanSuccess} start={isOpenCamera} errors={_onScanError} />
            </Box>
            <CustomButton outlineButton style={{ width: isTablet && '100%' }} onClick={_closeCamera}>
              Close
            </CustomButton>
          </>
        ) : (
          <>
            <BoxStyled isTablet={isTablet}>
              <ImageStyled src={ScanIcon} />
            </BoxStyled>
            <CustomButton disabled={loading} style={{ width: isTablet && '100%' }} onClick={handleOpenCamera}>
              Scan
            </CustomButton>
            <TypographyStyled>Can’t access camera?</TypographyStyled>
          </>
        )}
      </Box>
      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </>
  );
};

export default ScanTicket;
