import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';

import { InputField } from 'src/components';
import { CustomButtonStyled } from 'src/pages/user-management/types';
import { ISearchVolunteer } from 'src/services/volunteer';
import { shallowEqual } from 'src/utils/common';

import { defaultSearchVolunteer } from '../../types';

const LEAST_CHAR_FOR_SEARCH = 3;

interface ISearch {
  _onSearch: (search: ISearchVolunteer) => void;
  _onReset: () => void;
  loading: boolean;
  searchData: ISearchVolunteer;
}

const SearchVolunteer = ({ searchData, loading, _onSearch, _onReset }: ISearch) => {
  const [search, setSearch] = useState<ISearchVolunteer>(defaultSearchVolunteer);

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch({ ...search, [e.target.name]: e.target.value === 'all' ? '' : e.target.value });
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setSearch({ ...search, search: valueTrimmed });
  };

  const reset = () => {
    _onReset();
    setSearch(defaultSearchVolunteer);
  };

  useEffect(() => {
    if (!shallowEqual(search, searchData)) {
      setSearch(searchData);
    }
  }, []);

  return (
    <Box mb={4}>
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1} pr={1}>
              <InputField
                title="Volunteer"
                placeholder="Enter at least 3 characters"
                name="search"
                value={search.search}
                onChange={_handleChange}
                onBlur={onBlurField}
                margin="dense"
                fullWidth
                notice
                noticeTitle={'You can search by NSAA ID, Name, Email Address or Phone number'}
                noticeYellow
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
                error={search.search !== '' && search.search.length < LEAST_CHAR_FOR_SEARCH}
                errorMessage="Enter at least 3 characters"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end" mt={6}>
            <Box display="flex">
              <CustomButtonStyled disabled={loading} outlineButton onClick={reset}>
                Reset
              </CustomButtonStyled>
            </Box>
            <Box display="flex">
              <CustomButtonStyled
                disabled={loading || (search.search !== '' && search.search.length < LEAST_CHAR_FOR_SEARCH)}
                onClick={_onSearch(search)}
              >
                Search
              </CustomButtonStyled>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchVolunteer;
