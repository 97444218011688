import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import styled from 'styled-components';

import { HeaderMobile } from 'src/components/headerMobile';
import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import { CALL_SUCCESS } from 'src/constants/common';
import useFetchMe from 'src/hooks/use-fetch-me';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { getFeatureItem, IFeatureHomepage } from 'src/services/homepage';
import { getMe } from 'src/services/users';
import { saveProfile } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';
import useResponsive from 'src/utils/responsive';

import NsaaEvent from './components/Events';
import NsaaNews from './components/News';
import Sliders from './components/Sliders/Sliders';
import Subscribe from './components/Subscribe';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const Home = () => {
  const { me } = useFetchMe();
  const [current, setCurrent] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IFeatureHomepage[]>([]);

  const dispatch = useAppDispatch();
  const responsive = useResponsive();
  const getMeData = async () => {
    const response = await getMe();
    if (response?.statusCode === CALL_SUCCESS) {
      dispatch(saveProfile(response.data));
    }
  };
  const getData = async () => {
    setLoading(true);
    const response = await getFeatureItem();
    if (response.statusCode === CALL_SUCCESS) {
      setData(response.data.item);
    }
    setLoading(false);
  };

  useEffect(() => {
    Promise.all([getMeData(), getData()]);
  }, []);

  useScrollToTop();
  return (
    <Box pt={3.75}>
      <Container container>
        <LoadingCustom loading={loading} loadingIcon />
        {responsive.isTabletOrMobile && <HeaderMobile hasLogo userProfile={me} />}
        <Sliders slides={data} responsive={responsive} setCurrent={setCurrent} current={current} />
        <NsaaEvent responsive={responsive} />
        <NsaaNews responsive={responsive} />
        <Subscribe responsive={responsive} />
      </Container>
    </Box>
  );
};

const Container = styled(Grid)``;
