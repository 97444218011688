import {
  Box,
  Button,
  CircularProgress,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  MenuItem,
  Typography,
} from '@material-ui/core';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import styled, { css } from 'styled-components';

import { CustomButton, InputField } from 'src/components';
import { IPost, IPostCmsNews } from 'src/services/news';

export interface IListCMSNews {
  data: IPostCmsNews[];
  setData: React.Dispatch<React.SetStateAction<[] | IPostCmsNews[]>>;
  _onCreatePost: () => void;
  _onClickView: (post: IPost) => () => void;
  loading: boolean;
  searchData: ISearchNewsCMS;
  setSearch: React.Dispatch<React.SetStateAction<ISearchNewsCMS>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  _setErrorMessage: React.Dispatch<React.SetStateAction<IErrorMessage | undefined>>;
  isOnSearch: boolean;
  _setIsOnSearch: React.Dispatch<React.SetStateAction<boolean>>;
  searchValue: ISearchNewsCMS;
}
export interface ISearchCMSNews {
  loading: boolean;
  _onSearch: (searchData: ISearchNewsCMS) => () => void;
  _onReset: () => Promise<void>;
  _setSearchValue: React.Dispatch<React.SetStateAction<ISearchNewsCMS>>;
}
export interface IMenuPosition {
  top: number;
  left: number;
}
export interface ISearchNewsCMS {
  title: string;
  status: string;
  startDate: string | Date | null;
  endDate: string | Date | null;
}
export interface IErrorMessage {
  isError: boolean;
  message: string;
}
export const ErrorMessage = styled(Box)`
  color: #f44336;
  font-size: 12px;
`;
export const defaultSearchCMSNews = {
  title: '',
  status: '',
  startDate: null,
  endDate: null,
};
export const useStyles = makeStyles((theme) => ({
  noticeRequire: {
    color: 'red',
    verticalAlign: 'middle',
    marginLeft: 4,
  },
}));

export const CMSHomepageTypes = [
  { key: 'public', value: 'Published' },
  { key: 'un_public', value: 'Unpublished' },
];
export const CustomLabel = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header6,
  height: 18,
  color: 'rgba(0, 0, 0, 0.6)',
}));
export const CustomButtonStyled = styled(CustomButton)(({ theme }) => ({
  width: '120px !important',
  height: '44px !important',
  marginLeft: 16,
}));
export const Title = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header4,
}));
export const KeyboardArrowDownIconStyled = styled(KeyboardArrowDownIcon)(() => ({
  marginLeft: 12,
}));
export const MenuItemStyled = styled(MenuItem)(() => ({
  height: 40,
  minWidth: 217,
}));
export const Actions = styled(Box)`
  display: flex;
  gap: 5px;
`;
export const IconButtonStyled = styled(IconButton)(() => ({
  padding: 2,
}));
export const ImageIconStyled = styled.img(() => ({}));
export const IconStyled = styled.img(() => ({
  marginRight: 12,
}));
export const ButtonStatus = styled(Button)<{
  variants?: 'public' | 'un_public' | 'expired';
}>`
  ${({ theme, variants }) => css`
    width: 112px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: ${variants === 'public'
      ? theme.palette.green?.[900]
      : variants === 'un_public'
      ? theme.palette.gray?.[1000]
      : null};
    font-size: 13px;
    color: white;
    text-transform: capitalize;
    &:hover {
      background-color: ${variants === 'public'
        ? theme.palette.green?.[900]
        : variants === 'un_public'
        ? theme.palette.gray?.[1000]
        : null};
    }
  `}
`;
// Create Post
export const SectionTitle = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: 700;
    vertical-align: middle;
    max-height: 18px;
    color: rgba(0, 0, 0, 0.6);
  }
`;
export const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  padding: 8,
}));
export const HeaderGroup = styled(Box)(({ theme }) => ({
  gap: '10px',
}));
export const TitleH3 = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
  width: '100%',
}));
export const ButtonGroup = styled(Box)`
  display: flex;
  gap: 10px;
`;
export const InputFieldCustom = styled(InputField)`
  ${({ theme }) => css`
    & input {
      padding: 10px 16px;
    }
    & .MuiFormControl-root.MuiTextField-root {
      margin: 0;
      margin-top: 4px;
    }
    & .MuiBox-root {
      margin-top: 24px;
    }
  `}
`;
export const ContainerUploadImage = styled(Box)`
  height: 180px;
  width: 300px;
`;
export const UploadBox = styled.input`
  display: none;
`;
export const Label = styled.label<{ backgroundImg?: string }>`
  ${({ theme, backgroundImg }) => css`
    background-blend-mode: overlay;
    background-color: #0000004a;
    width: 100%;
    height: 100%;
    display: flex;
    background-repeat: no-repeat;
    align-items: center;
    background-position: center;
    background-size: cover;
    background-image: url(${backgroundImg});
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 8px;
  `}
`;
export const IconBox = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
export const AddPhotoAlternateIconCustom = styled(AddPhotoAlternateIcon)`
  color: white;
  width: 32px;
  height: 32px;
`;
export const UploadText = styled(Typography)`
  ${({ theme }) => ({
    ...theme.fonts.textM,
  })}
  color: white;
`;
export const BannerPhoto = styled.img<{ backgroundImg?: string }>`
  ${({ theme, backgroundImg }) => css`
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(${backgroundImg});
    border-radius: 8px;
  `}
`;
export const HeaderButton = styled(Button)<{ variants: 'cancel' | 'save' | 'delete' | 'save & publish' | 'publish' }>`
  ${({ theme, variants }) => css`
    width: 150px;
    font-size: 16px;
    height: 45px;
    border-radius: 6px;
    text-transform: capitalize;
    ${(variants === 'cancel' || variants === 'save') &&
    css`
      border: 1px solid ${theme.palette.yellow?.[900]};
      color: ${theme.palette.yellow?.[900]};
    `}
    ${variants === 'delete' &&
    css`
      border: 1px solid ${theme.palette.red?.[250]};
      color: ${theme.palette.red?.[250]};
    `}
    ${variants === 'publish' &&
    css`
      background: ${theme.palette.yellow?.[900]};
      color: white;
      &:hover {
        background: ${theme.palette.yellow?.[900]};
        color: white;
      }
    `}
    ${variants === 'save & publish' &&
    css`
      width: 300px;
      background: ${theme.palette.yellow?.[900]};
      color: white;
      &:hover {
        background: ${theme.palette.yellow?.[900]};
        color: white;
      }
    `}
  `}
`;
export const TitleRight = styled(Box)`
  ${({ theme }) => ({
    ...theme.fonts.header3,
  })}
`;
export const LabelDetail = styled(Box)`
  ${({ theme }) => ({
    ...theme.fonts.header6,
    color: '#00000099',
  })}
`;
export const TextDetail = styled(Box)`
  ${({ theme }) => ({
    ...theme.fonts.textM,
    color: '#718096',
  })}
`;
export const BoxDetail = styled(Box)`
  gap: 16px;
`;
export const HeaderRight = styled(Box)`
  justify-content: center;
  display: flex;
  gap: 16px;
`;
export const LoadingCustom = styled(CircularProgress)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
}));
export const PreviewContainer = styled(Box)`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 1000;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;
export const DialogTitleStyled = styled(DialogTitle)(() => ({
  '& > h2': {
    fontSize: 20,
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.8)',
  },
  marginTop: 16,
  alignSelf: 'center',
}));
export const DialogContentTextStyled = styled(DialogContentText)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
}));
