import React from 'react';

import { Box, MenuItem, styled, Theme, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

import { CustomButton, InputField } from 'src/components';
import { SearchPagination } from 'src/pages/event-management/components/search/index.styled';
import { IPagination } from 'src/services/users';

export const NumberItems = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  color: 'rgba(113, 128, 150, 1)',
  marginRight: 32,
}));

export const InputPaginationStyled = styled(InputField)(({ theme, width }: { theme: Theme; width?: number }) => ({
  width: width,
  '& .MuiInputBase-root': {
    height: 32,
    fontSize: 14,
    fontWeight: 400,
  },
}));

export const LabelPagination = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  marginRight: 16,
  color: 'rgba(0, 0, 0, 0.6)',
}));

interface ICustomPagination {
  data: IPagination | undefined;
  limit: number;
  handleChangePagination: (e: React.ChangeEvent<HTMLInputElement>) => void;
  currentPage: number;
  setCurrentPage: (value: React.SetStateAction<number>) => void;
  onClickChangePage: () => void;
  disableSearchPage: boolean;
  searchPage: string;
  onBlurField: (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => void;
  itemName: string;
}

export const CustomPagination = ({
  data,
  limit,
  handleChangePagination,
  currentPage,
  setCurrentPage,
  onClickChangePage,
  disableSearchPage,
  searchPage,
  onBlurField,
  itemName,
}: ICustomPagination) => {
  return (
    <SearchPagination>
      <Box display="flex" flex={1} justifyContent="flex-start">
        <Box display="flex" alignItems="center">
          <LabelPagination>Number of records/page</LabelPagination>
          <Box mr={1}>
            <InputPaginationStyled
              width={72}
              select
              value={limit}
              name="records/page"
              onChange={handleChangePagination}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                },
              }}
            >
              {[10, 50, 100, 300].map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </InputPaginationStyled>
          </Box>
        </Box>
      </Box>
      {data?.totalItems && data.totalItems > 0 ? (
        <NumberItems>
          {data?.totalItems} {itemName}
        </NumberItems>
      ) : null}
      <Pagination
        page={currentPage}
        onChange={(_, p) => {
          setCurrentPage(p);
        }}
        count={data?.totalPages}
        variant="outlined"
        shape="rounded"
        color="primary"
      />
      <Box display="flex" flex={1} justifyContent="flex-end">
        <Box display="flex" alignItems="center">
          <LabelPagination>Page</LabelPagination>
          <Box mr={1}>
            <InputPaginationStyled
              name="searchPage"
              value={searchPage === '' ? '' : searchPage}
              onChange={handleChangePagination}
              onBlur={onBlurField}
              width={96}
              placeholder="Ex: 1, 2, 3,..."
            />
          </Box>
          <CustomButton onClick={onClickChangePage} disabled={disableSearchPage} style={{ width: 96, height: 32 }}>
            Go
          </CustomButton>
        </Box>
      </Box>
    </SearchPagination>
  );
};
