import React from 'react';

import { Box } from '@material-ui/core';

import { menus } from '../types';

import EligibilityCriterialSetting from './EligibilityCriterialSetting';
import EligibleUser from './EligibleUser';
import UserList from './UserList';

interface IRightContent {
  menuActive: string;
}

const RightContent = ({ menuActive }: IRightContent) => {
  return (
    <Box m={4}>
      {menuActive === menus[0] && <UserList />}
      {menuActive === menus[1] && <EligibleUser />}
      {menuActive === menus[2] && <EligibilityCriterialSetting />}
    </Box>
  );
};

export default RightContent;
