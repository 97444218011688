import React, { useEffect } from 'react';

import { AppBar, Box, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useFetchProfileQuery } from 'src/api/endpoints/profiles.endpoint';
import { CustomButton } from 'src/components/button';
import useFetchMe from 'src/hooks/use-fetch-me';
import DefaultAvatar from 'src/medias/images/default_avatar.png';
import headerLogo from 'src/medias/images/main-nsaa-logo.png';
import { IResponsive } from 'src/pages/home/homepageStyled';
import { Avatar } from 'src/pages/profile/shared/profile.image.styled';
import useResponsive from 'src/utils/responsive';

import { selectLoginState, selectUserProfile } from '../../../store/authentication/selector';
import { logout } from '../../../store/authentication/slices';
import { useAppDispatch } from '../../../store/hooks';
import { menuAboutUs, menuContactUs, menuUser, menuUserMobile, menuUserNotLogin, menuMembership } from '../type';

import BasicMenu from './BasicMenu';
import SideMenu from './SideMenu';

const useStyles = makeStyles((theme) => ({
  menuButton: {
    color: '#979168',
  },
}));
const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.yellow?.[900],
  height: theme.spacing(3.75),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingRight: '68px',
  paddingLeft: '68px',
}));

const Text = styled(Typography)<{ bold?: boolean }>`
  ${({ theme, bold = true }) => ({
    color: theme.palette.common.black,
    ...theme.fonts.textSSBold,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    fontWeight: bold ? 700 : 300,
    fontSize: 14,
  })}
  span {
    ${({ theme }) => ({
      color: theme.palette.common.black,
      ...theme.fonts.textSS,
    })}
  }
`;

const ToolbarCustom = styled(Toolbar)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    && {
      display: flex;
      justify-content: space-between;
      padding: ${responsive && responsive.isMobile ? '24px 20px 16px 20px' : '32px 68px 32px 68px'};
      height: 120px;
    }
  `};
`;
export const LoginBtn = styled(CustomButton)`
  &&& {
    ${({ theme }) => css`
      background-color: ${theme.palette.yellow?.[900]} !important;
      margin-right: 10px;
      border-radius: 3px;
      width: 100px;
      height: 36px;
      box-shadow: none;
    `}
  }
`;
export const SignupBtn = styled(CustomButton)`
  &&& {
    ${({ theme }) => css`
      border-radius: 3px;
      width: 100px;
      height: 36px;
      border: 1px solid #c5be8b;
      box-shadow: none;
    `}
    &:hover {
      box-shadow: none;
    }
  }
`;

const BoxStyled = styled(Box)<{ active?: boolean }>`
  ${({ theme, active }) => css`
    position: relative;
    &:not(:first-child) {
      margin-left: 22px;
    }
    ${active &&
    css`
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
      .MuiButtonBase-root {
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 100%;
          height: 5px;
          background-color: ${theme.palette.yellow?.[900]};
          bottom: 0;
          left: 0;
        }
      }
    `}
  `}
`;
const BoxText = styled(Box)`
  gap: 10px;
`;
export const Header = () => {
  const classes = useStyles();
  const responsive = useResponsive();
  const userProfile = useSelector(selectUserProfile);
  const isLogin = useSelector(selectLoginState);
  const dispatch = useAppDispatch();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const { data: profile } = useFetchProfileQuery();
  const { me } = useFetchMe();
  function _onLogout() {
    dispatch(logout());
  }
  useEffect(() => {
    setOpenDrawer(false);
  }, [responsive.isDesktopOrLaptop]);
  let redirectURL = '/user-management';
  if (userProfile && userProfile.roleName === 'event-ic') {
    redirectURL = '/event-management';
  }

  if (userProfile && userProfile.committeeMember === 'yes' && userProfile.roleName === null) {
    redirectURL = '/gtky-applicant';
  }
  if (userProfile && userProfile.roleName === 'accountant') {
    redirectURL = '/payment-management';
  }
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    setOpenDrawer(open);
  };
  const _onClickMenu = () => {
    setOpenDrawer(true);
  };
  return (
    <Box>
      {responsive.isMobile || responsive.isTablet ? (
        <Box>
          <AppBarStyled>
            <ToolbarCustom responsive={responsive}>
              <SideMenu
                open={openDrawer}
                toggleDrawer={toggleDrawer}
                isMobile={responsive.isMobile}
                isTablet={responsive.isTablet}
                isLogin={isLogin}
                userProfile={userProfile}
                redirectURL={redirectURL}
              />
              <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu">
                <MenuIcon onClick={_onClickMenu} />
              </IconButton>
              <Box>
                <NavLink to="/">
                  <LogoHeader src={headerLogo} alt="" />
                </NavLink>
              </Box>
              <IconButton edge="end" className={classes.menuButton} color="inherit" aria-label="menu">
                <BasicMenu
                  extend={true}
                  text={
                    isLogin ? (
                      <Avatar width={'50px'} height="50px" imageUrl={me?.avatar || DefaultAvatar} />
                    ) : (
                      <PersonIcon />
                    )
                  }
                  menuItem={isLogin ? (me?.canScanTicket ? menuUserMobile : menuUser) : menuUserNotLogin}
                  logout={_onLogout}
                  isLogin={isLogin}
                />
              </IconButton>
            </ToolbarCustom>
          </AppBarStyled>
        </Box>
      ) : (
        <AppBarStyled>
          <Container>
            <Box>
              <Text>Nanyang Schools Alumni Association | 南洋中小幼校友会</Text>
            </Box>
            <BoxText display={'flex'}>
              <NavLinkStyled to="/">
                <Text bold={false}>English</Text>
              </NavLinkStyled>
              <Text>|</Text>
              <NavLinkStyled to={`/cn`}>
                <Text bold={false}>
                  <span>中文</span>
                </Text>
              </NavLinkStyled>
            </BoxText>
          </Container>
          <ToolbarCustom>
            <Box>
              <NavLink to="/">
                <LogoHeader src={headerLogo} alt="" />
              </NavLink>
            </Box>
            <BoxCustom sx={{ flexGrow: 1 }} color="black" display="flex" height={'100%'}>
              <BoxStyled>
                <NavLinkStyled exact activeClassName="header__nav-active" to="/">
                  <BasicMenu text={'Home'} link="/" />
                </NavLinkStyled>
              </BoxStyled>
              <BoxStyled active={window.location.pathname.includes('about-us')}>
                <BasicMenu text={'ABOUT US'} menuItem={menuAboutUs} />
              </BoxStyled>
              <BoxStyled>
                <NavLinkStyled activeClassName="header__nav-active" to="/events">
                  <BasicMenu text={'events & activities'} link="/events" />
                </NavLinkStyled>
              </BoxStyled>
              <BoxStyled>
                <NavLinkStyled activeClassName="header__nav-active" to="/news">
                  <BasicMenu text={'NEWS'} link="/news" />
                </NavLinkStyled>
              </BoxStyled>
              <BoxStyled active={window.location.pathname.includes('membership')}>
                <BasicMenu text={'MEMBERSHIP'} menuItem={menuMembership} />
              </BoxStyled>
              <BoxStyled
                active={
                  window.location.pathname.includes('contact-us') ||
                  window.location.pathname.includes('subcription-center')
                }
              >
                <BasicMenu text={'CONTACT US'} menuItem={menuContactUs} />
              </BoxStyled>
              {isLogin &&
                ((userProfile && userProfile.committeeMember === 'yes') ||
                  (profile?.data && profile?.data?.committeeMember === 'yes')) && (
                  <>
                    <BoxStyled>
                      <NavLinkStyled activeClassName="header__nav-active" to="/donation">
                        <BasicMenu text={'donation'} />
                      </NavLinkStyled>
                    </BoxStyled>
                    <BoxStyled>
                      <NavLinkStyled activeClassName="header__nav-active" to={redirectURL}>
                        <BasicMenu text={'Committee portal'} />
                      </NavLinkStyled>
                    </BoxStyled>
                  </>
                )}
            </BoxCustom>
            {!isLogin ? (
              <Box display="flex" justifyContent="center" alignItems="center">
                <ButtonNavLink to="/login">
                  <LoginBtn>LOG IN</LoginBtn>
                </ButtonNavLink>
                <ButtonNavLink to="/signup">
                  <SignupBtn white>SIGN UP</SignupBtn>
                </ButtonNavLink>
              </Box>
            ) : (
              <BasicMenu
                extend={true}
                me={me}
                menuItem={me?.canScanTicket ? menuUserMobile : menuUser}
                logout={_onLogout}
                isLogin={isLogin}
              />
            )}
          </ToolbarCustom>
        </AppBarStyled>
      )}
    </Box>
  );
};

const LogoHeader = styled.img`
  && {
    height: 90px;
    width: 70px;
  }
`;
const BoxCustom = styled(Box)`
  && {
    padding-left: 5%;
  }
`;
const AppBarStyled = styled(AppBar)`
  && {
    background: ${({ theme }) => theme.palette.common.white};
    position: fixed;
    z-index: 999;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }
`;
const NavLinkStyled = styled(NavLink)`
  && {
    font-size: 20px;
    text-decoration: none;
    height: 100%;
  }
  ${({ theme }) => css`
    position: relative;
    &.header__nav-active {
      .MuiButton-label {
        color: ${theme.palette.yellow?.[900]};
      }
      .MuiButtonBase-root {
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          width: 100%;
          height: 5px;
          background-color: ${theme.palette.yellow?.[900]};
          bottom: 0px;
          left: 0;
        }
      }
    }
  `}
`;
export const ButtonNavLink = styled(NavLinkStyled)`
  && {
    margin-right: 0;
  }
`;
