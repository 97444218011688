import { DialogContentText } from '@material-ui/core';
import styled from 'styled-components';

import ConfirmPopup from 'src/components/confirm';

interface IConfirmModal {
  openConfirmModal: { action: string; open: boolean };
  handleClose: () => void;
  onSubmit: () => void;
}
export const DialogContentTextStyled = styled(DialogContentText)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
}));
function CMSConfirmModal({ openConfirmModal, handleClose, onSubmit }: IConfirmModal) {
  let text = '';

  if (openConfirmModal.action === 'cancel') {
    text = 'Are you sure you want to cancel?';
  }
  if (openConfirmModal.action === 'save') {
    text = 'Are you sure you want to save this change?';
  }
  return (
    <ConfirmPopup
      open={openConfirmModal.open}
      title="Notification"
      content={text}
      onCancel={handleClose}
      onOk={onSubmit}
      btnCancelVariant="outlined"
      btnOkVariant="filled"
    />
  );
}

export default CMSConfirmModal;
