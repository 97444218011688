import React, { Suspense, useEffect } from 'react';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { CssBaseline } from '@material-ui/core';
import { Theme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { useSelector } from 'react-redux';
import { ThemeProvider as StyledComponentThemeProvider } from 'styled-components';

import { localStorageHelper } from 'src/helpers';
import { selectCountLoginError } from 'src/store/authentication/selector';

import { Loading, ErrorFallback } from './components';
import { useGetAppData } from './hooks/use-app-data-example';
import { useScrollToTop } from './hooks/use-scroll-to-top';
import { AppRoutes } from './routes';
import { sendBlockToIP } from './services/auth';
import { getMe } from './services/users';
import { store } from './store';
import { setLoginState, resetCountLoginError } from './store/authentication/slices';
import { logout } from './store/authentication/slices';
import { useAppDispatch } from './store/hooks';
import { theme } from './theme/theme';
import IdleTimer from './utils/idleTimer';

const TIME_FOR_AUTO_LOGOUT = 1800; //second
const TIME_FOR_CHECK_BLOCK = 300; //second
const MAX_REQUEST_FAIL_TIME = 5;

function StyledComponentThemeContext({ children, theme }: { children: any; theme: Theme }) {
  const dispatch = useAppDispatch();
  const countLoginError = useSelector(selectCountLoginError);

  //Check login state
  useEffect(() => {
    if (localStorageHelper.getItem(process.env.REACT_APP_ACCESS_TOKEN_KEY!)) {
      dispatch(setLoginState(true));
    }
  }, [dispatch]);

  //Check token expired
  useEffect(() => {
    if (localStorageHelper.expired()) {
      dispatch(logout());
    }
  }, [dispatch]);

  //Auto logout
  useEffect(() => {
    const timer = new IdleTimer({
      timeout: TIME_FOR_AUTO_LOGOUT,

      //onExpired: The callback will be triggered if the users re-open the app after the expired time.
      onTimeout: () => {
        dispatch(logout());
      },

      //The callback will be triggered if the users re-open the app after the expired time.
      onExpired: () => {
        dispatch(logout());
      },
    });

    return () => {
      timer.cleanUp();
    };
  }, []);

  useEffect(() => {
    if (localStorageHelper.getItem(process.env.REACT_APP_ACCESS_TOKEN_KEY!)) {
      const interval = setInterval(async () => {
        const response = await getMe();
        if (response && response.data && response.data.accountStatus !== 'active') {
          dispatch(logout());
        }
      }, TIME_FOR_CHECK_BLOCK * 1000);

      return () => clearInterval(interval);
    }
  }, []);

  const postIPToBlock = async () => {
    await sendBlockToIP();
    dispatch(resetCountLoginError());
  };

  useEffect(() => {
    if (countLoginError >= MAX_REQUEST_FAIL_TIME) {
      postIPToBlock();
    }
  }, [countLoginError]);

  return <StyledComponentThemeProvider theme={theme}>{children}</StyledComponentThemeProvider>;
}

function App() {
  useScrollToTop();
  const { loading } = useGetAppData();

  return (
    <Suspense fallback={<Loading />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {loading ? (
          <Loading />
        ) : (
          <Provider store={store}>
            <MuiThemeProvider theme={theme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <StyledComponentThemeContext theme={theme}>
                  <CssBaseline />
                  <AppRoutes />
                </StyledComponentThemeContext>
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>
          </Provider>
        )}
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
