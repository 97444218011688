import React, { useEffect, useMemo, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import {
  createAlignPlugin,
  createBoldPlugin,
  createItalicPlugin,
  createHighlightPlugin,
  createUnderlinePlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createFontColorPlugin,
  createFontBackgroundColorPlugin,
  createKbdPlugin,
  createNodeIdPlugin,
  createDndPlugin,
  createExitBreakPlugin,
  createSelectOnBackspacePlugin,
} from '@udecode/plate';
import { createHistoryPlugin, createReactPlugin, Plate, PlatePlugin, SPEditor } from '@udecode/plate-core';
import { createHeadingPlugin } from '@udecode/plate-heading';
import { createImagePlugin, ELEMENT_IMAGE } from '@udecode/plate-image';
import { createLinkPlugin } from '@udecode/plate-link';
import { createListPlugin, createTodoListPlugin } from '@udecode/plate-list';
import { createMediaEmbedPlugin } from '@udecode/plate-media-embed';
import { createParagraphPlugin } from '@udecode/plate-paragraph';
import { createTablePlugin } from '@udecode/plate-table';
import moment from 'moment';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';

import { CustomButton } from 'src/components';
import Banner from 'src/components/banner';
import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import { optionsExitBreakPlugin, editablePreviewProps } from 'src/components/slateEditor/config/pluginOptions';
import { CALL_SUCCESS, FORMAT_DATE_YMD } from 'src/constants/common';
import { components } from 'src/pages/admin/components/Preview';
import { IResponsive } from 'src/pages/home/homepageStyled';
import { getPost, publicPost } from 'src/services/news';

import FBLogo from '../../../../../medias/images/facebook-logo.png';
import TwitterLogo from '../../../../../medias/images/twitter-logo.png';

interface IPreview {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  postData?: any;
  _setMenuActive: React.Dispatch<React.SetStateAction<string>>;
  setRightType: React.Dispatch<React.SetStateAction<string | null | undefined>>;
}
type TEditor = SPEditor & ReactEditor & HistoryEditor;
function Preview({ setOpen, postData, _setMenuActive, setRightType }: IPreview) {
  const PreviewId = 'preview';
  const [data, setData] = useState<any | null>(null);
  const [loading, setIsLoading] = useState<boolean>(false);
  async function getData(id: string | number) {
    setIsLoading(true);
    const response = await getPost(id);

    if (response?.statusCode === CALL_SUCCESS) {
      setData(response.data);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    if (postData) {
      getData(postData.id);
    }
  }, [postData]);
  const handlePublish = async () => {
    setIsLoading(true);
    let params = {
      ids: [data.id],
      action: 'public',
    };
    const response = await publicPost(params);
    if (response.statusCode === CALL_SUCCESS) {
      _setMenuActive('Create News');
      setRightType(null);
    }
    setIsLoading(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const pluginsMemo: PlatePlugin<TEditor>[] = useMemo(() => {
    const plugins = [
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createMediaEmbedPlugin(),
      createAlignPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createDndPlugin(),
      createExitBreakPlugin(optionsExitBreakPlugin),
      createSelectOnBackspacePlugin({
        allow: [ELEMENT_IMAGE],
      }),
    ];

    return plugins;
  }, []);
  return (
    <Box bgcolor="#000000c4" style={{ minHeight: '100vh' }} pt={5}>
      {<LoadingCustom loading={loading} loadingIcon />}
      <OverflowBox p={4} maxWidth={846} mx="auto" bgcolor="white" maxHeight={'90vh'}>
        <NewPreview>News Preview</NewPreview>
        <Banner image={data?.coverPhoto || ''} title="" description=" " />
        <Box pl={5} pr={5} pt={3}>
          <DateTime>{moment(+data?.updatedAt).format(FORMAT_DATE_YMD)}</DateTime>
          <Title>{data?.title || ''}</Title>
          <Shared>
            Share on <Logo src={FBLogo} /> <Logo src={TwitterLogo} />
          </Shared>
        </Box>
        <Box display="flex" flexDirection="column" maxWidth={730} pl={5} pr={5}>
          <Plate
            id={PreviewId}
            plugins={pluginsMemo}
            components={components}
            editableProps={editablePreviewProps}
            value={data?.description && data?.description}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={6}>
          <Box display="flex">
            <CustomButtonStyled outlineButton onClick={handleClose}>
              Cancel
            </CustomButtonStyled>
          </Box>
          <Box display="flex">
            <CustomButtonStyled onClick={handlePublish}>Publish Now</CustomButtonStyled>
          </Box>
        </Box>
      </OverflowBox>
    </Box>
  );
}

export default Preview;
const DateTime = styled(Box)`
  ${({ theme }) => css`
    color: ${theme.palette.yellow?.[900]};
    margin-bottom: ${theme.spacing(1.5)}px;
  `}
`;
const Title = styled(Typography)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    margin-bottom: ${theme.spacing(3)}px;
    font-size: ${responsive?.isTabletOrMobile ? '20px' : '45px'};
    font-weight: 700;
    padding: ${responsive?.isTabletOrMobile ? '0px 16px' : '0'};
  `}
`;
const NewPreview = styled(Typography)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    margin-bottom: ${theme.spacing(3)}px;
    font-size: ${theme.fonts.header3S.fontSize};
    font-weight: 700;
    padding: ${responsive?.isTabletOrMobile ? '0px 16px' : '0'};
  `}
`;
const Shared = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: ${theme.spacing(4.5)}px;
    color: ${theme.palette.yellow?.[900]};
  `}
`;
const Logo = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 3px;
`;
const OverflowBox = styled(Box)`
  overflow: auto;
`;
export const CustomButtonStyled = styled(CustomButton)(({ theme }) => ({
  width: '120px !important',
  height: '44px !important',
  marginLeft: 16,
}));
