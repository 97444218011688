import React, { useEffect } from 'react';

import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { HeaderMobile } from 'src/components/headerMobile';
import { CALL_SUCCESS } from 'src/constants/common';
import { getMe } from 'src/services/users';
import { selectLoginState, selectUserProfile } from 'src/store/authentication/selector';
import { saveProfile } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';
import useResponsive from 'src/utils/responsive';

import ProfileTabs from '../profile/shared/profile.tabs';

import InputManual from './components/InputManual';
import ScanTicket from './components/ScanTicket';

export const ETicketChecking = () => {
  const history = useHistory();
  const { isMobile, isTabletOrMobile } = useResponsive();
  const isLogin = useSelector(selectLoginState);
  const dispatch = useAppDispatch();
  const userProfile = useSelector(selectUserProfile);

  const getData = async () => {
    const response = await getMe();
    if (response && response?.statusCode === CALL_SUCCESS) {
      dispatch(saveProfile(response?.data));
    }
  };

  useEffect(() => {
    if (isLogin) {
      getData();
    }
  }, [isLogin]);

  useEffect(() => {
    if (
      userProfile &&
      userProfile.roleName !== 'membership-admin' &&
      userProfile.roleName !== 'event-ic' &&
      !userProfile.canScanTicket
    ) {
      history.push('/not-access');
    }
  }, [userProfile]);

  const tabs = [
    { label: 'Scan Ticket', disabled: false },
    { label: 'Input Ticket Code', disabled: false },
  ];

  const panels = [<ScanTicket />, <InputManual />];

  return (
    <Box>
      {isTabletOrMobile ? (
        <Box minHeight={isMobile ? '896px' : '1194px'} display="flex" flexDirection="column">
          <Box mt={5} />
          <HeaderMobile title={`E-ticket Checking`} userProfile={userProfile} />
          <Box mt={11} />
          <ProfileTabs panels={panels} tabs={tabs} />
        </Box>
      ) : null}
    </Box>
  );
};
