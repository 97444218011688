import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { find } from 'lodash';
import moment from 'moment';

import CustomFormDialog from 'src/components/formDialog';
import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import { CALL_SUCCESS, FORMAT_TIME_AM_PM } from 'src/constants/common';
import { IErrorMessage } from 'src/pages/admin/components/News/type';
import { IDataDetail, ISearchPayment, postPaymentData } from 'src/services/payment';

import { InfoText } from '../InfoText';
import { ButtonStatus, CaptionStyled } from '../type';

interface IPaymentDetail {
  open: boolean;
  _onCloseModal: () => void;
  paymentId: string | undefined;
  data: IDataDetail[];
  setSearch: React.Dispatch<React.SetStateAction<ISearchPayment>>;
  searchData: ISearchPayment;
  _setErrorMessage: React.Dispatch<React.SetStateAction<IErrorMessage | undefined>>;
}
function PaymentDetail({
  open,
  _onCloseModal,
  paymentId,
  data,
  setSearch,
  searchData,
  _setErrorMessage,
}: IPaymentDetail) {
  const [loading, setLoading] = useState(false);
  const [dataDetail, setDataDetail] = useState<IDataDetail>();
  const getData = async () => {
    if (data) {
      setLoading(true);
      const findData = find(data, (obj) => obj.id === paymentId);

      setDataDetail(findData);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (paymentId) {
      getData();
    }
  }, [paymentId]);
  const handleSubmit = async () => {
    setLoading(true);
    if (dataDetail) {
      const id = dataDetail?.id;
      if (dataDetail && dataDetail?.status === 'confirmed') {
        const response = await postPaymentData(id, 'reverse');
        if (response?.statusCode === CALL_SUCCESS) {
          setSearch((item: any) => ({ ...searchData }));
          _onCloseModal();
        } else {
          _setErrorMessage({
            isError: true,
            message: response?.message,
          });
        }
      } else {
        const response = await postPaymentData(id, 'verify');
        if (response?.statusCode === CALL_SUCCESS) {
          setSearch((item: any) => ({ ...searchData }));
          _onCloseModal();
        }
      }
    }
    setLoading(false);
  };
  const fullName = dataDetail?.guest
    ? dataDetail?.guest.fullName
    : dataDetail?.user
    ? `${dataDetail?.user?.surname}, ${dataDetail?.user?.givenName}`
    : '-';
  const nassId = dataDetail?.user ? dataDetail?.user?.username : '-';
  const emailAddress = dataDetail?.guest
    ? dataDetail?.guest?.email
    : dataDetail?.user
    ? `${dataDetail?.user?.emailAddress}`
    : '-';
  const phoneNumber = dataDetail?.guest
    ? `${dataDetail?.guest?.areaCode} ${dataDetail?.guest?.mobilePhone}`
    : dataDetail?.user
    ? `${dataDetail?.user?.areaCode} ${dataDetail?.user?.mobilePhone}`
    : '-';
  const updateUser = dataDetail?.updateUser
    ? `${dataDetail.updateUser.surname}, ${dataDetail.updateUser.givenName}`
    : '-';
  const paymentType =
    dataDetail?.type === 'donation'
      ? 'Donation'
      : dataDetail?.type === 'buy-ticket'
      ? 'Buy event ticket'
      : 'Pay membership fee';
  const reference =
    dataDetail?.type === 'buy-ticket'
      ? dataDetail?.event
        ? dataDetail?.event?.title
        : '-'
      : dataDetail?.type === 'donation'
      ? dataDetail?.receiveAccount === 'S83SS0071H'
        ? 'NSAA'
        : 'NSAA - NYPS Bursary Fund'
      : fullName;
  const renderPaymentStatus = () => {
    let variants: 'confirmed' | 'pending' | 'expired' =
      dataDetail?.status === 'confirmed' ? 'confirmed' : dataDetail?.status === 'pending' ? 'pending' : 'expired';
    return (
      <ButtonStatus variants={variants} disableRipple>
        {dataDetail?.status}
      </ButtonStatus>
    );
  };
  return (
    <CustomFormDialog
      open={open}
      _onCloseModal={_onCloseModal}
      _onSubmit={handleSubmit}
      submitText={dataDetail && dataDetail?.status === 'confirmed' ? 'Reverse Payment' : 'Confirm Payment'}
      title={`Payment Detail ${dataDetail?.code}`}
      noClickOutSide={true}
      hiddenSubmitButton={dataDetail && dataDetail?.status === 'expired'}
      deleteButton={dataDetail ? dataDetail?.status === 'confirmed' : false}
      modalStyles={{
        marginLeft: 0,
        marginRight: 0,
      }}
      minWidth="960px"
    >
      {dataDetail && (
        <Box>
          <LoadingCustom loading={loading} loadingIcon />
          <Box display="flex" justifyContent="space-between">
            <Box mr={1} flex={1}>
              <CaptionStyled>Payer info</CaptionStyled>
              <InfoText label="Name" value={fullName} labelWidth={'auto'} />
              <InfoText label="NSAA ID" value={nassId} labelWidth={'auto'} />
            </Box>
            <Box ml={1} mt={3} flex={1}>
              <InfoText label="Email Address" value={emailAddress} labelWidth={'auto'} />
              <InfoText label="Phone Number" value={phoneNumber} labelWidth={'auto'} />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" mt={3}>
            <Box mr={1} flex={1}>
              <CaptionStyled>Payment info</CaptionStyled>
              <InfoText label="Amount" value={dataDetail?.amount} labelWidth={'auto'} />
              <InfoText label="Payment Type" value={paymentType} labelWidth={'auto'} />
              <InfoText label="Reference" value={reference} labelWidth={'auto'} />
              <InfoText
                label="Payment Description"
                value={dataDetail ? dataDetail.description : ''}
                labelWidth={'auto'}
                maxContent
              />
              <InfoText
                label="Last Modified at"
                value={dataDetail?.updatedAt ? moment(+dataDetail?.updatedAt).format(FORMAT_TIME_AM_PM) : ''}
                labelWidth={'auto'}
              />
              <InfoText label="Last Modified By" value={updateUser} labelWidth={'auto'} />
              <InfoText label="Payment Status" value={renderPaymentStatus()} labelWidth={'auto'} />
            </Box>
          </Box>
        </Box>
      )}
    </CustomFormDialog>
  );
}

export default PaymentDetail;
