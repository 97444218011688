import { Box, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const Content = styled(Box)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    padding: 50px 100px;
    max-width: 1440px;
    margin: auto;
    flex: 1;
    line-height: 36px;
    scroll-padding-top: 1000px;
    ${responsive.isTabletOrMobile &&
    css`
      margin-top: 20px;
      padding: 20px;
    `}
  `}
`;

export const Title = styled(Typography)`
  ${({ theme }) => css`
    color: ${theme.palette.gray?.[60]};
    font-size: 20px;
    font-weight: ${theme.fonts.header4S.fontWeight};
    line-height: ${theme.fonts.header4S.lineHeight};
    margin: 10px 0;
    &:first-child {
      margin-top: 0px;
    }
  `}
`;
