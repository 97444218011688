import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '..';

export const selectLoginState = createSelector(
  (state: RootState) => state.auth,
  (authentication) => authentication?.isLoggedIn ?? false,
);

export const selectUserProfile = createSelector(
  (state: RootState) => state.auth,
  (authentication) => authentication?.userProfile,
);

export const selectLoginStatus = createSelector(
  (state: RootState) => state.auth,
  (authentication) => authentication?.loginStatus,
);

export const selectPreviousPath = createSelector(
  (state: RootState) => state.auth,
  (authentication) => authentication?.previousPath ?? '',
);

export const selectCountLoginError = createSelector(
  (state: RootState) => state.auth,
  (authentication) => authentication?.countLoginError ?? false,
);
