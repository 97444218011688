import React from 'react';

import { Box, FormControl, Grid, Link, makeStyles, RadioGroup } from '@material-ui/core';

import { CustomButton, NASSRadio } from 'src/components';
import LogoJSA from 'src/medias/images/main-nsaa-logo.png';
import SignUpBackground from 'src/medias/images/signup-background.jpg';
import { LinkButton, Logo, Note, NYSImage, TitleLogin } from 'src/pages/login/LoginMobile';
import useResponsive from 'src/utils/responsive';

import { CustomFormControlLabel, TextBoldMobile, TextBoldSMobile } from './types';

const IS_OLD_ENOUGH = 'yes';
const IS_NOT_OLD_ENOUGH = 'no';

const useStyles = makeStyles((theme) => ({
  left: {
    height: 300,
    position: 'relative',
    marginTop: 110,
  },
  right: {
    top: 202,
    width: '100%',
  },
}));

interface IConfirmOldStep {
  _onConfirmOld(): void;
  isOldEnough: string;
  setIsOldEnough: React.Dispatch<React.SetStateAction<string>>;
}

const ConfirmOldStepMobile = ({ _onConfirmOld, isOldEnough, setIsOldEnough }: IConfirmOldStep) => {
  const classes = useStyles();
  const { isTablet, isMobile } = useResponsive();

  const _onChange = () => (event: { target: { value: string; name: string } }) => {
    setIsOldEnough(event.target.value);
  };

  function _onClickLogin() {
    window.location.href = '/login';
  }

  return (
    <Grid container>
      <Grid item xs={12} className={classes.left}>
        <Box display="flex" width={'100%'} height={'100%'}>
          <NYSImage src={SignUpBackground} />
          <Link href="/">
            <Logo src={LogoJSA} />
          </Link>
        </Box>
      </Grid>
      <Grid item xs={12} className={classes.right}>
        <Box
          width={'100%'}
          height={'100%'}
          p={2}
          pl={isTablet ? '242px' : '16px'}
          pr={isTablet ? '242px' : '16px'}
          display="flex"
          bgcolor="#F9F9F4"
          minHeight={isTablet ? '996px' : '692px'}
        >
          <Box display="flex" width={'100%'} flexDirection="column">
            <TitleLogin isTablet={isTablet}>Sign up</TitleLogin>
            <Box mb={8}>
              <TextBoldMobile>Dear alumni, please tell us some information</TextBoldMobile>
            </Box>
            <TextBoldSMobile>Are you over 21 years old?</TextBoldSMobile>
            <Box display="flex" flex={isTablet ? 1 : 5}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="confirm-old"
                  value={isOldEnough}
                  name="radio-buttons-group"
                  onChange={_onChange()}
                >
                  <CustomFormControlLabel
                    style={{ color: '#718096' }}
                    value={IS_OLD_ENOUGH}
                    control={<NASSRadio />}
                    label="Yes"
                  />
                  <CustomFormControlLabel
                    style={{ color: '#718096' }}
                    value={IS_NOT_OLD_ENOUGH}
                    control={<NASSRadio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box flex={1} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Note isTablet={isTablet}>
                Already have an account?
                <LinkButton isTablet={isTablet} onClick={_onClickLogin} variant="text">
                  Log in
                </LinkButton>
              </Note>
            </Box>
            {isMobile && (
              <Box mb={4} display="flex" justifyContent="center">
                <CustomButton onClick={_onConfirmOld}>Next</CustomButton>
              </Box>
            )}
            {isTablet && (
              <Box position={'absolute'} bottom={0} left={0} width={'100%'} display="flex" flex={1}>
                <CustomButton style={{ width: '100%', height: 83 }} onClick={_onConfirmOld}>
                  NEXT
                </CustomButton>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConfirmOldStepMobile;
