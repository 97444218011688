import React, { useEffect, useState } from 'react';

import { CALL_SUCCESS } from 'src/constants/common';
import { Container, Header } from 'src/pages/user-management/types';
import { getTicketsAttendance } from 'src/services/tickets';
import { ISearchTicketAttendance, ITicketAttendance } from 'src/services/tickets/ticket.interface';
import { IMeta } from 'src/types';

import { defaultSearchTicket } from '../types';

import Listing from './ListingTicket';
import SearchTicket from './SearchTicket';

const Ticket = () => {
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState<ITicketAttendance[]>([]);
  const [data, setData] = useState<IMeta>();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState<ISearchTicketAttendance>(defaultSearchTicket);

  async function getData({
    search,
    currentPage,
    limit,
  }: {
    search?: ISearchTicketAttendance;
    currentPage: number;
    limit: number;
  }) {
    setLoading(true);
    const response = await getTicketsAttendance({ search, currentPage, limit });

    if (response?.statusCode === CALL_SUCCESS) {
      setTickets(response.data.items);
      setData(response.data.meta);
    }
    setLoading(false);
  }

  const _onSearch = (searchData: ISearchTicketAttendance) => () => {
    setSearch(searchData);
    getData({
      search: searchData,
      currentPage: 1,
      limit,
    });
    setCurrentPage(1);
  };

  const _onReset = async () => {
    setLoading(true);
    setSearch(defaultSearchTicket);
    setCurrentPage(1);

    const response = await getTicketsAttendance({
      search: defaultSearchTicket,
      currentPage: 1,
      limit,
    });

    if (response?.statusCode === CALL_SUCCESS) {
      setTickets(response.data.items);
      setData(response.data.meta);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData({
      search,
      currentPage,
      limit,
    });
  }, [currentPage, limit]);

  return (
    <Container minHeight={500} p={4} bgcolor="white">
      <Header>Ticket Check-in</Header>
      <SearchTicket searchData={search} loading={loading} _onSearch={_onSearch} _onReset={_onReset} />
      <Listing
        tickets={tickets}
        data={data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        limit={limit}
        setLimit={setLimit}
        getData={getData}
        search={search}
      />
    </Container>
  );
};

export default Ticket;
