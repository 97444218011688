import React from 'react';

import { Box, Checkbox, FormControlLabel, FormGroup, MenuItem } from '@material-ui/core';

import { ISkill } from 'src/api/interfaces/users.interface';
import { ISkillKey } from 'src/api/interfaces/users.interface';
import { DATE_PICKER_DISPLAY_FORMAT } from 'src/constants/common';
import { GENDER, NATIONALITY, SKILLS } from 'src/constants/users.constants';
import { FormMenuItem } from 'src/pages/profile/components/general-information.styled';
import { ReactFlagsSelectStyled } from 'src/pages/signup/components/types';
import { IUser } from 'src/services/users';
import { customLabels, getAreaKey } from 'src/utils/areaCode';

import {
  ActionButton,
  Label,
  FormTextField,
  GeneralInformationFormContainer,
  GeneralInformationDatePicker,
} from '../components/general-information.styled';

interface IGeneralInformationFormProps {
  onOpenEditGeneralInformation: () => void;
  variants?: 'user-detail';
  profile: IUser | null;
  showActionButton: boolean;
  showRemarkField?: boolean;
}

const GeneralInformationForm = ({
  onOpenEditGeneralInformation,
  variants,
  profile,
  showActionButton,
  showRemarkField = false,
}: IGeneralInformationFormProps) => {
  const profileSkills: ISkill = profile?.skills?.[0] || ({} as ISkill);

  return (
    <GeneralInformationFormContainer variants={variants}>
      <form>
        <Box mb={3} display="flex" style={{ gap: 24 }}>
          <Box flex="1">
            <Label required>Surname (as in NRIC)</Label>
            <FormTextField
              disabled={true}
              variant="outlined"
              fullWidth
              size="small"
              value={profile?.surname || ''}
              name="surName"
            />
          </Box>
          <Box flex="1">
            <Label required>Given Name (as in NRIC)</Label>
            <FormTextField
              disabled={true}
              variant="outlined"
              fullWidth
              size="small"
              name="givenName"
              value={profile?.givenName || ''}
            />
          </Box>
        </Box>
        <Box mb={3}>
          <Label>中文名字</Label>
          <FormTextField
            disabled={true}
            variant="outlined"
            fullWidth
            size="small"
            name="chineseName"
            value={profile?.chineseName || ''}
          />
        </Box>
        <Box mb={3} display="flex" style={{ gap: 24 }}>
          <Box flex="1">
            <Label required>Date Of Birth</Label>
            <GeneralInformationDatePicker
              fullWidth
              autoOk
              variant="inline"
              inputVariant="outlined"
              format={DATE_PICKER_DISPLAY_FORMAT}
              value={profile?.dateOfBirth}
              InputAdornmentProps={{ position: 'end' }}
              onChange={(date) => {}}
              disabled={true}
            />
          </Box>
          <Box flex="1">
            <Label required>Gender</Label>
            <FormTextField
              disabled={true}
              variant="outlined"
              fullWidth
              size="small"
              value={GENDER.find((i) => i.value === profile?.gender)?.value}
              select={true}
              SelectProps={{
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                },
              }}
            >
              {GENDER.map(({ value, label }, idx) => (
                <MenuItem key={idx} value={value}>
                  {label}
                </MenuItem>
              ))}
            </FormTextField>
          </Box>
        </Box>
        <Box mb={3}>
          <Label required>Email Address</Label>
          <FormTextField
            disabled={true}
            variant="outlined"
            fullWidth
            size="small"
            name="emailAddress"
            value={profile?.emailAddress || ''}
          />
        </Box>
        <Box mb={3} display="flex" style={{ gap: 24 }} alignItems="flex-end">
          <Box flex="0.5">
            <Label required>Phone number</Label>
            <ReactFlagsSelectStyled
              fullWidth
              selected={profile?.areaCode ? getAreaKey(profile.areaCode) : ''}
              onSelect={() => {}}
              searchable
              customLabels={customLabels}
              showSecondaryOptionLabel
              showSelectedLabel={false}
              disabled
            />
          </Box>
          <Box flex="0.5">
            <FormTextField
              value={profile?.mobilePhone || ''}
              disabled={true}
              variant="outlined"
              fullWidth
              size="small"
            />
          </Box>
        </Box>
        <Box mb={3}>
          <Box display="flex">
            <Box flex="0.4">
              <Label required>Which school have you attended?</Label>
            </Box>
            <Box flex="0.6">
              <Label required>Year of Graduation / Leave School</Label>
            </Box>
          </Box>
          <Box display="flex" mb={2}>
            <Box flex="0.4">
              <FormControlLabel
                label="Nanyang Kindergarten"
                control={
                  <Checkbox color="primary" disabled={true} checked={profile?.periodStudyInNYK ? true : false} />
                }
              />
            </Box>
            <Box flex="0.6">
              <FormTextField
                disabled={true}
                variant="outlined"
                color="primary"
                fullWidth
                size="small"
                value={profile?.periodStudyInNYK || ''}
              />
            </Box>
          </Box>
          <Box display="flex" mb={2}>
            <Box flex="0.4">
              <FormControlLabel
                label="Nanyang Pre-Primary"
                control={
                  <Checkbox color="primary" disabled={true} checked={profile?.periodStudyInNPP ? true : false} />
                }
              />
            </Box>
            <Box flex="0.6">
              <FormTextField
                disabled={true}
                variant="outlined"
                fullWidth
                size="small"
                value={profile?.periodStudyInNPP || ''}
              />
            </Box>
          </Box>
          <Box display="flex" mb={2}>
            <Box flex="0.4">
              <FormControlLabel
                label="Nanyang Primary"
                control={<Checkbox color="primary" disabled={true} />}
                checked={profile?.periodStudyInNYP ? true : false}
              />
            </Box>
            <Box flex="0.6">
              <FormTextField
                disabled={true}
                variant="outlined"
                fullWidth
                size="small"
                value={profile?.periodStudyInNYP || ''}
              />
            </Box>
          </Box>
          <Box display="flex" mb={2}>
            <Box flex="0.4">
              <FormControlLabel
                label="Nanyang Girls' High"
                control={
                  <Checkbox color="primary" disabled={true} checked={profile?.periodStudyInNYGH ? true : false} />
                }
              />
            </Box>
            <Box flex="0.6">
              <FormTextField
                disabled={true}
                variant="outlined"
                fullWidth
                size="small"
                value={profile?.periodStudyInNYGH || ''}
              />
            </Box>
          </Box>
        </Box>
        <Box mb={3}>
          <Label required>Mailing Address</Label>
          <FormTextField
            disabled={true}
            variant="outlined"
            fullWidth
            size="small"
            name="mailingAddress"
            value={profile?.mailingAddress || ''}
          />
        </Box>
        <Box mb={3}>
          <Label required>Postal Code</Label>
          <FormTextField
            disabled={true}
            variant="outlined"
            fullWidth
            size="small"
            name="postalCode"
            value={profile?.postalCode || ''}
          />
        </Box>
        <Box mb={3}>
          <Label>Nationality</Label>
          <FormTextField disabled={true} variant="outlined" fullWidth size="small" value={profile?.nationality} select>
            {NATIONALITY.map(({ value, label }, idx) => (
              <FormMenuItem key={idx} value={value}>
                {label}
              </FormMenuItem>
            ))}
          </FormTextField>
        </Box>
        <Box mb={3}>
          <Label>Job Title</Label>
          <FormTextField disabled={true} variant="outlined" fullWidth size="small" value={profile?.jobTitle || ''} />
        </Box>
        <Box mb={3}>
          <Label>Company Name</Label>
          <FormTextField disabled={true} variant="outlined" fullWidth size="small" value={profile?.companyName || ''} />
        </Box>
        <Box mb={1.8}>
          <Label>Skills</Label>
          <FormGroup>
            {SKILLS.map(({ label, value }) => (
              <FormControlLabel
                label={label}
                control={<Checkbox color="primary" disabled={true} checked={!!profileSkills?.[value as ISkillKey]} />}
              />
            ))}
          </FormGroup>
        </Box>
        <Box mb={3}>
          <Label>Other Skills</Label>
          <FormTextField
            disabled={true}
            variant="outlined"
            fullWidth
            size="small"
            value={profileSkills['other-skills'] || ''}
          />
        </Box>
        {showRemarkField && (
          <Box mb={3}>
            <Label>Remark</Label>
            <FormTextField variant="outlined" fullWidth size="small" value={profile?.remark} disabled={true} />
          </Box>
        )}
      </form>
      <Box display="flex" justifyContent="center">
        {showActionButton && (
          <ActionButton variants="yellow" type="submit" onClick={onOpenEditGeneralInformation}>
            Edit
          </ActionButton>
        )}
      </Box>
    </GeneralInformationFormContainer>
  );
};

export default GeneralInformationForm;
