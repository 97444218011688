import React from 'react';

import { Box, ListItem, ListItemText } from '@material-ui/core';
import styled from 'styled-components';

import { IMenuItemData, menus } from '../types';

const CustomListItem = styled(ListItem)`
  background-color: white;

  min-width: fit-content;
  .MuiTypography-root {
    ${({ theme }) => ({
      ...theme.fonts.header6,
      fontWeight: 400,
    })}
  }
`;
const CustomListItemActive = styled(ListItem)`
  color: #c5be8b;
  border-bottom: 5px solid;
  .MuiListItemIcon-root {
    color: #c5be8b;
  }
  .MuiTypography-root {
    ${({ theme }) => ({
      ...theme.fonts.header6,
    })}
  }
`;
interface ILeftContent {
  setMenuActive: any;
  menuActive: IMenuItemData;
}
function MenuBar({ setMenuActive, menuActive }: ILeftContent) {
  const _onClickMenuItem = (menu: IMenuItemData) => () => {
    setMenuActive(menu);
  };
  return (
    <Box display="flex">
      {menus.map((menu) => (
        <Box>
          {menu.text === menuActive.text ? (
            <CustomListItemActive button onClick={_onClickMenuItem(menu)}>
              <ListItemText primary={menu.text} />
            </CustomListItemActive>
          ) : (
            <CustomListItem button onClick={_onClickMenuItem(menu)}>
              <ListItemText primary={menu.text} />
            </CustomListItem>
          )}
        </Box>
      ))}
    </Box>
  );
}

export default MenuBar;
