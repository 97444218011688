import React, { useEffect, useState } from 'react';

import { Box, Grid, MenuItem } from '@material-ui/core';

import { CustomInputSelect, InputField } from 'src/components';
import { ISearchUser } from 'src/services/users';
import { capitalizeFirstLetter, shallowEqual } from 'src/utils/common';

import {
  accountTypes,
  verificationStatus,
  adminRole,
  committeeMember,
  accountStatus,
  CustomButtonStyled,
  defaultSearchUser,
} from '../types';

const LEAST_CHAR_FOR_SEARCH = 2;
interface ISearchUserView {
  _onSearch: (search: ISearchUser) => void;
  _onReset: () => void;
  loading: boolean;
  searchData: ISearchUser;
}

const SearchUser = ({ searchData, loading, _onSearch, _onReset }: ISearchUserView) => {
  const [search, setSearch] = useState<ISearchUser>(defaultSearchUser);

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearch({ ...search, [e.target.name]: e.target.value === 'all' ? '' : e.target.value });
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setSearch({ ...search, search: valueTrimmed });
  };

  const reset = () => {
    _onReset();
    setSearch(defaultSearchUser);
  };

  useEffect(() => {
    if (!shallowEqual(search, searchData)) {
      setSearch(searchData);
    }
  }, []);

  return (
    <Box mb={4}>
      <Grid container justifyContent="space-between">
        <Grid item xs={8}>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1} pr={1}>
              <InputField
                title="User information"
                placeholder="Enter at least 2 characters"
                name="search"
                value={search.search}
                onChange={_handleChange}
                onBlur={onBlurField}
                margin="dense"
                fullWidth
                notice
                noticeTitle={'You can search by NSAA ID, Name, Email Address or Phone number'}
                noticeYellow
                InputProps={{
                  inputProps: {
                    maxLength: 50,
                  },
                }}
                error={search.search !== '' && search.search.length < LEAST_CHAR_FOR_SEARCH}
                errorMessage="Enter at least 2 characters"
              />
            </Box>
            <Box flex={1} pl={1}>
              <CustomInputSelect
                title="Account type"
                name="accountType"
                value={search.accountType === '' ? 'all' : search.accountType}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {accountTypes.map((accountType) => (
                  <MenuItem key={accountType.key} value={accountType.key}>
                    {capitalizeFirstLetter(accountType.value)}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1} pr={1}>
              <CustomInputSelect
                title="Committee member"
                name="committeeMember"
                value={search.committeeMember === '' ? 'all' : search.committeeMember}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {committeeMember.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {capitalizeFirstLetter(item.value)}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
            <Box flex={1} pl={1}>
              <CustomInputSelect
                title="Account status"
                name="accountStatus"
                value={search.accountStatus === '' ? 'all' : search.accountStatus}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {accountStatus.map((item) => (
                  <MenuItem key={item.key} value={item.key}>
                    {capitalizeFirstLetter(item.value)}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Box flex={1} pr={1}>
              <CustomInputSelect
                title="Role"
                name="roleName"
                value={search.roleName === '' ? 'all' : search.roleName}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {adminRole.map((role) => (
                  <MenuItem key={role.key} value={role.key}>
                    {capitalizeFirstLetter(role.value)}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
            <Box flex={1} pl={1}>
              <CustomInputSelect
                title="Verification status"
                name="verificationStatus"
                value={search.verificationStatus === '' ? 'all' : search.verificationStatus}
                onChange={_handleChange}
                margin="dense"
                fullWidth
                notRequired
              >
                <MenuItem key="all" value="all">
                  All
                </MenuItem>
                {verificationStatus.map((status) => (
                  <MenuItem key={status.key} value={status.key}>
                    {capitalizeFirstLetter(status.value)}
                  </MenuItem>
                ))}
              </CustomInputSelect>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box display="flex" justifyContent="flex-end" mt={6}>
            {!shallowEqual(searchData, defaultSearchUser) && (
              <Box display="flex">
                <CustomButtonStyled disabled={loading} outlineButton onClick={reset}>
                  Reset
                </CustomButtonStyled>
              </Box>
            )}
            <Box display="flex">
              <CustomButtonStyled
                disabled={loading || (search.search !== '' && search.search.length < LEAST_CHAR_FOR_SEARCH)}
                onClick={_onSearch(search)}
              >
                Search
              </CustomButtonStyled>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchUser;
