import React, { useState } from 'react';

import { Box, CircularProgress, IconButton, Link, Radio, RadioGroup } from '@material-ui/core';
import { Form, Formik } from 'formik';
import styled, { css } from 'styled-components';
import * as Yup from 'yup';

import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import DeleteOutlined from 'src/medias/icons/delete-outlined.png';
import CircleDisable from 'src/medias/icons/disabled-circle.png';
import CirclePurple from 'src/medias/icons/purple-circle.png';
import { Title } from 'src/pages/profile/components/additional-information.styled';
import { ActionButton, FormTextField, Label } from 'src/pages/profile/components/general-information.styled';
import { ReactFlagsSelectStyled } from 'src/pages/signup/components/types';
import { FormControlLabelStyled } from 'src/pages/user-management/shared/user-detail.account-settings';
import { uploadCoverPhoto } from 'src/services/events';
import { IApplicant, updateApplicantGtkyById } from 'src/services/gtky-applicant';
import { customLabels, getAreaCode } from 'src/utils/areaCode';

interface IAdditionalInformationYouthFormProps {
  applicant: IApplicant | null;
  onSave: (values: any) => void;
}

const AdditionalInformationYouthForm = ({ applicant, onSave }: IAdditionalInformationYouthFormProps) => {
  const [isEditable, setEditable] = useState(false);
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [uploading, setUploading] = useState(false);

  const ONE_MB_FILE_SIZE = 1024;
  const UPLOAD_FILE_SIZE_MESSAGE = 'Uploaded file’s format must be PNG, JPG, IMG and size must be less than 1MB';
  const THIS_FIELD_IS_REQUIRE = 'This field is require';
  const THIS_FIELD_MUST_BE_AN_EMAIL = 'This field must be an email';

  const toggleEditable = () => setEditable((toggle) => !toggle);
  const proofFileRef = React.useRef<HTMLInputElement | null>(null);

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };

  function handleProofFileClick() {
    if (proofFileRef.current) {
      proofFileRef.current.click();
    }
  }

  function setRef(ref: HTMLInputElement | null) {
    proofFileRef.current = ref!;
  }
  if (!applicant) return <div />;
  return (
    <Box>
      <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
      <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
      <Formik
        initialValues={{
          parentSurname: applicant.parentSurname ? applicant.parentSurname : '',
          parentGivenName: applicant.parentGivenName ? applicant.parentGivenName : '',
          relationship: applicant.relationship ? applicant.relationship : 'parent',
          parentEmail: applicant.parentEmail ? applicant.parentEmail : '',
          parentAreaCode: applicant.parentAreaCode ? applicant.parentAreaCode : 'SG',
          parentMobilePhone: applicant.parentMobilePhone ? applicant.parentMobilePhone : '',
          signature: applicant.signature ? applicant.signature : '',
        }}
        onSubmit={(values) => {
          values.parentAreaCode = getAreaCode(values.parentAreaCode);

          (async () => {
            const response = await updateApplicantGtkyById(applicant.id, {
              ...applicant,
              ...values,
            });
            if (response.statusCode === CALL_SUCCESS) {
              setShowSnackBarSuccess(true);
              setMessage(response.message);
              setEditable(false);
            } else {
              setMessage(response.message);
              setShowSnackBarError(true);
            }
          })();
        }}
        validationSchema={Yup.object({
          parentSurname: Yup.string().required(THIS_FIELD_IS_REQUIRE),
          parentGivenName: Yup.string().required(THIS_FIELD_IS_REQUIRE),
          parentEmail: Yup.string().email(THIS_FIELD_MUST_BE_AN_EMAIL).required(THIS_FIELD_IS_REQUIRE),
          parentMobilePhone: Yup.string().required(THIS_FIELD_IS_REQUIRE),
        })}
      >
        {(formik) => {
          const handleCancel = () => {
            toggleEditable();
            formik.resetForm();
          };
          const handleRemoveSignature = () => {
            formik.setFieldValue('signature', '');
          };
          const handleAddSignature = () => {
            handleProofFileClick();
          };

          const handleInputNumberChange =
            (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (e.target.value.includes('+') || e.target.value.includes('-') || isNaN(e.target.value as any)) return;
              formik.setFieldValue(name, e.target.value);
            };

          const handleAreaCodeChange = (value: any) => {
            formik.setFieldValue('parentAreaCode', value);
          };

          async function handleProofFileChange(e: React.ChangeEvent<HTMLInputElement>) {
            const fileSize = e.target?.files?.[0].size;
            const fileS = Math.round(fileSize! / ONE_MB_FILE_SIZE);
            if (fileS > ONE_MB_FILE_SIZE) {
              setShowSnackBarError(true);
              setMessage(UPLOAD_FILE_SIZE_MESSAGE);
              e.target.value = '';
              return;
            }
            const file = e.target?.files?.[0];
            if (file) {
              const formData = new FormData();
              formData.append('image', file);
              setUploading(true);
              const response = await uploadCoverPhoto(formData);
              if (response.statusCode === CALL_SUCCESS) {
                setUploading(false);
                setMessage(response.message);
                setShowSnackBarSuccess(true);
                formik.setFieldValue('signature', response?.data?.name);
                e.target.value = '';
              } else {
                setUploading(false);
                setMessage(response.message);
                setShowSnackBarSuccess(true);
                e.target.value = '';
              }
            }
          }

          return (
            <Form>
              <Box px={3}>
                <Title>Your Parent/Guardian Info</Title>
                <Box my={3} display="flex" style={{ gap: 24 }}>
                  <Box flex="1">
                    <Label required>Surname</Label>
                    <FormTextField
                      variant="outlined"
                      fullWidth
                      disabled={!isEditable}
                      name="parentSurname"
                      value={formik.values.parentSurname}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.parentSurname && !!formik.errors.parentSurname}
                      helperText={formik.touched.parentSurname && formik.errors.parentSurname}
                    />
                  </Box>
                  <Box flex="1">
                    <Label required>Given Name</Label>
                    <FormTextField
                      variant="outlined"
                      fullWidth
                      disabled={!isEditable}
                      name="parentGivenName"
                      value={formik.values.parentGivenName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={formik.touched.parentGivenName && !!formik.errors.parentGivenName}
                      helperText={formik.touched.parentGivenName && formik.errors.parentGivenName}
                    />
                  </Box>
                </Box>
                <Box mb={3}>
                  <Label required>Relationship</Label>
                  <RadioGroup
                    row
                    name="relationship"
                    value={formik.values.relationship}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <FormControlLabelStyled
                      disabled={!isEditable}
                      value="parent"
                      control={<Radio disabled={!isEditable} />}
                      label="Parent"
                    />
                    <Box width={160} />
                    <FormControlLabelStyled
                      disabled={!isEditable}
                      value="guardian"
                      control={<Radio disabled={!isEditable} />}
                      label="Guardian"
                    />
                  </RadioGroup>
                </Box>
                <Box mb={3}>
                  <Label required>Email Address</Label>
                  <FormTextField
                    variant="outlined"
                    fullWidth
                    disabled={!isEditable}
                    name="parentEmail"
                    value={formik.values.parentEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.parentEmail && !!formik.errors.parentEmail}
                    helperText={formik.touched.parentEmail && formik.errors.parentEmail}
                  />
                </Box>
                <Box mb={3}>
                  <Label required>Phone Number</Label>
                  <Box display="flex" width="100%" style={{ gap: 24 }} alignItems="flex-start">
                    <Box flex="0.5">
                      <ReactFlagsSelectStyled
                        fullWidth
                        selected={formik.values.parentAreaCode}
                        onSelect={handleAreaCodeChange}
                        searchable
                        customLabels={customLabels}
                        showSecondaryOptionLabel
                        showSelectedLabel={false}
                        disabled={!isEditable}
                      />
                    </Box>
                    <Box flex="0.5">
                      <FormTextField
                        disabled={!isEditable}
                        variant="outlined"
                        fullWidth
                        size="small"
                        name="parentMobilePhone"
                        value={formik.values.parentMobilePhone}
                        onChange={handleInputNumberChange('parentMobilePhone')}
                        onBlur={formik.handleBlur}
                        error={formik.touched.parentMobilePhone && !!formik.errors.parentMobilePhone}
                        helperText={formik.touched.parentMobilePhone && formik.errors.parentMobilePhone}
                      />
                    </Box>
                  </Box>
                </Box>
                <Box mb={4}>
                  <input
                    accept=".png, .jpeg, .jpg, .pdf"
                    type="file"
                    ref={setRef}
                    hidden
                    onChange={handleProofFileChange}
                  />
                  <Label>Parent’s Consent Proof</Label>
                  <ParentConsentProof disabled={!isEditable}>
                    <Box display="flex" alignItems="center">
                      {uploading ? (
                        <Box mx={2}>
                          <CircularProgress size={20} color="inherit" />
                        </Box>
                      ) : (
                        <Box>
                          {isEditable ? (
                            <IconButton onClick={handleAddSignature}>
                              <img src={CirclePurple} alt="circle purple" />
                            </IconButton>
                          ) : (
                            <IconButton>
                              <img src={CircleDisable} alt="circle disable" />
                            </IconButton>
                          )}
                        </Box>
                      )}
                      {formik.values.signature ? (
                        <LinkPlaceholder disabled={!isEditable} href={formik.values.signature} target="_blank">
                          {formik.values.signature}
                        </LinkPlaceholder>
                      ) : (
                        <LinkPlaceholder disabled={!isEditable} href="#">
                          Attach File
                        </LinkPlaceholder>
                      )}
                    </Box>
                    <Box>
                      {formik.values.signature && (
                        <IconButton onClick={handleRemoveSignature}>
                          {formik.values.signature && <img src={DeleteOutlined} alt="delete outlined" />}
                        </IconButton>
                      )}
                    </Box>
                  </ParentConsentProof>
                </Box>
                <Box width="100%" display="flex" justifyContent="center" mb={5} style={{ gap: 24 }}>
                  {isEditable ? (
                    <>
                      <ActionButton variants="outlined" onClick={handleCancel}>
                        Cancel
                      </ActionButton>
                      <ActionButton type="submit" variants="yellow">
                        Save
                      </ActionButton>
                    </>
                  ) : (
                    <ActionButton variants="yellow" onClick={toggleEditable}>
                      Edit
                    </ActionButton>
                  )}
                </Box>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export const ParentConsentProof = styled(Box)<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    border-radius: 6px;
    border: 1px solid #0000001a;
    padding-left: 5px;
    padding-right: 5px;

    ${disabled &&
    css`
      background-color: #0000001a;
      user-select: none;
      pointer-events: none;
    `}
  `}
`;

export const LinkPlaceholder = styled(Link)<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    color: #5856d6;
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;

    ${disabled &&
    css`
      color: #718096;
      user-select: none;
      pointer-events: none;
      text-decoration: none;
    `}
  `}
`;

export default AdditionalInformationYouthForm;
