import React from 'react';

import { Box } from '@material-ui/core';

import { IUsersToEvent, IVolunteerData } from 'src/services/users';

import EventBox from './EventBox';

interface IMenuActive {
  text: string;
  value: string;
}
interface IVolunteerEventContent {
  menuActive: { text: string; value: string };
  data: IVolunteerData | null;
  setMenuActive: React.Dispatch<React.SetStateAction<IMenuActive>>;
}
function VolunteerEventContent({ menuActive, data, setMenuActive }: IVolunteerEventContent) {
  return (
    <Box width="1200px">
      {data &&
        data.usersToEvent &&
        data.usersToEvent.map((event: IUsersToEvent) => {
          return <EventBox event={event} setMenuActive={setMenuActive} menuActive={menuActive} />;
        })}
    </Box>
  );
}

export default VolunteerEventContent;
