import React, { useState } from 'react';

import { Box } from '@material-ui/core';
import styled from 'styled-components';

import AboutNSAA from './AboutNSAA';
import Committee from './Committee';
import Constitution from './Constitution';
import ContactUs from './ContactUs';
import Donation from './Donation';
import EventsActivities from './EventsActivities';
import Homepage from './Homepage';
import { News } from './News';
import { NewsPostCMS } from './News/CreatePost/NewsPostCMS';

interface IRightContent {
  menuActive: string;
  setMenuActive: React.Dispatch<React.SetStateAction<string>>;
  setRightType: React.Dispatch<React.SetStateAction<string | null | undefined>>;
  rightType?: string | null;
}

const RightContentBox = styled(Box)`
  margin: 50px;
`;
const RightContent = ({ menuActive, setMenuActive, setRightType, rightType }: IRightContent) => {
  const [post, setPost] = useState<any | null>();
  const renderComponent = (title: string) => {
    switch (title) {
      case 'Homepage':
        return <Homepage />;
      case 'Create News':
        if (rightType === 'create') {
          return (
            <NewsPostCMS
              rightType={rightType}
              setRightType={setRightType}
              _setMenuActive={setMenuActive}
              _setPost={setPost}
            />
          );
        }
        if (rightType === 'detailNews') {
          return (
            <NewsPostCMS
              rightType={rightType}
              setRightType={setRightType}
              postData={post}
              _setPost={setPost}
              _setMenuActive={setMenuActive}
            />
          );
        }
        return <News setMenuActive={setMenuActive} setRightType={setRightType} setPost={setPost} />;
      case 'About NSAA':
        return <AboutNSAA />;
      case 'Committee':
        return <Committee />;
      case 'Constitution':
        return <Constitution />;
      case 'Events & Activities':
        return <EventsActivities />;
      case 'Donation':
        return <Donation />;
      case 'Contact Us':
        return <ContactUs />;
      default:
        break;
    }
  };
  return <RightContentBox>{renderComponent(menuActive)}</RightContentBox>;
};

export default RightContent;
