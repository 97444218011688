import React, { useEffect, useRef, useState } from 'react';

import { Box, RadioGroup } from '@material-ui/core';
import { FormikProps } from 'formik';

import { NASSRadio } from 'src/components';
import CrossIconImage from 'src/medias/icons/cross.png';
import { FormTextField } from 'src/pages/profile/components/general-information.styled';
import { FormControlLabelStyled } from 'src/pages/user-management/shared/user-detail.account-settings';
import { isInputNumber } from 'src/utils/common';

import {
  QuestionPoint,
  QuestionRow,
  QuestionTitle,
  QuestionToolbar,
  RemoveIcon,
  SingleChoiceContainer,
} from './index.styled';

interface ISingleChoiceProps {
  formik: FormikProps<any>;
  questionIndex: string;
  disabled?: boolean;
  formStatus?: { isReset: boolean };
}

const SingleChoice = ({ formik, questionIndex, disabled, formStatus }: ISingleChoiceProps) => {
  const MAX_LENGTH_255 = 255;

  const [answers, setAnswers] = useState<{ answer: string; isTrue: boolean }[]>(
    formik.values.question[questionIndex].answer,
  );

  useEffect(() => {
    setAnswers(formik.values.question[questionIndex].answer);
  }, [formik.values.question[questionIndex]]);

  useEffect(() => {
    if (formStatus?.isReset) {
      setAnswers(formik.values.question[questionIndex].answer);
    }
  }, [formStatus]);

  const touched = formik.touched as any;
  const errors = formik.errors as any;
  const INPUT_MAX_LENGTH_255 = 255;
  const optionRefs = useRef<HTMLInputElement[]>([]);
  const answersLengthRef = useRef<number>(answers.length);

  const handleAddNewOption = () => {
    const answersCloned = [...answers, { answer: '', isTrue: false }];
    setAnswers(() => answersCloned);
  };
  useEffect(() => {
    formik.setFieldValue(`question[${questionIndex}].answer`, answers);
  }, [answers]);

  const handleRadioChange = (answerIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const answersCloned = [...answers].map((item) => ({ ...item, isTrue: false }));
    answersCloned.splice(answerIndex, 1, { ...answersCloned[answerIndex], isTrue: e.target.checked });
    setAnswers(answersCloned);
  };
  const handleAnswerTextChange =
    (answerIndex: number) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const answersCloned = [...answers];
      answersCloned.splice(answerIndex, 1, { ...answersCloned[answerIndex], answer: e.target.value });
      setAnswers(answersCloned);
    };
  const handleRemoveOption = (optionIndex: number) => () => {
    const answersCloned = [...answers];
    if (answersCloned[optionIndex].isTrue && optionIndex === 0) {
      answersCloned[1].isTrue = true;
    }
    if (answersCloned[optionIndex].isTrue && optionIndex !== 0) {
      answersCloned[0].isTrue = true;
    }
    answersCloned.splice(optionIndex, 1);
    setAnswers(answersCloned);
  };

  const handlePointChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!isInputNumber(e.target.value)) return;
    formik.setFieldValue(name, e.target.value);
  };

  useEffect(() => {
    if (answers.length === 1) return;

    if (answers.length > answersLengthRef.current) {
      optionRefs.current[answers.length - 1]?.focus();
    }

    answersLengthRef.current = answers.length;
  }, [answers]);

  return (
    <SingleChoiceContainer>
      {answers.map((item, index) => {
        return (
          <QuestionRow variants="body">
            <QuestionTitle variants="title">
              <FormTextField
                variant="outlined"
                fullWidth
                inputRef={(e) => (optionRefs.current[index] = e!)}
                placeholder={`Option ${index + 1}`}
                onChange={handleAnswerTextChange(index)}
                value={item.answer}
                name={`question[${questionIndex}].answer[${index}].answer`}
                error={
                  touched?.question?.[questionIndex]?.answer?.[index]?.answer &&
                  !!errors?.question?.[questionIndex]?.answer?.[index]?.answer
                }
                helperText={
                  touched?.question?.[questionIndex]?.answer?.[index]?.answer &&
                  errors?.question?.[questionIndex]?.answer?.[index]?.answer
                }
                onBlur={formik.handleBlur}
                disabled={disabled}
                inputProps={{ maxLength: INPUT_MAX_LENGTH_255 }}
              />
            </QuestionTitle>
            <QuestionTitle variants="type">
              <QuestionToolbar>
                <RadioGroup row>
                  <FormControlLabelStyled
                    control={
                      <NASSRadio
                        value={`Option[${index}]`}
                        checked={answers[index].isTrue}
                        onChange={handleRadioChange(index)}
                        disabled={disabled}
                      />
                    }
                    label="Set the right answer"
                  />
                  <Box width={100} />
                </RadioGroup>
                <RemoveIcon onClick={handleRemoveOption(index)} disabled={answers.length === 1 || disabled}>
                  <img src={CrossIconImage} alt="CrossIcon" />
                </RemoveIcon>
              </QuestionToolbar>
            </QuestionTitle>
          </QuestionRow>
        );
      })}
      <QuestionRow variants="body">
        <QuestionTitle variants="title">
          <FormTextField
            inputRef={(e) => (optionRefs.current[answers.length] = e!)}
            variant="outlined"
            fullWidth
            placeholder={`Add Option`}
            inputProps={{ readOnly: true, maxLength: MAX_LENGTH_255 }}
            onClick={handleAddNewOption}
            disabled={disabled}
            style={{ pointerEvents: disabled ? 'none' : 'auto' }}
          />
        </QuestionTitle>
        <QuestionTitle variants="type" />
      </QuestionRow>
      <QuestionPoint>
        <QuestionTitle variants="title" />
        <QuestionTitle variants="type">
          <QuestionTitle variants="title" required>
            Point
          </QuestionTitle>
          <FormTextField
            disabled={disabled}
            onChange={handlePointChange(`question[${questionIndex}].point`)}
            variant="outlined"
            fullWidth
            InputProps={{ inputProps: { min: 0 } }}
            placeholder="0"
            name={`question[${questionIndex}].point`}
            value={formik.values.question?.[questionIndex]?.point}
            error={touched?.question?.[questionIndex]?.point && !!errors?.question?.[questionIndex]?.point}
            helperText={touched?.question?.[questionIndex]?.point && errors?.question?.[questionIndex]?.point}
            onBlur={formik.handleBlur}
          />
        </QuestionTitle>
      </QuestionPoint>
    </SingleChoiceContainer>
  );
};

export default SingleChoice;
