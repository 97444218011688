import moment from 'moment';

import httpClient from 'src/configs/http-client';
import { FORMAT_DATE_DMY } from 'src/constants/common';
import { IResponse, IResponseList } from 'src/types';
import { convertURL } from 'src/utils/common';

import { ICms, IPostCms, ICmsHomepage, ISearchCMSHomepage, IParams } from './cms.interface';

export const getCMSData = async (value: string) => {
  const response = await httpClient.get<IResponse<ICms>>(`${process.env.REACT_APP_API_URL}/config-pages?type=${value}`);
  return response?.data;
};
export const postCMSData = async (body: IPostCms) => {
  const response = await httpClient.post<IResponse<ICms>>(`${process.env.REACT_APP_API_URL}/config-pages?`, body);
  return response?.data;
};
export const getCMSHomepage = async ({
  search,
  page,
  limit,
}: {
  search?: ISearchCMSHomepage;
  page?: number;
  limit?: number;
}) => {
  const response = await httpClient.get<IResponseList<ICmsHomepage>>(
    `${process.env.REACT_APP_API_URL}/config-pages/home-page?page=${page}&limit=${limit}&type=${
      search?.type ? search?.type : ''
    }&title=${search?.title ? convertURL(search.title) : ''}&isFeatureItem=${
      search?.isFeatureItem ? search?.isFeatureItem : ''
    }&startDate=${search?.startDate ? moment(search?.startDate).format(FORMAT_DATE_DMY) : ''}&endDate=${
      search?.endDate ? moment(search?.endDate).format(FORMAT_DATE_DMY) : ''
    }`,
  );
  return response?.data;
};
export const postCMSHomepage = async (body: IParams) => {
  const response = await httpClient.post<IResponse<ICmsHomepage>>(
    `${process.env.REACT_APP_API_URL}/config-pages/home-page`,
    body,
  );
  return response?.data;
};
