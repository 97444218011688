import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IGeneralInformation {
  surName: string;
  givenName: string;
  chineseName: string;
  dateOfBirth: string;
  gender: 'male' | 'female' | null;
  emailAddress: string;
  phoneNumberCode: number;
  phoneNumber: number;
  nanyangKindergarten: {
    isCheck: boolean;
    graduateYear: string;
  };
  nanyangPrePrimary: {
    isCheck: boolean;
    graduateYear: string;
  };
  nanyangPrimary: {
    isCheck: boolean;
    graduateYear: string;
  };
  nanyangGirlHigh: {
    isCheck: boolean;
    graduateYear: string;
  };
  mailingAddress: string;
  postalCode: string;
  nationality: string;
  jobTitle: string;
  companyName: string;
  skill: {
    editorial: boolean;
    translation: boolean;
    legalAdvice: boolean;
    buildingArchitectureEstateManagement: boolean;
    artisticDesign: boolean;
    other: string;
  };
}
interface IAdditionalInformation {}
interface IChangePassword {
  currentPassword: string;
  newPassword: string;
  verifyPassword: string;
}

interface IProfileState {
  generalInformation: IGeneralInformation;
  additionalInformation: IAdditionalInformation;
  changePassword: IChangePassword;
  isEditGeneralInformation: boolean;
  isEditAdditionalInformation: boolean;
}

const initialState: IProfileState = {
  generalInformation: {
    surName: 'John Wick',
    givenName: '',
    chineseName: '',
    dateOfBirth: '',
    gender: null,
    emailAddress: '',
    phoneNumberCode: 84,
    phoneNumber: 12345678,
    nanyangKindergarten: {
      isCheck: false,
      graduateYear: '',
    },
    nanyangPrePrimary: {
      isCheck: false,
      graduateYear: '',
    },
    nanyangPrimary: {
      isCheck: false,
      graduateYear: '',
    },
    nanyangGirlHigh: {
      isCheck: false,
      graduateYear: '',
    },
    mailingAddress: '',
    postalCode: '',
    nationality: '',
    jobTitle: '',
    companyName: '',
    skill: {
      editorial: false,
      translation: false,
      legalAdvice: false,
      buildingArchitectureEstateManagement: false,
      artisticDesign: false,
      other: '',
    },
  },
  additionalInformation: {},
  changePassword: {
    currentPassword: '',
    newPassword: '',
    verifyPassword: '',
  },
  isEditGeneralInformation: false,
  isEditAdditionalInformation: false,
};

function updateGeneralInformation(state: IProfileState, action: PayloadAction<Partial<IGeneralInformation>>) {
  state.generalInformation = { ...state.generalInformation, ...action.payload };
}
function updateAdditionalInformation(state: IProfileState, action: PayloadAction<Partial<any>>) {
  state.additionalInformation = { ...state.additionalInformation, ...action.payload };
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: { updateGeneralInformation, updateAdditionalInformation },
});

export const profileActions = profileSlice.actions;
export const profileReducer = profileSlice.reducer;
