import React, { useState } from 'react';

import { Box, FormControl, InputAdornment, MenuItem, Select, Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import moment from 'moment';
import styled, { css } from 'styled-components';

import { CustomButton, InputField } from 'src/components';
import { DATE_PICKER_DISPLAY_FORMAT, PLACEHOLDER_DATE_PICKER } from 'src/constants/common';
import { EventCategory, EventSearchHomePage } from 'src/constants/events.constant';
import CalenderURL from 'src/medias/icons/calendar-icon.svg';
import SearchIconURL from 'src/medias/icons/search.png';

import { ImageStyled } from '../donation/types';
import { FormTextField, GeneralInformationDatePicker } from '../profile/components/general-information.styled';
import ProfilePopupLayout from '../profile/layout/profile-popup.layout';

function Search({ responsive, isLoading }: { responsive: any; isLoading?: boolean }) {
  const MAX_LENGTH_128 = 128;
  const formik = useFormikContext<any>();
  const [isOpenSearchPopup, setOpenSearchPopup] = useState(false);
  const handleToggleSearchPopup = () => setOpenSearchPopup(!isOpenSearchPopup);

  const handleSubmit = () => {
    formik.submitForm();
    if (!Object.keys(formik.errors).length) {
      handleToggleSearchPopup();
    }
  };

  const handleDropdownChange = (name: string) => (e: any) => {
    if (name === 'category') {
      formik.setFieldValue('category', e.target.value);
    }
    if (name === 'requireVolunteer') {
      formik.setFieldValue('requireVolunteer', e.target.value);
    }
  };
  const handleDateChange = (name: string) => (date: any, value?: string | null | undefined) => {
    formik.setFieldValue(name, date);
  };

  const handleOpenMobileSearch = () => {
    handleToggleSearchPopup();
  };

  return responsive.isTabletOrMobile ? (
    <>
      <Box my={1} onClick={handleOpenMobileSearch}>
        <FormTextField
          name="search"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          variant="outlined"
          fullWidth
          error={formik.touched.search && !!formik.errors.search}
          helperText={formik.touched.search && formik.errors.search}
          disabled={false}
          placeholder="Search Events"
          value={formik.values.search}
          InputProps={{
            readOnly: true,
            inputProps: {
              maxLength: 0,
            },
            startAdornment: (
              <InputAdornment position="start">
                <ImageStyled src={SearchIconURL} />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <ProfilePopupLayout open={isOpenSearchPopup} onClose={handleToggleSearchPopup} notExpanded>
        <SearchBox
          responsive={responsive}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="flex-start"
          p={2}
        >
          <HeaderSearch>Search Events</HeaderSearch>
          <SearchArea display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
            <TitleSearch>Keyword</TitleSearch>
            <InputFieldCustom
              hiddenLabel
              fullWidth
              type="text"
              name="search"
              value={formik.values.search}
              onChange={formik.handleChange}
              autoComplete="off"
              placeholder="Enter at least 3 character"
              onBlur={formik.handleBlur}
              error={formik.touched.search && !!formik.errors.search}
              errorMessage={formik.touched.search && formik.errors.search}
              InputProps={{
                inputProps: {
                  maxLength: MAX_LENGTH_128,
                },
              }}
            />
            <FormControl fullWidth>
              <SelectStyled
                onChange={handleDropdownChange('category')}
                defaultValue="all"
                variant="outlined"
                name="category"
              >
                <MenuItem value="all">All Category</MenuItem>
                {EventCategory.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </SelectStyled>
              <SelectStyled
                defaultValue="all"
                variant="outlined"
                onChange={handleDropdownChange('requireVolunteer')}
                name="requireVolunteer"
              >
                <MenuItem value="all">All Event</MenuItem>
                {EventSearchHomePage.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </SelectStyled>
            </FormControl>
            <Box mt={2.5} />
            <TitleSearch mb={12}>From Date</TitleSearch>
            <GeneralInformationDatePicker
              name="fromDate"
              fullWidth
              inputVariant="outlined"
              format={DATE_PICKER_DISPLAY_FORMAT}
              value={formik.values.fromDate ? formik.values.fromDate : null}
              InputAdornmentProps={{ position: 'end' }}
              invalidDateMessage=""
              placeholder={PLACEHOLDER_DATE_PICKER}
              InputProps={{ readOnly: true }}
              error={formik.touched.fromDate && !!formik.errors.fromDate}
              helperText={formik.touched.fromDate && formik.errors.fromDate}
              keyboardIcon={<img src={CalenderURL} alt="Calender" />}
              onChange={handleDateChange('fromDate')}
              variant="inline"
              autoOk
              inputFontSize={13}
              maxDate={moment(formik.values.toDate)}
            />
            <Box mt={2.5} />
            <TitleSearch mb={12}>To Date</TitleSearch>
            <GeneralInformationDatePicker
              name="toDate"
              fullWidth
              inputVariant="outlined"
              format={DATE_PICKER_DISPLAY_FORMAT}
              value={formik.values.toDate ? formik.values.toDate : null}
              InputAdornmentProps={{ position: 'end' }}
              invalidDateMessage=""
              placeholder={PLACEHOLDER_DATE_PICKER}
              InputProps={{ readOnly: true }}
              error={formik.touched.toDate && !!formik.errors.toDate}
              helperText={formik.touched.toDate && formik.errors.toDate}
              keyboardIcon={<img src={CalenderURL} alt="Calender" />}
              onChange={handleDateChange('toDate')}
              minDate={moment(formik.values.fromDate)}
              variant="inline"
              autoOk
              inputFontSize={13}
            />
          </SearchArea>
          <Box mt={4} />
          <CustomButton
            style={{ width: 265, height: 43, boxSizing: 'border-box' }}
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Submit
          </CustomButton>
        </SearchBox>
      </ProfilePopupLayout>
    </>
  ) : (
    <SearchBox display="flex" flexDirection="column" mr={8} alignItems="center" justifyContent="flex-start" p={2}>
      <HeaderSearch>Search Events</HeaderSearch>
      <SearchArea display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
        <TitleSearch>Keyword</TitleSearch>
        <InputFieldCustom
          hiddenLabel
          fullWidth
          type="text"
          name="search"
          onChange={formik.handleChange}
          autoComplete="off"
          placeholder="Enter at least 3 character"
          onBlur={formik.handleBlur}
          error={formik.touched.search && !!formik.errors.search}
          errorMessage={formik.touched.search && formik.errors.search}
          InputProps={{
            inputProps: {
              maxLength: MAX_LENGTH_128,
            },
          }}
        />

        <FormControl fullWidth>
          <SelectStyled
            onChange={handleDropdownChange('category')}
            defaultValue="all"
            variant="outlined"
            name="category"
          >
            <MenuItem value="all">All Category</MenuItem>
            {EventCategory.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
          </SelectStyled>
          <SelectStyled
            defaultValue="all"
            variant="outlined"
            onChange={handleDropdownChange('requireVolunteer')}
            name="requireVolunteer"
          >
            <MenuItem value="all">All Event</MenuItem>

            {EventSearchHomePage.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
          </SelectStyled>
        </FormControl>
        <Box mt={2.5} />
        <TitleSearch mb={12}>From Date</TitleSearch>
        <GeneralInformationDatePicker
          name="fromDate"
          fullWidth
          inputVariant="outlined"
          format={DATE_PICKER_DISPLAY_FORMAT}
          value={formik.values.fromDate ? formik.values.fromDate : null}
          InputAdornmentProps={{ position: 'end' }}
          invalidDateMessage=""
          placeholder={PLACEHOLDER_DATE_PICKER}
          error={formik.touched.fromDate && !!formik.errors.fromDate}
          helperText={formik.touched.fromDate && formik.errors.fromDate}
          keyboardIcon={<img src={CalenderURL} alt="Calender" />}
          onChange={handleDateChange('fromDate')}
          variant="inline"
          autoOk
          inputFontSize={13}
          maxDate={moment(formik.values.toDate)}
        />
        <Box mt={2.5} />
        <TitleSearch mb={12}>To Date</TitleSearch>
        <GeneralInformationDatePicker
          name="toDate"
          fullWidth
          inputVariant="outlined"
          format={DATE_PICKER_DISPLAY_FORMAT}
          value={formik.values.toDate ? formik.values.toDate : null}
          InputAdornmentProps={{ position: 'end' }}
          invalidDateMessage=""
          placeholder={PLACEHOLDER_DATE_PICKER}
          error={formik.touched.toDate && !!formik.errors.toDate}
          helperText={formik.touched.toDate && formik.errors.toDate}
          keyboardIcon={<img src={CalenderURL} alt="Calender" />}
          onChange={handleDateChange('toDate')}
          minDate={moment(formik.values.fromDate)}
          variant="inline"
          autoOk
          inputFontSize={13}
        />
      </SearchArea>
      <Box mt={4} />
      <CustomButton
        style={{ width: 265, height: 43, boxSizing: 'border-box' }}
        onClick={handleSubmit}
        disabled={isLoading}
      >
        Submit
      </CustomButton>
    </SearchBox>
  );
}

export default Search;
const SearchArea = styled(Box)`
  && {
    width: 100%;
  }
`;
const HeaderSearch = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
  marginBottom: '30px',
  width: '100%',
  fontWeight: 900,
}));
const TitleSearch = styled(Typography)<{ mb?: number }>(({ theme, mb }) => ({
  ...theme.fonts.textSSBold,
  fontSize: '13px',
  color: theme.palette.gray?.[600],
  marginBottom: mb ? `${mb}px` : 0,
}));

const SearchBox = styled(Box)<{ responsive?: any }>`
  ${({ responsive }) => css`
    background: white;
    && {
      width: 302px;
      box-sizing: border-box;
      border: 1px solid ${({ theme }) => theme.palette.gray?.[10]};
      border-radius: 3px;
      height: fit-content;
      position: sticky;
      top: 120px;
    }
    ${responsive?.isTabletOrMobile &&
    css`
      &&& {
        width: 100%;
        border: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    `}
  `}
`;
const InputFieldCustom = styled(InputField)`
  width: 100%;
  margin-bottom: 12px;
  margin-top: 4px;
  color: ${({ theme }) => theme.palette.gray?.[60]};
  box-sizing: border-box;
  .MuiInputBase-root {
    border: none;
    border-radius: 4px;
  }
  .MuiInputBase-input {
    box-sizing: border-box;
    padding: 10px 16px;
    height: 40px;
    font-size: 13px;
    color: ${({ theme }) => theme.palette.gray?.[1000]};
    box-sizing: border-box;
  }

  .MuiInputBase-input::placeholder {
    font-size: 13px;
    color: ${({ theme }) => theme.palette.gray?.[1000]};
    opacity: 1;
    font-weight: 400;
  }
`;

const SelectStyled = styled(Select)`
  ${({ theme }) => css`
    .MuiOutlinedInput-root {
      border: none;
    }
    .MuiSelect-root {
      box-sizing: border-box;
      height: 40px;
      font-size: 13px;
      font-weight: 400;
      color: ${theme.palette.gray?.[60]};
      background: white;
      border: none;
      box-sizing: border-box;
      padding: 7px;
      padding-left: 15px;
    }
    .MuiInputBase-input {
      border: none;
      color: ${({ theme }) => theme.palette.gray?.[1000]};
    }
    line-height: 27px;
    margin-bottom: 16px;
    border: 1px solid ${theme.palette.gray?.[60]};
    border: none;
  `}
`;
