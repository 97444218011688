import React, { useEffect, useState } from 'react';

import { Box, FormControl, FormControlLabel, makeStyles, RadioGroup } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';

import { CustomButton, InputField, NASSRadio } from 'src/components';
import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import ConfirmPopup from 'src/components/confirm';
import ErrorSnackBar from 'src/components/snackBar/error';
import { httpStatus } from 'src/configs/http-status';
import { CALL_SUCCESS } from 'src/constants/common';
import backgroundSignup from 'src/medias/images/background-signup.png';
import { getSurvey, getSurveyPermission, submitSurvey } from 'src/services/quiz';
import { IQuestionSurvey, ISurvey, ISurveyParams } from 'src/services/quiz/quiz.interface';
import { selectLoginState } from 'src/store/authentication/selector';
import useResponsive from 'src/utils/responsive';

import { CustomLabelWrap } from '../signup/components/types';

import ResponseSuccess from './components/Response';
import {
  MAXIMUM_LENGTH,
  ScorePoint,
  TextScore,
  TitleStyled,
  questionTypes,
  TEXT_REQUIRED,
  ErrorMessage,
} from './types';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundImage: `url(${backgroundSignup})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    minHeight: 890,
  },
}));

export const EventQuiz = () => {
  const classes = useStyles();
  const { isTabletOrMobile } = useResponsive();
  const { path } = useRouteMatch();
  const [loading, setLoading] = useState(false);
  const [survey, setSurvey] = useState<ISurvey | null>();
  const [params, setParams] = useState<ISurveyParams>({
    eventId: '',
    responses: [],
  });
  const [errors, setErrors] = useState({
    question: [{ questionType: '', answerText: false, answerId: false }],
  });
  const [isActionFailed, setActionFailed] = useState(false);
  const [messageResponse, setMessageResponse] = useState('');
  const [isSuccess, setSuccess] = useState(false);
  const isLogin = useSelector(selectLoginState);
  const [quizFailed, setQuizFailed] = useState({
    isFailed: false,
    message: '',
  });

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionFailed(false);
  }

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>, question: IQuestionSurvey, index: number) {
    if (e.target.name === questionTypes.singleChoice) {
      if (params.responses.find((q) => q.questionId === question.id)) {
        let newParams = params;
        newParams.responses[index].answerId = e.target.value;
        setParams(newParams);
      } else {
        let newParams = params;
        newParams.responses.push({ questionId: question.id, answerText: null, answerId: e.target.value });
        setParams(newParams);
      }
    }
    if (e.target.name === questionTypes.shortParagraph) {
      if (params.responses.find((q) => q.questionId === question.id)) {
        let newResponse = params.responses;
        newResponse[index].answerText = e.target.value;
        setParams({ ...params, responses: newResponse });
      } else {
        let newParams = params;
        newParams.responses.push({ questionId: question.id, answerText: e.target.value, answerId: null });
        setParams(newParams);
      }
    }
  }

  const paths = window.location.pathname.split('/', 4);
  const eventId = paths[2];

  const getData = async () => {
    setLoading(true);
    if (eventId) {
      const response = await getSurvey({ eventId });

      if (response && response.statusCode === CALL_SUCCESS) {
        setSurvey(response?.data);
        if (response.data) {
          let newParams: ISurveyParams = { eventId: response.data.eventId, responses: [] };
          response?.data?.question?.map((item) => {
            return newParams.responses.push({
              questionId: item.id,
              answerText: null,
              answerId: null,
              questionType: item.questionType,
            });
          });
          setParams(newParams);
        }
        let getQuestionsType: string[] = [];

        response?.data?.question?.map((q) => {
          return getQuestionsType.push(q.questionType);
        });

        if (getQuestionsType) {
          let numberErrors: { questionType: string; answerText: boolean; answerId: boolean }[] = [];
          getQuestionsType.map((type) => numberErrors.push({ questionType: type, answerText: false, answerId: false }));

          setErrors({ ...errors, question: numberErrors });
        }
      } else if (
        response.statusCode === httpStatus.StatusBadRequest ||
        response.statusCode === httpStatus.StatusForbidden
      ) {
        setQuizFailed({
          isFailed: true,
          message: response.message,
        });
      }
    }
    setLoading(false);
  };

  const checkPermission = async () => {
    const response = await getSurveyPermission({ eventId });
    if (response?.statusCode === CALL_SUCCESS) {
      getData();
    } else if (response.statusCode === httpStatus.StatusNotFound) {
      setQuizFailed({
        isFailed: true,
        message: response.message,
      });
    } else {
      if (isLogin) {
        window.location.href = '/not-access';
      }
    }
  };

  useEffect(() => {
    if (eventId) {
      checkPermission();
    }
  }, [eventId]);

  const _onSubmit = async () => {
    setLoading(true);
    let hasError = false;
    let validErrors = errors;

    // eslint-disable-next-line array-callback-return
    params?.responses.map((item, index) => {
      if (item?.questionType === questionTypes.singleChoice) {
        if (item.answerId === null) {
          validErrors.question[index].answerId = true;
          let newErrors = errors.question;
          newErrors[index].answerId = true;
          setErrors({ ...errors, question: newErrors });
          hasError = true;
        } else {
          validErrors.question[index].answerId = false;
          let newErrors = errors.question;
          newErrors[index].answerId = false;
          setErrors({ ...errors, question: newErrors });
        }
      }
      if (item?.questionType === questionTypes.shortParagraph) {
        if (item.answerText === null || item.answerText === '') {
          validErrors.question[index].answerText = true;
          let newErrors = errors.question;
          newErrors[index].answerText = true;
          setErrors({ ...errors, question: newErrors });
          hasError = true;
        } else {
          validErrors.question[index].answerText = false;
          let newErrors = errors.question;
          newErrors[index].answerText = false;
          setErrors({ ...errors, question: newErrors });
        }
      }
    });

    if (survey && !hasError) {
      const response = await submitSurvey(params);
      if (response.statusCode === CALL_SUCCESS) {
        setMessageResponse(response.message);
        setSuccess(true);
      } else {
        setActionFailed(true);
        setMessageResponse(response.message);
      }
    }
    setLoading(false);
  };

  return (
    <Box className={classes.container}>
      {isSuccess ? (
        <ResponseSuccess />
      ) : (
        <Box>
          {!isTabletOrMobile && <NSAABreadcrumbs path={path} />}
          <Box
            mt={isTabletOrMobile ? 2 : 5}
            mb={isTabletOrMobile ? 2 : 5}
            ml={isTabletOrMobile ? 2 : 10}
            mr={isTabletOrMobile ? 2 : 10}
            p={2}
            border="1px solid rgba(0, 0, 0, 0.1)"
            borderRadius={10}
            minHeight={500}
            style={{ backgroundColor: 'white' }}
          >
            <TitleStyled align="center">{survey?.title}</TitleStyled>

            {survey?.question?.map((question, index) => {
              const error =
                question.questionType === questionTypes.singleChoice
                  ? errors.question[index]?.answerId
                  : errors.question[index]?.answerText;
              return (
                <Box
                  mt={5}
                  ml={isTabletOrMobile ? '0px' : '109px'}
                  mr={isTabletOrMobile ? '0px' : '109px'}
                  display="flex"
                  flexDirection="row"
                >
                  <Box width={'80%'} display="flex" flexDirection="column" flex={1}>
                    <Box flex={1} pr={4} mt={2}>
                      <CustomLabelWrap>
                        Question {index + 1}: {question.questionText}
                      </CustomLabelWrap>
                      {error && <ErrorMessage>{TEXT_REQUIRED}</ErrorMessage>}
                    </Box>
                    <Box mt={2}>
                      {question.questionType === questionTypes.singleChoice ? (
                        <FormControl component="fieldset">
                          <RadioGroup
                            value={params.responses[index]?.answerId}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => _handleChange(e, question, index)}
                            name={questionTypes.singleChoice}
                          >
                            {question?.answer?.map((option) => {
                              return (
                                <>
                                  <FormControlLabel value={option.id} control={<NASSRadio />} label={option.answer} />
                                  <Box width={'180px'} />
                                </>
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      ) : (
                        <Box>
                          <InputField
                            value={params.responses[index]?.answerText}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => _handleChange(e, question, index)}
                            name={questionTypes.shortParagraph}
                            fullWidth
                            placeholder="Answer here"
                            InputProps={{
                              inputProps: {
                                maxLength: MAXIMUM_LENGTH,
                              },
                            }}
                          />
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box ml={3} mt={2} display="flex" width={'20%'}>
                    <TextScore display="inline">
                      Scored: <ScorePoint display="inline">{question.point}</ScorePoint> point
                    </TextScore>
                  </Box>
                </Box>
              );
            })}

            <Box
              mt={isTabletOrMobile ? 2 : 5}
              mb={isTabletOrMobile ? 2 : 5}
              display="flex"
              flex={1}
              justifyContent="center"
            >
              <CustomButton disabled={loading} onClick={_onSubmit}>
                Submit
              </CustomButton>
            </Box>
          </Box>

          {isActionFailed && (
            <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
          )}
          {quizFailed.isFailed && (
            <ConfirmPopup
              open={quizFailed.isFailed}
              title={quizFailed.message}
              content={'&nbsp;'}
              onCancel={() => {}}
              onOk={() => (window.location.href = '/')}
              okText="Go to Homepage"
              btnCancelVariant="outlined"
              btnOkVariant="filled"
              hiddenBtnCancel
              disableBackdropClick
            />
          )}
        </Box>
      )}
    </Box>
  );
};
