import React, { useMemo } from 'react';

import { Box, Button, Typography } from '@material-ui/core';
import { QueryBuilder, Room } from '@material-ui/icons';
import {
  createAlignPlugin,
  createBoldPlugin,
  createDndPlugin,
  createExitBreakPlugin,
  createFontBackgroundColorPlugin,
  createFontColorPlugin,
  createHeadingPlugin,
  createHighlightPlugin,
  createHistoryPlugin,
  createImagePlugin,
  createItalicPlugin,
  createKbdPlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createNodeIdPlugin,
  createParagraphPlugin,
  createReactPlugin,
  createSelectOnBackspacePlugin,
  createStrikethroughPlugin,
  createSubscriptPlugin,
  createSuperscriptPlugin,
  createTablePlugin,
  createTodoListPlugin,
  createUnderlinePlugin,
  ELEMENT_IMAGE,
  Plate,
  PlatePlugin,
  SPEditor,
} from '@udecode/plate';
import moment from 'moment';
import { useHistory } from 'react-router';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import styled, { css } from 'styled-components';

import Banner from 'src/components/banner';
import ShareToButton from 'src/components/button/ShareToButton';
import { editablePreviewProps, optionsExitBreakPlugin } from 'src/components/slateEditor/config/pluginOptions';
import { FORMAT_DATE_MONTH_NAME } from 'src/constants/common';
import FBLogo from 'src/medias/images/facebook-logo.png';
import TwitterLogo from 'src/medias/images/twitter-logo.png';
import { components } from 'src/pages/admin/components/Preview';
import { DateBox, DateContainer, DateContent, EventDate, EventLocation, EventName } from 'src/pages/events/EventDetail';
import { IEvent } from 'src/services/events';

interface IPreviewProps {
  event: IEvent;
  onCancel: () => void;
  onPublished: () => void;
}

type TEditor = SPEditor & ReactEditor & HistoryEditor;

const Preview = ({ event, onCancel, onPublished }: IPreviewProps) => {
  const history = useHistory();

  const UNKNOWNS_LOCATION = '';
  const pluginsMemo: PlatePlugin<TEditor>[] = useMemo(() => {
    const plugins = [
      createReactPlugin(),
      createHistoryPlugin(),
      createParagraphPlugin(),
      createTodoListPlugin(),
      createHeadingPlugin(),
      createImagePlugin(),
      createLinkPlugin(),
      createListPlugin(),
      createTablePlugin(),
      createMediaEmbedPlugin(),
      createAlignPlugin(),
      createBoldPlugin(),
      createItalicPlugin(),
      createHighlightPlugin(),
      createUnderlinePlugin(),
      createStrikethroughPlugin(),
      createSubscriptPlugin(),
      createSuperscriptPlugin(),
      createFontColorPlugin(),
      createFontBackgroundColorPlugin(),
      createKbdPlugin(),
      createNodeIdPlugin(),
      createDndPlugin(),
      createExitBreakPlugin(optionsExitBreakPlugin),
      createSelectOnBackspacePlugin({
        allow: [ELEMENT_IMAGE],
      }),
    ];

    return plugins;
  }, []);

  const isShowRegister = event.link_for_registration;

  const isShowVolunteer = event.link_for_volunteer_registration;

  return (
    <Box height="100%" display="flex" flexDirection="column" justifyItems="space-between">
      <Header>
        <Title>Event Preview</Title>
      </Header>
      <Box px={6} pt={6} flex="1" overflow="auto" my={6}>
        <Banner image={event.cover_photo!} title="" description="" />
        <DateContainer>
          <DateContent>
            <Box position="relative" pt={10}>
              <DateBox position="absolute" top={-60} left={22} width={120} height={120} borderRadius={5}>
                {moment(+event.start_time!).format('D')}
              </DateBox>
            </Box>
            <Box pl={3.5} pb={6}>
              <EventName>{event.title}</EventName>
              <EventDate>
                <QueryBuilder fontSize="small" />
                {moment(+event.start_time!).format(FORMAT_DATE_MONTH_NAME)}
              </EventDate>
              <EventLocation>
                <Room fontSize="small" /> {event.location || UNKNOWNS_LOCATION}
              </EventLocation>
            </Box>
          </DateContent>
        </DateContainer>
        <Box maxWidth={'80%'} mx={'auto'} mt={12}>
          <EventInterestedHeader>DESCRIPTION</EventInterestedHeader>
          <Plate
            id={'PREVIEW_PUBLISH'}
            plugins={pluginsMemo}
            components={components}
            editableProps={editablePreviewProps}
            value={event.description}
          />
          <EventShare>
            <Box mr={1}>Share on</Box>
            <ShareToButton disabled children={<ShareIcon src={FBLogo} />} url={history.location.pathname} facebook />
            <ShareToButton
              disabled
              children={<ShareIcon src={TwitterLogo} />}
              url={history.location.pathname}
              twitter
            />
          </EventShare>
          <EventAction>
            {isShowRegister && <EventActionButton variants="register">Register Now</EventActionButton>}
            {isShowVolunteer && <EventActionButton variants="volunteer">Volunteer Now</EventActionButton>}
          </EventAction>
        </Box>
      </Box>
      <Footer>
        <ActionButton variants="cancel" onClick={onCancel}>
          Cancel
        </ActionButton>
        <ActionButton variants="published" onClick={onPublished}>
          Publish now
        </ActionButton>
      </Footer>
    </Box>
  );
};
export default Preview;

export const Line = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: lightgray;
  position: absolute;
  bottom: 100px;
`;
export const BoxStyled = styled(Box)`
  height: 50px;
  width: 100%;
  background-color: #c5be8b;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  padding-left: 36px;
`;

export const TitleStyled = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
`;

export const ActionsStyled = styled(Box)`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 20px;
`;

export const Header = styled(Box)`
  width: 100%;
  height: 50px;
  background: #c5be8b;
  display: flex;
  align-items: center;
`;

export const Title = styled(Typography)`
  font-size: 24px;
  font-weight: 700;
  margin-left: 36px;
`;
export const Footer = styled(Box)`
  height: 72px;
  border-top: 1px solid #cfcfcf;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding: 0 50px;
`;

export const ActionButton = styled(Box)<{ variants: 'cancel' | 'published' }>`
  ${({ theme, variants }) => css`
    width: 153px;
    height: 43px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    ${variants === 'cancel' &&
    css`
      color: #718096;
      border: 1px solid #cfcfcf;
    `}
    ${variants === 'published' &&
    css`
      background-color: #0fa958;
      color: white;
    `}
  `}
`;
const EventInterestedHeader = styled(Typography)`
  ${({ theme }) => css`
    text-transform: uppercase;
    font-size: ${theme.fonts.header3.fontSize};
    font-weight: ${theme.fonts.header3.fontWeight};
    margin-bottom: ${theme.spacing(3)}px;
  `}
`;
const EventShare = styled(Box)`
  ${({ theme }) => css`
    color: #cfcfcf;
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing(3)}px;
    margin-bottom: ${theme.spacing(3)}px;
  `}
`;
export const ShareIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 9px;
  border-radius: 5px;
`;

const EventAction = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    gap: 24px;
    margin: ${theme.spacing(6)}px 0;
  `}
`;
const EventActionButton = styled(Button)<{ variants: 'register' | 'volunteer' }>`
  ${({ theme }) => ({
    ...theme.fonts.textSBold,
  })}
  ${({ theme, variants }) => css`
    border-radius: 3px;
    flex: 50%;
    background: ${variants === 'register' ? theme.palette.common.white : theme.palette.yellow?.[900]};
    outline: 1px solid ${variants === 'register' ? '#cfcfcf' : 'transparent'};
    color: ${variants === 'register' ? theme.palette.gray?.[1000] : theme.palette.common.white};
    padding: ${theme.spacing(2)}px;
    text-transform: capitalize;
    width: 100%;
    &:hover {
      background-color: ${variants === 'volunteer' ? theme.palette.yellow?.[900] : ''};
    }
  `}
`;
