import httpClient from 'src/configs/http-client';
import { IResponse, IResponseList } from 'src/types';
import { convertURL } from 'src/utils/common';

import { IEvent } from '../events';

import {
  IGTKYApplicationData,
  ISearchGTKYApplication,
  ISearchVolunteer,
  ISearchVolunteerAttendance,
  IUpdateOverViewParams,
  IVolunteer,
} from './volunteer.interface';

export const getVolunteerByEventId = async ({
  eventId,
  type,
  search,
  currentPage,
  limit,
}: {
  eventId?: string;
  type: string;
  search?: ISearchVolunteer;
  currentPage: number;
  limit: number;
}) => {
  const response = await httpClient.get<IResponseList<IVolunteer>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/volunteer-management?type=${type}&search=${
      search?.search ? convertURL(search?.search) : ''
    }&status=${search?.status ?? ''}&scanTicket=${search?.scanTicket ?? ''}&page=${currentPage}&limit=${limit}`,
  );
  return response?.data;
};

export const assignETicket = async (eventId: string, body: { ids: string[]; action: 'set' | 'remove' }) => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/assign-scan-ticket`,
    body,
  );
  return response?.data;
};

export const removeVolunteerWaitList = async (eventId: string, body: { ids: string[]; reason: string }) => {
  const response = await httpClient.delete<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/delete-volunteer`,
    { data: body },
  );
  return response?.data;
};

export const removeVolunteerConfirmedList = async (
  eventId: string,
  body: { idsToRemove: string[]; reason: string; method?: string; idsToAdd?: string[] },
) => {
  const response = await httpClient.patch<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/remove-volunteer`,
    body,
  );
  return response?.data;
};

export const updateVolunteer = async (
  eventId: string,
  body?: {
    scanTicket: string;
    attendanceStatus: string;
    checkinTime: null | string | Date;
    checkoutTime: null | string | Date;
  },
) => {
  const response = await httpClient.patch<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/users-event/${eventId}`,
    body,
  );
  return response?.data;
};

export const getVolunteerAttendance = async ({
  search,
  currentPage,
  limit,
}: {
  search?: ISearchVolunteerAttendance;
  currentPage: number;
  limit: number;
}) => {
  const response = await httpClient.get<IResponseList<IVolunteer>>(
    `${process.env.REACT_APP_API_URL}/users-event/attendance?eventName=${
      search?.eventName ? convertURL(search?.eventName) : ''
    }&volunteer=${search?.volunteer ? convertURL(search?.volunteer) : ''}&volunteerStatus=${
      search?.volunteerStatus ?? ''
    }&eventCategory=${search?.eventCategory ?? ''}&fromDate=${
      search?.fromDate ? new Date(search?.fromDate).toLocaleDateString('en-GB') : ''
    }&toDate=${
      search?.toDate ? new Date(search?.toDate).toLocaleDateString('en-GB') : ''
    }&page=${currentPage}&limit=${limit}`,
  );
  return response?.data;
};

export const addVolunteerToConfirmList = async (eventId: string, body: { idsToAdd: string[] }) => {
  const response = await httpClient.patch<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/add-volunteer`,
    body,
  );
  return response?.data;
};

export const updateVolunteerManagement = async (eventId: string, body: IUpdateOverViewParams) => {
  const response = await httpClient.patch<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/volunteer-management`,
    body,
  );
  return response?.data;
};

export const getGTKYApplication = async ({
  search,
  currentPage,
  limit,
}: {
  search?: ISearchGTKYApplication;
  currentPage: number;
  limit: number;
}) => {
  const response = await httpClient.get<IResponse<IGTKYApplicationData>>(
    `${process.env.REACT_APP_API_URL}/users-event/gtky-applicant?gtkySearch=${
      search?.gtkySearch ? convertURL(search?.gtkySearch) : ''
    }&interviewId=${search?.interviewId ?? ''}&eventId=${search?.eventId ?? ''}&page=${currentPage}&userStatus=${
      search?.userStatus
    }&limit=${limit}`,
  );
  return response?.data;
};

export const exportConfirmListCSV = async ({
  search,
  currentPage,
  limit,
  event,
}: {
  search?: ISearchVolunteer;
  currentPage: number;
  limit: number;
  event: IEvent | null;
}) => {
  const response = await httpClient.get<IResponseList<IVolunteer>>(
    `${process.env.REACT_APP_API_URL}/event/${event?.id}/export/confirmed-list?search=${
      search?.search ? convertURL(search?.search) : ''
    }&status=${search?.status ?? ''}&scanTicket=${
      search?.scanTicket ?? ''
    }&page=${currentPage}&limit=${limit}&type=confirmed-list`,
  );

  const date = new Date();

  var downloadLink = document.createElement('a');
  var blob = new Blob(['\ufeff', response.data as any], { type: 'text/csv;charset=utf-8;' });
  var url = URL.createObjectURL(blob);
  downloadLink.target = '_blank';
  downloadLink.href = url;
  downloadLink.download = `Volunteer Confirmed List_${event?.title}_${date
    .toLocaleDateString('en-GB')
    .split('/')
    .join('')}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  return true;
};

export const checkUserRegisterVolunteer = async (eventId: string) => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/event/${eventId}/volunteer-check`,
  );
  return response?.data;
};
