import React, { useEffect, useState } from 'react';

import { Box, Link, Typography } from '@material-ui/core';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Banner from 'src/components/banner';
import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import { HeaderMobile } from 'src/components/headerMobile';
import { HeaderMobileChinese } from 'src/components/headerMobileChinese';
import { CALL_SUCCESS } from 'src/constants/common';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import email from 'src/medias/icons/email-icon.png';
import location from 'src/medias/icons/location.png';
import facebook from 'src/medias/images/facebook-blue-theme.png';
import instagram from 'src/medias/images/instagram.png';
import logoItem2 from 'src/medias/images/logo-item-2.png';
import logoItem3 from 'src/medias/images/logo-item-3.png';
import logoItem4 from 'src/medias/images/logo-item-4.png';
import logoItem6 from 'src/medias/images/logo-item-6.png';
import logoItem7 from 'src/medias/images/logo-item-7.png';
// import headerLogo from 'src/medias/images/main-nsaa-logo.png';
import { getCMSData, ICms } from 'src/services/cms';
import useResponsive from 'src/utils/responsive';

import { IResponsive } from '../home/homepageStyled';

import ContactForm from './ContactForm';

const Container = styled(Box)`
  ${({ theme }) => css`
    padding: 5% 5% 5% 5%;
  `}
`;
const Header = styled(Typography)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    font-size: ${responsive?.isMobile ? theme.fonts.header3.fontSize : theme.fonts.header4S.fontSize};
    font-weight: ${theme.fonts.header4S.fontWeight};
    line-height: ${theme.fonts.header4S.lineHeight};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: ${theme.palette.gray?.[60]};
  `}
`;

const Title = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header4.fontSize};
    font-weight: ${theme.fonts.header4.fontWeight};
    line-height: ${theme.fonts.header4.lineHeight};
    color: ${theme.palette.yellow?.[1000]};

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: ${theme.spacing(2)}px;
  `}
`;
const LogoContact = styled(Box)``;
const SocialMediaLogo = styled.img`
  ${({ theme }) => css`
    height: 42px;
    width: 42px;
    margin: 0px 8px;
  `}
`;
const BoxContainer = styled(Box)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    display: flex;
    align-items: ${responsive?.isMobile ? 'center' : 'stretch'};
    justify-content: center;
    padding: 20px;
    flex-direction: ${responsive?.isMobile ? 'column' : 'row'};
    gap: 16px;
    flex-wrap: ${responsive?.isTablet ? 'wrap' : 'nowrap'};
  `}
`;

const BoxItem = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: ${theme.spacing(3)}px;
    min-width: 302px;
    max-width: 302px;
    margin: 0px ${theme.spacing(1.5)}px;
    border-radius: ${theme.spacing(1)}px;
    flex-basis: 45%;
    justify-content: center;
  `}
`;
const BoxItemSmaller = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: ${theme.spacing(2)}px;
  `}
`;
const ContentDetail = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: flex-start;
  `}
`;
const Logo = styled.img`
  ${({ theme }) => css`
    padding-right: 11px;
  `}
`;
const InfoBox = styled(Typography)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
  `}
`;
const BoldText = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.textSBold.fontSize};
    font-weight: ${theme.fonts.textSBold.fontWeight};
    line-height: ${theme.fonts.textSBold.lineHeight};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: ${theme.spacing(0.5)}px;
    padding-bottom: 5px;
  `}
`;
const Text = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.textS.fontSize};
    font-weight: ${theme.fonts.textS.fontWeight};
    line-height: ${theme.fonts.textS.lineHeight};
    color: ${theme.palette.gray?.[1000]};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    line-height: 1;
  `}
`;
const LinkText = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.textS.fontSize};
    font-weight: ${theme.fonts.textS.fontWeight};
    line-height: ${theme.fonts.textS.lineHeight};
    color: ${theme.palette.blue?.[1000]};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    line-height: 1;
  `}
`;
const Note = styled(Typography)`
  font-size: 12px;
  font-style: italic;
`;
export const ImageItem = styled.img`
  width: 230px;
`;
const ContactUs = () => {
  const history = useHistory();
  const isChinesePage = history.location.pathname.slice(0, 3) === '/cn';
  const { path } = useRouteMatch();
  const responsive = useResponsive();
  useScrollToTop();
  const [data, setData] = useState<ICms>({
    type: '',
    banner_image: '',
    banner_title: '',
    banner_description: '',
    content_title: '',
    content_body: '',
    background_image: '',
    item_image: '',
    item_date: '',
    item_image_description: '',
  });
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const response = await getCMSData('contact-us');
    if (response.statusCode === CALL_SUCCESS) {
      setData(response.data as ICms);
    }
  };
  return (
    <Box pt={responsive.isTabletOrMobile ? 15 : 3.75}>
      {responsive.isTabletOrMobile && !isChinesePage ? (
        <HeaderMobile title={`Contact Us`} />
      ) : (
        <HeaderMobileChinese title={`联络我们`} />
      )}
      {!responsive.isTabletOrMobile && <NSAABreadcrumbs path={path} />}
      <Banner
        image={data.banner_image}
        title={data.banner_title}
        description={data.banner_description}
        responsive={responsive}
      />
      <Container>
        <Header responsive={responsive}>General Contact Information</Header>
        {responsive.isDesktopOrLaptop && <Box pb={10} />}
        <BoxContainer responsive={responsive}>
          <BoxItem>
            <Title>Address</Title>
            <ContentDetail>
              <BoxItemSmaller>
                <Logo src={location} />
                <InfoBox>
                  <BoldText>Nanyang Girls' High School</BoldText>
                  <Text>2, Linden Drive Singapore 288683</Text>
                </InfoBox>
              </BoxItemSmaller>
            </ContentDetail>
          </BoxItem>
          <BoxItem>
            <Title>Email</Title>
            <ContentDetail>
              <BoxItemSmaller>
                <Logo src={email} />
                <InfoBox>
                  <BoldText>For all queries on membership matters</BoldText>
                  <LinkText>membership@nanyang.org.sg</LinkText>
                </InfoBox>
              </BoxItemSmaller>
              <BoxItemSmaller>
                <Logo src={email} />
                <InfoBox>
                  <BoldText>All other enquiries</BoldText>
                  <LinkText>enquiry@nanyang.org.sg</LinkText>
                </InfoBox>
              </BoxItemSmaller>
            </ContentDetail>
            <Note>
              If you are unable to find emails from us in your inbox, please check your spam/junk mail folder or
              Promotions tab. Please add us to your Whitelist or Safe Senders list to ensure our emails always reach
              your inbox.
            </Note>
          </BoxItem>
          <BoxItem>
            <Title>Social Media</Title>
            <LogoContact>
              <Link href="https://www.facebook.com/Nanyang-Schools-Alumni-Association-299559223422037">
                <SocialMediaLogo src={facebook} />
              </Link>
              <Link href="https://instagram.com/nanyang_family?utm_medium=copy_link">
                <SocialMediaLogo src={instagram} />
              </Link>
            </LogoContact>
          </BoxItem>
        </BoxContainer>
        <Header responsive={responsive}>Nanyang Family</Header>
        <BoxContainer responsive={responsive}>
          <BoxItem>
            <LinkItem href="https://www.nygh.edu.sg/" target="_blank" rel="noreferrer">
              <ImageItem src={logoItem2} alt="Nanyang School" />
              <Box textAlign={'center'} pt={3}>
                Nanyang Girls’ High School
              </Box>
            </LinkItem>
          </BoxItem>
          <BoxItem>
            <LinkItem href="https://nyps.moe.edu.sg/" target="_blank" rel="noreferrer">
              <ImageItem src={logoItem4} alt="Nanyang School" />
              <Box textAlign={'center'} pt={3}>
                Nanyang Primary School
              </Box>
            </LinkItem>
          </BoxItem>
          <BoxItem>
            <LinkItem href="http://www.nanyangkindergarten.com/home.html" target="_blank" rel="noreferrer">
              <ImageItem src={logoItem3} alt="Nanyang School" />
              <Box textAlign={'center'} pt={3}>
                Nanyang Kindergarten
              </Box>
            </LinkItem>
          </BoxItem>
        </BoxContainer>
        <BoxContainer responsive={responsive}>
          <BoxItem>
            <LinkItem href="https://www.facebook.com/NYGH.PSG/" target="_blank" rel="noreferrer">
              <ImageItem src={logoItem7} alt="Nanyang School" />
              <Box textAlign={'center'} pt={3}>
                NYGH Parent Support Group
              </Box>
            </LinkItem>
          </BoxItem>
          <BoxItem>
            <LinkItem href="https://www.nypspta.com" target="_blank" rel="noreferrer">
              <ImageItem src={logoItem6} alt="Nanyang School" />
              <Box textAlign={'center'} pt={3}>
                NYPS Parent-Teacher Association
              </Box>
            </LinkItem>
          </BoxItem>
        </BoxContainer>
        <ContactForm />
      </Container>
    </Box>
  );
};

export const LinkItem = styled.a`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: black;
  text-decoration: none;
`;

export default ContactUs;
