import React from 'react';

import { AppBar, Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

export interface IMenu {
  title: string;
  link: string;
  logout?: boolean;
}

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.yellow?.[900],
  height: theme.spacing(3.75),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Text = styled(Typography)<{ bold?: boolean }>`
  ${({ theme, bold = true }) => ({
    paddingRight: '80px',
    color: theme.palette.common.black,
    ...theme.fonts.textSSBold,
    fontWeight: bold ? 700 : 300,
    fontSize: 14,
  })}
  span {
    ${({ theme }) => ({
      color: theme.palette.common.black,
      ...theme.fonts.textSS,
    })}
  }
`;
export const Header = () => {
  return (
    <Box>
      <AppBarStyled>
        <Container>
          <Box pl={10} color={'black'} fontWeight={'bold'}>
            Nanyang Schools Alumni Association | 南洋中小幼校友会
          </Box>
          <Text bold={false}>
            English | <span>中文</span>
          </Text>
        </Container>
      </AppBarStyled>
    </Box>
  );
};

const AppBarStyled = styled(AppBar)`
  && {
    background: ${({ theme }) => theme.palette.common.white};
    position: fixed;
    z-index: 2;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(50% 0, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(50% 100%, farthest-side, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background: /* Shadow covers */ linear-gradient(white 30%, rgba(255, 255, 255, 0)),
      linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
      /* Shadows */ radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
      radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
  }
`;
