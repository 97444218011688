import React, { useEffect, useState } from 'react';

import { CALL_SUCCESS } from 'src/constants/common';
import { Container, Header } from 'src/pages/user-management/types';
import { getVolunteerAttendance, IVolunteer, ISearchVolunteerAttendance } from 'src/services/volunteer';
import { IMeta } from 'src/types';

import { defaultSearchVolunteer } from '../types';

import Listing from './ListingVolunteer';
import SearchVolunteer from './SearchVolunteer';

const Volunteer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<ISearchVolunteerAttendance>(defaultSearchVolunteer);
  const [volunteers, setVolunteers] = useState<IVolunteer[]>([]);
  const [data, setData] = useState<IMeta>();
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  async function getData({
    search,
    currentPage,
    limit,
  }: {
    search?: ISearchVolunteerAttendance;
    currentPage: number;
    limit: number;
  }) {
    setLoading(true);
    const response = await getVolunteerAttendance({
      search,
      currentPage,
      limit,
    });
    if (response?.statusCode === CALL_SUCCESS) {
      setVolunteers(response?.data?.items);
      setData(response?.data?.meta);
    }

    setLoading(false);
  }

  const _onSearch = (searchData: ISearchVolunteerAttendance) => () => {
    setSearch(searchData);
    getData({
      search: searchData,
      currentPage: 1,
      limit,
    });
    setCurrentPage(1);
  };

  const _onReset = async () => {
    setLoading(true);
    setSearch(defaultSearchVolunteer);
    setCurrentPage(1);
    const response = await getVolunteerAttendance({
      search: defaultSearchVolunteer,
      currentPage: 1,
      limit,
    });
    if (response?.statusCode === CALL_SUCCESS) {
      setVolunteers(response?.data?.items);
      setData(response?.data?.meta);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData({
      search,
      currentPage,
      limit,
    });
  }, [currentPage, limit]);

  return (
    <Container minHeight={500} p={4} bgcolor="white">
      <Header>Volunteer Check-in/Check-out</Header>{' '}
      <SearchVolunteer searchData={search} loading={loading} _onSearch={_onSearch} _onReset={_onReset} />
      <Listing
        volunteers={volunteers}
        data={data}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        loading={loading}
        limit={limit}
        setLimit={setLimit}
        getData={getData}
        search={search}
      />
    </Container>
  );
};

export default Volunteer;
