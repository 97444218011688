import { Box, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled(Box)`
  gap: 50px;
  margin: auto;
`;
export const EligibleStatus = styled(Box)`
  gap: 16px;
`;
export const BoxQualifiedItem = styled(Box)`
  gap: 16px;
`;
export const NotificationBox = styled(Box)`
  gap: 16px;
`;
export const NavLinkCustom = styled(NavLink)`
  text-decoration: none;
`;
export const Item = styled(Box)``;
export const ButtonGroup = styled(Box)`
  gap: 16px;
`;
export const Icon = styled.img``;
export const ImgCustom = styled.img`
  width: 85px;
`;
export const BoldText = styled(Typography)`
  ${({ theme }) => ({
    ...theme.fonts.header4,
  })}
`;
export const ItalicText = styled(Typography)`
  ${({ theme }) => ({
    ...theme.fonts.textM,
    color: theme.palette.gray?.[1000],
  })}
`;
export const Notification = styled(Typography)`
  ${({ theme }) => ({
    ...theme.fonts.header4,
  })}
`;
