import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';

import { CALL_SUCCESS } from 'src/constants/common';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { sendEmailToParent } from 'src/services/users';
import { selectLoginState } from 'src/store/authentication/selector';
import useResponsive from 'src/utils/responsive';

import ConfirmOldStep from './components/ConfirmOldStep';
import ConfirmOldStepMobile from './components/ConfirmOldStepMobile';
import ConfirmParent from './components/ConfirmParent';
import ConfirmParentMobile from './components/ConfirmParentMobile';
import { MailSendSuccess } from './components/MailSendSuccess';

const IS_OLD_ENOUGH = 'yes';

export const SignUp = () => {
  useScrollToTop();
  const { isTabletOrMobile, isTablet } = useResponsive();
  const isLogin = useSelector(selectLoginState);
  const [loading, setLoading] = useState(false);
  const [isOldEnough, setIsOldEnough] = useState<string>(IS_OLD_ENOUGH);
  const [isConfirmOld, setIsConfirmOld] = useState<boolean>(false);
  const [isConfirmParent, setIsConfirmParent] = useState<boolean>(false);

  function _onConfirmOld() {
    if (isOldEnough === IS_OLD_ENOUGH) {
      window.location.href = '/signup/member';
    } else {
      setIsConfirmOld(true);
    }
  }

  async function _onConfirmParent(parentName: string, parentEmail: string) {
    setLoading(true);
    const res = await sendEmailToParent({ parentName, parentEmail });

    if (res.statusCode === CALL_SUCCESS) {
      setLoading(false);
      setIsConfirmParent(true);
    } else {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isLogin) {
      window.location.href = '/';
    }
  }, [isLogin]);

  return (
    <Box>
      {isConfirmOld ? (
        <Box>
          <Box>
            {isConfirmParent ? (
              <MailSendSuccess
                message="An email has been sent to your parent.
                Please ask your parent to check inbox and spam"
                titleButtonSubmit={isTablet ? 'GO TO HOMEPAGE' : 'Go to Homepage'}
              />
            ) : (
              <Box>
                {isTabletOrMobile ? (
                  <ConfirmParentMobile loading={loading} _onConfirmParent={_onConfirmParent} />
                ) : (
                  <ConfirmParent loading={loading} _onConfirmParent={_onConfirmParent} />
                )}
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box>
          {isTabletOrMobile ? (
            <ConfirmOldStepMobile
              _onConfirmOld={_onConfirmOld}
              setIsOldEnough={setIsOldEnough}
              isOldEnough={isOldEnough}
            />
          ) : (
            <ConfirmOldStep _onConfirmOld={_onConfirmOld} setIsOldEnough={setIsOldEnough} isOldEnough={isOldEnough} />
          )}
        </Box>
      )}
    </Box>
  );
};
