import React, { useEffect, useRef, useState } from 'react';

import { Box, InputAdornment, RadioGroup } from '@material-ui/core';
import { Form, Formik } from 'formik';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';

import { NASSRadio } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { ACCEPT_TYPE, CALL_SUCCESS } from 'src/constants/common';
import { TICKET_TYPE } from 'src/constants/ticket.constant';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import CloseIcon from 'src/icons/close';
import UploadPictureIcon from 'src/icons/upload-picture';
import UploadImageDisabledImage from 'src/medias/icons/upload-image-disabled.png';
import {
  ActionButton,
  ErrorMessage,
  FormMenuItem,
  FormTextField,
  GeneralInformationPopupTitle,
  Label,
} from 'src/pages/profile/components/general-information.styled';
import ProfilePopupLayout from 'src/pages/profile/layout/profile-popup.layout';
import { FooterSticky, HeaderSticky } from 'src/pages/profile/shared/additional-information.popup';
import { FormControlLabelStyled } from 'src/pages/user-management/shared/user-detail.account-settings';
import { Close } from 'src/pages/user-management/shared/user-detail.component';
import { Container } from 'src/pages/user-management/types';
import { IEvent, updateTicketSettingByEventId, uploadCoverPhoto } from 'src/services/events';
import { isInputNumber } from 'src/utils/common';

import FormikPromptIfDirty from '../../shared/prompt';
import { PreviewImage, UploadText } from '../general/index.styled';
import { TextStyled } from '../volunteer/types';

interface ITicketProps {
  event: IEvent;
  setEvent: React.Dispatch<React.SetStateAction<IEvent | undefined>>;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
}

const Ticket = ({ event, setEvent, setDirty }: ITicketProps) => {
  useScrollToTop();

  const [loading, setLoading] = useState(false);
  const [isUpdating, setUpdating] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const photoRef = useRef<HTMLDivElement>();
  const [message, setMessage] = useState('');
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const TYPE1 = 1;
  const TYPE2 = 2;
  const GTKY_EVENT = 'gtky-party';
  const [openPopup, setOpenPopup] = useState(false);
  const [upImg, setUpImg] = useState<any>();
  const imgRef = useRef<any>();
  const previewCanvasRef = useRef<any>();
  const [crop, setCrop] = useState<any>({ unit: '%', width: 1000, aspect: 16 / 9 });
  const [completedCrop, setCompletedCrop] = useState<any>();
  const [disabledEdit, setDisabledEdit] = useState(true);

  const THIS_FIELD_IS_REQUIRED = 'This field is required';
  const DRAFT_STATUS = 'draft';
  const MAX_DISCOUNT_PERCENT = 100;
  const CROP_WIDTH = 1000;
  const ASPECT_RATIO = 16 / 9;
  const CROP_UNIT = '%';
  const UPLOAD_PHOTO_FORMAT_MESSAGE = 'Uploaded file’s format must be PNG, JPG, IMG and size must be less than 1MB';
  const ONE_MB_FILE_SIZE = 1024;

  const handleTogglePopup = () => setOpenPopup((p) => !p);

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };

  const handleImageLoaded = React.useCallback((img) => {
    imgRef.current = img;
  }, []);

  React.useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY,
    );
  }, [completedCrop]);

  useEffect(() => {
    setDisabledEdit(event.status !== DRAFT_STATUS || event.category === GTKY_EVENT || !event.is_public);
  }, [event]);

  const VALIDATION_SCHEMA = Yup.object({
    require_ticket: Yup.boolean().required(),
    price_per_ticket: Yup.mixed().when('require_ticket', {
      is: true,
      then: Yup.number().min(0.1, 'Price per ticket must be greater than 0').required(THIS_FIELD_IS_REQUIRED),
      otherwise: Yup.number().notRequired(),
    }),
    max_number_per_purchase: Yup.mixed().when('require_ticket', {
      is: true,
      then: Yup.number()
        .min(0.1, 'Max number of ticket per purchase must be greater than 0')
        .lessThan(+event.max_guest, `Max number of ticket per purchase must be less than ${+event.max_guest}`)
        .required(THIS_FIELD_IS_REQUIRED),
      otherwise: Yup.number().notRequired(),
    }),
    ticket_type: Yup.mixed().when('require_ticket', {
      is: true,
      then: Yup.number().oneOf([1, 2], THIS_FIELD_IS_REQUIRED).required(THIS_FIELD_IS_REQUIRED),
      otherwise: Yup.number().notRequired(),
    }),
    discount: Yup.mixed().when('ticket_type', {
      is: 2,
      then: Yup.number().min(0).max(100).required(THIS_FIELD_IS_REQUIRED),
      otherwise: Yup.number().notRequired(),
    }),
    discount_for_age: Yup.mixed().when('ticket_type', {
      is: 2,
      then: Yup.number().min(0).max(100).required(THIS_FIELD_IS_REQUIRED),
      otherwise: Yup.number().notRequired(),
    }),
    include_meal: Yup.mixed().when('require_ticket', {
      is: true,
      then: Yup.boolean().oneOf([true, false]).required(THIS_FIELD_IS_REQUIRED),
      otherwise: Yup.boolean().notRequired(),
    }),
    ticket_background: Yup.mixed().when('require_ticket', {
      is: true,
      then: Yup.string().required(THIS_FIELD_IS_REQUIRED),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const handleFormTicketSubmit = async (values: any, resetForm: (values: any) => void) => {
    let payload;

    if (values.require_ticket) {
      payload = {
        ...values,
        price_per_ticket: +values.price_per_ticket,
        discount: +values.discount,
        discount_for_age: +values.discount_for_age,
        max_number_per_purchase: +values.max_number_per_purchase,
      };
    }
    if (!values.require_ticket) {
      payload = {
        require_ticket: values.require_ticket,
        price_per_ticket: null,
        max_number_per_purchase: null,
        ticket_type: null,
        discount: null,
        discount_for_age: null,
        include_meal: false,
        ticket_background: null,
      };
    }

    setUpdating(true);
    const response = await updateTicketSettingByEventId(event.id, payload);
    if (response.statusCode === CALL_SUCCESS) {
      setMessage(response.message);
      setShowSnackBarSuccess(true);
      setEditable(false);
      setEvent((event) => ({ ...event, ...values }));
      resetForm({ values: { ...event, ...values } });
      setDirty(false);
      setUpdating(false);
    } else {
      setMessage(response.message);
      setShowSnackBarError(true);
      setDirty(false);
      setUpdating(false);
    }
  };

  return (
    <Box>
      <Container minHeight={500} bgcolor="white">
        <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
        <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
        <Formik
          initialValues={{
            require_ticket: event.require_ticket || false,
            price_per_ticket: event.price_per_ticket || '',
            max_number_per_purchase: event.max_number_per_purchase || '',
            ticket_type: event.ticket_type || 0,
            discount: event.discount || '',
            discount_for_age: event.discount_for_age || '',
            include_meal: event.include_meal || false,
            ticket_background: event.ticket_background || '',
          }}
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={(values, { resetForm }) => {
            handleFormTicketSubmit(values, resetForm);
          }}
        >
          {(formik) => {
            setDirty(formik.dirty);
            const handleUploadCroppedImage = async (canvas: any, crop: any) => {
              if (!crop || !canvas) {
                return;
              }
              canvas.toBlob(
                async (blob: any) => {
                  const formData = new FormData();
                  formData.append('image', blob, Date.now().toString() + '.png');
                  setLoading(true);
                  const response = await uploadCoverPhoto(formData);
                  if (response?.statusCode === CALL_SUCCESS) {
                    setMessage(response.message);
                    setShowSnackBarSuccess(true);
                    setLoading(false);
                    formik.setFieldValue('ticket_background', response.data?.name);
                    setOpenPopup(false);
                    setCrop({ unit: CROP_UNIT, width: CROP_WIDTH, aspect: ASPECT_RATIO });
                  } else {
                    setShowSnackBarError(true);
                    setMessage(UPLOAD_PHOTO_FORMAT_MESSAGE);
                    setLoading(false);
                  }
                },
                'image/png',
                1,
              );
            };
            const handleSaveImagePopup = () => {
              handleUploadCroppedImage(previewCanvasRef.current, completedCrop);
            };
            const handleToggleEditable = () => setEditable((edit) => !edit);
            const handleCancel = () => {
              handleToggleEditable();
              formik.resetForm();
            };
            async function handleUploadCoverPhoto(e: React.ChangeEvent<HTMLInputElement>) {
              const fileSize = e.target?.files?.[0].size;
              const type = e.target?.files?.[0].type;
              const file = Math.round(fileSize! / ONE_MB_FILE_SIZE);
              if (file > ONE_MB_FILE_SIZE || !Object.values(ACCEPT_TYPE).includes(type!)) {
                setShowSnackBarError(true);
                setMessage(UPLOAD_PHOTO_FORMAT_MESSAGE);
                e.target.value = '';
                return;
              }
              if (e.target.files && e.target.files.length > 0) {
                const reader = new FileReader();
                reader.addEventListener('load', () => setUpImg(reader.result));
                reader.readAsDataURL(e.target.files[0]);
                setOpenPopup(true);
              }
              e.target.value = '';
            }
            const handleOpenFile = () => {
              if (photoRef.current) {
                isEditable && photoRef.current.click();
              }
            };
            const handleSetInputRef = (ref: HTMLInputElement | null) => {
              photoRef.current = ref!;
            };
            const handleTicketTypeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              if (+e.target.value === TYPE1) {
                formik.setFieldValue('ticket_type', +e.target.value);
                formik.setFieldValue('discount', 0);
                formik.setFieldValue('discount_for_age', 0);
              } else {
                formik.setFieldValue('ticket_type', +e.target.value);
              }
            };
            const handleInputNumberChange =
              (name: string) => (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                if (!isInputNumber(e.target.value)) return;
                if ((name === 'max_number_per_purchase' || name === 'discount_for_age') && e.target.value.includes('.'))
                  return;
                if (+e.target.value > MAX_DISCOUNT_PERCENT && (name === 'discount' || name === 'discount_for_age')) {
                  formik.setFieldValue(name, MAX_DISCOUNT_PERCENT);
                } else {
                  formik.setFieldValue(name, e.target.value);
                }
              };
            const handleRadioButtonChange = (name: string) => (e: React.ChangeEvent<HTMLInputElement>, value: any) => {
              if (name === 'include_meal') {
                formik.setFieldValue(name, value === 'true' ? true : false);
              }
              if (name === 'require_ticket') {
                if (value === 'true') {
                  formik.setFieldValue(name, value === 'true' ? true : false);
                } else {
                  formik.setFieldValue(name, value === 'true' ? true : false);
                  formik.setFieldValue('price_per_ticket', '');
                  formik.setFieldValue('max_number_per_purchase', '');
                  formik.setFieldValue('ticket_type', 0);
                  formik.setFieldValue('include_meal', false);
                  formik.setFieldValue('discount', '');
                  formik.setFieldValue('discount_for_age', '');
                  formik.setFieldValue('ticket_background', '');
                }
              }
            };
            return (
              <Form>
                <FormikPromptIfDirty />
                <Box p={3}>
                  <Box mb={3}>
                    <Label>Does This Event Require Ticket?</Label>
                    <RadioGroup
                      name="require_ticket"
                      row
                      value={formik.values.require_ticket}
                      onChange={handleRadioButtonChange('require_ticket')}
                    >
                      <FormControlLabelStyled value={true} control={<NASSRadio />} label="Yes" disabled={!isEditable} />
                      <Box width={100} />
                      <FormControlLabelStyled value={false} control={<NASSRadio />} label="No" disabled={!isEditable} />
                    </RadioGroup>
                  </Box>
                  <Box mb={3}>
                    <Label required>Price Per Ticket (SGD)</Label>
                    <NumericFormat
                      value={formik.values.price_per_ticket}
                      variant="outlined"
                      name="price_per_ticket"
                      fullWidth
                      inputProps={{
                        step: 0.01,
                        min: 0,
                      }}
                      onChange={handleInputNumberChange('price_per_ticket')}
                      disabled={!isEditable || !formik.values.require_ticket}
                      placeholder="0.00"
                      onBlur={formik.handleBlur}
                      error={
                        formik.values.require_ticket &&
                        formik.touched.price_per_ticket &&
                        !!formik.errors.price_per_ticket
                      }
                      helperText={
                        formik.values.require_ticket &&
                        formik.touched.price_per_ticket &&
                        formik.errors.price_per_ticket
                      }
                      decimalScale={2}
                      customInput={FormTextField}
                      isAllowed={(values) => {
                        const { floatValue } = values;
                        if (floatValue) {
                          return floatValue >= 0;
                        }
                        return true;
                      }}
                    />
                  </Box>
                  <Box mb={3}>
                    <Label required>Max Number Of Ticket Per Purchase</Label>
                    <FormTextField
                      onChange={handleInputNumberChange('max_number_per_purchase')}
                      value={formik.values.max_number_per_purchase}
                      name="max_number_per_purchase"
                      variant="outlined"
                      fullWidth
                      size="small"
                      disabled={!isEditable || !formik.values.require_ticket}
                      placeholder="0"
                      inputProps={{
                        step: 1,
                        min: 0,
                      }}
                      onBlur={formik.handleBlur}
                      error={
                        formik.values.require_ticket &&
                        formik.touched.max_number_per_purchase &&
                        !!formik.errors.max_number_per_purchase
                      }
                      helperText={
                        formik.values.require_ticket &&
                        formik.touched.max_number_per_purchase &&
                        formik.errors.max_number_per_purchase
                      }
                    />
                  </Box>
                  <Box mb={3}>
                    <Label required>Ticket Type</Label>
                    <FormTextField
                      variant="outlined"
                      name="ticket_type"
                      fullWidth
                      size="small"
                      value={formik.values.ticket_type}
                      onChange={handleTicketTypeChange}
                      select
                      SelectProps={{
                        MenuProps: {
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                        },
                      }}
                      onBlur={formik.handleBlur}
                      error={formik.values.require_ticket && formik.touched.ticket_type && !!formik.errors.ticket_type}
                      helperText={
                        formik.values.require_ticket && formik.touched.ticket_type && formik.errors.ticket_type
                      }
                      disabled={!isEditable || !formik.values.require_ticket}
                    >
                      <FormMenuItem value={0}>Choose a discount type</FormMenuItem>
                      {TICKET_TYPE.map(({ value, label }, idx) => (
                        <FormMenuItem key={idx} value={value}>
                          {label}
                        </FormMenuItem>
                      ))}
                    </FormTextField>
                  </Box>

                  <Box mb={3}>
                    <Box display="flex" style={{ gap: 24 }}>
                      <Box flex="0.5" flexShrink="0">
                        <Label>%Discount</Label>
                      </Box>
                      <Box flex="0.5" flexShrink="0">
                        <Label>Discount for age above</Label>
                      </Box>
                    </Box>
                    <Box display="flex" style={{ gap: 24 }}>
                      <Box flex="0.5" flexShrink="0">
                        <FormTextField
                          variant="outlined"
                          fullWidth
                          size="small"
                          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                          value={formik.values.discount}
                          onChange={handleInputNumberChange('discount')}
                          name="discount"
                          disabled={!isEditable || formik.values.ticket_type !== TYPE2}
                          onBlur={formik.handleBlur}
                          error={formik.touched.discount && !!formik.errors.discount}
                          helperText={formik.touched.discount && formik.errors.discount}
                        />
                      </Box>
                      <Box flex="0.5" flexShrink="0">
                        <FormTextField
                          value={formik.values.discount_for_age}
                          onChange={handleInputNumberChange('discount_for_age')}
                          variant="outlined"
                          fullWidth
                          size="small"
                          name="discount_for_age"
                          disabled={!isEditable || formik.values.ticket_type !== TYPE2}
                          onBlur={formik.handleBlur}
                          error={formik.touched.discount_for_age && !!formik.errors.discount_for_age}
                          helperText={formik.touched.discount_for_age && formik.errors.discount_for_age}
                        />
                      </Box>
                    </Box>
                  </Box>
                  <Box mb={3}>
                    <Label required>Does This Event Include Dinners/Lunches?</Label>
                    <RadioGroup
                      name="include_meal"
                      row
                      onChange={handleRadioButtonChange('include_meal')}
                      value={formik.values.include_meal}
                    >
                      <FormControlLabelStyled
                        value={true}
                        control={<NASSRadio />}
                        label="Yes"
                        disabled={!isEditable || !formik.values.require_ticket}
                      />
                      <Box width={100} />
                      <FormControlLabelStyled
                        value={false}
                        control={<NASSRadio />}
                        label="No"
                        disabled={!isEditable || !formik.values.require_ticket}
                      />
                    </RadioGroup>
                    <Box mt={1}>
                      <ErrorMessage>{formik.touched.include_meal && formik.errors.include_meal}</ErrorMessage>
                    </Box>
                  </Box>
                  <Label required>Ticket Background Photo (Recommended ticket size is 586px x 330px)</Label>
                  <Box mb={3} onClick={handleOpenFile} display="inline-block">
                    <PreviewImage
                      src={formik.values.ticket_background}
                      disabled={!isEditable || !formik.values.require_ticket}
                      error={
                        !!formik.values.require_ticket &&
                        !!formik.touched.ticket_background &&
                        !!formik.errors.ticket_background
                      }
                    >
                      {formik.values.ticket_background && isEditable && (
                        <Box
                          width="100%"
                          height="100%"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          flexDirection="column"
                          position="absolute"
                          left="0"
                          bottom="0"
                          right="0"
                          top="0"
                          style={{ backgroundImage: 'linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1))' }}
                        >
                          <UploadPictureIcon />
                          <UploadText>Ticket Background Photo</UploadText>
                        </Box>
                      )}
                      {!formik.values.ticket_background && !isEditable && (
                        <>
                          <img src={UploadImageDisabledImage} alt="Upload Disable" />
                          <UploadText disabled={true}>Cover photo</UploadText>
                        </>
                      )}
                      {!formik.values.ticket_background && isEditable && (
                        <>
                          <UploadPictureIcon />
                          <UploadText disabled={false}>Cover photo</UploadText>
                        </>
                      )}
                    </PreviewImage>
                    <input
                      accept=".png, .jpg, .jpeg"
                      type="file"
                      ref={handleSetInputRef}
                      hidden
                      onChange={handleUploadCoverPhoto}
                      disabled={!formik.values.require_ticket}
                    />
                    <canvas
                      hidden
                      ref={previewCanvasRef}
                      style={{
                        width: Math.round(completedCrop?.width ?? 0),
                        height: Math.round(completedCrop?.height ?? 0),
                      }}
                    />
                    <Box mt={1}>
                      <ErrorMessage>
                        {formik.values.require_ticket &&
                          formik.touched.ticket_background &&
                          formik.errors.ticket_background}
                      </ErrorMessage>
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" alignItems="center" style={{ gap: 16 }}>
                    {isEditable ? (
                      <>
                        <ActionButton variants="outlined" onClick={handleCancel}>
                          Cancel
                        </ActionButton>
                        <ActionButton variants="yellow" type="submit" disabled={isUpdating}>
                          Save
                        </ActionButton>
                      </>
                    ) : (
                      <ActionButton variants="yellow" onClick={handleToggleEditable} disabled={disabledEdit}>
                        Edit
                      </ActionButton>
                    )}
                  </Box>
                </Box>
                {openPopup && (
                  <ProfilePopupLayout onClose={handleTogglePopup} open={openPopup}>
                    <Box position="relative">
                      <HeaderSticky>
                        <Box px={4} display="flex" justifyContent="space-between" width="100%">
                          <GeneralInformationPopupTitle>Ticket Background Photo</GeneralInformationPopupTitle>
                          <Close onClick={handleTogglePopup}>
                            <CloseIcon fill="white" />
                          </Close>
                        </Box>
                      </HeaderSticky>
                      <Box width="100%" p={3} overflow="hidden" display="flex" justifyContent="center">
                        <ReactCrop
                          src={upImg}
                          crop={crop}
                          onImageLoaded={handleImageLoaded}
                          onChange={(c) => setCrop(c)}
                          onComplete={(c) => setCompletedCrop(c)}
                        />
                      </Box>
                      <FooterSticky>
                        <Box width="100%" display="flex" justifyContent="flex-end" style={{ gap: 16 }} px={4}>
                          <ActionButton onClick={handleTogglePopup} variants="outlined" disabled={loading}>
                            Cancel
                          </ActionButton>
                          <ActionButton
                            type="button"
                            variants="yellow"
                            disabled={loading}
                            onClick={handleSaveImagePopup}
                          >
                            Save
                          </ActionButton>
                        </Box>
                      </FooterSticky>
                    </Box>
                  </ProfilePopupLayout>
                )}
              </Form>
            );
          }}
        </Formik>
      </Container>
      <Box p={0} mt={3} display="flex" justifyContent="center">
        <TextStyled>
          Created on {event?.createdAt ? new Date(event?.createdAt).toLocaleDateString('en-GB') : ''} by{' '}
          {event?.createdBy ? event.createdBy : ''} | Last Updated on{' '}
          {event?.updatedAt ? new Date(event?.updatedAt).toLocaleDateString('en-GB') : ''} by:{' '}
          {event?.updatedBy ? event.updatedBy : ''}
        </TextStyled>
      </Box>
    </Box>
  );
};

export default Ticket;
