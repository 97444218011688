import React, { useEffect, useState } from 'react';

import { Box, Grid, makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { HeaderMobile } from 'src/components/headerMobile';
import { CALL_SUCCESS } from 'src/constants/common';
import { Header } from 'src/layouts/admin/components/header';
import background from 'src/medias/images/background-signup.png';
import { getMe } from 'src/services/users';
import { selectUserProfile } from 'src/store/authentication/selector';
import { saveProfile } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';
import useResponsive from 'src/utils/responsive';

import LeftMenu from './components/LeftMenu';
import RightContent from './components/RightContent';
import { CMSContainer, menus } from './types';

export const DashBoard: React.FC = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userProfile = useSelector(selectUserProfile);
  const [menuActive, setMenuActive] = useState<string>(menus[0]);
  const [rightType, setRightType] = useState<string | null>();
  const responsive = useResponsive();

  const useStyles = makeStyles((theme) => ({
    container: {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      borderTop: '1px solid rgba(0, 0, 0, 0.1)',
      minWidth: responsive.isTabletOrMobile ? 1440 : 'auto',
    },
    left: {
      boxShadow: '1px 1px 4px #9E9E9E',
      minWidth: 245,
    },
  }));

  const getData = async () => {
    const response = await getMe();
    if (response.statusCode === CALL_SUCCESS) {
      dispatch(saveProfile(response.data));
    }
  };
  const classes = useStyles();

  useEffect(() => {
    getData();
    setRightType(null);
  }, []);

  useEffect(() => {
    if (userProfile && userProfile.roleName !== 'secretariat-admin' && userProfile.roleName !== 'it-admin') {
      history.push('/not-access');
    }
  }, [userProfile]);

  return (
    <Box width="100%" overflow="auto">
      {responsive.isTabletOrMobile && <HeaderMobile title="CMS" userProfile={userProfile} />}
      {responsive.isDesktopOrLaptop && <Header />}
      <Box pt={responsive.isTabletOrMobile ? 15 : 3.75} />
      <Box display="flex" minHeight={894}>
        <CMSContainer responsive={responsive}>
          <Grid container wrap="nowrap">
            <Grid item xs={2} className={classes.left}>
              <LeftMenu setMenuActive={setMenuActive} menuActive={menuActive} setRightType={setRightType} />
            </Grid>
            <Grid item xs={10} className={classes.container}>
              <RightContent
                menuActive={menuActive}
                setMenuActive={setMenuActive}
                setRightType={setRightType}
                rightType={rightType}
              />
            </Grid>
          </Grid>
        </CMSContainer>
      </Box>
    </Box>
  );
};
