import fifthNewsImage from '../../medias/images/news-left-big-homepage.png';
import sixthNewsImage from '../../medias/images/news-right-big-homepage.png';
import firstNewsImg from '../../medias/images/news-small-homepage-1.png';
import secondNewsImg from '../../medias/images/news-small-homepage-2.png';
import thirdNewsImg from '../../medias/images/news-small-homepage-3.png';
import fourthNewsImg from '../../medias/images/news-small-homepage-4.png';
import firstEventImg from '../../medias/images/up-coming-event-1.png';
import secondEventImg from '../../medias/images/up-coming-event-2.png';
import thirdEventImg from '../../medias/images/up-coming-event-3.png';

export const eventsData = [
  {
    id: 1,
    title: 'Event Title',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat praesent est elementum ut eget elit donec. Mi augue ut urna vitae, quis placerat consectetur. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat praesent est elementum ut eget elit donec. Mi augue ut urna vitae, quis placerat consectetur. ',
    time: '31/12/2021',
    thumbnail: firstEventImg,
    isUpComing: true,
    labelDate: '30th, November, 2021',
  },
  {
    id: 2,
    title: 'Event Title',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat praesent est elementum ut eget elit donec. Mi augue ut urna vitae, quis placerat consectetur. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat praesent est elementum ut eget elit donec. Mi augue ut urna vitae, quis placerat consectetur. ',
    time: '31/12/2021',
    thumbnail: secondEventImg,
    isUpComing: true,
    labelDate: '2nd, December, 2021',
  },
  {
    id: 3,
    title: 'Event Title',
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat praesent est elementum ut eget elit donec. Mi augue ut urna vitae, quis placerat consectetur. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Feugiat praesent est elementum ut eget elit donec. Mi augue ut urna vitae, quis placerat consectetur. ',
    time: '31/12/2021',
    thumbnail: thirdEventImg,
    isUpComing: true,
    labelDate: '2nd, December, 2021',
  },
];

export const NewsData = [
  {
    id: 3,
    title: 'DATA PROTECTION NOTICE',
    description:
      'We, at Nanyang Schools Alumni Association, recognize the importance of the protection of the personal data of applicants...',
    time: '29/10/2021',
    thumbnail: firstNewsImg,
    isUpComing: true,
    labelDate: '30th, November, 2021',
  },
  {
    id: 4,
    title: 'News TitleNANYANG FAMILY NEWSLETTER 2021',
    description: '随着科技的进步与社会的发展，品德教育的重要性日益凸显。特选学校（SAP School）加上南洋三校的教育...',
    time: '31/12/2021',
    thumbnail: secondNewsImg,
    isUpComing: true,
    labelDate: '30th, November, 2021',
  },
  {
    id: 5,
    title: 'NOTICE FOR 2A1 ELIGIBLE ALUMNI ...',
    description: 'If you are an alumni member and your child was born in 2015, you are eligible to register under...',
    time: '31/12/2021',
    thumbnail: thirdNewsImg,
    isUpComing: true,
    labelDate: '30th, November, 2021',
  },
  {
    id: 6,
    title: 'NANYANG FAMILY NEWSLETTER 2018',
    description: 'If you are an alumni member and your child was born in 2015, you are eligible to register under...',
    time: '31/12/2021',
    thumbnail: fourthNewsImg,
    isUpComing: true,
    labelDate: '30th, November, 2021',
  },
];
export const AllNewsData = [
  {
    id: 1,
    title: 'NANYANG GIRLS’ HIGH SCHOOL BUILDING EXTENSION',
    description:
      'We, at Nanyang Schools Alumni Association, recognize the importance of the protection of the personal data of applicants...',
    time: '29/10/2021',
    thumbnail: fifthNewsImage,
    isUpComing: true,
    labelDate: '30th, November, 2021',
  },
  {
    id: 2,
    title: 'IN MEMORY OF DR LAU WAI HAR (1925-2020)',
    description:
      'We are deeply saddened by the passing of our Honorary Member and Advisor, Dr Lau Wai Har, on 29 April 2020.',
    time: '29/10/2021',
    thumbnail: sixthNewsImage,
    isUpComing: true,
    labelDate: '30th, November, 2021',
  },
  {
    id: 3,
    title: 'DATA PROTECTION NOTICE',
    description:
      'We, at Nanyang Schools Alumni Association, recognize the importance of the protection of the personal data of applicants...',
    time: '29/10/2021',
    thumbnail: firstNewsImg,
    isUpComing: true,
    labelDate: '30th, November, 2021',
  },
  {
    id: 4,
    title: 'News TitleNANYANG FAMILY NEWSLETTER 2021',
    description: '随着科技的进步与社会的发展，品德教育的重要性日益凸显。特选学校（SAP School）加上南洋三校的教育...',
    time: '31/12/2021',
    thumbnail: secondNewsImg,
    isUpComing: true,
    labelDate: '30th, November, 2021',
  },
  {
    id: 5,
    title: 'NOTICE FOR 2A1 ELIGIBLE ALUMNI ...',
    description: 'If you are an alumni member and your child was born in 2015, you are eligible to register under...',
    time: '31/12/2021',
    thumbnail: thirdNewsImg,
    isUpComing: true,
    labelDate: '30th, November, 2021',
  },
  {
    id: 6,
    title: 'NANYANG FAMILY NEWSLETTER 2018',
    description: 'If you are an alumni member and your child was born in 2015, you are eligible to register under...',
    time: '31/12/2021',
    thumbnail: fourthNewsImg,
    isUpComing: true,
    labelDate: '30th, November, 2021',
  },
];
