import React from 'react';

import { youthMembershipFAQ } from '../data';
import Membership from '../shared/membership.component';

const CNMembershipApplicant = () => {
  return <Membership data={youthMembershipFAQ} />;
};

export default CNMembershipApplicant;
