import React, { useEffect, useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import { QueryBuilder, Room } from '@material-ui/icons';
import moment from 'moment';
import { useParams, useRouteMatch } from 'react-router';
import styled, { css } from 'styled-components';

import Banner from 'src/components/banner';
import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import { HeaderMobile } from 'src/components/headerMobile';
import { FORMAT_DATE_MONTH_NAME } from 'src/constants/common';
import useFetchMe from 'src/hooks/use-fetch-me';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { getPublicEventById, IEvent } from 'src/services/events';
import useResponsive from 'src/utils/responsive';

import EventDetailContent from './ContentDetail';

export const DateContainer = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    opacity: 0.85;
    z-index: 5;
  `}
`;

export const DateContent = styled(Box)`
  ${({ theme }) => css`
    background: ${theme.palette.gray?.[200]};
    position: absolute;
    max-width: 1165px;
    width: 60%;
    height: 180px;
    margin: auto;
    top: -80px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  `}
`;

export const DateBox = styled(Box)`
  ${({ theme }) => css`
    background: ${theme.palette.yellow?.[900]};
    font-size: 54px;
    font-weight: 700;
    border-radius: 8px;
    border: 2px solid white;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;
export const EventName = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.header3.fontSize};
    font-weight: ${theme.fonts.header3.fontWeight};
    line-height: ${theme.fonts.header3.lineHeight};
    text-transform: uppercase;
  `}
`;
export const EventDate = styled(Typography)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 8px 0;
    font-size: 14px;
  `}
`;
export const EventLocation = styled(Typography)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 8px 0;
    font-size: 14px;
  `}
`;

function EventDetail() {
  useScrollToTop();
  const { path } = useRouteMatch();
  const { id } = useParams<{ id: string }>();
  const [event, setEvent] = useState<IEvent>();
  const { me } = useFetchMe();

  const UNKNOWNS_LOCATION = '';
  const responsive = useResponsive();

  useEffect(() => {
    (async () => {
      const response = await getPublicEventById(id!);
      setEvent(response.data!);
    })();
  }, []);

  if (!event) return <div />;

  return (
    <Box pt={responsive.isTabletOrMobile ? 15 : 3.75}>
      {responsive.isTabletOrMobile && <HeaderMobile title={event.title} userProfile={me} />}
      {!responsive.isTabletOrMobile && <NSAABreadcrumbs path={path} appendItems={[event?.title!]} />}
      <Banner image={event.cover_photo!} title="" description="" responsive={responsive} />
      {!responsive.isTabletOrMobile && (
        <DateContainer>
          <DateContent>
            <Box position="relative" pt={5}>
              <DateBox position="absolute" top={-90} left={22} width={120} height={120} borderRadius={5}>
                {moment(+event.start_time!).format('DD')}
              </DateBox>
            </Box>
            <Box pl={3.5} pb={6}>
              <EventName>{event.title}</EventName>
              <EventDate>
                <QueryBuilder fontSize="small" />
                {moment(+event.start_time!).format(FORMAT_DATE_MONTH_NAME)} {' - '}
                {moment(+event.end_time!).format(FORMAT_DATE_MONTH_NAME)} SGT
              </EventDate>
              <EventLocation>
                <Room fontSize="small" /> {event.location || UNKNOWNS_LOCATION}
              </EventLocation>
            </Box>
          </DateContent>
        </DateContainer>
      )}
      <EventDetailContent event={event!} />
    </Box>
  );
}

export default EventDetail;
