import React from 'react';

import { Box, DialogTitle, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { CustomDialog } from 'src/components';

const DialogTitleStyled = styled(DialogTitle)(() => ({
  '& > h2': {
    fontSize: 20,
    fontWeight: 700,
    color: 'rgba(0, 0, 0, 0.8)',
  },
  marginTop: 16,
  alignSelf: 'center',
}));

const DialogContentTextStyled = styled(Typography)(() => ({
  fontSize: 16,
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.6)',
}));

interface INotification {
  open: boolean;
  handleClose: () => void;
  content?: string;
  hiddenCancelButton?: boolean;
  onSubmit?: () => void;
}

export const Notification = ({ open, handleClose, content, hiddenCancelButton, onSubmit }: INotification) => {
  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      hiddenCancelButton={hiddenCancelButton}
      submitText="OK"
      onSubmit={onSubmit}
      isConfirmPopup={true}
    >
      <DialogTitleStyled id="form-dialog-title">Notification</DialogTitleStyled>
      <Box
        width={400}
        alignSelf="center"
        mb={3}
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <DialogContentTextStyled>
          <Typography style={{ textAlign: 'center' }}>{content}</Typography>
        </DialogContentTextStyled>
      </Box>
    </CustomDialog>
  );
};
