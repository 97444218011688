import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import Banner from 'src/components/banner';
import { HeaderMobileChinese } from 'src/components/headerMobileChinese';
import { CALL_SUCCESS } from 'src/constants/common';
import { localStorageHelper } from 'src/helpers';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { BannerMainTitle } from 'src/pages/about-us/components/nsaa';
import { getPublicEventLoggedIn, getPublicEventNonLoggedIn, IEvent } from 'src/services/events';
import { IMeta } from 'src/types';
import useResponsive from 'src/utils/responsive';

import EventList from './EventList';

function Events() {
  useScrollToTop();
  const responsive = useResponsive();

  const FIRST_PAGE = 1;
  const data = {
    type: '',
    banner_image:
      'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/1639104915-1638861042-1636026799-bZXXNe5dP569.png',
    banner_title: '活动与项目',
    banner_description: '活动与项目 活动与项目 活动与项目',
    content_title: '',
    content_body: '',
    background_image: '',
    item_image: '',
    item_date: '',
    item_image_description: '',
  };
  const [events, setEvents] = useState<IEvent[]>([]);
  const [page, setPage] = useState(FIRST_PAGE);
  const limit = 6;
  const [meta, setMeta] = useState<IMeta>();
  const isLoggedIn = localStorageHelper.isLogin();

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        const response = await getPublicEventLoggedIn({ page, limit, language: 'chinese' });
        if (response.statusCode === CALL_SUCCESS) {
          setEvents((events) => [...events, ...response.data.items]);
          setMeta((meta) => response.data.meta);
        }
      } else {
        const response = await getPublicEventNonLoggedIn({ page, limit, language: 'chinese' });
        if (response.statusCode === CALL_SUCCESS) {
          setEvents((events) => [...events, ...response.data.items]);
          setMeta((meta) => response.data.meta);
        }
      }
    })();
  }, [isLoggedIn, page]);

  const handleViewMoreEvent = () => {
    if (meta?.currentPage && meta?.totalPages && meta?.currentPage < meta?.totalPages) {
      setPage((page) => page + 1);
    }
  };
  return (
    <Box pt={responsive.isTabletOrMobile ? 15 : 3.75} mx={responsive.isTabletOrMobile ? 2 : 0}>
      <HeaderMobileChinese title={`关于校友会`} />
      <Banner
        image={data.banner_image}
        title={data.banner_title}
        description={data.banner_description}
        responsive={responsive}
      />
      <BannerMainTitle style={{ paddingTop: !responsive.isMobile ? 80 : 30 }}>活动与项目</BannerMainTitle>
      <Box display="flex" mx="auto" maxWidth={1280} flexDirection={responsive.isTabletOrMobile ? 'column' : 'row'}>
        <EventList onViewMoreEvent={handleViewMoreEvent} events={events} meta={meta!} />
      </Box>
    </Box>
  );
}

export default Events;
