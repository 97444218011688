import React, { useState } from 'react';

import { Box, Grid, Link, makeStyles } from '@material-ui/core';
import _ from 'lodash';

import { CustomButton, InputField } from 'src/components';
import LogoJSA from 'src/medias/images/main-nsaa-logo.png';
import SignUpBackground from 'src/medias/images/signup-background.jpg';
import { emailIsValid, _checkErrors } from 'src/utils/common';

import { Logo, Note, NYSImage, TitleSmall } from '../../login';

import { LinkButton, TextBold } from './types';

const MAXIMUM_USERNAME_LENGTH = 50;
const TEXT_REQUIRE = 'This field is required';
const EMAIL_TEXT_CORRECT = 'Please fill in a correct email';

const useStyles = makeStyles((theme) => ({
  left: {
    minHeight: 966,
    position: 'relative',
  },
}));

interface IConfirmParent {
  loading: boolean;
  _onConfirmParent(parentName: string, parentEmail: string): void;
}

const ConfirmParent = ({ _onConfirmParent, loading }: IConfirmParent) => {
  const classes = useStyles();
  const [data, setData] = useState({
    parentName: '',
    parentEmail: '',
  });
  const [errors, setErrors] = useState({
    parentName: false,
    parentEmail: false,
  });

  function _onClickLogin() {
    window.location.href = '/login';
  }

  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [e.target.name]: e.target.value });
    setValidate(e.target.name, e.target.value);

    if (e.target.name === 'parentEmail') {
      setValidate('parentEmail', emailIsValid(e.target.value) ? e.target.value : '');
    }
  }

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();

    setData({ ...data, [e.target.name]: valueTrimmed });
  };

  const disabled = data.parentName !== '' && data.parentEmail !== '' && !_checkErrors(errors);
  function _onSubmit() {
    if (disabled) {
      _onConfirmParent(data.parentName, data.parentEmail);
    }
  }

  return (
    <Grid container>
      <Grid item xs={6} className={classes.left}>
        <Box display="flex" width={'100%'} height={'100%'}>
          <NYSImage src={SignUpBackground} objectFitContain />
          <Link href="/">
            <Logo src={LogoJSA} />
          </Link>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          width={'100%'}
          height={'100%'}
          alignItems="center"
          justifyContent="center"
          display="flex"
          pt={'150px'}
          px={5}
          flexDirection="column"
        >
          <Box textAlign="left">
            <TitleSmall style={{ textAlign: 'center' }}>
              Thank you for your interest, but a parent/guardian’s consent is required when students and alumni under 21
              years old sign up for an account.
            </TitleSmall>
            <Box textAlign="center">
              <TextBold mt={false}>
                Please provide your parent/guardian&#39;s information below, and we will send an email to help him/her
                create an account for you.
              </TextBold>
            </Box>
            <Box mt={8} px={4}>
              <InputField
                margin="dense"
                name="parentName"
                value={data.parentName}
                onChange={_handleChange}
                onBlur={onBlurField}
                require
                title="Parent/Guardian’s Name "
                fullWidth
                InputProps={{
                  inputProps: {
                    maxLength: MAXIMUM_USERNAME_LENGTH,
                  },
                }}
                error={errors.parentName}
                errormessage={errors.parentName && TEXT_REQUIRE}
              />
            </Box>
            <Box px={4}>
              <InputField
                value={data.parentEmail}
                margin="dense"
                name="parentEmail"
                onChange={_handleChange}
                onBlur={onBlurField}
                require
                title="Parent/Guardian’s Email Address"
                fullWidth
                type="email"
                InputProps={{
                  inputProps: {
                    maxLength: MAXIMUM_USERNAME_LENGTH,
                  },
                }}
                error={errors.parentEmail}
                errormessage={
                  errors.parentEmail && data.parentEmail === ''
                    ? TEXT_REQUIRE
                    : errors.parentEmail && EMAIL_TEXT_CORRECT
                }
              />
            </Box>
            <Box mb={4} mt={4} display="flex" justifyContent="center">
              <CustomButton disabled={!disabled || loading} onClick={_onSubmit}>
                Submit
              </CustomButton>
            </Box>
            <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
              <Note>
                Already have an account?
                <LinkButton onClick={_onClickLogin} variant="text">
                  Log in
                </LinkButton>
              </Note>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ConfirmParent;
