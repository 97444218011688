import React from 'react';

import { TextFieldProps } from '@material-ui/core';

import { InputField } from './inputField';

export const CustomInputSelect = ({
  children,
  title,
  width,
  notRequired,
  error,
  errormessage,
  defaultValue,
  ...props
}: any & TextFieldProps) => {
  return (
    <InputField
      {...props}
      style={{ width: width && width }}
      require={notRequired ? false : true}
      title={title}
      fullWidth
      select
      defaultValue={defaultValue}
      SelectProps={{
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        },
      }}
      error={error}
      errormessage={error && errormessage}
    >
      {children}
    </InputField>
  );
};
