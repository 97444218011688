import { Box, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components';

import EventBackgroundSource from 'src/medias/images/background-signup.png';

export const GuestContainer = styled(Box)`
  ${({ theme }) => css`
    border-radius: 16px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 1px 1px 4px #9e9e9e;
    border-radius: 4px;
    padding: 24px;
    min-width: 1100px;
  `}
`;

export const ActionBar = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListingLabel = styled(Typography)`
  ${({ theme }) => css`
    color: #000000cc;
    font-size: ${theme.fonts.header4.fontSize};
    font-weight: ${theme.fonts.header4.fontWeight};
  `}
`;

export const GuestLabelItem = styled(Box)<{ variants?: 'name' }>`
  ${({ theme, variants }) => css`
    color: ${variants === 'name' ? theme.palette.yellow?.[900] : '#00000099'}};
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  `}
`;

export const RegistrationInformationLabel = styled(Typography)<{ mt?: string }>`
  color: #333333;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  ${({ mt }) => css`
    margin-top: ${mt ? mt : ''};
  `};
`;
export const TextLabel = styled(Typography)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: #00000099;
  text-transform: capitalize;
`;
export const TextContent = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #00000099;
  margin-left: 10px;
`;

export const StatisticLabel = styled(Typography)<{ header?: boolean }>`
  ${({ theme, header }) => css`
    color: #000000cc;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin: 24px 0;
    ${header &&
    css`
      color: white;
      margin: 0;
    `}
  `}
`;
export const StatisticContent = styled(Typography)`
  ${({ theme }) => css`
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    display: flex;
    align-items: center;
    height: 48px;
  `}
`;

export const StatisticHeader = styled(Box)`
  ${({ theme }) => css`
    background-color: ${theme.palette.yellow?.[900]};
  `}
`;

export const StatisticItem = styled(Box)<{ w?: number; pl?: number; item?: boolean; bold?: boolean }>`
  display: flex;
  height: 56px;
  align-items: center;
  padding: 0 24px;
  color: white;
  ${({ w }) => css`
    min-width: ${w}px;
  `}
  ${({ pl }) => css`
    padding-left: ${pl}px;
  `}
  ${({ item }) =>
    item &&
    css`
      color: #333333;
      background-color: white;
    `}
  ${({ bold }) =>
    bold &&
    css`
      font-weight: 700;
    `}
`;
export const StatisticHeaderLabel = styled(Typography)``;

export const StatisticContainer = styled(Box)<{ border?: boolean }>`
  ${({ theme, border }) => css`
    ${border &&
    css`
      border: thin solid ${theme.palette.yellow?.[900]};
    `}
  `}
`;

export const RegistrationStatus = styled(Typography)<{
  variants?: 'pending' | 'expired' | 'confirmed' | undefined;
}>`
  ${({ theme, variants }) => css`
    padding: 6px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    background-color: ${variants === 'pending'
      ? theme.palette.yellow?.[150]
      : variants === 'expired'
      ? theme.palette.red?.[150]
      : variants === 'confirmed'
      ? theme.palette.yellow?.[1000]
      : null};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: white;
    height: 34px;
    text-transform: capitalize;
    box-sizing: border-box;
    width: 112px;
  `};
`;

export const ButtonStatus = styled(Typography)<{
  variants?: 'delivered' | 'bounce' | undefined;
}>`
  ${({ theme, variants }) => css`
    width: 112px;
    padding: 6px;
    box-sizing: border-box;
    border-radius: 5px;
    text-align: center;
    background-color: ${variants === 'bounce'
      ? theme.palette.yellow?.[150]
      : variants === 'delivered'
      ? theme.palette.green?.[900]
      : null};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: white;
    height: 34px;
    text-transform: capitalize;
    box-sizing: border-box;
  `};
`;

export const EmailTrackingContainer = styled(Box)<{ responsive?: any }>`
  ${({ theme, responsive }) => css`
    padding: 40px 80px;
    background-image: url(${EventBackgroundSource});
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    box-sizing: border-box;
    ${responsive?.isTabletOrMobile &&
    css`
      width: 1440px;
    `}
    ${responsive?.isDesktopOrLaptop &&
    css`
      width: 100%;
    `}
  `}
`;

export const ImageIconStyled = styled.img<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    ${disabled &&
    css`
      filter: grayscale(100%);
    `}
  `}
`;
