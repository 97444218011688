import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
import styled, { css } from 'styled-components';

import CommonSlide from 'src/components/sliders';
import { BoxContent, BoxItem, Description, TextTime, ThumbnailPost, Title } from 'src/components/sliders/type';
import { CALL_SUCCESS, FORMAT_DATE_DMY } from 'src/constants/common';
import ArrowRightAltIcon from 'src/icons/arrow-right-alt';
import { NavLinkCustom } from 'src/pages/login/types';
import { getPublicPost, IPost } from 'src/services/news';

import {
  BigTitle,
  ButtonBox,
  IResponsive,
  MaxWidth1440,
  MoreBtn,
  NavLinkStyled,
  NsaaTitle,
} from '../../homepageStyled';

import NewsGroup from './NewsGroup';
import Post from './Post';

interface IHomepageNews {
  responsive: IResponsive;
}
const NsaaNewsContainer = styled(Grid)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    && {
      width: 100%;
      height: auto;
      margin: auto;
      padding: ${responsive?.isMobile ? '0px' : '0  80px 80px 80px'};
      margin-bottom: ${responsive?.isMobile ? '64px' : '0px'};
    }
  `}
`;
const PostContainer = styled(Box)<{ postLength?: boolean }>`
  ${({ theme, postLength }) => ({
    padding: postLength ? '80px 0px' : '20px',
  })}
`;
function NsaaNews({ responsive }: IHomepageNews) {
  const [postData, setPostData] = useState<IPost[] | []>([]);
  const getPublicNews = async () => {
    const DEFAULT_LIMIT = 6;
    const DEFAULT_PAGE = 1;
    const params = { page: DEFAULT_PAGE, limit: DEFAULT_LIMIT };
    const response = await getPublicPost(params);
    if (response?.statusCode === CALL_SUCCESS && response?.data) {
      setPostData(response?.data?.items);
    }
  };
  useEffect(() => {
    getPublicNews();
  }, []);
  const renderNews = (item: IPost) => {
    return (
      <BoxItem display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
        <ThumbnailPost src={item?.coverPhoto} />
        <BoxContent p={3} responsive={responsive}>
          <TextTime>{moment(+item?.publishTime!).format(FORMAT_DATE_DMY)}</TextTime>
          <NavLinkCustom to={`/news/${item.id}`}>
            <Title>{item?.title}</Title>
          </NavLinkCustom>
          <Description>{item?.shortDescription || 'No description'}</Description>
        </BoxContent>
      </BoxItem>
    );
  };
  return (
    <NsaaNewsContainer spacing={8} responsive={responsive}>
      <Box mt={!responsive.isMobile ? 4 : 0} />
      <MaxWidth1440>
        <Grid container>
          <NsaaTitle item container justifyContent="space-between" responsive={responsive}>
            <BigTitle responsive={responsive} item md={6} lg={6}>
              NSAA NEWS
            </BigTitle>
            <ButtonBox>
              <NavLinkStyled to="/news">
                <MoreBtn responsive={responsive}>MORE NEWS</MoreBtn>
              </NavLinkStyled>
              <ArrowRightAltIcon style={{ marginTop: 0 }} />
            </ButtonBox>
          </NsaaTitle>
        </Grid>
      </MaxWidth1440>

      {responsive.isTabletOrMobile ? (
        <MaxWidth1440>
          <Grid container>
            <CommonSlide data={postData} render={renderNews} />
          </Grid>
        </MaxWidth1440>
      ) : (
        <>
          <NewsGroup postData={postData?.slice(0, 2)} />
          <MaxWidth1440>
            <PostContainer postLength={true} display="flex" flexWrap="wrap" style={{ gap: 24 }}>
              {responsive.isDesktopOrLaptop && postData && postData?.length > 0
                ? postData?.slice(2)?.map((post: any) => {
                    return <Post post={post} width="20%" />;
                  })
                : null}
            </PostContainer>
          </MaxWidth1440>
        </>
      )}
    </NsaaNewsContainer>
  );
}
export default NsaaNews;
