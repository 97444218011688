import React from 'react';

import Membership from '../membership/shared/membership.component';

import { TOS } from './data';

const TermOfService = () => {
  return <Membership titleMobile="Terms Of Use" data={TOS} />;
};

export default TermOfService;
