import React from 'react';

import { Box } from '@material-ui/core';
import { useRouteMatch } from 'react-router-dom';

import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import useResponsive from 'src/utils/responsive';

import { Footer } from './components/footer';
import { Header } from './components/header';

export const MainChineseLayout: React.FC<{ isShowBreadcrumbs?: boolean }> = ({
  children,
  isShowBreadcrumbs = false,
}) => {
  const { path } = useRouteMatch();
  const { isTabletOrMobile } = useResponsive();

  return (
    <Box>
      {!isTabletOrMobile && <Header />}
      {isShowBreadcrumbs && <NSAABreadcrumbs path={path} />}
      <Box mt={isTabletOrMobile ? '0px' : '120px'} minHeight={'100vh'}>
        {children}
      </Box>
      <Footer />
    </Box>
  );
};
