/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { CustomButton } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { getEligibleCriteriaSetting, ICriteriaSetting, updateEligibleCriteriaSetting } from 'src/services/users';
import { selectUserProfile } from 'src/store/authentication/selector';
import { _checkErrors } from 'src/utils/common';

import { Container, UpdateText, ViewHistoryButton } from '../types';

import EligibilityCriteriaApplicant from './EligibilityCriteriaApplicant';
import EligibilityCriteriaYouthMember from './EligibilityCriteriaYouthMember';
import HistoryChangeModal from './HistoryChangeModal';

const EligibilityCriterialSetting = () => {
  useScrollToTop();
  const history = useHistory();
  const userProfile = useSelector(selectUserProfile);
  const [edit, setEdit] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [settings, setSettings] = useState<ICriteriaSetting>({
    createdAt: '',
    updatedAt: '',
    id: '',
    applicantSession: '',
    youngMemberSession: '',
    applicantConfig: [{ key: '', value: '' }],
    youngMemberConfig: [{ key: '', value: '' }],
  });
  const [errors, setErrors] = useState({
    applicantSession: false,
    youngMemberSession: false,
    applicantConfig: [{ key: false, value: false }],
    youngMemberConfig: [{ key: false, value: false }],
  });
  const [isActionSuccess, setActionSuccess] = useState(false);
  const [isActionFailed, setActionFailed] = useState(false);
  const [messageResponse, setMessageResponse] = useState('');
  const [isOpenHistory, setIsOpenHistory] = useState(false);

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const _onChangeEditStatus = () => {
    setEdit(!edit);
  };

  const _onCancel = () => {
    getData().then((data) => {
      setEdit(!edit);
    });
  };

  const handleModal = () => {
    setIsOpenHistory(!isOpenHistory);
  };

  const _onSave = async () => {
    setLoading(true);
    let body = settings;
    let hasErrors = false;
    body.applicantSession = Number(body.applicantSession);
    body.youngMemberSession = Number(body.youngMemberSession);

    errors.applicantConfig.map((item, index) => {
      if (item.key === true) {
        hasErrors = true;
      }
      if (item.value === true) {
        hasErrors = true;
      }
    });
    errors.youngMemberConfig.map((item, index) => {
      if (item.key === true) {
        hasErrors = true;
      }
      if (item.value === true) {
        hasErrors = true;
      }
    });

    if (!_checkErrors(errors) && !hasErrors) {
      const response = await updateEligibleCriteriaSetting(body);
      if (response.statusCode === CALL_SUCCESS) {
        setEdit(!edit);
        setActionSuccess(true);
        setMessageResponse(response.message);
      } else {
        setActionFailed(true);
        setMessageResponse(response.message);
      }
    }

    setLoading(false);
  };

  const getData = async () => {
    setLoading(true);
    const response = await getEligibleCriteriaSetting();
    if (response && response.statusCode === CALL_SUCCESS) {
      setSettings(response.data);

      if (response?.data?.applicantConfig) {
        let numberErrorsAppConfig: { key: boolean; value: boolean }[] = [];
        let numberErrorsYouthMember: { key: boolean; value: boolean }[] = [];

        const numberErrorApp = response?.data?.applicantConfig?.length;
        const numberErrorYouthMember = response?.data?.youngMemberConfig?.length;

        [...Array(numberErrorApp).keys()]?.map(() => numberErrorsAppConfig.push({ key: false, value: false }));
        [...Array(numberErrorYouthMember).keys()]?.map(() =>
          numberErrorsYouthMember.push({ key: false, value: false }),
        );

        setErrors({ ...errors, applicantConfig: numberErrorsAppConfig, youngMemberConfig: numberErrorsYouthMember });
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (userProfile && userProfile.roleName !== 'membership-admin') {
      history.push('/not-access');
    }
  }, [userProfile]);

  return (
    <Container minHeight={500} p={4} bgcolor="white">
      {settings && (
        <>
          <Box pr={'30%'}>
            <EligibilityCriteriaApplicant
              settings={settings}
              setSettings={setSettings}
              edit={edit}
              errors={errors}
              setErrors={setErrors}
            />
            <EligibilityCriteriaYouthMember
              settings={settings}
              setSettings={setSettings}
              edit={edit}
              errors={errors}
              setErrors={setErrors}
            />
          </Box>

          <Box mt={3} display="flex" flex={1} alignItems="center">
            <Box display="flex" flex={1}>
              <UpdateText>
                Last Updated on {settings?.updatedAt ? new Date(settings.updatedAt).toLocaleDateString('en-GB') : ''}{' '}
                by: {settings?.user?.surname}, {settings?.user?.givenName}
              </UpdateText>
              <ViewHistoryButton onClick={handleModal}>View history</ViewHistoryButton>
            </Box>
            <Box>
              {edit ? (
                <Box justifyContent="flex-end" display="flex">
                  <CustomButton outlineButton onClick={_onCancel}>
                    Cancel
                  </CustomButton>
                  <Box ml={2} />
                  <Box>
                    <CustomButton disabled={loading} onClick={_onSave}>
                      Save
                    </CustomButton>
                  </Box>
                </Box>
              ) : (
                <Box justifyContent="flex-end" display="flex">
                  <CustomButton disabled={loading} onClick={_onChangeEditStatus}>
                    Edit
                  </CustomButton>
                </Box>
              )}
            </Box>
          </Box>
          {isActionSuccess && (
            <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
          )}
          {isActionFailed && (
            <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
          )}
        </>
      )}
      <HistoryChangeModal open={isOpenHistory} _onCloseModal={handleModal} />
    </Container>
  );
};

export default EligibilityCriterialSetting;
