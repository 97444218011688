import React from 'react';

import { Box } from '@material-ui/core';
import styled, { css } from 'styled-components';

interface IHistoryCard {
  image: string;
  header: string;
  responsive: any;
}

const HistoryCardContainer = styled(Box)<{ image: string }>`
  ${({ theme, image }) => css`
    width: 401px;
    height: 500px;
    border-radius: 2px;
    background: url(${image});
    flex-basis: 410px:
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    border-radius: 3px; 
    align-items: flex-end;
    background-repeat: no-repeat;
    background-size: 100% cover;
    background-position: bottom left;
  `}
`;

const HistoryCard = ({ image, header, responsive }: IHistoryCard) => {
  return <HistoryCardContainer image={image} />;
};

export default HistoryCard;
