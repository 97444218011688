import httpClient from 'src/configs/http-client';
import { IResponse, IResponseList } from 'src/types';

import { IDonateNYGH, ISendMailForPayment } from './payment.interface';

import { IPaymentHistory, ISearchPayment } from '.';

export const getPaymentData = async ({
  search,
  page,
  limit,
}: {
  search?: ISearchPayment;
  page?: number;
  limit?: number;
}) => {
  const response = await httpClient.get<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/payment?payerName=${search?.payerName ? search?.payerName : ''}&id=${
      search?.id ? search?.id : ''
    }&paymentType=${search?.paymentType ? search?.paymentType : ''}&status=${
      search?.status ? search?.status : ''
    }&page=${page}&limit=${limit}`,
  );
  return response?.data;
};
export const postPaymentData = async (id: string, type: 'reverse' | 'verify') => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/payment/${id}/${type}`);
  return response?.data;
};

export const donateNSAA = async (body: { amount: number }) => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/payment/donate-nsaa`, body);
  return response?.data;
};

export const donateNYGH = async (body: IDonateNYGH) => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/payment/donate-nygh`, body);
  return response?.data;
};

export const sendMailPayment = async (body: ISendMailForPayment) => {
  const response = await httpClient.post<IResponse<any>>(`${process.env.REACT_APP_API_URL}/payment/send-mail`, body);
  return response?.data;
};

export const verifyToken = async (token: string) => {
  const response = await httpClient.patch<IResponse<any>>(`${process.env.REACT_APP_API_URL}/payment/verify-token`, {
    token,
  });
  return response?.data;
};

export const getPaymentHistory = async ({ page, limit }: { page?: number; limit?: number }) => {
  const response = await httpClient.get<IResponseList<IPaymentHistory>>(
    `${process.env.REACT_APP_API_URL}/payment/history?page=${page}&limit=${limit}`,
  );
  return response?.data;
};

export const paymentForMembership = async (body: { createNewPayment: boolean }) => {
  const response = await httpClient.post<IResponse<any>>(
    `${process.env.REACT_APP_API_URL}/payment/pay-membership`,
    body,
  );
  return response?.data;
};
