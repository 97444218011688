import React from 'react';

import { Box, Popover, Tooltip, Typography } from '@material-ui/core';

import { CustomPagination } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import NSAATable, { INSAAColumn, INSAADataSource } from 'src/components/table';
import { CALL_SUCCESS } from 'src/constants/common';
import AssignInterviewIcon from 'src/medias/icons/assign-interview-icon.svg';
import IconSuccess from 'src/medias/icons/icon-success.png';
import ViewIcon from 'src/medias/icons/view-icon.png';
import { NameStyled } from 'src/pages/event-management/components/volunteer/types';
import {
  Actions,
  CustomButtonStyled,
  IconButtonStyled,
  IconVerifyStyled,
  ImageIconStyled,
  KeyboardArrowDownIconStyled,
  MenuItemStyled,
  Title,
} from 'src/pages/user-management/types';
import { assignInterviewer, IPagination } from 'src/services/users';
import { IGTKYApplication, ISearchGTKYApplication } from 'src/services/volunteer';
import { intRegex, shallowEqual } from 'src/utils/common';

import { actions, defaultSearch } from '../types';

import AssignModal from './AssignModal';

const VIEW_DETAIL_TAB = 2;

interface ITable {
  items: IGTKYApplication[];
  data: IPagination | undefined;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  loading?: boolean;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  getData({
    search,
    currentPage,
    limit,
  }: {
    search?: ISearchGTKYApplication | undefined;
    currentPage: number;
    limit: number;
  }): Promise<void>;
  currentPage: number;
  search: ISearchGTKYApplication;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  setUser: React.Dispatch<React.SetStateAction<IGTKYApplication | undefined>>;
}

const Listing = ({
  items,
  data,
  currentPage,
  setCurrentPage,
  loading,
  limit,
  setLimit,
  getData,
  search,
  setActiveTab,
  setUser,
}: ITable) => {
  const [loadingAction, setLoadingAction] = React.useState(false);
  const [disableSearchPage, setDisableSearchPage] = React.useState(false);
  const [searchPage, setSearchPage] = React.useState('');
  const [isActionSuccess, setActionSuccess] = React.useState(false);
  const [isActionFailed, setActionFailed] = React.useState(false);
  const [openAssignModal, setOpenAssignModal] = React.useState(false);
  const [messageResponse, setMessageResponse] = React.useState('');
  const [idsSelected, setIdsSelected] = React.useState<IGTKYApplication[]>();
  const [applicationsSelected, setApplicationSelected] = React.useState<IGTKYApplication[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [idItemSelected, setIdItemSelected] = React.useState<string[]>([]);

  const open = Boolean(anchorEl);
  const id = open ? 'popover' : undefined;

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleAssignModal = () => {
    setOpenAssignModal(!openAssignModal);
    setApplicationSelected([]);
    setIdItemSelected([]);
  };

  const onSelectedItems = (ids: IGTKYApplication[]) => {
    setIdsSelected(ids);
  };
  const handleOpenViewDetail = (record: any) => () => {
    setActiveTab(VIEW_DETAIL_TAB);
    setUser(record?.id);
  };

  const handleChangePagination = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value || e.target.value === '0') {
      setSearchPage('');
      return;
    }
    if (e.target.name === 'searchPage') {
      if (data?.totalPages) {
        if (!intRegex.test(e.target.value)) {
          setDisableSearchPage(true);
        } else {
          if (Number(e.target.value) > data.totalPages) {
            setDisableSearchPage(true);
          } else {
            setDisableSearchPage(false);
            setSearchPage(e.target.value);
          }
        }
      } else {
        setDisableSearchPage(true);
      }
    }
    if (e.target.name === 'records/page') {
      setLimit(Number(e.target.value));
      setSearchPage('');
      setCurrentPage(1);
    }
  };

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimmed = e.target.value.trim();
    setSearchPage(valueTrimmed);
  };

  const _onSelectAction = (action: string) => () => {
    handleAssignModal();
    handleClose();
  };

  const _onAssignInterview = async () => {
    setLoadingAction(true);
    if (applicationsSelected && idsSelected) {
      const data = applicationsSelected?.length > 0 ? applicationsSelected : idsSelected;
      let registrationIds: string[] = [];
      data?.map((item) => {
        return registrationIds.push(item.id);
      });

      const response = await assignInterviewer({
        registrationIds,
        interviewIds: idItemSelected,
      });

      if (response?.statusCode === CALL_SUCCESS) {
        getData({ search, currentPage, limit });
        setActionSuccess(true);
        setMessageResponse(response?.message);
      } else {
        setActionFailed(true);
        setMessageResponse(response?.message);
      }
      handleAssignModal();
      setLoadingAction(false);
      handleClose();
      return;
    }
    setLoadingAction(false);
  };

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setActionSuccess(false);
    setActionFailed(false);
  }

  const onClickChangePage = () => {
    setCurrentPage(Number(searchPage));
  };

  const columns = React.useMemo(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'NSAAID',
        dataIndex: 'NSAAID',
        title: 'NSAA ID',
        width: 62,
      },
      {
        key: 'Name',
        dataIndex: 'Name',
        title: 'Name',
        width: 150,
        render: (value, record) => {
          return (
            <NameStyled>
              {(record.id?.user?.surname + ', ' + record.id?.user?.givenName)?.length > 26
                ? (record.id?.user?.surname + ', ' + record.id?.user?.givenName)?.slice(0, 26) + '...'
                : record.id?.user?.surname + ', ' + record.id?.user?.givenName}
              {record.id?.user?.verificationStatus === 'verified' && <IconVerifyStyled src={IconSuccess} />}
            </NameStyled>
          );
        },
      },
      {
        key: 'EmailAddress',
        dataIndex: 'EmailAddress',
        title: 'Email Address',
        width: 150,
        ellipsis: true,
      },
      {
        key: 'PhoneNumber',
        dataIndex: 'PhoneNumber',
        title: 'Phone number',
        width: 60,
      },
      {
        key: 'Event',
        dataIndex: 'Event',
        title: 'Event',
        width: 150,
      },
      {
        key: 'Interviewer',
        dataIndex: 'Interviewer',
        title: 'Interviewer',
        width: 280,
        render: (value, record) => {
          return (
            <Box>
              {record.id?.interview?.map((item: { user: { surname: string; givenName: string } }) => {
                return (
                  <Typography display="inline">
                    {(item?.user?.surname + ', ' + item?.user?.givenName)?.length > 26
                      ? (item?.user?.surname + ', ' + item?.user?.givenName)?.slice(0, 26) + '...'
                      : item?.user?.surname + ', ' + item?.user?.givenName}
                    {'; '}
                  </Typography>
                );
              })}
            </Box>
          );
        },
      },
      {
        key: 'Actions',
        title: '',
        dataIndex: ' Actions',
        width: 50,
        render: (value, record) => {
          return (
            <Actions>
              <Tooltip title="Assign Interviewer">
                <IconButtonStyled
                  onClick={() => {
                    setApplicationSelected([record.id]);
                    setOpenAssignModal(true);
                  }}
                >
                  <ImageIconStyled src={AssignInterviewIcon} />
                </IconButtonStyled>
              </Tooltip>
              <Tooltip title="View details">
                <IconButtonStyled onClick={handleOpenViewDetail(record)}>
                  <ImageIconStyled src={ViewIcon} />
                </IconButtonStyled>
              </Tooltip>
            </Actions>
          );
        },
      },
    ];
    return columns;
  }, []);

  const dataSource = React.useMemo(() => {
    const dataSource = items.map((item, idx) => ({
      key: idx,
      id: item,
      NSAAID: item?.user?.username,
      Name: item?.user?.surname,
      EmailAddress:
        item?.user?.emailAddress?.length > 25
          ? item?.user?.emailAddress.slice(0, 25) + '...'
          : item?.user?.emailAddress,
      PhoneNumber:
        ((item?.user?.areaCode || '') + (item?.user?.mobilePhone || ''))?.length > 8
          ? (item?.user?.areaCode || '') + ' ' + (item?.user?.mobilePhone || '').slice(0, 8) + '...'
          : (item?.user?.areaCode || '') + ' ' + (item?.user?.mobilePhone || ''),
      Event:
        item?.event?.title && item?.event?.title?.length > 55
          ? item?.event?.title.slice(0, 55) + '...'
          : item?.event?.title,
      Interviewer: item?.interview
        ? item?.interview?.map((item: { user: { surname: string; givenName: string } }) => item?.user?.surname)
        : '',
    }));
    return dataSource as unknown as INSAADataSource[];
  }, [items]);

  return (
    <Box border={'1px solid rgba(0, 0, 0, 0.1)'} borderRadius={8}>
      <Box display="flex" justifyContent="space-between" alignItems="center" m={3}>
        <Title>{shallowEqual(search, defaultSearch) ? `Listing` : `Results`}</Title>
        <Box>
          <CustomButtonStyled disabled={idsSelected && idsSelected?.length === 0} outlineButton onClick={handleClick}>
            Action
            <KeyboardArrowDownIconStyled />
          </CustomButtonStyled>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {actions?.map((action) => {
              return (
                <Box>
                  <MenuItemStyled onClick={_onSelectAction(action)} key={action} value={action}>
                    {action}
                  </MenuItemStyled>
                </Box>
              );
            })}
          </Popover>
        </Box>
      </Box>
      <Box mb={2}>
        <NSAATable
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          isShowCheckBoxes
          onSelectedItems={onSelectedItems}
        />
      </Box>
      <CustomPagination
        itemName={'applicants'}
        data={data}
        limit={limit}
        handleChangePagination={handleChangePagination}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        onClickChangePage={onClickChangePage}
        disableSearchPage={disableSearchPage || searchPage === ''}
        searchPage={searchPage}
        onBlurField={onBlurField}
      />
      {openAssignModal && (
        <AssignModal
          loading={loading || loadingAction}
          open={openAssignModal}
          _onCloseModal={handleAssignModal}
          applications={applicationsSelected?.length > 0 ? applicationsSelected : idsSelected}
          idItemSelected={idItemSelected}
          setIdItemSelected={setIdItemSelected}
          _onSubmit={_onAssignInterview}
        />
      )}

      {isActionSuccess && (
        <SuccessSnackBar message={messageResponse} open={isActionSuccess} handleClose={handleCloseSnackBar} />
      )}
      {isActionFailed && (
        <ErrorSnackBar message={messageResponse} open={isActionFailed} handleClose={handleCloseSnackBar} />
      )}
    </Box>
  );
};

export default Listing;
