import React from 'react';

interface IUploadPictureIcon {
  width?: number;
  height?: number;
}

const UploadPictureIcon = ({
  width = 32,
  height = 32,
  ...props
}: IUploadPictureIcon & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M31.994 24.45c-.146.31-.15.655-.234.98-.62 2.372-2.747 4.276-5.168 4.644-1.823.277-3.457-.148-4.923-1.24a.794.794 0 00-.518-.152H4.536C2.32 28.679.668 27.394.17 25.289a4.514 4.514 0 01-.105-1.05V6.318c0-2.54 1.813-4.41 4.358-4.418 5.853-.021 11.703-.021 17.55 0 2.541.008 4.345 1.876 4.348 4.425.003 3.575.001 7.15-.006 10.723 0 .238.077.314.295.35 2.858.478 4.927 2.616 5.322 5.491.007.049-.006.107.06.127l.002 1.436z"
        fill="#979168"
      />
      <path
        d="M1.685 18.205v-.307c0-3.825.03-7.65-.013-11.473C1.654 4.71 2.985 3.526 4.566 3.53c5.757.028 11.515.01 17.272.012 1.731 0 2.869 1.137 2.87 2.862v9.25c-.136-.037-.19-.14-.268-.213-1.361-1.358-2.72-2.717-4.08-4.077-.533-.533-.932-.533-1.463 0a5617.58 5617.58 0 00-6.443 6.474c-.158.16-.234.174-.4 0a697.89 697.89 0 00-4.258-4.294c-.516-.517-.929-.518-1.438 0a3205.817 3205.817 0 00-4.382 4.437c-.078.08-.147.17-.22.255l-.07-.032z"
        fill="#fff"
      />
      <path
        d="M19.944 27.122H4.642c-1.409 0-2.471-.77-2.85-2.055a2.802 2.802 0 01-.102-.8v-3.43a.52.52 0 01.166-.407c1.682-1.685 3.358-3.374 5.029-5.067.178-.18.257-.137.408.015 1.375 1.386 2.776 2.75 4.13 4.157.588.61 1.134.547 1.663.007 2.1-2.145 4.234-4.26 6.347-6.393.16-.162.236-.172.402 0 1.347 1.371 2.706 2.732 4.06 4.096.05.05.095.105.179.191-2.1.567-3.656 1.782-4.516 3.77-.875 2.004-.645 3.967.386 5.916z"
        fill="#fff"
      />
      <path
        d="M25.49 18.926a4.795 4.795 0 014.824 4.76c.025 2.672-2.085 4.794-4.777 4.833-2.446.034-4.846-2.004-4.843-4.74.003-2.688 2.103-4.834 4.796-4.853z"
        fill="#fff"
      />
      <path
        d="M9.915 11.058a2.205 2.205 0 012.174-2.195c1.192-.015 2.202.996 2.2 2.195a2.187 2.187 0 11-4.374-.006v.006zM26.324 22.982v3.345a.816.816 0 01-1.557.382.932.932 0 01-.077-.454v-3.163l-.067-.046c-.13.14-.252.286-.392.418-.345.326-.887.341-1.183.043-.315-.317-.304-.859.04-1.206.589-.6 1.184-1.194 1.784-1.784.394-.392.834-.414 1.24-.028.63.602 1.245 1.222 1.851 1.85.32.332.31.87.015 1.159-.311.304-.843.297-1.183-.024-.146-.143-.275-.286-.471-.492z"
        fill="#979168"
      />
    </svg>
  );
};

export default UploadPictureIcon;
