import React, { useCallback, useState } from 'react';

import { Box, Button, Tooltip } from '@material-ui/core';
import moment from 'moment';
import styled, { css } from 'styled-components';

import NSAATable, { INSAAColumn, INSAADataSource, INSAATableSearch } from 'src/components/table';
import { CALL_SUCCESS } from 'src/constants/common';
import DetailView from 'src/medias/icons/file-icon.png';
import { getPaymentHistory } from 'src/services/payment';
import { IPaymentHistory } from 'src/services/users';

import PaymentHistoryDetail from './payment-history-detail';
import { Actions, IconButtonStyled, ImageIconStyled } from './payment-history.styled';

const FORMAT_TIME = 'DD/MM/YYYY hh:mm A';
interface Props {}

const ButtonStatus = styled(Button)<{
  variants?: 'confirmed' | 'pending' | 'expired';
}>`
  ${({ theme, variants }) => css`
    width: 112px;
    height: 34px;
    box-sizing: border-box;
    border-radius: 6px;
    background-color: ${variants === 'confirmed'
      ? theme.palette.yellow?.[1000]
      : variants === 'pending'
      ? theme.palette.yellow?.[150]
      : variants === 'expired'
      ? theme.palette.red?.[150]
      : null};
    font-size: 13px;
    color: white;
    text-transform: capitalize;
    cursor: default;
    &:hover {
      background-color: ${variants === 'confirmed'
        ? theme.palette.yellow?.[1000]
        : variants === 'pending'
        ? theme.palette.yellow?.[150]
        : variants === 'expired'
        ? theme.palette.red?.[150]
        : null};
    }
  `}
`;

const PaymentHistory = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [dataDetail, setDataDetail] = useState<IPaymentHistory>();

  const _onCloseModal = () => {
    setOpen(false);
  };
  const handleClickView = (value: any) => () => {
    setDataDetail(value);
    setOpen(true);
  };
  const fetchData = useCallback(async (search: INSAATableSearch) => {
    setLoading(true);

    const response = await getPaymentHistory({ ...search });

    if (response?.statusCode === CALL_SUCCESS) {
      setLoading(false);
      return response;
    } else {
      setLoading(false);
      return {
        statusCode: 1,
        message: response?.message,
        data: {
          items: [],
          meta: {
            currentPage: 0,
            itemCount: 0,
            itemsPerPage: 0,
            totalItems: 0,
            totalPages: 0,
          },
        },
      };
    }
  }, []);
  const builderColumns = useCallback(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'No.',
        dataIndex: 'No',
        title: 'No.',
        width: 115,
        render: (value, record) => {
          return <div>{value + 1}</div>;
        },
      },
      {
        key: 'PaymentID',
        dataIndex: 'PaymentID',
        title: 'Payment ID',
        width: 115,
      },
      {
        key: 'PaymentType',
        dataIndex: 'PaymentType',
        title: 'Payment Type',
        width: 115,
      },
      {
        key: 'Amount',
        dataIndex: 'Amount',
        title: 'Amount ( SGD)',
        width: 115,
        render: (value, record) => {
          return <div>{value}.00</div>;
        },
      },
      {
        key: 'PaymentStatus',
        dataIndex: 'PaymentStatus',
        title: 'Payment Status',
        width: 115,
        render: (value, record) => {
          let variants: 'confirmed' | 'pending' | 'expired' =
            value === 'confirmed' ? 'confirmed' : value === 'pending' ? 'pending' : 'expired';
          return (
            <ButtonStatus variants={variants} disableRipple>
              {value}
            </ButtonStatus>
          );
        },
      },
      {
        key: 'LastModified',
        dataIndex: 'LastModified',
        title: 'Last Modified At',
        width: 115,
        render: (value, record) => {
          return <div>{moment(+value).format(FORMAT_TIME)}</div>;
        },
      },
      {
        key: 'Actions',
        dataIndex: 'Actions',
        title: '',
        width: 115,
        render: (value, record) => {
          return (
            <Actions>
              <Tooltip title="View details">
                <IconButtonStyled onClick={handleClickView(value)}>
                  <ImageIconStyled src={DetailView} />
                </IconButtonStyled>
              </Tooltip>
            </Actions>
          );
        },
      },
    ];
    return columns;
  }, []);
  const builderDataSource = (data: IPaymentHistory[]) => {
    const dataSource = data.map((item: IPaymentHistory, idx: number) => ({
      key: idx,
      No: idx,
      id: item.id,
      PaymentID: item.code,
      PaymentType: item.type,
      Amount: item.amount,
      PaymentStatus: item.status,
      LastModified: item.updatedAt,
      Actions: item,
    }));
    return dataSource as unknown as INSAADataSource[];
  };
  return (
    <>
      <Box mt={2} mb={2}>
        <NSAATable
          builderColumns={builderColumns}
          builderDataSource={builderDataSource}
          fetchData={fetchData}
          loading={loading}
          isShowPagination={true}
        />
      </Box>
      <PaymentHistoryDetail open={open} _onCloseModal={_onCloseModal} dataDetail={dataDetail} />
    </>
  );
};

export default PaymentHistory;
