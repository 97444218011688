import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import moment from 'moment';

import CommonSlide from 'src/components/sliders';
import { CALL_SUCCESS, FORMAT_DATE_DMY } from 'src/constants/common';
import ArrowRightAltIcon from 'src/icons/arrow-right-alt';
import CalendarIcon from 'src/icons/calendar';
import {
  BigTitle,
  ButtonBox,
  ContentBox,
  CustomImg,
  GridItem,
  IResponsive,
  LabelTime,
  MaxWidth1440,
  MoreBtn,
  NavLinkStyled,
  NsaaEventContainer,
  NsaaTitle,
  ThumbnailPost,
  TimeEvent,
  TitleEvent,
} from 'src/pages/home/homepageStyled';
import { getPublicEventNonLoggedIn, IEvent } from 'src/services/events';

import { eventsData } from '../../eventData';

interface IHomepageEvent {
  responsive: IResponsive;
}

function NsaaEvent({ responsive }: IHomepageEvent) {
  const [events, setEvents] = useState<IEvent[]>([]);
  const CHINESE_LANGUAGE = 'chinese';
  useEffect(() => {
    (async () => {
      const response = await getPublicEventNonLoggedIn({ page: 1, limit: 3, language: CHINESE_LANGUAGE });
      if (response?.statusCode === CALL_SUCCESS) {
        setEvents(response?.data?.items);
      }
    })();
  }, []);

  const renderEvents = (event: IEvent) => {
    return (
      <NavLinkStyled to={`events/${event?.id}`}>
        <Grid item key={event?.id}>
          <ThumbnailPost>
            <CustomImg src={event?.cover_photo} />
            <ContentBox mt={2} ml={2} responsive={responsive}>
              <LabelTime>
                <CalendarIcon fill="#333333" />
                <TimeEvent>{moment(+event.start_time!).format(FORMAT_DATE_DMY)}</TimeEvent>
              </LabelTime>
              <TitleEvent responsive={responsive}>
                {/* {event.title.length > 20 ? event.title.slice(0, 20) + '...' : event.title} */}
                {event.title}
              </TitleEvent>
              <NavLinkStyled to={`events/${event?.id}`}>
                <Box display="flex" justifyContent="space-between" alignContent="center" width={'40%'}>
                  <MoreBtn>更多详情</MoreBtn>
                  <ArrowRightAltIcon />
                </Box>
              </NavLinkStyled>
            </ContentBox>
          </ThumbnailPost>
        </Grid>
      </NavLinkStyled>
    );
  };
  return (
    <NsaaEventContainer responsive={responsive}>
      <MaxWidth1440>
        <Grid container>
          <NsaaTitle item container justifyContent="space-between" responsive={responsive}>
            <BigTitle item md={6} lg={6} responsive={responsive}>
              活动与项目
            </BigTitle>
            <ButtonBox>
              <NavLinkStyled to="/cn/events">
                <MoreBtn responsive={responsive}>更多活动与项目</MoreBtn>
              </NavLinkStyled>
              <ArrowRightAltIcon style={{ marginTop: 0 }} />
            </ButtonBox>
          </NsaaTitle>
        </Grid>
        <Grid container>
          <Grid item container direction={'row'} justifyContent="center" alignItems="center">
            {responsive.isDesktopOrLaptop && eventsData?.length > 0 ? (
              events?.map((post) => {
                return (
                  <GridItem item md={4} lg={4} key={post?.id}>
                    <NavLinkStyled to={`/cn/events/${post?.id}`}>
                      <ThumbnailPost>
                        <CustomImg src={post?.cover_photo} />
                        <ContentBox mt={2} mr={2}>
                          <LabelTime>
                            <CalendarIcon fill="#333333" />
                            <TimeEvent>{moment(+post.start_time).format(FORMAT_DATE_DMY)}</TimeEvent>
                          </LabelTime>
                          <TitleEvent>{post.title}</TitleEvent>
                          <NavLinkStyled to={`/cn/events/${post?.id}`}>
                            <Box display="flex" justifyContent="space-between" alignContent="center" width={'40%'}>
                              <MoreBtn>更多详情</MoreBtn>
                              <ArrowRightAltIcon />
                            </Box>
                          </NavLinkStyled>
                        </ContentBox>
                      </ThumbnailPost>
                    </NavLinkStyled>
                  </GridItem>
                );
              })
            ) : (
              <CommonSlide data={events} render={renderEvents} />
            )}
          </Grid>
        </Grid>
      </MaxWidth1440>
    </NsaaEventContainer>
  );
}

export default NsaaEvent;
