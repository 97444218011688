import React, { useState } from 'react';

import { Box } from '@material-ui/core';

import { useFetchProfileQuery, useUpdateProfileChildrenMutation } from 'src/api/endpoints/profiles.endpoint';
import SuccessSnackBar from 'src/components/snackBar/success';
import { TOAST } from 'src/constants/toasts.constant';

import AdditionalInformationForm from '../forms/additional-information.form';
import AdditionalInformationYouthForm from '../forms/additional-information.youth.form';
import ProfilePopupLayout from '../layout/profile-popup.layout';
import AdditionalInformationPopup from '../shared/additional-information.popup';

interface Props {}

const YOUTH_MEMBER = 'youth-member';

const AdditionalInformation = (props: Props) => {
  const [isOpenEditAdditionalInformation, setOpenAdditionalInformation] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);

  const { data: profile, isSuccess: isFetchingProfileSuccess, refetch } = useFetchProfileQuery();
  const [
    updateProfile,
    { isLoading: isUpdatingProfile, isSuccess: isUpdatingProfileSuccess, isError: isUpdatingProfileError },
  ] = useUpdateProfileChildrenMutation();

  React.useEffect(() => {
    refetch();
  }, []);

  React.useEffect(() => {
    if (isUpdatingProfileSuccess) {
      setShowSnackBarSuccess(true);
      setOpenAdditionalInformation(false);
    }
  }, [isUpdatingProfileSuccess, isUpdatingProfileError]);
  function handleCloseAdditionalInformation() {
    setOpenAdditionalInformation(false);
  }
  function handleOpenAdditionalInformation() {
    setOpenAdditionalInformation(true);
  }
  function handleCloseSnackBarSuccess() {
    setShowSnackBarSuccess(false);
  }
  function handleUpdateProfile(values: any) {
    updateProfile(values);
  }

  return (
    <Box padding={'32px 109px 0px'}>
      {profile?.data?.accountType === YOUTH_MEMBER ? (
        <AdditionalInformationYouthForm profile={isFetchingProfileSuccess ? profile?.data! : null} />
      ) : (
        <>
          <SuccessSnackBar
            open={isShowSnackBarSuccess}
            handleClose={handleCloseSnackBarSuccess}
            message={TOAST.UPDATE_PROFILE_SUCCESS}
          />
          <AdditionalInformationForm
            onOpenEditAdditionalInformation={handleOpenAdditionalInformation}
            profile={profile?.data || null}
          />
          <ProfilePopupLayout open={isOpenEditAdditionalInformation} onClose={handleCloseAdditionalInformation}>
            <AdditionalInformationPopup
              onSave={handleUpdateProfile}
              onClose={handleCloseAdditionalInformation}
              profile={profile?.data || null}
              isSaveLoading={isUpdatingProfile}
            />
          </ProfilePopupLayout>
        </>
      )}
    </Box>
  );
};

export default AdditionalInformation;
