import React, { useState } from 'react';

import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import { ICmsHomepage, ISearchCMSHomepage } from 'src/services/cms';

import HomepageCMSList from './HomepageCMSList/HomepageCMSList';
import SearchCMSHomepage from './SearchCMSHomepage/SearchCMSHomepage';
import { defaultSearchCMSHomepage } from './type';

const CustomBox = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 8,
  padding: 8,
}));
const HeaderGroup = styled(Box)(({ theme }) => ({
  gap: '10px',
}));
const Title = styled(Typography)(({ theme }) => ({
  ...theme.fonts.header3,
  width: '100%',
}));
function Homepage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<ISearchCMSHomepage>(defaultSearchCMSHomepage);
  const [searchValue, setSearchValue] = useState<ISearchCMSHomepage>(defaultSearchCMSHomepage);
  const [data, setData] = useState<ICmsHomepage[] | []>([]);
  const [isOnSearch, setIsOnSearch] = useState<boolean>(false);
  const _onSearch = (searchData: ISearchCMSHomepage) => () => {
    setSearch({ ...searchData });
    setIsOnSearch(true);
  };
  const _onReset = async () => {
    setSearch(defaultSearchCMSHomepage);
    setIsOnSearch(true);
  };
  return (
    <Box minHeight={500} p={4} bgcolor="white" boxShadow={'1px 1px 4px #9E9E9E'} borderRadius={4}>
      <LoadingCustom loading={loading} />
      <CustomBox>
        <HeaderGroup display="flex" justifyContent="space-between" alignItems="center">
          <Title>Feature News & Events</Title>
        </HeaderGroup>
      </CustomBox>
      <SearchCMSHomepage
        searchData={search}
        loading={loading}
        _onSearch={_onSearch}
        _onReset={_onReset}
        _setSearchValue={setSearchValue}
      />
      <HomepageCMSList
        setSearch={setSearch}
        searchData={search}
        data={data}
        setData={setData}
        loading={loading}
        setLoading={setLoading}
        searchValue={searchValue}
        isOnSearch={isOnSearch}
        _setIsOnSearch={setIsOnSearch}
      />
    </Box>
  );
}

export default Homepage;
