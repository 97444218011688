import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';
import { find } from 'lodash';

import { CustomButton } from 'src/components';
import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import { CALL_SUCCESS } from 'src/constants/common';
import { EventCategory } from 'src/constants/events.constant';
import CheckIcon from 'src/medias/icons/check-icon.svg';
import ClockIcon from 'src/medias/icons/Clock.png';
import FailIcon from 'src/medias/icons/Fail.svg';
import OutlineCheckIcon from 'src/medias/icons/outline-check.png';
import WarningIcon from 'src/medias/icons/warning-circle.png';
import { getMembershipStatus } from 'src/services/users';

import Item from './membership-components/Item';
import {
  BoldText,
  BoxQualifiedItem,
  ButtonGroup,
  Container,
  EligibleStatus,
  Icon,
  ImgCustom,
  ItalicText,
  NavLinkCustom,
  Notification,
  NotificationBox,
} from './membership-status.styled';

interface Props {}
interface IUser {
  accountType: string;
  eligibleStatus: string;
  verificationStatus: string;
}
interface IConfig {
  applicantConfig: IKeyValue[];
  applicantSession: number;
  id: string;
  updatedAt: string;
  updatedBy: string;
  user: { givenName: string; id: string; surname: string; username: string };
  youngMemberConfig: IKeyValue[];
  youngMemberSession: number;
}
interface ICountVolunteer {
  key: string;
  value: number;
}
interface IKeyValue {
  key: string;
  value: string;
}

const MembershipStatus = (props: Props) => {
  const [config, setConfig] = useState<IConfig>();
  const [user, setUser] = useState<IUser>();
  const [countVolunteer, setCountVolunteer] = useState<ICountVolunteer[]>();
  const [loading, setLoading] = useState(false);
  // const { me } = useFetchMe();
  const getData = async () => {
    setLoading(true);
    const response = await getMembershipStatus();
    if (response?.statusCode === CALL_SUCCESS && response?.data) {
      setConfig(response?.data?.config);
      setUser(response?.data?.user);
      setCountVolunteer(response?.data?.countVolunteer);
    }
    setLoading(false);
  };
  useEffect(() => {
    Promise.all([getData()]);
  }, []);

  const qualificationItemRender = () => {
    const isYoungMember = user?.accountType === 'youth-member' ? true : false;
    const findVolunteer = find(countVolunteer, (obj) => {
      return obj.key === 'volunteer';
    });
    let volunteerEventIsQualified;
    let configNumber;

    if (isYoungMember) {
      volunteerEventIsQualified = Number(findVolunteer?.value) >= Number(config?.youngMemberSession);
      configNumber = config?.youngMemberSession;
    } else {
      volunteerEventIsQualified = Number(findVolunteer?.value) >= Number(config?.applicantSession);
      configNumber = config?.applicantSession;
    }
    return (
      <BoxQualifiedItem display={'flex'} justifyContent={'center'} alignItems={'flex-start'} flexDirection={'column'}>
        {user?.verificationStatus === 'verified' ? (
          <Item icon={CheckIcon} isQualified={true}>
            Account is verified
          </Item>
        ) : (
          <Item icon={FailIcon} isQualified={false}>
            Account is not verified
          </Item>
        )}

        <Item icon={volunteerEventIsQualified ? CheckIcon : FailIcon} isQualified={volunteerEventIsQualified}>
          {` Volunteer Event ${findVolunteer?.value}/${configNumber}`}
        </Item>
        {isYoungMember
          ? config &&
            config.youngMemberConfig &&
            config.youngMemberConfig.map((item: IKeyValue) => {
              let isQualified: boolean = false;
              let findData;
              if (countVolunteer) {
                findData = find(countVolunteer, (object) => {
                  return object.key === item.key;
                });
                if (findData && findData.value >= Number(item.value)) {
                  isQualified = true;
                }
              }
              const assignValue = find(EventCategory, (object) => {
                return object.value === item.key;
              });
              return (
                <Item icon={isQualified ? CheckIcon : FailIcon} isQualified={isQualified}>
                  {`${assignValue?.label} : ${findData?.value}/${item.value}`}
                </Item>
              );
            })
          : config &&
            config.applicantConfig &&
            config.applicantConfig.map((item: IKeyValue) => {
              let isQualified: boolean = false;
              let findData;
              if (countVolunteer) {
                findData = find(countVolunteer, (object) => {
                  return object.key === item.key;
                });
                if (findData && findData.value >= Number(item.value)) {
                  isQualified = true;
                }
              }
              const assignValue = find(EventCategory, (object) => {
                return object.value === item.key;
              });
              return (
                <Item icon={isQualified ? CheckIcon : FailIcon} isQualified={isQualified}>
                  {`${assignValue?.label} : ${findData?.value}/${item.value}`}
                </Item>
              );
            })}
      </BoxQualifiedItem>
    );
  };
  return (
    <Box>
      <LoadingCustom loading={loading} loadingIcon />
      {
        <Container
          width={'1062px'}
          p={4}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          {user?.eligibleStatus === 'not-eligible' ? (
            <EligibleStatus display="flex" justifyContent={'center'} alignItems="center">
              <Icon src={WarningIcon} />
              <Box display={'flex'} justifyContent={'center'} alignItems={'flex-start'} flexDirection={'column'}>
                <BoldText>Currently, you do not qualify to be a Member.</BoldText>
                <ItalicText>
                  {/* {`Your account will be expired if you don’t upgrade your account to life membership before ${
                    me && moment.utc(me.expirationDate).format(FORMAT_DATE_DMY)
                  }`} */}
                  This account will expire if you are not a Member by...
                </ItalicText>
              </Box>
            </EligibleStatus>
          ) : user?.eligibleStatus === 'eligible' ? (
            <EligibleStatus display="flex" justifyContent={'center'} alignItems="center">
              <Icon src={OutlineCheckIcon} />
              <Box display={'flex'} justifyContent={'center'} alignItems={'flex-start'} flexDirection={'column'}>
                <BoldText> Congratulations! You are officially a Life Member!</BoldText>
                <BoldText>Please pay membership fee to finish the process</BoldText>
              </Box>
            </EligibleStatus>
          ) : null}
          {user?.eligibleStatus === 'eligible' || user?.eligibleStatus === 'not-eligible' ? (
            <>
              {qualificationItemRender()}
              <ButtonGroup display={'flex'} justifyContent={'center'} alignItems={'center'}>
                {user?.eligibleStatus === 'not-eligible' ? (
                  <>
                    <NavLinkCustom to={'/my-volunteer-event'}>
                      <CustomButton white outlineButton>
                        Go to My Event
                      </CustomButton>
                    </NavLinkCustom>
                    <NavLinkCustom to={'/events'}>
                      <CustomButton>Go to Events & Activites</CustomButton>
                    </NavLinkCustom>
                  </>
                ) : (
                  <NavLinkCustom to={'/my-volunteer-event'}>
                    <CustomButton>Go to My Event</CustomButton>
                  </NavLinkCustom>
                )}
              </ButtonGroup>{' '}
            </>
          ) : null}
          {user?.eligibleStatus === 'pending-member' ? (
            <NotificationBox display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
              <ImgCustom src={ClockIcon} />
              <Notification>Your life membership application is on pending list.</Notification>
              <Notification>You’ll be an official life member after our approval.</Notification>
            </NotificationBox>
          ) : user?.eligibleStatus === 'approved' ? (
            <NotificationBox display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
              <ImgCustom src={OutlineCheckIcon} />
              <Notification> Congratulations! You are officially a Life Member!</Notification>
            </NotificationBox>
          ) : null}
        </Container>
      }
    </Box>
  );
};

export default MembershipStatus;
