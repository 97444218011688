import React from 'react';

import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { CustomButton } from 'src/components';
import SuccessIcon from 'src/medias/images/success-logo.svg';

export const ImageStyled = styled.img(() => ({}));

export const ContentStyled = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 700,
  color: 'rgba(51, 51, 51, 1)',
  lineHeight: 1.5,
}));

const ResponseSuccess = () => {
  const _onSubmit = () => {
    window.location.href = '/';
  };
  const _onBack = () => {
    window.location.reload();
  };
  return (
    <Box
      display="flex"
      alignItems="center"
      flex={1}
      flexDirection="column"
      mt={6}
      p={3}
      justifyContent="center"
      minHeight={'890px'}
    >
      <ImageStyled src={SuccessIcon} />
      <Box mt={'60px'} mb={'60px'}>
        <ContentStyled style={{ textAlign: 'center' }}>Thank you for your response!</ContentStyled>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box mr={2}>
          <CustomButton outlineButton onClick={_onBack}>
            Update new response
          </CustomButton>
        </Box>
        <CustomButton onClick={_onSubmit}>Go back to Homepage</CustomButton>
      </Box>
    </Box>
  );
};

export default ResponseSuccess;
