import React from 'react';

import {
  Box,
  CircularProgress,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Link,
  RadioGroup,
} from '@material-ui/core';
import { FieldArray, useFormik, FormikProvider } from 'formik';
import * as Yup from 'yup';

import { useUploadImageMutation } from 'src/api/endpoints/upload.endpoint';
import { NASSRadio } from 'src/components';
import ConfirmPopup from 'src/components/confirm';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { DATE_PICKER_DISPLAY_FORMAT, PLACEHOLDER_DATE_PICKER } from 'src/constants/common';
import { INPUT_MAX_LENGTH_50, NATIONALITY } from 'src/constants/users.constants';
import { PROFILE_VALIDATION_MESSAGE } from 'src/constants/validation-message.constant';
import AttachmentIcon from 'src/icons/attachment';
import CloseIcon from 'src/icons/close';
import { Title } from 'src/pages/profile/components/additional-information.styled';
import {
  ActionButton,
  FormTextField,
  GeneralInformationDatePicker,
  FormMenuItem,
  Label,
} from 'src/pages/profile/components/general-information.styled';
import ProfilePopupLayout from 'src/pages/profile/layout/profile-popup.layout';
import { HeaderSticky, FooterSticky } from 'src/pages/profile/shared/additional-information.popup';
import { ReactFlagsSelectStyled } from 'src/pages/signup/components/types';
import { Close } from 'src/pages/user-management/shared/user-detail.component';
import { IUser } from 'src/services/users';
import { customLabels, getAreaCode, getAreaKey } from 'src/utils/areaCode';

import { TitleStyled } from './user-detail.additional-information';

interface IAdditionalInformationPopupProps {
  profile: IUser;
  onSave: (updateValues: any) => void;
  onClose: () => void;
  isSaveLoading?: boolean;
  open: boolean;
}

const AdditionalInformationPopup = ({
  open,
  isSaveLoading,
  profile,
  onSave,
  onClose,
}: IAdditionalInformationPopupProps) => {
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = React.useState(false);
  const [isShowSnackBarError, setShowSnackBarError] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const UPLOAD_PHOTO_FORMAT_MESSAGE = 'Uploaded file’s format must be PNG, JPG, IMG and size must be less than 1MB';

  const ONE_MB_FILE_SIZE = 1024;

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };

  const formik = useFormik({
    initialValues: {
      parentAreaCode: profile?.parentAreaCode || 'SG',
      parentEmail: profile?.parentEmail || '',
      parentGivenName: profile?.parentGivenName || '',
      parentMobilePhone: profile?.parentMobilePhone || '',
      parentSurname: profile?.parentSurname || '',
      relationship: profile?.relationship || '',
      signature: profile?.signature || '',
      child:
        profile?.child && profile?.child?.length > 0
          ? profile.child
          : [
              {
                surname: '',
                givenName: '',
                dateOfBirth: '',
                nationality: 'singapore-citizen',
                verificationStatus: 'unverified',
              },
            ],
    },
    validationSchema: Yup.object({
      parentEmail: Yup.string().email(PROFILE_VALIDATION_MESSAGE.EMAIL).optional(),
      child: Yup.array(
        Yup.object({
          surname: Yup.string().required(PROFILE_VALIDATION_MESSAGE.SURNAME),
          givenName: Yup.string().required(PROFILE_VALIDATION_MESSAGE.GIVEN_NAME),
          dateOfBirth: Yup.string().required(PROFILE_VALIDATION_MESSAGE.DOB),
        }),
      ),
    }),
    onSubmit: (values) => {
      values.parentAreaCode = getAreaCode(values.parentAreaCode);

      onSave(values);
    },
  });

  const [isHaveChildren, setHaveChildren] = React.useState<boolean>(
    profile?.child && profile?.child.length > 0 ? true : false,
  );
  const proofFileRef = React.useRef<HTMLInputElement | null>(null);
  const [, setSelectedFileName] = React.useState<string>();
  const [isShowConfirmPopup, setShowConfirmPopup] = React.useState(false);

  const [
    uploadProof,
    {
      isLoading: isUploadingProof,
      data: uploadProofData,
      isSuccess: isUploadingProofSuccess,
      error: uploadProofError,
      isError: isUploadingProofError,
    },
  ] = useUploadImageMutation();
  React.useEffect(() => {
    if (isUploadingProofError) {
      setShowSnackBarError(true);
    }
  }, [isUploadingProofSuccess, isUploadingProofError]);
  React.useEffect(() => {
    if (isUploadingProofSuccess) {
      formik.setFieldValue('signature', uploadProofData?.data?.name);
    }
    if (isUploadingProofError) {
      formik.setFieldValue('signature', null);
    }
  }, [isUploadingProofSuccess, isUploadingProofError]);

  React.useEffect(() => {
    if (!isHaveChildren) {
      formik.setFieldValue('child', []);
    }
  }, [isHaveChildren]);

  React.useEffect(() => {
    setMessage((uploadProofError as any)?.data?.message);
  }, [uploadProofError]);

  function handleProofFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target?.files?.[0];

    const fileSize = e.target?.files?.[0].size;
    const fileS = Math.round(fileSize! / ONE_MB_FILE_SIZE);
    if (fileS > ONE_MB_FILE_SIZE) {
      setShowSnackBarError(true);
      setMessage(UPLOAD_PHOTO_FORMAT_MESSAGE);
      e.target.value = '';
      return;
    }
    if (file) {
      setSelectedFileName(file.name);
      const formData = new FormData();
      formData.append('image', file);
      uploadProof(formData);
    }
    e.target.value = '';
  }
  function handleProofFileClick() {
    if (proofFileRef.current) {
      proofFileRef.current.click();
    }
  }
  function setRef(ref: HTMLInputElement | null) {
    proofFileRef.current = ref!;
  }
  function handleOkConfirmPopup() {
    formik.submitForm();
    handleTogglePopup();
  }
  function handleTogglePopup() {
    setShowConfirmPopup((status) => !status);
  }
  function handleClosePopupAndClearOldData() {
    onClose();
  }

  return (
    <ProfilePopupLayout open={open} onClose={handleClosePopupAndClearOldData}>
      <ConfirmPopup
        open={isShowConfirmPopup}
        title="Notification"
        content="Are you sure you want to save this change?"
        onCancel={handleTogglePopup}
        onOk={handleOkConfirmPopup}
        btnCancelVariant="outlined"
        btnOkVariant="filled"
      />
      <SuccessSnackBar
        open={isShowSnackBarSuccess}
        handleClose={handleCloseSnackBar('success')}
        message={uploadProofData?.message}
      />
      <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />
      <Box px={3}>
        <HeaderSticky>
          <Title>Additional information</Title>
          <Close onClick={onClose}>
            <CloseIcon fill="white" />
          </Close>
        </HeaderSticky>
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Box>
                <TitleStyled>Your Parent/Guardian Info</TitleStyled>
                <Box my={3} display="flex" style={{ gap: 24 }}>
                  <Box flex="1">
                    <Label>Surname</Label>
                    <FormTextField
                      name="parentSurname"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      fullWidth
                      value={formik.values.parentSurname}
                      inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
                    />
                  </Box>
                  <Box flex="1">
                    <Label>Given Name</Label>
                    <FormTextField
                      name="parentGivenName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      variant="outlined"
                      fullWidth
                      value={formik.values.parentGivenName}
                      inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
                    />
                  </Box>
                </Box>
                <Box mb={3}>
                  <Label>Relationship</Label>
                  <RadioGroup
                    value={formik.values.relationship}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="relationship"
                    row
                  >
                    <FormControlLabel value="parent" control={<NASSRadio />} label="Parent" />
                    <Box width={160} />
                    <FormControlLabel value="guardian" control={<NASSRadio />} label="Guardian" />
                  </RadioGroup>
                </Box>
                <Box mb={3}>
                  <Label>Email Address</Label>
                  <FormTextField
                    variant="outlined"
                    name="parentEmail"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    fullWidth
                    value={formik.values.parentEmail}
                    error={formik.touched?.parentEmail && !!(formik.errors as any)?.parentEmail}
                    helperText={formik.touched?.parentEmail && (formik.errors as any)?.parentEmail}
                  />
                </Box>
                <Box mb={3} display="flex" style={{ gap: 24 }} alignItems="flex-end">
                  <Box flex="0.5">
                    <Label>Phone number</Label>
                    <ReactFlagsSelectStyled
                      fullWidth
                      selected={formik.values.parentAreaCode ? getAreaKey(formik.values.parentAreaCode) : ''}
                      onSelect={(v) => {
                        formik.setFieldValue('parentAreaCode', getAreaCode(v));
                      }}
                      searchable
                      customLabels={customLabels}
                      showSecondaryOptionLabel
                      showSelectedLabel={false}
                    />
                  </Box>
                  <Box flex="0.5">
                    <FormTextField
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={formik.values.parentMobilePhone}
                      name="parentMobilePhone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Phone number (Including area’s code, if there is)"
                    />
                  </Box>
                </Box>
                <input
                  accept=".png, .jpeg, .jpg, .pdf"
                  type="file"
                  ref={setRef}
                  hidden
                  onChange={handleProofFileChange}
                />
                <Box mb={4}>
                  <Label>Parent’s Consent Proof</Label>
                  <FormTextField
                    variant="outlined"
                    fullWidth
                    style={{ overflow: 'hidden' }}
                    InputProps={{
                      readOnly: true,
                      startAdornment: (
                        <InputAdornment position="start">
                          {isUploadingProof ? (
                            <IconButton>
                              <CircularProgress size={20} />
                            </IconButton>
                          ) : (
                            <IconButton onClick={handleProofFileClick}>
                              <AttachmentIcon />
                            </IconButton>
                          )}
                          <Box mx={1}>
                            <Link
                              target={formik.values?.signature ? '_blank' : '_self'}
                              href={formik.values?.signature || '#'}
                            >
                              {formik.values?.signature}
                            </Link>
                          </Box>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box mb={1.8}>
              <Box mb={3}>
                <TitleStyled>Your Children Info</TitleStyled>
              </Box>
              <Label>Do You Have Children?</Label>
              <RadioGroup
                value={isHaveChildren ? 'yes' : 'no'}
                onChange={(e, v) => {
                  if (v === 'yes')
                    formik.setFieldValue('child', [
                      {
                        surname: '',
                        givenName: '',
                        dateOfBirth: '',
                        nationality: 'singapore-citizen',
                        verificationStatus: 'unverified',
                      },
                    ]);
                  setHaveChildren(v === 'no' ? false : true);
                }}
                row
              >
                <FormControlLabel value="yes" control={<NASSRadio />} label="Yes" />
                <Box width={160} />
                <FormControlLabel value="no" control={<NASSRadio />} label="No" />
              </RadioGroup>
            </Box>

            {isHaveChildren && formik.values.child.length > 0 && (
              <Box mb={0.5} display="flex" style={{ gap: 16 }}>
                <Box flex="1">
                  <Label>Child’s Surname</Label>
                </Box>
                <Box flex="1">
                  <Label>Child’s GivenName</Label>
                </Box>
                <Box flex="1">
                  <Label>Child’s Date Of Birth</Label>
                </Box>
                <Box flex="1">
                  <Label>Child’s Nationality</Label>
                </Box>
                <Box flex="1.3" />
              </Box>
            )}
            {isHaveChildren && (
              <FieldArray name="child">
                {({ push, remove }) => {
                  return (
                    <>
                      {formik.values.child.map((item, index) => {
                        return (
                          <Box mb={3} display="flex" style={{ gap: 16 }}>
                            <Box flex="1" flexShrink="0">
                              <FormTextField
                                variant="outlined"
                                fullWidth
                                value={item.surname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name={`child[${index}].surname`}
                                inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
                                error={
                                  formik.touched?.child?.[index]?.surname &&
                                  !!(formik.errors as any)?.child?.[index]?.surname
                                }
                                helperText={
                                  formik.touched?.child?.[index]?.surname &&
                                  (formik.errors as any)?.child?.[index]?.surname
                                }
                              />
                            </Box>
                            <Box flex="1" flexShrink="0">
                              <FormTextField
                                variant="outlined"
                                fullWidth
                                value={item.givenName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                name={`child[${index}].givenName`}
                                inputProps={{ maxLength: INPUT_MAX_LENGTH_50 }}
                                error={
                                  formik.touched?.child?.[index]?.givenName &&
                                  !!(formik.errors as any)?.child?.[index]?.givenName
                                }
                                helperText={
                                  formik.touched?.child?.[index]?.givenName &&
                                  (formik.errors as any)?.child?.[index]?.givenName
                                }
                              />
                            </Box>
                            <Box flex="1" flexShrink="0">
                              <GeneralInformationDatePicker
                                fullWidth
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                format={DATE_PICKER_DISPLAY_FORMAT}
                                value={item.dateOfBirth}
                                placeholder={PLACEHOLDER_DATE_PICKER}
                                InputProps={{ readOnly: true }}
                                InputAdornmentProps={{ position: 'end' }}
                                error={
                                  formik.touched?.child?.[index]?.dateOfBirth &&
                                  !!(formik.errors as any)?.child?.[index]?.dateOfBirth
                                }
                                invalidDateMessage={
                                  (formik.touched?.child?.[index]?.dateOfBirth &&
                                    (formik.errors as any)?.child?.[index]?.dateOfBirth) ||
                                  ''
                                }
                                onChange={(date) => {
                                  if (!date) return;
                                  formik.setFieldValue(`child[${index}]`, {
                                    ...formik.values.child[index],
                                    dateOfBirth: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
                                  });
                                }}
                              />
                            </Box>
                            <Box flex="1" flexShrink="0">
                              <FormTextField
                                ellipsis
                                variant="outlined"
                                name="nationality"
                                fullWidth
                                size="small"
                                value={item.nationality}
                                onChange={(e) => {
                                  formik.setFieldValue(`child[${index}]`, {
                                    ...formik.values.child[index],
                                    nationality: e.target.value,
                                  });
                                }}
                                onBlur={formik.handleBlur}
                                select={true}
                                SelectProps={{
                                  MenuProps: {
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                  },
                                }}
                              >
                                {NATIONALITY.map(({ value, label }, idx) => (
                                  <FormMenuItem key={idx} value={value}>
                                    {label}
                                  </FormMenuItem>
                                ))}
                              </FormTextField>
                            </Box>
                            <Box
                              flex="1"
                              mb={
                                formik.touched?.child?.[index] &&
                                !!(formik.errors as any)?.child?.[index] &&
                                formik.touched?.child?.[index].givenName
                                  ? 3
                                  : 0.5
                              }
                              display="flex"
                              alignItems="center"
                              style={{ gap: 10 }}
                            >
                              <FormTextField
                                ellipsis
                                variant="outlined"
                                name="verificationStatus"
                                fullWidth
                                size="small"
                                value={item.verificationStatus}
                                onChange={(e) => {
                                  formik.setFieldValue(`child[${index}]`, {
                                    ...formik.values.child[index],
                                    verificationStatus: e.target.value,
                                  });
                                }}
                                onBlur={formik.handleBlur}
                                select={true}
                                SelectProps={{
                                  MenuProps: {
                                    anchorOrigin: {
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                  },
                                }}
                              >
                                <FormMenuItem value="verified">Verified</FormMenuItem>
                                <FormMenuItem value="unverified">Unverified</FormMenuItem>
                              </FormTextField>
                              <Box flexShrink="0">
                                <Close onClick={() => remove(index)}>
                                  <CloseIcon fill="white" />
                                </Close>
                              </Box>
                            </Box>
                          </Box>
                        );
                      })}
                      <Box width="100%">
                        <ActionButton
                          variants="outlined"
                          style={{ width: '100%' }}
                          onClick={() =>
                            push({
                              surname: '',
                              givenName: '',
                              dateOfBirth: '',
                              nationality: 'singapore-citizen',
                              verificationStatus: 'unverified',
                            })
                          }
                        >
                          Add More Child
                        </ActionButton>
                      </Box>
                    </>
                  );
                }}
              </FieldArray>
            )}
            <FooterSticky>
              <ActionButton onClick={onClose} variants="outlined">
                Cancel
              </ActionButton>
              <ActionButton disabled={isSaveLoading} type="button" variants="yellow" onClick={handleTogglePopup}>
                {isSaveLoading && (
                  <IconButton>
                    <CircularProgress size={20} />
                  </IconButton>
                )}
                Save
              </ActionButton>
            </FooterSticky>
          </form>
        </FormikProvider>
      </Box>
    </ProfilePopupLayout>
  );
};

export default AdditionalInformationPopup;
