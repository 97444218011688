import React, { useEffect, useState } from 'react';

import { Box, Step, StepLabel, Grid, makeStyles, Link } from '@material-ui/core';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { CustomButton, StepperCustom } from 'src/components';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import BackgroundMobile from 'src/medias/images/background-school-mobile.svg';
import backgroundSignup from 'src/medias/images/background-signup.png';
import LogoJSA from 'src/medias/logo/logo-JSA.svg';
import { Title, TitleResponse } from 'src/pages/login';
import { ISignUpReq, signUp } from 'src/services/auth';
import { selectLoginState } from 'src/store/authentication/selector';
import { getAreaCode } from 'src/utils/areaCode';
import { _checkErrors } from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import BasicInformation from '../signup/components/BasicInformation';
import ParentInformation from '../signup/components/ParentInformation';
import PrivacyPolicy from '../signup/components/PrivacyPolicy';
import { LogoMobile, nationalities } from '../signup/components/types';
import VerifyEmail from '../signup/components/VerifyEmail';

const steps = ["Student's Information", "Parent's Information", 'Finish'];

const useStyles = makeStyles(() => ({
  container: {
    backgroundImage: `url(${backgroundSignup})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  containerResponse: {
    backgroundColor: '#F9F9F4',
    padding: 16,
  },
  bgMobile: {
    backgroundImage: `url(${BackgroundMobile})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: 175,
  },
}));

const SignUpForChild = () => {
  const { isTabletOrMobile } = useResponsive();
  const classes = useStyles();
  const isLogin = useSelector(selectLoginState);
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [signUpStatus, setSignUpStatus] = useState<{ statusCode: undefined | number; message: string }>({
    statusCode: undefined,
    message: '',
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (isLogin) {
      window.location.href = '/';
    }
  }, [isLogin]);

  const [data, setData] = useState<ISignUpReq>({
    surname: '',
    givenName: '',
    nationality: nationalities[2].key,
    dateOfBirth: null,
    gender: '',
    jobTitle: '',
    companyName: '',
    mailingAddress: '',
    postalCode: '',
    areaCode: 'SG',
    mobilePhone: '',
    emailAddress: '',
    periodStudyInNYK: null,
    periodStudyInNPP: null,
    periodStudyInNYP: null,
    periodStudyInNYGH: null,
    skills: '',
    otherSkills: '',
    password: '',
    verifyPassword: '',
    chineseName: '',
    child: [],
    graduatingClass: '',
    parentName: '',
    parentSurname: '',
    parentGivenName: '',
    relationship: 'parent',
    parentEmail: '',
    parentAreaCode: 'SG',
    parentMobilePhone: '',
    role: '',
    isConfirmPolicy: false,
    isParentConfirmPolicy: false,
    signature: null,
  });

  const [errors, setErrors] = useState({
    surname: false,
    givenName: false,
    dateOfBirth: false,
    gender: false,
    emailAddress: false,
    mobilePhone: false,
    mailingAddress: false,
    postalCode: false,
    password: false,
    verifyPassword: false,
    graduateSchool: false,
    parentSurname: false,
    parentGivenName: false,
    parentEmail: false,
    parentMobilePhone: false,
  });

  const disabledStepBasicInformation =
    data.surname !== '' &&
    data.givenName !== '' &&
    data?.dateOfBirth?.toString() !== null &&
    data.gender !== '' &&
    data.emailAddress !== '' &&
    data.areaCode !== '' &&
    data.mobilePhone !== '' &&
    data.mailingAddress !== '' &&
    data.postalCode !== '' &&
    data.password !== '' &&
    data.verifyPassword !== '' &&
    (data.periodStudyInNYK !== null ||
      data.periodStudyInNPP !== null ||
      data.periodStudyInNYGH !== null ||
      data.periodStudyInNYP !== null) &&
    data.password === data.verifyPassword &&
    !_checkErrors(errors);

  const disabledStepParentInfo =
    data.parentSurname !== '' &&
    data.parentGivenName !== '' &&
    data.parentEmail !== '' &&
    data.parentMobilePhone !== '' &&
    !_checkErrors(errors);

  const disabledStepPrivacy =
    data.isConfirmPolicy && data.isParentConfirmPolicy && data.signature !== null && !_checkErrors(errors);

  function handleCloseSnackBar(event: React.SyntheticEvent | React.MouseEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  }

  function _onClickBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  const validateGraduate =
    data.periodStudyInNYK !== null ||
    data.periodStudyInNPP !== null ||
    data.periodStudyInNYGH !== null ||
    data.periodStudyInNYP !== null;

  function _onClickNext() {
    if (activeStep === 0) {
      if (!disabledStepBasicInformation) {
        let validErrors = errors;
        if (!validateGraduate) {
          validErrors.graduateSchool = true;
          setErrors({ ...validErrors, graduateSchool: true });
        }
        if (_.isEmpty(data.verifyPassword)) {
          validErrors.verifyPassword = true;
          setErrors({ ...validErrors, verifyPassword: true });
        }
        if (_.isEmpty(data.password)) {
          validErrors.password = true;
          setErrors({ ...validErrors, password: true });
        }
        if (_.isEmpty(data.postalCode)) {
          validErrors.postalCode = true;
          setErrors({ ...validErrors, postalCode: true });
        }
        if (_.isEmpty(data.mailingAddress)) {
          validErrors.mailingAddress = true;
          setErrors({ ...validErrors, mailingAddress: true });
        }
        if (_.isEmpty(data.mobilePhone)) {
          validErrors.mobilePhone = true;
          setErrors({ ...validErrors, mobilePhone: true });
        }
        if (_.isEmpty(data.emailAddress)) {
          validErrors.emailAddress = true;
          setErrors({ ...validErrors, emailAddress: true });
        }
        if (_.isEmpty(data.gender)) {
          validErrors.gender = true;
          setErrors({ ...validErrors, gender: true });
        }
        if (_.isEmpty(data?.dateOfBirth?.toString())) {
          validErrors.dateOfBirth = true;
          setErrors({ ...validErrors, dateOfBirth: true });
        }
        if (_.isEmpty(data.givenName)) {
          validErrors.givenName = true;
          setErrors({ ...validErrors, givenName: true });
        }
        if (_.isEmpty(data.surname)) {
          validErrors.surname = true;
          setErrors({ ...validErrors, surname: true });
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
    if (activeStep === 1) {
      if (!disabledStepParentInfo) {
        let validErrors = errors;

        if (_.isEmpty(data.parentMobilePhone)) {
          validErrors.parentMobilePhone = true;
          setErrors({ ...validErrors, parentMobilePhone: true });
        }
        if (_.isEmpty(data.parentEmail)) {
          validErrors.parentEmail = true;
          setErrors({ ...validErrors, parentEmail: true });
        }
        if (_.isEmpty(data.parentGivenName)) {
          validErrors.parentGivenName = true;
          setErrors({ ...validErrors, parentGivenName: true });
        }
        if (_.isEmpty(data.parentSurname)) {
          validErrors.parentSurname = true;
          setErrors({ ...validErrors, parentSurname: true });
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  }

  useEffect(() => {
    if (validateGraduate) {
      setErrors({ ...errors, graduateSchool: false });
    }
  }, [validateGraduate]);

  async function _onClickSubmit() {
    if (disabledStepBasicInformation) {
      setLoading(true);

      const body = {
        surname: data.surname,
        givenName: data.givenName,
        chineseName: data.chineseName,
        dateOfBirth: data.dateOfBirth,
        gender: data.gender,
        emailAddress: data.emailAddress,
        areaCode: getAreaCode(data.areaCode),
        mobilePhone: data.mobilePhone,
        mailingAddress: data.mailingAddress,
        postalCode: data.postalCode,
        password: data.password,
        periodStudyInNYK: data.periodStudyInNYK !== null ? data.periodStudyInNYK.toString() : null,
        periodStudyInNPP: data.periodStudyInNPP !== null ? data.periodStudyInNPP.toString() : null,
        periodStudyInNYP: data.periodStudyInNYP !== null ? data.periodStudyInNYP.toString() : null,
        periodStudyInNYGH: data.periodStudyInNYGH !== null ? data.periodStudyInNYGH.toString() : null,
        parentSurname: data.parentSurname,
        parentGivenName: data.parentGivenName,
        relationship: data.relationship,
        parentEmail: data.parentEmail,
        parentAreaCode: getAreaCode(data.parentAreaCode),
        parentMobilePhone: data.parentMobilePhone,
        nationality: null,
        jobTitle: null,
        companyName: null,
        signature: data.signature,
      };

      const response = await signUp(body);
      setOpenSnackBar(true);

      if (response.statusCode === CALL_SUCCESS) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSignUpStatus(response);
        setLoading(false);
      }
      if (response.error) {
        setSignUpStatus({ message: response.message, statusCode: response.statusCode });
        setLoading(false);
      }
    }
  }

  return (
    <>
      {isTabletOrMobile && (
        <Box display="flex" width={'100%'} className={classes.bgMobile}>
          <Link href="/">
            <LogoMobile src={LogoJSA} />
          </Link>
        </Box>
      )}
      <Grid
        container
        justifyContent="center"
        className={isTabletOrMobile ? classes.containerResponse : classes.container}
      >
        <Grid item xs={isTabletOrMobile ? 12 : 6}>
          {activeStep === steps.length ? (
            <Box> {activeStep === 3 && <VerifyEmail youthMember />}</Box>
          ) : (
            <Box>
              <Box mt={isTabletOrMobile ? 2 : 8} textAlign="center">
                {isTabletOrMobile ? (
                  <TitleResponse>Sign up form for youth member</TitleResponse>
                ) : (
                  <Title>SIGN UP FORM FOR YOUTH MEMBER</Title>
                )}
                <Box mt={2} width={'100%'}>
                  <StepperCustom activeStep={activeStep}>
                    {steps.map((label, index) => {
                      return (
                        <Step key={label}>
                          <StepLabel>{isTabletOrMobile ? '' : label}</StepLabel>
                        </Step>
                      );
                    })}
                  </StepperCustom>
                </Box>
              </Box>
              {activeStep === 0 && (
                <BasicInformation
                  currentYear={currentYear}
                  errors={errors}
                  setErrors={setErrors}
                  youthMember
                  data={data}
                  setData={setData}
                />
              )}
              {activeStep === 1 && (
                <ParentInformation errors={errors} setErrors={setErrors} data={data} setData={setData} />
              )}
              {activeStep === 2 && <PrivacyPolicy youthMember data={data} setData={setData} />}
              <Box mb={8} mt={4} display="flex" justifyContent="center">
                <CustomButton outlineButton onClick={_onClickBack} disabled={activeStep === 0}>
                  Back
                </CustomButton>
                <Box ml={3}>
                  {activeStep === steps.length - 1 ? (
                    <CustomButton disabled={!disabledStepPrivacy || loading} onClick={_onClickSubmit}>
                      Submit
                    </CustomButton>
                  ) : (
                    <CustomButton onClick={_onClickNext}>Next</CustomButton>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {signUpStatus.statusCode === CALL_SUCCESS && openSnackBar && (
            <SuccessSnackBar message={signUpStatus.message} open={openSnackBar} handleClose={handleCloseSnackBar} />
          )}
          {signUpStatus.statusCode !== undefined && signUpStatus.statusCode !== CALL_SUCCESS && openSnackBar && (
            <ErrorSnackBar message={signUpStatus.message} open={openSnackBar} handleClose={handleCloseSnackBar} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SignUpForChild;
