import React from 'react';

import * as moment from 'moment-timezone';
import './index.css';
import ReactDOM from 'react-dom';

import App from './App';
import { AppProvider } from './contexts/app-provider-example';
import reportWebVitals from './reportWebVitals';
import './i18n';

moment.tz.setDefault('Asia/Singapore');

ReactDOM.render(
  <React.StrictMode>
    <AppProvider>
      <App />
    </AppProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
