import React, { useEffect, useState } from 'react';

import { Box, FormControlLabel, FormGroup, Typography } from '@material-ui/core';

import { CheckboxCustom } from 'src/components';
import CustomFormDialog from 'src/components/formDialog';
import { CALL_SUCCESS } from 'src/constants/common';
import IconSuccess from 'src/medias/icons/icon-success.png';
import { InterviewNameStyled, SelectBoxItem, TextStyled } from 'src/pages/user-management/components/BlockAccountModal';
import { IconVerifyStyled } from 'src/pages/user-management/types';
import { IApplicant } from 'src/services/gtky-applicant';
import { getCommitteeList } from 'src/services/users';
import { IGTKYApplication, IInterview, ISearchInterview } from 'src/services/volunteer';
import { capitalizeFirstLetter } from 'src/utils/common';

import { defaultSearchInterview, LabelStyled, NoteStyled, SelectBox } from '../types';

import SearchInterviewer from './SearchInterviewer';

const NUMBER_INTERVIEWERS_CHOOSEN = 2;

interface IAssignModal {
  _onCloseModal: () => void;
  open: boolean;
  _onSubmit?: () => void;
  applications?: IGTKYApplication[] | undefined;
  application?: IApplicant;
  loading: boolean | undefined;
  idItemSelected: string[];
  setIdItemSelected: React.Dispatch<React.SetStateAction<string[]>>;
}
const AssignModal = ({
  _onCloseModal,
  open,
  _onSubmit,
  applications,
  application,
  loading,
  idItemSelected,
  setIdItemSelected,
}: IAssignModal) => {
  const [interviewers, setInterviewers] = useState<IInterview[]>([]);
  const [search, setSearch] = useState<ISearchInterview>(defaultSearchInterview);
  const [loadingSearch, setLoadingSearch] = useState(false);

  function handleItemCheckboxChange(id: string) {
    return function (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
      if (checked) {
        if (idItemSelected.length < NUMBER_INTERVIEWERS_CHOOSEN) {
          setIdItemSelected((items) => [...items, id]);
        }
      } else {
        setIdItemSelected((items) => items.filter((item) => item !== id));
      }
    };
  }

  const handleSubmit = () => {
    if (idItemSelected.length === NUMBER_INTERVIEWERS_CHOOSEN) {
      _onSubmit?.();
    }
  };

  const handleClose = () => {
    _onCloseModal?.();
    setSearch(defaultSearchInterview);
  };

  const renderSelectedInterviewer = () => {
    const getInterviewerName = (id: string) => {
      const findItem = interviewers.find((item) => item.id === id);
      return findItem?.surname + ', ' + findItem?.givenName;
    };
    return (
      <Box>
        <NoteStyled>
          Selected interviewer:
          <Typography display="inline">
            {idItemSelected.map((item, index) => (
              <span>
                {index === 1 ? ',' : ' '} {getInterviewerName(item)}
              </span>
            ))}
          </Typography>
        </NoteStyled>
      </Box>
    );
  };

  const getData = async ({ search }: { search?: ISearchInterview }) => {
    setLoadingSearch(true);

    const response = await getCommitteeList({ search });
    if (response?.statusCode === CALL_SUCCESS) {
      setInterviewers(response?.data?.interviewers);
    }
    setLoadingSearch(false);
  };

  const _onSearch = (searchData: ISearchInterview) => {
    setSearch(searchData);
    getData({
      search: searchData,
    });
  };

  const _onReset = async () => {
    setLoadingSearch(true);
    setSearch(defaultSearchInterview);

    const response = await getCommitteeList({
      search: defaultSearchInterview,
    });
    if (response?.statusCode === CALL_SUCCESS) {
      setInterviewers(response?.data?.interviewers);
    }
    setLoadingSearch(false);
  };

  useEffect(() => {
    if (open) {
      getData({
        search,
      });
    }
  }, [open]);

  return (
    <CustomFormDialog
      title="Assign Interviewer"
      open={open}
      _onCloseModal={handleClose}
      submitText="Assign"
      cancelText="Close"
      _onSubmit={handleSubmit}
      disabled={loading}
    >
      <Box>
        <LabelStyled>Selected Applicant</LabelStyled>
        <SelectBox mt={1} style={{ overflowY: 'auto' }}>
          {application && (
            <SelectBoxItem p={1} index={0}>
              <Box display="flex" flex={1}>
                <Box width="60%">
                  <TextStyled>
                    {`${application?.surname}, ${application?.givenName}`?.length > 40
                      ? `${application?.surname}, ${application?.givenName}`?.slice(0, 40) + '...'
                      : `${application?.surname}, ${application?.givenName}`}
                    {application?.verificationStatus === 'verified' && <IconVerifyStyled src={IconSuccess} />}
                  </TextStyled>
                </Box>
                <Box>
                  <TextStyled>
                    {application?.accountType ? capitalizeFirstLetter(application?.accountType) : ''}
                  </TextStyled>
                </Box>
              </Box>
            </SelectBoxItem>
          )}
          {applications &&
            [...new Set(applications)]?.map((application, index) => {
              return (
                <SelectBoxItem p={1} index={index}>
                  <Box display="flex" flex={1}>
                    <Box width="60%">
                      <TextStyled>
                        {`${application?.user?.surname}, ${application?.user?.givenName}`?.length > 40
                          ? `${application?.user?.surname}, ${application?.user?.givenName}`?.slice(0, 40) + '...'
                          : `${application?.user?.surname}, ${application?.user?.givenName}`}
                        {application?.user?.verificationStatus === 'verified' && <IconVerifyStyled src={IconSuccess} />}
                      </TextStyled>
                    </Box>
                    <Box>
                      <TextStyled>{capitalizeFirstLetter(application?.user?.accountType)}</TextStyled>
                    </Box>
                  </Box>
                </SelectBoxItem>
              );
            })}
        </SelectBox>
      </Box>
      <Box mt={3}>
        <LabelStyled>Assign Interviewer</LabelStyled>
        <Box mt={1}>
          <NoteStyled display="inline">
            Interviewer{' '}
            <Typography display="inline" color="error">
              (Choose 2 interviews)
            </Typography>
          </NoteStyled>
        </Box>
        <SearchInterviewer searchData={search} loading={loadingSearch} _onSearch={_onSearch} _onReset={_onReset} />
        {renderSelectedInterviewer()}
        <SelectBox style={{ overflowY: 'auto' }}>
          {interviewers?.map((interview, index) => {
            return (
              <SelectBoxItem key={interview.id} p={1} index={index}>
                <Box display="flex" flex={1} pl={'10px'}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <CheckboxCustom
                          defaultChecked={false}
                          checked={idItemSelected?.includes(interview['id'])}
                          onChange={handleItemCheckboxChange(interview['id'])}
                        />
                      }
                      label={
                        <InterviewNameStyled>
                          {interview.surname}, {interview.givenName}
                        </InterviewNameStyled>
                      }
                    />
                  </FormGroup>
                </Box>
              </SelectBoxItem>
            );
          })}
        </SelectBox>
      </Box>
    </CustomFormDialog>
  );
};

export default AssignModal;
