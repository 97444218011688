import React, { useEffect, useState } from 'react';

import { Box } from '@material-ui/core';

import { CheckboxCustom, InputField } from 'src/components';
import { ISignUpReq } from 'src/services/auth';
import { intRegex } from 'src/utils/common';
import useResponsive from 'src/utils/responsive';

import { CustomLabelCheckBox } from './types';

const MAXIMUM_GRADUATE_YEAR_LENGTH = 4;

export const InputCheckBox = ({
  school,
  data,
  setData,
  errors,
  setErrors,
  youthMember,
  type,
}: {
  data: ISignUpReq;
  setData: React.Dispatch<React.SetStateAction<ISignUpReq>>;
  school: {
    label: string;
    name: string;
    checked: boolean;
    graduateYear: string | null;
  };
  errors: any;
  setErrors: React.Dispatch<React.SetStateAction<any>>;
  youthMember?: boolean;
  type?: 'createUser';
}) => {
  const [graduateYear, setGraduateYear] = useState(school.graduateYear);
  const date = data.dateOfBirth ?? '';
  const dob = new Date(date.toString()).getFullYear() + 1;
  const { isTabletOrMobile } = useResponsive();

  let currentYear = new Date().getFullYear();

  function _handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setGraduateYear(e.target.value);

    if (type === 'createUser') {
      if (e.target.value !== null || (e.target.value !== null && Number(e.target.value) >= Number(dob))) {
        setErrors({ ...errors, graduateSchool: false });
      }
    } else {
      if (youthMember) {
        if (e.target.value !== null || (e.target.value !== null && Number(e.target.value) > Number(dob))) {
          setErrors({ ...errors, graduateSchool: false });
        }
      } else {
        if (
          e.target.value !== null ||
          (e.target.value !== null &&
            Number(e.target.value) < Number(currentYear) &&
            Number(e.target.value) >= Number(dob))
        ) {
          setErrors({ ...errors, graduateSchool: false });
        }
      }
    }
  }

  const onCheckBox = (graduateYear: string | null) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (data.dateOfBirth) {
      if (event.target.checked === false) {
        setGraduateYear(null);
        setErrors({ ...errors, graduateSchool: false });
      }

      setData({
        ...data,
        [event.target.name]: event.target.checked
          ? graduateYear === null
            ? isNaN(dob)
              ? currentYear
              : dob
            : graduateYear
          : null,
      });
    }
  };

  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    if (type === 'createUser') {
      if (graduateYear === null || Number(graduateYear) < Number(dob) || !intRegex.test(graduateYear)) {
        setErrors({ ...errors, graduateSchool: true });
      }
    } else {
      if (youthMember) {
        if (
          graduateYear === null ||
          (graduateYear !== null && Number(graduateYear) < Number(dob)) ||
          !intRegex.test(graduateYear)
        ) {
          setErrors({ ...errors, graduateSchool: true });
        }
      } else {
        if (
          graduateYear === null ||
          (graduateYear !== null && Number(graduateYear) >= Number(currentYear)) ||
          Number(graduateYear) < Number(dob) ||
          !intRegex.test(graduateYear)
        ) {
          setErrors({ ...errors, graduateSchool: true });
        }
      }
    }

    setData({
      ...data,
      [school.name]: graduateYear === '' ? null : graduateYear,
    });
  };

  useEffect(() => setGraduateYear(school.graduateYear), [data, school.graduateYear]);

  return (
    <Box key={school.name} display="flex" alignItems="center">
      <Box flex={isTabletOrMobile ? 3 : 1} pr={1} display="flex" flexDirection="row">
        <CheckboxCustom
          id={school.name}
          name={school.name}
          checked={school.checked}
          onChange={onCheckBox(graduateYear)}
        />
        <CustomLabelCheckBox>{school.label}</CustomLabelCheckBox>
      </Box>
      <Box flex={isTabletOrMobile ? 2 : 1} pl={1}>
        <InputField
          value={graduateYear === null ? '' : graduateYear}
          disabled={!school.checked}
          margin="dense"
          fullWidth
          onChange={_handleChange}
          onBlur={onBlurField}
          InputProps={{
            inputProps: {
              maxLength: MAXIMUM_GRADUATE_YEAR_LENGTH,
            },
          }}
        />
      </Box>
    </Box>
  );
};
