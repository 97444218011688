import React, { useCallback, useLayoutEffect } from 'react';

import { Route, useHistory } from 'react-router-dom';

import { localStorageHelper } from 'src/helpers';
import { IRoute } from 'src/routes/config';
import { saveCurrentPath } from 'src/store/authentication/slices';
import { useAppDispatch } from 'src/store/hooks';

interface IRestrictedRouteProps extends IRoute {
  isPrivate?: boolean;
}

const params = new URLSearchParams(window.location.search);
let type: string | null;
let eventId: string | null;
if (params) {
  eventId = params.get('eventId');
  type = params.get('type');
}

export const RestrictedRoute: React.FC<IRestrictedRouteProps> = ({
  children,
  restricted = false,
  isPrivate,
  layout: Layout,
  ...props
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const redirect = useCallback(() => {
    if (isPrivate && !localStorageHelper.isLogin()) {
      dispatch(saveCurrentPath({ path: window.location.pathname }));
      history.replace('/login');
    }

    if (restricted && localStorageHelper.isLogin()) {
      if (type && (type === 'check-in' || type === 'check-out') && eventId) {
        return;
      } else {
        history.replace('/');
      }
    }
  }, [isPrivate, history, restricted]);

  useLayoutEffect(() => {
    redirect();
  }, [redirect]);

  const Component = Layout ? <Layout>{children}</Layout> : children;

  return <Route {...props}>{Component}</Route>;
};
