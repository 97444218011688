import React, { useEffect, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import Banner from 'src/components/banner';
import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import { CustomButton } from 'src/components/button';
import { HeaderMobile } from 'src/components/headerMobile';
import { HeaderMobileChinese } from 'src/components/headerMobileChinese';
import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import CommonSlide from 'src/components/sliders';
import {
  BoxContent,
  BoxItem,
  Description,
  NavLinkCustom,
  TextTime,
  ThumbnailPost,
  Title,
} from 'src/components/sliders/type';
import { CALL_SUCCESS, FORMAT_DATE_DMY } from 'src/constants/common';
import useFetchMe from 'src/hooks/use-fetch-me';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { getPublicPost, IPost } from 'src/services/news';
import { IMeta } from 'src/types';
import useResponsive from 'src/utils/responsive';

import { BannerMainTitle } from '../about-us/components/nsaa';
import { IResponsive } from '../home/homepageStyled';

import Post from './Post';

const NEWS_BANNER = 'https://nsaa-images.s3.ap-southeast-1.amazonaws.com/1643084955-Nanayna%203%404x-100%20%281%29.jpg';
const NEWS_TITLE = 'News';
// const NEWS_DESCRIPTION =
//   " Stay up to date with what's happening at Nanyang School and within the Nanyang alumni community.";
const NEWS_CHINESE_TITLE = '消息';
// const NEWS_CHINESE_DESCRIPTION = '随时了解南洋学校和南洋校友社区的最新动态。';
export const News = () => {
  const history = useHistory();
  const isChinesePage = history.location.pathname.slice(0, 3) === '/cn';
  useScrollToTop();
  const responsive = useResponsive();
  const { me } = useFetchMe();
  const { pathname } = window.location;
  const [posts, setPosts] = useState<IPost[]>([]);
  const [splitPosts, setSplitPost] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [meta, setMeta] = useState<IMeta>();
  const [isLoading, setLoading] = useState(false);

  const limit = 6;
  const getPublicPostFunc = async ({
    page = 6,
    limit = 1,
    isChinesePage,
  }: {
    page?: number;
    limit?: number;
    isChinesePage: boolean;
  }) => {
    setLoading(true);
    const params = {
      page,
      limit,
      language: isChinesePage ? 'chinese' : 'english',
    };
    const response = await getPublicPost({ ...params });
    if (response.statusCode === CALL_SUCCESS && response.data) {
      setMeta(response.data.meta);
      setPosts((news) => [...news, ...response?.data?.items]);
    }
    setLoading(false);
  };
  useEffect(() => {
    getPublicPostFunc({ page, limit, isChinesePage });
  }, [page]);
  useEffect(() => {
    let array = [];
    if (posts) {
      let i,
        j,
        temporary,
        chunk = 3;
      for (i = 0, j = posts.length; i < j; i += chunk) {
        temporary = posts.slice(i, i + chunk);
        array.push(temporary);
      }
    }
    setSplitPost(array);
  }, [posts]);
  const handleViewMoreEvent = () => {
    if (meta?.currentPage && meta?.totalPages && meta?.currentPage < meta?.totalPages) {
      setPage((page) => page + 1);
    }
  };
  const renderNews = (item: IPost) => {
    return (
      <BoxItem display="flex" justifyContent="space-between" alignItems="center" flexDirection="column">
        <ThumbnailPost src={item?.coverPhoto} />
        <BoxContent p={3} responsive={responsive}>
          <TextTime>{moment(+item?.publishTime!).format(FORMAT_DATE_DMY)}</TextTime>
          <NavLinkCustom to={isChinesePage ? `/cn/news/${item.id}` : `/news/${item.id}`}>
            <Title>{item?.title}</Title>
          </NavLinkCustom>
          <Description>{item?.shortDescription || 'No description'}</Description>
        </BoxContent>
      </BoxItem>
    );
  };
  return (
    <Box display={'flex'} flex={1} flexDirection={'column'} pt={3.75}>
      {responsive.isTabletOrMobile && !isChinesePage ? (
        <HeaderMobile title={`News`} userProfile={me} />
      ) : (
        <HeaderMobileChinese title={NEWS_CHINESE_TITLE} />
      )}
      {!responsive.isTabletOrMobile && !isChinesePage && <NSAABreadcrumbs path={pathname} />}
      {responsive.isTabletOrMobile && <Box mt={'90px'} />}
      <Banner
        image={NEWS_BANNER}
        title={isChinesePage ? NEWS_CHINESE_TITLE : NEWS_TITLE}
        // description={isChinesePage ? NEWS_CHINESE_DESCRIPTION : NEWS_DESCRIPTION}
        description={''}
        responsive={responsive}
      />
      <BannerMainTitle style={{ paddingTop: !responsive.isMobile ? 80 : 30 }}>
        {isChinesePage ? '消息' : 'News'}
      </BannerMainTitle>
      <LoadingCustom loading={isLoading} loadingIcon />
      {responsive.isTabletOrMobile ? (
        splitPosts &&
        splitPosts?.length > 0 &&
        splitPosts.map((splitPost: any) => {
          return (
            <NewsContainer responsive={responsive}>
              <CommonSlide data={splitPost} render={renderNews} />
            </NewsContainer>
          );
        })
      ) : (
        <Box maxWidth={1440} mx={'auto'}>
          <PostContainer
            postLength={posts && posts?.length > 0 ? true : false}
            display="flex"
            flexWrap="wrap"
            style={{ gap: 24 }}
          >
            {posts &&
              posts?.length > 0 &&
              posts?.map((post) => {
                return <Post post={post} isChinesePage={isChinesePage} />;
              })}
          </PostContainer>
        </Box>
      )}
      {!isLoading && (
        <Box justifyContent="center" display="flex" flex={1} mb={12.5} mt={responsive.isTabletOrMobile ? 12.5 : 0}>
          <CustomButton
            disabled={meta?.currentPage === meta?.totalPages}
            style={{ width: 250, borderRadius: 7 }}
            onClick={handleViewMoreEvent}
          >
            {isChinesePage ? '更多消息' : `MORE NEWS`}
          </CustomButton>
        </Box>
      )}
    </Box>
  );
};
const PostContainer = styled(Box)<{ postLength?: boolean }>`
  ${({ theme, postLength }) => ({
    padding: postLength ? '80px' : '20px',
  })}
`;

const NewsContainer = styled(Grid)<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    && {
      width: 100%;
      height: auto;
      margin: auto;
      padding: ${responsive?.isMobile ? '0px' : '0  80px 0 80px'};
      margin-bottom: ${responsive?.isMobile ? '64px' : '0px'};
    }
  `}
`;
