import React from 'react';

import { Box, Typography } from '@material-ui/core';
import styled from 'styled-components';

import { CustomButton } from 'src/components';
import SuccessIcon from 'src/medias/images/success-logo.svg';
import useResponsive from 'src/utils/responsive';

export const ImageStyled = styled.img(() => ({}));

export const ContentStyled = styled(Typography)(() => ({
  fontSize: 24,
  fontWeight: 700,
  color: 'rgba(51, 51, 51, 1)',
  lineHeight: 1.5,
}));

const Finish = () => {
  const { isTabletOrMobile, isTablet } = useResponsive();

  const _onSubmit = () => {
    window.location.href = '/';
  };

  return (
    <Box display="flex" alignItems="center" flex={1} flexDirection="column" mt={6} p={3}>
      <ImageStyled src={SuccessIcon} />
      <Box mt={'60px'} mb={'60px'}>
        <ContentStyled style={{ textAlign: 'center' }}>
          Thank you for your contribution to our community! <br />
          Please be patient! Your account will be upgrade to Life Member after <br /> we receive your membership fee and
          approve your account
        </ContentStyled>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        flex={1}
        width={'100%'}
        alignItems={isTabletOrMobile ? 'flex-end' : 'flex-start'}
        mb={4}
      >
        <CustomButton style={{ width: isTablet && '100%' }} onClick={_onSubmit}>
          Go back to Homepage
        </CustomButton>
      </Box>
    </Box>
  );
};

export default Finish;
