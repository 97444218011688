import React, { useState } from 'react';

import { Box, FormControlLabel, Typography } from '@material-ui/core';
import _ from 'lodash';
import { useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { CheckboxCustom, CustomButton, InputField } from 'src/components';
import Banner from 'src/components/banner';
import { NSAABreadcrumbs } from 'src/components/breadcrumbs';
import { HeaderMobile } from 'src/components/headerMobile';
import { HeaderMobileChinese } from 'src/components/headerMobileChinese';
import { LoadingCustom } from 'src/components/loading/LoadingCustom';
import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import { CALL_SUCCESS } from 'src/constants/common';
import { useScrollToTop } from 'src/hooks/use-scroll-to-top';
import { mailingSend } from 'src/services/auth';
import useResponsive from 'src/utils/responsive';

import { BannerMainTitle } from '../about-us/components/nsaa';
import { IResponsive } from '../home/homepageStyled';

const MAXIMUM_USERNAME_LENGTH = 50;
const TEXT_REQUIRE = 'This field is required';
const EMAIL_TEXT_CORRECT = 'Please fill in a correct email';
interface IPolicyForm {
  surname: string;
  givenName: string;
  emailAddress: string;
  isConfirmPolicy: boolean;
}
const InputForm = styled(Box)`
  ${({ theme }) => css`
    margin: auto;
    margin-top: 50px;
  `}
`;
const TextBox = styled(Typography)`
  ${({ theme }) => css`
    padding: 24px;
    border: 0.25px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin: 30px 0px;
    color: ${theme.palette.gray?.[1000]};
    background-color: ${theme.palette.gray?.[200]};
    border-radius: 8px;
  `}
`;
const Text = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.textSS.fontSize};
    font-weight: ${theme.fonts.textSS.fontWeight};
    line-height: ${theme.fonts.textSS.lineHeight};
    margin-bottom: 10px;
  `}
`;
const TextMB = styled(Typography)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.textSS.fontSize};
    font-weight: ${theme.fonts.textSS.fontWeight};
    line-height: ${theme.fonts.textSS.lineHeight};
    margin-bottom: 30px;
  `}
`;
const SubmitButton = styled(CustomButton)<{ disable?: boolean }>`
  ${({ theme, disable }) => css`
    && {
      margin: auto;
      width: 200px;
      margin-top: 30px;
      margin-bottom: 50px;
      display: block;
    }
  `}
`;
const Form = styled.form<{ responsive?: IResponsive }>`
  ${({ theme, responsive }) => css`
    max-width: 846px;
    margin: 0 auto;
    padding: ${responsive?.isMobile ? '16px' : '64px'};
  `}
`;
export const LabelCheckbox = styled(Box)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.textSS.fontSize};
    font-weight: ${theme.fonts.textSS.fontWeight};
    font-style: italic;
    color: ${theme.palette.gray?.[1000]};
  `}
`;
function SubscriptionCenter() {
  const history = useHistory();
  const isChinesePage = history.location.pathname.slice(0, 3) === '/cn';
  useScrollToTop();
  const responsive = useResponsive();
  const [data, setData] = useState<IPolicyForm>({
    surname: '',
    givenName: '',
    emailAddress: '',
    isConfirmPolicy: false,
  });
  const [errors, setErrors] = useState({
    surname: false,
    givenName: false,
    emailAddress: false,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [apiStatus, setApiStatus] = React.useState<number | null>();
  const [errConfirmMess, setErrConfirmMess] = React.useState<string>('');
  const { path } = useRouteMatch();
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setData({ ...data, [e.target.name]: e.target.value });
    setValidate(e.target.name, e.target.value);
  }
  function handleCloseSnackBar(type?: 'success' | 'error') {
    return () => {
      if (type === 'success') {
        setLoading(true);
        setData({
          surname: '',
          givenName: '',
          emailAddress: '',
          isConfirmPolicy: false,
        });
        setLoading(false);
      }
      setErrConfirmMess('');
      setOpenSnackBar(false);
    };
  }
  const _onConfirmPolicy = () => (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, isConfirmPolicy: e.target.checked });
  };
  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    let validErrors = errors;
    e.preventDefault();
    if (_.isEmpty(data.emailAddress)) {
      validErrors.emailAddress = true;
      setErrors({ ...errors, emailAddress: true });
    }
    if (_.isEmpty(data.surname)) {
      validErrors.surname = true;
      setErrors({ ...errors, surname: true });
    }
    if (_.isEmpty(data.givenName)) {
      validErrors.givenName = true;
      setErrors({ ...errors, givenName: true });
    }
    if (validErrors.surname || validErrors.emailAddress || validErrors.givenName) {
      return;
    } else {
      setLoading(true);
      const response = await mailingSend({
        surname: data.surname,
        givenName: data.givenName,
        emailAddress: data.emailAddress,
      });
      setApiStatus(response?.statusCode);
      if (response?.statusCode === CALL_SUCCESS) {
        setLoading(false);
        setOpenSnackBar(true);
        setData({
          surname: '',
          givenName: '',
          emailAddress: '',
          isConfirmPolicy: false,
        });
        setErrConfirmMess('');
      } else {
        if (response.message) {
          setErrConfirmMess(response.message);
        } else {
          setErrConfirmMess('Something wrong, please try again later');
        }
        setOpenSnackBar(true);
        setLoading(false);
      }
    }
  }
  const setValidate = (name: any, val: any) => {
    if (_.isEmpty(val)) {
      setErrors({ ...errors, [name]: true });
    } else {
      setErrors({ ...errors, [name]: false });
    }
  };
  const onBlurField = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    const valueTrimed = e.target.value.trim();
    setData({ ...data, [e.target.name]: valueTrimed });
  };
  return (
    <Box pt={responsive.isTabletOrMobile ? 15 : 3.75}>
      <LoadingCustom loading={loading} loadingIcon />
      {responsive.isTabletOrMobile && !isChinesePage ? (
        <HeaderMobile title={`Subscription Centre`} />
      ) : (
        <HeaderMobileChinese title={`加入我们的邮件列表`} />
      )}
      {!responsive.isTabletOrMobile && <NSAABreadcrumbs path={path} />}
      <Banner
        image="https://gcdn.pbrd.co/images/bZXXNe5dP569.png?o=1"
        title="SUBSCRIPTION CENTRE"
        description="Subscribe to our mailing list to stay in touch with us for faster update"
        responsive={responsive}
      />
      <BannerMainTitle style={{ paddingTop: !responsive.isMobile ? 150 : 30 }}>Subscription Centre</BannerMainTitle>
      <Form responsive={responsive} onSubmit={handleSubmit}>
        <InputForm>
          <Box mb={3}>
            <InputField
              value={data.surname}
              onChange={handleChange}
              onBlur={onBlurField}
              require
              title="Your Surname"
              name="surname"
              margin="dense"
              fullWidth
              InputProps={{
                inputProps: {
                  maxLength: MAXIMUM_USERNAME_LENGTH,
                },
              }}
              error={errors.surname}
              errormessage={errors.surname && TEXT_REQUIRE}
            />
          </Box>
          <Box mb={3}>
            <InputField
              value={data.givenName}
              onChange={handleChange}
              onBlur={onBlurField}
              require
              title="Your Given Name"
              name="givenName"
              margin="dense"
              fullWidth
              InputProps={{
                inputProps: {
                  maxLength: MAXIMUM_USERNAME_LENGTH,
                },
              }}
              error={errors.givenName}
              errormessage={errors.givenName && TEXT_REQUIRE}
            />
          </Box>
          <Box mb={3}>
            <InputField
              value={data.emailAddress}
              onChange={handleChange}
              name="emailAddress"
              title="Email Address"
              type="emailAddress"
              margin="dense"
              require
              fullWidth
              InputProps={{
                inputProps: {
                  maxLength: MAXIMUM_USERNAME_LENGTH,
                },
              }}
              error={errors.emailAddress}
              errormessage={
                errors.emailAddress && data.emailAddress === ''
                  ? TEXT_REQUIRE
                  : errors.emailAddress && EMAIL_TEXT_CORRECT
              }
            />
          </Box>

          <TextBox>
            <TextMB>
              Nanyang Schools Alumni Association (NSAA) may collect, use and disclose my personal data, as provided in
              this application form, or otherwise obtained by NSAA as a result of my association with NSAA, in
              accordance with NSAA's data protection policy (available at www.nanyang.org.sg) and the Personal Data
              Protection Act 2012, for the following purposes:
            </TextMB>

            <Text>(A) processing of my mailing list application;</Text>
            <Text>(B) administering my association with NSAA;</Text>
            <Text>(C) informing me of NSAA events and activities;</Text>
            <Text>(D) informing me of volunteering opportunities with Nanyang Schools and NSAA;</Text>
            <Text>(E) attending to my enquiries and feedback; and</Text>
            <Text>(F) sending me NSAA publications</Text>
          </TextBox>
          <FormControlLabel
            control={
              <CheckboxCustom
                checked={data.isConfirmPolicy}
                value={data.isConfirmPolicy}
                onChange={_onConfirmPolicy()}
              />
            }
            label={
              <LabelCheckbox>
                I have read and agreed to the{' '}
                <Link style={{ textDecoration: 'underline', color: 'inherit' }} to={'/privacy-policy'}>
                  Privacy Policy
                </Link>
                <Box component={'span'} mx={0.5}>
                  &
                </Box>
                <Link style={{ textDecoration: 'underline', color: 'inherit' }} to={'/terms-of-use'}>
                  Terms Of Use
                </Link>
              </LabelCheckbox>
            }
          />
          <SubmitButton type="submit" disabled={!data.isConfirmPolicy}>
            SUBMIT
          </SubmitButton>
        </InputForm>
        {!loading && apiStatus === CALL_SUCCESS && openSnackBar && (
          <SuccessSnackBar
            message={'You have successfully subscribed to our mailing list.'}
            open={openSnackBar}
            handleClose={handleCloseSnackBar('success')}
          />
        )}
        {!loading && apiStatus !== CALL_SUCCESS && openSnackBar && (
          <ErrorSnackBar message={errConfirmMess} open={openSnackBar} handleClose={handleCloseSnackBar('error')} />
        )}
      </Form>
    </Box>
  );
}

export default SubscriptionCenter;
