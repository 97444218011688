import React from 'react';

import { Box } from '@material-ui/core';

import DefaultAvatar from 'src/medias/images/default_avatar.png';
import { Avatar, BasicInfo, Label, Text } from 'src/pages/profile/shared/profile.image.styled';
import { IApplicant } from 'src/services/gtky-applicant';

import { Actions, ApplicationActionButton, BoxStyled, Container } from '../types';

interface IApplicationInformationHeaderProps {
  imageUrl: string;
  nsaaId: string;
  accountType: string;
  interviewer: string;
  applicant?: IApplicant;
  handleBack: () => void;
  handleAssignInterview: () => void;
  handleVerify: () => void;
}

const ApplicationInformationHeader = ({
  imageUrl,
  nsaaId,
  accountType,
  interviewer,
  handleBack,
  handleAssignInterview,
  handleVerify,
  applicant,
}: IApplicationInformationHeaderProps) => {
  const UNVERIFIED = 'unverified';
  return (
    <Container>
      <Avatar imageUrl={imageUrl || DefaultAvatar} />
      <BoxStyled>
        <Box alignSelf="flex-end">
          <BasicInfo>
            <Box mb={2.5} display="flex">
              <Label>NSAA ID:</Label>
              <Text>{nsaaId}</Text>
            </Box>
            <Box mb={2.5} display="flex">
              <Label>Account Type</Label>
              <Text>{accountType}</Text>
            </Box>
            <Box display="flex">
              <Label>Interviewer:</Label>
              <Text>{interviewer}</Text>
            </Box>
          </BasicInfo>
        </Box>
        <Actions>
          <ApplicationActionButton variants="outlined" onClick={handleBack}>
            Back
          </ApplicationActionButton>
          {applicant?.verificationStatus === UNVERIFIED && (
            <ApplicationActionButton variants="outlined" onClick={handleAssignInterview}>
              Assign Interviewer
            </ApplicationActionButton>
          )}
          {applicant?.verificationStatus === UNVERIFIED && (
            <ApplicationActionButton variants="yellow" onClick={handleVerify}>
              Verify
            </ApplicationActionButton>
          )}
        </Actions>
      </BoxStyled>
    </Container>
  );
};

export default ApplicationInformationHeader;
