import React, { useCallback, useState } from 'react';

import { Box, Tooltip } from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import ErrorSnackBar from 'src/components/snackBar/error';
import SuccessSnackBar from 'src/components/snackBar/success';
import NSAATable, { INSAAColumn, INSAADataSource, INSAATableSearch } from 'src/components/table';
import { CALL_SUCCESS, FORMAT_TIME_AM_PM } from 'src/constants/common';
import IconSuccess from 'src/medias/icons/icon-success.png';
import ViewIcon from 'src/medias/icons/view-icon.png';
import { ActionButton } from 'src/pages/event-management/components/guest/shared/button.component';
import TooltipStyled from 'src/pages/event-management/components/guest/shared/tooltip.component';
import { FormMenuItem, FormTextField, Label } from 'src/pages/profile/components/general-information.styled';
import ProfilePopupLayout from 'src/pages/profile/layout/profile-popup.layout';
import { ConfirmModal } from 'src/pages/user-management/components/ConfirmModal';
import { Actions, IconButtonStyled, IconVerifyStyled, ImageIconStyled } from 'src/pages/user-management/types';
import { IEvent, registrationEventAdmin } from 'src/services/events';
import { exportGuestListCSV } from 'src/services/gtky-applicant';
import { getGuestListByEventId, IRegistrationResponse } from 'src/services/guests';
import { markAsAttended, resendTicket } from 'src/services/tickets';
import { IUser, sendEmail } from 'src/services/users';

import CreateTicket from './CreateTicket';
import { ActionBar, ButtonStatus, ListingLabel } from './index.styled';
import SendEmailModal from './SendMail';
import { ActionButtonPopover } from './shared/button.component';
import TicketInformation from './TicketInformation';
import { DEFAULT_RESPONSE, GUEST_ACTIONS, REGISTRATION_STATUS } from './types';

const GuestList = ({ event }: { event: IEvent }) => {
  const { id } = useParams<{ id: string }>();
  const [isCreateTicketLoading, setCreatTicketLoading] = useState(false);
  const [search, setSearch] = useState<INSAATableSearch>();
  const [isShowSnackBarError, setShowSnackBarError] = useState(false);
  const [isShowSnackBarSuccess, setShowSnackBarSuccess] = useState(false);
  const [message, setMessage] = useState('');
  const [isShowConfirmPopup, setShowConfirmPopup] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [formSearch, setFormSearch] = useState<{ name: string; status: string }>({
    name: '',
    status: '',
  });
  const [isSearching, setSearching] = useState(false);
  const [isShowCreateTicket, setShowCreateTicket] = useState(false);
  const [isShowTicketInformation, setShowTicketInformation] = useState(false);
  const [isShowOpenSendMail, setShowOpenSendMail] = useState(false);
  const [ticketInformationId, setTicketInformationId] = useState<any>();
  const [userSelected] = React.useState<IUser[]>([]);
  const [idsSelected, setIdsSelected] = React.useState<any>();
  const [template, setTemplate] = useState<string>('none');
  const [actionSelected, setActionSelected] = useState('');
  const [loadingAction, setLoadingAction] = React.useState(false);

  const SEND_MAIL = 'send-email';
  const RESEND_TICKET = 'resend-ticket';
  const MARK_AS_ATTENDED = 'mark-as-attended';
  const ENTER_AT_LEAST_3_CHARACTER = 'Enter at least 3 characters';
  const MAX_LENGTH_50 = 50;

  const handleCloseSnackBar = (type: 'success' | 'error') => {
    return () => {
      if (type === 'error') {
        setShowSnackBarError(false);
      }
      if (type === 'success') {
        setShowSnackBarSuccess(false);
      }
    };
  };
  const handleTogglePopupInformation = () => setShowConfirmPopup((status) => !status);

  const handleChangeAction = async (action: string) => {
    setActionSelected(action);

    if (action === SEND_MAIL) {
      setShowOpenSendMail(true);
    }
    if (action === RESEND_TICKET) {
      (async () => {
        const response = await resendTicket(
          id,
          idsSelected?.map?.((item: any) => item?.registrationId),
        );
        if (response?.statusCode === CALL_SUCCESS) {
          setShowSnackBarSuccess(true);
          setMessage(response.message);
          setFormSearch((formSearch) => ({ ...formSearch }));
        } else {
          setShowSnackBarError(true);
          setMessage(response.message);
        }
      })();
    }
    if (action === MARK_AS_ATTENDED) {
      (async () => {
        const response = await markAsAttended(
          id,
          idsSelected?.map?.((item: any) => item?.registrationId),
        );
        if (response?.statusCode === CALL_SUCCESS) {
          setShowSnackBarSuccess(true);
          setMessage(response.message);
          setFormSearch((formSearch) => ({ ...formSearch }));
        } else {
          setShowSnackBarError(true);
          setMessage(response.message);
        }
      })();
    }
  };

  const builderColumns = useCallback(() => {
    const columns: INSAAColumn[] = [
      {
        key: 'NSAAID',
        dataIndex: 'NSAAID',
        title: 'NSAA ID',
        width: 50,
        ellipsis: true,
        render: (value, record) => {
          return <Box>{value}</Box>;
        },
      },
      {
        key: 'Name',
        dataIndex: 'Name',
        title: 'Name',
        width: 150,
        ellipsis: true,
        render: (value, record) => {
          const user: IUser = record?.['user'];
          return (
            <Tooltip placement="top-start" title={value}>
              <Box display="flex">
                <Box>{value?.length > 13 ? value?.slice(0, 13) + '...' : value || ''}</Box>
                {user && user?.verificationStatus === 'verified' && <IconVerifyStyled src={IconSuccess} />}
              </Box>
            </Tooltip>
          );
        },
      },
      {
        key: 'EmailAddress',
        dataIndex: 'EmailAddress',
        title: 'Email Address',
        width: 130,
        ellipsis: true,
        render: (value, record) => {
          return (
            <Tooltip placement="top-start" title={value}>
              <Box display="flex">{value?.length <= 10 ? value : value?.slice(0, 10) + '...'}</Box>
            </Tooltip>
          );
        },
      },
      {
        key: 'PhoneNumber',
        dataIndex: 'PhoneNumber',
        title: 'Phone Number',
        width: 100,
        ellipsis: true,
        render: (value, record) => {
          return (
            <Tooltip placement="top-start" title={value}>
              <Box display="flex">{value?.length >= 10 ? value?.slice(0, 10) + '...' : value || ''}</Box>
            </Tooltip>
          );
        },
      },
      {
        key: 'Status',
        dataIndex: 'Status',
        title: 'Registration Status',
        width: 112,
        ellipsis: true,
        render: (value, record) => {
          let variants: 'pending' | 'confirmed' | 'expired' | 'attended' | undefined =
            value === 'pending'
              ? 'pending'
              : value === 'expired'
              ? 'expired'
              : value === 'confirmed' || value === 'attended'
              ? 'confirmed'
              : undefined;
          return (
            <Box>
              <ButtonStatus variants={variants}>{value}</ButtonStatus>
            </Box>
          );
        },
      },
      {
        key: 'RegistrationTime',
        dataIndex: 'RegistrationTime',
        title: 'Registration Time',
        width: 150,
        ellipsis: true,
        render: (value, record) => {
          return <Box>{value}</Box>;
        },
      },
      {
        key: 'Actions',
        title: '',
        dataIndex: ' Actions',
        width: 40,
        render: (value, record) => {
          return (
            <Actions>
              <Tooltip title="View More">
                <IconButtonStyled
                  onClick={() => {
                    setShowTicketInformation(true);
                    setTicketInformationId(record.key);
                  }}
                >
                  <ImageIconStyled src={ViewIcon} />
                </IconButtonStyled>
              </Tooltip>
            </Actions>
          );
        },
      },
    ] as INSAAColumn[];

    return columns;
  }, []);

  const builderDataSource = (data: IRegistrationResponse[]) => {
    const dataSource = data.map((item, index) => {
      return {
        key: item?.id,
        id: item?.guest,
        user: item?.user,
        NSAAID: item?.user ? item?.user?.username : '',
        Name: item?.guest ? item?.guest.fullName : item?.user?.surname + ', ' + item.user?.givenName,
        EmailAddress: item?.guest ? item?.guest?.email : item?.user?.emailAddress,
        PhoneNumber: item?.guest ? item.guest.mobilePhone : item?.user?.mobilePhone,
        Status: item?.registrationStatus,
        RegistrationTime: item.registrationTime ? moment(+item.registrationTime).format(FORMAT_TIME_AM_PM) : '',
      };
    });
    return dataSource as unknown as INSAADataSource[];
  };

  const fetchData = useCallback(
    async (search: INSAATableSearch) => {
      const searchFields = {
        ...search,
        ...formSearch,
      };

      setSearch({ ...search, ...formSearch });

      setLoading(true);
      const response = await getGuestListByEventId(id, searchFields);

      if (response?.statusCode === CALL_SUCCESS) {
        setLoading(false);
        setSearching(false);
        return response;
      }
      setLoading(false);
      setSearching(false);
      return DEFAULT_RESPONSE;
    },
    [formSearch, id],
  );

  const handleToggleShowTicketInformation = () => setShowTicketInformation((toggle) => !toggle);
  const handleToggleShowCreateTicket = () => setShowCreateTicket((toggle) => !toggle);
  const handleToggleSendMail = () => setShowOpenSendMail((toggle) => !toggle);

  const handleSubmitCreateTicket = async (val: any) => {
    setCreatTicketLoading(true);
    const response = await registrationEventAdmin({ id, ...val });
    if (response?.statusCode === CALL_SUCCESS) {
      setCreatTicketLoading(false);
      setShowSnackBarSuccess(true);
      setMessage(response.message);
      setShowCreateTicket(false);
      setFormSearch((formSearch) => ({ ...formSearch }));
    } else {
      setCreatTicketLoading(false);
      setShowSnackBarError(true);
      setMessage(response.message);
    }
  };

  const onSelectedItems = (ids: any) => {
    setIdsSelected(ids);
  };

  const handleSendMailSubmit = () => {
    setShowConfirmPopup(true);
  };

  const handleActionSubmit = async () => {
    setLoadingAction(true);
    if (actionSelected === SEND_MAIL) {
      if (userSelected && idsSelected) {
        let emails: string[] = [];

        [...new Set([...idsSelected, ...userSelected])].map((item) => {
          return emails.push(item.email);
        });
        const response = await sendEmail({
          emails,
          templateId: template,
        });
        if (response?.statusCode === CALL_SUCCESS) {
          setFormSearch((formSearch) => ({ ...formSearch }));
          setShowSnackBarSuccess(true);
          setMessage(response.message);
        } else {
          setShowSnackBarError(true);
          setMessage(response.message);
        }
        setLoadingAction(false);
        handleToggleSendMail();
        handleTogglePopupInformation();
        return;
      }
    }
  };

  const handleExportCSV = async () => {
    const filters = {
      limit: search?.limit!,
      page: search?.page!,
      type: 'guest',
      name: formSearch.name,
      status: formSearch.status,
    };
    await exportGuestListCSV(event.id, filters);
  };

  return (
    <Box py={3}>
      <SuccessSnackBar open={isShowSnackBarSuccess} handleClose={handleCloseSnackBar('success')} message={message} />
      <ErrorSnackBar open={isShowSnackBarError} handleClose={handleCloseSnackBar('error')} message={message} />

      <ConfirmModal
        open={isShowConfirmPopup}
        handleClose={handleTogglePopupInformation}
        onSubmit={handleActionSubmit}
        actionSelected={actionSelected}
        disabled={loadingAction}
      />
      <SendEmailModal
        loading={false}
        open={isShowOpenSendMail}
        _onCloseModal={handleToggleSendMail}
        _onSubmit={handleSendMailSubmit}
        userSelected={idsSelected?.sort((a: any, b: any) => a?.fullName.localeCompare(b.fullName))}
        template={template}
        setTemplate={setTemplate}
      />
      <Box>
        <Formik
          initialValues={{
            name: '',
            status: 'all',
          }}
          onSubmit={(values) => {
            setSearching(true);
            setFormSearch({ name: values.name.trim(), status: values.status });
          }}
          validationSchema={Yup.object({
            name: Yup.string().min(3, ENTER_AT_LEAST_3_CHARACTER).notRequired(),
          })}
        >
          {(formik) => {
            const handleReset = () => {
              formik.handleReset();
              setSearching(true);
              setFormSearch({ name: '', status: '' });
            };
            return (
              <Form>
                <Box display="flex" justifyContent="space-between">
                  <Box display="flex" style={{ gap: 24 }} width="100%">
                    <Box flex="0 1 358px">
                      <Label>
                        Guest
                        <TooltipStyled
                          message="You can search by NSAA ID, Name, Email Address or Phone number"
                          placement="top"
                        />
                      </Label>
                      <FormTextField
                        onChange={formik.handleChange}
                        value={formik.values.name}
                        name="name"
                        variant="outlined"
                        fullWidth
                        size="small"
                        placeholder="Enter at least 3 character"
                        error={formik.touched.name && !!formik.errors.name}
                        helperText={formik.touched.name && formik.errors.name}
                        onBlur={formik.handleBlur}
                        inputProps={{ maxLength: MAX_LENGTH_50 }}
                      />
                    </Box>
                    <Box flex="0 1 358px">
                      <Label>Registration Status</Label>
                      <FormTextField
                        onChange={formik.handleChange}
                        value={formik.values.status}
                        variant="outlined"
                        name="status"
                        fullWidth
                        size="small"
                        select={true}
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: 'bottom',
                              horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                          },
                        }}
                        defaultValue="all"
                      >
                        {REGISTRATION_STATUS.map(({ label, value }, index) => (
                          <FormMenuItem key={index} value={value}>
                            {label}
                          </FormMenuItem>
                        ))}
                      </FormTextField>
                    </Box>
                  </Box>
                  <Box
                    width={264}
                    ml={6}
                    display="flex"
                    justifySelf="flex-end"
                    style={{ gap: 24 }}
                    alignSelf="flex-end"
                    justifyContent="flex-end"
                  >
                    {formik.dirty && (
                      <ActionButton variants="outlined" onClick={handleReset}>
                        Reset
                      </ActionButton>
                    )}
                    <ActionButton type="submit" variants="yellow">
                      Search
                    </ActionButton>
                  </Box>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
      <Box mt={6}>
        {isShowCreateTicket && (
          <ProfilePopupLayout open={isShowCreateTicket} onClose={handleToggleShowCreateTicket}>
            <CreateTicket
              onSave={handleSubmitCreateTicket}
              onClose={handleToggleShowCreateTicket}
              isLoading={isCreateTicketLoading}
            />
          </ProfilePopupLayout>
        )}
        {isShowTicketInformation && (
          <ProfilePopupLayout open={isShowTicketInformation} onClose={handleToggleShowTicketInformation}>
            <TicketInformation onClose={handleToggleShowTicketInformation} ticketInformationId={ticketInformationId} />
          </ProfilePopupLayout>
        )}
        <ActionBar>
          <ListingLabel>Listing</ListingLabel>
          <Box display="flex" style={{ gap: 24 }}>
            <ActionButton variants="outlined" onClick={handleExportCSV}>
              Export CSV
            </ActionButton>
            <ActionButton
              variants="yellow"
              onClick={handleToggleShowCreateTicket}
              disabled={event.status !== 'pending' || !event.require_ticket}
            >
              Create Ticket
            </ActionButton>
            <ActionButtonPopover
              label="Action"
              onChange={handleChangeAction}
              items={GUEST_ACTIONS}
              disabled={idsSelected && idsSelected.length === 0}
            />
          </Box>
        </ActionBar>
        <Box mt={3}>
          <NSAATable
            isShowCheckBoxes
            isShowPagination
            fetchData={fetchData}
            builderColumns={builderColumns}
            builderDataSource={builderDataSource}
            loading={loading}
            paginationTitle="guests"
            isSearchReset={isSearching}
            onSelectedItems={onSelectedItems}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default GuestList;
