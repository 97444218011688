import { localStorageHelper } from 'src/helpers';

const EXPIRED_TIME = 'expiredTime';

class IdleTimer {
  timeout: any;
  onTimeout: any;
  eventHandler!: () => void;
  interval!: NodeJS.Timeout;
  timeoutTracker!: NodeJS.Timeout;

  constructor({ timeout, onTimeout, onExpired }: { timeout: any; onTimeout: any; onExpired: any }) {
    this.timeout = timeout;
    this.onTimeout = onTimeout;

    // If we have the expiredTime value in localStorage, we immediately call onExpired callback and then stop the execution.
    // const expiredTime = parseInt(localStorageHelper.getItem(EXPIRED_TIME), 10);
    // if (expiredTime && expiredTime > 0 && expiredTime < Date.now()) {
    //   onExpired();
    //   return;
    // }

    this.eventHandler = this.updateExpiredTime.bind(this);
    this.tracker();
    this.startInterval();
  }

  startInterval() {
    this.updateExpiredTime();

    this.interval = setInterval(() => {
      const expiredTime = parseInt(localStorageHelper.getItem(EXPIRED_TIME), 10);
      if (expiredTime < Date.now()) {
        this.onTimeout();
        this.cleanUp();
      }
    }, 1000);
  }

  updateExpiredTime() {
    if (this.timeoutTracker) {
      clearTimeout(this.timeoutTracker);
    }
    //delay 3000 milliseconds to store the expired time to localStorage
    this.timeoutTracker = setTimeout(() => {
      localStorageHelper.setItem(EXPIRED_TIME, Date.now() + this.timeout * 1000);
    }, 3000);
  }

  tracker() {
    window.addEventListener('mousemove', this.eventHandler);
    window.addEventListener('scroll', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  cleanUp() {
    // If we have the expiredTime value in localStorage, we immediately call onExpired callback and then stop the execution.
    localStorageHelper.removeItem(EXPIRED_TIME);

    clearInterval(this.interval);
    window.removeEventListener('mousemove', this.eventHandler);
    window.removeEventListener('scroll', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
}

export default IdleTimer;
