import moment from 'moment';
import { stringify } from 'query-string';

import httpClient from 'src/configs/http-client';
import { FORMAT_DATE_DMY } from 'src/constants/common';
import { IResponse, IResponseList } from 'src/types';
import { convertURL } from 'src/utils/common';

import { IPostReq, IPost } from './news.interface';

import { IPostCmsNews } from '.';

export const createDraft = async (body: IPostReq) => {
  const response = await httpClient.post(`${process.env.REACT_APP_API_URL}/news`, body);
  return response?.data;
};

export const getAllPost = async ({ search, page, limit }: { search?: any; page?: number; limit?: number }) => {
  const response = await httpClient.get<IResponseList<IPostCmsNews>>(
    `${process.env.REACT_APP_API_URL}/news?page=${search?.page ? search?.page : page}&limit=${limit}&title=${
      search?.title ? convertURL(search.title) : ''
    }&status=${search?.status ? search?.status : ''}&startDate=${
      search?.startDate ? moment(search?.startDate).format(FORMAT_DATE_DMY) : ''
    }&endDate=${search?.endDate ? moment(search?.endDate).format(FORMAT_DATE_DMY) : ''}`,
  );
  return response?.data;
};

export const getPublicPost = async ({
  page,
  limit,
  language,
}: {
  page?: number;
  limit?: number;
  language?: string;
}) => {
  const DEFAULT_LIMIT = 1;
  const DEFAULT_PAGE = 6;
  const queryString = stringify({
    page: page ?? DEFAULT_PAGE,
    limit: limit ?? DEFAULT_LIMIT,
    language: language ?? 'english',
  });
  const response = await httpClient.get<IResponseList<IPost>>(
    `${process.env.REACT_APP_API_URL}/news/public?${queryString}`,
  );
  return response?.data;
};

export const getSuggestPost = async (id: number | string) => {
  const response = await httpClient.get<IResponse<IPost[]>>(
    `${process.env.REACT_APP_API_URL}/news/suggest?exceptId=${id}`,
  );
  return response?.data;
};

export const publicPost = async (body: any) => {
  const response = await httpClient.patch(`${process.env.REACT_APP_API_URL}/news/status`, body);
  return response?.data;
};

export const getPost = async (id: number | string) => {
  const response = await httpClient.get<IResponse<IPost>>(`${process.env.REACT_APP_API_URL}/news/${id}`);
  return response?.data;
};

export const updatePost = async (id: number | string, body: IPostReq) => {
  const response = await httpClient.patch(`${process.env.REACT_APP_API_URL}/news/${id}`, body);
  return response?.data;
};
export const deletePost = async (body: string[]) => {
  const response = await httpClient.patch(`${process.env.REACT_APP_API_URL}/news/delete`, {
    ids: body,
  });
  return response?.data;
};
