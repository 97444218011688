import React from 'react';

interface ILockIcon {
  width?: number;
  height?: number;
}

const LockIcon = ({ width = 24, height = 24, ...props }: ILockIcon & React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 24 24`}
      fill={props.fill}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.792 11v-.75a.75.75 0 00-.75.75h.75zm13.416 0h.75a.75.75 0 00-.75-.75V11zm0 10v.75a.75.75 0 00.75-.75h-.75zM4.792 21h-.75c0 .414.336.75.75.75V21zm0-9.25h13.416v-1.5H4.792v1.5zM17.458 11v10h1.5V11h-1.5zm.75 9.25H4.792v1.5h13.416v-1.5zM5.542 21V11h-1.5v10h1.5zM8.417 7c0-1.825 1.41-3.25 3.083-3.25v-1.5C8.94 2.25 6.917 4.407 6.917 7h1.5zM11.5 3.75c1.673 0 3.083 1.425 3.083 3.25h1.5c0-2.593-2.022-4.75-4.583-4.75v1.5zM6.917 7v4h1.5V7h-1.5zm7.666 0v4h1.5V7h-1.5z"
        fill={props.fill}
      />
    </svg>
  );
};

export default LockIcon;
