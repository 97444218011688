import React from 'react';

import { membershipApplicant } from '../../data';
import Membership from '../../shared/membership.component';

const MembershipApplicant = () => {
  return <Membership data={membershipApplicant} />;
};

export default MembershipApplicant;
