import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IEventSlice {
  currentEventId: string | null;
  isUnSaveChangedData?: boolean;
}

const initialState: IEventSlice = {
  currentEventId: null,
  isUnSaveChangedData: false,
};

const setCurrentEventId = (state: IEventSlice, action: PayloadAction<string>) => {
  state.currentEventId = action.payload;
};

const setStatusUnSaveChangedStatus = (state: IEventSlice, action: PayloadAction<boolean>) => {
  state.isUnSaveChangedData = action.payload;
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setCurrentEventId,
    setStatusUnSaveChangedStatus,
  },
  extraReducers: (builder) => ({}),
});

export const eventActions = eventSlice.actions;
export const eventReducer = eventSlice.reducer;
