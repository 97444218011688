import React from 'react';

import { Box } from '@material-ui/core';
import { ArrowRightAltOutlined, CalendarTodayOutlined } from '@material-ui/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { FORMAT_DATE_DMY } from 'src/constants/common';
import EventDetailBackground from 'src/medias/images/event-detail-background.png';

interface ICardEvent {
  image: string;
  date: string;
  title: string;
  eventId: number | string;
}

const CardEventContainer = styled(Box)`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    max-height: ${theme.spacing(30)}px;
    &:not(:last-child) {
      margin-bottom: ${theme.spacing(3)}px;
    }
    height: 240px;
    width: 100%;
  `}
`;

const CardEventImage = styled.img`
  object-fit: contain;
  object-position: center bottom;
  width: 400px;
  height: 100%;
  background: url(${EventDetailBackground});
`;

const CardEventContent = styled(Box)`
  ${({ theme }) => css`
    padding: ${theme.spacing(3)}px;
    width: 497px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: ${theme.palette.gray?.[200]};
    color: #333333;
    flex: 1;
  `}
`;

const CardEventDate = styled(Box)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: ${theme.fonts.header5.fontSize};
    line-height: ${theme.fonts.header5.lineHeight};
    font-weight: 700;
    color: #333333;
  `}
`;

const CardEventTitle = styled(Box)`
  ${({ theme }) => css`
    font-size: ${theme.fonts.cardEvent.fontSize};
    font-weight: ${theme.fonts.cardEvent.fontWeight};
    line-height: 33px;
    padding-top: ${theme.spacing(2)}px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #333333;
  `}
`;

const CardEventViewDetail = styled(Link)`
  ${({ theme }) => css`
    text-decoration: none;
    align-self: flex-end;
    text-transform: uppercase;
    color: ${theme.palette.yellow?.[1000]};
    display: flex;
    align-items: center;
    font-size: ${theme.fonts.header5.fontSize};
    font-weight: ${theme.fonts.header5.fontWeight};
    line-height: ${theme.fonts.header5.lineHeight};
    gap: 5px;
  `}
`;

const CalendarTodayOutlinedStyled = styled(CalendarTodayOutlined)`
  margin-right: 20px;
  fill: #333333;
`;

export const EventLinkStyled = styled(Link)`
  ${() => css`
    position: relative;
    text-decoration: none;
    color: inherit;
    margin-bottom: 24px;
    width: 100%;
  `}
`;

export const UpComingTag = styled(Box)`
  clip-path: polygon(0 0, 65% 0%, 50% 16%, 0 16%);
  background: red;
  color: white;
  width: 180px;
  position: absolute;
  top: 0;
  left: 0;
  height: 200px;
  padding: 5px;
  padding-left: 10px;
  font-size: 14px;
`;
export const Line = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: red;
  z-index: 99;
`;

const CardEvent = ({ image, date, eventId, title }: ICardEvent) => {
  const dateMoment = moment(+date);
  const now = moment();

  return (
    <EventLinkStyled to={`/events/${eventId}`}>
      {dateMoment > now && <Line />}
      <CardEventContainer>
        {dateMoment > now && <UpComingTag> UPCOMING</UpComingTag>}
        <CardEventImage src={image} />
        <CardEventContent>
          <Box pt={3}>
            <CardEventDate>
              <CalendarTodayOutlinedStyled fontSize="small" />
              {dateMoment.format(FORMAT_DATE_DMY)}
            </CardEventDate>
            <CardEventTitle>{title}</CardEventTitle>
          </Box>
          <CardEventViewDetail to={`/events/${eventId}`}>
            Discover <ArrowRightAltOutlined />
          </CardEventViewDetail>
        </CardEventContent>
      </CardEventContainer>
    </EventLinkStyled>
  );
};

export default CardEvent;
